import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { DataApi,Config,Local,Constants,Opera} from '../../provider/index';

@Component({
  selector: 'app-company-status-one',
  templateUrl: './company-status-one.component.html',
  styleUrls: ['./company-status-one.component.css']
})
export class CompanyStatusOneComponent implements OnInit {
  public power:boolean=true;
  public config: Config;
  constructor(
    private router: Router,
    public opera: Opera) { 
    this.config = Config.getInstance();//实例化config 
  }

  ngOnInit() {
    this.opera.initConfig('company','company-authentication');
    this.opera.checkPlatformIdentity();
  }
  powerTab(){
    this.power=true;
  }
  searchTab(){
    this.power=false;
  }
  // 跳转支付页面
goSearch(){
  this.router.navigate(["home/company-pay"],{
    queryParams:{
      searchMoney:298
    }
  });
}
}
