<!-- 页头部分 -->
<div class="contentRight-header">招聘管理 - 签约中心</div>
<!-- 主体内容部分 -->
<div class="homepage-block"> 
    <div class="company-block">
        <!-- 头部 -->
        <div class="identity-head">
            <button [class.purple]="type=='departrment'" (click)="goDepartment()">我发起的</button>
            <button [class.purple]="type=='talent'" (click)="goTalent()">我收到的</button>
        </div>
        <!-- 组件 -->
        <!-- 认证信息提交部分 -->
        <router-outlet></router-outlet>
        <app-model-hired-department [isShow]="modelShow"></app-model-hired-department>
        <app-model-hired-talent [isShow]="modelTwoShow"></app-model-hired-talent>
    </div>
    
    <!-- <app-model-company-identity [isShow]="modelShow" [id]="collegeid" (onVoted)="Modeldismiss($event)"></app-model-company-identity>
    <app-model-identity-status [isShow]="modelShowStatus" [status] ="status" (onVoted)="Modeldismiss($event)"></app-model-identity-status>
    <app-model-identity-college [isShow]="modelShowCollege"></app-model-identity-college> -->
</div>
