import { Component, OnInit,Input, Output,ViewChild } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { DataApi,Local,Constants,Config,Opera,} from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmComponent } from './../confirm/confirm.component';
import { ModelSelectTwoComponent } from '../model-select-two/model-select-two.component';
import { ModelCropperComponent } from '../model-cropper/model-cropper.component';
import {ModelAddressAddComponent} from "../model-address-add/model-address-add.component";
import {$} from "protractor";
import {ModelCompanyStrengthComponent} from "../model-company-strength/model-company-strength.component";
import { Events } from '../../provider/eventService';
import {LogoutAccountModelComponent} from "../logout-account-model/logout-account-model.component";
declare var window;
declare let laydate;
declare var layui;

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.css']
})
export class GuideComponent{
  @ViewChild('guideIndustry', { static: true }) child: ModelSelectTwoComponent;
  @ViewChild('address', { static: true }) address: ModelAddressAddComponent;

  @ViewChild('crop', { static: true }) child1: ModelCropperComponent;
  @ViewChild('strength', { static: true }) child4: ModelCompanyStrengthComponent;

  @Output() modelTwoShow = false;//是否打开弹窗
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelThreeShow = false;//是否打开弹窗
  @Output() modelFiveShow = false;//公司实力
  public config: Config;
  //对象初始化
  public model = {
    linkman : '', //联系人
    post : '', //职务
    gender:'01',//性别
    name: '', //公司全称
    common_name : '', //公司简称
    industry : '', //行业
    employer_type:'',// 企业性质
    employer_category:'0101',//公司注册地
    email : '', //工作邮箱
    register_capital:'',//注册资金
    address:'',//公司地址
    establish_year:"",//成立年份
    is_quoted_company:false,
    strengths:[],
    linshiDiZhi:'',
    ScaleArr:[],
    scale:'',
    intro:'',
    addressArr:[],
    location:[],
    logo:"",
    logoData:'',
    first_collegeid:'',
    register_capital_unit:'',
    currency:'01',//默认人民币
  };
  public register_capital_type = '万元'

  public industryListOne=[];//一级行业列表
  public industryListTwo=[];//二级行业列表
  public industryOne='';//行业类别一级
  public currency=[];//货币类型
  public isSend=false;//是否点击发送按钮
  //头像默认值
  public userAvatar = '';
  public guideStep=false;
  public guideStep1=false;
  public addAddressModel=false;


  //企业当前地址
  public workPlace = '';
  //企业数据(企业信息,修改资料的时候不用这个传递)
  public myDepartment:any;
  public addressList1=[];
  public addressList2=[];
  public addressList3=[];
  public listTwo=[];
  public listThree=[];
  public address1='';
  public address2='';
  public address3='';
  public detailAddress='';

  // public  is_quoted_company = true;//是否上市
  // public  strengths=[];//公司实力

  //是否是大陆高校，大陆高校不展示
  public isMainlandCollege = true;

  public  global_regionArr = [];
  public  global_regionArr1 = [];
  public  global_regionValue1 ="01";
  public  global_regionArr2 = [];

  constructor(public dataApi: DataApi,
    public events: Events,
    public local:Local,
    public opera:Opera,
    private location: PlatformLocation,
    private router: Router,
    public dialog: MatDialog,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
      this.config.isGuide=true;
      // location.onHashChange(val=>{
      //   //console.log("浏览器操作路由-------");
      //   //console.log(val);
      //   this.router.navigateByUrl("login");
      //   history.go(0);//前进&后退均处理为刷新
      // })
      //获取企业类别字典
      this.dataApi.getDictDate('employer_type').then((res:any)=>{
        //console.log('获取企业类别线上数据');
        //console.log(res);
        this.config.employer_type=res;
      }).catch((error)=>{
          //console.log(error);
      });
      this.getIndustry();//获取所属行业
       console.log("lcoal",this.local)
      if(this.local.get(Constants.byCollegeDetails)){
        this.guideStep=true;

        let  CollegeDetails= JSON.parse(this.local.get(Constants.byCollegeDetails))
        let index = CollegeDetails.region.slice(0,2)
        if(index=='32'||index=='33'||index=='34'||index=='35')
        {
          this.isMainlandCollege = false;
        }
        console.log('是否是大陆高校',this.isMainlandCollege,this.modelFiveShow)
      }
    window.onpopstate = (event) => {
      console.log('onpopstate',event)
      window.location.reload()
    };

      if(this.local.get('collegeid')) {
        this.model.first_collegeid = this.local.get('collegeid')
      }
      let that = this;
      if(this.local.get('register_guide'))
      {
        try {
          this.model = JSON.parse(this.local.get('register_guide'))
          if(this.local.get('register_userAvatar'))
          {
            this.userAvatar =this.local.get('register_userAvatar')
          }
          if(this.model.employer_category)
          {
            this.global_regionValue1 = this.model.employer_category.slice(0, 2);
            this.getGlobal_region(this.global_regionValue1);
          }

        }catch (e) {
        }
      }
      else if(this.local.get('department')) {
        try {
         let department = JSON.parse(this.local.get('department'));

          that.userAvatar = department.logo;
          that.model.logo = department.logo;
          that.model.name = department.name;
          that.model.common_name = department.common_name;
          that.model.industry = department.industry;
          that.model.employer_type = department.employer_type;
          that.model.employer_category = department.employer_category;
          that.model.register_capital = department.register_capital;
          that.model.establish_year = department.establish_year;
          that.model.scale = department.scale;
          that.model.intro = department.intro;
          that.model.is_quoted_company = department.is_quoted_company;
          that.model.strengths = department.strengths;

          // that.model.addressArr = department.locations;
          //code: "160201"
          // DetailAddress: "撒打算打算"
          // text: "河南省开封市龙亭区撒打算打算"

          // lid: "5e9419f370b3fe314493833e"
          // region: "160102"
          // region_text: "河南省,郑州市,二七区"
          // coordinates: (2) [0, 0]
          // address: "塞班塞班"
          // ltype: 0
          // is_default: false
          if(department.locations)
          {
            let newArr = [];
            for (let n of department.locations)
            {
              let data = {code: n.region,DetailAddress:n.address,text:n.region_text+n.address,}
              newArr.push(data);
            }
            that.model.addressArr = newArr;
          }
          //model.linshiDiZhi
          if(department.locations.length>0)
          {
            that.model.linshiDiZhi = department.locations[0].region;
          }
          console.log('缓存data',that.model)
          if(that.model.employer_category)
          {
            that.global_regionValue1 = that.model.employer_category.slice(0, 2);
            that.getGlobal_region(that.global_regionValue1);
          }


        }catch (e) {
        }
      }


  }
  ngOnInit() {
    let that = this;
    laydate.render({
      elem: '#establish1',
      type: 'year',
      done: function(value, date){
        that.model.establish_year = value;
      }
    })
    //获取企业注册地
    this.getGlobal_region('');

    //获取货币类型字典
    this.dataApi.getDictDate('currency').then((res:any)=>{
      console.log('获取货币类型线上数据');
      console.log(res);
      this.currency = res;
      if(!this.model.currency)
      {
        this.model.currency = '01';//默认人民币
      }
      console.log('hhhhhh',this.model.currency);
    }).catch((error)=>{
      //console.log(error);
    });

    // 获取地区字典
    this.dataApi.getDictDate('region').then((res:any)=>{
      this.config.region = res;
      console.log("获取地区字典一级列表2",this.config.region);

      for(let val of this.config.region){
        if(val.level==1){
          this.addressList1.push(val);
        }
        else if(val.level==2){
          this.addressList2.push(val);
        }
        else
        {
          this.addressList3.push(val);

        }
      }


    }).catch((error)=>{
      //console.log(error);
    });

    // this.model.ScaleArr = this.dataApi.getScaleCode();
    // console.log('企业规模',this.model.ScaleArr);
    //
    // // 获取地区字典
    // this.dataApi.getScaleCode().then((res:any)=>{
    //   this.model.ScaleArr  = res;
    //   console.log('企业规模',res);
    //
    // }).catch((error)=>{
    //   console.log('getScaleCodeerror',error);
    // });

    this.getEmployerScale();

    let collegeid=this.local.get('collegeid')+''
    console.log("请重新从高校站内登录",collegeid);
    if(collegeid+''=='false'){
      this.toastr.error(this.dataApi.getNoDepardmentID());
      return;
    }


  }
  //获取企业规模字典
  getEmployerScale() {
    if (this.dataApi.getScaleCode()) {
      this.model.ScaleArr = this.dataApi.getScaleCode();
    } else {
      //获取企业规模字典
      this.dataApi.getDictDate('scale').then((res:any)=> {
        this.model.ScaleArr = res;
        this.dataApi.setScaleCode(res);
      }).catch((error) => {
        //console.log(error);
      });
    }
  }

    //获取所属行业
  getIndustry(){
    if(this.local.get(Constants.industry)){
      //console.log('获取行业类型本地存储');
      //console.log(this.dataApi.getIndustryCode());
      this.config.industry = this.dataApi.getIndustryCode();
    }else{
      //获取行业类型字典
      this.dataApi.getDictDate('industry').then((res:any)=>{
        //console.log('获取行业类型线上数据');
        //console.log(res);
        this.dataApi.setIndustryCode(res);
      }).catch((error)=>{
        //console.log(error);
      });
    }
  }

  //选择行业类别
  chooseIndustry(){
    this.modelShow = true;
    this.opera.scrollTop();//页面滚动函数
    // scrollTo(0,0);
    //调用子组件函数
    this.child.initData(this.model.industry,this.config.industry,'选择行业类别');

  }
  //接收子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
    if(event){
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      this.model.industry = event.code;
    }
  }

//验证企业全称
checkName(){
 if(this.model.name){
   let filter2=/^[a-zA-Z\uff08\uff09\u4e00-\u9fa5]+$/;

  //  if(!filter2.test(this.model.name)){
  //   this.toastr.warning("企业全称只能输入汉字和字母");
  //   return;
  // }

   this.opera.loading();//加载动画
   this.dataApi.testDepartmentName({name:this.model.name}).then((res:any)=>{
    this.opera.loadiss();//停止加载动画
      //console.log(res);
     let title = '您申请的公司（'+this. model.name+') 全称已存在，是否继续注册？（注册后可正常使用）'

     if(res.value>0){
        this.opera.scrollTop();//页面滚动函数
       this.dialog.open(ConfirmComponent, {
         data: {
           title: '温馨提示',
            message: title,
            trueText:'确定',
            falseText:'取消'
         }
       }).afterClosed().subscribe((data) => {
             //console.log('检测是否存在返回值');
             //console.log(data);
            //  //console.log(typeof(data));
             this.opera.scrollTrue();
             if(!data){
               this.model.name=''
             }
       });
      }
    }).catch((err)=>{
      this.opera.loadiss();//停止加载动画
     //console.log(err);
    })
 }
}
//验证企业简称
checkCommonName(){
 if(this.model.common_name){
   let filter2=/^[a-zA-Z\uff08\uff09\u4e00-\u9fa5]+$/;
   if(!filter2.test(this.model.common_name)){
     this.toastr.warning("企业简称只能输入汉字和字母");
     return;
   }
 }
}
//验证姓名
checkLinkman(){
 if(this.model.linkman){
   let filter2=/^[a-zA-Z\u4e00-\u9fa5]+$/;
   if(!filter2.test(this.model.linkman)){
     this.toastr.warning("姓名只能输入汉字和字母");
     return;
   }
 }
}
//验证职位
checkPost(){
 if(this.model.post){
   let filter2=/^[a-zA-Z\u4e00-\u9fa5]+$/;
   if(!filter2.test(this.model.post)){
     this.toastr.warning("职务只能输入汉字和字母");
     return;
   }
 }
}
//验证邮箱
checkEmail(){
 if(!this.model.email){
   let phoneModel={
     format_type:2,//0:手机号，1：手机号&固话；2.邮箱
     data:this.model.email
   }
   this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
     if(!res.value){
       this.toastr.warning('工作邮箱格式错误');
       return;
     }
   }).catch((err)=>{
     //console.log(err);
     this.toastr.error('工作邮箱格式错误');
   })
 }
}

//创建引导(创建企业)
setOneStep(){

  let collegeid = this.local.get('collegeid')+''
  console.log("请重新从高校站内登录",collegeid);
  if(collegeid+''=='false') {
    this.toastr.error(this.dataApi.getNoDepardmentID());
    return;
  }
  if(!collegeid) {
    this.toastr.error(this.dataApi.getNoDepardmentID());
    return;
  }
  let filter2=/^[a-zA-Z\uff08\uff09\u4e00-\u9fa5]+$/;
  if(!filter2.test(this.model.name)){
   this.toastr.warning("企业全称只能输入汉字和字母");
   return;
  }
  if(!filter2.test(this.model.common_name)){
    this.toastr.warning("企业简称只能输入汉字和字母");
    return;
  }
  if(this.model.addressArr.length==0)
  {
    this.toastr.warning("请输入公司地址");
    return;
  }
   this.isSend=true;
   console.log("CreatDepartment",this.model)
  if(this.model.industry ==''||this.model.employer_category==""||this.model.employer_type==''||this.model.employer_category==''||this.model.register_capital==''||this.register_capital_type==''||this.model.currency==''||this.model.establish_year==''||this.model.scale==''||this.model.intro=='')
  {
    this.toastr.warning("请完善必填项信息");
    return;
  }
  if(this.model.establish_year)
  {
    if(!this.opera.isEstablish_year(this.model.establish_year))
    {
      this.toastr.warning("公司成立年份格式错误");
      this.model.establish_year = "";
      return;
    }
  }
  let arr=[];
  for (let n of this.model.addressArr)
  {
    let data = {region:""};
    arr.push({region:n.code,address:n.DetailAddress,coordinates:[0,0]})
  }
  if(this.model.logoData)
  {
    this.model.logo=this.model.logoData;
  }
  if(this.model.intro.length<50)
  {
    this.toastr.warning("公司简介不得少于50字");
    return;
  }else if(this.model.intro.length>500){
    this.toastr.warning("公司简介不得多于500字");
    return;
  }
  this.model.location=arr;


    this.model.register_capital_unit = this.register_capital_type;
    if(this.register_capital_type=='亿元')
    {
      this.model.register_capital = parseInt( this.model.register_capital)*10000+"";
    }
    else
    {

    }
    console.log("CreatDepartment",this.model)
  this.local.set('register_guide',JSON.stringify(this.model))
  this.local.set('register_userAvatar',this.userAvatar)

   this.opera.loading();//加载动画
    if(this.local.get('resetOneStep')){
      this.dataApi.resetOneStep(this.model).then((res:any)=>{
        //console.log(res);
        this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
          // console.log('创建企业',res);
          if(res){
            this.dataApi.setLocalAccountId(res.accountid);//将accounid存到本地、全局变量
            this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
            this.opera.loadiss();//停止加载动画
            this.getImInit(res);//初始化极光登陆
            //------跳转企业认证页面
            this.router.navigateByUrl('guide-company');
          }else{
            this.opera.loadiss();//停止加载动画
            setTimeout(() => {
              this.toastr.error('保存失败,请重新提交');
            },600);
          }

        }).catch((error)=>{
          //console.log(error);
          this.opera.loadiss();//停止加载动画
          setTimeout(() => {
            this.toastr.error(error);
          },600);
        });


      }).catch((error) => {
        this.opera.loadiss();//停止加载动画
        setTimeout(() => {
          this.toastr.error(error);
        },600);

      });

    }else{
      this.dataApi.setOneStep(this.model).then((res:any)=>{
        //console.log(res);
        this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
          // console.log('创建企业',res);
          if(res){
            this.dataApi.setLocalAccountId(res.accountid);//将accounid存到本地、全局变量
            this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
            this.opera.loadiss();//停止加载动画
            this.getImInit(res);//初始化极光登陆
            //------跳转企业认证页面
            this.router.navigateByUrl('guide-company');
          }else{
            this.opera.loadiss();//停止加载动画
            setTimeout(() => {
              this.toastr.error('保存失败,请重新提交');
            },600);
          }

        }).catch((error)=>{
          //console.log(error);
          this.opera.loadiss();//停止加载动画
          setTimeout(() => {
            this.toastr.error(error);
          },600);
        });


      }).catch((error) => {
        this.opera.loadiss();//停止加载动画
        console.log('setOneSteperr',error)
        this.toastr.error(error);

        setTimeout(() => {
          if(error=='企业已注册，请勿重复注册'){
            this.local.set('resetOneStep',true);

            this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
              // console.log('创建企业',res);
              if(res){
                this.dataApi.setLocalAccountId(res.accountid);//将accounid存到本地、全局变量
                this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
                this.opera.loadiss();//停止加载动画
                this.getImInit(res);//初始化极光登陆
                //------跳转企业认证页面
                this.router.navigateByUrl('guide-company');
              }else{
                this.opera.loadiss();//停止加载动画
                setTimeout(() => {
                  this.toastr.error('保存失败,请重新提交');
                },600);
              }

            }).catch((error)=>{
              //console.log(error);
              this.opera.loadiss();//停止加载动画
              setTimeout(() => {
                this.toastr.error(error);
              },600);
            });


          }
        },600);

      });
    }
 }

  //极光初始化&登陆
  getImInit(data){
   //console.log("guide极光登陆--------------------------");
  this.dataApi.getImInit().then((res:any)=>{
    //console.log(res);

  }).catch((error)=>{
    //console.log(error);
    //this.tip.presentToast(error);
  });
}

//上传logo
previewPic(event) {
 let imgData  = event.target.files[0]; //获取file对象
  if (parseInt(imgData.size)/1024 > 1024*5) {
    this.toastr.warning('该图片大小超出了5M，请重新上传');
    return ;
  }
 if (window.FileReader) {
   var reader = new FileReader();
   reader.readAsDataURL(imgData);
   var that = this;
   reader.onloadend = function (e : any) {
     that.imageCropper(reader.result);
   };

 }
}

//裁剪插件
imageCropper(imageData){
  this.modelTwoShow=true;
  this.child1.initData(imageData);
  // this.opera.scrollTop();//页面滚动函数
}

//接收子组件返回数据
ModelTwodismiss(event){
  this.modelTwoShow = false;
  this.opera.scrollTrue();
  if(event){
    this.uploadDepImg(event);
  }
}

//上传
uploadDepImg(imageData){
    let newimageData=this.dataApi.formatImage(imageData)
    this.model.logoData = newimageData;
  this.userAvatar = imageData;
   // this.opera.loading();//加载动画
   // this.dataApi.uploadDepartmentLogo({value: imageData}).then((res:any)=>{
   //   this.userAvatar = res.value;
   //   this.model.logoData = imageData;
   //   this.opera.loadiss();//停止加载动画
   //   setTimeout(() => {
   //     this.toastr.success('上传成功');
   //   },600);
   // }).catch((error)=>{
   //   this.opera.loadiss();//停止加载动画
   //   this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
   // })
}

  //添加地址
  goAddAddress(){
    if(this.model.addressArr.length==5)
    {
      this.toastr.error("最多添加5个地址");
      return;
    }
    this.addAddressModel=true;
  }
  cloceDownLoad(){
    this.addAddressModel=false;
    this.address1='';
    this.address2='';
    this.address3='';
    this.detailAddress='';
  }

  cityTwoChange(){

    this.listTwo=[];
    for(let n of this.config.region){
      if(n.parentcode==this.address1){
        this.listTwo.push(n);
      }
    }
  }
  cityThreeChange(){
    this.listThree=[];
    for(let n of this.config.region){
      if(n.parentcode==this.address2){
        this.listThree.push(n);
      }
    }
  }
  okAddressClick(){

    if(this.address3==''||this.detailAddress=='')
    {
      this.toastr.error("地址错误");
      return;
    }
    let title1='';
    let title2='';
    let title3='';
    for (let n of this.config.region)
    {
      if(n.code+''==this.address1)
      {
        title1=n.text;
      }
      else if(n.code+''==this.address2)
      {
        title2=n.text;
      }
      else if(n.code+''==this.address3)
      {
        title3=n.text;
      }
    }

    this.addAddressModel = false;
    let add = {code:this.address3,DetailAddress:this.detailAddress,text:title1+title2+title3+this.detailAddress}
    this.model.addressArr.push(add);
    if(this.model.addressArr.length>0)
    {
      this.model.linshiDiZhi=this.model.addressArr[0].code;
    }
    this.address1='';
    this.address2='';
    this.address3='';
    this.detailAddress='';

    console.log("this.addressArr",this.model.addressArr);
  }
  addShiLi(){
    // this.opera.scrollTop();//页面滚动函数
    this.modelFiveShow=true;
    this.child4.initData(this.model.strengths)
  scrollTo(0,0)


  }
  //接收子组件返回数据
  ModelFivedismiss(event){
    this.modelFiveShow = false;
    // this.opera.scrollTrue();
    scrollTo(0,1300)
    if(event){
      console.log("接收子组件返回数据",event);
      // //console.log(event);
      // //重新赋值企业标签
      this.model.strengths=event;
      // this.myDepartment.strengths=this.model.strengths;
      // this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
    }
  }
  register_capitalChange(e){
    console.log('e',e,this.model.register_capital)
    if(this.register_capital_type == "亿元")
    {
      if(parseInt(e)>10000)
      {
        setTimeout(()=>{
          this.model.register_capital = "10000";
          this.register_capital_type = "亿元"
        },200)
      }
      return;
    }
    if(e>=10000)
    {
      console.log('e',this.model.register_capital)
      setTimeout(()=>{
        this.model.register_capital =  (parseFloat(e)/10000)+"";
        this.register_capital_type = "亿元"
      },200)
    }
    else
    {
      this.model.register_capital =  (parseInt(e)*10000)+"";
      this.register_capital_type = "万元"
    }
  }
  //取消注册询问
  cancelRegisterChips(){
    let that = this;
    this.opera.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '您是否要退出注册？',
        trueText:'确定',
        falseText:'取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      //console.log('检测是否存在返回值');
      //console.log(isConfirmed);
      this.opera.scrollTrue();
      if(isConfirmed){
        that.cancelRegisterPost();
      }
    });
  }
  //取消注册询问
  cancelRegisterPost(){
    this.events.publish('user:created'); //发送退出登录选项
    let collegeid = this.local.get('collegeid') + '';
    this.dataApi.logout();
    console.log('logout',collegeid);
    if (collegeid !='false') {
      this.router.navigateByUrl("login?type=login&id="+collegeid);
    }else
    {
      this.router.navigateByUrl("login");
    }
  }

  //企业注册地修改
  employer_categoryClick(type){
    this.model.employer_category = type;
  }
  getGlobal_region(value1){
    this.dataApi.getDictDate('global_region').then((res:any)=>{
      let arr1 = [];
      let arr2 = [];
      let arr3 = [];
      res.map((item,index)=>{
        if(item.code.length=='2')
        {
          arr1.push(item);
        }
        else
        {
          arr2.push(item);
          if(value1)
          {
            if(value1==item.code.slice(0, 2))
            {
              arr3.push(item)
            }
          }
        }
      })
      this.global_regionArr1 = arr1;
      this.global_regionArr = arr2;

      let newArr = [];
      if(this.model.employer_category)
      {
        this.global_regionArr.map((item)=>{

          if(this.global_regionValue1==item.code.slice(0, 2))
          {
            newArr.push(item)
          }
        })
        if(newArr.length>0)
        {
          this.global_regionArr2 = newArr;
        }
      }
      if(arr3.length>0)
      {
        this.global_regionArr2 = arr3;
      }

    }).catch((error)=>{
      //console.log(error);
    });
  }
  //注册地址一级变动
  employer_categoryChange(e){
    // console.log('e',e,this.global_regionValue1);

    let arr = [];
    this.global_regionArr.map((item,index)=>{
         let code = item.code.slice(0, 2);
         if(code==this.global_regionValue1)
         {
           arr.push(item);
         }
    })
    this.global_regionArr2 = arr;
    this.model.employer_category = "";
    console.log('global_regionValue1',this.global_regionValue1,this.model.employer_category)
  };

  employer_categoryChange2(){
    console.log('global_regionValue1',this.global_regionValue1,this.model.employer_category)
  }
  //注销账号
  logoutAccountClick(){
    this.dialog.open(LogoutAccountModelComponent, {
      data: {}
    }).afterClosed().subscribe((isConfirmed) => {
      console.log('isConfirmed',isConfirmed)
      if(isConfirmed){
      }
    });
  }


}
