<!-- 页头部分 -->
<div class="contentRight-header">企业中心 - 企业认证</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <div class="college-block">
        <!-- 页头部分 -->
        <div class="college-block-head collegeBlockMargin">
            <!-- <p>企业权益中心</p> -->
          <div class="college-tab">
            <span (click)="powerTab()"  [class.bold]="power">企业认证权益</span>
            <!-- 高级搜索暂时下线 -->
            <!-- <span (click)="searchTab()" [class.bold]="!power">人才搜索权益</span> -->
          </div>
        </div>
      
        <!-- 介绍 -->
        <div class="college-block" *ngIf="power">
            <h3 class="power-title powerTitle"><img src="assets/images/college-status-six.png" alt=""> 我的认证权益</h3>
            <!-- 信息展示部分 -->
            <div class="college-list collegeList">
                <!-- <div *ngIf="config.department" class="college-message clear">
                    <div class="left">单位名称:</div>
                    <div class="left">{{config.department.name}}</div>
                </div>
                <div *ngIf="config.loginUser" class="college-message clear">
                    <div class="left">人&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;事:</div>
                    <div class="left">{{config.loginUser.name}}</div>
                </div> -->
                <div class="college-message collegeMessage clear">
                    <div class="left">认证状态：</div>
                    <div class="left">已认证</div>
                </div>
                <div *ngIf="config.department" class="college-message collegeMessage clear">
                    <!-- <div class="left">认证期限:</div>
                    <div class="left">{{config.department.authentication_begintime | date:'yyyy.MM.dd'}}-{{config.department.authentication_endtime | date:'yyyy.MM.dd'}}</div> -->
                    <div class="left">认证有效期至：</div>
                    <div class="left">{{config.department.authentication_endtime | date:'yyyy.MM.dd'}}</div>
                </div>
                <div *ngIf="config.department" class="college-message">
                    <!-- <div class="left"></div> -->
                    <ul class="UlRights">
                        <li>
                            <p class="rightsTitle"><i>——</i><span>查看高校人才简历</span><i>——</i></p>
                            <p class="rightsMain">全部<em>50</em>所高校</p>
                            <button class="purple" (click)="goSchool()">立即查看</button>
                        </li>
                        <li>
                            <p class="rightsTitle"><i>——</i><span>今日主动沟通剩余</span><i>——</i></p>
                            <p class="rightsMain"><em>{{config.department.quota.dialogue}}</em>人</p>
                            <button class="purple" (click)="goCollegeResume()">立即直聊</button>
                        </li>
                        <li>
                            <p class="rightsTitle"><i>——</i><span>发布职位数量</span><i>——</i></p>
                            <p class="rightsMain"><em>10</em>个/天</p>
                            <button class="purple" (click)="goPositionManage()">发布职位</button>
                        </li>
                        <li>
                            <p class="rightsTitle"><i>——</i><span>消息查看特权</span><i>——</i></p>
                            <p class="rightsMain"><em></em>已开通</p>
                            <button class="purple" (click)="goChatContact()">查看消息</button>
                        </li>
                    </ul>
                    <ul class="UlRights UlRights2">
                        <li>
                            <p class="rightsTitle"><i>——</i><span>企业加‘V’</span><i>——</i></p>
                            <p class="rightsMain"><em></em>已开通</p>
                            <span>&nbsp;</span>
                        </li>
                        <li>
                            <p class="rightsTitle"><i>——</i><span>专属人工客服</span><i>——</i></p>
                            <p class="rightsMain"><em></em>已开通</p>
                            <span>&nbsp;</span>
                        </li>
                        <li class="lib">
                        </li>
                        <li class="lib">
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 人才搜索 -->
        <div *ngIf="!power" class="search-part">
                <h3 class="power-title powerTitle"><img src="assets/images/college-status-six.png" alt="">我的搜索权益</h3>
                <div *ngIf="!config.searchStatus">
                    <div class="powerBlock">
                        <p class="powerState">当前状态：<span>未认证</span><button (click)="goSearch()">立即开通高级搜索</button></p>
                      </div>
                      <!-- <h3 class="power-title powerTitle"><img src="assets/images/college-status-three.png" alt="">企业认证介绍</h3>
                      <div class="powerBlock">
                        <span class="powerSee">点击查看</span>
                      </div> -->
                      <h3 class="power-title powerTitle"><img src="assets/images/college-status-two.png" alt="">人才搜索权益对比</h3>
                      <div class="powerBlock powers flex_row_left">
                        <div class="ordinaryPower flex_col_center">
                          <h3>普通搜索</h3>
                          <p class="powerMoney"><sup>￥</sup><span>0</span></p>
                          <button class="have">已获得</button>
                          <p class="transitionLine">————————————————</p>
                          <p><em></em>按学校搜索</p>
                          <p><em></em>按在招职位搜索</p>
                          <del>&nbsp;按学校标签搜索&nbsp;</del>
                          <del>&nbsp;按全部职位搜索&nbsp;</del>
                          <del>&nbsp;按专业搜索&nbsp;</del>
                          <del>&nbsp;按学历搜索&nbsp;</del>
                          <del>&nbsp;按界别搜索&nbsp;</del>
                          <del>&nbsp;按性别搜索&nbsp;</del>
                          <del>&nbsp;按期望工作城市搜索&nbsp;</del>
                        </div>
                        <div class="vipPower flex_col_center">
                          <img src="assets/images/college-status-five.png" alt="">
                          <h3>高级搜索</h3>
                          <p class="powerMoney"><sup>￥</sup><span class="redCol">298</span>/90天</p>
                          <button class="goBuy" (click)="goSearch()">立即认证</button>
                          <p class="transitionLine">————————————————</p>
                          <p><em></em>按学校搜索</p>
                          <p><em></em>按在招职位搜索</p>
                          <p><em></em>按学校标签搜索</p>
                          <p><em></em>按全部职位搜索</p>
                          <p><em></em>按专业搜索</p>
                          <p><em></em>按学历搜索</p>
                          <p><em></em>按界别搜索</p>
                          <p><em></em>按性别搜索</p>
                          <p><em></em>按期望工作城市搜索</p>
                        </div>
                      </div>
                </div>
                <!-- 已认证 -->
                <div class="college-list collegeList" *ngIf="config.searchStatus" >
                    <div class="college-message collegeMessage clear">
                      <div class="left">当前状态：</div>
                      <div class="left">开通</div>
                    </div>
                    <div class="college-message collegeMessage clear"  *ngIf="config.searchendTime">
                        <!-- <div class="left">认证期限:</div>
                        <div class="left">{{config.department.authentication_begintime | date:'yyyy.MM.dd'}}-{{config.department.authentication_endtime | date:'yyyy.MM.dd'}}</div> -->
                        <div class="left">认证有效期至：</div>
                        <div class="left">{{config.searchendTime | date:'yyyy.MM.dd'}}</div>
                    </div>
                    <div class="rightsCard">
                      <p><i>————————</i><span>多维度搜索人才</span><i>————————</i></p>
                      <span>已开通</span>
                      <ul class="flex_row_around">
                        <li><em></em>按学校搜索</li>
                        <li><em></em>按专业搜索</li>
                        <li><em></em>按期望工作城市搜索</li>
                        <li><em style="margin-left: 1rem;"></em>按在招职位搜索</li>
                        <li><em></em>按学历搜索</li>
                        <li><em></em>按期望薪资搜索</li>
                        <li><em></em>按学校标签搜索</li>
                        <li><em></em>按界别搜索</li>
                        <li><em></em>按是否在线搜索</li>
                        <li><em></em>按全部职位搜索</li>
                        <li><em></em>按性别搜索</li>
                        <li>&nbsp;</li>
                      </ul>
                    </div>
                  </div>
            </div>
    </div>
</div>
