<!-- 页头部分 -->
<div class="contentRight-header"><img (click)="opera.goNewRouter('college-details')" src='assets/images/router-old.png'/>校园招聘<span *ngIf="!config.isByCollege"> - 校园+</span> - 学校详情 - 新闻公告</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <div class="college-block" style="margin-bottom:20px;">
        <!-- 页头部分 -->
        <div class="college-block-head">
          新闻公告
        </div>
        <!-- 详情模块 -->
        <div class="college-head-details clear" *ngIf="college">
          <!-- 左侧文字 -->
          <ul class="left">
            <li><span>学校名称：</span>{{college.name}}<i *ngIf="!college.name">暂无</i></li>
            <li><span>创办时间：</span>{{college.establish_time | date:"yyyy-MM-dd"}}<i *ngIf="!college.establish_time">暂无</i></li>
            <li><span>学校性质：</span>{{college.school_nature_text}}<i *ngIf="!college.school_nature_text">暂无</i></li>
            <li><span>联系邮箱：</span>{{college.email}}<i *ngIf="!college.email">暂无</i></li>
            <li><span>学校代码：</span>{{college.code}}<i *ngIf="!college.code">暂无</i></li>
            <li><span>所在城市：</span>{{college.region_text}}<i *ngIf="!college.region_text">暂无</i></li>
            <!-- <li><span>就业电话：</span>{{college.phone}}</li> -->
          </ul>
          <!-- 右侧logo -->
          <div class="right">
            <img *ngIf="college.logo" [src]="college.logo" alt="高校logo">
            <img *ngIf="!college.logo" src="assets/images/none.png" alt="高校logo">
          </div>
      </div>
  </div>
  <div class="college-block">
    <!-- 头部 -->
    <div class="identity-head">
        <button *ngFor="let item of menuList;let i =index;" [class.purple]="step==i" (click)="getList(i)">{{item.name}}</button>
    </div>
    <!-- 中间部分 -->
    <!-- 没有内容存在时 -->
    <div *ngIf="countList==0" class="none-block">
        <img src="assets/images/resume-search-fail.png"><br/>
        暂未找到文章信息，请稍后重试
    </div>
    <!-- 循环列表 -->
    <div class="college-list-cont" *ngIf="countList>0">
      <ul>
          <li>文章标题</li>
          <li>发布时间</li>
          <li>操作</li>
      </ul>     
      <ul class="college-li" *ngFor="let n of resumeList;let i = index;">
          <li><span *ngIf='n.title'>{{n.title}}</span><span *ngIf='!n.title'>暂无</span></li>
          <li><span *ngIf='n.publish_time'>{{n.publish_time | date:"yyyy-MM-dd"}}</span><span *ngIf='!n.publish_time'>暂无</span></li>
          <li>
              <span (click)="goDetails(n.articleid)">查看</span>
          </li> 
      </ul>
              
  </div>
  <!-- 分页部分 -->
  <div class='paging-block' [class.isPageHeight]="countList>0">
      <router-outlet></router-outlet>
      <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
  </div>
</div>