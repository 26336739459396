<!-- 引导页面-->

<div class="guide-page">
    <!-- 头部 -->
    <div class="guide-head">
      <ul>
        <li>
          <span><img src="assets/images/guide-ok.png"></span>
           <br/>招聘信息
         </li>
         <li>
          <span><img src="assets/images/guide-ok.png"></span>
          <br/>完善信息
        </li>
        <li>
          <span><img src="assets/images/guide-ok.png"></span>
          <br/>申请高校合作
        </li>
        <li>
          <span class="isHeadActive">4</span>
          <br/>等待审核
        </li>
      </ul>
    </div>
   <!-- 中间部分 -->
   <div class="info-block">
     <div class="info-head"><img src="assets/images/guide-identity.png">证件提交成功</div>
     <div class="info-text">证件已提交，请耐心等待审核，我们会在一个工作日内反馈审核结果</div>
     <!-- 下一步按钮 -->
     <div class="block-button">
        <button (click)="goHome()">进入后台</button>
     </div>
   </div>
   <!-- 尾部 -->
  <div class="guide-foot"><span>客服热线：{{config.CustomerServicePhone}}</span>
    &nbsp;&nbsp;&nbsp;&nbsp;{{config.websiteRecord}}</div>
  </div>

