<!-- 常用语 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
  <div class="black-bg" (click)="dismiss(false)"></div>
  <div class="model-cont">
      <!-- 上层提示部分 -->
      <div class="model-head clear">
          <div class="left">请选择{{title}}</div>
          <button class="right" (click)="dismiss(false)">×</button>
      </div>
      <!-- 企业常见问题 -->
      <div *ngIf="title!='常见问题'" class="div-common">
          <div  *ngFor="let common of config.common" (click)="dismiss(common.text)" class="div-list">{{common.text}}</div>
      </div>  
      <!-- 小秘书常见问题 -->
      <div *ngIf="title=='常见问题'" class="div-common">
        <div class="div-list" *ngFor="let common of config.commonJun;let i =index;">
            <div  class="div-title">{{i+1}}.{{common.text}}</div>
            <div>{{common.relation_desc}}</div>
        </div>
      </div>
  </div>
</div>
