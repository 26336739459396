import { Component, OnInit, Input, Output,ViewChild } from '@angular/core';
import {DataApi, Local, Constants, Config, Opera, milkround,DataManager} from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {  ActivatedRoute,Router } from  '@angular/router';
import { ModelCollegeMilksignComponent } from '../model-college-milksign/model-college-milksign.component';
@Component({
  selector: 'app-college-milkdetails',
  templateUrl: './college-milkdetails.component.html',
  styleUrls: ['./college-milkdetails.component.css']
})
export class CollegeMilkdetailsComponent implements OnInit {
  public config: Config;
  @ViewChild('milkUpdata', { static: true }) child: ModelCollegeMilksignComponent;
  @Output() modelShow = false;//是否打开弹窗
  @Output() milkNumber = 1;//当前申请的招聘会场次
  @Output() mridList=[];//招聘会id列表
  public milk:any;//双选会详情
  public milkId='';//双选会id
  public isApplySign=true;//是否允许报名（即招聘会是已经开始）
  public isMilkStart=false;//招聘会是否已经开始
  public isMilkEnd=false;//招聘会是否已经结束
  public collegeIdentity:any;//企业当前高校认证数据

  public DownLoadUrl1 ='';//附件1
  public DownLoadUrlArr =[]//附件4


  constructor(public dataApi: DataApi,
    public dataManager:DataManager,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public milkroundFormat:milkround,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
      activatedRoute.queryParams.subscribe(queryParams => {
        if(queryParams.type){
          this.milkId = queryParams.mrId;
          this.getMilkDetails();//获取招聘会详情
        }else{
          if(queryParams.id){
            this.milkId = queryParams.id;
            this.getMilkDetails();//获取招聘会详情
          }
        }
      })
      if(!this.milkId){
        this.milkId = this.local.get(Constants.mrid);
        this.getMilkDetails();//获取招聘会详情
      }
    }

  ngOnInit() {
    this.opera.initConfig('college','college-milkround');
    this.getCollageDetails();
  }

  //接收返回参数
  Modeldismiss(event){
    this.modelShow=false;
    this.opera.scrollTrue();
    if(event){
      this.milk.status='to_audit';
    }
    this.mridList = [];//清空
  }

  //去报名
  async goSign(){
    let releasePositionList = (await this.dataManager.getPositionList(true)).filter(v=>v.need_perfect==false&&v.position_status=='release')
    if(releasePositionList.length==0){
      this.opera.remindPublish("报名双选会");
      return;
    }
    this.goMilkSign();//报名
    // if(this.collegeIdentity){//判断check
    //   if(this.collegeIdentity.gwl_status=='2'){//通过工作啦认证
    //     this.goMilkSign();//报名
    //   }else{//未通过工作啦审核
    //     if(this.collegeIdentity.is_activity){//活动高校
    //       if(this.collegeIdentity.count>0){//通过1家以上高校校招
    //         this.goMilkSign();//报名
    //       }else{//提示企业认证
    //         this.opera.remindDepartmentIdentity(this.milk.collegeid,this.collegeIdentity);
    //       }
    //     }else{//非活动高校
    //       if(this.collegeIdentity.status=='2'){//通过校招
    //         this.goMilkSign();//报名
    //       }else if(this.collegeIdentity.status=='1'){//校招审核中
    //         this.toastr.warning("校招审核中，审核通过后才可报名双选会");
    //       }else{//未提交校招申请
    //         this.opera.remindCollegeIdentity("报名双选会",this.milk.collegeid,this.collegeIdentity);
    //       }
    //     }
    //   }
    // }else{//未请求到企业认证信息
    //   this.opera.remindCollegeIdentity("报名双选会",this.milk.collegeid,this.collegeIdentity);
    // }
  }

  //去双选会报名页
  goMilkSign(){
    if(this.milk.apply_start_time == '0001-01-01T00:00:00' || this.milk.apply_start_time == '0001-01-01T00:00:00+08:00' || this.milk.apply_start_time == ''){
      this.milk.signAppTime = false; // 未设置报名开始时间
    }else{
      this.milk.signAppTime = true;
    }
    if(this.milk.apply_end_time == '0001-01-01T00:00:00' || this.milk.apply_end_time == '0001-01-01T00:00:00+08:00' || this.milk.apply_end_time == ''){
      this.milk.signEdTime = false; // 未设置报名结束时间
    }else{
      this.milk.signEdTime = true;
    }
    if(this.milk.signAppTime && this.milk.signEdTime){
      let nowDate = new Date();
      let applyOpDate = new Date(this.milk.apply_start_time); // 报名开始时间
      let applyEndDate = new Date(this.milk.apply_end_time); // 报名结束时间
      let endDate = new Date(this.milk.end_time); // 结束时间
      let nowTime = nowDate.getTime();  // 当前时间戳
      let applyOpTime = applyOpDate.getTime();  // 报名开始时间戳
      let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳
      let endDateTime = endDate.getTime();  // 结束时间

      if(nowTime-applyOpTime<=0){
        this.toastr.warning('报名未开始！');
        return
      }
      if(this.milk.milkround_type!=2)
      {
        if(nowTime-applyEndTime>0){
          this.toastr.warning('报名已结束！');
          return
        }
      }
      else
      {
        if(nowTime-endDateTime>0){
          this.toastr.warning('报名已结束！');
          return
        }
      }

    }

    if(this.milk.milkround_type !=2){
      if(this.milk.scale <= this.milk.department_participate_count ){
        this.toastr.warning('坐席已满，无法报名！');
        return
      }
    }

    this.local.set(Constants.collegeid,this.milk.collegeid);
    this.local.set(Constants.mrid,this.milkId);
    this.local.remove(Constants.applyid);
    this.router.navigate(['home/college-milk-sign'], {
      queryParams: {
        milkname:this.milk.name
      }
    });
  }

   //修改申请
   goUpdate(data){
    //console.log('修改申请--------------');
    //console.log(data);
    // let model={
    //   applyid:data.mcid,
    //   contacts:'',//联系人
    //   attendance:'',//参会人数
    //   phone:'',
    // }
    // this.modelShow = true;
    // this.child.initData(model);
    this.local.set(Constants.applyid,data.mcid);
    this.router.navigateByUrl('/home/college-milk-sign');
  }

  //进入会场
  goSpace(){
    this.router.navigateByUrl('home/college-milk-space');
    this.local.set(Constants.mrid,this.milkId);//覆盖本地存储招聘会id
  }

  //判断企业是否通过高校认证
  checkDepartmentIndentityByCollege(id){
    this.dataApi.checkDepCollegeIdentity(id).then((res:any)=>{
      this.collegeIdentity=res;
    }).catch((err)=>{
      //console.log(err);
    })
  }

   //获取招聘会详情
   getMilkDetails(){
    this.opera.loading();
    this.dataApi.getMilkDetails(this.milkId).then((res:any)=>{
       if(res.department_receipt_switch ==true) {
         this.DownLoadUrl1 = res.department_receipt_url;
       }

      //console.log(res);
      let newList =  this.milkroundFormat.formatList([res]);

      this.milk = newList[0];
      console.log("获取到招聘会详情", this.milk);

      this.getApplyStatus(res.start_time,res.end_time);//根据开始和结束时间判断当前招聘会的状态
       this.milkId = res.mrid;
       if(res.attachment_list!=null) {
         this.DownLoadUrlArr = res.attachment_list;
       }

      if(this.milk.zoom_name==null) {
        this.milk.zoom= '';
      }
      else
      {
        this.milk.zoom= this.milk.zoom_name==''?"":this.milk.zoom_name+"-";
      }
      console.log("获取到招聘会详情this.milk",this.milk.zoom);

       this.checkDepartmentIndentityByCollege(this.milk.collegeid);//验证企业是否通过高校认证
       this.local.set(Constants.mrid,this.milkId);
       this.opera.loadiss();
    }).catch((error)=>{
      // console.log(error);
      this.opera.goChangeRouter('homepage');
      this.toastr.error(error);
      this.opera.loadiss();
    })
  }

  //开始和结束时间判断当前招聘会的状态
  //判断招聘会是否允许报名以及是否允许进入会场
  getApplyStatus(data,res){
    let timeNow=new Date().getTime();
    let time= new Date(data).getTime();
    let timeOne=new Date(res).getTime();
    // //console.log(timeNow);
    // //console.log(time);
    // //console.log(timeOne);
    if(timeNow-time>0){
      this.isMilkStart=true;
      this.isApplySign=false;//报名已截止
       if(timeOne-timeNow<0){
          this.isMilkEnd=true;//招聘会已结束
       }
    }else{
      this.isMilkStart=false;
    }
    //增加是否是活动判断
    if(this.milk.milkround_type == 2){
      if(timeOne-timeNow>0){
        this.isApplySign=true;
      }else{
        this.isApplySign=false;
      }
    }
  }

  // 获取高校信息
  getCollageDetails(){
    this.opera.loading();
    this.dataApi.getCollageDetails(this.local.get(Constants.collegeid)).then((res:any)=>{
      console.log("获取到高校信息",res);
      if(res){}
      this.opera.loadiss();
    }).catch((error)=>{
      // console.log(error);
      this.toastr.error(error);
      this.opera.loadiss();
    })
  }

  /**
   * 下载附件
   */
  downLoadGfile(){

    if( this.DownLoadUrl1!='')
    {
      this.DownLoadUrlArr.push({link:this.DownLoadUrl1})
    }
    if(this.DownLoadUrlArr.length==0)
    {
      this.toastr.error('暂无附件');
      return;
    }
    console.log("downLoadGfile", this.DownLoadUrlArr)
    for (let a=0;a<this.DownLoadUrlArr.length;a++)
    {
      let item = this.DownLoadUrlArr[a];
      setTimeout(()=>{
        let item = this.DownLoadUrlArr[a];
        let arr = item.link.split('.');
        if(arr[arr.length-1]=='png'||arr[arr.length-1]=='jpg'||arr[arr.length-1]=='jpeg'||arr[arr.length-1]=='gif'||arr[arr.length-1]=='pdf')
        {
           window.open(item.link)
        }
        else
        {
          window.location.href =item.link;

        }
      },1000*a );
    }
  }




}
