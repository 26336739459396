<!-- 添加企业地址 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>
    <div class="model-cont">
        <!-- 上层提示部分 -->
        <div class="model-head clear">
            <div class="left">{{title}}</div>
            <button class="right" (click)="dismiss(false)">×</button>
        </div>
        <div class="address-block">
          <div class="address-select">
              <select name="city-milk" id="city-milk" autocomplete="off" [(ngModel)]="cityOne" (ngModelChange)="cityOneChange()">
                  <option class="place-text" value='' disabled hidden selected="selected">选择省</option>
                  <option *ngFor="let city of listOne" [value]="city.code">{{city.text}}</option>
              </select>
              <select name="city-milk" id="city-milk" autocomplete="off" [(ngModel)]="cityTwo" (click)="isShowTwo()" (ngModelChange)="cityTwoChange()">
                  <option class="place-text" value='' disabled hidden selected="selected">选择市</option>
                  <option *ngFor="let city of listTwo" [value]="city.code">{{city.text}}</option>
              </select>
              <select name="city-milk" id="city-milk" autocomplete="off" [(ngModel)]="locations.region" (click)="isShowThree()" (ngModelChange)="cityThreeChange()">
                  <option class="place-text" value='' disabled hidden selected="selected">选择县/区</option>
                  <option *ngFor="let city of listThree" [value]="city.code">{{city.text}}</option>
              </select>
          </div>
          <div class="address-input"><input type="text" [(ngModel)]="locations.address" placeholder="请填写公司详细地址"></div>
        </div>
        <!-- 底部按钮 -->
        <div class="select-submit"><button class="purple" (click)="employerUpdatePositionInfo()">保存</button></div>
    </div>
</div>
