import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Config, Constants, DataApi, Local, Opera } from "../../provider";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ModelStillMoreComponent } from "../model-still-more/model-still-more.component";


declare let tvp;
declare let layui;
declare let ActiveXObject;
declare let VSwf;
declare var layer;
declare let window;
declare let Txplayer;

@Component({
  selector: 'app-creat-air-campus',
  templateUrl: './creat-air-campus.component.html',
  styleUrls: ['./creat-air-campus.component.css']
})
export class CreatAirCampusComponent implements OnInit {

  @ViewChild('child1', { static: true }) child: ModelStillMoreComponent;

  public isDisabled = false;

  public config: Config;
  @Output() modelMajorShow = false;//是否打开弹窗
  public myDepartment: any;//当前登录的企业信息(department)

  public positionList = [];
  public airCampustalk: any;
  public positionmodel = {
    status: 'release',
    name: '',
    offset: 0,
    limit: 999
  }
  public model = {
    title: {
      value: '',
      err: ""
    },
    desc: {
      value: '',
      err: ""
    },
    QuestionList: [{ question: '', answer: '', titleErr: '', valueErr: "", airquestionid: '', acid: '' }],
    majors: [],//专业要求
    video_cover: { value: '', err: '' },
    video_url: { value: '', err: '' },
    phone: { value: "", open: true, err: '' },
    mobile: { value: "", open: true, err: '' },
    wx: { value: "", open: true, err: '' },
    email: { value: "", open: true, err: '' },
    department_name: '',

  }
  public t_positionids = [];
  public mianzeModel = false;

  constructor(
    public dataApi: DataApi,
    public local: Local,
    private router: Router,
    public opera: Opera,
    public toastr: ToastrService,
  ) {
    this.config = Config.getInstance();//实例化config

  }

  ngOnInit() {

    // this.opera.initConfig('college','college-campustalk');
    // this.opera.initConfig('college','college-details-campus');

    this.opera.initConfig('college', 'airCampusDetail');
    this.getMajorList();
    this.getDepartment();

    let creatAirCampData = this.local.get("creatAirCampData")
    let collegeid = localStorage.getItem('collegeid')

    this.dataApi.getAirCampustalk(collegeid).then((res: any) => {
      if (res) {
        this.airCampustalk = res;
        if (this.airCampustalk.title == null) {
          this.airCampustalk.title = "";
        }
        this.model.title.value = this.airCampustalk.title;
        this.model.desc.value = this.airCampustalk.desc;
        this.model.video_cover.value = this.airCampustalk.video_cover;
        this.model.video_url.value = this.airCampustalk.video_url;
        this.model.majors = this.airCampustalk.major_codes == null ? [] : this.airCampustalk.major_codes;
        if (this.airCampustalk.wx) {
          this.model.wx.value = this.airCampustalk.wx.value;
          this.model.wx.open = this.airCampustalk.wx.is_public;
        }

        if (this.airCampustalk.email) {
          this.model.email.value = this.airCampustalk.email.value;
          this.model.email.open = this.airCampustalk.email.is_public;
        }
        if (this.airCampustalk.mobile) {
          this.model.mobile.value = this.airCampustalk.mobile.value;
          this.model.mobile.open = this.airCampustalk.mobile.is_public;
        }
        if (this.airCampustalk.phone) {
          this.model.phone.value = this.airCampustalk.phone.value;
          this.model.phone.open = this.airCampustalk.phone.is_public;
        }
        this.t_positionids = this.airCampustalk.positionids == null ? [] : this.airCampustalk.positionids;

        if (res.college_audit_status == 2) {
          this.isDisabled = true;
        }
        if (this.airCampustalk.title.length > 0 && this.model.majors.length == 0) {
          this.model.majors = ['808080']
        }


        this.getAirQuestion();
        this.getPositionList();

        // if(this.airCampustalk.reasons!=null)
        // {
        //   for (let n of this.airCampustalk.reasons)
        //   {
        //     if(n.name=='desc') {this.model.desc.err = n.desc;}
        //     if(n.name=='title') {this.model.title.err = n.desc;}
        //     if(n.name=='video_url') {this.model.video_url.err = n.desc;}
        //     if(n.name=='video_cover') {this.model.video_cover.err = n.desc;}
        //   }
        // }
        if (creatAirCampData) {
          this.model = JSON.parse(creatAirCampData);
        }
      }
    }).catch(() => {
    })
  }
  getPositionList() {
    this.opera.loading();
    this.dataApi.getPositionList(this.positionmodel).then((res: any) => {
      if (res && res != null) {
        let newList = []
        res.list.map((item) => {
          item.selete = true;
          if (this.t_positionids.length > 0) {
            if (this.t_positionids.indexOf(item.positionid) >= 0) {
              item.selete = true;
            } else {
              item.selete = false;
            }
          }
          if (!item.need_perfect) {
            newList.push(item);
          }
        })
        this.positionList = newList;
      }
      this.opera.loadiss();
    }).catch((error) => {
      this.toastr.error(error);
      this.opera.loadiss();
    });
  }
  positionClick(n) {

    let newList = this.positionList.map((item) => {
      if (n.positionid == item.positionid)
        item.selete = !n.selete;
      return item;
    })
    this.positionList = newList;
  }
  creatPositionClick() {

    this.dataApi.getDepartment().then((res: any) => {
      this.opera.loadiss();
      if (res) {
        this.dataApi.setLocalDepartment(res);
        if (res.quota.position == 0) {
          this.toastr.warning("今日发布职位数量已达上限");
          return;
        } else {
          this.local.remove(Constants.positionid);
          this.local.set('creatAirCampData', JSON.stringify(this.model))
          this.router.navigate(['home/position-publish'], { queryParams: { backRouter: 'home/creatAirCampus' } });
        }
      }
    }).catch((error) => {
      this.opera.loadiss();
      this.toastr.error(error);
    });
  }
  //选择意向专业
  goMajor() {

    this.modelMajorShow = true;
    this.opera.scrollTop();//页面滚动
    //调用子组件函数
    console.log("majors", this.model.majors, this.config.major)
    this.child.initData(this.model.majors, this.config.major, 5, "选择专业");
  }
  //接收子组件返回数据
  Modeldismiss(event) {
    this.modelMajorShow = false;
    this.opera.scrollTrue();
    if (event) {
      this.model.majors = event;

    }
    console.log('接收子组件返回数据', event);
  }
  //获取专业列表信息
  getMajorList() {
    if (this.dataApi.getMajorCode()) {
      this.config.major = this.dataApi.getMajorCode();
      this.config.major.push({
        code: "8080",
        parentcode: "0",
        parenttext: null,
        text: "不限",
        level: 1,
        order: 1234,
        relation_code: "",
        relation_desc: ""
      })
      this.config.major.push({
        code: "808080",
        parentcode: "8080",
        parenttext: null,
        text: "不限专业",
        level: 2,
        order: 1235,
        relation_code: "",
        relation_desc: ""
      })
    } else {
      this.opera.loading();
      this.dataApi.getDictDate('major').then((res: any) => {
        //console.log("获取专业列表-----college-resume");
        //console.log(res);
        this.opera.loadiss();
        this.config.major = res;
        this.dataApi.setMajorCode(res);
        this.config.major.push({
          code: "8080",
          parentcode: "0",
          parenttext: null,
          text: "不限",
          level: 1,
          order: 1234,
          relation_code: "",
          relation_desc: ""
        })
        this.config.major.push({
          code: "808080",
          parentcode: "8080",
          parenttext: null,
          text: "不限专业",
          level: 2,
          order: 1235,
          relation_code: "",
          relation_desc: ""
        })
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
      });
    }
  }
  //上传logo
  previewPic(event) {
    let imgData = event.target.files[0]; //获取file对象
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e: any) {
        that.uploadDepImg(reader.result);
      };

    }
  }
  //上传图片
  uploadDepImg(data) {
    let imageData = this.dataApi.formatImage(data);
    this.opera.loading();
    this.dataApi.uploadDepartmentImg({ value: imageData }).then((res: any) => {
      this.opera.loadiss();
      this.model.video_cover.value = res.value;
      this.model.video_cover.err = "";
    }).catch((error) => {
      this.opera.loadiss();
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    })
  }
  changeOpen(key, isOpen) {
    this.model[key].open = isOpen;

  }
  inputChange(e, key) {
    this.model[key] = e;
  }
  inputChangeWithOpen(e, key) {
    if (!key) {
      return;
    }
    this.model[key].value = e;
    this.model[key].err = "";
    if (key == "video_url" && e != '') {
      let videEX = "https://v.qq.com/x/page";
      if (e.indexOf(videEX) < 0) {
        this.model.video_url.err = "视频链接非腾讯视频链接"
        return;
      }
    }
    // this.setVideoTime(e);

  }
  setVideoTime(url) {
    let that = this;
    if (url) {
      let video_url = url;
      let video_urlArr = video_url.split("/");
      let lastStr = video_urlArr[video_urlArr.length - 1];
      let newArr = lastStr.split(".");


      var video = new tvp.VideoInfo();
      video.setVid(newArr[0]);
      var player = new tvp.Player();

      player.create({
        width: 0,
        height: 0,
        video: video,
        autoplay: false,
        modId: "mod_player",
        onwrite: function () {
          // alert("播放器显示完毕");
          console.log('播放器显示完毕video=', newArr[0], video, player)

          for (var i in video) {
            console.log('键：' + i)
            console.log('值：', video[i])
          }

          // console.log('播放器显示完毕video.a=',video.a,)
          //http://vv.video.qq.com/getinfo?vids=a30662p0khi
        },

      });


      // let url1 = "http://vv.video.qq.com/getinfo?vids=x0164ytbgov&charge=0&otype=json";



    }


  }
  //获取企业信息
  getDepartment() {
    if (this.dataApi.getLocalDepartment()) {
      this.myDepartment = this.dataApi.getLocalDepartment();
      this.model.department_name = this.myDepartment.name
    } else {
      this.dataApi.getDepartment().then((res: any) => {//通过tooken获取企业信息
        if (res) {
          this.myDepartment = res;
          this.model.department_name = this.myDepartment.name
          this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
        }
      }).catch((error) => {
        this.toastr.error(error);
      });
    }
  }
  //提交
  commitBtnClick() {

    if (!this.airCampustalk) {
      return;
    }
    console.log("QuestionList", this.model.QuestionList)


    let qustions = [];
    let updatequstions = [];

    for (let n of this.model.QuestionList) {
      if (!n.question && !n.answer) {
        break;
      }
      if (!n.question || !n.answer) {
        this.toastr.warning('设置常见问题或回答为空')
        return;
      }
      if (n.airquestionid.length > 0) {
        let creatAirQuestiondata = {
          question: n.question,
          answer: n.answer,
          acid: this.airCampustalk.acid,
          airquestionid: n.airquestionid
        }
        updatequstions.push(creatAirQuestiondata)
      }
      else {
        let creatAirQuestiondata = {
          question: n.question,
          answer: n.answer,
          acid: this.airCampustalk.acid
        }
        qustions.push(creatAirQuestiondata)
      }
    }


    if (!this.model.title.value) {
      this.model.title.err = "请填写宣讲主题"; return;
    }
    if (!this.model.desc.value) {
      this.model.desc.err = "请填写宣讲介绍"; return;
    }
    if (!this.model.video_url.value) {
      this.model.video_url.err = "请填写视频链接"; return;
    }
    if (!this.model.video_cover.value) {
      this.model.video_cover.err = "请上传视频封面"; return;
    }

    if (!this.model.mobile.value) {
      this.model.mobile.err = "请填写联系电话"; return;
    }
    if (!this.model.phone.value) {
      this.model.phone.err = "请填写电话"; return;
    }
    if (!this.model.wx.value) {
      this.model.wx.err = "请填写微信"; return;
    }
    if (!this.model.email.value) {
      this.model.email.err = "请填写邮箱"; return;
    }

    console.log('职位列表', this.positionList)
    let videEX = "https://v.qq.com/x/page";
    let video_url = this.model.video_url.value;
    console.log('职位列表', video_url.indexOf(videEX))

    if (video_url.indexOf(videEX) < 0) {
      this.toastr.warning('视频链接非腾讯视频链接')
      this.model.video_url.err = "视频链接非腾讯视频链接"
      return;
    }

    // 用户上传的URL是这样的：https://v.qq.com/x/page/a30662p0khi.html

    let positionids = [];

    for (let posi of this.positionList) {
      if (posi.selete) {
        positionids.push(posi.positionid)
      }
    }
    let major_codes = this.model.majors;
    if (major_codes.length == 0) {
      this.toastr.warning('请选择意向专业')
      return;
    }
    if (positionids.length == 0) {
      this.toastr.warning('请选择职位')
      return;
    }

    if (this.model.majors.indexOf("808080") >= 0) {
      major_codes = [];
    }
    console.log('808080', this.model.majors.indexOf("808080"))

    let postData = {
      acid: this.airCampustalk.acid,
      title: this.model.title.value,
      department_name: this.model.department_name,
      desc: this.model.desc.value,
      positionids: positionids,
      major_codes: major_codes,
      video_url: this.model.video_url.value,
      video_cover: this.model.video_cover.value,
      mobile: {
        value: this.model.mobile.value,
        is_public: this.model.mobile.open
      },
      phone: {
        value: this.model.phone.value,
        is_public: this.model.phone.open
      },
      email: {
        value: this.model.email.value,
        is_public: this.model.email.open
      },
      wx: {
        value: this.model.wx.value,
        is_public: this.model.wx.open
      },
      minute: 0
    }
    console.log(postData)
    if (this.isDisabled) {
      this.dataApi.updateCampustalk(postData).then((res: any) => {//通过tooken获取企业信息
        console.log('updateCampustalk');
        this.toastr.success('修改成功')
        let that = this;
        setTimeout(() => {
          that.router.navigateByUrl("home/airCampusDetail");
        }, 1000)
      }).catch((error) => {
        this.toastr.error(error);
      });
      return;
    } else {
      this.mianzeModel = true;
      return;
    }
  }

  creatProblemHTTP(qustions) {
    this.dataApi.creatAirQuestion(qustions).then((res: any) => {//通过tooken获取企业信息
      console.log('creatAirQuestion', res);
    }).catch((error) => {
      this.toastr.error(error);
    });
  }
  updateProblemHTTP(qustions) {
    this.dataApi.updateAirQuestion(qustions).then((res: any) => {//通过tooken获取企业信息
      console.log('updateProblemHTTP', res, qustions);
    }).catch((error) => {
      this.toastr.error(error);
    });
  }
  getAirQuestion() {
    let data = {
      acid: this.airCampustalk.acid,
      type: '1',
      isAnswer: true
    }
    this.dataApi.getAirQuestion(data).then((res: any) => {
      if (res) {
        let arr = [];
        let resArr = res.list;
        for (let index = 0; index < resArr.length; index++) {
          let item = resArr[index];
          let newitem = { question: item.question, answer: item.answer, titleErr: '', valueErr: "", "airquestionid": item.id, acid: this.airCampustalk.acid }
          arr.push(newitem);
        }
        if (arr.length > 0) {
          this.model.QuestionList = arr;
        }
        console.log('getAirQuestion', res)
      }
    }).catch(() => {
    })
  }
  addProblem() {
    if (this.isDisabled) {
      return;
    }
    if (this.model.QuestionList.length == 10) {
      this.toastr.warning('设置常见问题已上限')
      return;
    }

    this.model.QuestionList = [...this.model.QuestionList, { question: '', answer: '', titleErr: '', valueErr: "", "airquestionid": "", acid: this.airCampustalk.acid }]
    console.log(this.model.QuestionList)
  }
  deleteProblem(n, index) {
    if (this.isDisabled) {
      return;
    }
    let newArr = [];
    for (let n = 0; n < this.model.QuestionList.length; n++) {
      if (n != index) {
        newArr.push(this.model.QuestionList[n])
      }
    }
    this.model.QuestionList = newArr;

    if (n.airquestionid.length > 0) {
      //删除问题
      //deleteAirQuestion
      this.dataApi.deleteAirQuestion(n.airquestionid).then((res: any) => {//通过tooken获取企业信息
        console.log('deleteAirQuestion', res, n.airquestionid);
      }).catch((error) => {
        this.toastr.error(error);
      });
    }

  }
  inputProblem(e, n) {

    console.log(e, n);
  }
  gowoUploadChip() {
    window.open("http://activity.goworkla.cn/uploadtxvideo/", '_blank')
  }
  //免责的提交
  commitMianzeBtnClick(isCommit) {
    if (!isCommit) {
      this.mianzeModel = false;
      return;
    }


    let qustions = [];
    let updatequstions = [];

    for (let n of this.model.QuestionList) {
      if (!n.question && !n.answer) {
        break;
      }
      if (!n.question || !n.answer) {
        this.toastr.warning('设置常见问题或回答为空')
        return;
      }
      if (n.airquestionid.length > 0) {
        let creatAirQuestiondata = {
          question: n.question,
          answer: n.answer,
          acid: this.airCampustalk.acid,
          airquestionid: n.airquestionid
        }
        updatequstions.push(creatAirQuestiondata)
      }
      else {
        let creatAirQuestiondata = {
          question: n.question,
          answer: n.answer,
          acid: this.airCampustalk.acid
        }
        qustions.push(creatAirQuestiondata)
      }
    }
    if (!this.model.title.value) {
      this.model.title.err = "请填写宣讲主题"; return;
    }
    if (!this.model.desc.value) {
      this.model.desc.err = "请填写宣讲介绍"; return;
    }
    if (!this.model.video_url.value) {
      this.model.video_url.err = "请填写视频链接"; return;
    }
    if (!this.model.video_cover.value) {
      this.model.video_cover.err = "请上传视频封面"; return;
    }

    if (!this.model.mobile.value) {
      this.model.mobile.err = "请填写联系电话"; return;
    }
    if (!this.model.phone.value) {
      this.model.phone.err = "请填写电话"; return;
    }
    if (!this.model.wx.value) {
      this.model.wx.err = "请填写微信"; return;
    }
    if (!this.model.email.value) {
      this.model.email.err = "请填写邮箱"; return;
    }

    console.log('职位列表', this.positionList)
    let videEX = "https://v.qq.com/x/page";
    let video_url = this.model.video_url.value;
    console.log('职位列表', video_url.indexOf(videEX))

    if (video_url.indexOf(videEX) < 0) {
      this.toastr.warning('视频链接非腾讯视频链接')
      this.model.video_url.err = "视频链接非腾讯视频链接"
      return;
    }

    // 用户上传的URL是这样的：https://v.qq.com/x/page/a30662p0khi.html

    let positionids = [];

    for (let posi of this.positionList) {
      if (posi.selete) {
        positionids.push(posi.positionid)
      }
    }
    let major_codes = this.model.majors;
    if (major_codes.length == 0) {
      this.toastr.warning('请选择意向专业')

      return;
    }
    if (positionids.length == 0) {
      this.toastr.warning('请选择职位')
      return;
    }


    if (this.model.majors.indexOf("808080") >= 0) {
      major_codes = [];
    }
    console.log('808080', this.model.majors.indexOf("808080"))
    let postData = {
      acid: this.airCampustalk.acid,
      title: this.model.title.value,
      department_name: this.model.department_name,
      desc: this.model.desc.value,
      positionids: positionids,
      major_codes: major_codes,
      video_url: this.model.video_url.value,
      video_cover: this.model.video_cover.value,
      mobile: {
        value: this.model.mobile.value,
        is_public: this.model.mobile.open
      },
      phone: {
        value: this.model.phone.value,
        is_public: this.model.phone.open
      },
      email: {
        value: this.model.email.value,
        is_public: this.model.email.open
      },
      wx: {
        value: this.model.wx.value,
        is_public: this.model.wx.open
      },
      minute: 0

    }
    console.log(postData)

    let collegeid = localStorage.getItem('collegeid')
    this.dataApi.commitCampustalk(collegeid, postData).then((res: any) => {//通过tooken获取企业信息

      console.log('commitCampustalk', res, postData);
      this.local.remove('creatAirCampData');
      if (qustions.length > 0) {
        this.creatProblemHTTP(qustions);
      }
      if (updatequstions.length > 0) {
        this.updateProblemHTTP(updatequstions);
      }
      this.toastr.success('提交成功')
      let that = this;
      setTimeout(() => {
        that.router.navigateByUrl("home/airCampusDetail");
      }, 1000)
    }).catch((error) => {
      this.toastr.error(error);
    });




  }

}
