import { NgModule }from '@angular/core';
import { BrowserModule }  from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy,APP_BASE_HREF,LocationStrategy, PathLocationStrategy } from '@angular/common';

import { FormsModule }   from '@angular/forms';
// import { IonicModule, Events } from 'ionic-angular';
import { Events } from '../provider/eventService';
import { MatDialogModule } from '@angular/material/dialog';//确认操作弹窗
import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';
import {CalendarModule} from 'primeng/calendar';
import { ImageCropperModule } from "ngx-img-cropper";
import { QRCodeModule } from 'angular2-qrcode';
import { NgxNeditorModule } from '@notadd/ngx-neditor';
import { DialogModule } from 'primeng/dialog';


import {MessageService} from 'primeng/api';

// 服务
import { Api,DataApi,Config,Local,Opera,DataManager,FormatDate,Eventemit,MyMMessage,NotEmitTool,milkround} from '../provider/index';
import { AppRoutingModule }   from './app-routes';
// 管道
import { NameImg,TextFormat,TextLight,CodeTransform,CodeMoreTransform,NoticeTransform,SalaryFilter,TimeTransform,OnlineTime,NumTransform,AgeTransform,HeightTransform,ConTimeTransform } from '../pipe/index';
 //组件部分
import { AppComponent }   from './app.component';
import { LoginComponent }  from './login/login.component';
import { HomeComponent }  from './home/home.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { GuideComponent } from './guide/guide.component';
import { IdentityComponent } from './identity/identity.component';
import { IdentityStatusComponent } from './identity-status/identity-status.component';
import { HomepageComponent } from './homepage/homepage.component';
import { CollegeResumeComponent } from './college-resume/college-resume.component';
import { PagingComponent } from './paging/paging.component';
import { CollegeCampustalkComponent } from './college-campustalk/college-campustalk.component';
import { CollegeMilkroundComponent } from './college-milkround/college-milkround.component';
import { ChatContactComponent } from './chat-contact/chat-contact.component';
import { ChatCollectionComponent } from './chat-collection/chat-collection.component';
import { ChatLookComponent } from './chat-look/chat-look.component';
import { CollegeResumedetailsComponent } from './college-resumedetails/college-resumedetails.component';
import { CollegeDetailsComponent } from './college-details/college-details.component';
import { ModelCollegeIdentityComponent } from './model-college-identity/model-college-identity.component';
import { CollegeCampusSignComponent } from './college-campus-sign/college-campus-sign.component';
import { ModelStillMoreComponent } from './model-still-more/model-still-more.component';
import { CollegeMilkdetailsComponent } from './college-milkdetails/college-milkdetails.component';
import { ModelCollegeMilksignComponent } from './model-college-milksign/model-college-milksign.component';
import { CompanyIdentityComponent } from './company-identity/company-identity.component';
import { ModelCompanyIdentityComponent } from './model-company-identity/model-company-identity.component';
import { PositionResumeComponent } from './position-resume/position-resume.component';
import { ModelDeliveryResumeComponent } from './model-delivery-resume/model-delivery-resume.component';
import { ModelCollectionResumeComponent } from './model-collection-resume/model-collection-resume.component';
import { PositionNoticeComponent } from './position-notice/position-notice.component';
import { PositionHiredComponent } from './position-hired/position-hired.component';
import { PositionCampusComponent } from './position-campus/position-campus.component';
import { PositionMilkComponent } from './position-milk/position-milk.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CompanyAccountComponent } from './company-account/company-account.component';
import { ModelSelectMoreComponent } from './model-select-more/model-select-more.component';
import { ModelSelectTwoComponent } from './model-select-two/model-select-two.component';
import { ModelPositionTemplateComponent } from './model-position-template/model-position-template.component';
import { ModelHiredDepartmentComponent } from './model-hired-department/model-hired-department.component';
import { ModelHiredTalentComponent } from './model-hired-talent/model-hired-talent.component';
import { ModelAddressAddComponent } from './model-address-add/model-address-add.component';
import { CompanyDetailsAddressComponent } from './company-details-address/company-details-address.component';
import { ModelCompanyLabelComponent } from './model-company-label/model-company-label.component';
import { ModelCompanyProductComponent } from './model-company-product/model-company-product.component';
import { ModelCompanyCoursesComponent } from './model-company-courses/model-company-courses.component';
import { ModelCompanyPhoneComponent } from './model-company-phone/model-company-phone.component';
import { ModelChatCommonComponent } from './model-chat-common/model-chat-common.component';
import { ModelChatPositionComponent } from './model-chat-position/model-chat-position.component';
import { ModelPositionHiredComponent } from './model-position-hired/model-position-hired.component';
import { ModelPositionNoticeComponent } from './model-position-notice/model-position-notice.component';
import { CollegeMilkSpaceComponent } from './college-milk-space/college-milk-space.component';
import { CollegeDetailsOrgComponent } from './college-details-org/college-details-org.component';
import { CollegeDetailsMajorComponent } from './college-details-major/college-details-major.component';
import { CollegeDetailsMilkComponent } from './college-details-milk/college-details-milk.component';
import { CollegeDetailsCampusComponent } from './college-details-campus/college-details-campus.component';
import { CollegeDetailsNewsComponent } from './college-details-news/college-details-news.component';
import { CollegeDetailsNewstxtComponent } from './college-details-newstxt/college-details-newstxt.component';
import { CollegeDetailsStudentComponent } from './college-details-student/college-details-student.component';
import { ModelChatReportComponent } from './model-chat-report/model-chat-report.component';
import { PositionManageComponent } from './position-manage/position-manage.component';
import { PositionPublishComponent } from './position-publish/position-publish.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PositionCalendarComponent } from './position-calendar/position-calendar.component';
import { ModelPositionMoreComponent } from './model-position-more/model-position-more.component';
import { CompanyIdentityDetailsComponent } from './company-identity-details/company-identity-details.component';
import { CollegeMilkSignComponent } from './college-milk-sign/college-milk-sign.component';
import { ModelStillMilkComponent } from './model-still-milk/model-still-milk.component';
import { GuideCompanyComponent } from './guide-company/guide-company.component';
import { ModelCompanyStrengthComponent } from './model-company-strength/model-company-strength.component';
import { ModelCropperComponent } from './model-cropper/model-cropper.component';
import { PositionRulesComponent } from './position-rules/position-rules.component';
import { PositionRulesDetailsComponent } from './position-rules-details/position-rules-details.component';
import { PositionRulesEditorComponent } from './position-rules-editor/position-rules-editor.component';
import { CompanyAuthenticationComponent } from './company-authentication/company-authentication.component';
import { ModelAuthExplainComponent } from './model-auth-explain/model-auth-explain.component';
import { ModelAuthAgreenmentComponent } from './model-auth-agreenment/model-auth-agreenment.component';
import { CompanyPayComponent } from './company-pay/company-pay.component';
import { CompanyStatusOneComponent } from './company-status-one/company-status-one.component';
import { CompanyStatusTwoComponent } from './company-status-two/company-status-two.component';
import { ModelPayWechatComponent } from './model-pay-wechat/model-pay-wechat.component';
import { CompanyStatusThreeComponent } from './company-status-three/company-status-three.component';
import { EnsureComponent } from './ensure/ensure.component';
import { CompanyStatusFourComponent } from './company-status-four/company-status-four.component';
import { CollegeDetailsPreachComponent } from './college-details-preach/college-details-preach.component';
import { ConfirmsComponent } from './confirms/confirms.component';
import { FindPswComponent } from './find-psw/find-psw.component';
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { AirCampHistoryComponent } from './air-camp-history/air-camp-history.component';
import { CreatAirCampusComponent } from './creat-air-campus/creat-air-campus.component';
import { AirCampusDetailComponent } from './air-campus-detail/air-campus-detail.component';
import { AirCampHistoryDetailComponent } from './air-camp-history-detail/air-camp-history-detail.component';
import { LogoutAccountModelComponent } from './logout-account-model/logout-account-model.component';
import { HttpErrorModelComponent } from './http-error-model/http-error-model.component';
import { CollegeidentityComponent } from './collegeidentity/collegeidentity.component';
import { CollegeMilkHistoryComponent } from './college-milk-history/college-milk-history.component';
import { FindAccoundComponent } from './find-accound/find-accound.component'

//declarations —— 该应用所拥有的组件。
//imports —— 导入 BrowserModule 以获取浏览器特有的服务，比如 DOM 渲染、无害化处理和位置（location）。
//providers —— 各种服务提供商。
//bootstrap —— 根组件，Angular 创建它并插入 index.html 宿主页面。

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ImageCropperModule,
    NgxNeditorModule,
    MatDialogModule,
    QRCodeModule,
    DialogModule,
    FormsModule,
    LoadingModule.forRoot({
      animationType: ANIMATION_TYPES.threeBounce,//circleSwish.threeBounce
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#5d479a',
      secondaryColour: '#5d479a',
      tertiaryColour: '#5d479a'
    }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
    }),
    HttpClientModule,
    CalendarModule
  ],
  declarations: [
    NameImg,
    TextFormat,
    TextLight,
    CodeTransform,
    CodeMoreTransform,
    ConTimeTransform,
    NoticeTransform,
    SalaryFilter,
    TimeTransform,
    OnlineTime,
    NumTransform,
    AgeTransform,
    HeightTransform,
    AppComponent,
    HomepageComponent,
    CollegeResumeComponent,
    HomeComponent,
    PagingComponent,
    CollegeCampustalkComponent,
    CollegeMilkroundComponent,
    ChatContactComponent,
    LoginComponent,
    ConfirmComponent,
    GuideComponent,
    CollegeDetailsComponent,
    ModelCollegeIdentityComponent,
    CollegeCampusSignComponent,
    ModelStillMoreComponent,
    CollegeMilkdetailsComponent,
    ModelCollegeMilksignComponent,
    ModelCompanyIdentityComponent,
    PositionResumeComponent,
    ModelDeliveryResumeComponent,
    ModelCollectionResumeComponent,
    PositionNoticeComponent,
    PositionHiredComponent,
    PositionCampusComponent,
    PositionMilkComponent,
    CompanyDetailsComponent,
    CompanyAccountComponent,
    ModelSelectMoreComponent,
    ModelSelectTwoComponent,
    ModelPositionTemplateComponent,
    ModelHiredDepartmentComponent,
    ModelHiredTalentComponent,
    ModelAddressAddComponent,
    CompanyDetailsAddressComponent,
    ModelCompanyLabelComponent,
    ModelCompanyProductComponent,
    ModelCompanyCoursesComponent,
    ModelCompanyPhoneComponent,
    ModelChatCommonComponent,
    ModelChatPositionComponent,
    ModelPositionHiredComponent,
    ModelPositionNoticeComponent,
    CollegeMilkSpaceComponent,
    CollegeDetailsOrgComponent,
    CollegeDetailsMajorComponent,
    CollegeDetailsMilkComponent,
    CollegeDetailsCampusComponent,
    CollegeDetailsNewsComponent,
    CollegeDetailsNewstxtComponent,
    CollegeDetailsStudentComponent,
    ModelChatReportComponent,
    PositionManageComponent,
    PositionPublishComponent,
    NotFoundComponent,
    PositionCalendarComponent,
    ModelPositionMoreComponent,
    CompanyIdentityDetailsComponent,
    CollegeMilkSignComponent,
    ModelStillMilkComponent,
    GuideCompanyComponent,
    ModelCompanyStrengthComponent,
    ModelCropperComponent,
    PositionRulesComponent,
    PositionRulesDetailsComponent,
    PositionRulesEditorComponent,
    CompanyAuthenticationComponent,
    ModelAuthExplainComponent,
    ModelAuthAgreenmentComponent,
    CompanyPayComponent,
    CompanyStatusOneComponent,
    CompanyStatusTwoComponent,
    ModelPayWechatComponent,
    CompanyStatusThreeComponent,
    CompanyIdentityComponent,
    CompanyStatusFourComponent,
    CollegeDetailsPreachComponent,
    ConfirmsComponent,
    FindPswComponent,
    EnsureComponent,
    IdentityComponent,
    AirCampHistoryComponent,
    CreatAirCampusComponent,
    AirCampusDetailComponent,
    AirCampHistoryDetailComponent,
    LogoutAccountModelComponent,
    HttpErrorModelComponent,
    CollegeidentityComponent,
    CollegeMilkHistoryComponent,
    FindAccoundComponent,
    IdentityStatusComponent,
    ChatCollectionComponent,
    ChatLookComponent,
    CollegeResumedetailsComponent,
    NotificationCenterComponent
  ],
  //服务中使用
  // entryComponents: [
  //   ConfirmComponent,
  //   ConfirmsComponent,
  //   EnsureComponent,
  //   LogoutAccountModelComponent,
  //   HttpErrorModelComponent
  // ],
  providers:[
    Events,
    Config,
    Api,
    Local,
    DataApi,
    Opera,
    DataManager,
    FormatDate,
    MyMMessage,
    Eventemit,
    MessageService,
    NotEmitTool,
    milkround,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: APP_BASE_HREF, useValue: '/' }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
