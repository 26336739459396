<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">注销当前账号</h4>
      <button type="button" class="close" mat-dialog-close>×</button>
    </div>
    <div class="modal-body">

      <div class="sign-list flex_l">
        <span>注销手机号：</span><input type="text" disabled [(ngModel)]="model.phone" placeholder="请填写手机号">
      </div>
      <div class="sign-list flex_l">
        <span>图形验证码：</span><input style="width: 235px" type="text" [(ngModel)]="model.imageCode" placeholder="请填写图片验证码">
        <div class="flex_l" style="width: 150px" (click)="getCode()"><img style="width: 150px;margin-left: 15px;height: 36px" *ngIf="imgCode" [src]="imgCode"><img *ngIf="!imgCode" src="assets/images/login-img-code.png"></div>
      </div>

      <div class="sign-list flex_l">
        <span>验证码：</span><input style="width: 235px" type="text" [(ngModel)]="model.code" placeholder="请填写短信验证码">
        <button class="emailBtn" (click)="sendPhoneCode()">{{sendEmailText2}}</button>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" [mat-dialog-close]="false" >取消</button>
      <button type="button" class="btn btn-primary" (click)="commit()" >确定</button>
    </div>
  </div>
</div>
