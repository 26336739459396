import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'textlight'})
export class TextLight implements PipeTransform {
  constructor(public _sanitizer: DomSanitizer){

  }  
  transform(_content,_one) {    
    if (_content) {
      if (_one) {
          let reg = new RegExp(_one, 'gi');            
          _content = _content.replace(reg, '<span style="color:#2ea1fa;">' + _one + '</span>');          
          return this._sanitizer.bypassSecurityTrustHtml(_content);
      } else {
        return this._sanitizer.bypassSecurityTrustHtml(_content);
      }
    }
  }
}