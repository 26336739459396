import { Component, OnInit, ChangeDetectorRef, NgZone, Output } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { DataApi, Local, Constants, Config, Opera, NotEmitTool } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Events } from '../../provider/eventService';
import { MatDialog } from '@angular/material/dialog';

import { DatePipe } from "@angular/common";


// import {AppModule}from '../app.module'
import { MessageService } from 'primeng/api';

import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized, NavigationStart } from '@angular/router';
import { ConfirmComponent } from "../confirm/confirm.component";
import { $ } from "protractor";
import { HttpErrorModelComponent } from "../http-error-model/http-error-model.component";
declare let window;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [DatePipe],
})
export class HomeComponent implements OnInit {
  public config: Config;
  public isGoCollege = false;   //当前是否激活校园招聘导航
  public isGoChat = false;     //当前是否激活消息中心导航
  public isGoPosition = false; //当前是否激活招聘管理导航
  public isGoCompany = false; //当前是否激活企业中心导航
  public departmentJm = '';//企业极光号
  public isSendDepartment = false;
  public msgNumber1 = 0;
  public msgNumber2 = 0;
  public msgNumber3 = 0;
  public msgNumber4 = 0;
  public isShow = false;//点击聊天时的下载APP弹窗
  public downLoadAPPChatText = "下载APP，不错过最新消息提醒，想招的学生直接聊";//
  public ShenHeAPPTest = "";
  public SchoolPhone = '';
  public college_is_activity = false;//是否是活动账号，活动账号不受院系申请限制
  public ZhaopinSwitch = false;
  public AirMilkBanner;

  public myDepartment: any;//当前登录的企业信息(department)


  @Output() collegeid = '';//高校id(提交认证)
  @Output() modelShow = false;//是否校招申请弹窗
  @Output() collegeIdentity: any;//高校认证信息
  @Output() collegeName: any;//高校名称
  @Output() audit_time = '';//认证审核时间
  @Output() faculty_authcation = false;//院系审核开关 //true 要选择院系，false 不选择院系
  @Output() CollegeList = [];

  public currentRoute = '';

  public model = {
    major: '',
    education: '',
    position_name: '',
    positionid: '',
    limit: 10,
    offset: 0
  };
  public ListModel1 = {
    status: '',
    name: '',
    need_perfect: true,
  }
  public listModel = {//会话列表参数
    positionid: '',//职位id
    account_type: '',//联系人类别
    is_read: false,//是否已读
  };
  /**
   * 0未申请
   * 1有申请，未通过
   * 2全部通过
   * 3已拒绝
   */
  public histortyCollegeState = 0;
  public apply_time = '';// 企业申请高校校招信息

  public isShowDownLoadAPP = false;
  public isShowWeiShenQing = false;//显示未申请的弹窗
  public isShowWeiTongGuo = false;//显示未申请的弹窗
  public ShowWeiWanShanZhiwei = false;//显示未完善列表
  public WanShanZhiweiStr = '';//未完善列表职位文本

  public booth: any;

  public bindEmailModel = {
    email: '',
    code: '',
    psw: '',
    psw2: ''
  };
  public showBingLoginEmailModel = false
  public sendEmailText2 = '获取邮箱验证码';
  public timer1 = null;
  public loginUser: any

  constructor(public dataApi: DataApi,
    public local: Local,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private zone: NgZone,
    public opera: Opera,
    private location: PlatformLocation,
    private activatedRoute: ActivatedRoute,
    public events: Events,
    public cd: ChangeDetectorRef,
    public toastr: ToastrService,
    public dialog: MatDialog,
    private messageService: MessageService,
    public notEmitTool: NotEmitTool,
    private datePipe: DatePipe
  ) {
    this.config = Config.getInstance();//实例化config
    this.departmentJm = this.dataApi.getLocalDepartment().jm_username;
    // 监听浏览器返回/前进
    location.onHashChange(val => {
      //console.log("浏览器操作路由-------");
      //console.log(val);
      history.go(0);
    })
    // 监听切换路由
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // //console.log("获取到路由参数3--------------");
        // //console.log(event);
        // //console.log(event.url);
        this.opera.setRouterLocal(event.url);
      }
    });

    //收到新消息广播
    this.events.subscribe('newMM:tabs', (data) => {
      console.log('tabs页面接受到了新消息广播-------MMtabs');
      console.log(data);
      if (data) {
        if (data.from == this.departmentJm) {
          this.isSendDepartment = true;

        } else {
          this.isSendDepartment = false;
          this.config.unReadNum = this.config.unReadNum + 1;//接收到新消息时改变未读消息数量（新消息一条一条的发送）
        }
        this.setChatList(data);
        if (!this.cd['destroyed']) {
          this.cd.detectChanges();//刷新数据
        }

        this.events.publish('tabsTo:chatM', data); //tabs给消息页面发送的广播(消息内容)
      }

    })

    this.getBoothList();//获取广告

    this.getBoothWithKey();

    // 接收api.ts通知错误退出登录广播
    this.events.subscribe('user:created', () => {
      console.log("收到api.ts发送的退出登录广播-----home");
    });
    this.config.isDoubleCollegeClearLoginInfo = false;

  }

  //进入首页获取广告列表
  getBoothList() {
    this.dataApi.getBoothList().then((res: any) => {
      if (res) {
        console.log("BoothList", res);
        for (let val of res) {
          //侧面图
          if (val.alias == "leftnav") {
            this.booth = val;
          }
        }
      }
    }).catch((err) => {
      console.log('-----广告列表', err);
    })
  }

  //点击跳转活动详情（双选会/宣讲会）
  goActivityDetails(data) {
    if (this.booth.links) {
      window.open(this.booth.links, '_blank')
    }
    this.currentRoute = '';
  }
  getBoothWithKey() {
    this.dataApi.getBoothWithKey('gxqybanner').then((res: any) => {
      if (res) {
        console.log("getBoothWithKey", res);
        if (res.length > 0) {
          this.booth = res[0];
        }
      }
    }).catch((err) => {
      console.log('-----广告列表', err);
    })
  }

 async ngOnInit() {
    this.getUrl();//初始化时获取路由信息切换子路由
    setTimeout(() => { this.config.isLogin = false; })
    this.opera.checkPlatformIdentity();//检测企业认证信息
    this.getMsgCount();
    this.getIsFirstJoin();
    if (this.local.get(Constants.byCollege)) {
      this.getCollege(this.local.get(Constants.byCollege));//获取高校信息
      this.checkDepartmentIndentityByCollege();
    }
    //
    this.notEmitTool.event.subscribe(res => {
      console.log("------首页通知------", res);
      if (res == "RushMainList") {
        this.getIsFirstJoin();
      }
      if (res == 'NotHomeRushUnReadMsgCount') {
        this.getMsgCount();
      }
    }, error => {
    })

    if (this.local.get('collegeid')) {
      let collegeid = this.local.get('collegeid');
      this.dataApi.getCollegePart(collegeid).then((res: any) => {
        // console.log("准备申请的院系列表",res);
        if (res) {
          this.CollegeList = res;
        }
      }).catch((err) => {
        console.log("准备申请的院系列表err", err);
      })
    }
   this.getDepartment();
   this.getCollegeZhaopinSwitch();
   this.getRecruitmentSelf();

    this.events.subscribe('showHTTPErrModelNot', (data) => {
      if (this.config.isShowHTTPErrModel) {
        return;
      }
      this.config.isShowHTTPErrModel = true;
      this.dialog.open(HttpErrorModelComponent, {
        data: {
          type: '404',
          message: this.config
        }
      }).afterClosed().subscribe((isConfirmed) => { });
    })


    this.dataApi.getUser().then((res: any) => {
      if (res) {
        this.loginUser = res;
        this.dataApi.setLocalLoginUser(res);
        if (res.login_email == null || res.login_email == '') {
          console.log('showBingLoginEmailModel', res)
          setTimeout(() => {
            this.showBingLoginEmailModel = true
          }, 1500)
        }
      }
    }).catch((error) => {
      this.toastr.error(error);
    });

    // 判断是否分发页面
    if(this.local.get('isType') == 'milk' && this.local.get('loginmrId')){
      console.log('判断是否分发页面');
      let id = this.local.get('loginmrId');
      this.router.navigate(['home/college-milkdetails'], {
        queryParams: {
          id: id
        }
      });
      this.local.remove('isType');
      this.local.remove('loginmrId');
    }
  }
  //获取当前路由地址
  getUrl() {
    //初始化时判断路由切换页面
    if (this.activatedRoute.children[0]) {
      this.config.isNavChildActive = this.activatedRoute.children[0].snapshot.routeConfig.path;
      this.router.navigate([this.config.isNavChildActive], { relativeTo: this.activatedRoute });
      let n = this.config.isNavChildActive.indexOf('-');
      if (n > -1) {
        this.config.isNavActive = this.config.isNavChildActive.slice(0, n);
      }
    } else {
      this.router.navigate(['homepage'], { relativeTo: this.activatedRoute });
    }
  }
  //获取高校信息
  getCollege(id) {
    this.dataApi.getCollageDetails(id).then((res: any) => {
      if (res) {
        if (res.work_info != null) {
          this.SchoolPhone = res.work_info.office_tel;
        }
        this.collegeid = res.collegeid;
        this.config.byCollegeName = res.name;
        this.collegeName = res.name;
        this.college_is_activity = res.is_activity;//是否活动账号
        console.log("Home获取到高校信息---------", res);
        this.faculty_authcation = res.audit_switch.faculty_authcation;
      }
    }).catch((err) => {
    });
  }
  //判断切换的导航路由
  goChild(data, txt) {
    if (txt == 'college-details-student' || txt == 'college-details-campus' || txt == 'college-details-milk' || txt == 'notification-center' || txt == 'airCampusDetail') {
      let collegeid = this.local.get('collegeid') + '';
      console.log("请重新从高校站内登录", collegeid);
      if (collegeid + '' == 'false') {
        this.toastr.error(this.dataApi.getNoDepardmentID());
        return;
      }
      /**
       * 0未申请  弹窗下载APP
       * 1待审核  跳转到申请高校合作
       * 2全部通过  跳转到详情
       * 3已拒绝  弹窗跳转到申请历史
       */
      console.log("点击了生源速览等。。。", this.histortyCollegeState);
      this.config.isNavActive = data;
      this.config.isNavChildActive = txt;
      this.router.navigateByUrl("home/" + txt);//审核中
      return;
    }
    console.log("goChild", txt);
    this.config.isNavActive = data;
    this.config.isNavChildActive = txt;
    if (txt == 'company-authentication') {
      this.opera.goplatformIdentity();
    }
  }
  //去引导页
  goGuid() {
    this.router.navigateByUrl('guide');
  }

  //收到新消息后处理会话列表
  setChatList(data) {
    //人才面试邀请反馈状态
    //  console.log('收到新消息处理会话列表--------------------tabs');
    let from = data.from;
    if (this.isSendDepartment) {
      from = data.to;
    }
    //------判断是否是人才主动发起的新会话
    if (this.config.chatsList.list) {
      let n = 0;
      for (let val of this.config.chatsList.list) {
        if (val.dialogue_jmusername == from) {
          break;
        } else {
          n += 1;
        }
      }
      if (n == this.config.chatsList.list.length) {
        this.NewChatList(data);//重新获取会话列表
        return;
      }
    } else {
      this.NewChatList(data);//重新获取会话列表
      return;
    }

    //-----------处理特殊消息
    let model = {
      type: '',
      content: ''
    };
    if (data.type == "reply") {
      model.type = "reply";
      if (data.content == "0") {
        model.content = "rejected";//拒绝
      } else if (data.content == "1") {
        model.content = "accept";//接受
      } else if (data.content == "2") {
        model.content = "talent_canceled";//取消
      }
    } else if (data.type == "shield") {
      model.type = "shield";
      if (data.content == "true") {
        model.content = "true";//屏蔽
      } else if (data.content == "false") {
        model.content = "false";//取消屏蔽
      }
    } else if (data.type == "multitext") {//多媒体消息
      let identity = JSON.parse(data.content);
      if (identity.type == 'authentication') {
        this.opera.remindIdentityResult(identity._id);
      }
    }
    //----------------收到新消息之后更新会话列表
    let isTopNum = 0;//-------置顶的会话数量
    let newList: any;//------接收新消息的会话
    let num = 0;//接收新消息的会话的下标
    let unReadCount = 0;//会话列表总的未读消息数量
    let unReadElse = 0;//会话列表其他项的未读消息数量
    for (let val of this.config.chatsList.list) {
      if (val.is_top) {
        isTopNum += 1;
      }
      if (val.dialogue_jmusername == data.from) {//更新本地列表内容
        num = this.config.chatsList.list.indexOf(val);
        if (!this.isSendDepartment) {
          val.unread_count += 1;
        }
        // console.log("会话列表未读数量--------------------------------tabs");
        console.log(val);
        val.last_message = data.content;
        val.last_message_type = data.type;
        val.last_message_time = new Date();//赋值最后一条消息发送时间
        if (data.type == "text") {
          val.last_message = data.content;
        }
        newList = val;
        if (val.is_top) {//-----------当前会话为置顶会话消息
          this.config.chatsList.list.splice(num, 1);
          this.config.chatsList.list.splice(0, 0, newList);
          this.events.publish('tabsTo:listM'); //tabs给会话列表页发送的广播(消息内容)
          // if(!this.cd['destroyed']) {
          //   this.cd.detectChanges();//刷新数据
          // }
          return;
        }
        unReadCount = unReadCount + val.unread_count;
      } else {
        unReadCount = unReadCount + val.unread_count;
        unReadElse = unReadElse + val.unread_count;
      }
    }//------循环结束

    //处理会话排序
    if (num == isTopNum) {//----------当前会话在置顶会话后一位
      return;
    } else if (num == 0) {//----------当前会话在第一位
      return;
    } else if (num < isTopNum) {//当前会话为置顶会话消息且不在第一位
      this.config.chatsList.list.splice(num, 1);
      this.config.chatsList.list.unshift(newList);
    } else {
      this.config.chatsList.list.splice(num, 1);
      this.config.chatsList.list.splice(isTopNum, 0, newList);
    }
    this.events.publish('tabsTo:listM'); //tabs给会话列表页发送的广播(消息内容)
  }

  //-------处理新会话消息
  NewChatList(data) {
  }

  //获取会话列表
  getImDialogueList() {
    this.config.unReadNum = 0;
    this.config.loading = true;
    this.dataApi.getImDialogueAllList({}).then((res: any) => {
      if (res) {
        this.config.loading = false;
        console.log("本地会话列表为空时获取会话列表-----tabs");
        console.log(res);
        this.config.chatsList = res;//-----------------更新会话列表全局变量
        for (let val of this.config.chatsList.list) {
          this.config.unReadNum += val.unread_count;
        }
      }
    }).catch((error) => {
      this.config.loading = false;
      console.log(error);
      this.toastr.error(error);
    });
  }

  cloceDownLoad() {
    console.log("cloceDownLoad");
    this.isShow = false;
    this.isShowDownLoadAPP = false;
    this.isShowWeiTongGuo = false;
    this.isShowWeiShenQing = false;
    this.ShowWeiWanShanZhiwei = false;
  }

  getMsgCount() {
    //对我感兴趣简历列表
    this.dataApi.getPositionCollectionHistory(this.model).then((res: any) => {
      if (res) {
        this.msgNumber4 = res.count;
        this.msgNumber1 = this.msgNumber2 + this.msgNumber3 + this.msgNumber4
      }
    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss();
      this.toastr.error(error);
    });

    //说看过我
    this.dataApi.getPositionViewHistory(this.model).then((res: any) => {
      this.opera.loadiss();
      if (res) {
        this.msgNumber3 = res.count;
        this.msgNumber1 = this.msgNumber2 + this.msgNumber3 + this.msgNumber4
      }
    }).catch((error) => {
      console.log(error);
      this.opera.loadiss();
      this.toastr.error(error);
    });

    //聊天会话列表
    this.dataApi.getImDialogueAllList(this.listModel).then((res: any) => {
      // console.log(res);
      if (res) {
        this.msgNumber2 = res.count;
        this.msgNumber1 = this.msgNumber2 + this.msgNumber3 + this.msgNumber4
      }

      // console.log("getImDialogueAllList",res);
      // this.config.chatsList=res;
      this.opera.loadiss();
    }).catch((error) => {
      //console.log(error);
      this.toastr.error(error);
      this.opera.loadiss();
    });
  }
  /**
   * 获取申请的高校合作列表
   */
  getIsFirstJoin() {

    let collegeid = this.local.get("collegeid");
    this.dataApi.lastCollegeAuthenticationIdentity(collegeid).then((res: any) => {
      if (res) {
        console.log("获取企业平台认证信息getIsFirstJoin", res, collegeid);
        this.apply_time = res.apply_time;
        //status (integer, optional): 企业认证审核状态 1：待审核 2：通过审核 3：未通过审核 ,
        if (res.status == 1) {
          this.histortyCollegeState = 1;//待审核的
        }
        else if (res.status == 2) {
          this.histortyCollegeState = 2;//全部通过
        }
        else if (res.status == 3) {
          this.histortyCollegeState = 3;//已拒绝
        }
        console.log("histortyCollegeState", this.histortyCollegeState)
      }
    }).catch((error) => {
      console.log("获取企业平台认证信息出错");
      console.log(error);
    })
  }

  /**
   * 跳转申请高校合作
   */
  gotoShenQing() {
    scrollTo(0, 0)
    this.isShowDownLoadAPP = false;
    this.isShowWeiTongGuo = false;
    this.isShowWeiShenQing = false;
    // console.log("跳转申请高校合作");
    this.collegeid = this.local.get(Constants.byCollege)
    this.modelShow = true;//打开校招申请确认弹窗
    this.opera.scrollTop();//页面滚动函数
  }

  /**
   *跳转申请的高校合作记录
   */
  gotoGaoXiaoHistory() {
    this.isShowDownLoadAPP = false;
    this.isShowWeiTongGuo = false;
    this.isShowWeiShenQing = false;
    this.router.navigateByUrl('/home/company-identity');

  }

  //获取职位列表
  getPositionList() {
    this.opera.loading();
    this.dataApi.getPositionList(this.ListModel1).then((res: any) => {
      console.log("获取职位列表-----", res, this.ListModel1);

      if (res) {
        let str = ''
        let fenGe = res.list.length == 1 ? "" : "|";
        if (res.list.length > 0) {
          for (let index = 0; index < res.list.length; index++) {
            let item = res.list[index];
            str += item.name + fenGe
          }
          this.WanShanZhiweiStr = str;
          this.ShowWeiWanShanZhiwei = true;
        }
      }

    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  /**
   * 去职位
   */
  gotoZhiWei() {
    this.ShowWeiWanShanZhiwei = false;
    this.router.navigateByUrl('/home/position-manage');

  }
  //判断企业是否通过高校认证
  checkDepartmentIndentityByCollege() {
    this.dataApi.lastCollegeAuthenticationIdentity(this.local.get(Constants.byCollege)).then((res: any) => {
      console.log("获取企业在高校的认证信息-----------------------CampusDetails");
      console.log(res);
      if (res) {
        this.audit_time = res.audit_time;
        this.collegeIdentity = res;
      }

    }).catch((err) => {
      ////console.log(err);
    })
  }
  //获取申请校招返回值
  Modeldismiss(event) {
    ////console.log('子组件返回结果-----college-details');
    ////console.log(event);
    this.opera.scrollTrue();
    if (event) {
      //跳转认证页面
      // this.local.set(Constants.collegeid,this.local.get(Constants.byCollege));
      this.router.navigateByUrl("/home/company-identity-details");
      this.modelShow = false;
    } else {
      this.modelShow = false;
    }
  }

  goToNewWeb() {
    if (this.local.get('accesstoken')) {
      let token = this.local.get('accesstoken');
      let accountid = this.local.get('accountid');
      let collegeid = this.local.get('collegeid');
      this.isShow = false;
      window.open(this.config.JumpMainWeb + token + '&accountid=' + accountid + '&route=' + 'notification-center', '_blank')
    }
  }
  getCollegeZhaopinSwitch() {
    if (this.local.get('collegeid')) {
      let collegeid = this.local.get('collegeid');
      this.dataApi.getCollegeZhaopinSwitch(collegeid).then((res: any) => {
        console.log("招聘简章开关", res.value);
        if (res) {
          this.ZhaopinSwitch = res.value
        }
      }).catch((err) => {
      })
    }
  }
  //获取企业信息
  getDepartment() {
    //修正为每次都去请求api获取企业最新冻结情况
    this.dataApi.getDepartment().then((res: any) => {//通过tooken获取企业信息
      console.log("企业信息线上数据-----homepage", res);
      //console.log(res);
      if (res) {
        this.myDepartment = res;

        this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
        if (this.myDepartment.is_frozen == true) {
          this.config.is_Frozen = true
          this.isFrozenFanc()
        } else {
          this.config.is_Frozen = false
        }
        localStorage.setItem("app_login", this.myDepartment.app_login ? 'true' : 'false')

      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }
  //判断冻结
  isFrozenFanc() {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '账号被冻结',
        message: `
					冻结提示：由于您的账号涉及违规，被暂时冻结。您现在仅可使用职位管理、招聘简章、公司信息、账号管理功能。请修改相关信息后发送邮件到service@gowork.la申请解冻，主题备注“申请解冻+注册手机号，工作人员1个工作日内审核给出结果。
				`,
        trueText: '确定',
        falseText: '取消'
      }
      //回调函数
      // }).afterClosed().subscribe((isConfirmed) => {
      //         if(isConfirmed){
      //           console.log('isConfirmed——true')
      //         }else{
      // 	console.log('isConfirmed——false')
      // }
    });
    // .afterClosed().subscribe((isConfirmed) => {
    //          //console.log('检测是否存在返回值');
    //          //console.log(isConfirmed);
    //          this.opera.scrollTrue();
    //          if(isConfirmed){
    //            this.toastr.info("请重新登录");
    //            this.setRoot();//不重新登录无法接收消息，踢到登录页
    //          }
    //    });
  }

  //获取左侧栏目的校招顾问
  getRecruitmentSelf() {
    this.dataApi.getRecruitmentSelf().then((res: any) => {
      console.log('获取左侧栏目的校招顾问', res);
      if (res) {
        this.config.departmentSelf = res;
      }
    }).catch((error) => {
      // console.log('企业未绑定微信',error)
    });
  }
  closeModel() {
    this.showBingLoginEmailModel = false
  }
  getBingEmailCode() {
    if (!this.bindEmailModel.email) {
      this.toastr.warning('请填写邮箱');
      return;
    }
    if (this.sendEmailText2 != '获取邮箱验证码') {
      this.toastr.error('60秒内不能重复发送');
      return;
    }
    let phoneModel = {
      format_type: 2,//0:手机号，1：手机号&固话；2.邮箱
      data: this.bindEmailModel.email
    }
    this.dataApi.phoneVerification(phoneModel).then((res: any) => {
      if (res.value) {

        let data = {
          email: this.bindEmailModel.email,
        };
        this.dataApi.sendEmail(data).then((res: any) => {
          console.log("发送邮箱", res, data);
          this.toastr.success('获取邮箱验证码成功')
          let count = 60;
          this.sendEmailText2 = count + 's';
          this.timer1 = setInterval(() => {
            count -= 1;
            this.sendEmailText2 = count + 's';
            if (count === 0) {
              clearInterval(this.timer1);
              this.sendEmailText2 = '获取邮箱验证码';
            }
          }, 1000);

        }).catch((err) => {
          console.log("发送邮箱失败", err);
          this.toastr.error(err); return;
        })
      }
      else {
        this.toastr.error('邮箱格式错误');
      }
    }).catch((err) => {
      //console.log(err);
      this.toastr.error('工作邮箱格式错误');
      return;
    })


  }
 



}
