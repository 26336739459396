<!-- 签约通知 -->
<!-- 遮罩层公共样式 -->
<div class="model-block" [class.block]='isShow'>
  <div class="black-bg" (click)="dismiss(false)"></div>
  <div class="model-cont">
    <!-- 上层提示部分 -->
    <div class="model-head clear">
      <div class="left">签约通知</div>
      <button class="right" (click)="dismiss(false)">×</button>
    </div>
    <!-- 编辑签约通知 -->
    <div class="model-content">
        <!-- 签约职位 -->
        <div *ngIf="!senOffer" class="sign-list">
          <span>签约职位：</span>
          <div class="selected-text disabled-text">{{position_name}}</div>
        </div>
        <div *ngIf="senOffer" class="sign-list select-list">
          <span>签约职位：</span> 
          <select autocomplete="off" [(ngModel)]="model.positionid">
              <option class="place-text" value='' disabled hidden selected="selected">选择签约职位</option>
              <option *ngFor="let position of config.positionList" [value]="position.positionid">{{position.name}}</option>
          </select>
          <b *ngIf="isSend&&!model.positionid"><img src="assets/images/guide-false.png" alt="必填项提示"></b>
      </div>
        <!-- 签约薪资 -->
        <div class="sign-list">
          <span>签约薪资：</span><input type="text" [(ngModel)]="model.salary" placeholder="请填写签约薪资"><span class="purple">元</span>
          <b *ngIf="isSend&&!model.salary"><img src="assets/images/guide-false.png" alt="必填项提示"></b>
        </div>
        <!-- 入职时间 -->
        <div class="sign-list sign-date">
            <span>入职时间：</span>
            <input type="text" id="hiredDate" [(ngModel)]="completeDate" placeholder="请选择日期"/><img class="calendar-img" src="assets/images/calendar.png" alt="打开日历"/>
            <input type="text" id="hiredHour" [(ngModel)]="hourAndMinute" placeholder="请选择时间"/><img class="calendar-img" src="assets/images/calendar.png" alt="打开日历"/>
            <b *ngIf="isSend&&!model.entry_time"><img src="assets/images/guide-false.png" alt="必填项提示"></b>
        </div>
        <!-- 公司地址 -->
        <div class="sign-list select-list" *ngIf="config.department">
            <span>公司地址：</span> 
            <select autocomplete="off" [(ngModel)]="model.address">
                <option class="place-text" disabled hidden selected="selected" value=''>选择面试地址</option>
                <option *ngFor="let address of config.department.locations" [value]="address.region_text+'，'+address.address">{{address.region_text}}，{{address.address}}</option>
            </select>
            <b *ngIf="isSend&&!model.address"><img src="assets/images/guide-false.png" alt="必填项提示"></b>
        </div>
        <!-- 联系电话 -->
        <div class="sign-list">
            <span>联系电话：</span><input type="number" [(ngModel)]="model.phone" (blur)="checkPhone()" placeholder="请填写联系电话">
            <b *ngIf="isSend&&!model.phone"><img src="assets/images/guide-false.png" alt="必填项提示"></b>
        </div>
         <!-- 注意事项 -->
         <div class="sign-list sign-intro">
          <span>职位描述：</span>
          <textarea [(ngModel)]="model.remark" maxlength="1000" placeholder="请填写注意事项（示例：请携带本人身份证、学位证书、1寸照片2张等）"></textarea>
      </div>
    </div><!-- 编辑面试邀请 -->
     
    <!-- 底部操作部分 -->
    <div class="model-foot" *ngIf="!isClickSend">
      <!-- 发送 -->
      <button class="purple" (click)="sendNotice()">发送</button>
    </div><!--底部操作部分-->
  </div>
  

</div>
