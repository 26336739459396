<!-- 页头部分 -->
<div class="contentRight-header">首页</div>
<!-- 主体内容部分 -->
<div class="homepage-block clear">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-college-identity [orgList]='CollegeList' [name]="collegeName" [faculty_authcation]="faculty_authcation" [item]="collegeIdentity" [audit_time]="audit_time" [collegeid]="collegeid" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-identity>
    <!-- 工作台 -->

    <div class="screenSmallBox flex_l" *ngIf="isShowPerfetInfo">
        <img class="screenSmallBox_icon" src="../../assets/images/orange_warning.png">
        <div>请设置是否公开您的联系方式。为了更好地触达学生，建议设为公开. <span (click)="gotoPerfect()" class="gotoPerfect">去完善 >></span></div>
    </div>

    <div class="homepage-hr homepage-two left">
        <div class="hr-top">
            <div class="left" *ngIf="myUser">
                <img *ngIf="myUser.avatar" [src]="myUser.avatar+'&imageView2/1/w/100/h/100'">
                <img *ngIf="!myUser.avatar&&(myUser.gender=='01')" src="assets/images/male.png">
                <img *ngIf="!myUser.avatar&&(myUser.gender=='02')" src="assets/images/female.png">
                <img *ngIf="!myUser.avatar&&!myUser.gender" src="assets/images/male.png">
            </div>
            <div class="hr-content">
                <div class="hr-name" *ngIf="myUser">{{myUser.name}}，{{nowNoon}}好</div>
                <div class="hr-txt" *ngIf="myDepartment">{{myDepartment.name}}</div>
                <div class="hr-time" *ngIf="myDepartment">上次登录时间&nbsp;&nbsp;{{myDepartment.last_online_time | date:"yyyy-MM-dd"}}
                </div>
            </div>
        </div>
        <ul *ngIf="!config.isByCollege" class="clear">
            <!-- <li class="left" (click)="goChild('position-calendar')"><img src="assets/images/home-calendar.png"><br/>招聘日历</li> -->
            <li class="left" (click)="goChild('college-campustalk')"><img src="assets/images/home-campus-manage1.png"><br />校园+</li>
            <li class="left" (click)="goChild('college-resume')"><img src="assets/images/home-student-resume.png"><br />学生简历</li>
            <li class="left" (click)="goChild('position-manage')"><img src="assets/images/home-position-manage.png"><br />职位管理</li>
            <li class="left" (click)="goChild('college-milkround')"><img src="assets/images/home-milk-manage.png"><br />双选会</li>
            <!--<li class="left" (click)="goChild('chat-contact')"><img src="assets/images/home-chat-manage.png"><br/>在线直聊</li>-->
            <li class="left" (click)="goChild('position-resume')"><img src="assets/images/home-resume-manage.png"><br />简历管理</li>
        </ul>
        <ul *ngIf="config.isByCollege" class="clear college-list">
            <li *ngIf='config.is_Frozen==false' class="left" (click)="goChild('college-details-campus')"><img src="assets/images/home-campus-manage.png"><br />宣讲会</li>
            <li *ngIf='config.is_Frozen==false' class="left" (click)="goChild('college-details-milk')"><img src="assets/images/home-milk-manage.png"><br />双选会</li>
            <!-- <li class="left" (click)="goChild('position-calendar')"><img src="assets/images/home-calendar.png"><br/>招聘日历</li> -->
            <!--<li *ngIf='config.is_Frozen==false' class="left"  (click)="goCollegeIdentity()"><img src="assets/images/home-identity-manage1.png"><br/>申请高校合作</li>-->
            <!--<li *ngIf='config.is_Frozen==false' class="left" (click)="goChild('college-details-student')"><img src="assets/images/home-student-manage.png"><br/>生源速览</li>-->

            <!--<li class="left" (click)="goChild('chat-contact')"><img src="assets/images/home-chat-manage.png"><br/>在线直聊</li>-->
            <li *ngIf='config.is_Frozen==false' class="left" (click)="goChild('position-resume')"><img src="assets/images/home-resume-manage.png"><br />简历管理</li>
            <li class="left" (click)="goChild('position-manage')"><img src="assets/images/home-position-manage.png"><br />职位管理</li>
        </ul>
        <div *ngIf="config.isByCollege" class="hr-date-time">
            <h3>欢迎来到{{config.byCollegeName}}就业管理中心</h3>
            今天是&nbsp;{{nowDate}}&nbsp;&nbsp;&nbsp;&nbsp;{{nowWeek}}
        </div>
        <div *ngIf="!config.isByCollege" class="hr-date-time">今天是&nbsp;{{nowDate}}&nbsp;&nbsp;&nbsp;&nbsp;{{nowWeek}}
        </div>
    </div>
    <!-- 工作台 -->
    <div class="homepage-hr homepage-one left" [class.byCollege]="config.isByCollege">
        <div class="hr-top">
            <div class="left" *ngIf="myUser">
                <img *ngIf="myUser.avatar" [src]="myUser.avatar+'&imageView2/1/w/50/h/50'">
                <img *ngIf="!myUser.avatar&&(myUser.gender=='01')" src="assets/images/male.png">
                <img *ngIf="!myUser.avatar&&(myUser.gender=='02')" src="assets/images/female.png">
                <img *ngIf="!myUser.avatar&&!myUser.gender" src="assets/images/male.png">
            </div>
            <div class="hr-content">
                <div class="hr-name" *ngIf="myUser">{{myUser.name}}，{{nowNoon}}好</div>
                <div class="hr-txt" *ngIf="myDepartment">{{myDepartment.name}}</div>
                <div class="hr-time" *ngIf="myDepartment">上次登录时间&nbsp;&nbsp;{{myDepartment.last_online_time | date:"yyyy-MM-dd"}}
                </div>
            </div>
        </div>
        <ul *ngIf="!config.isByCollege" class="clear">
            <!-- <li class="left" (click)="goChild('position-calendar')"><img src="assets/images/home-calendar.png"><br/>招聘日历</li> -->
            <li class="left" (click)="goChild('college-campustalk')"><img src="assets/images/home-campus-manage1.png"><br />校园+</li>
            <li class="left" (click)="goChild('college-resume')"><img src="assets/images/home-student-resume.png"><br />学生简历</li>
            <li class="left" (click)="goChild('position-manage')"><img src="assets/images/home-position-manage.png"><br />职位管理</li>
            <li class="left" (click)="goChild('college-milkround')"><img src="assets/images/home-milk-manage.png"><br />双选会</li>
            <!--<li class="left" (click)="goChild('chat-contact')"><img src="assets/images/home-chat-manage.png"><br/>在线直聊</li>-->
            <li class="left" (click)="goChild('position-resume')"><img src="assets/images/home-resume-manage.png"><br />简历管理</li>
        </ul>
        <ul *ngIf="config.isByCollege" class="clear college-list">
            <li class="left" (click)="goChild('college-details-campus')"><img src="assets/images/home-campus-manage.png"><br />宣讲会</li>
            <li class="left" (click)="goChild('college-details-milk')"><img src="assets/images/home-milk-manage.png"><br />双选会</li>
            <!-- <li class="left" (click)="goChild('position-calendar')"><img src="assets/images/home-calendar.png"><br/>招聘日历</li> -->
            <!--<li class="left" (click)="goCollegeIdentity()"><img src="assets/images/home-identity-manage1.png"><br/>申请高校合作</li>-->
            <!--<li class="left" (click)="goChild('college-details-student')"><img src="assets/images/home-student-manage.png"><br/>生源速览</li>-->
            <!--<li class="left" (click)="goChild('college-details-campus')"><img src="assets/images/home-campus-manage.png"><br/>宣讲会</li>-->
            <!--<li class="left" (click)="goChild('college-details-milk')"><img src="assets/images/home-milk-manage.png"><br/>双选会</li>-->
            <!--<li class="left" (click)="goChild('chat-contact')"><img src="assets/images/home-chat-manage.png"><br/>在线直聊</li>-->
            <li class="left" (click)="goChild('position-resume')"><img src="assets/images/home-resume-manage.png"><br />简历管理</li>
            <li class="left" (click)="goChild('position-manage')"><img src="assets/images/home-position-manage.png"><br />职位管理</li>

        </ul>
        <div *ngIf="config.isByCollege" class="hr-date-time">
            <h3>欢迎来到{{config.byCollegeName}}就业管理中心</h3>
            今天是&nbsp;{{nowDate}}&nbsp;&nbsp;&nbsp;&nbsp;{{nowWeek}}
        </div>
        <div *ngIf="!config.isByCollege" class="hr-date-time">今天是&nbsp;{{nowDate}}&nbsp;&nbsp;&nbsp;&nbsp;{{nowWeek}}
        </div>
    </div>
    <!-- 招聘进度 -->
    <div class="homepage-progress left clear" [class.byCollege]="config.isByCollege" *ngIf="jobProgress">
        <!-- {{jobProgress.delivery.total}} -->
        <!-- 合作院校 -->
        <div class="progress-li left clear" *ngIf="!config.isByCollege">
            <div class="left"><img src="assets/images/home-college.png"></div>
            <div class="left">合作院校<br /><b *ngIf="jobProgress">{{jobProgress.cooperation.total}}所</b></div>
            <div class="right">
                <div class="color" [class.color-top]="jobProgress.cooperation.stauts==1" [class.color-down]="jobProgress.cooperation.stauts==-1">今日&nbsp;{{jobProgress.cooperation.today}}
                </div>
                <div>昨日&nbsp;{{jobProgress.cooperation.yesterday}}</div>
            </div>
        </div>
        <!-- 收到简历 -->
        <div class="progress-li left clear ">
            <div class="left"><img src="assets/images/home-resume.png"></div>
            <div class="left">收到简历<br /><b>{{jobProgress.delivery.total}}份</b></div>
            <div class="right">
                <div class="color" [class.color-top]="jobProgress.delivery.stauts==1" [class.color-down]="jobProgress.delivery.stauts==-1">今日&nbsp;{{jobProgress.delivery.today}}</div>
                <div>昨日&nbsp;{{jobProgress.delivery.yesterday}}</div>
            </div>
        </div>
        <!-- 在线直聊 -->
        <div class="progress-li left clear mro" *ngIf="!config.isByCollege">
            <div class="left"><img src="assets/images/home-chat.png"></div>
            <div class="left">在线直聊<br /><b>{{jobProgress.dialogue.total}}次</b></div>
            <div class="right">
                <div class="color" [class.color-top]="jobProgress.dialogue.stauts==1" [class.color-down]="jobProgress.dialogue.stauts==-1">
                    今日&nbsp;{{jobProgress.dialogue.today}}</div>
                <div>昨日&nbsp;{{jobProgress.dialogue.yesterday}}</div>
            </div>
        </div>
        <!-- 面试邀请 -->
        <div class="progress-li left clear">
            <div class="left"><img src="assets/images/home-interview.png"></div>
            <div class="left">面试邀请<br /><b>{{jobProgress.interview.total}}人</b></div>
            <div class="right">
                <div class="color" [class.color-top]="jobProgress.interview.stauts==1" [class.color-down]="jobProgress.interview.stauts==-1">今日&nbsp;{{jobProgress.interview.today}}</div>
                <div>昨日&nbsp;{{jobProgress.interview.yesterday}}</div>
            </div>
        </div>

        <!-- 宣讲会 -->
        <div class="progress-li left clear">
            <div class="left"><img src="assets/images/home-campus.png"></div>
            <div class="left">宣讲会<br /><b>{{jobProgress.campustalk.total}}场</b></div>
            <div class="right">
                <div class="color" [class.color-top]="jobProgress.campustalk.stauts==1" [class.color-down]="jobProgress.campustalk.stauts==-1">今日&nbsp;{{jobProgress.campustalk.today}}
                </div>
                <div>昨日&nbsp;{{jobProgress.campustalk.yesterday}}</div>
            </div>
        </div>

        <!-- 双选会 -->
        <div class="progress-li left clear mro">
            <div class="left"><img src="assets/images/home-milk.png"></div>
            <div class="left">双选会<br /><b>{{jobProgress.milkround.total}}场</b></div>
            <div class="right">
                <div class="color" [class.color-top]="jobProgress.milkround.stauts==1" [class.color-down]="jobProgress.milkround.stauts==-1">今日&nbsp;{{jobProgress.milkround.today}}</div>
                <div>昨日&nbsp;{{jobProgress.milkround.yesterday}}</div>
            </div>
        </div>
    </div>

    <!-- 招聘情况 -->
    <div class="homepage-position left" [class.byCollege]="config.isByCollege">
        <div class="position-header">招聘情况</div>
        <ul class="clear">
            <li><span></span>职位名称</li>
            <li>在线直聊</li>
            <li>收到简历</li>
            <li>面试邀请</li>
        </ul>
        <!-- 没有内容存在时 -->
        <div class="none-block" *ngIf="positionList.length==0">
            <img src="assets/images/resume-search-fail.png"><br /> 暂无在期的招聘职位
            <br /><button class="purple" (click)="goPublish()">去发布职位</button>
        </div>
        <ul class="clear list-txt" *ngFor="let n of positionList;let i = index;">
            <li><b [class.red]="3>i">{{i+1}}</b>{{n.name}}</li>
            <li>{{n.dialogue_count}}</li>
            <li>{{n.deliverycount}}</li>
            <li>{{n.interview_count}}</li>
        </ul>
    </div>
    <!-- 合作院校 -->
    <div *ngIf="!config.isByCollege" class="homepage-position homepage-college left">
        <div class="position-header">合作院校</div>
        <ul class="clear">
            <li><span></span>学校名称</li>
            <li>在线直聊</li>
            <li>收到简历</li>
            <li>面试邀请</li>
        </ul>
        <!-- 没有内容存在时 -->
        <div class="none-block" *ngIf="collegePart.length==0">
            <img src="assets/images/resume-search-fail.png"><br /> 已有{{countCollege}}所高校加入工作啦，期待与您合作！
            <br /><button class="purple" (click)="goChild('college-campustalk')">我要申请合作</button>
        </div>
        <ul class="clear list-txt" *ngFor="let n of collegePart;let i = index;">
            <li><b [class.purple]="3>i">{{i+1}}</b>{{n.name?n.name:'暂无'}}</li>
            <li>{{n.dialogue_count}}</li>
            <li>{{n.delivery_count}}</li>
            <li>{{n.interview_count}}</li>
        </ul>
    </div>
    <!-- 待办提醒 -->
    <div class="homepage-position homepage-message left" [class.byCollege]="config.isByCollege">
        <div class="position-header">待办提醒</div>
        <ul class="clear">
            <li><span></span>待办事项</li>
            <li>日期</li>
        </ul>
        <!-- 没有内容存在时 -->
        <div *ngIf="backlogList.length==0" class="none-block">
            <img src="assets/images/resume-search-fail.png"><br /> 暂无待办事项
        </div>
        <div *ngIf="backlogList.length>0">
            <ul class="clear list-txt" *ngFor="let chat of backlogList">
                <li *ngIf="chat.last_message_type == 'text'" class="position-span">
                    <span></span>收到{{chat.talent.talent_name}}的【文本消息】 </li>
                <li *ngIf="chat.last_message_type == 'resume'" class="position-span">
                    <span></span>收到{{chat.talent.talent_name}}的【简历消息】</li>
                <li *ngIf="chat.last_message_type == 'wechat'" class="position-span">
                    <span></span>收到{{chat.talent.talent_name}}的【微信号】 </li>
                <li *ngIf="chat.last_message_type == 'position'" class="position-span">
                    <span></span>收到{{chat.talent.talent_name}}的【职位消息】 </li>
                <li *ngIf="chat.last_message_type == 'invitation'" class="position-span">
                    <span></span>收到{{chat.talent.talent_name}}的【面试邀请】 </li>
                <li *ngIf="chat.last_message_type == 'reply'" class="position-span">
                    <span></span>收到{{chat.talent.talent_name}}的【面试邀请回复】 </li>
                <li *ngIf="chat.last_message_type == 'talent'" class="position-span">
                    <span></span>收到{{chat.talent.talent_name}}的【投递职位消息】 </li>
                <li *ngIf="chat.last_message_type == 'image'" class="position-span">
                    <span></span>收到{{chat.talent.talent_name}}的【图片消息】 </li>
                <li *ngIf="chat.last_message_type == 'multitext'" class="position-span">
                    <span></span>收到{{chat.talent.talent_name}}的【通知消息】 </li>
                <li *ngIf="chat.last_message_type == 'offer'" class="position-span">
                    <span></span>收到{{chat.talent.talent_name}}的【签约邀请】 </li>
                <li *ngIf="chat.last_message_type == 'offer_reply'" class="position-span">
                    <span></span>收到{{chat.talent.talent_name}}的【签约邀请回复】 </li>
                <li class="message-time">{{chat.last_message_time | date:"yyyy-MM-dd"}}</li>
            </ul>
        </div>
        <!-- <div >
        <ul class="clear list-txt">
          <li class="position-span"><span></span>收到赵小花的【签约邀请回复】 </li>
          <li class="message-time">2018-08-22</li>
        </ul>
      </div> -->
    </div>

    <!--<p-dialog [(visible)]="isShowDownLoadAPP" [width]="300" [height]="340" [minHeight]="340" [modal]=true>-->
    <!--<p-header>下载APP<img (click)="cloceDownLoad()"  style="width: 15px;height: 15px;float: right;margin-top: 5px" src="../../assets/images/closeX.png"/></p-header>-->
    <!--&lt;!&ndash;<p style="text-align: center;line-height: 15px;padding-top: 0px;padding-bottom: 0px;margin: 0px 0px 10px 0px">高校合作通过后才可查看/申请</p>&ndash;&gt;-->
    <!--<img  style="width: 200px;height: 200px;margin-left: 25px" src="../../assets/images/goworklaQRcode.png"/>-->
    <!--<p style="text-align: center;line-height: 15px;margin: 5px">{{DownLoadAPPTitle}}</p>-->
    <!--</p-dialog>-->



    <p-dialog [(visible)]="isShowWeiShenQing" [style]="{'width':300,'height':180,'minHeight':180}" [modal]=true>
        <p-header>未申请高校合作 <img (click)="cloceDownLoad()" style="width: 15px;height: 15px;float: right;margin-top: 5px" src="../../assets/images/closeX.png" /></p-header>
        <p style="text-align: center;line-height: 15px;padding-top: 0px;padding-bottom: 0px;margin: 0px 0px 10px 0px">
            申请并通过该高校合作后，才可查看/申请！</p>
        <br />

        <button (click)="gotoShenQing()" style="height: 30px;margin-left: 30px;float: left;padding: 0px 15px;background-color:#5d479a;color: white ">立即申请</button>
        <button (click)="cloceDownLoad()" style="height: 30px;margin-right: 30px;float: right;padding: 0px 15px;background-color:#5d479a;color: white">取消</button>
    </p-dialog>


    <!--<p-dialog [(visible)]="isShowWeiTongGuo" [width]="300" [height]="180" [minHeight]="180" [modal]=true>-->
    <!--<p-header>审核已拒绝 <img (click)="cloceDownLoad()"  style="width: 15px;height: 15px;float: right;margin-top: 5px" src="../../assets/images/closeX.png"/></p-header>-->
    <!--<p style="text-align: center;line-height: 15px;padding-top: 0px;padding-bottom: 0px;margin: 0px 0px 10px 0px">您申请的高校审核未通过，请点击查看详情</p>-->
    <!--<br/>-->
    <!--<button (click)="gotoGaoXiaoHistory()" style="height: 30px;margin-left: 30px;float: left;padding: 0px 15px;background-color:#5d479a;color: white ">立即前往</button>-->
    <!--<button (click)="cloceDownLoad()" style="height: 30px;margin-right: 30px;float: right;padding: 0px 15px;background-color:#5d479a;color: white">取消</button>-->
    <!--</p-dialog>-->

    <p-dialog [closeOnEscape]="false" [(visible)]="shouWeiChatCode" [style]="{'width':300,'height':340,'minHeight':340}" [modal]="true" (onHide)="dialogHiden()">
        <p-header>关注公众号</p-header>
        <img src={{codeURL}} style="width: 200px;height: 200px;display: block;margin: 0 auto;" />
        <br />
        <p style="text-align: center;line-height: 15px;margin: 10px 0px">立即扫码关注"校园招聘企业通知中心"， 及时获悉您的高校合作、双选会、宣讲会审核结果通知。
        </p>
    </p-dialog>

    <p-dialog [closeOnEscape]="false" [(visible)]="ShowWeiWanShanZhiwei" [style]="{'width':650,'height':340,'minHeight':340}" [modal]=true>

        <p-header>职位未完善</p-header>
        <div style="overflow: hidden;width:600px">
            <div style="width: 120px;height: 250px;float: left">
                <img style="width: 70px;height:70px;margin-left:30px;margin-top: 70px" src="../../assets/images/jingGao.png" />
            </div>
            <div style="width: 480px;height: 250px;float: right;position: absolute;margin-left: 120px">
                <p style="font-size: 18px;margin-top: 30px"><b>请立即完善您发布的职位：</b></p>
                <p style="font-size: 18px;color: #5d479a;padding: 10px 0px"><b>{{WanShanZhiweiStr}}</b></p>
                <p style="font-size: 18px"><b>完善详情后，学生才能看到您的招聘信息并投递简历。</b></p>
                <span (click)="gotoZhiWei()" style="width: 100px;padding:5px 15px;background-color:#5d479a;color: white;float: left;margin-left: 120px;text-align: center;border-radius: 5px;cursor: pointer;margin-top: 40px">立即完善</span>
            </div>
        </div>

    </p-dialog>


    <!--<p-dialog-->
    <!--[showHeader]="false"-->
    <!--[dismissableMask]="true"-->
    <!--[contentStyle]="{'padding':'0px'}"-->
    <!--[(visible)]="isShowDownLoadAPP" [width]="402"-->
    <!--[height]="460" [minHeight]="460"-->
    <!--[modal]=true>-->
    <!--<img (click)="goToNewWeb()"  style="width: 400px;height: 460px;border-radius:3px" src="../../assets/images/goToSchoolAlert.png"/>-->
    <!--</p-dialog>-->

    <!--isShowWeiTongGuo-->
    <p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isShowWeiTongGuo" [style]="{'width':490,'height':536,'minHeight':536,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"
        [modal]=true>
        <div class="alertPhone_Bg" *ngIf="config.departmentSelf">
            <img style="width: 80px;height: 105px;margin-left: 15px;margin-top: 0px;position: absolute" src="../../assets/images/alert_huizhang.png">
            <div style="margin-top: 30px;width: 490px;height:370px; background-color: white; background: url(../../assets/images/guwen_BG.png) 100% 100% no-repeat;overflow: hidden">
                <div class="guwen_cardbg">
                    <!--396-->
                    <div class="guweb_l">
                        <div style="width: 100%;height: 50px">
                            <img style="width: 50px;height: 50px;position:absolute;border-radius: 5px" src="{{config.departmentSelf.avatar}}">
                            <p style="color: #666666;font-size: 16px;margin-left: 55px">工作啦校招顾问</p>
                            <p style="color: #333333;font-size: 16px;margin-left: 55px">{{config.departmentSelf.name}}
                            </p>
                        </div>
                        <ul style="margin-top: 15px">
                            <li>
                                <img style="width:20px;height: 20px " src="../../assets/images/kefu_wx.png">
                                <span style="color: #5E4FFF;font-size: 14px;margin-left: 2px;">微信：</span>
                                <span style="color: #666666;font-size: 14px;margin-left: 5px;">{{config.departmentSelf.wx}}</span>
                            </li>
                            <li>
                                <img style="width:20px;height: 20px " src="../../assets/images/kefu_email.png">
                                <span style="color: #5E4FFF;font-size: 14px;margin-left: 2px;">邮箱：</span>
                                <span style="color: #666666;font-size: 14px;margin-left: 5px;">{{config.departmentSelf.email}}</span>
                            </li>
                        </ul>
                    </div>
                    <img class="guweb_r" src="{{config.departmentSelf.qr_code}}}}" />
                </div>
                <p style="margin: 20px 40px 10px 40px;font-size:16px;color: #666666;text-align: center">
                    校招合作、双选会相关问题，请扫码咨询！</p>
            </div>
        </div>
    </p-dialog>


    <!--修改密码弹窗-->
    <p-dialog [closeOnEscape]="false" [(visible)]="showBingLoginEmailModel" [contentStyle]="{'border-bottom-right-radius':'8px','border-bottom-left-radius':'8px'}" [style]="{'width':650}" [modal]=true>
        <p-header>绑定登录邮箱</p-header>
        <div class="sign-list">
            <span>绑定登录邮箱：</span><input type="text" name="bindloginEmail" autocomplete="off" style="width: 250px" [(ngModel)]="bindEmailModel.email" placeholder="请填写邮箱">
            <button class="emailBtn" (click)="getBingEmailCode()">{{sendEmailText2}}</button>
        </div>
        <div class="sign-list">
            <span>输入验证码：</span><input type="text" name="bindloginEmailsms" autocomplete="off" [(ngModel)]="bindEmailModel.code" placeholder="请填写邮箱验证码">
        </div>
        <div class="sign-list">
            <span>密码：</span><input type='password' name="bindloginEmailpsw" autocomplete="off" [(ngModel)]="bindEmailModel.psw" placeholder="请填写密码">
        </div>
        <div class="sign-list">
            <span>确认密码：</span><input type='password' name="bindloginEmailpsw" autocomplete="off" [(ngModel)]="bindEmailModel.psw2" placeholder="请填写确认密码">
        </div>
        <div class="sign-list">
            <div style="font-size: 10px;color: lightgray;margin-top: -15px;margin-left: 130px" class="flex_l">
                <p style="color: red">*</p>格式：长度8位，由不连续的字母和数字2种构成
            </div>
        </div>
        <div class="flex_c">
            <button class="updateEmailBtnClick" (click)="commitBindLoginEmail()">确定</button>
        </div>
    </p-dialog>

</div>