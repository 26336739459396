<!-- 页头部分 -->
<div class="contentRight-header">企业中心 - 账号管理</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <router-outlet></router-outlet>
    <app-model-company-phone #phone [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-company-phone>
    <app-model-cropper #crop [isShow]="modelTwoShow" (onVoted)="ModelTwodismiss($event)"></app-model-cropper>
    <div class="college-block">
        <!-- 页头部分 -->
        <div class="college-block-head">账号管理</div>
        <div class="sign-block" ng-show="myDepartment">
            <!-- 头像 -->
            <div class="sign-list sign-img" *ngIf="myUser">
                <span>头像：</span>
                <label *ngIf="myUser.avatar" class="uploadImg-label department-logo" for="uploadUserAvatar">
                    <img [src]="myUser.avatar">
                </label>
                <label *ngIf="!myUser.avatar" class="uploadImg-label" for="uploadUserAvatar">
                    <img src="assets/images/add-img.png"><br/>点击头像
                </label>
                <input class="uploadImg" id="uploadUserAvatar" (change)="previewPic($event)" type="file" accept="image/jpg,image/png">
                <br/><s>*像素：200*200px,格式：jpg,png</s>
            </div>
            <!-- 姓名 -->
            <div class="sign-list">
                <span>姓名：</span>
                <div *ngIf="isName" class="selected-text disabled-text">{{userData.name}}</div>
                <input *ngIf="!isName" type="text" [(ngModel)]="userData.name" placeholder="请填写姓名"><button (click)="modifyName()">修改</button>
                <s class="remark-text">* 一经认证姓名不可修改</s>
            </div>
            <!-- 性别 -->
            <div class="sign-list select-list">
                <span>性别：</span>
                <select autocomplete="off" [(ngModel)]="userData.gender">
                    <option class="place-text" value='' disabled hidden selected="selected">选择性别</option>
                    <option value="01">男</option>
                    <option value="02">女</option>
                </select>
            </div>
            <!-- 职务 -->
            <div class="sign-list">
                <span>职务：</span><input type="text" [(ngModel)]="userData.post" placeholder="请填写职务">
            </div>
            <!-- 工作邮箱 -->
            <div class="sign-list">
                <span>工作邮箱：</span><input type="text" [(ngModel)]="userData.email" placeholder="请填写工作邮箱">

                <span [ngClass]="{'openInfo':contact_info_switch.is_public_email,'closeInfo':!contact_info_switch.is_public_email}" (click)="setUserInfoOpen('email',true)"><b></b>公开</span>
                <span [ngClass]="{'openInfo':!contact_info_switch.is_public_email,'closeInfo':contact_info_switch.is_public_email}" (click)="setUserInfoOpen('email',false)"><b></b>不公开</span>
            </div>
            <!-- 微信号 -->
            <div class="sign-list">
                <span>微信号：</span><input type="text" [(ngModel)]="userData.weixin" placeholder="请填写微信号">
                <span [ngClass]="{'openInfo':contact_info_switch.is_public_wx,'closeInfo':!contact_info_switch.is_public_wx}" (click)="setUserInfoOpen('wx',true)"><b></b>公开</span>
                <span [ngClass]="{'openInfo':!contact_info_switch.is_public_wx,'closeInfo':contact_info_switch.is_public_wx}" (click)="setUserInfoOpen('wx',false)"><b></b>不公开</span>
            </div>

            <!-- 绑定手机 -->
            <div class="sign-list">
                <span>绑定手机：</span>
                <div class="selected-text disabled-text">{{loginPhone}}</div><button (click)="uploadPhone()">修改</button>
                <span [ngClass]="{'openInfo':contact_info_switch.is_public_mobile,'closeInfo':!contact_info_switch.is_public_mobile}" (click)="setUserInfoOpen('phone',true)"><b></b>公开</span>
                <span [ngClass]="{'openInfo':!contact_info_switch.is_public_mobile,'closeInfo':contact_info_switch.is_public_mobile}" (click)="setUserInfoOpen('phone',false)"><b></b>不公开</span>
            </div>
            <!--<div style="width: 100%;height: 30px;line-height: 30px;">-->
            <!--<input  [checked]="isTuiJian" (click)="tuijianChanged($event)" style="margin-right: 5px;margin-left: 140px" type="checkbox" /> <span style="color: #666666;font-size: 12px;cursor: pointer" (click)="tuijianChanged($event)">若企业被高校推荐到就业信息网，此手机号将被展示，以便学生主动联系。</span>-->
            <!--</div>-->

            <!--登录邮箱-->
            <!--<div class="sign-list" *ngIf="userData.login_email==''">-->
            <!--<span>绑定登录邮箱：</span><input type="text" [(ngModel)]="userData.loginEmail" placeholder="请填写邮箱"><button class="emailBtn" (click)="sendEmail(1)">{{sendEmailText1}}</button>-->
            <!--</div>-->
            <!--<div class="sign-list"  *ngIf="userData.login_email==''">-->
            <!--<span>输入验证码：</span><input type="text" [(ngModel)]="userData.emailCode" placeholder="请填写邮箱验证码"><button class="emailBtn" (click)="virtyEmailCode(1)">验证</button>-->
            <!--</div>-->

            <!--已绑定登录邮箱-->
            <div class="sign-list" *ngIf="userData.login_email">
                <span>登录邮箱：</span><input readonly type="text" [(ngModel)]="userData.login_email" placeholder="">
                <button class="updateEmailBtn" (click)="updateEmail()">修改邮箱</button>
                <button class="updateEmailBtn" (click)="updatePsw()">修改密码</button>
            </div>

            <div class="sign-list">
                <span>{{IsBindWeiChat?"解绑登录微信":"绑定登录微信"}}：</span>
                <button class="deleteWeichat" (click)="showBindWeiChatClick()">{{IsBindWeiChat?"解绑登录微信":"绑定登录微信"}}</button>
            </div>

        </div>
        <!--sign-block-->
        <!-- 保存 -->
        <div class="sign-submit">
            <button class="purple" (click)="updateUser()">保存</button>
        </div>
    </div>
    <!--college-block-->
</div>
<!--homepage-block-->

<!--修改邮箱-->
<p-dialog [closeOnEscape]="false" [(visible)]="updateLoginEmailModel" [style]="{'width':650,'height':400,'minHeight':400}" [modal]=true>
    <p-header>修改邮箱 </p-header>
    <div class="sign-list">
        <span>原邮箱号：</span><input readonly type="text" [(ngModel)]="updateEmailData.oldEmail" placeholder="请填写工作邮箱">
    </div>
    <div class="sign-list">
        <span>新邮箱号：</span><input type="text" [(ngModel)]="updateEmailData.newEmail" placeholder="请填写新邮箱">
    </div>
    <div class="sign-list">
        <span>验证码：</span><input style="width: 240px" type="text" [(ngModel)]="updateEmailData.newEmailCode" placeholder="请填写验证码">
        <button class="emailBtn" (click)="sendEmail(2)">{{sendEmailText2}}</button>
    </div>
    <button class="updateEmailBtnClick" (click)="saveEmail()">保存</button>
    <p style="color: red;text-align: center;margin-top: 5px">*&nbsp;修改后，请使用新邮箱登录工作啦</p>

</p-dialog>

<!--修改密码弹窗-->
<p-dialog [closeOnEscape]="false" [(visible)]="updateLoginEmailPsw" [style]="{'width':650,'height':400,'minHeight':400}" [modal]=true>
    <p-header>修改密码 </p-header>
    <div class="sign-list">
        <span>原密码：</span><input type={{seePswType}} [(ngModel)]="updatePswData.oldPsw" placeholder="请填写原密码">
        <img *ngIf="seePswType=='text'" (click)="changeSeePsw(1,'password')" style="width: 25px;height: 25px;margin-left: 10px" src="../../assets/images/no_seePsw.png">
        <img *ngIf="seePswType=='password'" (click)="changeSeePsw(1,'text')" style="width: 25px;height: 25px;margin-left: 10px" src="../../assets/images/seePsw..png">
    </div>
    <div class="sign-list">
        <span>新密码：</span><input type={{seePswType2}} [(ngModel)]="updatePswData.newPsw" placeholder="请填写新密码">
        <img *ngIf="seePswType2=='text'" (click)="changeSeePsw(2,'password')" style="width: 25px;height: 25px;margin-left: 10px" src="../../assets/images/no_seePsw.png">
        <img *ngIf="seePswType2=='password'" (click)="changeSeePsw(2,'text')" style="width: 25px;height: 25px;margin-left: 10px" src="../../assets/images/seePsw..png">
    </div>
    <div class="sign-list">
        <span>确认密码：</span><input type={{seePswType3}} [(ngModel)]="updatePswData.okPsw" placeholder="请填写确认密码">
        <img *ngIf="seePswType3=='text'" (click)="changeSeePsw(3,'password')" style="width: 25px;height: 25px;margin-left: 10px" src="../../assets/images/no_seePsw.png">
        <img *ngIf="seePswType3=='password'" (click)="changeSeePsw(3,'text')" style="width: 25px;height: 25px;margin-left: 10px" src="../../assets/images/seePsw..png">
    </div>
    <div class="sign-list">
        <div style="font-size: 10px;color: lightgray;margin-top: -15px;margin-left: 130px" class="flex_l">
            <p style="color: red">*</p>格式：长度8位，由不连续的字母和数字2种构成</div>
    </div>
    <button class="updateEmailBtnClick" (click)="savePsw()">保存</button>
</p-dialog>

<!--绑定登录邮箱成功-->
<p-dialog [(visible)]="BindEmailSuccessModel" [style]="{'width':300,'height':180,'minHeight':180}" [modal]=true>
    <p-header>绑定邮箱</p-header>
    <p style="text-align: center;font-size: 25px;color:#5d479a;line-height: 15px;margin: 5px">绑定成功！</p>
    <p style="text-align: center;font-size: 14px;color: red;line-height: 15px;margin-top: 15px">默认密码为Asd123456</p>
    <p style="text-align: center;font-size: 14px;color: red;line-height: 15px;margin-top: 10px">请用邮箱账号登录后修改</p>
</p-dialog>

<p-dialog [closeOnEscape]="false" [showHeader]="false" [dismissableMask]="false" [contentStyle]="{'padding':'0',border:0}" [(visible)]="showBingWeiChatModel" [style]="{'width':373,'height':242,'minHeight':242,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"
    [modal]=true>
    <div class="selteTypeModel" style="width: 373px">
        <div class="selteTypeModelTitle flex_s" style="font-size: 14px;width: 373px"><span>使用微信扫码关注公众号即可绑定登录微信</span><img (click)="closeCheckWeiChat()" src="../../assets/images/close_default.png" /></div>
        <div class="wxBorder flex_c">
            <img *ngIf="weChatCode" src={{weChatCode}} style="width: 127px;height: 127px" />
            <img *ngIf="checkWeChatIndex==61" (click)="startCheckBingWx()" src="../../assets/images/pay-refresh.png" class="rush" />
        </div>
    </div>
</p-dialog>

<p-dialog [closeOnEscape]="false" [showHeader]="false" [dismissableMask]="false" [contentStyle]="{'padding':'0',border:0}" [(visible)]="showDeleteBingWeiChatModel" [style]="{'width':438,'height':260,'minHeight':260,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"
    [modal]=true>
    <div class="selteTypeModel">
        <div class="selteTypeModelTitle flex_s"><span>解绑登录微信</span><img (click)="closeBingWeiChat()" src="../../assets/images/close_default.png" /></div>
        <div class="bingWeiChatCell marginTop20 flex_l">
            <div class="wcLeft">绑定手机：</div>
            <div class="wcRight flex_l">
                <img class="wxIcon" src="../../assets/images/bindWXphone.png" />
                <input class="wxInput" disabled [(ngModel)]="deleteWX.phone" />
            </div>
        </div>
        <div class="bingWeiChatCell marginTop24 flex_l">
            <div class="wcLeft">验证码：</div>
            <div class="wcRight flex_l">
                <img class="wxIcon" src="../../assets/images/bindWXcode.png" />
                <input class="wxInput" [(ngModel)]="deleteWX.code" />
                <div class="wxsendSms" (click)="sendSms()">{{countDown}}</div>
            </div>
        </div>
        <div class="bindWeiChatBtn" (click)="deleteWxBing()">解除绑定</div>
    </div>
</p-dialog>


<p-dialog [(visible)]="openProTip" [style]="{'width':300,'height':180,'minHeight':180}" [contentStyle]="{'border-bottom-right-radius':'6px','border-bottom-left-radius':'6px'}" [modal]=true>
    <p-header>提示</p-header>
    <div style="padding-bottom: 16px;">您当前账号已提交工作啦认证，您可登录工作啦pro端修改</div>
    <div class="proClos">
        <button class="closBtnClick" (click)="openProTip = false">关闭</button>
    </div>
</p-dialog>

<p-dialog [(visible)]="openModify" [style]="{'width':300,'height':180,'minHeight':180}" [contentStyle]="{'border-bottom-right-radius':'6px','border-bottom-left-radius':'6px'}" [modal]=true>
    <p-header>修改</p-header>
    <div class="openModify">
        <div class="Tip">Tip：修改成功后，以往本企业申请过的所有高校合作都将变更为审核拒绝状态，需要企业重新提交高校合作</div>
        <div class="nameModify">
            <input type="text" [(ngModel)]="modifyValue" placeholder="请填写新的联系人姓名">
        </div>
        <div class="proClos">
            <button class="closBtnClick" (click)="openModify = false">取消</button>
            <button class="saveBtnClick" (click)="saveBtnClick()">保存</button>
        </div>
    </div>

</p-dialog>