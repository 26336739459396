import { Component, OnInit } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute,Router } from  '@angular/router';

declare var window;
declare let laydate;
@Component({
  selector: 'app-position-rules-editor',
  templateUrl: './position-rules-editor.component.html',
  styleUrls: ['./position-rules-editor.component.css']
})
export class PositionRulesEditorComponent implements OnInit {
  public config: Config;
  public publishTitle="发布招聘简章";
  public isShowDownLoadAPP=false;

  public myDepartment:any;//企业信息
  public model={
    name:'',//标题
    content:'',//说明
    collegeid:'',
    effect_time:'',
  }
  public toolConfig: any;//编辑器配置
  public briefDetais:any;//详情
  public isLoading = false;

  //高校合作属性
  public  CollegeIdentityModel = {
    status:10086,
    no_apply_30day:false,
    remark:''
  };
  public showTab1 = false;
  public showTab2 = false;
  public dmodel={
    collegeid:'',
    facultyid:'',//院系id
    proxy_document:'',//招聘委托书
    business_licence:'',//企业营业执照
    id_card_front:'',//身份证正面
    register_number:'',//营业执照注册号
    brief:"",//申请简述
    office_phone:'',//固定电话
    school_permit:"",
  };
  public secureMaterail:any;//招聘委托书下载链接
  public collegeName="";
  public orgList_shenhe=[];//院系列表
  public authcation_office_phone=false;

  public completeDate = "";//日期
  public isSend = false;//是否点击了发送按钮
  public college:any;//高校信息
  public applyDetails:any;//申请数据
  public orgList=[];//院系列表
  public positionList=[];//选中职位列表
  public majorList=[];//专业列表（院级）
  public collegeMajorList=[];//专业列表（校级）
  public campusList=[];//校区列表
  public audit_switch = { // 高校开关列表
    department_receipt:''
  };
  //提交资质审核后，不可再次提交
  public IdentityLoading = false;

  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();
      this.toolConfig= {//编辑器配置
        // toolbars: [
        //   [
        //       'drafts', // 从草稿箱加载
        //       'charts', // 图表
        //   ]
        // ],
        elementPathEnabled: false, //删除元素路径
        initialFrameHeight: 500
      };
    }

  ngOnInit() {
    this.opera.initConfig('position','position-rules');
    if(this.local.get(Constants.briefDetais)){
       this.briefDetais=JSON.parse(this.local.get(Constants.briefDetais));
       this.model.name=this.briefDetais.name;
       this.model.content=this.briefDetais.content;
       this.publishTitle="修改招聘简章";
    }
    this.getDepartment();//获取企业信息
    let collegeid=this.local.get('collegeid')+'';
    this.model.collegeid = collegeid;
    if(this.model.collegeid)
    {
      this.dmodel.collegeid = collegeid

      this.singleIdentity( this.model.collegeid)
      this.getCollageDetails();
    }
    let that = this;
    laydate.render({
      elem: '#effect-time',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm',
      done: function (value, date) {
        that.model.effect_time = value;
      }
    })
  }
  //单个高校认证信息
  singleIdentity(collegeid){
    if(!collegeid)
    {
      return;
    }
    this.dataApi.lastCollegeAuthenticationIdentity(collegeid).then((res:any)=>{
      console.log("获取上次认证信息",res);
      if(res)
      {
        this.CollegeIdentityModel = res;
        if(res.facultyid){
          this.dmodel.facultyid = res.facultyid;
        }
        this.dmodel.business_licence = res.business_license;
        this.dmodel.id_card_front = res.id_card_front;
        this.dmodel.register_number = res.register_number;
        if(res.proxy_document){
          this.dmodel.proxy_document = res.proxy_document;
        }
        if(res.brief)
        {
          this.dmodel.brief = res.brief;
        }


        if(this.CollegeIdentityModel.status==2||this.CollegeIdentityModel.status==1)
        {
          this.showTab1 = false;
          this.showTab2 = true;

          if(this.briefDetais){
            //设置招聘简章表单的状态
            console.log('this.briefDetais',this.briefDetais)
            if(this.briefDetais.audit[0].audit_status==1)
            {
              this.showTab1 = false;
              this.showTab2 = false;
            }
          }
        }
        else if(this.CollegeIdentityModel.status==3)
        {

          if(this.CollegeIdentityModel.no_apply_30day==true)
          {
            this.showTab1 = false;
            this.showTab2 = false;
          }
          else
          {
            this.showTab1 = true;
            this.showTab2 = false;
          }

        }
        else
        {
          this.showTab1 = true;
          this.showTab2 = false;
          //没有进行高校合作//

        }
        console.log("获取上次认证信息",res);

      }
      else
      {
        this.showTab1 = true;
        this.showTab2 = false;
        this.CollegeIdentityModel.status =0;
      }
    }).catch((error)=>{
      console.log("获取上次认证信息error",error);
      this.opera.loadiss();
    })

  }
  // 获取高校信息
  getCollageDetails(){
    this.opera.loading();
    if(!this.local.get(Constants.collegeid))
    {
      return;
    }
    this.dataApi.getCollageDetails(this.local.get(Constants.collegeid)).then((res:any)=>{
      console.log("获取到高校信息",res);
      this.opera.loadiss();
      if(res)
      {
        // this.secureMaterail = res.audit_switch.proxy_document;
        this.secureMaterail = res.audit_switch.proxy_document_url;

        if(res.audit_switch.faculty_authcation){
          this.getOrgList_shenhe();
        }else{
          this.collegeName = res.name;
        }
      }

    }).catch((error)=>{
      // console.log(error);
      this.opera.goChangeRouter('homepage');
      this.toastr.error(error);
      this.opera.loadiss();
    })
  }
  preview_auc(event,name) {
    let imgData  = event.target.files[0]; //获取file对象
    if (parseInt(imgData.size)/1024 > 1024*5) {
      this.toastr.warning('该图片大小超出了5M，请重新上传');
      return ;
    }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.imageCropper_auc(reader.result,name);
      };
    }
  }
  //上传
  imageCropper_auc(imageData,name){
    imageData=imageData.replace(/^(data:image)\/png;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
    this.opera.loading();//加载动画
    this.dataApi.uploadDepartmentImg({value: imageData}).then((res:any)=>{
      if(res){
        if(res.value){//------------上传之后赋值
          this.dmodel[name] = res.value;
        }
      }
      this.opera.loadiss();//停止加载动画
      setTimeout(() => {
        this.toastr.success('上传成功');
      },600);
    }).catch((error)=>{
      this.opera.loadiss();//停止加载动画
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    })
  }
  //验证手机号码格式
  checkPhone(phone){
    let phoneModel={
      format_type:1,//0:手机号，1：手机号&固话；2.邮箱
      data:phone
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(!res.value){
        this.toastr.warning("联系电话格式错误");
        return;
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    })
  }
  getOrgList_shenhe(){
    this.opera.loading();
    if(!this.local.get(Constants.collegeid))
    {
      return;
    }
    this.dataApi.getCollegePart(this.local.get(Constants.collegeid)).then((res:any)=>{
      //console.log("获取院系列表---");
      //console.log(res);
      this.orgList_shenhe = res;
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
    })
  }
  //提交认证
  uploadDepartmentIdentity(){
    if(!this.collegeName){
      if(!this.dmodel.facultyid){
        this.toastr.info("请选择当前申请学院");
        return;
      }
    }
    let filter1 = /^[0-9a-zA-Z]{9}$/;
    let filter2 = /^[0-9a-zA-Z]{15}$/;
    let filter3 = /^[0-9a-zA-Z]{18}$/;
    if(!this.dmodel.business_licence){
      this.toastr.warning('请上传营业执照图片');
      return;
    }
    if(!this.dmodel.id_card_front){
      this.toastr.warning('请上传身份证正面');
      return;
    }
    if(!this.dmodel.register_number){
      this.toastr.warning('请填写统一社会信用代码');
      return;
    }
    if(!this.opera.isRegister_number(this.dmodel.register_number)){
      this.toastr.warning('统一社会信用代码格式不正确');
      return;
    }

    if(this.authcation_office_phone && !this.dmodel.office_phone){
      this.toastr.warning('请填写办公电话');
      return;
    }
    if(this.secureMaterail){
      if(!this.dmodel.proxy_document){
        this.toastr.info("请上传招聘委托书");
        return;
      }
    }
    if(this.myDepartment.industry=='0604'||this.myDepartment.industry=='0605'||this.myDepartment.industry=='0606')
    {
      if(!this.dmodel.school_permit){
        this.toastr.info("请上传办学许可证");
        return;
      }
    }
    this.opera.loading();//加载动画
    console.log("申请院系",this.dmodel);
    if(this.IdentityLoading)
    {
      this.toastr.success('提交中');
      return;
    }
    this.dataApi.uploadDepartmentIdentity(this.dmodel).then((res:any)=>{
      this.opera.loadiss();//停止加载动画
      this.IdentityLoading = false;
      setTimeout(() => {
        this.toastr.success('提交成功');
        this.singleIdentity(this.dmodel.collegeid)//通过高校id获取高校详情
        this.showTab1 = false;
        this.showTab2 = true;
      },600);
    }).catch((error) => {
      this.IdentityLoading = false;
      this.toastr.error(error);
      this.opera.loadiss();//停止加载动画
      //跳转页面
    });
  }
  //获取企业信息
  getDepartment(){
    if(this.dataApi.getLocalDepartment()){
          this.myDepartment = this.dataApi.getLocalDepartment();
        }else{
          this.dataApi.getDepartment().then((res:any)=>{
          this.dataApi.setLocalDepartment(res);
        }).catch((error) => {
          //console.log(error);
          this.toastr.error(error);
      });
      }
  }
  //提交
  goSubmit(){
    console.log(this.model);
    if(!this.model.name){
       this.toastr.warning("招聘简章标题为必填项");
       return;
    }
    if(!this.model.content){
      this.toastr.warning("招聘简章内容为必填项");
      return;
    }
    if(!this.model.effect_time){
      this.toastr.warning("请选择招聘简章到期时间");
      return;
    }
    this.opera.loading();
    if(this.briefDetais){
      this.dataApi.editorRules(this.briefDetais.briefid,this.model).then((res:any)=>{
        this.toastr.success("修改成功");
        this.router.navigateByUrl('/home/position-rules');
        this.local.remove(Constants.briefDetais);
        this.opera.loadiss();
      }).catch((error) => {
        this.toastr.error(error);
        this.opera.loadiss();
      });
    }else{
      this.dataApi.creatRules(this.model).then((res:any)=>{
        this.toastr.success("发布成功");
        this.opera.loadiss();
        this.router.navigateByUrl('/home/position-rules');

      }).catch((error) => {
        this.toastr.error(error);
        this.opera.loadiss();
      });
    }

  }
  cloceDownLoad(){
    console.log("cloceDownLoad",this.isShowDownLoadAPP);
    this.router.navigateByUrl('/home/position-rules');

    this.isShowDownLoadAPP= false;

  }

}
