<!-- 页头部分 -->
<!--<div class="contentRight-header"><img (click)="opera.goNewRouter('college-details')" src='assets/images/router-old.png'/>校园招聘<span *ngIf="!config.isByCollege"> - 校园+</span> - 学校详情 - 双选会列表</div>-->
<!-- 主体内容部分 -->
<div class="homepage-block">


    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-college-milksign [number]="milkNumber" [mridList]="mridList" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-milksign>
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <div class="college-block" style="margin-bottom:20px;">
        <div class="pageTitle"> 校园招聘 - 双选会</div>

        <!-- <div class="notification">
            <img src="assets/images/noticotion.png" />
            <span>进入工作啦官网，可一站式申请、管理百所高校双选会 <span  (click)="gotoPro()" class="check">点击查看 <img src="assets/images/notMore.png" /></span></span>
        </div> -->

        <div style="width: 100%;height: 40px;margin-top: 20px;">
            <span style="height: 40px;line-height: 40px;color: #333333;font-size: 20px;float: left">我的申请记录</span>
            <span style="height: 40px;line-height: 40px;color: #5e4fff;font-size: 14px;float: right;cursor: pointer" (click)="gotoApplyHistory()">查看全部申请记录 >></span>
        </div>
        <div *ngIf="historyMilkRoundList.length==0" class="historyMilkRoundList_noData">
            <img src="assets/images/campnull.png"><br /> 暂无
        </div>
        <div class="college-list-cont" *ngIf="historyMilkRoundList.length>0">
            <!--//新版列表-->
            <div class="listCell" *ngFor="let n of historyMilkRoundList;let i = index;">
                <div style="width: 45%;cursor: pointer;display: flex;align-items: center;overflow: hidden" (click)="goDeatils(n.mrid)">
                    <img style="width: 50px;height: 50px;border-radius: 50%;margin-left: 30px" src="{{n.logo}}" />
                    <div>
                        <div style="display: flex;align-items: center">
                            <div style="margin-left: 15px;font-size: 17px;color:#333333;  overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width: 220px;font-weight: bold">
                                {{n.name}}</div>
                            <span class="gongyi" *ngIf="n.is_welfare">专场</span>
                            <img class="sign-img uglyImg" *ngIf="n.loadState==1" src="../../assets/images/sign1.png" />
                            <img class="sign-img" *ngIf="n.loadState==2" src="../../assets/images/sign2.png" />
                            <img class="sign-img" *ngIf="n.loadState==4" src="../../assets/images/sign4.png" />
                            <img class="sign-img" *ngIf="n.loadState==5" src="../../assets/images/sign5.png" />
                        </div>
                        <p class="milkRoundAddressAndTime">
                            <img src="../../assets/images/addressIcon.png" style="width: 12px;height: 16px;margin-top: -3px"> {{(n.milkround_type == 2?"线上":n.place_name?n.place_name:'暂无')+" 举办时间："}}{{n.start_time| date:"yyyy-MM-dd HH:mm"}}
                        </p>
                    </div>
                </div>
                <!-- <div style="width: 17%;min-width: 175px" (click)="goDeatils(n.mrid)">
                    <div style="margin-top: 4px;color: #999999;font-size: 13px;text-overflow: ellipsis;white-space: nowrap;">{{"举办学校:"}} {{n.college_name}}</div>
                </div> -->
                <div style="width: 17%;min-width: 175px">
                    审核状态：
                    <span style="cursor: pointer;" *ngIf="n.audit_status=='to_audit'" (click)="goDeatils(n.mrid)">审核中</span>
                    <span style="cursor: pointer; color: #FF5555;" *ngIf="n.audit_status=='not_passed'" (click)="goDeatils(n.mrid)">审核失败</span>
                    <span style="color: #30c742;" *ngIf="n.audit_status=='passed'">审核通过</span>
                    <span style="cursor: pointer;" *ngIf="n.audit_status=='revoke'">已撤回申请</span>
                </div>
                <div style="width: 38%;">
                    <div class="cellButton shibai bordeRadius" *ngIf="n.audit_status=='to_audit'" (click)="openRevoke(n.applyid)">撤回</div>
                    <div class="cellButton baoming bordeRadius" *ngIf="n.showBaoMingBtn &&(n.loadState != 1 && n.loadState != 5)" (click)="goSign(n)">我要报名</div>
                    <div class="cellButton bordeRadius" (click)="okCanhui(n)" *ngIf="n.showSuccessBtn &&n.feedback_status=='no_feedback'">{{n.milkround_type == 2?'进入会场':'确认参会'}}
                    </div>
                    <div class="cellButton bordeRadius" (click)="ConfirmMilkroundCommit2(n)" *ngIf="n.showSuccessBtn&&n.feedback_status=='join'&&n.milkround_type != 2">已确认</div>
                    <div class="cellButton shibai bordeRadius" *ngIf="n.showSuccessBtn&&n.feedback_status=='no_join'">
                        已拒绝</div>
                    <div class="cellButtonYellow zhanwei bordeRadius" *ngIf="n.showSuccessBtn&&n.feedback_status=='join'&&n.milkround_type != 2">
                        {{n.seat_number==0?"展位号暂未分配":'展位号：'+n.zone_name_string+n.seat_number}}</div>
                    <div class="cellButton shenhe bordeRadius" *ngIf="n.showShenHeBtn" (click)="goDeatils(n.mrid)">查看详情
                    </div>
                    <div class="cellButton baoming bordeRadius" *ngIf="n.showFaileBtn" (click)="goSign(n)">重新申请</div>
                    <button class="cellButton bordeRadius" (click)="downloadPoster(n.applyid)" *ngIf="n.status == 'passed' && !n.isEnd && n.poster_type">下载海报</button>
                    <img class="air-icon" src="../../assets/images/air_icon.png" *ngIf="n.milkround_type == 2">
                </div>
            </div>
        </div>

        <div style="width: 100%;height: 40px;margin:20px 0;">
            <span style="height: 40px;line-height: 40px;color: #5d479a;font-size: 20px;float: left">其他双选会</span>
            <button class="bordeRadius" (click)="chooseType('')" style="margin-left: 15px" [class.purple]="model.milkround_type==''">所有</button>
            <button class="bordeRadius" (click)="chooseType('2')" [class.purple]="model.milkround_type=='2'">线上</button>
            <button class="bordeRadius" (click)="chooseType('1')" [class.purple]="model.milkround_type=='1'">线下</button>
        </div>
        <div style="margin-top: 14px;padding: 0" class="clear">
            <div class="left date  dataBtn">
                <button class="bordeRadius" (click)="chooseDate('all')" [class.purple]="dateSattus=='all'">全部</button>
                <button class="bordeRadius" (click)="chooseDate('now')" [class.purple]="dateSattus=='now'">本月</button>
                <button class="bordeRadius" (click)="chooseDate('next')" [class.purple]="dateSattus=='next'">下月</button>
            </div>
        </div>

        <!-- 中间部分 -->
        <!-- 没有内容存在时 -->
        <div *ngIf="countList==0" class="historyMilkRoundList_noData">
            <img src="assets/images/campnull.png"><br /> 暂无
        </div>

        <div class="college-list-cont" *ngIf="milkRoundList.length>0">
            <!--//新版列表-->
            <div class="listCell" *ngFor="let n of milkRoundList;let i = index;">
                <div style="width: 45%;cursor: pointer;display: flex;align-items: center;overflow: hidden" (click)="goDeatils(n.mrid)">
                    <img style="width: 50px;height: 50px;border-radius: 50%;margin-left: 30px" src="{{n.logo}}" />
                    <div>
                        <div style="display: flex;align-items: center">
                            <div style="margin-left: 15px;font-size: 17px;color:#333333;  overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width: 220px;font-weight: bold">
                                {{n.name}}</div>
                            <span class="gongyi" *ngIf="n.is_welfare">专场</span>
                            <img class="sign-img uglyImg" *ngIf="n.loadState==1" src="../../assets/images/sign1.png" />
                            <img class="sign-img" *ngIf="n.loadState==2" src="../../assets/images/sign2.png" />
                            <img class="sign-img" *ngIf="n.loadState==4" src="../../assets/images/sign4.png" />
                            <img class="sign-img" *ngIf="n.loadState==5" src="../../assets/images/sign5.png" />
                        </div>
                        <p class="milkRoundAddressAndTime">
                            <img src="../../assets/images/addressIcon.png" style="width: 12px;height: 16px;margin-top: -3px"> {{(n.milkround_type == 2?"线上":n.place_name?n.place_name:'暂无')+" 举办时间："}}{{n.start_time| date:"yyyy-MM-dd HH:mm"}}
                        </p>
                    </div>
                </div>
                <div style="width: 17%;min-width: 175px" (click)="goDeatils(n.mrid)">
                    <div style="margin-top: 4px;color: #999999;font-size: 13px;text-overflow: ellipsis;white-space: nowrap;">
                        {{"举办学校:"}} {{n.college_name}}</div>
                </div>
                <div style="width: 38%;">
                    <div class="cellButton baoming bordeRadius" *ngIf="n.showBaoMingBtn &&(n.loadState != 1 && n.loadState != 5)" (click)="goSign(n)">我要报名</div>
                    <div class="cellButton bordeRadius" (click)="okCanhui(n)" *ngIf="n.showSuccessBtn &&n.feedback_status=='no_feedback'">{{n.milkround_type == 2?'进入会场':'确认参会'}}
                    </div>
                    <div class="cellButton bordeRadius" (click)="ConfirmMilkroundCommit2(n)" *ngIf="n.showSuccessBtn&&n.feedback_status=='join'&&n.milkround_type != 2">已确认</div>
                    <div class="cellButton shibai bordeRadius" *ngIf="n.showSuccessBtn&&n.feedback_status=='no_join'">
                        已拒绝</div>
                    <div class="cellButtonYellow zhanwei bordeRadius" *ngIf="n.showSuccessBtn&&n.feedback_status=='join'&&n.milkround_type != 2">
                        {{n.seat_number==0?"展位号暂未分配":'展位号：'+n.zone_name_string+n.seat_number}}</div>
                    <div class="cellButton shenhe bordeRadius" *ngIf="n.showShenHeBtn" (click)="goSign(n)">审核中</div>
                    <div class="cellButton shibai bordeRadius" *ngIf="n.showFaileBtn" (click)="goSign(n)">报名失败</div>
                    <button class="cellButton bordeRadius" (click)="downloadPoster(n.applyid)" *ngIf="n.status == 'passed' && !n.isEnd && n.poster_type">下载海报</button>
                    <img class="air-icon" src="../../assets/images/air_icon.png" *ngIf="n.milkround_type == 2">
                </div>
            </div>
        </div>

        <!-- 分页部分 -->
        <div class='paging-block' [class.isPageHeight]="countList>0">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>
    </div>

    <p-dialog [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'width':600,'minHeight':300,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="showConfirmMilkroundModel">
        <div style="width: 600px;padding-bottom: 30px; background-color: white;overflow: hidden;" *ngIf="currentMilkround">

            <p style="font-size: 23px;text-align: center;margin-top: 40px;">双选会确认函</p>
            <p style="font-size: 20px;width: 300px;margin-left: 150px;text-align: center;margin-top: 30px;">
                恭喜您，您申请的双选会"{{currentMilkround.name}}"已通过高校审核，请确认是否参加</p>
            <button (click)="ConfirmMilkroundCommit('no_join')" style="float: left;margin-left: 120px;width: 100px;background-color: gray;color: white;margin-top: 40px">不参加</button>
            <button (click)="ConfirmMilkroundCommit('join')" style="float: right;margin-right: 120px;width: 100px;background-color: #5e4fff;color: white;margin-top: 40px">参加</button>
        </div>
    </p-dialog>

    <p-dialog [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'width':300,'height':532,'minHeight':532,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="showMilkCode">
        <div style="width: 300px;height: 532px;background-color: white;overflow: hidden;">
            <img (click)="closeModel()" src="{{QrCodeURL}}" style="width: 300px;height: 532px;float: right;margin-top: 0px;margin-right: 0px;background-color: #ffffff">
        </div>
    </p-dialog>

    <p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="revokeModule" [modal]=true>
        <div class="showTopingBox">
            <div class="closIcon" (click)="revokeModule = false"><img src="/assets/images/v3/contactMeClos.png"></div>
            <div class="title">
                <div class="tipText">
                    <div class="name">撤回报名</div>
                </div>
            </div>
            <div class="p2">确定撤回该双选会报名吗？</div>
            <div class="btns">
                <div class="btn active" (click)="revokeModule = false">取消</div>
                <div class="btn blueStreamer" (click)="downMilk()">确定</div>
            </div>
        </div>
    </p-dialog>