<div class="homepage-block">
    <router-outlet></router-outlet>
    <div class="box ">
        <p class="titleImage">{{airCampustalk?.title}}</p>
        <div class="rightMenu flex_r">
            <div class="flex_c" *ngIf="airCampustalk?.college_audit_status==2" (click)="downLineClick()"><img src="../../assets/images/air_downLine.png" />下线</div>
            <div class="flex_c" *ngIf="airCampustalk?.college_audit_status==3&&airCampustalk?.isApply" (click)="editAirCampClick()"><img src="../../assets/images/air_edit.png" />编辑</div>
            <div class="flex_c" *ngIf="airCampustalk?.college_audit_status==2&&airCampustalk?.isApply" (click)="editAirCampClick()"><img src="../../assets/images/air_edit.png" />编辑</div>
            <div class="flex_c" *ngIf="airCampustalk?.college_audit_status==2" (click)="shareAirCampClick()"><img src="../../assets/images/air_share.png" />分享</div>
        </div>

        <div class="topCard">
            <div class="videoBox">
                <div class="videoBox_h1">宣传视频：</div>
                <div class="video_content">
                    <div id="mod_player"></div>
                </div>
                <div class="video_Bottom flex_s">
                    <div class="video_Bottom_l flex_l"><img src="../../assets/images/air_eye.png" />{{airCampustalk?.video_play_count}}人观看</div>
                </div>
            </div>
            <div class="video_r">

                <div class="video_row">
                    <div class="video_row_l">宣讲会主题：</div>
                    <div class="video_row_r flex_l">{{airCampustalk?.title}}</div>
                </div>

                <div class="video_row">
                    <div class="video_row_l">宣讲会介绍：</div>
                    <div class="video_row_r flex_l">{{airCampustalk?.desc}}</div>
                </div>

                <div class="video_row">
                    <div class="video_row_l">招聘职位：</div>
                    <div class="video_row_r flex_l">
                        <div class="video_row_r_position" *ngFor="let n of airCampustalk?.positions">{{n.name}}</div>
                    </div>
                </div>

                <div class="video_row">
                    <div class="video_row_l">意向专业：</div>
                    <div class="video_row_r flex_l">
                        <div class="video_row_r_yixiang" *ngFor="let n of airCampustalk?.majors">{{n.text}}</div>
                        <div class="video_row_r_yixiang" *ngIf="airCampustalk?.majors.length==0">不限</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="gotogwl flex_c" *ngIf="AllresumeCount>0&&airCampustalk?.college_audit_status==2" (click)="gotogwlAirCamp()">
            <img src="../../assets/images/gwl_link.png">还有<span>{{AllresumeCount}}</span>名学生查看了您，但并未投递简历,请到<span>工作啦平台</span><img src="../../assets/images/click.png">查看
        </div>

        <div class="shenheFile" *ngIf="airCampustalk?.college_audit_status==3">
            <img class="shenheFileIcon" src="../../assets/images/air_fail.png" />
            <p class="shenheFile_h1">很可惜，您发布的空中宣讲会未通过审核！</p>
            <p class="shenheFile_h2" *ngIf="airCampustalk?.college_audit_status==3">
                <span class="flex_c">{{airCampustalk.college_audit_reason}}</span></p>
            <button class="resetCommit" *ngIf="airCampustalk.isApply" (click)="editAirCampClick()">重新提交</button>
        </div>

        <div class="shenheding" *ngIf="airCampustalk?.college_audit_status==1">
            <img class="shenhedingImage" src="../../assets/images/airloading.png" />
            <p class="shenheding_h1">空中宣讲会审核中…</p>
        </div>

        <div class="tabBox" *ngIf="airCampustalk?.college_audit_status==2">
            <div class="tab flex_l">
                <div (click)="tabClick('1')" style="margin-left: 70px" [ngClass]="{currentTab:currentTab=='1',currentTab_none:currentTab!='1'}">投递过我的学生<span *ngIf="currentTab=='1'" class="tabLine"></span></div>
                <div (click)="tabClick('2')" [ngClass]="{currentTab:currentTab=='2',currentTab_none:currentTab!='2'}">互动<span *ngIf="currentTab=='2'" class="tabLine"></span></div>
            </div>

            <div class="tabContent" *ngIf="currentTab=='1'">
                <div class="positionView">
                    <div (click)="goDetails(n.resume_number)" class="positionItem" *ngFor="let n of resumeList;let i = index;">
                        <div class="positionItem_Top">
                            <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}" />
                            <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png" />
                            <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png" />
                            <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png" />
                            <div class="info">
                                <div class="name">{{n.talent_name}}</div>
                                <div class="school">{{n.graduate_university}}</div>
                            </div>
                        </div>
                        <div class="positionItem_Center">
                            <div class="tag">
                                <div class="item">
                                    <img src="../../assets/images/major.png" class="icon" />
                                    <span>{{n.major_text}}</span>
                                </div>
                                <div class="item">
                                    <img src="../../assets/images/education.png" class="icon" />
                                    <span>{{n.education_text}}</span>
                                </div>
                            </div>
                            <div class="skill">
                                <div *ngFor="let youshi of n.youshiArr | slice:0:4;let i = index;" class="item">{{youshi}}</div>
                            </div>
                        </div>
                        <div class="positionItem_Bottom">
                            <a title="可以将职位定向推送给该学生" class="positionItem_Bottom_item purple">查看简历</a>
                        </div>
                    </div>
                </div>
                <div id="resumePage" *ngIf="AllresumeCount>0" style="float: right;margin-right: 0.5%"></div>
                <div class="noneTalent" *ngIf="AllresumeCount==0">
                    <img src="../../assets/images/air_noneTalent.png" />
                    <div class="noneTalent_h1">无学生</div>
                </div>
            </div>

            <div class="tabContent" *ngIf="currentTab=='2'">
                <div class="subTab flex_l">
                    <div [ngClass]="{subTab_div_s:questionTab=='1',subTab_div:questionTab!='1'}" (click)="questionTabTabClick('1')">未回答</div>
                    <div [ngClass]="{subTab_div_s:questionTab=='2',subTab_div:questionTab!='2'}" (click)="questionTabTabClick('2')">已回答</div>
                    <div class="addNewquestion flex_c" (click)="addNewquestionModelClick('add',null)"><img src="../../assets/images/addwen.png" />新增问答</div>
                </div>
                <div class="questionMenu flex_l" *ngIf="questionTab=='2' &&AllquestionCount>0">
                    <div class="flex_l" (click)="allSeletequestionClick()">
                        <img *ngIf="!isALlSeletequestion" src="../../assets/images/air_none.png" />
                        <img *ngIf="isALlSeletequestion" src="../../assets/images/air_check.png" /> 全选
                    </div>
                    <div *ngIf="seleteQuestionList.length>0" (click)="questionMenuClick('open')">批量公开</div>
                    <div *ngIf="seleteQuestionList.length>0" (click)="questionMenuClick('hiden')">批量隐藏</div>
                </div>
                <div class="questionCell flex_s" *ngFor="let n of questionList">
                    <img class="question_check" *ngIf="n.selete" src="../../assets/images/check_qusition.png" />
                    <div class="questionCell_l" (click)="questionCellClick(n)">
                        <div class="questionCell_h1 flex_l" style="margin-bottom: 7px"><img src="../../assets/images/wen.png" /><span> {{n.question}}</span></div>
                        <div class="questionCell_h1 flex_l" *ngIf="questionTab=='2'"><img src="../../assets/images/da.png" /><span>  {{n.answer}}</span></div>
                    </div>
                    <img class="questionPublic" *ngIf="n.is_public && questionTab=='2'" src="../../assets/images/qusition_open.png" />
                    <img class="questionPublic" *ngIf="!n.is_public && questionTab=='2'" src="../../assets/images/qusition_hiden.png" />
                    <div class="backquestion" *ngIf="questionTab=='1'" (click)="addNewquestionModelClick('answer',n)">回答问题</div>
                </div>
                <div id="test1" (show)="AllquestionCount>0" style="float: right;margin-right: 0.5%"></div>
                <div class="noneTalent" *ngIf="AllquestionCount==0">
                    <img src="../../assets/images/air_noneTalent.png" />
                    <div class="noneTalent_h1">您还未和学生互动</div>
                    <div class="noneTalent_h2" (click)="addNewquestionModelClick('add',null)">创建互动</div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--新增问答弹窗-->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="addNewquestionModel" [style]="{'width':500,'height':320,'minHeight':320,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"
    (onHide)="addNewquestionBoxHiden()" [modal]=true>
    <div class="addNewquestionBox">
        <div class="addNewq_row1 flex_l" *ngIf="currentAnswerTalent"><img src="../../assets/images/wen.png" /><input disabled [(ngModel)]="addNewquestionData.question" placeholder="请输入问题" /></div>
        <div class="addNewq_row1 flex_l" *ngIf="!currentAnswerTalent"><img src="../../assets/images/wen.png" /><input [(ngModel)]="addNewquestionData.question" placeholder="请输入问题" /></div>

        <div class="addNewq_row2 flex_l"><img src="../../assets/images/da.png" /><textarea [(ngModel)]="addNewquestionData.answer" placeholder="请输入回答"></textarea></div>
        <div class="addNewquestionComi" (click)="addNewquestionClick()">确定</div>
    </div>
</p-dialog>

<!--//下线-->

<!--新增问答弹窗-->
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="downLineModel" [style]="{'width':460,'height':260,'minHeight':260,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]=true>
    <div class="downLineModel">
        <img class="downLineIcon" src="../../assets/images/downLineIcon.png" />
        <div class="downLine_h1">此功能会将您发布的空中宣讲会全网下线，确定下线吗？</div>
        <div class="downLineBtn flex_c">
            <div (click)="downLineModekClick(false)">取消</div>
            <div class="purple" style="color: white;background-color: #5E4FFF" (click)="downLineModekClick(true)">确定</div>
        </div>
    </div>
</p-dialog>