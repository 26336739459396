import { Injectable } from '@angular/core';
import { Config } from "./config";
import { Local } from "./local";
import { Constants } from "./constants";
import { DataApi } from "./dataApi";
import { ToastrService } from 'ngx-toastr';
import { ConfirmComponent } from '../app/confirm/confirm.component';
import { ConfirmsComponent } from '../app/confirms/confirms.component';
import {MatDialog} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router,ActivatedRoute } from  '@angular/router';
declare let window;
@Injectable()
export class Opera {
    private config: Config;
    public resumeDetails:any;//简历详情
    public position:any;//职位详情
    public college:any;//高校详情
    constructor(private dataApi:DataApi,
        private local: Local,
        public dialog: MatDialog,
        private router: Router,
        private sanitizer: DomSanitizer,
        // public cd: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        public toastr: ToastrService) {
         //获取单例config
        this.config = Config.getInstance();
    }

    //提醒发布职位
    remindPublish(text){
      this.scrollTop();//页面滚动函数
      this.dialog.open(ConfirmComponent, {
        data: {
          title: '提示',
          message: '至少有一个在期职位才可'+text,
          trueText:'发布职位',
          falseText:'再等等'
        }
      }).afterClosed().subscribe((isConfirmed) => {
          this.scrollTrue();
          if(isConfirmed){
            this.local.remove(Constants.positionid);
            this.router.navigate(['home/position-publish']);
          }
      });
    }

    //更新企业信息本地存储
    getDepartment(){
      this.loading();
      this.dataApi.getDepartment().then((res:any)=>{
        this.loadiss();
        // console.log("更新企业信息本地存储");
        // console.log(res);
        this.dataApi.setLocalDepartment(res);
      }).catch((error) => {
        this.loadiss();
        console.log(error);
        this.toastr.error(error);
      });
    }

    //提醒企业认证结果
    remindIdentityResult(status){
      let name,text;
      if(status=='2'){
        this.getDepartment();
        name="认证成功";
        text="您的企业认证已通过快去看看吧";
      }else if(status=='3'){
        name="认证失败";
        text="您的企业认证未通过快去看看吧";
      }
      this.scrollTop();//页面滚动函数
      this.dialog.open(ConfirmComponent, {
        data: {
          title: name,
          message: text,
          trueText:'去看看',
          falseText:'再等等'
        }
      }).afterClosed().subscribe((isConfirmed) => {
          //console.log('返回值');
          //console.log(isConfirmed);
          this.scrollTrue();
          if(isConfirmed){
            this.config.lastIdentity.status=status;
            this.dataApi.setLastIdentity(this.config.lastIdentity);
            //跳转认证页面
            this.goplatformIdentity();
          }
      });
    }

     //检测企业认证信息
     checkPlatformIdentity(){
      this.config.lastIdentity=this.dataApi.getLastIdentity();
      if((!this.config.lastIdentity)||(this.config.lastIdentity.status!=2)){
          this.dataApi.getPlatformApply().then((res:any)=>{
           console.log("获取企业平台认证信息",res);
          //  console.log(res);
           if(res){
              if(res.status=='2'){
                this.config.department.is_certified=true;
                this.dataApi.setLocalDepartment(this.config.department);
              }
              this.config.lastIdentity=res;
              this.dataApi.setLastIdentity(this.config.lastIdentity);

             //status (integer, optional): 企业认证审核状态 1：待审核 2：通过审核 3：未通过审核 ,

           }
          }).catch((error)=>{
            console.log("获取企业平台认证信息出错");
            console.log(error);
          })
      }
    }

    //验证企业认证信息跳转
    goplatformIdentity(){
      this.config.lastIdentity=this.dataApi.getLastIdentity();
      if((!this.config.lastIdentity)||(this.config.lastIdentity.status!=2)){
        this.dataApi.getPlatformApply().then((res:any)=>{
          console.log(res);
          this.goIdentityPage(res);
        }).catch((error)=>{
          console.log(error);
        })
      }else{
        this.goIdentityPage(this.config.lastIdentity);
      }
    }

    //跳转不同页面判断
    goIdentityPage(res){
      if(res){
          this.config.lastIdentity=res;
          this.dataApi.setLastIdentity(this.config.lastIdentity);
         if(res.status==2){//已通过
           this.config.department.is_certified=true;
           this.dataApi.setLocalDepartment(this.config.department);
           this.router.navigateByUrl("home/company-status-two");//审核通过
         }else if(res.status==1){//审核中
             if(res.order_stauts=='to_pay'){//未付款
              this.local.set(Constants.orderid,res.orderid);
              // this.router.navigateByUrl("home/company-pay");//付款页面
              this.router.navigateByUrl("home/company-status-three");//介绍企业认证页面
             }else{
              this.router.navigateByUrl("home/company-status-one");//审核中
             }
         }else if(res.status==3){//审核失败
            //nav.push('AboutDepartmentExhibitionPage');//认证特权展示页面
            // this.router.navigateByUrl("home/company-authentication");//填写企业认证页面
            this.router.navigateByUrl("home/company-status-four");//审核失败页面
         }else{//未提交
            this.router.navigateByUrl("home/company-status-three");//介绍企业认证页面
         }
      }
  }

    //路由存储逻辑处理
    setRouterLocal(url){
      let localUrl=this.local.get(Constants.newUrl);
      let oldUrl=this.local.get(Constants.oldUrl);
      //console.log("存储路由----");
      //console.log(oldUrl);
      //console.log(localUrl);
      if(!localUrl&&!oldUrl){//未存储路由
        this.local.set(Constants.newUrl,url);
      }else if(localUrl&&!oldUrl){//未填充上一级路由
        this.local.set(Constants.oldUrl,localUrl);
        this.local.set(Constants.newUrl,url);
      }else if(localUrl&&oldUrl){//当前路由及上级路由均存在
        let newUrl = url;//当前未存储路由
        let n = localUrl.indexOf('?');
        let m = oldUrl.indexOf('?');
        if(n>-1){
          localUrl = localUrl.slice(0,n);
        }
        if(m>1){
          newUrl = newUrl.slice(0,m);
        }
        if(newUrl==localUrl){//防止重复存储上级路由，重复时不覆盖上级路由
          this.local.set(Constants.newUrl,url);
          return;
        }else{//未重复时正常覆盖
          this.local.set(Constants.oldUrl,localUrl);
          this.local.set(Constants.newUrl,url);
        }
      }
    }

    //切换路由函数(返回操作)
    goChangeRouter(url){
      let oldUrl=this.local.get(Constants.oldUrl);
      let localUrl=this.local.get(Constants.newUrl);
      let newUrl = '/home/'+url;
      // console.log("二级页面返回操作----");
      // console.log(oldUrl);
      // console.log(newUrl);
      // console.log(localUrl);
      if(!oldUrl){//未存储路由
        this.router.navigateByUrl('/home/'+url);
      }else if(oldUrl){
        let n = localUrl.indexOf('?');
        let m = oldUrl.indexOf('?');
        if(n>-1){
          localUrl = localUrl.slice(0,n);
        }
        if(m>1){
          oldUrl = oldUrl.slice(0,m);
        }
        if(oldUrl==localUrl){
          this.router.navigateByUrl('/home/'+url);
        }else{
          this.router.navigateByUrl(this.local.get(Constants.oldUrl));
        }
      }
    }

    //跳转路由操作（部分为定向跳转不再按照上一路由存储）
    goNewRouter(url){
      let newUrl = '/home/'+url;
      this.router.navigateByUrl(newUrl);
    }
    //加载动画
    loading(){
      setTimeout(() => {
        this.config.loading = true;
        this.scrollTop();
      });
    }
    loadiss(){
      setTimeout(() => {
        this.config.loading = false;
        this.scrollTrue();
      });
    }

    //页面初始化时，赋值全局变量
    initConfig(data,data1){
        //config.isNavChildActive=='college-details-student
      setTimeout(() => {
        if(this.config.isLogin||this.config.isGuide){
          this.config.isLogin=false;
          this.config.isGuide=false;
          this.getUser();//获取用户信息
        }
        this.config.isNavActive=data;
        this.config.isNavChildActive=data1;
        if(data=='college'){
          this.config.isGoCollege=true;
          this.config.isGoPosition=false;
          this.config.isGoChat=false;
          this.config.isGoCompany=false;
        }else if(data=='position'){
          this.config.isGoPosition=true;
          this.config.isGoCollege=false;
          this.config.isGoChat=false;
          this.config.isGoCompany=false;
        }else if(data=='chat'){
          this.config.isGoChat=true;
          this.config.isGoCollege=false;
          this.config.isGoPosition=false;
          this.config.isGoCompany=false;
        }else if(data=='company'){
          this.config.isGoCompany=true;
          this.config.isGoCollege=false;
          this.config.isGoPosition=false;
          this.config.isGoChat=false;
        }
        this.scrollTrue();
      });
      console.log('initConfig',this.config.isNavActive,this.config.isNavChildActive);
    }

    // 自定义弹窗
    customTips(title,message,trueText,falseText,fn1,fn2){
        this.scrollTop();//页面滚动函数
        this.dialog.open(ConfirmsComponent, {
            data: {
                title:title,
                message: message,
                trueText:trueText||'知道了',
                falseText:falseText
            }
        }).afterClosed().subscribe((data) => {
            this.scrollTrue();
            if(data != undefined){
                if(data){
                    if(fn1){
                        fn1()
                    }
                }else{
                    if(fn2){
                        fn2()
                    }
                }
            }
        });
    }


    //获取用户信息(页头显示hr姓名)
  getUser(){
    if(this.dataApi.getLocalLoginUser()){
      //console.log("本地存储hr信息------------------------aboutsettinguser");
      //console.log(this.dataApi.getLocalLoginUser());
      setTimeout(() => {this.config.loginUser = this.dataApi.getLocalLoginUser();})
    }else{
      this.dataApi.getUser().then((res:any)=>{
        //console.log("线上数据hr信息------------------------aboutsettinguser");
        //console.log(res);
        if(res){
          setTimeout(() => {this.config.loginUser = res;})
        }
      }).catch((error)=>{
        this.toastr.error(error);
      });
    }
  }

    //打开弹窗时，页面滚动到头部
    scrollTop(): void{
      // let body1=document.getElementsByTagName('html')[0];
      // body1.scrollTop=50;
      let body=document.getElementsByTagName('body')[0];
      body.setAttribute('style', 'overflow: hidden');
    }

    //允许页面滚动
    scrollTrue(){
      let body=document.getElementsByTagName('body')[0];
      body.setAttribute('style', 'overflow: auto');
    }

    //刷新页面
    refreshPage(){
      // location.reload();
      // if(!this.cd['destroyed']) {
      //   this.cd.detectChanges();//刷新数据
      // }
    }

    //职位下线时更新在期职位本地存储
    setPositionListByExpired(val){
      let index;
      this.config.positionList=this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
      if(this.config.positionList){
         for(let n of this.config.positionList){
            if(n.positionid==val){
              index=this.config.positionList.indexOf(val);
            }
         }
      }

      this.config.positionList.splice(index, 1);
      this.dataApi.setLocalpositionList(this.config.positionList);
      let d=this.dataApi.getLocalDepartment();
      d.quota.position++;
      console.log('职位下线后企业可发布职位数量');
      console.log(d.quota.position);
      this.dataApi.setLocalDepartment(d);//更新企业可发布职位数量本地存储
    }

    //发布职位&职位重发时更新在期职位本地存储
    setPositionListByRelease(){
      this.loading();
      let model={limit:100,offset:0,status:'release'};
      this.dataApi.getPositionList(model).then((res:any)=>{
        //console.log("在期职位获取线上数据-----opera");
        //console.log(res);
        this.loadiss();
        if(res)
        {
            this.dataApi.setLocalpositionList(res.list);
            let d=this.dataApi.getLocalDepartment();
            d.quota.position=d.quota.position_total-res.count;
            console.log('发布职位&职位重发后企业可发布职位数量');
            console.log(d.quota.position);
            this.dataApi.setLocalDepartment(d);//更新企业可发布职位数量本地存储
        }

      }).catch((error) => {
        this.loadiss();
        this.toastr.error(error);
      });
    }

    //获取职位详情
    getPosition(data){
        let isRelease=false;
        for(let val of this.config.positionList){
          if(data == val.positionid){
            this.position = val;
            isRelease=true;
            return;
          }
        }
        if(!isRelease){
          this.getPositionList();//职位过期时
        }
    }

   //获取职位列表数据(第一次沟通需要发送职位)
   getPositionList(){
    //console.log("在期职位本地存储-----opera");
    //console.log(this.config.positionList);
    if(this.config.positionList.length>0){
      this.position = this.config.positionList[0];
    }else{
      let model={
        limit:100,
        offset:0,
        status:'release'
      }
      this.dataApi.getPositionList(model).then((res:any)=>{
        //console.log("在期职位获取线上数据-----opera");
        //console.log(res);
        // this.config.positionList = res.list;
          if(res) {
              this.dataApi.setLocalpositionList(res.list);
              this.position = this.config.positionList[0];
          }

      }).catch((error) => {
        this.toastr.error(error);
      });
    }
  }

  //判断当前是否存在会话列表
  checkChatList(){
    if(!this.config.chatsList){
      this.dataApi.getImDialogueList({}).then((res:any)=>{
        //console.log("获取会话列表--------opera");
        //console.log(res);
        this.dataApi.setLocalChatsList(res);//会话列表本地存储
      }).catch((error)=>{
        console.log(error);
        this.toastr.error(error);
      });
    }
  }


    //立即沟通功能封装
    goChat(details,position){
      console.log("立即沟通检查参数（详情&职位id）------opera");
      console.log(details);
      console.log(position);
      this.config.positionList=this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
      this.config.chatsList=this.dataApi.getLocalChatsList();//赋值会话列表本地存储
      this.checkChatList();//检查会话列表
        if(position){//是否有职位id
          this.getPosition(position);//获取职位详情
        }else{
          this.getPositionList();
        }
        this.resumeDetails = details;
        //判断权限
        if(this.resumeDetails.jm_username){//判断是否需要扣除资源(会话权限,与下面的遍历可能有冲突,暂时不影响,后续优化)
          //console.log("不需要获取极光资源------opera");
          this.goContactChat();
        }else{
            //console.log("需要获取极光资源-------opera");
            this.dataApi.getChatIm({accountid:this.resumeDetails.talentid,content:this.resumeDetails.collegeid}).then((res:any)=>{
                //console.log("极光资源获取成功---------opera");
                //console.log(res);
                this.resumeDetails.jm_username = res.jm_username;
                this.goContactChat();
            }).catch((error:any) => {
                //console.log("极光资源获取失败--------opera");
                console.log(error);
                if(error.Errcode=='2002'){//未通过认证
                  this.dataApi.checkDepCollegeIdentity(this.resumeDetails.collegeid).then((res:any)=>{
                    console.log("检测认证信息------");
                    console.log(res);
                    if(res){
                      if(res.status==1){//审核中
                        this.toastr.warning("您在"+this.resumeDetails.graduation_school+'的校招申请正在审核中，暂无法使用此功能');
                      }else{//审核失败
                        this.remindCollegeIdentity("与学生直聊",this.resumeDetails.collegeid,res);//提醒认证
                      }
                    }else{//未申请
                      this.remindCollegeIdentity("与学生直聊",this.resumeDetails.collegeid,res);//提醒认证
                    }
                  }).catch((err)=>{
                    this.toastr.error(err);
                  })
                }else if(error.Errcode=='2001'){//50次配额超限
                  // this.goCallChatEidentity();//提醒企业认证
                  this.toastr.warning('今天沟通已达上限，休息一下，明天继续吧');
                }else if(error.Errcode=='2005'){//100次配额超限
                  this.toastr.warning('今天沟通已达上限，休息一下，明天继续吧');
                }else{
                  this.toastr.error(error);
                }
            });
        }
    }

    //50次超限企业认证
    goCallChatEidentity(){
      this.scrollTop();//页面滚动函数
      this.dialog.open(ConfirmComponent, {
        data: {
          title: '提示',
          message: '当前还可沟通0人，完成企业认证后可增加至100人',
          trueText:'立即认证',
          falseText:'暂不沟通'
        }
      }).afterClosed().subscribe((isConfirmed) => {
          //console.log('返回值');
          //console.log(isConfirmed);
          this.scrollTrue();
          if(isConfirmed){
            //跳转认证页面
            this.goplatformIdentity();
          }
      });
    }

    //提醒校招和企业认证
    remindCollegeIdentity(text,id,identity){
      this.scrollTop();//页面滚动函数
      this.dialog.open(ConfirmComponent, {
        data: {
          title: '提示',
          message: '高校合作通过后，才可'+text,
          trueText:'申请合作',
          falseText:'再等等'
        }
      }).afterClosed().subscribe((isConfirmed) => {
          console.log('返回值');
          console.log(isConfirmed);
          this.scrollTrue();
          if(isConfirmed){
            //跳转申请校招
            this.local.set(Constants.collegeid,id);
            this.router.navigateByUrl("/home/company-identity-details");
          }
      });
    }

    //提醒企业认证
    remindDepartmentIdentity(id,identity){
      this.scrollTop();//页面滚动函数
      this.dialog.open(ConfirmComponent, {
        data: {
          title: '提示',
          message: '请前往APP申请免费高校云认证',
          trueText:'去认证',
          falseText:'再等等'
        }
      }).afterClosed().subscribe((isConfirmed) => {
          //console.log('返回值');
          //console.log(isConfirmed);
          this.scrollTrue();
          if(isConfirmed){
            //跳转认证页面
            this.toastr.error("请前往APP申请免费高校云认证")
            // this.goplatformIdentity();
          }
      });
    }

    //去沟通页面
    goContactChat(){
        if(!this.config.chatsList)
        {
            return;
        }
        let chatsList = this.config.chatsList.list;
        //console.log("判断当前有无会话列表------opera");
        //console.log(chatsList);
        if(chatsList){//判断当前有无会话列表
          if(chatsList.length>0){
            let isInDialog = false;//当前人才是否已在会话列表中
            chatsList.forEach((val)=>{
              if(val.dialogue_accountid == this.resumeDetails.talentid){
                isInDialog = true;
                this.local.remove(Constants.chatPosition);//移除职位参数
                this.local.set(Constants.chatsInfo,JSON.stringify(val));//聊天记录本地存储参数
                this.router.navigate(['home/chat-contact']);
              }
            });
            if(!isInDialog){
              this.goContactChatHandle();//创建会话
            }
          }else{
            this.goContactChatHandle();//创建会话
          }
        }else{
          this.goContactChatHandle();//创建会话
        }
    }

    //去沟通页面前携带参数处理(初次沟通的情况)
    goContactChatHandle(){
        //console.log("新会话去沟通页面之前检查职位参数--opera");
        //console.log(this.position);
        let userInfo = {
          personal_avater: this.resumeDetails.logo,
          resume_number: this.resumeDetails.resume_number,
          resumeid: this.resumeDetails.resumeid,
          talent_name: this.resumeDetails.talent_name,
          talentid: this.resumeDetails.talentid,
          jm_username:this.resumeDetails.jm_username
        }
        //console.log("新会话去沟通页面之前检查参数--opera");
        //console.log(userInfo);
        if(this.config.positionList.length==0){
            this.remindPublish('在线直聊');
        }else{
            this.config.isNavActive=='college';
            this.config.isNavChildActive=='college-resume';
            this.local.set(Constants.chatPosition,JSON.stringify(this.position));//移除职位参数
            this.local.set(Constants.userInfo,JSON.stringify(userInfo));//聊天记录本地存储参数
            this.router.navigate(['home/chat-contact']);
        }

    }



 //收藏简历
 manageResumeArchived(val){
     this.resumeDetails = val;
    if(!this.resumeDetails.isfollow_reusme){
      this.dataApi.manageResumeArchived({resumeid:this.resumeDetails.resumeid}).then((res:any)=>{
        ////console.log(res);
        this.resumeDetails.isfollow_reusme = true;
        setTimeout(() => {
          this.toastr.success('收藏成功');
        },600);
        return this.resumeDetails;
      }).catch((error) => {
        console.log(error);
        setTimeout(() => {
          this.toastr.error(error);
        },600);
        return this.resumeDetails;
      });
    }else{
      this.dataApi.manageResumeArchivedDelete(this.resumeDetails.resumeid).then((res:any)=>{
        ////console.log(res);
        this.resumeDetails.isfollow_reusme = false;
        setTimeout(() => {
          this.toastr.success('取消收藏成功');
        },600);
        return this.resumeDetails;
      }).catch((error) => {
        console.log(error);
       //loader.dismiss();
        setTimeout(() => {
          this.toastr.error(error);
        },600);
        return this.resumeDetails;
      });
    }
  }


 //订阅高校的公众号
 CollegeRead(val){
    this.college = val;
    if(this.college.is_subscribe){
        this.unCollegeAccount();
    }else{
        this.getCollegeAccount();
    }
 }
 //订阅高校的公众号
 getCollegeAccount(){
    this.dataApi.getCollegeAccount(this.college.collegeid).then((res:any)=>{
       this.college.is_subscribe=true;
       this.toastr.success("订阅成功");
       return this.college;
    }).catch((err)=>{
       this.toastr.error("订阅操作失败，请稍后重试");
       return this.college;
    })
  }
  //取消订阅高校的公众号
  unCollegeAccount(){
    this.dataApi.unCollegeAccount(this.college.collegeid).then((res:any)=>{
        this.toastr.success("取消订阅成功");
        return this.college;
    }).catch((err)=>{
        this.toastr.error("订阅操作失败，请稍后重试");
        return this.college;
    })
  }

  //获取企业认证信息(app.ts获取企业信息，此处只需判断是否需要获取企业认证信息)
  checkIdentity(){
    if(this.config.department&&this.config.department.is_certified){
      return true;
    }else{
      this.dataApi.getPlatformApply().then((res:any)=>{
        //console.log("获取到企业认证信息------------------------opera");
        //console.log(res);
        if(res){
          this.config.lastIdentity=res;
          this.dataApi.setLastIdentity(this.config.lastIdentity);
          if(res.status==2){
            this.config.department.is_certified=true;
            this.dataApi.setLocalDepartment(this.config.department);
            return true;
          }else{
            return false;
          }
        }
      }).catch((error)=>{
        console.log(error);
        this.toastr.error(error);
        return false;
      })
    }
  }

  isIE(){
    let userAgent = window.navigator.userAgent; //取得浏览器的userAgent字符串
    //console.log("浏览器类型及版本");
    //console.log(userAgent);
    //console.log(window.navigator.appName);
    let isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE浏览器
    let is360 = userAgent.toLowerCase().indexOf("360se")>-1;//判断是否360浏览器
    let isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
    let isChrome = userAgent.indexOf("Chrome") > -1; //判断是否Chrome浏览器
    if (((/Trident\/7\./).test(userAgent))){//判断是否IE11浏览器
      //  alert('IE11');
    }
    if(isIE){
      // alert("IE");
    }
    // if (is360) {
    //   alert('360');
    // }
    // if (isFF) {
    //   alert('Firefox');
    // }
    // if (isChrome) {
    //     alert('Chrome');
    // }
  }

  //跳转提交认证资料页面
  goIdentityDetails(id){
    this.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
       data: {
         title: '提示',
         message: '经高校合作通过后，才可使用此功能',
         trueText:'去申请',
         falseText:'再等等'
       }
    }).afterClosed().subscribe((isConfirmed) => {
        //console.log('返回值');
        //console.log(isConfirmed);
        this.scrollTrue();
        if(isConfirmed){
          //跳转认证页面
          this.local.set(Constants.collegeid,id);
          this.router.navigateByUrl("/home/company-identity-details");
        }
    });

  }

  //跳转页面
  goCollegePage(page,id,mrId,resumeId,rs){
    this.getCollege(id);//获取高校信息
    if(page=='milk'){//跳转双选会详情
      this.local.set(Constants.mrid,mrId);
      this.local.set(Constants.typeUrl,'/home/college-milkdetails');
    }else if(page=='campus'){//跳转高校宣讲会
      this.local.set(Constants.typeUrl,'/home/college-details-campus');
    }else if(page=='collegeMilk'){//跳转高校双选会列表
      this.local.set(Constants.typeUrl,'/home/college-details-milk');
    }else if(page=='positionPublish'){//发布职位
      this.local.remove(Constants.positionid);
      this.local.set(Constants.typeUrl,'/home/position-manage');
    }else if(page=='resume'){//简历详情
      this.local.set(Constants.resumeid,resumeId);
      this.local.set(Constants.typeUrl,'/home/college-resumedetails');
    }else if(page=='login'){//企业登陆
      this.local.set(Constants.typeUrl,'/home/homepage');
      if(rs){
         this.local.set(Constants.loginRs,rs);
      }
    }

  }

    //提醒
    sendTips(text){
      this.scrollTop();//页面滚动函数
      this.dialog.open(ConfirmComponent, {
        data: {
          title: '提示',
          message: text,
          trueText:'知道了',
          falseText:''
        }
      }).afterClosed().subscribe(() => {
          this.scrollTrue();
      });
    }


    sendTipss(text){
      this.scrollTop();//页面滚动函数
      this.dialog.open(ConfirmsComponent, {
        data: {
          title: '提示',
          message: text,
          trueText:'知道了',
          falseText:''
        }
      }).afterClosed().subscribe(() => {
          this.scrollTrue();
      });
    }

  //获取高校信息并保存
  getCollege(id){
    this.local.set(Constants.collegeid,id);
    this.loading();
    this.dataApi.getCollageDetails(id).then((res:any)=>{
      console.log("获取到高校信息---------");
      console.log(res);
      setTimeout(() => {
        this.config.isByCollege=true;
      });
      this.local.set(Constants.byCollegeDetails,JSON.stringify(res));
      this.config.byCollegeLogo=res.logo;
      this.config.byCollegeName=res.name;
      // document.title = res.name;
      // document.querySelector("#favicon").href=res.logo;
      this.loadiss();
    }).catch((err)=>{
      this.toastr.error(err);
      this.loadiss();
    });
  }

  //富文本过滤
  assembleHTML(strHTML:any) {
    return this.sanitizer.bypassSecurityTrustHtml(strHTML);
  }
    //是否是注册信用代码  为7 9 15 18 位
    isRegister_number(register_number){
        let filter7 = /^[0-9a-zA-Z]{7}$/;
        let filter9 = /^[0-9a-zA-Z]{9}$/;
        let filter15 = /^[0-9a-zA-Z]{15}$/;
        let filter16 = /^[0-9a-zA-Z]{16}$/;
        let filter18 = /^[0-9a-zA-Z]{18}$/;
        if (filter7.test(register_number)||filter9.test(register_number)|| filter15.test(register_number)|| filter16.test(register_number) || filter18.test(register_number)) {
           console.log('营业执照正确')
            return true;
        } else {
            console.log('营业执照错误')
            return false;
        }
    }
    //验证手机号

    isPhone(phone){
        const phone_rex = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
        return phone_rex.test(phone);
    }
    //成立年份是否是符合规范 2020 1999 等
    isEstablish_year(year){
        let number = /^[0-9]{4}$/;
        if(!year)
        {
            return false;
        }
        let newYear = year+'';
        if(newYear.length!=4)
        {
            return false;
        }
        if(newYear.length>1)
        {
            let index = newYear.slice(0,1);
            console.log('index',parseInt(index));
            if(parseInt(index)>2||parseInt(index)<0)
            {
                return false;
            }
        }
        var myDate = new Date();
        var tYear = myDate.getFullYear();
        console.log('tYear',tYear,year)
        if(year>tYear)
        {
            return false;
        }

        return number.test(year);
    }
    formatFileData(imageData) {
        //png
        if(imageData.indexOf('data:image/png;base64')>=0) {
            imageData=imageData.replace('data:image/png;base64,','');
        }
        //jpg
        if(imageData.indexOf('data:image/jpeg;base64')>=0) {
            imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
        }
        //word
        if(imageData.indexOf('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64')>=0) {
            imageData=imageData.replace('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,','');
        }
        //xls
        if(imageData.indexOf('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64')>=0) {
            imageData=imageData.replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,','');
        }
        //txt
        if(imageData.indexOf('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64')>=0) {
            imageData=imageData.replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,','');
        }
        //pptx
        if(imageData.indexOf('data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64')>=0) {
            imageData=imageData.replace('data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,','');
        }
        //zip
        if(imageData.indexOf('data:application/zip;base64')>=0) {
            imageData=imageData.replace('data:application/zip;base64,','');
        }
        if(imageData.indexOf('data:application/msword;base64')>=0) {
            imageData=imageData.replace('data:application/msword;base64,','');
        }

        if(imageData.indexOf('data:application/pdf;base64')>=0) {
            imageData=imageData.replace('data:application/pdf;base64,','');
        }

        if(imageData.indexOf('data:application/x-zip-compressed;base64')>=0) {
            imageData=imageData.replace('data:application/x-zip-compressed;base64,','');
        }

        if(imageData.indexOf('data:text/plain;base64')>=0) {
            imageData=imageData.replace('data:text/plain;base64,','');
        }
        if(imageData.indexOf('data:application/vnd.ms-excel;base64')>=0) {
            imageData=imageData.replace('data:application/vnd.ms-excel;base64,','');
        }
        if(imageData.indexOf('data:application/octet-stream;base64')>=0) {
            imageData=imageData.replace('data:application/octet-stream;base64,','');
        }
        if(imageData.indexOf('data:application/wps-writer;base64')>=0) {
            imageData=imageData.replace('data:application/wps-writer;base64,','');
        }
        return imageData
    }

    vipState(callback?: any) {
      //getDepartment
      this.dataApi.getDepartment().then((res: any) => {
        let department = res;
        if (department.member_info) {
          let nowData = new Date();
          //活动截止时间
          let overData = new Date(department.member_info.endtime);
          //90天到期提醒
          let spaceBe = 90 * 24 * 60 * 60 * 1000;
          if ((overData.getTime() - nowData.getTime()) > spaceBe) {
            callback(true)
          } else if (nowData.getTime() > overData.getTime()) {
            callback(false)
          } else {
            callback(true)
          }
        }else{
          callback(false)
        }
      })
    }
    
}
