<!-- 页头部分 -->
<div class="contentRight-header">消息中心 - 谁看过我</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
   <div class="resume-block">
     <!-- 操作部分 <img src="assets/images/select-img.png">-->
    <div class="college-block-opera clear">
        <div class="right" style="width:100%;">
         <span class="left htips" (click)="sendTip()" >下载「工作啦」APP，查看学生浏览职位记录</span>
         <span>筛选</span>
         <select name="major" id="major" [(ngModel)]="majorOne" (ngModelChange)="majorOneChange()" autocomplete="off">
            <option class="place-text" value='' disabled hidden selected="selected">选择一级专业</option>
            <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
        </select>
        <select name="majorTwo" id="majorTwo" [(ngModel)]="model.major" (ngModelChange)="getPositionViewHistory()" (click)="isShowTwo()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择二级专业</option>
                <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
        </select>
        <!-- 选择学历 -->
        <span>&nbsp;&nbsp;&nbsp;&nbsp;学历</span>
        <select name="education" id="education" [(ngModel)]="model.education" (ngModelChange)="getPositionViewHistory()" autocomplete="off">
            <option class="place-text" value='' disabled hidden selected="selected">选择学历</option>
            <option *ngFor="let a of educationList" [value]="a.code">{{a.text}}</option>
        </select>  
         <!-- 搜索筛选 -->
         <div class="search-input right">
              <input type="text" placeholder="请输入职位名称" [(ngModel)]="model.position_name" (keyup)="inputKeyup($event)"><span (click)="goSearchName()"><img src="assets/images/search.png"></span>
              <!-- 搜索关键词匹配列表 -->
              <div class="keyup-block" *ngIf="keywordList.length>0">
                  <div class="keyup-list" *ngFor = "let key of keywordList" (click)="goSearch(key)">{{key.name}}</div>
              </div>
          </div>
        </div>
    </div>
     <!-- 没有内容存在时 -->
    <div *ngIf="countList==0" class="none-block">
        <img src="assets/images/resume-search-fail.png"><br/>
        <span *ngIf="config.department&&config.department.app_login">暂未找到相关简历，换个关键词试试吧</span>
        <span *ngIf="config.department&&!config.department.app_login">扫描左下方，下载app查看“谁看过我”</span>
    </div>
    <div *ngIf="countList>0" class="college-list-cont">
        <ul class="clear">
            <li>头像</li>
            <li>姓名</li>           
            <li>学校</li>
            <li>学历</li>
            <li>浏览职位</li>
            <li>浏览日期</li>
            <li>操作</li>
        </ul>
        <div>
            <!-- 没有内容存在时 -->
            <div *ngIf="resumeList.length==0&&!config.department.is_certified" class="none-block">
                <img src="assets/images/resume-search-fail.png"><br/>
                可查看10个访客，完成<span class="none-btn" (click)="opera.goplatformIdentity()">企业认证</span>查看全部
            </div>
            <ul class="college-li clear" *ngFor="let n of resumeList;let i = index;">
                <li>
                    <!-- <img *ngIf="n.logo" [src]="n.logo+'&imageView2/1/w/100/h/100'"><img *ngIf="!n.logo" src="assets/images/none.png"> -->
                    <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}">
                    <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png">
                    <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png">
                    <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png">
                </li>
                <li><span *ngIf='n.talent_name'>{{n.talent_name}}</span><span *ngIf='!n.talent_name'>暂无</span></li>
                <li><span *ngIf='n.school'>{{n.school}}</span><span *ngIf='!n.school'>暂无</span></li>   
                <li><span *ngIf='n.education_text'>{{n.education_text}}</span><span *ngIf='!n.education_text'>暂无</span></li>
                <li><span *ngIf='n.position_name'>{{n.position_name}}</span><span *ngIf='!n.position_name'>暂无</span></li>
                <li><span *ngIf='n.view_date'>{{n.view_date | date:"yyyy-MM-dd"}}</span><span *ngIf='!n.view_date'>暂无</span></li>
                <li>
                    <span (click)="goDetails(n.resume_number)">查看简历</span>
                    <!--<span (click)="goChat(n,n.positionid)">{{n.jm_username?'继续沟通':'立即沟通'}}</span>-->
                    <span (click)="goCollect(n)">{{n.isfollow_reusme?'取消收藏':'收藏'}}</span>
                    <!-- <span>分享</span> -->
                </li> 
            </ul>
        </div>
    </div>
    <!-- 分页部分 -->
    <div class='paging-block' [class.isPageHeight]="countList>0">
       <router-outlet></router-outlet>
       <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
    </div>
   </div>
</div>
