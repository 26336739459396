import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ensure',
  templateUrl: './ensure.component.html',
  styleUrls: ['./ensure.component.css']
})
export class EnsureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
