import { Component,EventEmitter, Input, Output,ViewChild,ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataApi,Constants,Config,Local} from '../../provider/index';
declare let laydate;
@Component({
  selector: 'app-model-company-courses',
  templateUrl: './model-company-courses.component.html',
  styleUrls: ['./model-company-courses.component.css']
})
export class ModelCompanyCoursesComponent {
  public config: Config;
  @Input() isShow: false;//是否显示弹窗页面
  public title="";  
  public model={
    name:'',
    details:'',
    link:'',
    occur_time:'',
    cid:''
 };
public judge = false;//判断是否是添加操作
public name='';
  constructor(public toastr: ToastrService,
    public local:Local,
    public dataApi: DataApi) { 
      this.config = Config.getInstance();
    }

    @Output() onVoted = new EventEmitter();
    dismiss(result: any) {
      //console.log("传值检查");
      //console.log(result);
      this.onVoted.emit(result);
    }

    //初始化函数
    initData(data){
      let that = this;
      laydate.render({
        elem: '#course-time',
        // type: 'year',
        done: function(value, date){
        that.model.occur_time = value;
        }
      })
      if(data){
        this.title="编辑";
        this.judge=false;
        this.model=data;
        this.model.occur_time=data.occur_time.slice(0,10);
        //console.log("检查传参------model-company-product");
        //console.log(data);
      }else{
        this.judge=true;
        this.title="添加";
        this.model={
          name:'',
          details:'',
          link:'',
          occur_time:'',
          cid:''
        };
      }
    }

    //保存
getProgress(){
  //判断数据是否填写完整
  let filter1=/^[0-9a-zA-Z\u4e00-\u9fa5]+$/;
  if(!this.model.name){
    this.toastr.success('请填写事件名称');
    return;
  }else if(!filter1.test(this.model.name)){
    this.toastr.success("事件名称只能输入汉字、字母或数字");
    return;
  } 
  if(!this.model.occur_time){
    this.toastr.success('请填写发生时间');
    return;
  }
  // let loader = this.tip.presentLoading('保存中...');
  if(this.judge){
    this.dataApi.createDepartmentProgress(this.model).then((res:any)=>{
      //console.log(res);
      this.model.cid=res.value;
      // loader.dismiss();
      this.toastr.success("发展历程添加成功");
      this.dismiss(this.model);
    }).catch((err)=>{
      //console.log(err);
      // loader.dismiss();
    })
  }else{
    this.dataApi.uploadDepartmentProgress(this.model.cid,this.model).then((res:any)=>{
      //console.log(res);
      // loader.dismiss();
      this.toastr.success("发展历程修改成功");
      this.dismiss(this.model);
    }).catch((err)=>{
      //console.log(err);
      // loader.dismiss();
    })
  }
  
}
//删除
deleteProgress(){
  this.dataApi.deleteDepartmentProgress(this.model.cid).then((res:any)=>{
    //console.log(res);
    this.toastr.success('事件已删除');
    this.model={
     name:'',
     details:'',
     link:'',
     occur_time:'',
     cid:''
   };
   this.dismiss('delete');
  })

}

}
