<div class="contentRight-header">招聘管理 - 申请高校合作</div>


<!-- 主体内容部分 -->
<div class="homepage-block">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <!-- 遮罩层部分 -->
    <!--<router-outlet></router-outlet>-->
    <div class="college-block" style="margin-bottom:20px;padding-bottom: 20px;">
        <!-- 页头部分 -->
        <div class="college-block-head">
            申请高校合作
        </div>

        <!-- 中间部分 -->
        <div class="info-block" *ngIf="(CollegeIdentityModel.status==0||CollegeIdentityModel.status==3||CollegeIdentityModel.status==7)&& isCommitErr">
            <!-- 中间部分 -->
            <!-- <div *ngIf="collegeName" class="identityCollege-name">当前认证高校：{{collegeName}}</div>  -->
            <div *ngIf="collegeName&&orgList.length==0" class="identityCollege-name">当前申请高校：{{collegeName}}</div>
            <div *ngIf="orgList.length>0" class="identityCollege-name">当前申请院系：
                <select name="CIOrg" id="CIOrg" [(ngModel)]="model.facultyid" autocomplete="off">
                    <option class="place-text" value='' disabled selected="selected">请选择院系</option>
                    <option *ngFor="let a of orgList" [value]="a.facultyid">{{a.name}}</option>
                </select>
            </div>
            <p>以下必填项为高校要求，请详细完善信息,否则影响高校校招合作</p>
            <!-- 营业执照 -->
            <div class="info-list info-logo">
                <div class="info-head">第一步&nbsp;&nbsp;企业有效证照(如营业执照、商业登记证等)</div>
                <label *ngIf="model.business_licence" class="uploadImg-label department-logo" for="uploadLoginAvatar">
                    <img [src]="model.business_licence">
                </label>
                <label *ngIf="!model.business_licence" class="uploadImg-label" for="uploadLoginAvatar">
                    <img src="assets/images/add-img.png"><br />点击上传&nbsp;&nbsp;<span>企业有效证照</span>
                </label>
                <input class="uploadImg" id="uploadLoginAvatar" (change)="previewPic($event,'business_licence')" type="file" accept="image/jpeg,image/jpg,image/png">
                <!--<p style="position: absolute;margin-left: 70px;margin-top: 10px;color: red;height: 50px;padding-bottom: 20px">注：属于培训学校的企业，需要上传办学许可证</p>-->
                <ul>
                    <li>1. 上传<span *ngIf="isMainland">企业有效证照</span><span *ngIf="!isMainland">企业有效证照</span>名称必须与公司全称保持一致
                    </li>
                    <li>2. 请提供有效年检期内的《企业有效证照》副本</li>
                    <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                    <li>4. 高校就业中心将会尽快审核</li>
                    <!--<li style="margin-top: 10px">-->
                    <!--&lt;!&ndash;<input name="attachment" value='Brows' placeholder="+上传办学许可证" style="width: 100px;height:40px;background-color: lightgray;padding: 5px 15px;margin-top: 10px" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">&ndash;&gt;-->

                    <!--<div style="width: 150px;height: 25px;background-color: lightgray">-->
                    <!--<label style="width: 150px;height: 25px;background-color: lightgray;margin-top: -25px;margin-left: 0px;">+&nbsp;上传办学许可证-->
                    <!--<input id="uploadLoginbanXue" (change)="previewPic($event,'school_permit')" style="width: 150px;height: 25px;background-color: lightgray" type="file" accept="image/jpg,image/png,image/jpeg,image/bmp" style="display:none;">-->
                    <!--</label>-->
                    <!--</div>-->
                    <!--</li>-->
                </ul>
            </div>
            <!--<label *ngIf="model.school_permit"  for="uploadLoginbanXue">-->
            <!--<img style="width:240px;height: 182px;margin-left: 71px;margin-top: 35px;" [src]="model.school_permit">-->
            <!--</label>-->

            <div class="info-list info-logo" style="margin-top: 15px" *ngIf="department?.industry=='0606'||department?.industry=='0604'||department?.industry=='0605'">
                <label *ngIf="model.school_permit" class="uploadImg-label department-logo" for="uploadLoginbanXue">
                    <img [src]="model.school_permit">
                </label>
                <label *ngIf="!model.school_permit" class="uploadImg-label" for="uploadLoginbanXue">
                    <img src="assets/images/add-img.png"><br />点击上传&nbsp;&nbsp;上传办学许可证
                </label>
                <input class="uploadImg" id="uploadLoginbanXue" (change)="previewPic($event,'school_permit')" type="file" accept="image/jpeg,image/jpg,image/png">
            </div>



            <!-- 统一社会信用代码 -->
            <div class="info-list info-number">
                <!--<div *ngIf="!secureMaterail" class="info-head">第三步&nbsp;&nbsp;统一社会信用代码</div>-->
                <div class="info-head">第二步&nbsp;&nbsp;<span>企业有效证照号码(如统一社会信用代码、商业登记证号码)</span></div>
                <input type="text" placeholder="请填写企业有效证照号码" [(ngModel)]="model.register_number" maxlength="18">
                <ul>
                    <li>1. 填写的企业有效证照号码必须与企业有效证照保持一致</li>
                    <li>2. 高校就业中心将会尽快审核</li>
                </ul>
            </div>
            <!-- 招聘委托书 -->
            <div class="info-list info-logo" *ngIf="isHavethree">
                <div class="info-head">第三步&nbsp;&nbsp;招聘委托书</div>
                <label *ngIf="model.proxy_document" class="uploadImg-label department-logo" for="uploadProxy">
                    <img [src]="model.proxy_document">
                </label>
                <label *ngIf="!model.proxy_document" class="uploadImg-label" for="uploadProxy">
                    <img src="assets/images/add-img.png"><br />点击上传招聘委托书
                </label>
                <input class="uploadImg" id="uploadProxy" (change)="previewPic($event,'proxy_document')" type="file" accept="image/jpeg,image/jpg,image/png">
                <ul>
                    <li>1. 点击下载：<a [href]="secureMaterail">《招聘委托书示例》</a></li>
                    <li>2. 修改后请加盖公章，截图或拍照上传</li>
                </ul>
            </div>

            <!-- 营业执照 -->
            <div class="info-list info-logo">
                <div class="info-head">{{isHavethree?'第四步':"第三步"}}&nbsp;&nbsp;招聘者手持个人有效身份证明（如身份证）的照片</div>
                <label *ngIf="model.id_card_front" class="uploadImg-label department-logo" for="uploadGuideCard">
                    <img [src]="model.id_card_front">
                </label>
                <label *ngIf="!model.id_card_front" class="uploadImg-label" for="uploadGuideCard">
                    <img src="assets/images/add-img.png"><br />点击上传个人有效身份证明
                </label>
                <input class="uploadImg" id="uploadGuideCard" (change)="previewPic($event,'id_card_front')" type="file" accept="image/jpeg,image/jpg,image/png">
                <ul>
                    <li>1. 请提供有效期内的本人手持《个人有效身份证明》照片</li>
                    <li>2. 请勿自拍</li>
                    <li>3. 上传个人有效身份证明姓名必须与招聘联系人姓名保持一致</li>
                    <li>4. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                    <li>5. 高校就业中心将会尽快审核</li>
                    <li><a href="../../assets/images/addImage_new.png" target="_blank">示例：<img
                                style="width: 160px;height:  90px" src="../../assets/images/addImage_new.png" /></a>
                    </li>
                </ul>
            </div>

            <!-- 办公电话 -->
            <div class="info-lis" *ngIf="authcation_office_phone">
                <div *ngIf="!secureMaterail" class="info-head">第四步&nbsp;&nbsp;办公电话</div>
                <div *ngIf="secureMaterail" class="info-head">第五步&nbsp;&nbsp;办公电话</div>
                <input type="text" [(ngModel)]="model.office_phone" (blur)="checkPhone1(model.office_phone)" placeholder="请填写固定电话，如0371-66668888" class="info-phone">
                <!-- <b *ngIf="!model.office_phone"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b> -->
            </div>

            <!-- 申请简述 -->
            <div class="info-list">
                <div *ngIf="!secureMaterail&&!authcation_office_phone" class="info-head">第四步&nbsp;&nbsp;申请简述</div>
                <div *ngIf="secureMaterail&&!authcation_office_phone" class="info-head">第五步&nbsp;&nbsp;申请简述</div>
                <div *ngIf="!secureMaterail&&authcation_office_phone" class="info-head">第五步&nbsp;&nbsp;申请简述</div>
                <div *ngIf="secureMaterail&&authcation_office_phone" class="info-head">第六步&nbsp;&nbsp;申请简述</div>
                <div class="list-brief">
                    <textarea placeholder="请简述申请校招的目的(最多100字)" scrollHeight [(ngModel)]="model.brief" minlength="0" maxlength="100">{{model.brief}}</textarea>

                </div>
            </div>





            <!-- 按钮 -->
            <div class="block-button flex_c">
                <button (click)="uploadDepartmentIdentity()">提交</button>
            </div>
        </div>

        <div class="info-block" *ngIf="CollegeIdentityModel.status==1">
            <div class="loadingBox flex_c">
                <img class="loadingBoxImage" src="../../assets/images/http_error2.png" />
                <div class="loadingBox_r">
                    <div class="loadingBox_title" style="margin-top: 50px">审核中</div>
                    <div class="loadingBox_err">您已提交申请，高校正在加急审核中，请耐心等待</div>
                    <div class="revokeBox">
                        <button class="cellButton" (click)="openRevoke()">撤回申请</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="info-block" *ngIf="CollegeIdentityModel.status==2">
            <div class="loadingBox flex_c">
                <img class="loadingBoxImage" src="../../assets/images/http_error3.png" />
                <div class="loadingBox_r">
                    <div class="loadingBox_title">审核成功</div>
                    <div class="loadingBox_err" style="line-height: 25px">
                        对于{{currentCollege?currentCollege.name:"当前高校"}}，您已获取以下权益：<br /> 提交双选会/宣讲会申请
                        <br /> 提交招聘简章申请
                        <br /> 提交发布职位申请
                        <br /> 查看生源信息
                        <br />
                    </div>
                </div>
            </div>
        </div>

        <div class="info-block" *ngIf="CollegeIdentityModel.status==3&&!isCommitErr">
            <div class="loadingBox flex_c">
                <img class="loadingBoxImage" src="../../assets/images/http_error1.png" />
                <div class="loadingBox_r">
                    <div class="loadingBox_title">审核不通过</div>
                    <div class="loadingBox_err" style="text-align: center">拒绝原因：{{CollegeIdentityModel.remark}}</div>
                    <div class="loadingBox_err" style="text-align: center;margin-top: 50px;cursor: pointer;color: #5d479a;text-decoration:underline" *ngIf="CollegeIdentityModel.canApply" (click)="isCommitErrClick()">重新申请</div>
                </div>
            </div>
        </div>

    </div>
</div>

<p-dialog [closeOnEscape]="false" [(visible)]="isShowChips" [modal]="true" [showHeader]="false" [contentStyle]="{'padding':'0',border:0}" [style]="{'width':500,'height':300,'minHeight':300,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}">

    <div style="width:500px;height: 300px;background-color: white;position: relative;box-sizing: border-box;overflow: hidden;">
        <img class="colseAlert" src="../../assets/images/close_default.png" (click)="isShowChips=false" />
        <div class="id_tiitle">温馨提示</div>
        <div class="alertTitle">您还未通过高校资质审核，资质审核通过前学校无法看到您的双选会/宣讲会申请，重新提交学校资质审核后需要重新报名学校招聘活动
        </div>
        <div class="iKonw" (click)="isShowChips=false">我知道了</div>

    </div>

</p-dialog>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="revokeModule" [modal]=true>
    <div class="showTopingBox">
        <div class="closIcon" (click)="revokeModule = false"><img src="/assets/images/v3/contactMeClos.png"></div>
        <div class="title">
            <div class="tipText">
                <div class="name">撤回合作申请</div>
            </div>
        </div>
        <div class="p2">确定撤回高校合作申请吗？</div>
        <div class="btns">
            <div class="btn active" (click)="revokeModule = false">取消</div>
            <div class="btn blueStreamer" (click)="downCooperate()">确定</div>
        </div>
    </div>
</p-dialog>