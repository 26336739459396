<!-- 企业发展历程 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>
    <div class="model-cont">
        <!-- 上层提示部分 -->
        <div class="model-head clear">
            <div class="left">{{title}}发展历程</div>
            <button class="right" (click)="dismiss(false)">×</button>
        </div>
        <!-- 中间内容部分 -->
        <div class="model-content">
             <!-- 事件名称 -->
            <div class="sign-list">
                <span>事件名称：</span><input type="text" [(ngModel)]="model.name" maxlength="20" placeholder="请填写事件名称(少于20个字)">
            </div>
            <!-- 发生时间 -->
            <div class="sign-list">
                <span>发生时间：</span><input type="text" id="course-time" [(ngModel)]="model.occur_time" placeholder="请选择发生时间"><img class="calendar-img" src="assets/images/calendar.png" alt="打开日历"/>
            </div>
            <!-- 事件网址 -->
            <div class="sign-list">
                <span>事件网址：</span><input type="text" [(ngModel)]="model.link" placeholder="请填写事件相关网址">
            </div>
            <!-- 事件描述 -->
            <div class="sign-list sign-intro">
                <span>事件描述：</span>
                <textarea [(ngModel)]="model.details" minlength="0" maxlength="200" placeholder="请填写事件描述(小于200字)"></textarea>
            </div>
        </div>
        <!-- 底部按钮 -->
        <div class="select-submit"><button *ngIf="model.cid" (click)="deleteProgress()">删除</button><button class="purple" (click)="getProgress()">保存</button></div>
    </div>
</div>