import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'timetransform'})
export class TimeTransform implements PipeTransform {
  constructor(){

  }  
  transform(_content,_isNoDetail) {    
    if(_content){
      let date,yyyy,MM,dd,hh,mm,nowDate,nowyyyy,nowMM,nowdd;      
      //先格式化收到的数据     
      // if(_content.toString()[10]=="T"){
      //   _content = _content+'+08:00';
      // }
      date = new Date(_content);
      yyyy = date.getFullYear(); //年份
      MM = date.getMonth() + 1; //月份 
      dd = date.getDate(); //日 
      hh = date.getHours(); //小时 
      mm = date.getMinutes(); //分
      if(hh<10){
        hh = '0'+hh;
      }    
      if(mm<10){
        mm = '0'+mm;
      } 
      //创建今天的日期,做比对
      nowDate = new Date();
      nowyyyy = nowDate.getFullYear(); //年份
      nowMM = nowDate.getMonth() + 1; //月份 
      nowdd = nowDate.getDate(); //日 
      
      if(yyyy==nowyyyy && MM==nowMM && dd==nowdd){
        return "今天"+' '+ hh+':'+mm;        
      }else{
        if(yyyy!=nowyyyy){
          if(_isNoDetail){
            return yyyy+'年'+MM+'月'+dd+'日';
          }else{
            return yyyy+'年'+MM+'月'+dd+'日'+' '+hh+':'+mm;
          }          
        }else{
          if(_isNoDetail){
            return MM+'月'+dd+'日';
          }else{
            return MM+'月'+dd+'日'+' '+hh+':'+mm;
          }
          
        }
      }
      
    }else{
      return _content;
    }

    
  }
}