<!-- 页头部分 -->
<div class="contentRight-header"><img (click)="opera.goChangeRouter('college-details-news')" src='assets/images/router-old.png'/>校园招聘 - 校园+ - 学校详情 - 新闻公告 - 文章详情</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <div class="college-block" *ngIf="item">
      <!-- 页头部分 -->
      <div class="college-block-head">{{item.title}}</div>
      <!-- 小字部分 -->
      <div class="college-msg">
        <span *ngIf="item.publisher">发布人：{{item.publisher}}</span>
        <span *ngIf="item.publish_time">发布时间：{{item.publish_time | date:"yyyy-MM-dd HH:mm"}}</span>
        <span *ngIf="item.column_name">所属栏目：{{item.column_name}}</span>
        <span *ngIf="item.source">新闻来源：{{item.source}}</span>
      </div>
      <!-- 文章部分 -->
      <div class="college-message" [innerHtml]="opera.assembleHTML(item.content)"></div>
    </div>
</div>
