<!-- 页头部分 -->
<div class="contentRight-header">企业中心 - 企业认证</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <div class="college-block">
    <!-- 页头部分 -->
    <div class="college-block-head collegeBlockMargin">
      <!-- <p>企业权益中心</p> -->
      <div class="college-tab">
        <span (click)="powerTab()" [class.bold]="power">企业认证权益</span>
         <!-- 高级搜索暂时下线 -->
        <!-- <span (click)="searchTab()" [class.bold]="!power">人才搜索权益</span> -->
      </div>
    </div>

    <!-- 介绍 -->
    <div class="college-block" *ngIf="power">
      <h3 class="power-title powerTitle"><img src="assets/images/college-status-six.png" alt=""> 我的认证权益</h3>
      <!-- 信息展示部分 -->
      <div class="college-list collegeList">
        <div class="college-message collegeMessage clear">
          <div class="left">当前状态：</div>
          <div class="left result">审核中</div>
        </div>
      </div>
      <h3 class="power-title powerTitle"><img src="assets/images/college-status-seven.png" alt="">认证说明</h3>
      <div class="powerBlock powerExplain">
        <p>1.根据《中华人民共和国网络安全法》，使用工作啦平台进行招聘的机构或组织，需提交机构与经办人个人的认证材料，经审核与本案通过后，方可正常使用工作啦平台实施招聘或用户信息发布行为。</p>
        <p>2.不同单位性质需要提交不同的认证材料。</p>
        <p>3.申请企业认证，需一次性支付300元认证服务费。个体工商户暂未开放认证，为了更好的支持公益事业，为工作啦平台提供更优质的服务，对基金会免收认证费用。</p>
        <p>4.工作啦认证成功后，认证标识及认证特权将会保留一年（自认证成功之日起计算，一年内有效）。</p>
        <p>5.餐饮娱乐及相关行业中，经营范围涵盖KTV，娱乐表演，夜总会，歌舞厅等不适宜校招的企业，暂不支持认证。</p>
        <p>6.保险企业认证时需提供集团公司/人力资源部出具的招聘委托书，暂不接受非人力资源部员工的企业认证申请。</p>
        <p>7.因企业认证申请方原因导致审核不通过，在退费时，系统将扣除10%的人工审核费。</p>
      </div>
    </div>
    <!-- 人才搜索权益 -->
    <div *ngIf="!power">
      <h3 class="power-title powerTitle"><img src="assets/images/college-status-six.png" alt="">我的搜索权益</h3>
      <div *ngIf="!config.searchStatus">
        <div class="powerBlock">
          <p class="powerState">当前状态：<span>未认证</span><button (click)="goSearch()">立即开通高级搜索</button></p>
        </div>
        <!-- <h3 class="power-title powerTitle"><img src="assets/images/college-status-three.png" alt="">企业认证介绍</h3>
              <div class="powerBlock">
                <span class="powerSee">点击查看</span>
              </div> -->
        <h3 class="power-title powerTitle"><img src="assets/images/college-status-two.png" alt="">人才搜索权益对比</h3>
        <div class="powerBlock powers flex_row_left">
          <div class="ordinaryPower flex_col_center">
            <h3>普通搜索</h3>
            <p class="powerMoney"><sup>￥</sup><span>0</span></p>
            <button class="have">已获得</button>
            <p class="transitionLine">————————————————</p>
            <p><em></em>按学校搜索</p>
            <p><em></em>按在招职位搜索</p>
            <del>&nbsp;按学校标签搜索&nbsp;</del>
            <del>&nbsp;按全部职位搜索&nbsp;</del>
            <del>&nbsp;按专业搜索&nbsp;</del>
            <del>&nbsp;按学历搜索&nbsp;</del>
            <del>&nbsp;按界别搜索&nbsp;</del>
            <del>&nbsp;按性别搜索&nbsp;</del>
            <del>&nbsp;按期望工作城市搜索&nbsp;</del>
          </div>
          <div class="vipPower flex_col_center">
            <img src="assets/images/college-status-five.png" alt="">
            <h3>高级搜索</h3>
            <p class="powerMoney"><sup>￥</sup><span class="redCol">298</span>/90天</p>
            <button class="goBuy" (click)="goSearch()">立即认证</button>
            <p class="transitionLine">————————————————</p>
            <p><em></em>按学校搜索</p>
            <p><em></em>按在招职位搜索</p>
            <p><em></em>按学校标签搜索</p>
            <p><em></em>按全部职位搜索</p>
            <p><em></em>按专业搜索</p>
            <p><em></em>按学历搜索</p>
            <p><em></em>按界别搜索</p>
            <p><em></em>按性别搜索</p>
            <p><em></em>按期望工作城市搜索</p>
          </div>
        </div>
      </div>
      <!-- 已认证 -->
      <div class="college-list collegeList" *ngIf="config.searchStatus">
        <div class="college-message collegeMessage clear">
          <div class="left">当前状态：</div>
          <div class="left">开通</div>
        </div>
        <div class="college-message collegeMessage clear" *ngIf="config.searchendTime">
          <!-- <div class="left">认证期限:</div>
                <div class="left">{{config.department.authentication_begintime | date:'yyyy.MM.dd'}}-{{config.department.authentication_endtime | date:'yyyy.MM.dd'}}</div> -->
          <div class="left">认证有效期至：</div>
          <div class="left">{{config.searchendTime | date:'yyyy.MM.dd'}}</div>
        </div>
        <div class="rightsCard">
          <p><i>————————</i><span>多维度搜索人才</span><i>————————</i></p>
          <span>已开通</span>
          <ul class="flex_row_around">
            <li><em></em>按学校搜索</li>
            <li><em></em>按专业搜索</li>
            <li><em></em>按期望工作城市搜索</li>
            <li><em style="margin-left: 1rem;"></em>按在招职位搜索</li>
            <li><em></em>按学历搜索</li>
            <li><em></em>按期望薪资搜索</li>
            <li><em></em>按学校标签搜索</li>
            <li><em></em>按界别搜索</li>
            <li><em></em>按是否在线搜索</li>
            <li><em></em>按全部职位搜索</li>
            <li><em></em>按性别搜索</li>
            <li>&nbsp;</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
