<!-- 页头部分 -->
<div class="contentRight-header"><img (click)="opera.goChangeRouter('college-campustalk')" src='assets/images/router-old.png' />校园招聘<span *ngIf="!config.isByCollege"> - 校园+</span> - 申请宣讲会</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-still-milk #child [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-still-milk>
    <app-model-position-more #child1 [isShow]="modelTwoShow" [initValue]="model.positionids" (onVoted)="ModelTwodismiss($event)"></app-model-position-more>
    <div class="college-block">
        <!-- 页头部分 -->
        <div class="college-block-head">申请宣讲会</div>


        <div style="width: 1000px;margin: 0 auto;height: 120px;background-color: #F0F1FF;overflow: hidden;" *ngIf="config.departmentSelf">
            <div class="guwen_bg_gray">
                <img class="guwen_info_huizhang" src="../../assets/images/kefu_hui.png">

                <img class="guwen_head" src="{{config.departmentSelf.avatar}}">
                <div class="guwen_infoDiv">
                    <ul>
                        <li><img style="width: 120px;height: 20px" src="../../assets/images/guwen_title.png"></li>
                        <li><span class="guwen_info_name">{{config.departmentSelf.name}}</span></li>
                        <li>
                            <img style="width: 17px;height: 17px" src="../../assets/images/kefu_wx.png">
                            <span class="guwen_info_title">微信：</span>
                            <span class="guwen_info_wx">{{config.departmentSelf.wx}}</span>
                        </li>
                        <li style="width: 250px">
                            <img style="width: 17px;height: 17px" src="../../assets/images/kefu_email.png">
                            <span class="guwen_info_title">邮箱：</span>
                            <span class="guwen_info_wx">{{config.departmentSelf.email}}</span>
                        </li>
                    </ul>
                </div>
                <img class="guwen_head_code" style="margin-left: 5px;" src="{{config.departmentSelf.qr_code}}">
                <div class="guwen_info_Line"></div>
                <!--<span class="guwen_info_rightText">双选会/宣讲会申请相关问题， 请扫码咨询！</span>-->
                <div class="guwen_info_rightText">
                    <p>双选会/宣讲会申请相关问题,</p>
                    <p>请扫码咨询！<span>{{config.departmentSelf_ex}}</span></p>
                </div>

            </div>
        </div>
        <div (click)="isShowCampChipsWithCollege=true" style="width: 50px;height: 50px;position:absolute;right: 300px;top: 205px;cursor: pointer" *ngIf="isHaveCampChipsWithCollege&&!isShowCampChipsWithCollege">
            <img src="../../assets/images/zhuxiao_waring.png" style="width: 50px;height: 50px" />
        </div>

        <!--//顶部状态-->
        <div style="width: 100%;height: 140px;margin-top: 30px;">
            <div style="width: 33%;height: 120px;text-align: center;float: left">
                <div style="width: 100px;height: 60px;;margin-right: 15px;float: right">
                    <!--<div style="width: 40px;height: 40px;background-color: #5e4fff;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel?.status==0">1</div>-->
                    <!--<div style="width: 40px;height: 40px;background-color: #30c742;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel?.status==1">1</div>-->
                    <!--<div style="width: 40px;height: 40px;background-color: #30c742;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel?.status==2">1</div>-->
                    <!--<div style="width: 40px;height: 40px;background-color: #fa5d5e;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel.status==3">1</div>-->

                    <img style="width: 52px;height: 52px;" src="../../assets/images/au_ing.png" *ngIf="CollegeIdentityModel.status==0" />
                    <img style="width: 52px;height: 52px;" src="../../assets/images/au_shenqing.png" *ngIf="CollegeIdentityModel.status==1" />
                    <img style="width: 52px;height: 52px;" src="../../assets/images/au_success.png" *ngIf="CollegeIdentityModel.status==2" />
                    <img style="width: 52px;height: 52px;" src="../../assets/images/au_fail.png" *ngIf="CollegeIdentityModel.status==3" />
                    <img style="width: 52px;height: 52px;" src="../../assets/images/au_fail.png" *ngIf="CollegeIdentityModel.status==5" />

                    <div style="width: 100px;margin-top: 5px;color: #000000">资质审核</div>
                    <div class="statuBorder_auing" style="width: 100px;margin:0;margin-top: 5px;padding-top: 5px;padding-bottom: 5px" *ngIf="CollegeIdentityModel.status==0">申请中</div>
                    <div class="statuBorder_success" style="width: 100px;margin:0;margin-top: 5px;padding-top: 5px;padding-bottom: 5px" *ngIf="CollegeIdentityModel.status==2">已通过</div>
                    <div class="statuBorder_ing" style="width: 100px;margin:0;margin-top: 5px;padding-top: 5px;padding-bottom: 5px" *ngIf="CollegeIdentityModel.status==1">审核中</div>
                    <div class="statuBorder_fail" style="width: 100px;margin:0;margin-top: 5px;padding-top: 5px;padding-bottom: 5px" *ngIf="CollegeIdentityModel.status==3">审核失败</div>

                    <div style="width: 100px;margin:0;margin-top: 5px;color: #fa5d5e" *ngIf="CollegeIdentityModel.status==3">{{CollegeIdentityModel.remark}}</div>
                    <!--<div style="width: 100px;height: 20px;margin-top: 5px;color: #fa5d5e" *ngIf="CollegeIdentityModel.status==3 && CollegeIdentityModel.no_apply_30day" >请申请其他高校</div>-->
                </div>
            </div>

            <div style="height: 3px;width:  34%;background-color: #5e4fff;float: left;margin-top: 17px"></div>

            <div style="width:  33%;height: 120px;text-align: center;float: right">
                <div style="width: 100px;height: 60px;margin-left: 15px;float: left">

                    <img style="width: 52px;height: 52px;" *ngIf="!applyDetails &&(CollegeIdentityModel.status==0||CollegeIdentityModel.status==3||CollegeIdentityModel.status==5)" src="../../assets/images/milk_none.png" />
                    <img style="width: 52px;height: 52px;" *ngIf="!applyDetails &&(CollegeIdentityModel.status==1||CollegeIdentityModel.status==2)" src="../../assets/images/milk_suc.png" />

                    <img style="width: 52px;height: 52px;" *ngIf="applyDetails&&applyDetails.audit_status=='to_audit'" src="../../assets/images/milk_ing.png" />
                    <img style="width: 52px;height: 52px;" *ngIf="applyDetails&&applyDetails.audit_status=='passed'" src="../../assets/images/milk_success.png" />
                    <img style="width: 52px;height: 52px;" *ngIf="applyDetails&&applyDetails.audit_status=='not_passed'" src="../../assets/images/milk_fail.png" />

                    <div style="width: 100px;height: 20px;margin-top: 5px;;color: #000000">报名宣讲会</div>

                    <div class="statuBorder_none" style="margin:0;margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="!applyDetails &&(CollegeIdentityModel.status==0||CollegeIdentityModel.status==3||CollegeIdentityModel.status==5)">未申请</div>
                    <div class="statuBorder_auing" style="margin:0;margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="!applyDetails &&(CollegeIdentityModel.status==1||CollegeIdentityModel.status==2)">申请中</div>

                    <div class="statuBorder_ing" style="margin:0;margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="applyDetails&&applyDetails.audit_status=='to_audit'">审核中</div>
                    <div class="statuBorder_success" style="margin:0;margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="applyDetails&&applyDetails.audit_status=='passed'">已通过</div>
                    <div class="statuBorder_fail" style="margin:0;margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="applyDetails&&applyDetails.audit_status=='not_passed'">已拒绝</div>


                </div>
            </div>
        </div>

        <div style="width: 100px;height: 20px;margin-top: 5px;color: white;background-color: white;text-align: center;padding:8px 15px;margin-left: 46%;margin-bottom: 15px;border-radius: 5px" *ngIf="CollegeIdentityModel.status==3 && CollegeIdentityModel.no_apply_30day"></div>


        <div class="noPass" *ngIf="applyDetails&&applyDetails.audit_status=='not_passed'">原因：{{applyDetails.reason}}</div>


        <div class="company-block" [hidden]="!showTab1">
            <!-- 中间部分 -->
            <div class="info-block">

                <div *ngIf="collegeName&&orgList_shenhe.length==0" class="identityCollege-name">当前申请高校：{{collegeName}}</div>
                <div *ngIf="orgList_shenhe.length>0" class="identityCollege-name">当前申请院系：
                    <select name="CIOrg" id="CIOrg" [(ngModel)]="dmodel.facultyid" autocomplete="off">
                        <option class="place-text" value='' disabled selected="selected">请选择院系</option>
                        <option *ngFor="let a of orgList_shenhe" [value]="a.facultyid">{{a.name}}</option>
                    </select>
                </div>

                <!-- 营业执照 -->
                <div class="info-list info-logo">
                    <div class="info-head">第一步&nbsp;&nbsp;营业执照/企业资质</div>
                    <label *ngIf="dmodel.business_licence" class="uploadImg-label department-logo" for="uploadLoginAvatar">
                        <img [src]="dmodel.business_licence">
                    </label>
                    <label *ngIf="!dmodel.business_licence" class="uploadImg-label" for="uploadLoginAvatar">
                        <img src="assets/images/add-img.png"><br/>点击上传&nbsp;&nbsp;营业执照/企业资质
                    </label>
                    <input class="uploadImg" id="uploadLoginAvatar" (change)="preview_auc($event,'business_licence')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
                    <!--<p style="position: absolute;margin-left: 70px;margin-top: 10px;color: red;height: 50px;padding-bottom: 20px">注：属于培训学校的企业，需要上传办学许可证</p>-->

                    <ul>
                        <li>1. 上传营业执照名称必须与公司全称保持一致</li>
                        <li>2. 请提供有效年检期内的《企业法人营业执照》副本</li>
                        <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                        <li>4. 高校就业老师将会在 1 -3个工作日内审核</li>
                        <li>5. 企业资质是指：有的公司没有营业执照，可以根据<br> &nbsp;&nbsp;&nbsp;&nbsp;不同行业上传不同的证件；如：基金会等公益组织，
                            <br> &nbsp;&nbsp;&nbsp;&nbsp;上传法人登记证书等。
                        </li>
                        <li>6. 属于培训学校的企业，需要上传办学许可证</li>
                        <!--<li style="margin-top: 10px">-->
                        <!--&lt;!&ndash;<input name="attachment" value='Brows' placeholder="+上传办学许可证" style="width: 100px;height:40px;background-color: lightgray;padding: 5px 15px;margin-top: 10px" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">&ndash;&gt;-->

                        <!--<div style="width: 150px;height: 25px;background-color: lightgray">-->
                        <!--<label style="width: 150px;height: 25px;background-color: lightgray;margin-top: -25px;margin-left: 0px;">+&nbsp;上传办学许可证-->
                        <!--<input id="uploadLoginbanXue" (change)="previewPic($event,'school_permit')" style="width: 150px;height: 25px;background-color: lightgray" type="file" accept="image/jpg,image/png,image/jpeg,image/bmp" style="display:none;">-->
                        <!--</label>-->
                        <!--</div>-->

                        <!--</li>-->
                    </ul>
                </div>

                <!--<label *ngIf="dmodel.school_permit"  for="uploadLoginbanXue" style="width:240px;height:190px">-->
                <!--<img style="width:240px;height: 190px;margin-left: 71px;margin-top: 35px;border-radius: 5px" [src]="dmodel.school_permit">-->
                <!--</label>-->

                <div class="info-list info-logo" *ngIf="myDepartment?.industry=='0606'||myDepartment?.industry=='0604'||myDepartment?.industry=='0605'">
                    <label *ngIf="dmodel.school_permit" class="uploadImg-label department-logo" for="uploadLoginbanXue">
                        <img [src]="dmodel.school_permit">
                    </label>
                    <label *ngIf="!dmodel.school_permit" class="uploadImg-label" for="uploadLoginbanXue">
                        <img src="assets/images/add-img.png"><br/>点击上传&nbsp;&nbsp;上传办学许可证
                    </label>
                    <input class="uploadImg" id="uploadLoginbanXue" (change)="preview_auc($event,'school_permit')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
                </div>

                <!-- 营业执照 -->
                <div class="info-list info-logo">
                    <div class="info-head">第二步&nbsp;&nbsp;招聘者身份证</div>
                    <label *ngIf="dmodel.id_card_front" class="uploadImg-label department-logo" for="uploadGuideCard">
                        <img [src]="dmodel.id_card_front">
                    </label>
                    <label *ngIf="!dmodel.id_card_front" class="uploadImg-label" for="uploadGuideCard">
                        <img src="assets/images/add-img.png"><br/>点击上传手持身份证
                    </label>
                    <input class="uploadImg" id="uploadGuideCard" (change)="preview_auc($event,'id_card_front')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
                    <ul>
                        <li>1. 上传身份证姓名必须与招聘联系人姓名保持一致</li>
                        <li>2. 请提供有效期内的本人手持《身份证》照片</li>
                        <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                        <li>4. 高校就业老师将会在 1 -3个工作日内审核</li>
                    </ul>
                </div>
                <!-- 招聘委托书 -->
                <div *ngIf="secureMaterail" class="info-list info-logo">
                    <div class="info-head">第三步&nbsp;&nbsp;招聘委托书</div>
                    <label *ngIf="dmodel.proxy_document" class="uploadImg-label department-logo" for="uploadProxy">
                        <img [src]="dmodel.proxy_document">
                    </label>
                    <label *ngIf="!dmodel.proxy_document" class="uploadImg-label" for="uploadProxy">
                        <img src="assets/images/add-img.png"><br/>点击上传招聘委托书
                    </label>
                    <input class="uploadImg" id="uploadProxy" (change)="preview_auc($event,'proxy_document')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
                    <ul>
                        <li>1. 点击下载：<a [href]="secureMaterail">《招聘委托书示例》</a></li>
                        <li>2. 修改后请加盖公章，截图或拍照上传</li>
                    </ul>
                </div>
                <!-- 统一社会信用代码 -->
                <div class="info-list info-number">
                    <div *ngIf="!secureMaterail" class="info-head">第三步&nbsp;&nbsp;统一社会信用代码</div>
                    <div *ngIf="secureMaterail" class="info-head">第四步&nbsp;&nbsp;统一社会信用代码</div>
                    <input type="text" placeholder="请填写营业执照统一社会信用代码" [(ngModel)]="dmodel.register_number" maxlength="18">
                    <ul>
                        <li>1. 填写的统一社会信用代码必须与营业执照保持一致</li>
                        <li>2. 高校就业老师将会在 1 -3个工作日内审核</li>
                    </ul>
                </div>
                <!-- 办公电话 -->
                <div class="info-lis" *ngIf="authcation_office_phone">
                    <div *ngIf="!secureMaterail" class="info-head">第四步&nbsp;&nbsp;办公电话</div>
                    <div *ngIf="secureMaterail" class="info-head">第五步&nbsp;&nbsp;办公电话</div>
                    <input type="text" [(ngModel)]="dmodel.office_phone" (blur)="checkPhone(dmodel.office_phone)" placeholder="请填写固话" class="info-phone">
                    <!-- <b *ngIf="!model.office_phone"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b> -->
                </div>
                <!-- 申请简述 -->
                <div class="info-list">
                    <div *ngIf="!secureMaterail&&!authcation_office_phone" class="info-head">第四步&nbsp;&nbsp;申请简述</div>
                    <div *ngIf="secureMaterail&&!authcation_office_phone" class="info-head">第五步&nbsp;&nbsp;申请简述</div>
                    <div *ngIf="!secureMaterail&&authcation_office_phone" class="info-head">第五步&nbsp;&nbsp;申请简述</div>
                    <div *ngIf="secureMaterail&&authcation_office_phone" class="info-head">第六步&nbsp;&nbsp;申请简述</div>
                    <div class="list-brief">
                        <textarea placeholder="请简述申请校招的目的(最多100字)" scrollHeight [(ngModel)]="dmodel.brief" minlength="0" maxlength="100">{{dmodel.brief}}</textarea>

                    </div>
                </div>
                <!-- 按钮 -->
                <div class="block-button">
                    <button (click)="uploadDepartmentIdentity()">提交</button>
                </div>
            </div>
        </div>


        <!-- 申请部分 -->
        <div class="sign-block" [hidden]="!showTab2">
            <!-- 当前学校 -->
            <div class="sign-list" *ngIf="college">
                <span>当前学校：</span><input type="text" [(ngModel)]="college.name" disabled="disabled">
            </div>
            <!-- 宣讲主题 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>宣讲主题：</span><input type="text" [(ngModel)]="model.name" maxlength="25" placeholder="请填写宣讲主题" (blur)="checkName()">
                <b *ngIf="isSend&&!model.name"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 单位名称 -->
            <div class="sign-list" *ngIf="myDepartment">
                <span>单位名称：</span><input type="text" [(ngModel)]="myDepartment.name" disabled="disabled">
            </div>
            <!-- 开始时间 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>开始时间：</span><input type="text" id="start-time" [(ngModel)]="model.start_time" /><img class="calendar-img" src="assets/images/calendar.png" alt="打开日历" />
                <b *ngIf="isSend&&!model.start_time">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 结束时间 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>结束时间：</span><input type="text" id="end-time" [(ngModel)]="model.end_time" /><img class="calendar-img" src="assets/images/calendar.png" alt="打开日历" />
                <b *ngIf="isSend&&!model.end_time">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 期望规模 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>期望学生人数：</span><input type="number" [(ngModel)]="model.scale" maxlength="10" (blur)="checkNum()" placeholder="请填写期望规模">
                <b *ngIf="isSend&&!model.scale"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 选择校区 -->
            <div class="sign-list sign-skill" *ngIf="campusList.length>0">
                <span><b class="remind-red">*</b>选择校区：</span>
                <select name="campusList" id="campusList" [(ngModel)]="model.campusid" autocomplete="off" (ngModelChange)="getCampusOrgList()">
                <option class="place-text" value='' disabled selected="selected">请选择校区</option>
                <option *ngFor="let a of campusList" [value]="a.campusid">{{a.name}}</option>
            </select>
                <b *ngIf="isSend&&!model.campusid"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 宣讲范围 -->
            <div class="sign-list sign-skill">
                <span><b class="remind-red">*</b>宣讲范围：</span>
                <div class="label-list clear">
                    <div class="label-item label-click" [class.purple]="model.campustalk_type=='college'" (click)="model.campustalk_type='college'">
                        <div class="label-text">校级宣讲会</div>
                    </div>
                    <div class="label-item label-click" [class.purple]="model.campustalk_type=='faculty'" (click)="model.campustalk_type='faculty'">
                        <div class="label-text">院级宣讲会</div>
                    </div>
                </div>
            </div>
            <!-- 申请院系（院级） -->
            <div class="sign-list sign-skill" *ngIf="model.campustalk_type=='faculty'" (click)="checkOrgList()">
                <span><b class="remind-red">*</b>申请院系：</span>
                <select name="position" id="position" [(ngModel)]="model.facultyid" autocomplete="off" (ngModelChange)="newMajor()">
                <option class="place-text" value='' disabled selected="selected">请选择院系</option>
                <option *ngFor="let a of orgList" [value]="a.facultyid">{{a.name}}</option>
            </select>
                <b *ngIf="isSend&&!model.facultyid"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 意向专业 （院级）-->
            <div class="sign-list sign-skill" *ngIf="model.campustalk_type=='faculty'">
                <span><b class="remind-red">*</b>意向专业<i>(限十个)</i>：</span>
                <div class="label-list clear" *ngIf="majorList.length==0">
                    <div class="label-item purple">
                        <div class="label-text">不限</div>
                    </div>
                </div>
                <div class="label-list clear" *ngIf="majorList.length>0">
                    <div class="label-item label-click" [class.purple]="m.isActive" (click)="newMajorList(m)" *ngFor="let m of majorList">
                        <div class="label-text">{{m.name}}</div>
                    </div>
                </div>
            </div>
            <!-- 申请院系（校级） -->
            <div class="sign-list sign-skill" *ngIf="model.campustalk_type=='college'">
                <span><b class="remind-red">*</b>申请院系：</span>
                <div class="label-list clear" *ngIf="model.major_list.length==0">
                    <div class="label-item purple">
                        <div class="label-text">不限</div>
                    </div>
                    <div class="label-item label-click" (click)="goMajor()">
                        <div class="label-text">添加+</div>
                    </div>
                </div>
                <div class="label-list clear" *ngIf="model.major_list.length>0">
                    <div class="label-item purple" *ngFor="let m of model.major_list">
                        <div class="label-text">{{m.faculty_name}}</div>
                    </div>
                    <div class="label-item label-click" (click)="goMajor()">
                        <div class="label-text">添加+</div>
                    </div>
                </div>
            </div>
            <!-- 意向专业 （校级）-->
            <div class="sign-list sign-skill" *ngIf="model.campustalk_type=='college'">
                <span><b class="remind-red">*</b>意向专业<i>(限十个)</i>：</span>
                <div class="label-list clear" *ngIf="collegeMajorList.length==0">
                    <div class="label-item purple">
                        <div class="label-text">不限</div>
                    </div>
                    <div class="label-item label-click" (click)="goMajor()">
                        <div class="label-text">添加+</div>
                    </div>
                </div>
                <div class="label-list clear" *ngIf="collegeMajorList.length>0">
                    <div class="label-item purple" *ngFor="let m of collegeMajorList">
                        <div class="label-text">{{m.text}}</div>
                    </div>
                    <div class="label-item label-click" (click)="goMajor()">
                        <div class="label-text">添加+</div>
                    </div>
                </div>
            </div>
            <!-- 招聘职位 -->
            <div class="sign-list sign-skill">
                <span><b class="remind-red">*</b>招聘职位：</span>
                <div class="label-list clear" *ngIf="model.positionids.length==0">
                    <!-- <div class="label-item purple">
                    <div class="label-text">不限</div>
                </div> -->
                    <div class="label-item label-click" (click)="choosePosition()">
                        <div class="label-text">添加</div>
                    </div>
                </div>
                <div class="label-list clear" *ngIf="model.positionids.length>0">
                    <div class="label-item purple" *ngFor="let m of positionList">
                        <div class="label-text">{{m.name}}</div>
                    </div>
                    <div class="label-item label-click" (click)="choosePosition()">
                        <div class="label-text">更改</div>
                    </div>
                </div>
            </div>
            <!-- 联系人 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>联系人：</span><input type="text" [(ngModel)]="model.contacts" maxlength="8" placeholder="请填写联系人" (blur)="checkContact()">
                <b *ngIf="isSend&&!model.contacts"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 联系电话 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>联系电话：</span><input type="number" [(ngModel)]="model.phone" (blur)="checkPhone(model.phone)" placeholder="请填写联系电话">
                <b *ngIf="isSend&&!model.phone"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!--参会人数 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>企业参会人数：</span><input type="number" [(ngModel)]="model.attendance" oninput="if(value.length>2)value=value.slice(0,2)" placeholder="请填写参会人数">
                <b *ngIf="isSend&&!model.attendance"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 封面图 -->
            <div class="sign-list sign-img">
                <span>封面图：</span>
                <label *ngIf="model.cover" class="uploadImg-label department-logo" for="uploadCampusImg">
                <img [src]="model.cover">
            </label>
                <label *ngIf="!model.cover" class="uploadImg-label" for="uploadCampusImg">
                <img src="assets/images/add-img.png"><br/>点击添加封面图
            </label>
                <input class="uploadImg" id="uploadCampusImg" (change)="previewPic($event)" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
                <br/><s>*像素：1242*650px,格式：jpg,png</s>
            </div>

            <!--附件 -->
            <div class="sign-list sign-skill enclosure" *ngIf="audit_switch.department_receipt">
                <span style="line-height:200px;"><b class="remind-red">*</b>附件：</span>
                <div class="company-imglist clear">
                    <!--<div class="left img-list" *ngFor="let img of model.attachment;let num=index">-->
                    <!--<img [src]="img+'?imageView2/1/w/240/h/180'">-->
                    <!--<div (click)="deleteImg(img)" class="delete-img"><img src="assets/images/delect.png"></div>-->
                    <!--</div>-->
                    <div class="left img-list" *ngIf="model.attachment.length < 3">
                        <label class="uploadImg-label" for="uploadMilkImg">
                        <img src="assets/images/add-img.png"><br/>点击添加附件
                    </label>
                        <input class="uploadImg" id="uploadMilkImg" (change)="previewMilkPic($event)" type="file" accept="multiple">
                    </div>
                    <a class="left downloadBlock" href="{{audit_switch.department_receipt_url}}" download="单位招聘委托书.docx">下载参会回执</a>
                </div>
                <br/><s>*请遵照高校要求上传参会回执、委托书或招聘公函等附件，最多3个附件，大小：小于5M。</s>
            </div>

            <div class="flex_l fileRow" *ngFor="let img of model?.attachment;let num=index">
                <div class="flex_l "> 附件{{num+1}}: &nbsp; {{formatFileName(img)}} <span class="fileRow-delete" (click)="deleteFlie(img)">删除</span></div>
            </div>

            <!-- 宣讲会介绍  [config]="config"-->
            <div class="sign-list sign-editor">
                <span><b class="remind-red">*</b>宣讲会介绍：</span>
                <ngx-neditor [(ngModel)]="model.intro" #neditor [config]="toolConfig"></ngx-neditor>
            </div>



            <!--<div class="sign-list">-->
            <!--<span>附件：</span>-->
            <!--<input type="file" style="width: 450px;height: 30px;font-size: 14px;margin-top: 10px" id="file_input" (change)="previewMilkWord($event)" multiple="multiple" />-->
            <!--</div>-->

            <!-- 提交 -->
            <div class="sign-submit">
                <button class="purple" (click)="goSign()">提交申请</button>
            </div>
        </div>
    </div>
</div>

<p-dialog [closeOnEscape]="false" [showHeader]="false" [dismissableMask]="false" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isShowCampChipsWithCollege" [style]="{'width':600,'height':450,'minHeight':450,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"
    [modal]=true>
    <div class="signCampCollegeChips">
        <div class="signCampCollegeChips_title">高校提示</div>
        <div class="signCollegeChips_Content" [innerHTML]="isHaveCampChipsWithContent">

        </div>
        <div class="signCollegeChips_Btn" *ngIf="ChipsWithCollegeBtn" (click)="collegeChipsClick()">{{ChipsWithCollegeBtn}}</div>
    </div>
</p-dialog>