<!-- 页头部分 -->
<div class="contentRight-header">企业中心 - 公司信息</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <router-outlet></router-outlet>
    <app-model-select-two #industry [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-select-two>
    <app-model-company-label #label [isShow]="modelTwoShow" (onVoted)="ModelTwodismiss($event)"></app-model-company-label>
    <app-model-company-product #product [isShow]="modelThreeShow" (onVoted)="ModelThreedismiss($event)"></app-model-company-product>
    <app-model-company-courses #courses [isShow]="modelFourShow" (onVoted)="ModelFourdismiss($event)"></app-model-company-courses>
    <app-model-company-strength #strength [isShow]="modelFiveShow" (onVoted)="ModelFivedismiss($event)"></app-model-company-strength>
    <app-model-cropper #crop [isShow]="modelSixShow" (onVoted)="ModelSixdismiss($event)"></app-model-cropper>
    <div class="college-block" *ngIf="myDepartment">
        <!-- 页头部分 -->
        <div class="college-block-head">公司信息</div>
        <!-- <div class="sign-list">
            <span>成立年份：</span>
            <input type="text" id="establish" [(ngModel)]="testTime" placeholder="请选择成立年份"><img class="calendar-img" src="assets/images/calendar.png" alt="打开日历"/>
        </div> -->
        <!-- 申请部分 *ngIf="myDepartment"-->
        <div class="sign-block">
            <!-- 公司全称 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>公司全称：</span>
                <!--修改企业名称-->
                <!--<span (click)="seleteUpdateNameType()"  style="width: 60px;text-align: center">修改</span>-->
                <div class="selected-text disabled-text">{{myDepartment.name}}</div><span (click)="seleteUpdateNameType()" style="width: 60px;text-align: center">修改</span>
            </div>

            <img *ngIf="CompanyStatus==1" style="width: 100px;height: 100px; margin-top: -50px;float: right" src="../../assets/images/companyStatus_loding.png">
            <img *ngIf="CompanyStatus==3" style="width: 100px;height: 100px; margin-top: -50px;float: right" src="../../assets/images/companyStatus_noPass.png">

            <!-- 公司简称 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>公司简称：</span>
                <div class="selected-text disabled-text">{{myDepartment.common_name}}</div>
                <!--<s class="remark-text">* 如需修改公司招聘名称，请联系客服：{{config.CustomerServicePhone}}</s>-->
            </div>
            <!-- 公司LOGO -->
            <div class="sign-list sign-img">
                <span>公司LOGO：</span>
                <label *ngIf="myDepartment.logo" class="uploadImg-label department-logo" for="uploadDepatmentLogoImg">
                    <img [src]="myDepartment.logo">
                </label>
                <label *ngIf="!myDepartment.logo" class="uploadImg-label" for="uploadDepatmentLogoImg">
                    <img src="assets/images/add-img.png"><br/>点击上传公司LOGO
                </label>
                <input class="uploadImg" id="uploadDepatmentLogoImg" (change)="previewPic($event)" type="file" accept="image/jpg,image/png">
                <br/><s>*像素：200*200px,格式：jpg,png</s>
            </div>
            <!-- 所属行业 -->
            <div class="sign-list" (click)="chooseIndustry()">
                <span><b class="remind-red">*</b>所属行业：</span>
                <div *ngIf="!model.industry" class="placholder-text">请选择选择所属行业</div>
                <div *ngIf="model.industry" class="selected-text" style="color: #999999">{{ model.industry | codetransform:config.industry}}</div>
            </div>
            <!-- 公司注册来源 港澳台高校才展示 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>企业注册地：</span>
                <!--<div class="selected-text" style="cursor: auto" >{{myDepartment.employer_category=='field_employer'?"港澳台":"大陆"}}</div>-->
                <select id="employer_category" autocomplete="off" style="width: 410px" disabled [(ngModel)]="myDepartment.employer_category">
                    <option *ngFor="let type of global_regionArr" disabled [value]="type.code">{{type.text}}</option>
                </select>
            </div>

            <!-- 公司性质 -->
            <div class="sign-list select-list">
                <span><b class="remind-red">*</b>公司性质：</span>
                <select autocomplete="off" [(ngModel)]="model.employer_type" [disabled]="model.employer_type?true:false">
                    <option class="place-text" value='' disabled hidden selected="selected">选择公司性质</option>
                    <option *ngFor="let type of config.employer_type" [value]="type.code">{{type.text}}</option>
                </select>
            </div>
            <!-- 注册资金 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>注册资金：</span><input type="text" (ngModelChange)="register_capitalChange($event)" [(ngModel)]="model.register_capital" autofocus="autofocus" onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                    placeholder="请填写注册资金">
                <select style="width: 85px;height: 44px;border-radius: 0;margin-left: 15px" [(ngModel)]="register_capital_unit" autocomplete="off" class="zijinType">
                    <option value="万元">万元</option>
                    <option value="亿元">亿元</option>
                </select>
                <select style="width: 85px;height: 44px;border-radius: 0;margin-left: 15px" [(ngModel)]="model.currency" autocomplete="off" class="zijinType">
                    <option *ngFor="let type of currency" [value]="type.code">{{type.text}}</option>
                </select>
            </div>
            <!-- 公司地址 -->
            <div class="sign-list select-list sign-address">
                <span><b class="remind-red">*</b>公司地址：</span>
                <select autocomplete="off" [(ngModel)]="workPlace">
                    <option class="place-text" value='' disabled hidden selected="selected">选择公司地址</option>
                    <option *ngFor="let address of myDepartment.locations" [value]="address.lid">{{address.region_text}}&nbsp;&nbsp;{{address.address}}</option>
                </select>
                <button (click)="goAddress()">地址管理</button>
            </div>
            <!-- 成立年份 [(ngModel)]="model.establish_year" -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>成立年份：</span>
                <input type="number" id="establish" [(ngModel)]="model.establish_year" placeholder="请输入成立年份">
                <span class="calendar-img" style="top:1px;margin-right: -15px;">年</span>
                <span style="text-align: left;margin-left: 15px;font-size: 12px;color: red;" *ngIf="!model.establish_year">*必填项</span>
            </div>
            <!-- 员工人数 -->
            <div class="sign-list select-list">
                <span><b class="remind-red">*</b>员工人数：</span>
                <select autocomplete="off" [(ngModel)]="model.scale">
                    <option class="place-text" value='' disabled hidden selected="selected">选择员工人数</option>
                    <option *ngFor="let scale of config.scale" [value]="scale.code">{{scale.text}}</option>
                </select>
            </div>
            <!-- 公司简介 -->
            <div class="sign-list sign-intro">
                <span><b class="remind-red">*</b>公司简介：</span>
                <textarea [(ngModel)]="model.intro" minlength="50" maxlength="500" placeholder="请填写公司简介（50-500字）"></textarea>
            </div>
            <!-- 是否上市 -->
            <div class="sign-list sign-skill">
                <span>是否上市：</span>
                <div class="label-list clear">
                    <div class="label-item label-click" [class.purple]="model.is_quoted_company" (click)="model.is_quoted_company=true">
                        <div class="label-text">上市公司</div>
                    </div>
                    <div class="label-item label-click" [class.purple]="!model.is_quoted_company" (click)="model.is_quoted_company=false">
                        <div class="label-text">非上市公司</div>
                    </div>
                </div>
            </div>
            <!-- 股票代码 -->
            <div class="sign-list" *ngIf="model.is_quoted_company">
                <span><b class="remind-red">*</b>股票代码：</span><input type="text" [(ngModel)]="model.stock_code" placeholder="请填写公司股票代码">
            </div>
            <!-- 公司实力 -->
            <div class="sign-list sign-skill">
                <span>公司实力：</span>
                <div class="label-list clear">
                    <div class="label-item purple" *ngFor="let label of model.strengths">
                        <div class="label-text">{{label.text}}</div>
                    </div>
                    <div class="label-item label-click" (click)="goStrengths()">
                        <div class="label-text">+添加</div>
                    </div>
                </div>
            </div>
            <!-- 福利待遇 -->
            <div class="sign-list sign-skill">
                <span>福利待遇：</span>
                <div class="label-list clear">
                    <div class="label-item purple" *ngFor="let label of model.labels">
                        <div class="label-text">{{label.text}}</div>
                    </div>
                    <div class="label-item label-click" (click)="goLabel()">
                        <div class="label-text">+添加</div>
                    </div>
                </div>
            </div>

            <!-- 微信公众号 -->
            <div class="sign-list">
                <span>微信公众号：</span><input type="text" [(ngModel)]="model.official_account" placeholder="请填写公司微信公众号">
            </div>
            <!-- 公司网址 -->
            <div class="sign-list">
                <span>公司网址：</span><input type="text" [(ngModel)]="model.website" placeholder="请填写公司网址">
            </div>
            <!-- 公司产品 -->
            <div class="sign-list sign-skill">
                <span>公司产品：</span>
                <div class="product-block" *ngIf="model.products.length>0">
                    <div class="product-list time-line" *ngFor="let n of model.products">
                        <b><img src="assets/images/line-time.png"></b>
                        <div (click)="goEditorProduct(n)" title="编辑" class="product-top">{{n.name}}<img src="assets/images/conpany-editor.png" alt="编辑产品"></div>
                        <div class="product-txt clear">
                            <div class="left">
                                <img *ngIf="n.image_url" [src]="n.image_url+'?imageView2/1/w/180/h/180'">
                                <img *ngIf="!n.image_url" src="assets/images/resume-search-fail.png">
                            </div>
                            <div class="right">{{n.description}}</div>
                            <!-- <textarea class="right" [(ngModel)]="n.description" disabled></textarea> -->
                        </div>
                    </div>
                    <div class="product-list"><button (click)="goProduct()">+添加</button></div>
                </div>
                <div class="label-list clear" *ngIf="model.products.length==0">
                    <div class="label-item label-click" (click)="goProduct()">
                        <div class="label-text">+添加</div>
                    </div>
                </div>

            </div>
            <!-- 发展历程 -->
            <div class="sign-list sign-skill">
                <span>发展历程：</span>
                <div class="product-block" *ngIf="model.courses.length>0">
                    <div class="product-list time-line" *ngFor="let n of model.courses">
                        <b><img src="assets/images/line-time.png"></b>
                        <div class="product-top" title="编辑" (click)="goEditorCourses(n)">{{n.occur_time | date:"yyyy-MM-dd"}}<img src="assets/images/conpany-editor.png" alt="编辑产品"></div>
                        <div class="product-title">{{n.name}}</div>
                        <div class="product-link"><a href="{{n.link}}">{{n.link}}</a></div>
                        <div class="product-details" [innerHtml]="n.details | textformat"></div>
                        <!-- <textarea class="product-details" [(ngModel)]="n.details" disabled></textarea> -->
                    </div>
                    <div class="product-list"><button (click)="goCourses()">+添加</button></div>
                </div>
                <div class="label-list clear" *ngIf="model.courses.length==0">
                    <div class="label-item label-click" (click)="goCourses()">
                        <div class="label-text">+添加</div>
                    </div>
                </div>
            </div>
            <!-- 公司展示 -->
            <div class="sign-list sign-skill">
                <span style="line-height:200px;">公司展示：<br/><i>(限10张)</i></span>
                <div class="company-imglist clear">
                    <div class="left img-list" *ngFor="let img of model.image_urls;let num=index">
                        <img [src]="img+'?imageView2/1/w/240/h/180'">
                        <div (click)="deleteImg(img)" class="delete-img"><img src="assets/images/delect.png"></div>
                    </div>
                    <div class="left img-list">
                        <label class="uploadImg-label" for="uploadDepartmentImg">
                            <img src="assets/images/add-img.png"><br/>点击添加图片
                        </label>
                        <input class="uploadImg" id="uploadDepartmentImg" (change)="previewDepartmentPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                    </div>
                </div>

            </div>
            <!-- 保存 -->
            <div class="sign-submit">
                <button class="purple" (click)="updateDepartment()">保存</button>
            </div>
        </div>
    </div>
</div>


<p-dialog [closeOnEscape]="false" [(visible)]="ShowUpdateModel2" [style]="{'width':700,'height':700,'minHeight':700}" [modal]=true>
    <p-header>修改信息</p-header>

    <span style="color: red;text-align: center">*&nbsp;修改成功后，以往申请过的校招、职位、招聘简章都将变更为待审核状态，需要联系高校重新审核</span>
    <div class="updateCompanyName">
        <span class="updateCompanyNameSpan"><span class="updateCompanyNameredSpan">*</span>公司全称:</span><input placeholder="请输入公司全称" [(ngModel)]="updateComModel.department_name" class="updateCompanyNameinput" maxlength="40" />
    </div>

    <div class="updateCompanyName">
        <span class="updateCompanyNameSpan"><span class="updateCompanyNameredSpan">*</span>公司简称:</span><input [maxlength]="6" placeholder="最多6个字" [(ngModel)]="updateComModel.common_name" class="updateCompanyNameinput" maxlength="10" (blur)="checkCommonName()"
        />
    </div>
    <div class="updateCompanyName">
        <span class="updateCompanyNameSpan"><span class="updateCompanyNameredSpan">*</span>企业有效证照号码:</span><input placeholder="请输入企业有效证照号码" [(ngModel)]="updateComModel.register_number" class="updateCompanyNameinput" maxlength="18" />
    </div>


    <div class="updateCompanyNameH">
        <span class="updateCompanyNameSpan"><span class="updateCompanyNameredSpan">*</span>企业有效证照:</span>
        <label class="uploadImg-label">
      <img *ngIf="!updateComModel.business_licence_url" style="width: 100px;height: 100px" src="../../assets/images/addImage.png">
      <img *ngIf="updateComModel.business_licence_url" style="width: 100px;height: 100px" src={{updateComModel.business_licence_url}}>
      <input class="uploadImg" id="uploadDepatmentLogoImg1" (change)="getPicData($event,'yingyezhizhao')" type="file" accept="image/jpg,image/png">
    </label>
    </div>

    <div class="updateCompanyNameH">
        <span class="updateCompanyNameSpan"><span class="updateCompanyNameredSpan">*</span>资质变更授权书:</span>
        <label class="uploadImg-label">
      <img *ngIf="!updateComModel.change_letter_url" style="width: 100px;height: 100px" src="../../assets/images/addImage.png">
      <img *ngIf="updateComModel.change_letter_url" style="width: 100px;height: 100px" src={{updateComModel.change_letter_url}}>
      <input class="uploadImg" id="uploadDepatmentLogoImg2" (change)="getPicData($event,'biangeng')" type="file" accept="image/jpg,image/png">
      <span style="font-size: 14px">请上传清晰的变更函图片</span>
    </label>
    </div>

    <div class="updateCompanyName">
        <a style="color: red;text-decoration:underline;text-align: left;font-size: 15px;height: 40px;margin-left: 150px" href="../../assets/资质变更授权书.docx" target="_Blank">下载资质变更授权书</a>
    </div>

    <div class="updateCompanyNameH">
        <span class="updateCompanyNameSpan"><span class="updateCompanyNameredSpan">*</span>备注:</span><textarea style="width: 300px;height: 100px" placeholder="请输入备注" [(ngModel)]="updateComModel.remark" class="updateCompanyNameinput" maxlength="300"></textarea>
    </div>

    <button class="layui-btn upodateBtn" (click)="update('2')">修改</button>

</p-dialog>