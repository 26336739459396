<!-- 二级多选 -->
<!-- 遮罩层公共样式 -->
<!-- <div class="model-block select-block" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>
    
</div> -->

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px','width':'700px'}" [(visible)]="isShow" (onHide)="dismiss(false)" [modal]=true>
    <div class="model-cont">
        <!-- 上层提示部分 -->
        <div class="model-head clear">
            <div class="left">{{title}}</div>
            <!-- <button class="right" (click)="dismiss(false)">×</button> -->
            <div class="clos" (click)="dismiss(false)"><img src="/assets/images/v3/contactMeClos.png"></div>
        </div>
        <div class="select-more-title  clear">

            <div class="select-more-absolute">
                <div class="title-block">已选：
                    <div class="right-block"><span>{{initValueTwoDataArray.length}}</span> / {{initLength}}</div>
                </div>

                <div class="select-choose-block" style="height: 46px">
                    <div class="choose-list">
                        <div class="choose-item" *ngFor="let item of initValueTwoDataArray;let last=last">
                            <div class="choose-text">{{ item.text }}</div>
                            <div class="choose-delete" (click)="getSecendLevel($event,item)">x</div>
                        </div>
                    </div>
                </div>

                <div class="sea-box">
                    <input style="" type="text" [(ngModel)]="model.name" placeholder="输入关键词搜索" (ngModelChange)="getSeaMajorLst()" />
                    <div *ngIf="isShowRes" class="list-box">
                        <div *ngFor="let item of seaList" (click)="getSecendLevel($event,item)">{{item.text}}</div>
                    </div>
                </div>

            </div>

            <div id="first-level" class="first-level" style="overflow-y: scroll;">
                <div class="list-block" *ngFor="let item of firstLevel" (click)="getFirstLevel(item)" [class.activeAddress]="item.code==oneActive.code">
                    <div *ngIf="initValueOneArray.indexOf(item.code)>-1" class="active-pic"><span></span></div>
                    {{ item.text }}
                </div>
            </div>

            <div id="secend-level" class="secend-level" style="overflow-y: scroll;">
                <div class="list-block" *ngFor="let item of secendLevel" (click)="getSecendLevel($event,item)" [class.active]="item.isShow!='0' && item.isShow">
                    <div class="list-text" [class.activeChild]="initValueTwoArray.indexOf(item.code)>-1">
                        <!--<div *ngIf="initValueTwoArray.indexOf(item.code)>-1" class="active-pic"><img src="assets/images/select-choose-pic.png"></div>-->
                        {{ item.text }}
                    </div>

                </div>
            </div>
        </div>
        <!-- <div class="select-submit"><button class="purple" (click)="dismiss(initValueTwoArray)">保存</button></div> -->
        <!-- 底部操作部分 -->
        <div class="model-foot">
            <div class="purple blueStreamer" (click)="dismiss(initValueTwoArray)">确定</div>
        </div>
    </div>
</p-dialog>