import { Component, OnInit, Input, Output } from '@angular/core';
import { DataApi, Local, Constants, Config, Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { timeout } from 'q';
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
  providers: [DatePipe],

})
export class HomepageComponent implements OnInit {
  public config: Config;
  public myDepartment: any;//当前登录的企业信息(department)
  public myUser: any;//当前登录的用户信息(user)
  public nowDate = "";//当前年月日
  public nowWeek = "";//当前星期
  public nowNoon = "";//当前上中下午
  public weekday = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
  public positionList = [];//在招职位列表
  public listCount = 0;//页面列表一共的数据------在招职位
  //筛选条件---在线
  public model = {
    limit: 100,
    offset: 0,
    status: 'release',
  };
  public ListModel1 = {
    status: '',
    name: '',
    need_perfect: true,
  }
  public jobProgress = {
    cooperation: { total: 0, today: 0, yesterday: 0, stauts: 0 },
    delivery: { total: 0, today: 0, yesterday: 0, stauts: 0 },
    dialogue: { total: 0, today: 0, yesterday: 0, stauts: 0 },
    interview: { total: 0, today: 0, yesterday: 0, stauts: 0 },
    milkround: { total: 0, today: 0, yesterday: 0, stauts: 0 },
    campustalk: { total: 0, today: 0, yesterday: 0, stauts: 0 }
  };//招聘进度

  public collegePart = [];//合作院校
  public backlogList = [];//待办事项
  public countCollege = 0;//工作啦平台合作院校数量

  public codeURL = '';
  public WanShanZhiweiStr = '';

  public shouWeiChatCode = false;
  public ShowWeiWanShanZhiwei = false;
  public SchoolPhone = '';
  public isShowPerfetInfo = false;
  public showBingLoginEmailModel = false;
  public bindEmailModel = {
    email: '',
    code: '',
    psw: '',
    psw2: ''
  };
  public loginUser: any;
  public sendEmailText2 = '获取邮箱验证码';
  public timer1 = null;

  @Output() collegeIdentity: any;//高校认证信息
  @Output() modelShow = false;//是否打开弹窗
  @Output() collegeName = '';//高校名称
  @Output() collegeid = '';//高校id(提交认证)
  @Output() audit_time = '';//认证审核时间
  @Output() faculty_authcation = false;//院系审核开关 //true 要选择院系，false 不选择院系
  @Output() CollegeList = [];//申请院系列表
  public college_is_activity = false;//是否是活动账号，活动账号不受院系申请限制


  /**
   * 0未申请
   * 1有申请，未通过
   * 2全部通过
   * 3已拒绝
   */
  public histortyCollegeState = 0;
  public apply_time = '';// 企业申请高校校招信息
  public isShowDownLoadAPP = false;
  public isShowWeiShenQing = false;//显示未申请的弹窗
  public isShowWeiTongGuo = false;//显示未申请的弹窗

  public ShenHeAPPTest = "";
  public DownLoadAPPTitle = '';
  public timer: any = null;
  constructor(public dataApi: DataApi,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    public local: Local,
    private router: Router,
    public opera: Opera,
    public toastr: ToastrService) {

    this.config = Config.getInstance();//实例化config
    this.getDepartment();//获取企业信息

    this.getDate();//获取当前实时时间
    this.getPositionList();//获取职位列表数据


  }
  ngOnInit() {
    this.getUser();//获取hr信息
    setTimeout(() => {
      this.config.isLogin = false;
    });
    this.opera.initConfig('home', '');

    let dateStr = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    // this.DownLoadAPPTitle = "您于"+dateStr+"申请的高校合作资质审核中，如有问题请联系高校就业老师，建议下载APP实时掌握审核进度";

    //判断本地存储高校信息
    // console.log('获取高校id---home');
    // console.log(this.local.get(Constants.byCollege));
    if (this.local.get(Constants.byCollege)) {
      this.getCollege(this.local.get(Constants.byCollege));//获取高校信息
      this.checkDepartmentIndentityByCollege();
    }
    this.getJobProgress();//获取招聘进度
    this.checkChatList();//待办事项
    this.getCollegePart();//合作院校
    this.getCollegeList();//获取高校列表
    this.getIsFirstJoin();//获取已经申请的高校列表

    if (this.local.get('collegeid')) {
      let collegeid = this.local.get('collegeid');
      this.dataApi.getCollegePart(collegeid).then((res: any) => {
        console.log("准备申请的院系列表", res);
        if (res) {
          this.CollegeList = res;
        }
      }).catch((err) => {
        console.log("准备申请的院系列表err", err);
      })
    }

  }

  //获取高校信息
  getCollege(id) {
    this.opera.loading();
    this.dataApi.getCollageDetails(id).then((res: any) => {
      console.log("homePage获取到高校信息---------", res);
      console.log(res);
      this.opera.loadiss();
      if (!res) { return };
      this.college_is_activity = res.is_activity;//是否活动账号

      let dateStr = this.datePipe.transform(new Date(), "yyyy-MM-dd");
      if (res.work_info != null) {
        this.SchoolPhone = res.work_info.office_tel;
      }
      setTimeout(() => {
        this.config.isByCollege = true;
      });
      this.local.set(Constants.byCollegeDetails, JSON.stringify(res));
      this.config.byCollegeLogo = res.logo;
      this.config.byCollegeName = res.name;
      this.collegeName = res.name;
      this.collegeid = res.collegeid;
      this.faculty_authcation = res.audit_switch.faculty_authcation;
      //faculty_authcation
      // this.toastr.info(this.config.byCollegeName);

    }).catch((err) => {
      this.toastr.error(err);
      this.opera.loadiss();
    });
  }

  //获取申请校招返回值
  Modeldismiss(event) {
    ////console.log('子组件返回结果-----college-details');
    ////console.log(event);
    this.opera.scrollTrue();
    if (event) {
      //跳转认证页面
      // this.local.set(Constants.collegeid,this.local.get(Constants.byCollege));
      this.router.navigateByUrl("/home/company-identity-details");
      this.modelShow = false;
    } else {
      this.modelShow = false;
    }

  }

  //提交校招申请
  goCollegeIdentity() {
    let collegeid = this.local.get('collegeid') + '';
    if (collegeid + '' == 'false') {
      this.toastr.error("请重新从高校站内登录");
      return;
    }
    this.collegeid = this.local.get(Constants.byCollege);
    this.modelShow = true;//打开校招申请确认弹窗
    this.opera.scrollTop();//页面滚动函数
  }

  //判断企业是否通过高校认证
  checkDepartmentIndentityByCollege() {
    this.dataApi.lastCollegeAuthenticationIdentity(this.local.get(Constants.byCollege)).then((res: any) => {
      console.log("获取企业在高校的认证信息-----------------------CampusDetails");
      console.log(res);
      if (res) {
        this.audit_time = res.audit_time;
        this.collegeIdentity = res;
      }

    }).catch((err) => {
      ////console.log(err);
    })
  }

  //获取高校列表--offset=0
  getCollegeList() {
    this.opera.loading();
    this.dataApi.getCollegeList({ city: '', school_type: '', school_nature: '', labels: '', offset: 0, limit: 9 }).then((res: any) => {
      console.log("获取高校列表-----", res);
      //console.log(res);
      this.opera.loadiss();

      if (res) {
        this.countCollege = res.count;
      }
    }).catch((err) => {
      //console.log(err);
      this.opera.loadiss();
    })
  }

  //合作院校
  getCollegePart() {
    this.opera.loading();
    this.dataApi.collegePart().then((res: any) => {
      console.log("合作院校列表", res);
      //console.log(res);
      if (res) {
        this.collegePart = res;
      }
      this.opera.loadiss();

    }).catch((err) => {
      //console.log(err);
      this.opera.loadiss();
    })
  }

  //跳转页面
  goChild(data) {

    // if(data=='college-details-student'||data=='college-details-campus')
    // {
    //   let collegeid=this.local.get('collegeid')+''
    //   console.log("请重新从高校站内登录",collegeid);
    //   if(collegeid+''=='false')
    //   {
    //     this.toastr.error(this.dataApi.getNoDepardmentID());
    //     return;
    //   }
    //
    //   /**
    //    * 0未申请  弹窗下载APP
    //    * 1待审核  跳转到申请高校合作
    //    * 2全部通过  跳转到详情
    //    * 3已拒绝  弹窗跳转到申请历史
    //    */
    //   console.log("点击了生源速览等。。。",this.histortyCollegeState);
    //   // public  isShowDownLoadAPP = false;
    //   // public  isShowWeiShenQing = false;//显示未申请的弹窗
    //   // public  isShowWeiTongGuo = false;//显示未通过的弹窗
    //   //有已经拒绝的
    //   if(this.histortyCollegeState==3)
    //   {
    //     this.isShowWeiTongGuo=true;
    //     return;
    //   }
    //   else if(this.histortyCollegeState==0)
    //   {
    //     //未申请
    //     this.isShowWeiShenQing=true;
    //     return;
    //   }
    //   else if(this.histortyCollegeState==1)
    //   {
    //     this.isShowWeiTongGuo=true;
    //
    //     //待审核
    //     this.isShowDownLoadAPP=true;
    //     // let dateStr= this.datePipe.transform(this.apply_time,"yyyy-MM-dd");
    //     // this.DownLoadAPPTitle = "您于"+dateStr+"申请的高校合作资质审核中，如有问题请联系高校就业老师"+this.SchoolPhone+"，建议下载APP实时掌握审核进度";
    //     return;
    //   }
    // }

    console.log("申请高校合作", data);
    this.router.navigateByUrl('/home/' + data);
  }

  //跳转会话页面
  goChat(val) {

    this.DownLoadAPPTitle = '下载APP,学生关注职位实时提醒';
    this.isShowDownLoadAPP = true;
    //console.log("检查会话信息");
    //console.log(val);
    // this.local.remove(Constants.chatPosition);//移除职位参数
    // this.local.set(Constants.chatsInfo,JSON.stringify(val));//聊天记录本地存储参数
    // this.router.navigate(['home/chat-contact']);
    // this.router.navigate(['home/chat-contact'], {
    //   queryParams: {
    //     is_read:'false',
    //   }
    // });
  }

  //发布职位
  goPublish() {
    this.local.remove(Constants.positionid);
    this.router.navigate(['home/position-publish']);
  }

  //判断当前是否存在会话列表
  checkChatList() {
    this.config.chatsList = this.dataApi.getLocalChatsList();//赋值会话列表本地存储
    let n = 0;
    if (!this.config.chatsList) {
      this.dataApi.getImDialogueAllList({}).then((res: any) => {
        console.log("获取会话列表--------opera");
        console.log(res);
        if (res) {
          this.dataApi.setLocalChatsList(res);//会话列表本地存储
          for (let val of res.list) {
            if (val.unread_count && val.talent) {
              // console.log(this.config.unReadNum);
              if (n > 9) { return; }
              this.backlogList.push(val);
              n++;
            }
          }
        }

        // console.log('待办事项-------');
        // console.log(this.backlogList);
      }).catch((error) => {
        //console.log(error);
        this.toastr.error(error);
      });
    } else {
      console.log('this.config.chatsList.list', this.config.chatsList.list);
      for (let val of this.config.chatsList.list) {
        if (val.unread_count && val.talent) {
          if (n > 9) { return; }
          this.backlogList.push(val);
          n++;
        }
      }
      // console.log('待办事项-------');
      // console.log(this.backlogList);
    }
    setTimeout(() => {
      this.config.unReadNum = n;
    });
  }

  //获取招聘进度
  getJobProgress() {
    this.opera.loading();
    let collegeid = this.local.get('collegeid');
    this.dataApi.jobProgress({ collegeid: collegeid }).then((res: any) => {
      //console.log("获取招聘进度数据-------");
      this.opera.loadiss();
      if (res) {
        this.jobProgress = res;
      }
    }).catch((err) => {
      //console.log(err);
      this.toastr.error(err);
      this.opera.loadiss();
    })
  }

  //获取当前时间并格式化
  getDate() {
    let date = new Date();
    let year = date.getFullYear(); //获取当前年份
    let month = date.getMonth() + 1; //获取当前月份
    let da = date.getDate(); // 获取当前日
    let week = date.getDay(); // 获取当前星期几
    let h = date.getHours(); // 获取小时
    let mon = ''; // 当前月份
    let day = ''; // 当前日
    if (month >= 1 && month <= 9) {
      mon = "0" + month;
    } else {
      mon = String(month);
    }
    if (da >= 1 && da <= 9) {
      day = "0" + da;
    } else {
      day = String(da);
    }
    this.nowDate = year + '-' + mon + '-' + day;
    this.nowWeek = this.weekday[week];
    if (h >= 5 && h < 12) {
      this.nowNoon = "上午";
    } else if (h >= 12 && h <= 14) {
      this.nowNoon = "中午";
    } else if (h >= 14 && h <= 19) {
      this.nowNoon = "下午";
    } else {
      this.nowNoon = "晚上";
    }
  }

  //获取企业信息
  getDepartment() {
    //修正为每次都去请求api获取企业最新冻结情况
    this.dataApi.getDepartment().then((res: any) => {//通过tooken获取企业信息
      console.log("企业信息线上数据-----homepage", res);
      //console.log(res);
      if (res) {
        this.myDepartment = res;
        if (this.myDepartment.contact_info_is_set == false) {
          this.isShowPerfetInfo = true
        }
        this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
        if (this.myDepartment.is_frozen == true) {
          this.config.is_Frozen = true
          this.isFrozenFanc()
        } else {
          this.config.is_Frozen = false
        }
        localStorage.setItem("app_login", this.myDepartment.app_login ? 'true' : 'false');
         //未绑定微信
         if (res.wechat_info == null) {
          res.wechat_info = { gwlopenid: '' }
        }
        if (!res.wechat_info.gwlopenid && (this.config.GetWXCode == "http://wx.goworkla.cn/mp/makeercode?sceneStr=" 
        || this.config.GetWXCode == "http://wx.test.goworkla.cn/mp/makeercode?sceneStr=")) {

          if (this.local.get('accountid') || this.local.get('collegeid')) {
            // let url = this.config.GetWXCode + this.local.get('accountid') + "&" + this.local.get('collegeid')
            let data = { departmentid: this.local.get('accountid'), collegeid: this.local.get('collegeid') };
            this.dataApi.getWeiChatCode(data).then((res: any) => {
              this.codeURL = res.value;
              //弹关注公众号的二维码/并验证用户是否
              this.shouWeiChatCode = true;
              this.virtyUerBingWeiChat();
            }).catch((err) => {
              console.log("获取认证材料失败", err);
            })
          }
        } else {
          // 已关注公总号，获取未完善职位
          this.getPositionListAlert();
        }
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }
  //判断冻结
  isFrozenFanc() {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '账号被冻结',
        message: `
					冻结提示：由于您的账号涉及违规，被暂时冻结。您现在仅可使用职位管理、招聘简章、公司信息、账号管理功能。请修改相关信息后发送邮件到service@gowork.la申请解冻，主题备注“申请解冻+注册手机号，工作人员1个工作日内审核给出结果。
				`,
        trueText: '确定',
        falseText: '取消'
      }
      //回调函数
      // }).afterClosed().subscribe((isConfirmed) => {
      //         if(isConfirmed){
      //           console.log('isConfirmed——true')
      //         }else{
      // 	console.log('isConfirmed——false')
      // }
    });
    // .afterClosed().subscribe((isConfirmed) => {
    //          //console.log('检测是否存在返回值');
    //          //console.log(isConfirmed);
    //          this.opera.scrollTrue();
    //          if(isConfirmed){
    //            this.toastr.info("请重新登录");
    //            this.setRoot();//不重新登录无法接收消息，踢到登录页
    //          }
    //    });
  }

  //获取职位列表数据
  getPositionList() {
    //console.log("在期职位本地存储-----homepage");
    //console.log(this.dataApi.getLocalpositionList());
    if (this.dataApi.getLocalpositionList()) {
      this.positionList = this.dataApi.getLocalpositionList();
      this.listCount = this.positionList.length;
    } else {
      this.getNewList();
    }
  }

  //-------获取线上数据
  getNewList() {
    this.dataApi.getPositionList(this.model).then((res: any) => {
      //console.log("在期职位获取线上数据-----homepage");
      //console.log(res);
      if (res) {
        if (res.list.length < 11) {
          this.positionList = res.list;
          this.listCount = res.count;
        } else {
          this.positionList = res.list.slice(0, 10);
          this.listCount = 10;
        }
        this.dataApi.setLocalpositionList(res.list);
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  //获取当前登录用户的信息(user信息)
  getUser() {
    this.dataApi.getUser().then((res: any) => {//通过tooken获取企业信息
      if (res) {
        this.myUser = res;
        this.loginUser = res;
        console.log("当前用户信息-----homepage", res);
        //console.log(res);
        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
       
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  /**
   * 获取申请的高校合作列表
   */
  getIsFirstJoin() {
    let collegeid = this.local.get("collegeid")

    this.dataApi.lastCollegeAuthenticationIdentity(collegeid).then((res: any) => {
      console.log("获取企业平台认证信息Page", res, collegeid);
      //  console.log(res);
      if (res) {
        //status (integer, optional): 企业认证审核状态 1：待审核 2：通过审核 3：未通过审核 ,
        this.apply_time = res.apply_time;
        if (res.status == 1) {
          this.histortyCollegeState = 1;//待审核的
        } else if (res.status == 2) {
          this.histortyCollegeState = 2;//全部通过
        } else if (res.status == 3) {
          this.histortyCollegeState = 3;//已拒绝
        } else if (res.status == 0) {
          this.histortyCollegeState = 0;//未申请
        }
        console.log("histortyCollegeState", this.histortyCollegeState)
      }
    }).catch((error) => {
      console.log("获取企业平台认证信息出错");
      console.log(error);
    })
  }
  cloceDownLoad() {
    this.isShowDownLoadAPP = false;
    this.isShowWeiTongGuo = false;
    this.isShowWeiShenQing = false;
    this.ShowWeiWanShanZhiwei = false;
  }

  /**
   * 跳转申请高校合作
   */
  gotoShenQing() {
    this.isShowDownLoadAPP = false;
    this.isShowWeiTongGuo = false;
    this.isShowWeiShenQing = false;
    console.log("跳转申请高校合作");
    // this.router.navigateByUrl('/identity');
    this.collegeid = this.local.get(Constants.byCollege)
    this.modelShow = true;//打开校招申请确认弹窗
    this.opera.scrollTop();//页面滚动函数
  }

  /**
   *跳转申请的高校合作记录
   */
  gotoGaoXiaoHistory() {
    this.isShowDownLoadAPP = false;
    this.isShowWeiTongGuo = false;
    this.isShowWeiShenQing = false;
    this.router.navigateByUrl('/home/company-identity');
  }

  virtyUerBingWeiChat() {
    if (this.codeURL == '') {
      this.toastr.error("获取微信公众号失败");
      return;
    }

    var that = this;
    let accountid = this.local.get('accountid');
    //开启循环：每秒出现一次提示框
    this.timer = setInterval(function () {
      console.log('检测企业是否绑定微信')
      //检测企业是否绑定微信
      that.dataApi.getComopanyIsBingWeiChat(accountid).then((res: any) => {
        that.shouWeiChatCode = false;
        that.getPositionListAlert();//获取未完善职位
        clearInterval(this.timer);
        return;
      }).catch((error) => {
        that.shouWeiChatCode = true;
        console.log('企业未绑定微信', error)
        // clearInterval(timer);
      });
    }.bind(this), 2000);
  }
  //获取职位列表
  getPositionListAlert() {
    this.dataApi.getPositionList(this.ListModel1).then((res: any) => {
      let str = '';
      if (res) {
        if (res.list.length > 0) {
          for (let index = 0; index < res.list.length; index++) {
            if (index == 5) {
              break;
            }
            let fenGe = index == 0 ? "" : '，';
            let item = res.list[index];
            if (item.position_status != 'delete') {
              str += fenGe + item.name;
            }
          }
          if (res.list.length > 5) {
            str = str + '，等' + (res.list.length - 5) + "位职位"
          }
          this.WanShanZhiweiStr = str;
          console.log("获取职位列表-----str", str);
          let loginUser = this.local.get('loginUser');
          let user = JSON.parse(loginUser);
          if (str) {
            if (user.login_email) {
              this.ShowWeiWanShanZhiwei = true;
            }
          }
        } else if (this.loginUser.login_email == null || this.loginUser.login_email == ''){
          this.showBingLoginEmailModel = true;
        }
      }
    }).catch((error) => {
      this.toastr.error(error);
    });
  }
  /**
   * 去职位
   */
  gotoZhiWei() {
    this.ShowWeiWanShanZhiwei = false;
    this.router.navigateByUrl('/home/position-manage');
  }

  getYuanXiList() {
  }
  goToNewWeb() {
    if (this.local.get('accesstoken')) {
      let token = this.local.get('accesstoken');
      let accountid = this.local.get('accountid');
      let collegeid = this.local.get('collegeid');
      window.open(this.config.JumpMainWeb + token + '&accountid=' + accountid + '&route=' + '', '_blank')
    }
  }
  gotoPerfect() {
    this.dataApi.departmentSwitchContactinfo({ value: true }).then((res: any) => {
    }).catch((err) => { })
    this.router.navigateByUrl('/home/company-account');
    this.isShowPerfetInfo = false
  }

  dialogHiden() {
    clearInterval(this.timer);
    this.shouWeiChatCode = false;
    // this.getPositionListAlert();//获取未完善职位
  }

  getBingEmailCode() {
    if (!this.bindEmailModel.email) {
      this.toastr.warning('请填写邮箱');
      return;
    }
    if (this.sendEmailText2 != '获取邮箱验证码') {
      this.toastr.error('60秒内不能重复发送');
      return;
    }
    let phoneModel = {
      format_type: 2,//0:手机号，1：手机号&固话；2.邮箱
      data: this.bindEmailModel.email
    }
    this.dataApi.phoneVerification(phoneModel).then((res: any) => {
      if (res.value) {

        let data = {
          email: this.bindEmailModel.email,
        };
        this.dataApi.sendEmail(data).then((res: any) => {
          console.log("发送邮箱", res, data);
          this.toastr.success('获取邮箱验证码成功')
          let count = 60;
          this.sendEmailText2 = count + 's';
          this.timer1 = setInterval(() => {
            count -= 1;
            this.sendEmailText2 = count + 's';
            if (count === 0) {
              clearInterval(this.timer1);
              this.sendEmailText2 = '获取邮箱验证码';
            }
          }, 1000);

        }).catch((err) => {
          console.log("发送邮箱失败", err);
          this.toastr.error(err); return;
        })
      }
      else {
        this.toastr.error('邮箱格式错误');
      }
    }).catch((err) => {
      //console.log(err);
      this.toastr.error('工作邮箱格式错误');
      return;
    })


  }

  commitBindLoginEmail() {
    if (!this.bindEmailModel.email) {
      this.toastr.error('请输入邮箱'); return;
    }
    if (!this.bindEmailModel.code) {
      this.toastr.error('请输入邮箱验证码'); return;
    }
    if (!this.bindEmailModel.psw) {
      this.toastr.error('请输入登录邮箱密码'); return;
    }
    if (!this.bindEmailModel.psw2) {
      this.toastr.error('请输入确认密码'); return;
    }
    if (this.bindEmailModel.psw != this.bindEmailModel.psw2) {
      this.toastr.error('密码不一致'); return;
    }
    this.dataApi.checkPassword(this.bindEmailModel.psw).then((res: any) => {
      let pswData = {
        email: this.bindEmailModel.email,
        vcode_text: this.bindEmailModel.code,
        password: this.bindEmailModel.psw
      }
      this.dataApi.userBindEmailSetPsw(pswData).then((res: any) => {
        this.toastr.success('绑定成功');
        this.showBingLoginEmailModel = false;
        console.log('res', res);
        if (this.loginUser) {
          this.loginUser.login_email = this.bindEmailModel.email;
          this.local.set('loginUser', JSON.stringify(this.loginUser));
        }

      }).catch((err) => {
        console.log("修改失败", err);
        this.toastr.error(err); return;
      })
      //userBindEmailSetPsw
    }).catch((err) => {
      console.log("修改失败", err);
      this.toastr.error("密码格式错误，请重新填写"); return;
    })
  }

}
