import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { DataApi,Config,Local,Constants,Opera} from '../../provider/index';
@Component({
  selector: 'app-company-status-two',
  templateUrl: './company-status-two.component.html',
  styleUrls: ['./company-status-two.component.css']
})
export class CompanyStatusTwoComponent implements OnInit {
  public config: Config;
  public power:boolean=true;
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.opera.initConfig('company','company-authentication');
    this.getDepartment();//获取企业信息
    this.getUser();//获取hr信息
  }
  // 选项卡
powerTab(){
  this.power=true;
}
searchTab(){
  this.power=false;
}
  //获取账户信息
  getUser(){
    if(this.dataApi.getLocalLoginUser()){
      console.log("企业hr信息本地存储");
      console.log(this.dataApi.getLocalLoginUser());
      this.config.loginUser=this.dataApi.getLocalLoginUser();
    }else{
      this.dataApi.getUser().then((res:any)=>{
        console.log("线上数据hr信息");
        console.log(res);
        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量 
      }).catch((error)=>{
        this.toastr.error(error);
      });
    } 
  }

  //获取企业信息
  getDepartment(){
    if(this.dataApi.getLocalDepartment()){
      console.log("企业信息本地存储");
      console.log(this.dataApi.getLocalDepartment());
      this.config.department=this.dataApi.getLocalDepartment();
    }else{
      this.opera.loading();
      this.dataApi.getDepartment().then((res:any)=>{
        this.opera.loadiss();
        console.log("企业信息获取线上数据");    
        console.log(res);
        this.dataApi.setLocalDepartment(res);
      }).catch((error) => {
        this.opera.loadiss();
       console.log(error);
       this.toastr.error(error);
      });
    }    
  }
  // 跳转至校园+页面
  goSchool(){
    this.router.navigate(['home/college-campustalk']);
  }
  // 跳转至在线招聘页面
  goCollegeResume(){
    this.router.navigate(['home/college-resume']);
  }
  // 跳转至职位管理页面
  goPositionManage(){
    this.router.navigate(['home/position-manage']);
  }
   // 跳转至在线直聊
   goChatContact(){
    this.router.navigate(['home/chat-contact']);
  }
  // 跳转支付页面
goSearch(){
  this.router.navigate(["home/company-pay"],{
    queryParams:{
      searchMoney:298
    }
  });
}
}
