import { Component,EventEmitter, Input, Output,ViewChild,ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataApi,Constants,Config,Local} from '../../provider/index';

@Component({
  selector: 'app-model-company-strength',
  templateUrl: './model-company-strength.component.html',
  styleUrls: ['./model-company-strength.component.css']
})
export class ModelCompanyStrengthComponent{
  public config: Config;
  @Input() isShow: false;//是否显示弹窗页面
  public labelAll = [];//标签--已选
  public index = 0;
  @Input() public isLogin = true;
  public userLabel:any;
  constructor(public toastr: ToastrService,
    public local:Local,
    public dataApi: DataApi) {
      this.config = Config.getInstance();
      this.getLabelCode();//获取企业标签字典
    }

    @Output() onVoted = new EventEmitter();
    dismiss(result: any) {
      //console.log("传值检查");
      //console.log(result);
      this.onVoted.emit(result);
    }

    //初始化函数
    initData(data){
      console.log("获取初始数据");
      console.log(data);
      this.labelAll=data;//选中标签列表赋值
      for(let val of this.config.strength){
         val.isActive=false;
         if(this.labelAll.length>0){
          this.index=this.labelAll.length;
          for(let n of this.labelAll){
            if(val.code==n.code){
              val.isActive=true;
            }
          }
         }
      }
    }
  hidden(){
    this.isShow = false
  }

    //获取企业标签本地存储
    getLabelCode(){
      if(this.config.strength.length==0){
        //console.log("企业推荐标签获取线上数据");
        // let loader = this.tip.presentLoading('数据加载中...');
        this.dataApi.getDictDate('employer_strength').then((res:any)=>{
          // console.log("公司实力标签");
          // console.log(res);
          this.config.strength=res;
          // loader.dismiss();
        }).catch((error)=>{
          //console.log(error);
          // loader.dismiss();
        });

      }
    }


  //删除企业标签
  delectSkill(label){
    for(let i of this.config.strength){
      if(i.text==label.text){
        i.isActive = false;
      }
    }
    for(let val of this.labelAll){
      if(label.code==val.code){
        let n=this.labelAll.indexOf(val);
        this.labelAll.splice(n,1);//----删除元素
        this.index--;
        return;
      }
    }
  }

  //选中标签------字典
  chooseConfigLabel(label){
    if(this.index>8){
      this.toastr.warning('标签数量大于9');
      return;
  }
    label.isActive=true;
    for(let item of this.labelAll){
      if(label.code==item.code){
        this.toastr.warning("此标签已选中");
        return;
      }
    }
    let model={
      text:'',
      code:''
    }
    model.text=label.text;
    model.code=label.code;
    this.labelAll.push(model);
    this.index++;
  }

  //保存标签
  getLabel(){

      console.log("isLogin'",this.isLogin)
      if(this.labelAll.length > 9){
        this.toastr.warning('标签数量大于9');
        return;
      }else{
        if(!this.isLogin)
        {
          this.dismiss(this.labelAll);
          return;
        }
        // let loader = this.tip.presentLoading('');
        this.dataApi.uploadDepartmentStrength(this.labelAll).then((res:any)=>{
          //console.log(res);
          this.dismiss(this.labelAll);
          // this.labelAll = data;
          // loader.dismiss();
          // this.viewCtrl.dismiss(this.labelAll);
        }).catch((error) => {
          //console.log(error);
          this.toastr.error(error);
          // loader.dismiss();
        });

      }
  }
}
