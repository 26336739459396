import { Component,Input,EventEmitter,Output } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute,Router } from  '@angular/router';
import { ModelStillMoreComponent } from '../model-still-more/model-still-more.component';
import { ConfirmComponent } from '../confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import { ModelAddressAddComponent } from '../model-address-add/model-address-add.component';
declare var laydate;

@Component({
  selector: 'app-model-position-notice',
  templateUrl: './model-position-notice.component.html',
  styleUrls: ['./model-position-notice.component.css']
})
export class ModelPositionNoticeComponent{
  @Input()  isShow: false;//是否显示弹窗页面
  public model={
    talent_name: "",//人才名字
    talentid: "",//人才ID
    positionid: "",//面试职位ID
    post_type_text:"",//面试职位text
    department_name: "",//企业简称
    departmentid:"",//企业ID
    feedback_status:'',
    mrid:'',// 招聘会id 用于线上招聘会中发出面试申请时
    phone: "",//电话
    stage: "first",//面试阶段 first:初试 reexamine：复试
    time: "",//面试时间
    location:{
      address: "",
      coordinates:[],
      lid: "",
      ltype: 0,
      region: "",
      region_text:"",
      is_default:false
    }
  }
  public config: Config;
  public myDepartment:any;//获取企业信息

  public interviewid='';//面试邀请ID
  public isSent = true;//是否是发送面试的情况(默认为true)
  public isCancel = false;//是否可以取消当前的面试
  public isShowTop = true;//是否显示页头操作按钮

  public validDate = new Date();//可取消的截止时间
  public isCountdown = false;//判断是否要显示面试倒计时
  public countdownNum = 0;//计算的毫秒
  public completeDate = "";//日期
  public hourAndMinute = "14:00:00";//小时

  public feedbackStatus = '面试邀请';//当前头部提示文字
  public noticeInfo:any;//上次面试通知信息
  //人才极光ID
  public userJmUsername:any;
  public isGoSend=false;//是否发送面试邀请
  public isSend = false;//是否点击了发送按钮(必填提示)
  public workPlace='';//地址id
  public isChat=false;//是否是聊天页面弹出
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public opera:Opera,
    public dialog: MatDialog,
    public formatDate: FormatDate,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
      this.getPositionList();//获取职位列表信息
    }

    @Output() onVoted = new EventEmitter<boolean>();
    dismiss(result: any) {
      //console.log("传值检查");
      //console.log(result);
      this.onVoted.emit(result);
    }

    //初始化面试邀请页面
    initData(id,isChat,talentid){
      this.isChat=isChat;
      this.isSend = false;
      this.model.talentid = talentid;
      //重新赋值时间
      this.completeDate = this.formatDate.formatDate(this.formatDate.addDays(new Date(),1));
      this.hourAndMinute = "14:00:00";
      if(id){
        this.isSent = false;//是否可以进行邀请(false代表现在已经不能邀请了)
         this.interviewid = id;//赋值面试邀请id
         this.getInterview(this.interviewid);
         this.getDepartment();//获取企业信息(不填充企业地址)
      }else{//第一次发起面试邀请，填充数据
        this.setInterviewData();
      }
      //日历插件初始化
      let that = this;
      laydate.render({
        elem: '#noticeDate',
        // type: 'year',
        done: function(value, date){
        that.completeDate = value;
        }
      })
      laydate.render({
        elem: '#noticeHour',
        type: 'time',
        done: function(value, date){
        that.hourAndMinute = value;
        }
      })

    }

    // 撤回
    goInterviewHandle(){
      this.opera.loading();
      this.dataApi.UpdateInterview(this.noticeInfo.interviewid,'accept').then((res:any)=>{
        this.noticeInfo.feedback_status='handle_interview';
        this.model.feedback_status = 'handle_interview';
        this.feedbackStatus = '待处理';
        this.toastr.success("撤回成功");
        let interviewData={
          interviewid:this.interviewid,
          feedback_status:this.noticeInfo.feedback_status,
          interview:this.model,
          contentText:"待处理"
        }
        this.dismiss(interviewData);
        this.opera.loadiss();
      }).catch((err)=>{
          this.opera.loadiss();
          this.toastr.error("撤回失败，请稍后重试");
          this.dismiss(false);
      })
    }

    //发送签约通知
    gohire(){
      this.opera.loading();
      //跳转到签约通知发送页面
      this.dataApi.UpdateInterview(this.interviewid,'hired').then((res:any)=>{
        this.toastr.success("面试状态修改成功");
        this.dismiss("hired");
        this.opera.loadiss();
      }).catch((err)=>{
          this.toastr.error("面试状态修改失败，请稍后重试");
          this.opera.loadiss();
      })

    }

    //操作面试邀请
    doNotice(){
      this.opera.scrollTop();//页面滚动函数
      this.dialog.open(ConfirmComponent, {
        data: {
          title: '面试处理',
          message: '请选择面试处理结果？',
          trueText:'录用',
          falseText:'不合适'
        }
      }).afterClosed().subscribe((isConfirmed) => {
          // //console.log('confirm返回值-------');
          // //console.log(isConfirmed);
          this.opera.scrollTrue();
          if(isConfirmed){
            //跳转到签约通知发送页面
            // this.dismiss("hired");
            this.gohire();
          }else if(isConfirmed===false){
            this.opera.loading();
            this.dataApi.UpdateInterview(this.interviewid,'improper').then((res:any)=>{
              this.noticeInfo.feedback_status='improper';
              this.model.feedback_status = 'improper';
              this.feedbackStatus = '不合适';
              this.toastr.success("面试状态修改成功");
              this.isShowTop=false;
              let interviewData={
                  // interviewid:this.interviewid,
                  // feedback_status:this.noticeInfo.feedback_status
                  interviewid:this.noticeInfo.interviewid,//面试通知的id
                  interview:this.model,
                  feedback_status:'improper',//修改上个页面的面试状态
                  contentText:"不合适"
              }
              if(this.isChat){
                this.dismiss(interviewData);
              }else{
                this.postMsgServe(interviewData);
              }
              this.opera.loadiss();
            }).catch((err)=>{
                this.toastr.error("面试状态修改失败，请稍后重试");
                this.opera.loadiss();
            })
          }
      });
    }

    //对方爽约
    missNotice(){
      this.opera.loading();
      this.dataApi.UpdateInterview(this.interviewid,'miss').then((res:any)=>{
        this.noticeInfo.feedback_status='miss';
        this.model.feedback_status = 'miss';
        this.feedbackStatus = '对方爽约';
        this.toastr.success("面试状态修改成功");
        let interviewData={
          interviewid:this.interviewid,
          interview:this.model,
          feedback_status:this.noticeInfo.feedback_status,
          contentText:"对方爽约"
        }
        this.isShowTop=false;
        this.dismiss(interviewData);
        this.opera.loadiss();
      }).catch((err)=>{
        this.toastr.error("标记对方爽约失败，请稍后重试");
        this.opera.loadiss();
      })
    }

    //发送面试邀请
    sendNotice(){
      this.isSend=true;
      this.model.time = this.completeDate+' '+this.hourAndMinute;
      let nowDate = new Date();
      let endDate = new Date(this.model.time);
      let nowTime = nowDate.getTime();
      let endTime = endDate.getTime();
      if(endTime<nowTime){
        this.toastr.error("面试时间不得小于当前时间");
        this.model.time = '';
        this.completeDate ='';
        this.hourAndMinute='';
        return;
      }
      if(this.workPlace){
        for(let val of this.myDepartment.locations){
          if(this.workPlace == val.lid){
            this.model.location=val;
          }
        }
      }
      if(!this.model.positionid||!this.model.location||!this.model.phone){
        this.toastr.warning("请完善面试邀请信息");
        return;
      }
      this.opera.loading();
      this.dataApi.sendNotice(this.model).then((res:any)=>{
        this.interviewid = res.value;
        this.model.feedback_status = "to_be_accepted";
        let modelData = {
          interviewid:res.value,//面试通知的id
          interview:this.model,
          feedback_status:"to_be_accepted",//修改上个页面的面试状态
          contentText:"邀请面试"
        }
        setTimeout(() => {
          //console.log("发送面试数据");
          //console.log(modelData);
          if(this.isChat){
            this.dismiss(modelData);
          }else{
            this.postMsgServe(modelData);
          }
          this.toastr.success('发送成功');
        },600);
        this.opera.loadiss();
      }).catch((error) => {
        this.opera.loadiss();
        setTimeout(() => {
          //console.log(error);
          this.toastr.error(error);
        },600);
      });
    }

    //取消面试
    cancelNotice(){
      this.opera.loading();
      this.dataApi.cancelNotice(this.noticeInfo.interviewid).then((res:any)=>{
        //console.log(res);
        // loader.dismiss();
        setTimeout(() => {
          this.isCancel = false;
          this.noticeInfo.feedback_status = 'department_canceled';//修改上个页面的面试状态
          this.model.feedback_status = 'department_canceled';//修改上个页面的面试状态
          this.feedbackStatus = '已取消';//修改本页面头部的状态
          this.toastr.success('操作成功');
          let model ={
            interviewid:this.noticeInfo.interviewid,//面试通知的id
            interview:this.model,
            feedback_status:'department_canceled',//修改上个页面的面试状态
            contentText:"取消面试"
          }
          //console.log("取消面试数据");
          //console.log(model);
          if(this.isChat){
            this.dismiss(model);
          }else{
            this.postMsgServe(model);
          }
        },600);
        this.opera.loadiss();
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
        setTimeout(() => {
          this.toastr.error(error);
        },600);
      });
    }

      //将消息提交到服务器和极光
      postMsgServe(_data){
        //-------发消息时更新本地存储
        this.setContactMsg(_data);
        this.jgPostChatMsg(_data);//消息发送到极光,放在这里(和发送到服务器异步执行)
        //------发送新消息时更新本地会话列表存储
        this.getNewChatList();
          //console.log("发送到服务器");
          //console.log(_data);
          //发送到服务器
          let _content:any;
          //转换JSON数据类型为字符串类型
            _content = JSON.stringify({id:_data.interviewid,
              status:_data.feedback_status,
              time:this.model.time,
              location:this.model.location.region_text+','+this.model.location.address});
          return this.dataApi.sendChat({
            jmusername:this.userJmUsername,//对方的极光ID
            message_type:"invitation",//消息类型
            content:_content//消息内容
          }).then((res:any)=>{
            //console.log(res);
            this.newListContact(this.userJmUsername);//发送消息成功之后--更新会话列表顺序
            this.dismiss(_data);
          }).catch((error)=>{
            //console.log(error);
          });
      }

      //将消息发送到极光
      jgPostChatMsg(_model){
        let newMenu;
        let Menu ={
            id:_model.interviewid,
            status:_model.feedback_status,
            time:this.model.time,
            location:this.model.location.region_text+','+this.model.location.address
          }
        newMenu = JSON.stringify(Menu);
        //发送到极光只需要  对方的极光ID + 消息内容(标题) + 消息内容(对象内容 extras )
        let newModel = {
          username:this.userJmUsername,
          contentText:_model.contentText,
          extras:{
            type:"invitation",
            content:newMenu
          }
        }
      //console.log("发送到极光");
      //console.log(newModel);
      //   this.myJMessage.sendSingleMsg(newModel);
      }

    //更新本地存储----发送新消息时（暂时）
    setContactMsg(model){
      let isNoneTime = false;
      //console.log("获取参数");
      //console.log(model);
      if(this.local.get(Constants.chatsMsgList)){
        this.config.chatsMsgList = this.dataApi.getLocalChatsMsgList();
      }
      for(let val of this.config.chatsMsgList){
        if(val.userID == this.userJmUsername){
          //console.log("更新本地存储");
          if(val.cont){
              //本地聊天记录超过十条时
              if(val.cont.length>9){
                //console.log("即将删除本地存储数量");
                val.cont.splice(0,1);
                }
            }
          //判断是否要显示时间
          let nowDate = new Date();
          let valDate;
          if(val.cont){
            if(val.cont.length>0){
              if(val.cont[val.cont.length-1].send_time.toString()[10]=="T"){
                valDate = new Date(val.cont[val.cont.length-1].send_time+'+08:00');
              }else{
                valDate = new Date(val.cont[val.cont.length-1].send_time);
              }
              let MIN = (nowDate.getTime()-valDate.getTime())/1000;
              if(MIN<300){
                isNoneTime = false;
              }else{
                isNoneTime = true;
              }
              //console.log(MIN);
            }else{
            isNoneTime = true;
            }
          }

          //本地存储数据
          let text ={
              message_type:"invitation",
              content:{id:model.interviewid,
                status:model.feedback_status,
                time:this.model.time,
                location:this.model.location.region_text+','+this.model.location.address},
              username:this.config.department.jm_username,//判断消息的发送方
              send_time:nowDate,
              isNoneTime:isNoneTime
            };
          val.cont.push(text);
          //console.log(this.config.chatsMsgList);
          this.dataApi.setLocalChatsMsgList(this.config.chatsMsgList);//更新本地存储
        }
      }
    }



  //更新会话列表本地存储
  getNewChatList(){
    this.config.chatsList=this.dataApi.getLocalChatsList();//赋值会话列表本地存储
    // //console.log("会话列表本地存储");
    // //console.log(this.config.chatsList);
    // //console.log(this.noticeInfo.jm_username);
    for(let val of this.config.chatsList.list){
      if(this.noticeInfo.jm_username==val.dialogue_jmusername){
        if(val.talent.interview){
          val.talent.interview.interviewid=this.interviewid;
        }else{
          val.talent.interview={id:this.interviewid,status:"invitation"};
        }
        val.last_message_type="invitation",
        val.last_message={id:this.interviewid,status:"invitation",time:this.model.time,location:this.model.location.region_text+','+this.model.location.address}
      }
    }
    //console.log("会话列表本地存储-----1");
    //console.log(this.config.chatsList);
    this.dataApi.setLocalChatsList(this.config.chatsList);
  }

  //更新会话列表顺序----聊天之后
  newListContact(data){
    let isTopNum=0;//-------置顶的会话数量
    let newList:any;//------接收新消息的会话
    let num=0;//接收新消息的会话的下标
    this.config.chatsList.list.forEach((val)=>{
      if(val.is_top){
        isTopNum+=1;
      }
      if(val.dialogue_jmusername == data){//更新本地列表内容
        val.isGoChat=true;
        num = this.config.chatsList.list.indexOf(val);
        newList=val;
      }
    })
    // //console.log(num);
    // //console.log(newList);
    // //console.log(isTopNum);
    if(num==isTopNum){//----------当前会话在置顶会话后一位
      return;
    }else if(num==0){//----------当前会话在第一位
      return;
    }else if(num<isTopNum){//当前会话为置顶会话消息且不在第一位
      this.config.chatsList.list.splice(num,1);
      // //console.log(this.chatsList.splice(num,1));
      // //console.log(this.chatsList);
      this.config.chatsList.list.unshift(newList);
      this.dataApi.setLocalChatsList(this.config.chatsList);
    }else{
    this.config.chatsList.list.splice(num,1);
    //console.log("接收到新消息处理会话列表-1");
    //  //console.log(this.chatsList);
    this.config.chatsList.list.splice(isTopNum,0,newList);
    this.dataApi.setLocalChatsList(this.config.chatsList);
    //  //console.log("接收到新消息处理会话列表-2");
    //  //console.log(this.chatsList);
    }
  }


    //检测联系电话格式
    checkPhone(){
      if(String(this.model.phone).length==0){
        this.toastr.warning("请填写联系电话");
        return;
     }else{
       let phoneModel={
         format_type:1,//0:手机号，1：手机号&固话；2.邮箱
         data:this.model.phone
       }
       this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
         if(!res.value){
          this.toastr.warning("联系电话格式错误");
          return;
         }
       }).catch((err)=>{
         //console.log(err);
         this.toastr.error(err);
       })
     }
    }

    //重新发送数据填充
    refreshSend(){
      this.isSent = true;
      this.model.stage="reexamine";
      if(this.feedbackStatus=='面试结束'){
        this.model.post_type_text='';
      }
      this.feedbackStatus = '面试邀请';
      //重新赋值时间
      this.completeDate = this.formatDate.formatDate(this.formatDate.addDays(new Date(),1));
      this.hourAndMinute = "14:00:00";
      //console.log("面试时间数据重新填写");
      //console.log(this.completeDate+' '+this.hourAndMinute);
      //清空职位填充
      this.model.post_type_text="";
      this.model.positionid="";
      //判断发送的职位是否在期
      // if(this.config.positionList.length>0){
      //   let isPosition=false;
      //   for(let val of this.config.positionList){
      //       if(val.positionid==this.model.positionid){
      //         isPosition=true;
      //       }
      //   }
      //   if(!isPosition){
      //     this.model.post_type_text="";
      //     this.model.positionid="";
      //     this.toastr.warning("职位已下线，请更换职位");
      //   }
      // }else{
      //   this.model.post_type_text="";
      //   this.model.positionid="";
      //   this.toastr.warning("职位已下线，请更换职位");
      // }
      //判断企业地址是否已被删除
      if(this.myDepartment.locations){
        for(let val of this.myDepartment.locations){
          if(val.is_default){
            this.model.location = val;//公司地址(第一次发送面试邀请采用公司默认地址)
            this.workPlace = val.lid;
            return;
          }
        }
      }else{
        this.toastr.warning("请添加企业地址");
        this.router.navigateByUrl("home/company-details-address");
      }
      if(this.myDepartment.locations){
        let isAddress=false;
        for(let address of this.myDepartment.locations){
          if(this.model.location.lid==address.lid){
            isAddress=true;
          }
        }
        if(!isAddress){
          this.toastr.warning("所选企业地址已被删除，请重新添加");
          this.workPlace="";
        }
      }
      // this.model.time = "";//时间重新填写
    }

    //新面试邀请时自动填充数据
    setInterviewData(){
      this.feedbackStatus = '面试邀请';//当前头部提示文字
      this.noticeInfo=null;
      this.interviewid='';//面试邀请ID
      this.isSent = true;//是否是发送面试的情况(默认为true)
      this.isCancel = false;//是否可以取消当前的面试
      this.isShowTop = true;//是否显示页头操作按钮
      this.model.stage= "first";
      //初始化时间
      this.completeDate = this.formatDate.formatDate(this.formatDate.addDays(new Date(),1));
      //填充企业地址
      this.getDepartmentLocation();
      //联系电话赋值
      //获取hr信息
      if(this.dataApi.getLocalLoginUser()){
        //console.log("获取公司HR联系电话");
        if(this.dataApi.getLocalLoginUser().mobile){
          this.model.phone = this.dataApi.getLocalLoginUser().mobile;//联系电话
        }
      }

    }

  //获取面试信息
  getInterview(id){
    // let loader = this.tip.presentLoading("页面加载中，请稍候...");
    this.opera.loading();
    this.dataApi.getInterviewById(id).then((res:any)=>{
        this.opera.loadiss();
        console.log("获取到面试邀请的详情-------model-position-notice");
        console.log(res);
        this.userJmUsername=res.jm_username;//赋值极光号
        // loader.dismiss();
        //赋值
        this.noticeInfo = res;
        this.model.location = this.noticeInfo.location;
        this.model.department_name = this.noticeInfo.department_name;
        this.model.departmentid = this.noticeInfo.departmentid;
        this.model.talent_name = this.noticeInfo.talent_name;
        this.model.talentid = this.noticeInfo.talentid;
        this.model.phone = this.noticeInfo.phone;
        this.model.time = this.noticeInfo.time;
        this.model.stage = this.noticeInfo.stage;
        this.model.positionid = this.noticeInfo.positionid;
        this.model.post_type_text = this.noticeInfo.post_type_text;
        this.model.feedback_status = this.noticeInfo.feedback_status;
        //面试邀请id赋值
        this.interviewid = this.noticeInfo.interviewid;
        //地址赋值
        this.workPlace = this.noticeInfo.location.lid;
        //赋值时间
        this.hourAndMinute=this.model.time.slice(11,19);
        this.completeDate = this.model.time.slice(0,10);
        //赋值职位类别
        if(!this.noticeInfo.post_type_text){
          this.model.post_type_text = this.noticeInfo.position_name;
        }
        //根据面试结束时间 为是否显示结束时间赋值
        if(this.noticeInfo.time){
          this.validDate = this.formatDate.cutHours(new Date(this.noticeInfo.time),8);
          let nowDate = new Date();
          let endDate = new Date(this.noticeInfo.time);
          let nowTime = nowDate.getTime();
          let endTime = endDate.getTime();
          //console.log("面试时间倒计时------------------------毫秒");
          //console.log(endTime-nowTime);
          //如果距离结束大于8小时
          if(endTime-nowTime>28800000){
            if(this.noticeInfo.feedback_status){
              //console.log("面试时间倒计时------------------------毫秒1");
              //判断是否可以取消该面试
              if(this.noticeInfo.feedback_status == 'to_be_accepted' && this.noticeInfo.feedback_status == 'accept'){
                this.isCancel = true;
              }
            }
          }
          if(endTime-nowTime<=28800000){
            if(this.noticeInfo.feedback_status){
              //判断是否显示面试倒计时
              if(this.noticeInfo.feedback_status == 'accept'){
                //console.log("面试时间倒计时------------------------毫秒2");
                this.isCountdown = true;
                this.countdownNum = Math.floor((endTime-nowTime)/1000/60/60);//毫秒/秒/分钟/小时
              }
            }
          }
          if(endTime-nowTime<=0){
            if(this.noticeInfo.feedback_status){
              //判断是否显示面试倒计时
              if(this.noticeInfo.feedback_status == 'accept'){
                this.noticeInfo.feedback_status = 'handle_interview';
                this.model.feedback_status = 'handle_interview';
              }else if(this.noticeInfo.feedback_status == 'to_be_accepted'){
                //console.log("面试时间倒计时------------------------毫秒3");
                this.noticeInfo.feedback_status = 'expired';
                this.model.feedback_status = 'expired';
              }
            }
          }
        }//面试结束时间赋值结束
         //面试状态
         if(this.noticeInfo.feedback_status){
          if(this.noticeInfo.feedback_status == 'to_be_accepted'){
            this.feedbackStatus = '待接受';
            this.isShowTop = false;
          }
          if(this.noticeInfo.feedback_status == 'rejected'){
            this.feedbackStatus = '已拒绝';
            this.isShowTop = true;
          }
          if(this.noticeInfo.feedback_status == 'accept'){
            this.feedbackStatus = '等待面试';
            this.isShowTop = false;
          }
          if(this.noticeInfo.feedback_status == 'talent_canceled'){
            this.feedbackStatus = '已取消';
            this.isShowTop = true;
          }
          if(this.noticeInfo.feedback_status == 'department_canceled'){
            this.feedbackStatus = '已取消';
            this.isShowTop = true;
          }
          if(this.noticeInfo.feedback_status == 'expired'){
            this.feedbackStatus = '已过期';
            this.isShowTop = true;
          }
          if(this.noticeInfo.feedback_status == 'handle_interview'){
            this.feedbackStatus = '待处理';
            this.isShowTop = false;
          }
           if(this.noticeInfo.feedback_status == 'hired'){
            this.feedbackStatus = '已录用';
            this.isShowTop = true;
          }
           if(this.noticeInfo.feedback_status == 'improper'){
            this.feedbackStatus = '不合适';
            this.isShowTop = false;
          }
           if(this.noticeInfo.feedback_status == 'miss'){
            this.feedbackStatus = '对方爽约';
            this.isShowTop = false;
          }
        }
      }).catch((err)=>{
        this.opera.loadiss();
        //console.log(err);
      })
    }
  //获取职位列表数据
  getPositionList(){
    //console.log("在期职位本地存储----");
    //console.log(this.dataApi.getLocalpositionList());
    if(this.dataApi.getLocalpositionList()){
      return;
    }else{
      this.opera.loading();
      this.dataApi.getPositionList({limit:100,offset:0,status:'release'}).then((res:any)=>{
        //console.log("在期职位获取线上数据---");
        //console.log(res);
        this.opera.loadiss();
        this.dataApi.setLocalpositionList(res.list);
      }).catch((error) => {
        this.opera.loadiss();
        this.toastr.error(error);
      });
    }
  }

  //获取企业信息
  getDepartment(){
    if(this.dataApi.getLocalDepartment()){
          this.myDepartment = this.dataApi.getLocalDepartment();
          //console.log("企业信息本地存储----position-notice-details");
          //console.log(this.dataApi.getLocalDepartment());
        }else{
          this.opera.loading();
          this.dataApi.getDepartment().then((res:any)=>{
          //console.log("企业信息获取线上数据----position-notice-details");
          //console.log(res);
          this.myDepartment = res;
          this.opera.loadiss();
          this.dataApi.setLocalDepartment(res);
        }).catch((error) => {
          this.opera.loadiss();
          //console.log(error);
          this.toastr.error(error);
      });
      }
  }

  //获取企业信息(填充企业地址)
  getDepartmentLocation(){
    if(this.dataApi.getLocalDepartment()){
      this.myDepartment = this.dataApi.getLocalDepartment();
      if(this.myDepartment.locations){
        let addressDefaul=false;
        for(let val of this.myDepartment.locations){
          if(val.is_default){
            addressDefaul=true;
            this.model.location = val;//公司地址(第一次发送面试邀请采用公司默认地址)
            this.workPlace = val.lid;
            return;
          }
        }
        if(!addressDefaul){
          this.workPlace = this.myDepartment.locations[0].lid;
        }
      }else{
        this.toastr.warning("请添加企业地址");
        this.router.navigateByUrl("home/company-details-address");
      }
      //console.log("企业信息本地存储----position-notice-details");
      //console.log(this.dataApi.getLocalDepartment());
    }else{
      this.opera.loading();
      this.dataApi.getDepartment().then((res:any)=>{
        //console.log("企业信息获取线上数据----position-notice-details");
        //console.log(res);
        this.myDepartment = res;
        if(this.myDepartment.locations){
          let addressDefaul=false;
          for(let val of this.myDepartment.locations){
            if(val.is_default){
              addressDefaul=true;
              this.model.location = val;//公司地址(第一次发送面试邀请采用公司默认地址)
              this.workPlace = val.lid;
              return;
            }
          }
          if(!addressDefaul){
            this.workPlace = this.myDepartment.locations[0].lid;
          }
        }
        this.dataApi.setLocalDepartment(res);
        this.opera.loadiss();
      }).catch((error) => {
        this.opera.loadiss();
        //console.log(error);
        this.toastr.error(error);
      });
    }
  }


}
