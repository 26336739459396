import { Component, OnInit } from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmComponent} from "../confirm/confirm.component";
import {PlatformLocation} from "@angular/common";
import {Router} from "@angular/router";

@Component({
  selector: 'app-find-accound',
  templateUrl: './find-accound.component.html',
  styleUrls: ['./find-accound.component.css']
})
export class FindAccoundComponent implements OnInit {

  public config: Config;

  public isLogin = false
  constructor(
      public local:Local,
      public opera:Opera,
      private location: PlatformLocation,
      private router: Router,
      public dataApi: DataApi,
      public dialog: MatDialog
  ) {
    this.config = Config.getInstance();//实例化config

  }

  ngOnInit() {

    let token =  localStorage.getItem('accesstoken')
    if(token){
      this.isLogin = true;
    }

  }
  //退出
  goLogin() {
    if (!this.isLogin) {
      this.setRoot();//不重新登录无法接收消息，踢到登录页
      return
    }
    this.dialog.open(ConfirmComponent, {
     
      data: {
        title: '提示',
        message: '您是否要退出登录？',
        trueText:'确定',
        falseText:'取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      //console.log('检测是否存在返回值');
      //console.log(isConfirmed);
      if(isConfirmed){
        this.setRoot();//不重新登录无法接收消息，踢到登录页
      }
    });
  }
  //接收错误信息返回登录
  setRoot() {
    this.dataApi.logout();
    // this.myJMessage.loginOut();//极光服务退出
    let collegeid = this.local.get('collegeid') + '';
    let hash = location.hash;

    console.log('logoutsetRoot',collegeid,hash);
    if(hash.indexOf('/login')<0){
      if (collegeid !='false') {
        this.router.navigateByUrl("login?type=login&id="+collegeid);
      }else {
        this.router.navigateByUrl("login");
      }
    }

  }
  goBack(){
    if(this.isLogin){
      this.router.navigateByUrl("guide");
    }else {
      this.router.navigateByUrl("app-find-psw");

    }
  }

}
