import { Component,EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-model-chat-report',
  templateUrl: './model-chat-report.component.html',
  styleUrls: ['./model-chat-report.component.css']
})
export class ModelChatReportComponent {
  @Input() isShow: false;//是否显示弹窗页面
  constructor() {}
  @Output() onVoted = new EventEmitter();
  dismiss(result: any) {
     //console.log("传值检查");
     //console.log(result);
     this.onVoted.emit(result);
  } 

}
