<!-- 页头部分 -->
<div class="contentRight-header">
    <img *ngIf="!config.isByCollege" (click)="opera.goChangeRouter('college-milkround')" src='assets/images/router-old.png'/>
    <img *ngIf="config.isByCollege" (click)="opera.goChangeRouter('college-details-milk')" src='assets/images/router-old.png'/>
校园招聘 - 双选会 - 双选会详情</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-college-milksign #milkUpdata [number]="milkNumber" [mridList]="mridList" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-milksign>
  <div class="college-block" *ngIf='milk'>
      <div class="img-cover">
          <img *ngIf="milk.cover" [src]="milk.logo">
          <img *ngIf="!milk.cover" src="assets/images/none.png">
          <div class="college-title">{{milk.name}}</div>
          <!-- 操作部分 -->
          <div class="college-opera">
            <!-- 未开始 -->
            <button class='purple' *ngIf="milk.showBaoMingBtn &&(milk.loadState != 1 && milk.loadState != 5)" (click)="goSign()">我要报名</button><!--未开始未报名-->
            <button class='purple' *ngIf="isApplySign&&(milk.status=='to_audit')" (click)="goUpdate(milk)">修改申请</button><!--未开始待审核-->
            <button class="purple" *ngIf="isApplySign&&(milk.status=='not_passed')" (click)="goSign()">再次申请</button><!--未开始未通过-->
            <button class="gray" *ngIf="isApplySign&&(milk.status=='passed')">已通过（请准备参会）</button><!--未开始已通过-->
            <!--  -->
            <button class="yellow" *ngIf="milk.cooperation_status==2&& milk.status=='passed'" (click)="goSpace()">进入会场</button>
          </div>
      </div>

    <div *ngIf="milk.status=='not_passed'" style="width: 95%;
    height: auto;
    word-wrap:break-word;
    word-break:break-all;
    overflow: hidden;margin-left: 30px;margin-top: 15px;margin-right: -55px;color: red;font-size: 20px">申请双选会未通过：{{milk.reason}}</div>

    <div class="college-center">

          <div class="cont-title"><span></span>活动简介</div>
          <div class="cont-txt clear">
              <div class="cont-list" *ngIf='milk.start_time'><span>开始时间&nbsp;&nbsp;&nbsp;</span>{{milk.start_time | date:'yyyy-MM-dd HH:mm'}}</div>
              <div class="cont-list" *ngIf='milk.end_time'><span>结束时间&nbsp;&nbsp;&nbsp;</span>{{milk.end_time | date:'yyyy-MM-dd HH:mm'}}</div>
              <div class="cont-list" *ngIf='milk.apply_start_time && milk.apply_start_time != "" && milk.apply_start_time != "0001-01-01T00:00:00+08:00" && milk.apply_start_time != "0001-01-01T00:00:00"'><span>报名开始&nbsp;&nbsp;&nbsp;</span>{{milk.apply_start_time | date:"yyyy-MM-dd HH:mm" }}</div>
              <div class="cont-list" *ngIf='milk.apply_end_time && milk.apply_end_time != "" && milk.apply_end_time != "0001-01-01T00:00:00+08:00" && milk.apply_end_time != "0001-01-01T00:00:00"'><span>报名结束&nbsp;&nbsp;&nbsp;</span>{{milk.apply_end_time | date:"yyyy-MM-dd HH:mm" }}</div>
            <div class="cont-list" *ngIf='milk.seat_number'><span>&nbsp;&nbsp;&nbsp;展位号&nbsp;&nbsp;&nbsp;</span>{{milk.seat_number==0?"未分配":milk.zoom+milk.seat_number}}</div>

            <div class="cont-list" *ngIf='milk.place_name'><span>举办场地&nbsp;&nbsp;&nbsp;</span>{{milk.place_name}}</div>
              <div class="cont-list" *ngIf='milk.contacts'><span>&nbsp;&nbsp;&nbsp;联系人&nbsp;&nbsp;&nbsp;</span>{{milk.contacts}}</div>
              <div class="cont-list" *ngIf='milk.phone'><span>联系电话&nbsp;&nbsp;&nbsp;</span>{{milk.phone}}</div>
              <div class="cont-list" *ngIf='milk.email'><span>联系邮箱&nbsp;&nbsp;&nbsp;</span>{{milk.email}}</div>
              <div class="cont-list" *ngIf="!isMilkStart"><span>当前状态&nbsp;&nbsp;&nbsp;</span>即将开始</div>
              <div class="cont-list" *ngIf="isMilkStart&&!isMilkEnd"><span>当前状态&nbsp;&nbsp;&nbsp;</span>正在进行</div>
              <div class="cont-list" *ngIf="isMilkEnd"><span>当前状态&nbsp;&nbsp;&nbsp;</span>已经结束</div>
          </div>
      <div class="cont-title"><span></span>活动说明 <span (click)="downLoadGfile()" style="font-size: 16px;margin-top: -5px;width: 100px;background-color: lightgray;text-align: center;border-radius: 5px;padding-top: 5px">附件下载</span> </div>
          <div class="college-message" [innerHtml]="opera.assembleHTML(milk.description)"></div>
      </div>
  </div>
</div>

