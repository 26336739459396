<!-- 用户确认操作弹出框 -->
<div class="modal-dialog">
  <div class="modal-content">
      <div class="modal-header">
          <h4 class="modal-title">{{title}}</h4>
          <button type="button" class="close" mat-dialog-close>×</button>
      </div>
      <div class="modal-body" style="position: relative">
          {{message}}
          <div *ngIf="isShowFindAccount" style="position: absolute;right: 35px;bottom: 15px;color: #5E4FFF;cursor: pointer" (click)="gotoFingAccpunt()"> >> 账号找回</div>

      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-default" [mat-dialog-close]="false">{{falseText}}</button>
          <button type="button" class="btn btn-primary" [mat-dialog-close]="true">{{trueText}}</button>
      </div>
  </div>
</div>
<!-- <h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content>{{message}}</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>取消</button>
  <button mat-button [mat-dialog-close]="true">确定</button>
</mat-dialog-actions> -->
