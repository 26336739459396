<!-- 我收到的 -->
<!-- 主体内容部分 -->
<div class="college-block" *ngIf="isShow">
    <!-- 头部 -->
    <div class="college-resume-head">
        <div class="search-position left">
            <!-- 按发布的职位筛选 -->
            <span>在期职位&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <select name="position" id="position" [(ngModel)]="model.positionid" (ngModelChange)="getResumeList()" autocomplete="off">
                <option class="place-text" value=''>全部</option>
                <option *ngFor="let a of positionList" [value]="a.positionid">{{a.name}}</option>
            </select>
        </div>
        <!-- 更多筛选 -->
        <div class="search-more right" (click)="isShowFilter=!isShowFilter" [class.show-filter]="isShowFilter">更多条件筛选<img src="assets/images/down-gray.png"></div>
        <!-- 搜索筛选 -->
        <div class="search-input right">
            <input type="text" placeholder="请输入学校名称" [(ngModel)]="model.college_name" (keyup)="getItems(model.college_name)"><span (click)="goSearch(model.college_name)"><img src="assets/images/search.png"></span>
            <!-- 搜索关键词匹配列表 -->
            <div class="keyup-block" *ngIf="keywordList.length>0">
                <div class="keyup-list" *ngFor = "let key of keywordList" (click)="goSearch(key.text)">{{key.text}}</div>
            </div>
        </div>
    </div>
    <div *ngIf="isShowFilter" class="college-resume-filter">
        <!-- 选择专业 -->
        <span>专业</span>
        <select name="major" id="major" [(ngModel)]="majorOne" (ngModelChange)="majorOneChange()" autocomplete="off">
            <option class="place-text" value='' disabled hidden selected="selected">选择一级专业</option>
            <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
        </select>
        <select name="majorTwo" id="majorTwo" [(ngModel)]="model.major" (ngModelChange)="getResumeList()" (click)="isShowTwo()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择二级专业</option>
                <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
        </select>
        <!-- 选择学历 -->
        <span>&nbsp;&nbsp;&nbsp;&nbsp;学历</span>
        <select name="education" id="education" [(ngModel)]="model.education" (ngModelChange)="getResumeList()" autocomplete="off">
            <option class="place-text" value='' disabled hidden selected="selected">选择学历</option>
            <option *ngFor="let a of educationList" [value]="a.code">{{a.text}}</option>
        </select>  
        <!-- 重置 -->
        <!-- <button (click)="goInit()">重置</button> -->
    </div>
    <!-- 中间部分 -->
    <!-- 没有内容存在时 -->
    <div *ngIf="countList==0" class="none-block">
        <img src="assets/images/resume-search-fail.png"><br/>
        暂未找到签约邀请
    </div>
    <div class="college-resume-cont" *ngIf="countList>0">
        <ul>
            <li>头像</li>
            <li>姓名</li>
            <li>学校</li>
            <li>专业</li>
            <li>学历</li>
            <li>签约职位</li>
            <li>签约薪资</li>
            <li>入职时间</li>
            <li>签约状态</li>
            <li>操作</li>
        </ul>
        <!-- <ul class="resume-li">
            <li><img src="assets/images/none.png"></li>
            <li>赵小花</li>
            <li>郑州升达经贸管理学院</li>
            <li>旅游管理</li>
            <li>本科</li>
            <li>总监助理</li>
            <li>3000</li>
            <li>2018-9-09-12 10：00</li>
            <li>待接受</li>
            <li><span>查看简历</span><span>发起沟通</span><span>签约处理</span></li>
        </ul> -->
        <div *ngIf="countList>0">
            <ul class="resume-li" *ngFor="let n of resumeList;let i = index;">
                <li><img *ngIf="n.avater" [src]="n.avater"><img *ngIf="!n.avater" src="assets/images/none.png"></li>
                <li><span *ngIf='n.talent_name'>{{n.talent_name}}</span><span *ngIf='!n.talent_name'>暂无</span></li>
                <li><span *ngIf='n.graduate_university'>{{n.graduate_university}}</span><span *ngIf='!n.graduate_university'>暂无</span></li>
                <li><span *ngIf='n.source_major_text'>{{n.source_major_text}}</span><span *ngIf='!n.source_major_text'>暂无</span></li>
                <li><span *ngIf='n.education_text'>{{n.education_text}}</span><span *ngIf='!n.education_text'>暂无</span></li> 
                <li><span *ngIf='n.position_name'>{{n.position_name}}</span><span *ngIf='!n.position_name'>暂无</span></li>
                <li><span *ngIf='n.salary'>{{n.salary}}</span><span *ngIf='!n.salary'>暂无</span></li>
                <li><span *ngIf='n.sign_time'>{{n.sign_time | date:"yyyy-MM-dd hh:mm"}}</span><span *ngIf='!n.sign_time'>暂无</span></li>
                <li><span *ngIf='n.sign_status'>{{n.sign_status}}</span><span *ngIf='!n.sign_status'>暂无</span></li>
                <li>
                  <span (click)="goDetails(n.resume_number)">查看简历</span>
                  <!--<span (click)="goChat(n,modelPosition.positionid)">立即沟通</span>-->
                  <span (click)="goCollect(n)">{{n.isfollow?'取消收藏':'收藏'}}</span>
                  <!-- <span>分享</span> -->
                </li> 
            </ul>
        </div>
    </div>
    <!-- 分页部分 -->
    <div class='paging-block' [class.isPageHeight]="countList>0">
       <router-outlet></router-outlet>
       <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
    </div>
   
  
</div>
