<!-- 页头部分 -->
<div class="contentRight-header"><img (click)="opera.goChangeRouter('position-manage')" src='assets/images/router-old.png' />招聘管理 - 职位管理 - {{publishPosition}}</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-still-more #child1 [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-still-more>
    <app-model-select-more #child2 [isShow]="modelTwoShow" (onVoted)="ModelTwodismiss($event)"></app-model-select-more>
    <app-model-select-two #child3 [isShow]="modelThreeShow" (onVoted)="ModelThreedismiss($event)"></app-model-select-two>
    <app-model-position-template #child4 [isShow]="modelFourShow" (onVoted)="ModelFourdismiss($event)"></app-model-position-template>
    <div class="college-block">
        <!-- 页头部分 -->
        <div class="college-block-head">{{publishPosition}}</div>
        <!-- 填写部分 -->
        <div class="sign-block">

            <div class="education_chips">
                <img src="../../assets/images/guide-false.png" />
                <span>因国家教育部规定，招聘详情中禁止出现“全日制”、“985”、“211”等学历歧视性关键字，若出现该职位将被处理，请知晓</span>
            </div>

            <!-- 职位类别 -->
            <div class="sign-list" *ngIf="!positionid">
                <span>职位类别：</span>
                <div *ngIf="!model.post_type" (click)="goPositionType()" class="placholder-text">请选择选择职位类别</div>
                <div *ngIf="model.post_type" class="selected-text" (click)="goPositionType()">{{model.post_type | codetransform:config.post_type}}</div>
                <s class="remark-text">*职位发布后职位类别不可修改</s>
                <b *ngIf="isSend&&!model.post_type"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <div class="sign-list" *ngIf="positionid">
                <span>职位类别：</span>
                <div class="selected-text disabled-text">{{model.post_type | codetransform:config.post_type}}</div>
                <s class="remark-text">*职位发布后职位类别不可修改</s>
            </div>
            <!-- 职位名称 -->
            <div class="sign-list">
                <span>职位名称：</span>
                <input *ngIf="!positionid" type="text" [(ngModel)]="model.name" maxlength="15" placeholder="请填写职位名称(15字以内)" (blur)="checkName(model.name)">
                <input *ngIf="positionid" type="text" [(ngModel)]="model.name" disabled>
                <s class="remark-text">*职位发布后职位名称不可修改</s>
                <b *ngIf="isSend&&!model.name"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 到期时间 -->
            <div class="sign-list">
                <span>到期时间：</span><input type="text" placeholder="请选择职位到期时间" id="effect-time" [(ngModel)]="endTime" /><img class="calendar-img" src="assets/images/calendar.png" alt="打开日历" />
                <s *ngIf="publishPosition=='发布职位'" class="remark-text">*职位到期时间最晚为{{positionEndTime | date:"yyyy-MM-dd"}}</s>
                <b *ngIf="isSend&&!endTime"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 工作性质 -->
            <div class="sign-list select-list">
                <span>工作性质：</span>
                <select autocomplete="off" [(ngModel)]="model.post_nature" (ngModelChange)="post_natureChange()">
                    <option class="place-text" value='' disabled hidden selected="selected">选择工作性质</option>
                    <option value="05">校招</option>
                    <option value="03">实习</option>
                </select>
            </div>
            <!-- 月薪范围 -->
            <div class="sign-list select-list">
                <span>月薪范围：</span>
                <select autocomplete="off" [(ngModel)]="model.position_salary" *ngIf="model.post_nature == '03' || model.post_nature == ''">
                    <option class="place-text" value='' disabled hidden selected="selected">选择月薪范围</option>
                    <option *ngFor="let salary of salaryData" [value]="salary.code">{{salary.text}}</option>
                </select>
                <select autocomplete="off" [(ngModel)]="model.position_salary" *ngIf="model.post_nature == '05'">
                    <option class="place-text" value='' disabled hidden selected="selected">选择月薪范围</option>
                    <option *ngFor="let salary of salaryData02" [value]="salary.code">{{salary.text}}</option>
                </select>
                <b *ngIf="isSend&&!model.position_salary"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 学历要求 -->
            <div class="sign-list select-list">
                <span>学历要求：</span>
                <select autocomplete="off" [(ngModel)]="model.education">
                <option class="place-text" value='' disabled hidden selected="selected">选择学历要求</option>
                <option *ngFor="let edu of config.education" [value]="edu.code">{{edu.text}}</option>
            </select>
                <b *ngIf="isSend&&!model.education"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 工作城市 -->
            <div class="sign-list">
                <span>工作城市：</span>
                <div *ngIf="!model.location.region" (click)="goCityCode()" class="placholder-text">请选择选择工作城市</div>
                <div *ngIf="model.location.region" (click)="goCityCode()" class="selected-text">{{model.location.region | codetransform:config.region}}</div>
                <b *ngIf="isSend&&!model.location.region"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 招聘人数 -->
            <div class="sign-list">
                <span>招聘人数：</span><input type="number" [(ngModel)]="model.intent_count" (blur)="recruits()" oninput="if(value.length>4)value=value.slice(0,4)" placeholder="请填写招聘人数(招聘人数小于10000人)">
                <b *ngIf="isSend&&!model.intent_count"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 专业要求 -->
            <div class="sign-list sign-skill">
                <span>专业要求：</span>
                <div class="label-list clear" *ngIf="model.majors.length==0">
                    <div class="label-item label-click" (click)="goMajor()">
                        <div class="label-text">+添加专业</div>
                    </div>
                </div>
                <div class="label-list clear" *ngIf="model.majors.length>0">
                    <div class="label-item purple" *ngFor="let m of model.majors">
                        <div class="label-text">{{m | codetransform:config.major}}</div>
                    </div>
                    <div class="label-item label-click" (click)="goMajor()">
                        <div class="label-text">+添加</div>
                    </div>
                </div>
                <!-- <div *ngIf="model.majors.length==0" class="placholder-text">不限</div>
            <div *ngIf="model.majors.length>0" class="selected-text"><span *ngFor="let m of model.majors">{{m | codetransform:config.major}}&nbsp;&nbsp;</span></div> -->
            </div>
            <!--技能标签 -->
            <div class="sign-list sign-skill">
                <span>技能标签：</span>
                <div class="label-list clear" *ngIf="model.skills.length==0">
                    <div class="label-item purple">
                        <div class="label-text">不限</div>
                    </div>
                    <div class="label-item label-click" (click)="goSkill()">
                        <div class="label-text">+添加</div>
                    </div>
                </div>
                <div class="label-list clear" *ngIf="model.skills.length>0">
                    <div class="label-item purple" *ngFor="let m of model.skills">
                        <div class="label-text">{{m | codetransform:config.skill}}</div>
                    </div>
                    <div class="label-item label-click" (click)="goSkill()">
                        <div class="label-text">+添加</div>
                    </div>
                </div>
                <s class="remark-text" *ngIf="isWidthSkill">*根据所选职位类别已为您设置技能标签，点击添加按钮可修改</s>
                <!-- <div *ngIf="model.skills.length==0" class="placholder-text">不限</div>
            <b *ngIf="isSend&&!model.skills.length==0"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b> -->
            </div>
            <!-- 职位描述 -->
            <div class="sign-list sign-intro">
                <span>职位描述：</span>
                <textarea name="responsibility" id="responsibility" [(ngModel)]="model.responsibility" maxlength="1000" placeholder="请填写工作内容和要求（1000字以内）"></textarea>
                <b *ngIf="isSend&&!model.responsibility"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
                <div class="sign-button"><button class="yellow" (click)="goTemplate()">查看模板</button></div>
            </div>
            <!-- 晋升路径 -->
            <div class="sign-list sign-path">
                <span>晋升路径<i>(选填)</i>：</span>
                <!-- 晋升路径样式  *ngIf="model.name"-->
                <!-- <div *ngIf="!model.name" class="placholder-text">请先填写职位名称</div> -->
                <div class="path-block">
                    <div class="path-list" *ngFor="let p of path;let i =index;">
                        <span>路径{{i+1}}：</span>
                        <input type="text" placeholder="请填写职位名称(15字以内)" maxlength="15" [(ngModel)]="p.text" (blur)="checkName(p.text)">
                        <img class="path-delete" *ngIf="i!=0" (click)="deletePath(p,i)" src="../../assets/images/delete_line.png" />
                    </div>
                    <div class="path-list textCenter"><img (click)="addPosition()" src="assets/images/add-img.png"></div>

                </div>
            </div>
            <!-- 提交 -->
            <div class="sign-submit" *ngIf="!positionid">
                <button class="purple" (click)="createPosition()">发布</button>
            </div>
            <div class="sign-submit" *ngIf="position">
                <button (click)="createPosition()" class="purple">保存</button>
                <button class="cancelbg" (click)="opera.goChangeRouter('position-manage')">取消</button>
                <button *ngIf="position.position_status=='expired'" class="yellow" (click)="goResend()">重发</button>
            </div>
        </div>
    </div>
    <!--    <p-dialog [(visible)]="isShowDownLoadAPP" [width]="300" [height]="330" [minHeight]="330" [modal]=true>
      <p-header>下载APP<img (click)="cloceDownLoad()"  style="width: 15px;height: 15px;float: right;margin-top: 5px" src="../../assets/images/closeX.png"/></p-header>
      <img  style="width: 200px;height: 200px;margin-left: 25px" src="../../assets/images/goworklaQRcode.png"/>
      <p style="text-align: center;line-height: 15px;margin: 0px">下载APP</p>
      <p style="text-align: center;line-height: 15px;margin: 0px">随时查看最新简历</p>
      &lt;!&ndash;<p style="text-align: center;line-height: 15px;margin: 0px">随时连接审核进度</p>&ndash;&gt;
    </p-dialog>-->
</div>