import { Component, OnInit, Input, Output } from '@angular/core';
import {DataApi, Local, Constants, Config, Opera, milkround} from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';

@Component({
  selector: 'app-position-campus',
  templateUrl: './position-campus.component.html',
  styleUrls: ['./position-campus.component.css']
})
export class PositionCampusComponent implements OnInit {
  public config: Config;
  public keywordList=[];//搜索关键词匹配列表
  public campusList = [];//宣讲会列表
   // 筛选条件
   public model={
    college_name:'',
    status:'',//状态
    offset:0,
    limit:9,
     collegeid:''
  }
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public myDepartment:any;//公司信息

  public isShowJoinCerModel = false; // 是否展示参会凭证弹窗
  public JoinCerModel = null; //展示参会凭证弹窗
  public revokeModule = false;
  public revokeApplyid = '';

  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public milkroundFormat:milkround,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {
    if(this.local.get(Constants.collegeid))
    {
      this.model.collegeid = this.local.get(Constants.collegeid);
      this.dataApi.lastCollegeAuthenticationIdentity(this.model.collegeid).then((res:any)=>{
        console.log('lastCollegeAuthenticationIdentity',res);
        if(res){
          // this.config.lastCollegeIdentity=res;
          if(res.status==3){
            this.router.navigate(['home/collegeidentity'],{
              queryParams: {
                isShow:true
              }
            });
          }
        }
        this.opera.loadiss();
      }).catch((error)=>{
        //console.log(error);
        this.opera.loadiss();
      })
    }
    // this.opera.initConfig('position','position-campus');
    this.opera.initConfig('college','college-details-campus');

    this.getCollegeCode();//获取高校字典
    this.getDepartment();//获取企业信息
    this.getCampusList();//获取宣讲会列表
  }

   //获取企业信息
   getDepartment(){
    if(this.dataApi.getLocalDepartment()){
          this.myDepartment = this.dataApi.getLocalDepartment();
          //console.log("企业信息本地存储---------------------------CollegeIdentityPage");
          //console.log(this.dataApi.getLocalDepartment());
        }else{
          this.dataApi.getDepartment().then((res:any)=>{
            //console.log("企业信息获取线上数据---------------------------CollegeIdentityPage");
          //console.log(res);
            if(res)
            {
              this.dataApi.setLocalDepartment(res);

            }
        }).catch((error) => {
          //console.log(error);
          this.toastr.error(error);
      });
    }

  }

  //修改宣讲会申请
  goUpdata(collegeid,n){
    //console.log("修改宣讲会申请-------");
    // this.local.set(Constants.collegeid,collegeid);


    this.dataApi.checkDepCollegeIdentity(collegeid).then((res:any)=>{
      if(res){
        if(res.gwl_status=='2'){//通过工作啦认证
          // this.local.set(Constants.collegeid,collegeid);

          this.local.set(Constants.campusApply,JSON.stringify(n));
          this.router.navigate(['home/college-campus-sign']);

        }else{//未通过工作啦审核
          if(res.status=='2'){//通过校招
            // this.local.set(Constants.collegeid,collegeid);
            this.local.remove(Constants.campusApply);
            this.router.navigate(['home/college-campus-sign']);
          }else if(res.status=='1'){//校招审核中
            this.toastr.warning("高校合作审核中，审核通过后才可预约宣讲会");
          }else{//未提交校招申请
            this.opera.remindCollegeIdentity("预约宣讲会",collegeid,res);
          }
        }
      }else{
        this.opera.remindCollegeIdentity("预约宣讲会",collegeid,res);
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    })

  }

  // 查看宣讲会详情
  goPreach(ctid){
    this.local.set(Constants.ctid,ctid);
    this.router.navigate(['home/college-details-preach']);
  }

  //申请宣讲会(再次申请)
  goSign(collegeid){
    if(this.config.positionList.length==0){
      this.opera.remindPublish("申请宣讲会");
      return;
    }
    this.dataApi.checkDepCollegeIdentity(collegeid).then((res:any)=>{
      if(res){
        if(res.gwl_status=='2'){//通过工作啦认证
          // this.local.set(Constants.collegeid,collegeid);
          this.local.remove(Constants.campusApply);
          this.router.navigate(['home/college-campus-sign']);
        }else{//未通过工作啦审核
          if(res.status=='2'){//通过校招
            // this.local.set(Constants.collegeid,collegeid);
            this.local.remove(Constants.campusApply);
            this.router.navigate(['home/college-campus-sign']);
          }else if(res.status=='1'){//校招审核中
            this.toastr.warning("高校合作审核中，审核通过后才可预约宣讲会");
          }else{//未提交校招申请
            this.opera.remindCollegeIdentity("预约宣讲会",collegeid,res);
          }
        }
      }else{
        this.opera.remindCollegeIdentity("预约宣讲会",collegeid,res);
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    })
  }

  //筛选
  goSearch(data){
    this.model.college_name=data;
    this.keywordList=[];
    this.getCampusList();//获取宣讲会列表
  }

  //获取宣讲会列表
  getCampusList(){
    this.opera.loading();
    this.dataApi.getDepartmentSignCampusList(this.model).then((res:any)=>{
      console.log("获取宣讲会列表-----",res);
      // console.log(res);
      this.opera.loadiss();

      if(res)
      {
        // this.campusList=res.list;
        this.campusList=this.milkroundFormat.formatCampus(res.list) ;

        this.countList=res.count;
        this.pageCount =Math.ceil(res.count/9);
      }

    }).catch((err)=>{
      //console.log(err);
      this.opera.loadiss();
    })
  }

  //获取高校字典
  getCollegeCode(){
    // 获取高校字典数据
    if(this.config.school.length<1){
     this.dataApi.getDictDate('school').then((res:any)=>{
       // //console.log(res);
       if(res)
       {
         this.config.school=res;
       }
     }).catch((err)=>{
       // this.toastr.error("网络错误,请稍后重试");
       //console.log(err);
     })
   }
 }

  //匹配关键字列表
  getItems(){
    this.keywordList=[];
    if(!this.model.college_name){return;}
    for(let s of this.config.school){
      if(s.text.indexOf(this.model.college_name) >= 0){
         this.keywordList.push(s);
      }
    }
  }
  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      that.goSearch(that.model.college_name);
    }else{
      that.getItems();
    }
  }


  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----college-campustalk');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getCampusList();//获取列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getCampusList();//获取列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getCampusList();//获取列表
      }
    }

  }
  //去其他的双选会，工作啦企业后端的页面
  gotoOtherCampus(){
    if(this.local.get('accesstoken')) {
      let token = this.local.get('accesstoken');
      let accountid = this.local.get('accountid');
      let collegeid = this.local.get('collegeid');
      window.open(this.config.JumpMainWeb+token+'&accountid='+accountid+'&route='+'college-campustalk1','_blank')
    }
  }
  showJoinCerModel(e,camp){
    e.stopPropagation();
    let data = {
      mcid:camp.ctid
    }
    this.dataApi.getCampustalkJoginCerInfo(data).then((res:any)=> {
      if(res){
        console.log('getCampustalkJoginCerInfo',res)
        this.JoinCerModel = res;
        console.log('showJoinCerModel',camp)
        this.isShowJoinCerModel = true
      }else {
        this.toastr.error('获取凭证失败')
      }
    }).catch(()=>{
      this.toastr.error('获取凭证失败')
    })
  }

  // 撤回
  openRevoke(e,applyid){
    e.stopPropagation();
    this.revokeApplyid = applyid;
    this.revokeModule = true;
  }

  downMilk(){
    this.dataApi.revokeCampus(this.revokeApplyid).then((res:any)=>{
      this.getCampusList();
      this.revokeModule = false;
    }).catch((err)=>{
      console.log("downMilk",err);
    });
  }
}
