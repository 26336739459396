import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { Config,Opera,DataApi} from '../../provider/index';

@Component({
  selector: 'app-company-status-three',
  templateUrl: './company-status-three.component.html',
  styleUrls: ['./company-status-three.component.css']
})
export class CompanyStatusThreeComponent implements OnInit {
  public config: Config;
  public power:boolean=true;
  constructor(
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService,
    public dataApi: DataApi
    ) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.opera.initConfig('company','company-authentication');
  }

  goAuthen(){
    let data = this.dataApi.getLocalDepartment()
    if(data.industry == '0202' || data.industry_text == '保险'){
      this.opera.sendTips('如需企业认证，请联系客服专线400-661-1396')
    }else{
      this.router.navigateByUrl("home/company-authentication");//填写企业认证页面
    }
  }
// 选项卡
powerTab(){
  this.power=true;
}
searchTab(){
  this.power=false;
}
// 跳转支付页面
goSearch(){
  this.router.navigate(["home/company-pay"],{
    queryParams:{
      searchMoney:298
    }
  });
}
// 继续支付
goPay(){
  this.router.navigateByUrl("home/company-pay");//付款页面
}
}
