<!-- 页头部分 goBack()-->
<div class="contentRight-header">
    <img *ngIf="!config.isByCollege" (click)="opera.goChangeRouter('college-resume')" src='assets/images/router-old.png'/>
    <img *ngIf="config.isByCollege" (click)="opera.goChangeRouter('college-details-student')" src='assets/images/router-old.png'/>
校园招聘 - 在线招聘 - 简历详情</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <!-- HTML -->
  <div class="container">
    <div class="resume-info-model" *ngIf="!isInit">
        <!-- 展示人才信息 -->
        <div class="home-resume-list">
          <div class="up-block">
            <!--头像-->
            <div class="left-block">
                <img *ngIf="resumeDetails.talent_base.logo" class="resumeImg" src="{{resumeDetails.talent_base.logo+'&imageView2/1/w/100/h/100'}}">
                <img *ngIf="!resumeDetails.talent_base.logo&&(resumeDetails.talent_base.gender=='01')"  class="resumeImg" src="assets/images/male.png"/>
                <img *ngIf="!resumeDetails.talent_base.logo&&(resumeDetails.talent_base.gender=='02')"  class="resumeImg" src="assets/images/female.png"/>
                <img *ngIf="!resumeDetails.talent_base.logo&&!resumeDetails.talent_base.gender"  class="resumeImg" src="assets/images/male.png"/>
              </div>
              <!--右边人才信息-->
              <div class="right-block">
                  <div class="name-text">{{resumeDetails.talent_base.name}}</div>
                  <div class="info-text">
                      <img src="assets/images/resume-people.png">
                      <span *ngIf="resumeDetails.talent_base.gender_text">{{resumeDetails.talent_base.gender_text}}</span>
                      <span *ngIf="resumeDetails.talent_base.education_text">/&nbsp;&nbsp;&nbsp;{{resumeDetails.talent_base.education_text}}</span>
                      <span *ngIf="resumeDetails.talent_base.work_experience_text">/&nbsp;&nbsp;&nbsp;{{resumeDetails.talent_base.work_experience_text}}</span>
                      <span *ngIf="resumeDetails.talent_base.address_text">/&nbsp;&nbsp;&nbsp;{{resumeDetails.talent_base.address_text}}</span>
                      <span *ngIf="resumeDetails.entry_text">/&nbsp;&nbsp;&nbsp;{{resumeDetails.entry_text}}</span>
                  </div>
                  <div class="info-text">
                      <img *ngIf="match" src="assets/images/resume-match.png">
                      <span *ngIf="match">简历匹配度：{{match}}%&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <img src="assets/images/resume-phone.png">
                      <!-- <span>{{resumeDetails.is_buy?'联系电话：'+resumeDetails.talent_base.mobile:'暂时无法展示联系方式，请向学生索要简历'}}</span>   -->
                      <span *ngIf="resumeDetails.talent_base.mobile">联系电话：{{resumeDetails.talent_base.mobile}}</span>
                      <!--<span *ngIf="!resumeDetails.is_buy">暂时无法展示联系方式，请向学生<button (click)="askResume()" class="yellow">索要简历</button></span> -->
                  </div>
                  <div class="info-text">
                      <!-- <button class=purple (click)="goContactChatBefore()">{{isFirst?'立即沟通':'继续沟通'}}</button> -->
                      <!--<button *ngIf="isFirst" class=purple (click)="goContactChatBefore()">立即沟通</button>-->
                      <!--<button *ngIf="!isFirst" class=purple (click)="goContinueChat()">继续沟通</button>-->
                      <!-- <button>面试邀请</button>  -->
                  </div>
              </div>
              <!-- 右上角操作区域 -->
              <div class="top-block">
                <!-- <button><img src="assets/images/resume-share.png">分享</button> -->
                <button *ngIf="!resumeDetails.isfollow" (click)="manageResumeArchived()"><img src="assets/images/resume-collect.png">收藏</button>
                <button *ngIf="resumeDetails.isfollow" (click)="manageResumeArchived()"><img src="assets/images/resume-collected.png">取消收藏</button>
              </div>
          </div>
        </div>
    </div>

    <div style="margin-bottom: 20px;padding-bottom: 30px;" *ngIf="!isInit">

      <!--教育经历-->
      <div class="resume-model-block" *ngIf="resumeDetails.education_backgrounds && resumeDetails.education_backgrounds.length>0">
          <div class="model-title"><b></b><span>教育经历</span><b></b></div>
          <div class="model-cont" *ngFor="let edubg of resumeDetails.education_backgrounds;let index=index">
            <div class="model-text clear">
              <div class="left text-title">{{ edubg.graduation_school}}</div>
              <div  class="right">{{edubg.start_date|date:'yyyy.MM'}} — {{edubg.end_date|date:'yyyy.MM'}}</div>
            </div>
            <div class="model-text gray" *ngIf="edubg.major_status_text || edubg.education_text">{{edubg.education_text}}&nbsp;{{edubg.major_status_text}}</div>
          </div>
      </div>
      <!-- 我的优势 -->
      <div class="skill-resume-block resume-model-block">
          <div class="model-title"><b></b><span>我的优势</span><b></b></div>
          <div class="label-list clear" *ngIf="resumeDetails.skills">
              <div class="label-item" *ngFor="let val of resumeDetails.skills">
                <div class="label-text">{{val.text}}</div>
              </div>
          </div>
      </div>
       <!--我的技能-->
       <div class="resume-model-block" *ngIf="resumeDetails.pro_skills && resumeDetails.pro_skills.length>0">
          <div class="model-title"><b></b><span>我的技能</span><b></b></div>
          <div class="skill-block" *ngFor="let val of resumeDetails.pro_skills">
            <div class="skill-title"><span></span>{{val.text}}</div>
            <div class="label-list clear" *ngIf="val.contrast_list">
                <div class="label-item" *ngFor="let item of val.contrast_list">
                  <div class="label-text">{{item.text}}</div>
                </div>
            </div>
          </div>
      </div>
      <!--工作/实践经历-->
      <div class="resume-model-block" *ngIf="resumeDetails.work_experiences && resumeDetails.work_experiences.length>0">
          <div class="model-title"><b></b><span>工作/实践经历</span><b></b></div>
          <div class="model-cont" *ngFor="let workexp of resumeDetails.work_experiences;let index=index">
              <div class="model-text clear">
                <div class="left">公司名：{{ workexp.position }}</div>
                <div class="right" *ngIf="workexp.position">职位：{{ workexp.position }}</div>
              </div>
              <div class="model-text  clear">
                <div class="left" *ngIf="workexp.salary_text">月薪：{{ workexp.salary_text }}</div>
                <!--工作时间-->
                <div class="right" *ngIf="workexp.end_date && !workexp.isEndDate">实习时间：{{workexp.start_date|date:'yyyy.MM'}} — {{workexp.end_date|date:'yyyy.MM'}}</div>
                <div class="right" *ngIf="!workexp.end_date || workexp.isEndDate">实习时间：{{workexp.start_date|date:'yyyy.MM'}} — 至今</div>
              </div>
              <!--工作描述-->
              <div class="model-text text-description"  *ngIf="workexp.description" [innerHtml]="workexp.description | textformat"></div>
          </div>
      </div>
      <!--项目/比赛-->
      <div class="resume-model-block" *ngIf="resumeDetails.project_experiences && resumeDetails.project_experiences.length>0">
          <div class="model-title"><b></b><span>项目/比赛</span><b></b></div>
          <div class="model-cont" *ngFor="let project of resumeDetails.project_experiences;let index=index">
              <div class="model-text clear">
                  <div class="left">项目名称：{{ project.name }}</div>
                  <!--时间-->
                  <div class="right" *ngIf="project.end_date && !project.isEndDate">{{project.start_date|date:'yyyy.MM'}} — {{project.end_date|date:'yyyy.MM'}}</div>
                  <div class="right" *ngIf="!project.end_date || project.isEndDate">{{project.start_date|date:'yyyy.MM'}} — 至今</div>
              </div>
              <div class="model-text">
                  <div *ngIf="project.post">担任职务：{{ project.post }}</div>
              </div>
              <div class="model-text">
                  <div *ngIf="project.link">链接地址：{{project.link}}</div>
              </div>
              <!--描述-->
              <div *ngIf="project.description" class="model-text text-description clear">
                <div class="left">项目说明：</div>
                <div class="left" [innerHtml]="project.description | textformat"></div>
              </div>
              <!-- 图片 -->
              <div class="model-text clear" *ngIf="project.image_url && project.image_url.length>0">
                <div class="left">项目图片：</div>
                <div class="model-pic" *ngFor="let url of project.image_url;let num=index">
                    <img *ngFor="let url of project.image_url;let num=index" src="{{url+'?imageView2/1/w/200/h/160'}}" >
                </div>
              </div>
          </div>
      </div>
      <!--作品展示-->
      <div class="resume-model-block" *ngIf="resumeDetails.works && resumeDetails.works.length>0">
          <div class="model-title"><b></b><span>作品展示</span><b></b></div>
          <div class="model-cont" *ngFor="let work of resumeDetails.works;let index=index">
              <div class="model-text">
                  <div>作品名称：{{ work.name }}</div>
              </div>
              <div class="model-text">
                  <div *ngIf="work.link">链接地址：{{work.link}}</div>
              </div>
              <!--作品说明-->
              <div *ngIf="work.description" class="model-text text-description clear">
                  <div class="left">作品说明：</div>
                  <div class="left" [innerHtml]="work.description | textformat"></div>
              </div>
              <!-- 图片 -->
              <div class="model-text clear" *ngIf="work.image_url && work.image_url.length>0">
                  <div class="left">作品图片：</div>
                  <div class="model-pic">
                    <img *ngFor="let url of work.image_url;let num=index" src="{{url+'?imageView2/1/w/200/h/160'}}" >
                  </div>
          </div>
      </div>

      <!--培训经历-->
      <div class="resume-model-block" *ngIf="resumeDetails.Training_Experience && resumeDetails.Training_Experience.length>0">
          <div class="model-title"><b></b><span>培训经历</span><b></b></div>
          <div class="model-cont" *ngFor="let project of resumeDetails.Training_Experience;let index=index">
              <div class="model-text clear">
                  <div class="left">培训课程：{{ project.course }}</div>
                  <!--时间-->
                  <div class="right" *ngIf="project.end_date && !project.isEndDate">培训时间：{{project.start_date|date:'yyyy.MM'}} — {{project.end_date|date:'yyyy.MM'}}</div>
                  <div class="right" *ngIf="!project.end_date || project.isEndDate">培训时间：{{project.start_date|date:'yyyy.MM'}} — 至今</div>
              </div>
              <div class="model-text">培训机构：{{ project.name }}</div>
          </div>
      </div>

      <!--奖杯证书-->
      <div class="resume-model-block" *ngIf="resumeDetails.certificates && resumeDetails.certificates.length>0">
          <div class="model-title"><b></b><span>获奖证书</span><b></b></div>
          <div class="model-cont" *ngFor="let cer of resumeDetails.certificates;let index=index">
              <div class="model-text clear">
                  <div class="left">证书名称：{{ cer.name }}</div>
                  <!--时间-->
                  <div class="right">获奖时间：{{cer.get_date|date:'yyyy-MM-dd'}}</div>
              </div>
              <div class="model-text" *ngIf="cer.awarding_body">颁发机构：{{ cer.awarding_body }}</div>
          </div>
      </div>



    </div>

    <!-- <div style="height: 60px;"></div> -->
  </div>
</div>
