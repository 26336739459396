import {Injectable, EventEmitter, OnInit} from "@angular/core";
import { Observable, throwError, of } from 'rxjs';
@Injectable()
export class NotEmitTool implements OnInit{
  public event:EventEmitter<any> = new EventEmitter<any>();


  constructor() {
    // 定义发射事件
    this.event = new EventEmitter();
    //emit()：发射,subscribe()：接收;unsubscribe()：取消接收
    // this.event = new Observable();
  }
  ngOnInit() {

  }
}
