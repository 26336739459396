import { Pipe, PipeTransform } from '@angular/core';
// import { DicData} from '../provider/index';

@Pipe({name: 'codetransform'})
export class CodeTransform implements PipeTransform {
  constructor(){
  }  
  transform(_content,dataList) {    
    let text:any;
    // console.log(dataList);
    for(let val of dataList){
      if(val.code == _content){
         text = val.text;
      }
    }
  return text;  
  }
}