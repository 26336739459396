import { Component,EventEmitter, Input, Output,ViewChild,ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataApi,Constants,Config,Local} from '../../provider/index';

@Component({
  selector: 'app-model-company-phone',
  templateUrl: './model-company-phone.component.html',
  styleUrls: ['./model-company-phone.component.css']
})
export class ModelCompanyPhoneComponent {
  public config: Config;
  @Input() isShow: false;//是否显示弹窗页面
  public userData:any;//用户信息
  public userMobile="";//用户手机号码
  public model={
    mobile:'',
    vcode_text:''
  };//新手机号和验证码
  public countDown:any;//倒计时
  public isSendOver = false;//是否发送过验证码
  public isSendIng = false;//是否为正在发送中
  public timer:any;//定时器
  public myUser:any;//hr信息
  constructor(public toastr: ToastrService,
    public local:Local,
    public dataApi: DataApi) {this.config = Config.getInstance();}
  @Output() onVoted = new EventEmitter();
  dismiss(result: any) {
    //console.log("传值检查");
    //console.log(result);
    clearInterval(this.timer);
    this.onVoted.emit(result);
  }

  //初始化数据
  initData(){
    this.getUser();//获取用户信息
    this.model={
      mobile:'',
      vcode_text:''
    };//新手机号和验证码
    this.countDown=0;//倒计时
    this.isSendOver = false;//是否发送过验证码
    this.isSendIng = false;//是否为正在发送中
  }

  //获取用户信息
  getUser(){
    if(this.dataApi.getLocalLoginUser()){
      //console.log("本地存储hr信息-----------");
      //console.log(this.dataApi.getLocalLoginUser());
      this.myUser = this.dataApi.getLocalLoginUser();    
      this.userMobile = this.myUser.mobile;
    }else{
      this.dataApi.getUser().then((res:any)=>{
        //console.log("线上数据hr信息--------");
        //console.log(res);
        if(res){
          this.myUser = res;
          this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量    
          this.userMobile = this.myUser.mobile;    
        }
      }).catch((error)=>{
        this.toastr.error(error);
      });
    }  
  }

  //更换手机号
  getMobileChange(){
    if(!this.model.mobile){
      this.toastr.warning("请填写新手机号");
      return;
    }

    if(!this.model.vcode_text){
      this.toastr.warning("请填写验证码");
      return;
    }else if(String(this.model.vcode_text).length<4){
      //console.log( typeof(this.model.vcode_text));
      let n=4-String(this.model.vcode_text).length;
      let textNum="0000";
      this.model.vcode_text=textNum.substring(0,n)+this.model.vcode_text;
      //console.log(this.model.vcode_text);
    }
    this.dataApi.userChangeMobile(this.model).then((res:any)=>{
      //console.log(res);
      this.toastr.success("修改成功");
      this.myUser.mobile=this.model.mobile;
      this.dataApi.setLocalLoginUser(this.myUser);//将user信息存到本地、全局变量   
      this.dismiss(true);
    }).catch((err)=>{
       //console.log(err);
      //  this.dismiss(false);
       this.toastr.error(err);
      //  this.toastr.error("修改失败");
    })
  }

  // 发送验证码(+验证手机号是否可用)
  postMobileSmsVcode () {    
    if(!this.model.mobile || this.isSendIng){
      return;
    }
    if(this.userMobile==this.model.mobile){
      this.toastr.warning("新手机号不得与原有手机号码一致");
      return;
    }
    this.isSendIng = true;
    let phoneModel={
      format_type:0,//0:手机号，1：手机号&固话；2.邮箱
      data:this.model.mobile
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(res.value){
        this.sendPhoneCode();//发送验证码
      }else{
         this.toastr.warning('手机号码格式不正确');
         this.model.mobile='';
         this.isSendIng = false;
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.warning(err);
    })    
  };

  // 发送验证码(+验证手机号是否可用)
  sendPhoneCode(){    
    this.dataApi.postMobileSmsVcodeApp({//获取验证码
      value: this.model.mobile
    }).then((res:any)=>{    
      this.toastr.success('验证码发送成功！');
      let count = 60;
      this.countDown = count + 's';
      this.isSendOver = true;            
      this.timer = setInterval(()=>{
        count -= 1;
        this.countDown = count + 's';
        if (count === 0) {                
          clearInterval(this.timer);
          this.countDown = '';                
          this.isSendIng = false;
        }
      }, 1000);
      //console.log(res);

    }).catch((error)=>{
      //console.log(error);
      this.toastr.error("网络错误");
      this.isSendIng = false;
    });
  };





}
