import { Component,EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataApi } from '../../provider/index';

@Component({
  selector: 'app-model-position-template',
  templateUrl: './model-position-template.component.html',
  styleUrls: ['./model-position-template.component.css']
})
export class ModelPositionTemplateComponent{
  @Input() isShow: false;//是否显示弹窗页面
  public initvalue = [];
  constructor(public dataApi: DataApi,public toastr: ToastrService) { }
  //------获取职位推荐模板
  getTemplate(type){
    this.dataApi.getPositionDescription(type).then((res:any)=>{
      //console.log("获取职位推荐模板");
      //console.log(res);
      if(res){
        this.initvalue = res;
      }
    }).catch((err)=>{
     this.toastr.error(err);
    })
  }
//------选中模板
closeTemplate(val){
  this.dismiss(val);
}
@Output() onVoted = new EventEmitter();
dismiss(result: any) {
  //console.log("传值检查");
  //console.log(result);
  this.onVoted.emit(result);
} 

}
