import { Component, OnInit, Input, Output } from '@angular/core';
import { Events } from '../../provider/eventService';
import { DataApi,Config,Opera,Local,Constants } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from  '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-college-details',
  templateUrl: './college-details.component.html',
  styleUrls: ['./college-details.component.css']
})
export class CollegeDetailsComponent implements OnInit {
  public config: Config;
  public isInit = false;//是否初始化成功
  public myDepartment:any;//公司信息
  public model={
    collegeid:'',
    business_licence:'',//企业营业执照
    id_card_front:'',//身份证正面
    register_number:''//营业执照注册号
  }
  public college={
    collegeid:'',
    logo:'',
    name:'',
    common_name:'',
    school_type_text:'',
    code:'',
    establish_time:'',
    email:'',
    region_text:'',
    is_subscribe:false,
    intro:'',
    has_milkround:false,
    campustalk_switch:false,
    audit_switch:{faculty_authcation:false},
    work_info:{
      office_tel:''
    }
  };//高校信息

  @Output() modelShow = false;//是否打开弹窗
  @Output() collegeName = '';//高校名称
  @Output() collegeIdentity:any;//高校认证信息
  @Output() orgList=[];//院系列表
  constructor( public dataApi: DataApi,
    public events: Events,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public local:Local,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
      activatedRoute.queryParams.subscribe(queryParams => {
        if(queryParams.college){
          this.college  = JSON.parse(queryParams.college);
          this.local.set(Constants.collegeid,this.college.collegeid);
          this.checkDepartmentIndentityByCollege(this.college.collegeid);//检测企业是否通过当前高校认证
          this.collegeName = this.college.name;
          ////console.log("高校信息");
          ////console.log(this.college);
          this.isInit = true;
        }
        if(queryParams.code){
          this.getCollegeByCode(queryParams.code);
        }
        if(queryParams.item){
          this.getCollegeById(queryParams.item);
        }
      })

      if(!this.college.collegeid){
        //----通过高校id获取高校详情
        this.getCollegeById(this.local.get(Constants.collegeid));//获取高校详情
        this.checkDepartmentIndentityByCollege(this.local.get(Constants.collegeid));//检测企业是否通过当前高校认证
      }
    }

  ngOnInit() {
    this.opera.initConfig('college','college-campustalk');
    this.getDepartment();//获取企业信息
  }

  //去教学机构
  goOrg(){
    this.local.set(Constants.collegeid,this.college.collegeid);
    this.router.navigateByUrl("home/college-details-org");
  }
  //去双选会
  goMilk(){
    this.router.navigateByUrl("home/college-details-milk");
  }
  //去宣讲会
  goCampus(){
    this.router.navigateByUrl("home/college-details-campus");
  }
  //去新闻公告
  goNews(){
    this.router.navigateByUrl("home/college-details-news");
  }
  //去生源速览
  goStudent(){
    this.router.navigateByUrl("home/college-details-student");
  }

  //判断企业是否通过高校认证
  checkDepartmentIndentityByCollege(id){
    this.dataApi.lastCollegeAuthenticationIdentity(id).then((res:any)=>{
      //console.log("获取企业在高校的认证信息-----------------");
      //console.log(res);
      this.collegeIdentity=res;
    }).catch((err)=>{
      ////console.log(err);
    })
  }



  //获取企业信息
getDepartment(){
  if(this.dataApi.getLocalDepartment()){
        this.myDepartment = this.dataApi.getLocalDepartment();
        ////console.log("企业信息本地存储---------------------------CollegeIdentityPage");
        ////console.log(this.dataApi.getLocalDepartment());
      }else{
        this.opera.loading();
        this.dataApi.getDepartment().then((res:any)=>{
          ////console.log("企业信息获取线上数据---------------------------CollegeIdentityPage");
         ////console.log(res);
         this.dataApi.setLocalDepartment(res);
         this.opera.loadiss();
      }).catch((error) => {
         ////console.log(error);
         this.toastr.error(error);
         this.opera.loadiss();
     });
  }

 }

  //获取申请校招返回值
  Modeldismiss(event){
    ////console.log('子组件返回结果-----college-details');
    ////console.log(event);
    this.opera.scrollTrue();
    if(event){
      if(event === true){
        this.local.remove(Constants.facultyid);
      }else{
        this.local.set(Constants.facultyid,event);
      }
      //跳转认证页面
      this.local.set(Constants.collegeid,this.college.collegeid);
      this.router.navigateByUrl("/home/company-identity-details");
      this.modelShow = false;
    }else{
      this.modelShow = false;
    }

  }

  //提交校招申请
  goCollegeIdentity(){
    // this.collegeName = '';
    if(this.college.audit_switch.faculty_authcation){//是否打开院系认证审核开关
      this.collegeName = '';
    }
    this.modelShow = true;//打开校招申请确认弹窗
    this.opera.scrollTop();//页面滚动函数
  }

  //通过高校代码获取高校详情
  getCollegeByCode(code){
    this.opera.loading();
    this.dataApi.getCollegeByCode(code).then((res:any)=>{
      ////console.log("获取到高校信息--------------------------CollegeHome");
       ////console.log(res);
       this.college = JSON.parse(JSON.stringify(res));
       this.local.set(Constants.collegeid,this.college.collegeid);
       if(res.audit_switch.faculty_authcation){
        this.getOrgList(res.collegeid);
       }
       this.collegeName = this.college.name;
       this.isInit = true;
       this.checkDepartmentIndentityByCollege(this.college.collegeid);
       this.opera.loadiss();
     }).catch((err)=>{
       this.toastr.error("该高校暂未注册工作啦账号");
       this.isInit = true;
      //  history.go(-1);//返回上一页
       this.router.navigateByUrl('/home/college-campustalk');//返回上一页
       this.opera.loadiss();
       ////console.log(err);
     })
  }

  //通过高校id获取高校详情
  getCollegeById(id){
    this.opera.loading();
    this.dataApi.getCollageDetails(id).then((res:any)=>{
      console.log("获取到高校信息--------------------------CollegeHome");
      console.log(res);
      this.isInit = true;
      this.college = JSON.parse(JSON.stringify(res));
      if(res.audit_switch.faculty_authcation){
        this.getOrgList(res.collegeid);
      }
      // this.getOrgList(res.collegeid);
      this.collegeName = this.college.name;
      this.checkDepartmentIndentityByCollege(this.college.collegeid);
      this.opera.loadiss();
    }).catch((err)=>{
      this.toastr.error(err);
      this.isInit = true;
      this.opera.loadiss();
      this.router.navigateByUrl('/home/college-campustalk');//返回上一页
    });
  }

  //获取列表
  getOrgList(id){
    this.opera.loading();
    this.dataApi.getCollegePart(id).then((res:any)=>{
      // console.log("获取院系列表---");
      // console.log(res);
      this.orgList = res;
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
      this.toastr.error("获取院系列表失败，请稍后重试");
    })
  }

  assembleHTML(strHTML:any) {
    return this.sanitizer.bypassSecurityTrustHtml(strHTML);
  }

  //订阅高校公众号
  getCollegeAccount(college){
    this.opera.CollegeRead(college);
  }




}
