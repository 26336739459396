import { Component } from '@angular/core';
import { Router,ActivatedRoute,NavigationEnd } from  '@angular/router';

import { Events } from '../provider/eventService';
import { ToastrService } from 'ngx-toastr';
import { DataApi,Local,Constants,Config,Opera,MyMMessage } from '../provider/index';
import { ConfirmComponent } from './confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import { timeout } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public config: Config;
  public isStop=false;//是否停止定时器
  constructor(
               private router: Router,
               public mimc:MyMMessage,
               public local: Local,
               public events: Events,
               public dataApi: DataApi,
               public toastr: ToastrService,
               public opera:Opera,
               private activatedRoute: ActivatedRoute,
               public dialog: MatDialog) {
    this.config = Config.getInstance();//实例化config
    activatedRoute.queryParams.subscribe(queryParams => {
      console.log("获取到路由参数 app.comment",queryParams,window.location.href);
      console.log(queryParams);
      if(queryParams.type){
        this.local.remove(Constants.byCollege);
        this.local.set(Constants.byCollege,queryParams.id);
        this.opera.goCollegePage(queryParams.type,queryParams.id,queryParams.mrId,queryParams.resumeId,queryParams.rs);
      }else{
        if(this.local.get(Constants.byCollegeDetails)){
          setTimeout(() => {
            this.config.isByCollege=true;
          });
          this.config.byCollegeLogo=JSON.parse(this.local.get(Constants.byCollegeDetails)).logo;
          this.config.byCollegeName=JSON.parse(this.local.get(Constants.byCollegeDetails)).name;
        }
      }
    });

    // 接收api.ts通知错误退出登录广播
    this.events.subscribe('user:created', () => {
        console.log("收到api.ts发送的退出登录广播app.ts");
        // this.events.unsubscribe('user:created');//取消订阅api.ts通知退出登陆事件
        let token = this.local.get(Constants.accesstoken)
        if(token){
            this.toastr.error("请重新登录");
        }
        this.setRoot();//不重新登录无法接收消息，踢到登录页
      // this.myJMessage.loginOut();//极光服务退出
   });
    //订阅拉黑报错
    this.events.subscribe('user:pullblack', () => {
      //console.log("收到api.ts发送的退出登录广播");
      this.toastr.error("账号被拉黑，暂不支持使用");
      this.setRoot();
      this.dialog.open(ConfirmComponent, {
        data: {
          title: '提示',
          message: `由于您的账号涉及违规，不支持使用！如有异议，请准备好相关材料和信息发送邮件到service@gowork.la进行申诉，主题备注“申诉+注册手机号）”`,
          trueText:'确定',
          falseText:'取消'
        }
      }).afterClosed().subscribe((isConfirmed) => {
        this.dialog.closeAll()
      });
    });
    this.checkIsLogin();//初始化全局信息
    //检查token是否已经过期
    if(this.local.get(Constants.accesstoken)){
      this.checkTokenWeb();
    }
    // this.opera.isIE();

      // 定义鼠标事件处理函数
      let that = this;

      let _local_collegeid = localStorage.getItem('collegeid');
      if(_local_collegeid){
          this.config.current_Collegeid = _local_collegeid;
      }

      document.onmousedown = function(event){  // 按下鼠标时，初始化处理
         let o = event.target;  // 获取当前拖放的元素
          let local_collegeid = localStorage.getItem('collegeid')
          // console.log('onmousedown',that.config.current_Collegeid,local_collegeid)
          if(local_collegeid!=that.config.current_Collegeid){
              // 去登录
              that.config.isDoubleCollegeClearLoginInfo = true
              that.router.navigateByUrl("login?type=login&id="+local_collegeid);

          }

      }


  }
  //去账号管理
  goAccount(){
    this.router.navigateByUrl('/home/company-account');
  }
  //退出
  goLogin(){
    this.opera.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      
      data: {
        title: '提示',
        message: '您是否要退出登录？',
        trueText:'确定',
        falseText:'取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
          //console.log('检测是否存在返回值');
          //console.log(isConfirmed);
          this.opera.scrollTrue();
          if(isConfirmed){
            this.toastr.info("请重新登录");
            this.setRoot();//不重新登录无法接收消息，踢到登录页
          }
    });
  }

  //接收错误信息返回登录
  setRoot() {
      this.dataApi.logout();
      // this.myJMessage.loginOut();//极光服务退出
      let collegeid = this.local.get('collegeid') + '';
      let hash = location.hash;

      console.log('logoutsetRoot',collegeid,hash);
      if(hash.indexOf('/login')<0){
          if (collegeid !='false') {
              this.router.navigateByUrl("login?type=login&id="+collegeid);
          }else {
              this.router.navigateByUrl("login");
          }
      }

  }


  //获取企业信息
  getDepartment(){
    this.dataApi.getDepartment().then((res:any)=>{//通过tooken获取企业信息
      //console.log("获取公司信息---------------------------------------------appcomponent");
      //console.log(res);
      if(res){
        if(res.logo){
          let fops = '&imageView2/1/w/100/h/100';
          res.logo = res.logo+fops;
        }
        this.dataApi.setLocalAccountId(res.accountid);//将accounid存到本地、全局变量
        this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
        // this.getImInit();//极光登录
        this.mimc.init(res.jm_username);//登陆mimc
      }
    }).catch((error)=>{
      //this.toastr.error(error);
    });
  }

  //获取当前登录用户的信息(user信息)
  getUser(){
    this.dataApi.getUser().then((res:any)=>{//通过tooken获取企业信息
      //console.log("获取hr信息---------------------------------------------appcomponent");
      //console.log(res);
      if(res){
        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
      }
    }).catch((error)=>{
      //this.toastr.error(error);
    });
  }

  //获取企业认证信息
  getPlatformIdentity(){
    this.dataApi.getPlatformApply().then((res:any)=>{
      console.log("获取企业平台认证信息");
      console.log(res);
      if(res){
         if(res.status=='2'){
           this.config.department.is_certified=true;
           this.dataApi.setLocalDepartment(this.config.department);
         }
         this.config.lastIdentity=res;
         this.dataApi.setLastIdentity(this.config.lastIdentity);
      }
     }).catch((error)=>{
       console.log("获取企业平台认证信息出错");
       console.log(error);
     })
  }
  // 获取是否开通高级搜索
  getEmployment(){
    this.dataApi.getEmployment().then((res:any)=> {
        if(res) {
            if(res.quota.product_service) {
                if(res.quota.product_service.length>0){
                    this.config.searchStatus=true;
                    this.config.searchendTime=res.quota.product_service[0].endtime ;
                }else{
                    this.config.searchStatus=false;
                }
            }
        }
    }).catch((error) => {
      console.log(error);
      // this.tip.presentToast(error);
    });
}
  //获取高校最后一次认证信息
  lastAuthenticationIdentity(){
    this.opera.loading();
    this.dataApi.lastAuthenticationIdentity().then((res:any)=>{
        if(res) {
            this.config.lastCollegeIdentity=res;
            this.dataApi.setLastCollegeIdentity(this.config.lastCollegeIdentity);
        }
      this.opera.loadiss();
    }).catch((error)=>{
      ////console.log(error);
      this.opera.loadiss();
    })
  }

  //获取会话列表
  getImDialogueList(){
        this.dataApi.getImDialogueAllList({}).then((res:any)=>{
            if(res) {
                this.config.chatsMsgList=[];
                this.local.remove(Constants.chatsMsgList);//启动时清空聊天记录重新填充
                this.config.chatsList = res;//会话列表全局变量
                this.dataApi.setLocalChatsList(res);
                for(let n of res.list){
                    let model = {userID: "",dlid:"",cont:[]};//极光ID，会话ID，聊天记录
                    model.userID = n.dialogue_jmusername;
                    model.dlid = n.dlid;
                    this.config.chatsMsgList.push(model);
                    if(n.unread_count>0){
                        setTimeout(() => {
                            this.config.unReadNum=this.config.unReadNum+n.unread_count;
                        });
                    }
                }
                this.dataApi.setLocalChatsMsgList(this.config.chatsMsgList);//将聊天记录中的会话列表保存到本地、全局变量
            }

          //console.log("启动时填充聊天记录-----------app.component.ts");
          //console.log(this.config.chatsMsgList);
        }).catch((err)=>{
          //console.log(err);
        });
  }

  //获取地区字典
  getRegion(){
    this.dataApi.getDictDate('region').then((res:any)=>{
        if(res) {
            this.dataApi.setRegionCode(res);
        }
    }).catch((error)=>{
       //console.log(error);
    });
  }
  //获取职位列表
   //-------获取线上数据
   getNewList(){
    this.dataApi.getPositionList({limit:100,offset:0,status:'release'}).then((res:any)=>{
        if(res) {
            this.dataApi.setLocalpositionList(res.list);
        }
     }).catch((error) => {
       //this.toastr.error(error);
     });
   }

  //初始化全局信息
  checkIsLogin() {
      // console.log('获取本地accesstoken');
      // console.log(this.local.get(Constants.accesstoken));
      // console.log(localStorage.getItem('accesstoken'));
      //console.log('获取本地accountid');
      //console.log(this.local.get(Constants.accountid));
      //console.log('获取本地loginName');
      //console.log(this.local.get(Constants.loginName));
      //'获取本地accesstoken和accountid
      this.config.token = this.local.get(Constants.accesstoken);
      this.config.accountid = this.local.get(Constants.accountid);
       //获取登录账号，方便出错登录
      this.config.loginName = this.local.get(Constants.loginName);
      //检测是否已经登录
      if(this.config.token&&this.config.accountid){
        this.getDepartment();//获取企业信息
        this.getUser();//获取当前登录用户的信息(user信息)
        this.getPlatformIdentity();//获取企业认证信息
        this.lastAuthenticationIdentity();//获取高校最后一次认证信息
        this.getImDialogueList();//获取会话列表
        this.getNewList();//获取职位列表
        this.getRegion();//获取地区字典
        this.getEmployment();//获取是否开通高级搜索
      }else{
        // this.setRoot();
        // if(this.activatedRoute.url.value[0].path!=='' && this.activatedRoute.url.value[0].path!=='login'){
        //   this.router.navigateByUrl("login");//返回登录页
        // }
        // this.router.navigateByUrl("login");//返回登录页
      }
  }

   //------检查token是否即将过期
  checkTokenWeb(){
    let timeout;//刷新token定时器
    if(this.local.get(Constants.refreshToken)){
      //console.log("打印刷新token数据");
      //console.log(this.local.get(Constants.refreshToken));
      this.config.refresh_token = JSON.parse(this.local.get(Constants.refreshToken));
      let time =Math.ceil((Number(this.config.refresh_token.exprid_time)-new Date().getTime())/(1000*3600));
      let time1 =Number(this.config.refresh_token.exprid_time)-new Date().getTime();
      // console.log("token过期时间");
      // console.log(time);
      // console.log(time1);
      if(time<5){
        if(time<0){
          this.toastr.warning("请重新登录");
          this.setRoot();
        }
        this.dataApi.postCodeTradeToken({
         "grant_type": "refresh_token",
         "refresh_token": this.config.refresh_token.refresh_token
        }).then((res:any)=>{
          if(res){
             //console.log(res);
             let refreshToken ={
              refresh_token:res.refresh_token,//给全局变量赋值----刷新token
              exprid_time:new Date().getTime()+Number(res.expires_in)*1000//token过期时间(毫秒)
             }
             this.dataApi.setRefreshToken(refreshToken);
             this.dataApi.setLocalToken(res.access_token);
          }
        }).catch((error)=>{
          //console.log(error);
          setTimeout(() => {
           //this.toastr.error(error);
          },600);
        });
      }else{
        //判断是否需要清除定时器
        if(this.isStop){
          window.clearTimeout(timeout);
        }
        //声明定时器
        timeout=setTimeout(()=>{
          this.refreshTokenFunction();
          this.isStop=true;
        },time1);
      }
    }
  }
  //刷新token
  refreshTokenFunction(){
      if(Constants.refreshToken) {
          this.dataApi.postCodeTradeToken({
              "grant_type": "refresh_token",
              "refresh_token": Constants.refreshToken
          }).then( async (res:any)=>{
              if(res){
                  //console.log(res);
                  let refreshToken ={
                      refresh_token:res.refresh_token,//给全局变量赋值----刷新token
                      exprid_time:new Date().getTime()+Number(res.expires_in)*1000//token过期时间(毫秒)
                  }
              }
          }).catch((error)=>{
              this.toastr.error('请重新登录');
              this.setRoot();
          });
      }
  }
}
