import { Component, OnInit, Input, Output,ViewChild } from '@angular/core';
import {DataApi, Local, Constants, Config, Opera, milkround} from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { ModelCollegeMilksignComponent } from '../model-college-milksign/model-college-milksign.component';
@Component({
  selector: 'app-position-milk',
  templateUrl: './position-milk.component.html',
  styleUrls: ['./position-milk.component.css']
})
export class PositionMilkComponent implements OnInit {
  @ViewChild('milkSign', { static: true }) child: ModelCollegeMilksignComponent;
  public config: Config;
  public keywordList=[];//搜索关键词匹配列表
  public milkRoundList = [];//双选会列表
  public posterUrl = ''; // 双选会海报下载链接

   // 筛选条件
   public model={
    college_name:'',//学校名称
    status:'',//状态
    offset:0,
    limit:9,
     collegeid:''

  }
  public countList = 0;//列表数量

  public collegeid = "";


  //确认参会参数
  public QrCodeURL = "";// 通过后可分享的二维码双选会信息
  public showConfirmMilkroundModel = false;
  public currentMilkround = null;
  public showMilkCode = false;
  public revokeModule = false;
  public revokeApplyid = '';

  @Output() modelShow = false;//是否打开弹窗
  @Output() mridList=[];//招聘会id列表
  @Output() milkNumber = 1;//当前申请的招聘会场次
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
              public milkroundFormat:milkround,

              public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {
    if(this.local.get(Constants.collegeid))
    {
      this.model.collegeid = this.local.get(Constants.collegeid);
      this.dataApi.lastCollegeAuthenticationIdentity(this.model.collegeid).then((res:any)=>{
        console.log('lastCollegeAuthenticationIdentity',res);
        if(res){
          // this.config.lastCollegeIdentity=res;
          if(res.status==3){
            this.router.navigate(['home/collegeidentity'],{
              queryParams: {
                isShow:true
              }
            });
          }
        }
        this.opera.loadiss();
      }).catch((error)=>{
        //console.log(error);
        this.opera.loadiss();
      })
    }
    // this.opera.initConfig('position','position-milk');
    this.opera.initConfig('college', 'college-details-milk');

    this.getCollegeCode();//获取高校字典
    this.getMilkList();//获取双选会列表
  }

  //修改申请
  goUpdate(n){
    //console.log('修改申请--------------');
    //console.log(n.applyid);
    this.local.set(Constants.applyid,n.applyid);
    this.router.navigateByUrl('/home/college-milk-sign');
  }
  //进入会场
  goSpace(id){
    this.router.navigateByUrl('home/college-milk-space');
    this.local.set(Constants.mrid,id);//覆盖本地存储招聘会id
  }

  //去报名（再次申请）
  goSign(n){
    this.dataApi.checkDepCollegeIdentity(n.collegeid).then((res:any)=>{
      //console.log("获取企业在高校的认证信息-----------------------CampusDetails");
      //console.log(res);
      if(res){
        if(res.gwl_status=='2'){//通过工作啦认证
          this.goMilkSign(n);//报名
        }else{//未通过工作啦审核
          if(res.is_activity){//活动高校
            if(res.count>0){//通过1家以上高校校招
              this.goMilkSign(n);//报名
            }else{//提示企业认证
              // this.opera.remindDepartmentIdentity(this.milk.collegeid,this.collegeIdentity,this.navCtrl);
              this.opera.goIdentityDetails(n.collegeid);
            }
          }else{//非活动高校
            if(res.status=='2'){//通过校招
              this.goMilkSign(n);//报名
            }else if(res.status=='1'){//校招审核中
              this.toastr.warning("校招审核中，审核通过后才可报名双选会");
              // this.opera.remindDepartmentIdentity(this.milk.collegeid,this.collegeIdentity,this.navCtrl);
            }else{//未提交校招申请
              // this.opera.remindCollegeIdentity("报名双选会",this.college.collegeid,this.collegeIdentity);
              this.opera.goIdentityDetails(n.collegeid);
            }
          }
        }
      }else{
        this.opera.goIdentityDetails(n.collegeid);
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    })

  }

  //去双选会报名页
  goMilkSign(n){
    if(n.scale <= n.department_participate_count ){
      this.toastr.warning('坐席已满，无法报名！');
      return
    }
    this.local.set(Constants.collegeid,n.collegeid);
    this.local.set(Constants.mrid,n.mrid);
    this.local.remove(Constants.applyid);
    this.router.navigate(['home/college-milk-sign'], {
      queryParams: {
        milkname:n.name
      }
    });
  }


  //去双选会详情
  goDeatils(id){
    this.router.navigate(['home/college-milkdetails'],{
      queryParams:{
        id:id
      }
    })

  }
 //筛选
  goSearch(text){
    this.model.college_name = text;
    this.getMilkList();//获取双选会列表
    this.keywordList=[];
  }

   //获取高校字典
   getCollegeCode(){
    // 获取高校字典数据
    if(this.config.school.length<1){
     this.dataApi.getDictDate('school').then((res:any)=>{
       // //console.log(res);
       if(res)
       {
         this.config.school=res;

       }
     }).catch((err)=>{
       // this.toastr.error("网络错误,请稍后重试");
       //console.log(err);
     })
   }
 }

 //匹配关键字列表
 getItems(){
  this.keywordList=[];
  if(!this.model.college_name){return;}
  for(let s of this.config.school){
    if(s.text.indexOf(this.model.college_name) >= 0){
       this.keywordList.push(s);
    }
  }
}
//监听键盘事件
inputKeyup(event){
  let that = this;
  var keycode = window.event?event.keyCode:event.which;
  if(keycode==13){
    that.goSearch(that.model.college_name);
  }else{
    that.getItems();
  }
}

  //获取双选会列表
  getMilkList(){
    this.opera.loading();
    this.collegeid = this.local.get(Constants.collegeid);//赋值高校id
    this.dataApi.getDepartmentSignMilkList(this.model).then((res:any)=>{
      this.opera.loadiss();
      if(res) {
        let newList =  this.milkroundFormat.formatList(res.list);
        this.milkRoundList = newList;
        console.log("获取双选会列表-----college-campustalk",newList);
        this.countList=res.count;
        this.pageCount =Math.ceil(res.count/9);
      }
    }).catch((err)=>{
      //console.log(err);
      this.opera.loadiss();
    })
  }

  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----college-campustalk');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getMilkList();//获取列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getMilkList();//获取列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getMilkList();//获取列表
      }
    }
  }

    // 获取双选会海报下载链接
    downloadPoster(applyid){
      this.opera.loading();
      this.dataApi.getMilkPoster(applyid).then((res:any)=>{
        if(res.value){
          let b = window.open();
          setTimeout(function(){
            b.location.href = res.value;
          }, 500);
          this.opera.loadiss();
        }
      }).catch((err)=>{
        console.log(err);
        this.opera.loadiss();
      })
    }

    downloadIamge(imgsrc, name) {//下载图片地址和图片名
      let image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function() {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        let a = document.createElement("a"); // 生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    }

  //确认才会逻辑
  /**
   * 确认参会按钮
   * @param n
   */
  okCanhui(n) {
    if(n.milkround_type == 2)
    {
      this.local.set(Constants.mrid, n.mrid);//覆盖本地存储招聘会id
      this.router.navigateByUrl('home/college-milk-space');
      return;
    }
    this.QrCodeURL = "";
    this.showConfirmMilkroundModel = true;
    this.currentMilkround = n
  }

  //已经参会
  ConfirmMilkroundCommit(type) {
    let data = {
      mrid: this.currentMilkround.mrid,
      type: type,
    };
    this.dataApi.confirmMilkround(data, this.currentMilkround.mrid, type).then((res:any)=> {

      console.log("确认参会", res);
      this.showConfirmMilkroundModel = false;
      if (type == "no_join") {
        this.toastr.error("请合理安排时间，如经常不参加双选会将对您企业的信用造成损失")
      } else {
        this.toastr.success("已确认参加成功")
      }
      for (let index = 0; index < this.milkRoundList.length; index++) {
        let item = this.milkRoundList[index];
        if (item.mrid == this.currentMilkround.mrid) {
          item.feedback_status = type;
        }
      }
      console.log('已确认参会回执',this.milkRoundList)
      if (type == "join") {
        this.currentMilkround.feedback_status = 'join';
        this.QrCodeURL = res.value;
        this.showMilkCode = true;
      }

    }).catch((err) => {
      console.log("确认参会失败", err);
      this.toastr.error(err)

    })

  }
  //关闭确认参会按钮的二维码
  closeModel(){
    this.QrCodeURL = "";
    this.showMilkCode = false;

  }
  ConfirmMilkroundCommit2(item){

    console.log('item',item)
    let type= 'join'
    let data = {
      mrid:item.mrid,
      type:type,
    };
    this.dataApi.getMilkroundFeedBackPost(item.mrid).then((res:any)=>{

      this.toastr.success("已确认参加成功")

      if(res.value)
      {
        this.QrCodeURL = res.value;
        this.showMilkCode = true;
      }

    }).catch((err)=>{
      console.log("确认参会失败",err);

    })
  }
  //去其他的双选会，工作啦企业后端的页面
  gotoOtherCampus(){
    if(this.local.get('accesstoken')) {
      let token = this.local.get('accesstoken');
      let accountid = this.local.get('accountid');
      let collegeid = this.local.get('collegeid');
      window.open(this.config.JumpMainWeb+token+'&accountid='+accountid+'&route='+'college-milkround','_blank')
    }
  }

   // 撤回
   openRevoke(applyid){
    this.revokeApplyid = applyid;
    this.revokeModule = true;
  }

  downMilk(){
    this.dataApi.revokeMilk(this.revokeApplyid).then((res:any)=>{
      this.getMilkList();
      this.revokeModule = false;
    }).catch((err)=>{
      console.log("downMilk",err);
    });
  }

}
