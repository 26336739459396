<!-- 举报 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>
    <div class="model-cont">
        <!-- 上层提示部分 -->
        <div class="model-head clear">
            <div class="left">请选择举报原因</div>
            <button class="right" (click)="dismiss(false)">×</button>
        </div>
        <!-- 企业常见问题 -->
        <div class="div-common">
            <div (click)="dismiss('广告')" class="div-list">广告</div>
            <div (click)="dismiss('违法/敏感内容')" class="div-list">违法/敏感内容</div>
            <div (click)="dismiss('招聘信息虚假')" class="div-list">招聘信息虚假</div>
            <div (click)="dismiss('人身攻击')" class="div-list">人身攻击</div>
            <div (click)="dismiss('其他')" class="div-list">其他</div>
        </div>  
    </div>
  </div>