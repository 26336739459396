<!-- 微信支付 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>
    <div class="model-cont">
      <!-- 上层提示部分 -->
      <div class="model-head clear">
          <div class="left">微信支付</div>
          <button class="right" (click)="dismiss(false)">×</button>
      </div>
      <!-- 中间内容部分 -->
      <div class="model-content">
          <div class="code-div">
              <qr-code [value]="payCode" [size]="190"></qr-code>
              <img *ngIf="isRefresh" (click)="goRefresh()" src="assets/images/pay-refresh.png">
          </div>
           使用微信扫一扫付款
      </div>
    </div>
  </div>
