import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'agetransform'})
export class AgeTransform implements PipeTransform {
  constructor(public _sanitizer: DomSanitizer){

  }  
  transform(min,max) {   
    if(min && max){
      if(Number(min)>0 && Number(max)>0){
        
        //最小值大于最大值的情况(特殊情况)      
        if(Number(min)>Number(max)){
          let minCopy = min;
          let maxCopy = max;
          min = maxCopy;
          max = minCopy;
        }

        if(Number(min)==1 && Number(max)==61){
          return '年龄：不限';
        }else if(Number(min)!=1 && Number(max)==61){
          return Number(min)+'岁以上';
        }else if(Number(min)==1 && Number(max)!=61){
          return Number(max)+'岁以下';
        }else{
          return Number(min)+'岁-'+Number(max)+'岁';
        }
      }else{
        return '不限';
      }

    }else{
      return '不限';
    }
    
    
  }
}