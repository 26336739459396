<!-- 职位模板 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>
    <div class="model-cont">
        <!-- 上层提示部分 -->
        <div class="model-head clear">
            <div class="left">请选择职位模板</div>
            <button class="right" (click)="dismiss(false)">×</button>
        </div>
        <!--没有模板时-->
        <div class="none-cont" *ngIf="initvalue.length<=0">    
          <img class="none-pic" src="assets/images/resume-search-fail.png">
          暂时没有职位描述模板
        </div>
        <div class="publish-position-list" *ngFor="let value of initvalue;let index=index">
            <div class="text-box" (click)="closeTemplate(value)">
               <div class="text-block" style="margin-bottom: 10px;font-size: 1.5rem;color: #5d479a;">模板{{index+1}}</div>
               <div class="text-block" [innerHtml]="value | textformat"></div>
            </div> 
        </div>
    </div>
  </div>