<!-- 页头部分 -->
<div class="contentRight-header">校园招聘 - 校园+</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <!-- 遮罩层部分 -->
  <router-outlet></router-outlet>
  <app-model-college-identity [name]="collegeName" [orgList]="orgList" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-identity>
  <div class="college-block">
    <!-- 页头部分 -->
    <div class="college-block-head">
       学校列表
    </div>
    <!-- 操作部分 <img src="assets/images/select-img.png">-->
    <div class="college-block-opera clear">
      <div class="right">
          <!-- <div class="select-all">全选</div>
          <button>批量申请</button>
          <button>我的申请记录</button> -->
           <!-- 选择所在城市 -->
          <span>筛选</span>
          <!-- 审核状态 -->
          <select name="city" id="city" [(ngModel)]="model.status" (ngModelChange)="getCollegeList()" autocomplete="off">
            <option class="place-text" value='' disabled hidden selected="selected">选择审核状态</option>
            <option class="place-text" value=''>不限</option>
            <option value=0>未申请</option>
            <option value=1>审核中</option>
            <option value=2>已通过</option>
            <option value=3>未通过</option>
          </select>
          <!-- 所在城市 -->
          <select name="city" id="city" [(ngModel)]="model.city" (ngModelChange)="getCollegeList()" autocomplete="off">
              <option class="place-text" value='' disabled hidden selected="selected">选择所在城市</option>
              <option class="place-text" value=''>不限</option>
              <option *ngFor="let city of hotCity" [value]="city.code">{{city.text}}</option>
          </select>
          <!-- 选择学校分类 -->
          <select name="type" id="type" [(ngModel)]="model.school_type" (ngModelChange)="getCollegeList()" autocomplete="off">
              <option class="place-text" value='' disabled hidden selected="selected">选择学校分类</option>
              <option class="place-text" value=''>不限</option>
              <option *ngFor="let type of config.school_type" [value]="type.code">{{type.text}}</option>
          </select>
          <!-- 选择学校性质 -->
          <select name="nature" id="nature" [(ngModel)]="model.school_nature" (ngModelChange)="getCollegeList()" autocomplete="off">
              <option class="place-text" value='' disabled hidden selected="selected">选择学校性质</option>
              <option class="place-text" value=''>不限</option>
              <option *ngFor="let nature of config.school_nature" [value]="nature.code">{{nature.text}}</option>
          </select>
          <!-- 选择学校特色 -->
          <select name="label" id="label" [(ngModel)]="model.labels" (ngModelChange)="getCollegeList()" autocomplete="off">
              <option class="place-text" value='' disabled hidden selected="selected">选择学校特色</option>
              <option class="place-text" value=''>不限</option>
              <option *ngFor="let label of config.school_label" [value]="label.code">{{label.text}}</option>
          </select>
      </div>
    </div>
    <!-- 没有内容存在时 -->
    <div *ngIf="countList==0" class="none-block">
        <img src="assets/images/resume-search-fail.png"><br/>
        暂未找到相关学校，换个关键词试试吧
    </div>
    <!-- 循环列表 -->
    <div class="college-list-cont" *ngIf="countList>0">
        <ul>
            <li>学校名称</li>
            <li>分类</li>
            <li>性质</li>
            <li>特色</li>
            <li>所在城市</li>
            <li>就业办电话</li>
            <li>校招审核</li>
            <li>操作</li>
        </ul>
        <div>
            <ul class="college-li" *ngFor="let n of collegeList;let i = index;">
                <li><span *ngIf='n.name'>{{n.name}}</span><span *ngIf='!n.name'>暂无</span></li>
                <li><span *ngIf='n.school_type_text '>{{n.school_type_text }}</span><span *ngIf='!n.school_type_text '>暂无</span></li>
                <li><span *ngIf='n.school_nature_text'>{{n.school_nature_text}}</span><span *ngIf='!n.school_nature_text'>暂无</span></li>
                <li>
                    <span *ngIf='n.labels&&n.labels[0]'>
                        <i *ngFor="let label of n.labels">{{label.text}}</i>
                    </span>
                    <span *ngIf='!n.labels||!n.labels[0]'>暂无</span>
                </li>
                <li><span *ngIf='n.region_text'>{{n.region_text}}</span><span *ngIf='!n.region_text'>暂无</span></li>
                <li><span *ngIf='n.work_info&&n.work_info.office_tel'>{{n.work_info.office_tel}}</span><span *ngIf='!n.work_info||!n.work_info.office_tel'>暂无</span></li>
                <li>
                    <span *ngIf='n.status==0'>未申请</span>
                    <span *ngIf='n.status==1'>审核中</span>
                    <span *ngIf='n.status==2'>已通过</span>
                    <span *ngIf='n.status==3'>未通过</span>
                </li>
                <li>
                    <span (click)="goDetails(n)">查看详情</span>
                    <span *ngIf='n.status == 0||n.status == 3' (click)="gocollegeIdentity(n)">申请高校合作</span>
                    <!-- <span (click)="goSigin(n)" *ngIf="n.campustalk_switch">申请宣讲会</span> -->
                    <span (click)="getCollegeAccount(n)">{{n.is_subscribe?'取消订阅':'订阅'}}</span>
                    <!-- <span>分享</span> -->
                </li>
            </ul>
        </div>
    </div>
    <!-- 分页部分 -->
    <div class='paging-block' [class.isPageHeight]="countList>0">
        <router-outlet></router-outlet>
        <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
    </div>
  </div>
</div>
