<!-- 添加公司实力 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
  <div class="black-bg" (click)="dismiss(false)"></div>
  <div class="model-cont">
      <!-- 上层提示部分 -->
      <div class="model-head clear">
          <div class="left">公司实力</div>
          <button class="right" (click)="dismiss(false)">×</button>
      </div>
      <!-- 中间内容部分 -->
      <div class="label-block">
        <!--可选标签  -->
        <div class="label-title-block"> {{index}}/9</div>
        <!-- 选择标签 -->
        <div class="select-choose-block clear" *ngIf="labelAll.length>0">
            <div class="choose-list">
              <div class="choose-item"  *ngFor="let label of labelAll">
                <div class="choose-text">{{label.text}}</div>
                <div class="choose-delete" (click)="delectSkill(label)">x</div>
              </div>
            </div>
        </div>
        <!--推荐标签  -->
        <div class="label-title-block">推荐标签</div>
        <!-- 选择标签 -->
        <div class="label-list clear">
            <div class="label-item" [class.isActive]="labels.isActive" *ngFor="let labels of config.strength" (click)="chooseConfigLabel(labels)">
               <div>{{labels.text}}</div>
            </div>
        </div>
      </div>
      <!-- 底部按钮 -->
      <div class="model-foot"><button class="purple" (click)="getLabel()">保存</button></div>
  </div>
</div>
