import { Component, OnInit, Input, Output } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
@Component({
  selector: 'app-college-details-news',
  templateUrl: './college-details-news.component.html',
  styleUrls: ['./college-details-news.component.css']
})
export class CollegeDetailsNewsComponent implements OnInit {
  public config: Config;
  // 筛选条件
  public model={
    columnid:'',
     offset:0,
     limit:9
  }
  public step=0;
  public menu:any;//公众号菜单
  public menuList=[];//二级菜单列表
  public articleList:any;//二级菜单列表
  public resumeList=[];//文章列表
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public collegeid="";//高校id
  public college:any;//高校详情
  public isInit=false;
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {
    this.opera.initConfig('college','college-campustalk');
    this.collegeid=this.local.get(Constants.collegeid);//赋值高校id
    if(this.collegeid)
    {
      this.getCollegeById();//获取高校详情
      this.getCollegeMenu();//获取公众号菜单
    }

  }

  //进入文章详情
  goDetails(at){
    // this.navCtrl.push('CollegeJobDetails',{item:at});
    this.local.set(Constants.articleid,at);
    this.router.navigateByUrl('home/college-details-newstxt');
  }

  //---------------获取公众号菜单
  getCollegeMenu(){
    this.opera.loading();
    this.dataApi.getCollegeMenu(this.collegeid).then((res:any)=>{
       this.menu=res;
       //console.log(this.menu);
       if(this.menu){
        for(let data of this.menu){
          if(data.button_type=='menu'){
              //console.log("获取到公众号菜单");
              //console.log(this.menu);
              //console.log(data.submenu);
              this.menuList=data.submenu;
              this.getResumeList();//获取文章列表
          }else if(data.button_type=='home'){
            this.collegeid=data.url;
          }
        }
      }
       this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
       //console.log(err);
    })
  }

  //点击筛选文章
  getList(i){
    if(this.step==i){
      return;
    }
    this.step=i;
    this.countList=0;
    this.getResumeList();//获取文章列表
  }

  //获取文章列表
  getResumeList(){
    this.model.columnid=this.menuList[this.step].url;
    this.opera.loading();
    this.dataApi.getCollegeJobList(this.model).then((res:any)=>{
     //console.log("文章列表------------------------------------collegeJob");
     //console.log(res);
     this.resumeList=res.list;
     this.countList=res.count;
     this.pageCount =Math.ceil(res.count/9);
     this.opera.loadiss();
    }).catch((err)=>{
      //console.log(err);
      this.opera.loadiss();
    })
  }

  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getResumeList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }
  }

  //通过高校id获取高校详情
  getCollegeById(){
    this.opera.loading();
    this.dataApi.getCollageDetails(this.collegeid).then((res:any)=>{
      //console.log("获取到高校信息---");
      //console.log(res);
      this.isInit = true;
      this.college = JSON.parse(JSON.stringify(res));
      this.opera.loadiss();
    }).catch((err)=>{
      this.toastr.error(err);
      this.isInit = true;
      this.opera.loadiss();
    });
  }

}
