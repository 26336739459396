import { Component, OnInit } from '@angular/core';
import {DataApi, Local, Opera} from "../../provider";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

declare let layui;
@Component({
  selector: 'app-air-camp-history',
  templateUrl: './air-camp-history.component.html',
  styleUrls: ['./air-camp-history.component.css']
})
export class AirCampHistoryComponent implements OnInit {

  public campList = [];
  public campCount = 0;
  constructor(
      public opera:Opera,
      public dataApi: DataApi,
      public local:Local,
      private router: Router,
      public toastr: ToastrService,
  ) { }

  ngOnInit() {
    // this.opera.initConfig('aircampus','airCampHistory');
    this.opera.initConfig('position','airCampHistory');

    this.getAircampustalkList(true,0)
  }

  getAircampustalkList(isFirst,page) {
    let data = {
      offset:0,
      limit:10
    }
    let collegeid =localStorage.getItem('collegeid');
    if(!collegeid) {
      return;
    }
    this.dataApi.getAircampustalkList(collegeid,data).then((res:any)=>{//通过tooken获取企业信息

      console.log('getAircampustalkList',res)
      if(res)
      {
        this.campCount = res.campCount
        this.campList= res.list;

        for(let n of this.campList)
        {

          if(n.college_audit_status==0)
          {
            n.status = "未提交"
          }
          else if(n.college_audit_status==1)
          {
            n.status = "审核中"
          }
          else if(n.college_audit_status==2)
          {
            n.status = "已通过"
          }
          else if(n.college_audit_status==3)
          {
            n.status = "已拒绝"
          }

        }
        if(!isFirst)
        {
          return;
        }
        let that = this;
        layui.use('laypage', function(){
          var laypage = layui.laypage;
          //执行一个laypage实例
          laypage.render({
            elem: 'resumePage' //注意，这里的 test1 是 ID，不用加 # 号
            ,count: res.count, //数据总数，从服务端得到
            limit:12,
            theme: '#5e4fff',
            layout:['prev', 'page', 'next','skip','count']
            ,jump: function(obj, first){
              //obj包含了当前分页的所有参数，比如：
              console.log("obj.curr",obj.curr); //得到当前页，以便向服务端请求对应页的数据。
              console.log(obj.limit); //得到每页显示的条数
              that.getAircampustalkList(false,obj.curr)
              //首次不执行
              if(!first){
              }
            }
          });
        });

      }
    }).catch((error)=>{
      this.toastr.error(error);
    });

  }

  gotoDetail(n){
    this.router.navigateByUrl("home/airCampHistoryDetail?acid="+n.acid);
  }


}
