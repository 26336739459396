<!-- 页头部分 -->
<div class="contentRight-header">校园招聘 - 在线招聘</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
        <router-outlet></router-outlet>
        <app-model-select-two #industry [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-select-two>
    <!-- 头部 -->
    <div class="college-resume-head">
        <div class="search-position left" *ngIf="!searchStatus">
            <!-- 按发布的职位筛选 -->
            <span>筛选&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <select name="position" id="position" [(ngModel)]="searchModel.positionid" (ngModelChange)="getRecommendList()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择职位</option>
                <option class="place-text" value='' selected="selected">不限</option>
                <option *ngFor="let a of positionList" [value]="a.positionid">{{a.name}}</option>
            </select>
            <select name="city-milk" id="city-milk"  [(ngModel)]="searchModel.collegeid" (ngModelChange)="getRecommendList()" autocomplete="off">
                    <option class="place-text" value='' disabled hidden selected="selected">选择学校</option>
                    <option class="place-text" value='' selected="selected">不限</option>
                    <option *ngFor="let list  of collegeList" [value]="list.collegeid">{{list.name}}</option>
                </select>
                <!-- <span (click)='goAdvancedSearch()'>&nbsp;&nbsp;&nbsp;&nbsp;开通高级搜索>></span> -->
        </div>
        <div class="search-position left" *ngIf="searchStatus">
            <!-- 按发布的职位筛选 -->
            <p>高级搜索&nbsp;&nbsp;&nbsp;&nbsp;</p>
            <div class="time">高级搜索有效期至：{{endTime | date:"yyyy-MM-dd"}}</div>
            <!-- 所属行业 -->
            <div class="industry-list" (click)="chooseIndustry()">
                <div *ngIf="!advanceModel.post_type" class="placholder-text">请选择选择所属行业</div>
                <div *ngIf="advanceModel.post_type" class="selected-text">{{ advanceModel.post_type | codetransform:config.industry}}</div>
            </div>
            <!-- 选择学校 -->
            <select name="city-milk" id="city-milk"  [(ngModel)]="advanceModel.collegeid" (ngModelChange)="getRecommendList()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择学校</option>
                <option class="place-text" value='' selected="selected">不限</option>
                <option *ngFor="let list  of collegeList" [value]="list.collegeid">{{list.name}}</option>
            </select>
                <select name="label" id="label" [(ngModel)]="advanceModel.school_label" (ngModelChange)="getRecommendList()" autocomplete="off">
                    <option class="place-text" value='' disabled hidden selected="selected">选择学校标签</option>
                    <option class="place-text" value=''>不限</option>
                    <option *ngFor="let label of config.school_label" [value]="label.code">{{label.text}}</option>
                </select>
                <select name="education" id="education" [(ngModel)]="advanceModel.education" (ngModelChange)="getRecommendList()" autocomplete="off">
                    <option class="place-text" value='' disabled hidden selected="selected">选择学历</option>
                    <option *ngFor="let a of educationList" [value]="a.code">{{a.text}}</option>
                </select>
                <select name="major" id="major" [(ngModel)]="majorOne" (ngModelChange)="majorOneChange()" autocomplete="off">
                    <option class="place-text" value='' disabled hidden selected="selected">选择一级专业</option>
                    <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
                </select>
                <select name="majorTwo" id="majorTwo" [(ngModel)]="advanceModel.major" (ngModelChange)="getRecommendList()" (click)="isShowTwo()" autocomplete="off">
                        <option class="place-text" value='' disabled hidden selected="selected">选择二级专业</option>
                        <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
                </select>
                <select name="city-milk" id="city-milk" autocomplete="off" [(ngModel)]="advanceModel.gender" (ngModelChange)="getRecommendList()">
                    <option class="place-text" value='' disabled hidden selected="selected">选择性别</option>
                    <option class="place-text" value='' selected="selected">不限</option>
                    <option value='01'>男性</option>
                    <option value='02'>女性</option>
                </select>
                <select name="city-milk" id="city-milk" autocomplete="off" [(ngModel)]="advanceModel.work_city" (ngModelChange)="getRecommendList()">
                    <option class="place-text" value='' disabled hidden selected="selected">选择期望工作城市</option>
                    <option class="place-text" value='' selected="selected">不限</option>
                    <option *ngFor="let city of hotCity" [value]="city.code">{{city.text}}</option>
                </select>
                <select name="city-milk" id="city-milk" autocomplete="off" [(ngModel)]="advanceModel.salary" (ngModelChange)="getRecommendList()">
                    <option class="place-text" value='' disabled hidden selected="selected">选择期望薪资</option>
                    <option class="place-text" value='' selected="selected">不限</option>
                    <option value="01">1K以下</option>
                    <option value="02">1K-2K</option>
                    <option value="03">2K-4K</option>
                    <option value="04">4K-6K</option>
                    <option value="05">6K-8K</option>
                    <option value="06">8K-10K</option>
                    <option value="07">10K-15K</option>
                    <option value="08">15K-25K</option>
                    <option value="09">25K以上</option>
                </select>
                <select name="status" id="status" [(ngModel)]="advanceModel.is_online" (ngModelChange)="getRecommendList()" autocomplete="off">
                    <option class="place-text" value='' disabled hidden selected="selected">选择状态</option>
                    <option class="place-text" value=''>不限</option>
                    <option value="true">在线</option>
                    <option value="false">不在线</option>
             </select>
                <!-- <button type="button" class="btn btn-primary purple" >搜索</button> -->
        </div>
        <div class="right">

                <!-- 选择所在城市[(ngModel)]="" -->
               <!-- <span>筛选</span>
               <select name="city-milk" id="city-milk" autocomplete="off" [(ngModel)]="model.city" (ngModelChange)="getRecommendList()">
                   <option class="place-text" value='' disabled hidden selected="selected">选择所在城市</option>
                   <option class="place-text" value='' selected="selected">不限</option>
                   <option *ngFor="let city of hotCity" [value]="city.code">{{city.text}}</option>
               </select>
               <select name="city-milk" id="city-milk" autocomplete="off" [(ngModel)]="model.gender" (ngModelChange)="getRecommendList()">
                    <option class="place-text" value='' disabled hidden selected="selected">选择性别</option>
                    <option class="place-text" value='' selected="selected">不限</option>
                    <option value='01'>男性</option>
                    <option value='02'>女性</option>
                </select> -->
               <!-- 搜索筛选 -->
               <!-- <div class="search-input right">
                    <input type="text" placeholder="请输入学校名称" [(ngModel)]="model.college_name" (keyup)="inputKeyup($event)"><span (click)="goSearch(model.college_name)"><img src="assets/images/search.png"></span>
                    搜索关键词匹配列表
                    <div class="keyup-block" *ngIf="keywordList.length>0">
                        <div class="keyup-list" *ngFor = "let key of keywordList" (click)="goSearch(key.text)">{{key.text}}</div>
                    </div>
               </div> -->
        </div>
    </div>
    <!-- 中间部分 -->
    <!-- 没有内容存在时 -->
    <div *ngIf="countList==0" class="none-block">
        <img src="assets/images/resume-search-fail.png"><br/>
        暂未找到相关简历，请先申请校招，审核通过后，才可浏览相关学校的学生简历
        <br/><button class="purple" (click)="opera.goNewRouter('college-campustalk')">我要申请校招</button>
    </div>
    <div *ngIf="countList>0" class="college-resume-cont">
        <ul>
            <li>头像</li>
            <li>姓名</li>
            <li>性别</li>
            <li>年龄</li>
            <li>学校</li>
            <li>专业</li>
            <li>学历</li>
            <li>技能标签</li>
            <li>匹配度</li>
            <li>在线状态</li>
            <li>操作</li>
        </ul>
        <div>
            <ul class="resume-li" *ngFor="let n of resumeList;let i = index;">
                <li>
                    <!-- <img *ngIf="n.logo" [src]="n.logo+'&imageView2/1/w/100/h/100'"><img *ngIf="!n.logo" src="assets/images/none.png"> -->
                    <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}">
                    <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png">
                    <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png">
                    <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png">
                </li>
                <li><span *ngIf='n.talent_name'>{{n.talent_name}}</span><span *ngIf='!n.talent_name'>暂无</span></li>
                <li><span *ngIf='n.gender_text'>{{n.gender_text}}</span><span *ngIf='!n.gender_text'>暂无</span></li>
                <li><span *ngIf='n.age'>{{n.age}}</span><span *ngIf='!n.age'>暂无</span></li>
                <li><span *ngIf='n.graduate_university'>{{n.graduate_university}}</span><span *ngIf='!n.graduate_university'>暂无</span></li>
                <li><span *ngIf='n.major_text'>{{n.major_text}}</span><span *ngIf='!n.major_text'>暂无</span></li>
                <li><span *ngIf='n.education_text'>{{n.education_text}}</span><span *ngIf='!n.education_text'>暂无</span></li>
                <li>
                    <span *ngIf='n.skills.length>0'>
                        <i *ngFor="let skill of n.skills">{{skill.text}}</i>
                    </span>
                    <span *ngIf='n.skills.length==0'>暂无</span>
                </li>
                <li><span *ngIf='n.matching'>{{n.matching}}%</span><span *ngIf='!n.matching'>暂无</span></li>
                <li><span *ngIf='n.last_online_time'>{{n.last_online_time | onlineTime}}</span><span *ngIf='!n.last_online_time'>暂无</span></li>
                <li>
                    <span (click)="goDetails(n)">查看简历</span>
                    <!--<span (click)="goChat(n,model.positionid)">{{n.jm_username?'继续沟通':'立即沟通'}}</span>-->
                    <span (click)="goCollect(n)">{{n.isfollow_reusme?'取消收藏':'收藏'}}</span>
                    <!-- <span>分享</span> -->
                </li>
            </ul>
        </div>
    </div>
    <!-- 分页部分 -->
    <div class='paging-block' [class.isPageHeight]="countList>0">
       <router-outlet></router-outlet>
       <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
    </div>


</div>
