<!-- 中间部分 -->
<div class="info-block" *ngIf="isShow">
    <!-- 营业执照 -->
    <div class="info-list info-logo">
      <div class="info-head">第一步&nbsp;&nbsp;营业执照</div>
      <label *ngIf="model.business_licence" class="uploadImg-label department-logo" for="uploadLoginAvatar">
        <img [src]="model.business_licence">
      </label>
      <label *ngIf="!model.business_licence" class="uploadImg-label" for="uploadLoginAvatar">
        <img src="assets/images/add-img.png"><br/>点击上传&nbsp;&nbsp;营业执照
      </label>
      <input class="uploadImg" id="uploadLoginAvatar" (change)="previewBusiness($event)" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
      <ul>
        <li>1. 上传营业执照名称必须与公司全称保持一致</li>
        <li>2. 请提供有效年检期内的《企业法人营业执照》副本</li>
        <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
        <li>4. 工作啦客服将会在 1 个工作日内审核</li>
      </ul>
    </div>
    <!-- 营业执照 -->
    <div class="info-list info-logo">
      <div class="info-head">第二步&nbsp;&nbsp;招聘者身份证</div>
      <label *ngIf="model.id_card_front" class="uploadImg-label department-logo" for="uploadGuideCard">
        <img [src]="model.id_card_front">
      </label>
      <label *ngIf="!model.id_card_front" class="uploadImg-label" for="uploadGuideCard">
        <img src="assets/images/add-img.png"><br/>点击上传手持身份证
      </label>
      <input class="uploadImg" id="uploadGuideCard" (change)="previewPic($event)" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
      <ul>
        <li>1. 上传身份证姓名必须与招聘联系人姓名保持一致</li>
        <li>2. 请提供有效期内的本人手持《身份证》照片</li>
        <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
        <li>4. 工作啦客服将会在 1 个工作日内审核</li>
      </ul>
    </div>
    <!-- 营业执照 -->
    <div class="info-list info-number">
        <div class="info-head">第三步&nbsp;&nbsp;统一社会信用代码</div>
        <input type="text" placeholder="请填写营业执照统一社会信用代码" [(ngModel)]="model.register_number" maxlength="18">
        <ul>
          <li>1. 填写的统一社会信用代码必须与营业执照保持一致</li>
          <li>2. 工作啦客服将会在 1 个工作日内审核</li>
        </ul>
    </div>
    <!-- 按钮 -->
    <div class="block-button">
      <button (click)="uploadDepartmentIdentity()">提交</button>
    </div>
  </div>