import { Component, OnInit } from '@angular/core';

import { Events, Subscription } from '../../provider/eventService';
import { DataApi, Config, Local, Constants, Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-college-resumedetails',
  templateUrl: './college-resumedetails.component.html',
  styleUrls: ['./college-resumedetails.component.css']
})
export class CollegeResumedetailsComponent implements OnInit {
  public config: Config;
  public isInit = true;//是否初始化成功
  public isCanFollow = false;//防止重复点击收藏按钮
  public isSearch = false;//是否从简历搜索列表进入
  //简历详情数据
  public resumeDetails = {
    talent_base: { name: '', accountid: '', personal_avater: '', collegeid: '' },
    job_target: {
      work_city: '',
      industry: '',
      career: '',
      address_text: '',
      education_text: '',
      work_experience_text: ''
    },
    education_backgrounds: [],
    work_experiences: [],
    project_experiences: [],
    Training_Experience: [],
    works: [],
    iscaninvite: false,
    is_buy: false,
    isfollow: false,
    jm_username: '',
    resumeid: '',
    resume_number: '',
    graduate_university: '',
    collegeid: '',
    delivery_info: null
  };
  public item: any;//接收的人才数据(上一个页面传递过来的少量人才数据)
  public match = 0;
  public positionDataList = [];//初始化在期职位类别列表
  public positionList = [];//初始化在期职位列表
  public positionDataListActive = [];//初始化在期职位列表(可供选择的职位名称)

  public careerTypeString = [];//期望职位的字符串数组

  public chatsList: any;//会话列表
  public myDepartment: any;//当前登录的企业信息(department)

  public userJmUsername = '';//对方的极光ID

  public isFirst = true;//是否是第一次沟通
  public workexp = [];//工作经验
  public works = [];//作品展示
  public project = [];//项目比赛
  public isHideWorkexp = true;
  public isHideWorks = true;
  public isHideProject = true;
  // public pro_skills=[];//通用技能
  public seat_number = '';//展位号
  public positionid = '';

  private chattoEvent:Subscription;

  constructor(
    public dataApi: DataApi,
    public events: Events,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public opera: Opera,
    public local: Local,
    public dialog: MatDialog,
    public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
    if (!this.local.get(Constants.collegeid)) {
      this.events.publish('user:created'); //发送退出登录选项
      return;
    }

    this.getImDialogueList();//获取会话列表
    this.lastAuthenticationIdentity();//---获取上次认证信息
    //接收到更新会话列表的广播
    this.chattoEvent  = this.events.subscribe('chatTo:contact', (data) => {
      if (data) {
        this.config.chatsList = data;
        // //console.log('会话列表更新');
        this.chatsList = data.list;

      }
    });
    activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.item) {
        this.item = queryParams.item;
      }

      if (queryParams.match) {
        this.match = queryParams.match;
      }

      if (queryParams.positionid) {
        this.positionid = queryParams.positionid;
        //console.log("获取到选中职位id-----------------college-resumedetails");
        //console.log(this.positionid);
      }

      if (queryParams.seat_number) {
        this.seat_number = queryParams.seat_number;
      }

      if (queryParams.resumeid) {
        this.resumeDetails.resume_number = queryParams.resumeid;
        this.getResumeNumView(queryParams.resumeid);//获取人才详情
        this.local.set(Constants.resumeid, queryParams.resumeid);
      }

    });

    if (this.positionList.length == 0) {
      this.getPositionList();//获取在期职位列表
    }

    if (!this.resumeDetails.resume_number) {
      //----通过简历编号获取简历详情
      this.getResumeNumView(this.local.get(Constants.resumeid));//获取人才详情
    }

    //获取企业信息
    this.getDepartment();

  }

  ngOnInit() {


    this.opera.initConfig('college', 'college-resume');
  }
  //获取在期职位列表
  getPositionList() {
    if (this.dataApi.getLocalpositionList()) {
      this.config.positionList = this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
      this.positionList = this.config.positionList;
    } else {
      //console.log("在期职位列表获取线上数据--college-resumeDetails");
      return this.dataApi.getPositionList({
        limit: 100,
        offset: 0,
        status: 'release',
      }).then((res:any)=> {
        if (res) {

          if (res.list.length == 0) {
            return;
          }
          for (let val of res.list) {
            let model = { text: '', code: '' };
            val.isFilterActive = false;
            model.code = val.post_type;
            model.text = val.post_type_text;
            this.positionDataList.push(model);
          }
          this.positionList = res.list;
          this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
        }

      }).catch((error) => {
        //console.log(error);
      });
    }
  }


  //获取企业信息
  getDepartment() {
    if (this.dataApi.getLocalDepartment()) {
      this.myDepartment = this.dataApi.getLocalDepartment();
      //console.log("企业信息本地存储");
      //console.log(this.myDepartment);
    } else {
      this.dataApi.getDepartment().then((res:any)=> {//通过tooken获取企业信息
        //console.log("企业信息线上数据");
        //console.log(res);
        if (res) {
          this.myDepartment = res;
          this.dataApi.setLocalDepartment(res);//将企业信息存到本地、全局变量
        }
      }).catch((error) => {
        this.toastr.error(error);
      });
    }

  }


  //获取会话列表
  getImDialogueList() {
    //console.log(this.config.chatsList);
    if (this.config.chatsList && this.config.chatsList.list) {
      this.chatsList = this.config.chatsList.list;
      for (let val of this.chatsList) {
        if (val.dialogue_jmusername == this.resumeDetails.jm_username) {
          this.isFirst = false;
          return;
        }
      }
    } else {
      this.getNewChatList();
    }
  }

  //----更新会话列表(线上数据)
  getNewChatList() {
    this.dataApi.getImDialogueAllList({}).then((res:any)=> {
      if (res) {
        this.dataApi.setLocalChatsList(res);//会话列表本地存储
        this.chatsList = res.list;
        for (let val of this.chatsList) {
          if (val.dialogue_jmusername == this.resumeDetails.jm_username) {
            this.isFirst = false;
            return;
          }
        }
      }

    }).catch((error) => {
      //console.log(error);
      this.toastr.error(error);
    });
  }

  //去沟通页面前携带参数处理(初次沟通的情况)
  goContactChatHandle() {
    //console.log("去沟通页面之前检查职位参数(新会话)-------college-resume-details");
    //console.log(this.positionList);
    let userInfo = {
      personal_avater: this.resumeDetails.talent_base.personal_avater,
      resume_number: this.resumeDetails.resume_number,
      resumeid: this.resumeDetails.resumeid,
      talent_name: this.resumeDetails.talent_base.name,
      talentid: this.resumeDetails.talent_base.accountid,
      jm_username: this.resumeDetails.jm_username,
    }
    let position;
    if (this.positionid) {
      for (let n of this.positionList) {
        if (this.positionid == n.positionid) {
          position = n;
        }
      }
    } else {
      position = this.positionList[0];
    }
    //检查是否有在期职位
    if (this.positionList.length > 0) {
      //展位号
      if (this.seat_number) {
        this.local.set(Constants.seat_number, JSON.stringify(this.seat_number));//展位号
      }
      // this.local.set(Constants.chatPosition,position);//职位参数
      this.local.set(Constants.chatPosition, JSON.stringify(position));//职位参数
      this.local.set(Constants.userInfo, JSON.stringify(userInfo));//聊天记录本地存储参数
      this.router.navigate(['home/chat-contact']);
    } else {
      this.opera.remindPublish('在线直聊');
    }

  }

  //索要简历
  askResume() {
    this.config.isAskResume = true;//是否索要简历
    this.goContactChatBefore();//去沟通界面
  }

  //继续沟通
  goContinueChat() {
    this.config.isAskResume = false;//是否索要简历
    this.goContactChatBefore();//去沟通界面
  }

  //去沟通界面之前的处理
  goContactChatBefore() {
    //这个地方再去判断权限
    if (this.resumeDetails.jm_username) {//判断是否需要扣除资源(会话权限,与下面的遍历可能有冲突,暂时不影响,后续优化)
      //console.log("不需要获取极光资源------------------------homedetails");
      this.goContactChat();
    } else {
      //console.log("需要获取极光资源------------------------homedetails");
      this.dataApi.getChatIm({
        accountid: this.resumeDetails.talent_base.accountid,
        content: this.resumeDetails.talent_base.collegeid
      }).then((res:any)=> {
        //console.log("极光资源获取成功------------------------homedetails");
        //console.log(res);
        this.resumeDetails.jm_username = res.jm_username;
        this.userJmUsername = res.jm_username;
        this.goContactChat();
      }).catch((error: any) => {
        //console.log("极光资源获取失败------------------------homedetails");
        //console.log(error);
        if (error.Errcode == '2002') {//未通过认证
          this.dataApi.checkDepCollegeIdentity(this.resumeDetails.talent_base.collegeid).then((res:any)=> {
            //console.log("检测认证信息------");
            //console.log(res);
            if (res) {
              if (res.status == 1) {
                this.toastr.warning("您在" + this.resumeDetails.education_backgrounds[0].graduation_school + '的校招申请正在审核中，暂无法使用此功能');
              } else {
                this.opera.remindCollegeIdentity("与学生直聊", this.resumeDetails.talent_base.collegeid, res);//提醒认证
              }
            } else {
              this.opera.remindCollegeIdentity("与学生直聊", this.resumeDetails.talent_base.collegeid, res);//提醒认证
            }
          }).catch((err) => {
            this.toastr.error(err);
          })
        } else if (error.Errcode == '2001') {//50次配额超限
          // this.opera.goCallChatEidentity();
          this.toastr.warning('今天沟通已达上限，休息一下，明天继续吧');
        } else if (error.Errcode == '2005') {//100次配额超限
          this.toastr.warning('今天沟通已达上限，休息一下，明天继续吧');
        } else {
          this.toastr.error(error);
        }
      });
    }

  }


  //去沟通页面
  goContactChat() {
    //console.log("判断当前有无会话列表------college-resumedetails");
    //console.log(this.chatsList);
    if (this.chatsList) {//判断当前有无会话列表
      if (this.chatsList.length > 0) {
        let isInDialog = false;//当前人才是否已在会话列表中
        this.chatsList.forEach((val) => {
          if (val.dialogue_accountid == this.resumeDetails.talent_base.accountid) {
            isInDialog = true;
            this.config.isNavActive = 'college';
            this.config.isNavChildActive = 'college-resume';
            //展位号
            if (this.seat_number) {
              this.local.set(Constants.seat_number, JSON.stringify(this.seat_number));//展位号
            }
            this.local.remove(Constants.chatPosition);//职位参数
            this.local.set(Constants.chatsInfo, JSON.stringify(val));//聊天记录本地存储参数
            this.router.navigate(['home/chat-contact']);
          }
        });
        if (!isInDialog) {
          //console.log("当前会话是新会话------college-resumedetails");
          this.goContactChatHandle();//创建会话
        }
      } else {
        this.goContactChatHandle();//创建会话
      }
    } else {
      this.goContactChatHandle();//创建会话
    }
  }




  //通过简历编号获取简历详情
  getResumeNumView(_resumeNumber) {
    if (!_resumeNumber) {
      this.toastr.error("当前简历或已被删除，请刷新后重试");
      this.opera.goChangeRouter('college-resume');
      return;
    }

    if (!this.resumeDetails.jm_username) {
      this.opera.loading();
      this.dataApi.getResumeNumView({ resume_number: _resumeNumber, is_search: this.isSearch }).then((res:any)=> {
        console.log("获取到简历详情------");
        console.log(res);
        this.resumeDetails = this.resumeHandle(res);
        this.setDeliveryRead()
        this.getImDialogueList();//---更新会话列表
        //  this.filterPosition();//筛选出和当前匹配的职位
        this.isInit = false;
        //获取对方的极光账号
        if (this.resumeDetails.jm_username) {
          this.userJmUsername = this.resumeDetails.jm_username;
        }
        this.opera.loadiss();
      }).catch((error) => {
        this.isInit = false;
        this.opera.loadiss();
        if (error.indexOf('简历不开放') || error.indexOf('简历不存在')) {
          this.toastr.error('该学生隐藏了自己的简历');
        } else if (error.indexOf('用户不存在') || error.indexOf('账户不存在') || error.indexOf('人才档案不存在或已删除')) {
          this.toastr.error('该学生简历已删除');
        }else {
          this.toastr.error('啊哦，服务器君忙不过来了');
        }
        this.opera.goChangeRouter('college-resume');
      });
    } else {
      this.getImDialogueList();//---更新会话列表
    }

  }


  //获取上次认证信息
  lastAuthenticationIdentity() {
    if ((!this.config.lastIdentity) || (this.config.lastIdentity.status != 2)) {
      this.dataApi.getPlatformApply().then((res:any)=> {
        //console.log(res);
        if (res) {
          if (res.status == 2) {
            this.config.department.is_certified = true;
            this.dataApi.setLocalDepartment(this.config.department);
          }
          this.config.lastIdentity = res;
          this.dataApi.setLastIdentity(this.config.lastIdentity);
        }
      }).catch((error) => {
        //console.log(error);
      })
    }
  }

  //处理获取到的数据
  resumeHandle(res) {
    //判断工作经历年份是否为至今
    if (res.work_experiences) {
      if (res.work_experiences.length > 0) {
        res.work_experiences.forEach((work) => {
          var endDate = new Date(work.end_date);
          if (endDate.getFullYear() == 2100) {
            work.isEndDate = true;
          }
          this.workexp.push(work);
        });
      }
      //-----------截取工作经验
      if (res.work_experiences.length > 2) {
        res.work_experiences = [];
        res.work_experiences.push(this.workexp[0]);
        res.work_experiences.push(this.workexp[1]);
      }
    }

    //作品展示
    if (res.works) {
      this.works = res.works;
      if (res.works.length > 0) {
        res.works.forEach((n) => {
          this.works.push(n);
        })
        //-----------截取作品展示
        if (res.works.length > 1) {
          res.works = [];
          res.works.push(this.works[0]);
        }
      }
    }

    //  //通用技能
    //  if(res.pro_skills){
    //     if(res.pro_skills.length>0){
    //      res.pro_skills.forEach((skills)=>{
    //        if(skills.contrast_list){
    //          if(skills.contrast_list.length>0){
    //            skills.contrast_list.forEach((skill)=>{
    //               this.pro_skills.push(skill.text);
    //             })
    //          }
    //        }
    //      })
    //     }
    //  }

    //判断项目经验年份是否为至今
    if (res.project_experiences) {
      if (res.project_experiences.length > 0) {
        res.project_experiences.forEach((project) => {
          var endDate = new Date(project.end_date);
          if (endDate.getFullYear() == 2100) {
            project.isEndDate = true;
          }
          this.project.push(project);
        });
      }
      //-----------截取项目经验
      if (res.project_experiences.length > 1) {
        res.project_experiences = [];
        res.project_experiences.push(this.project[0]);
      }
    }
    //判断培训经历时间是否为至今
    if (res.Training_Experience) {
      if (res.Training_Experience.length > 0) {
        res.Training_Experience.forEach((project) => {
          var endDate = new Date(project.end_date);
          if (endDate.getFullYear() == 2100) {
            project.isEndDate = true;
          }
          this.project.push(project);
        });
      }
      //-----------截取项目经验
      if (res.Training_Experience.length > 1) {
        res.Training_Experience = [];
        res.Training_Experience.push(this.project[0]);
      }
    }

    //职位方向
    if (res.job_target) {
      if (res.job_target.career) {
        res.job_target.career.forEach((val) => {
          if (val.code) {
            this.careerTypeString.push(val.code);
          }
        });

      }
      res.job_target.work_city = this.splitObjectArrayToString(res.job_target.work_city, 'text', '，');
      res.job_target.industry = this.splitObjectArrayToString(res.job_target.industry, 'text', '，');
      res.job_target.career = this.splitObjectArrayToString(res.job_target.career, 'text', '，');
    }
    return res;
  }

  // 分割数组对象,获取数组各个对象中的某个对象的
  splitObjectArrayToString(objs, eleName, splitText) {
    let result = '';
    if (objs) {
      for (var i = 0, length = objs.length; i < length; i++) {
        if (i === (length - 1)) {
          splitText = '';
        }
        result += objs[i][eleName] + splitText;
      }
    }
    // //console.log(result);
    return result;
  };



  //收藏简历
  manageResumeArchived() {
    if (this.isCanFollow) {//防止重复点击
      return;
    }
    if (!this.resumeDetails.isfollow) {
      this.isCanFollow = true;
      this.dataApi.manageResumeArchived({
        resumeid: this.resumeDetails.resumeid
      }).then((res:any)=> {
        //console.log(res);
        setTimeout(() => {
          this.resumeDetails.isfollow = true;
          this.isCanFollow = false;
          this.toastr.success('收藏成功');
        }, 600);

      }).catch((error) => {
        //console.log(error);
        setTimeout(() => {
          this.toastr.error(error);
          this.isCanFollow = false;
        }, 600);

      });
    } else {
      this.isCanFollow = true;
      this.dataApi.manageResumeArchivedDelete(this.resumeDetails.resumeid).then((res:any)=> {
        //console.log(res);
        setTimeout(() => {
          this.toastr.success('取消收藏成功');
          this.resumeDetails.isfollow = false;
          this.isCanFollow = false;
        }, 600);

      }).catch((error) => {
        //console.log(error);
        //  loader.dismiss();
        setTimeout(() => {
          this.toastr.error(error);
          this.isCanFollow = false;
        }, 600);

      });
    }
  }

  //试图销毁时,取消事件订阅
  ngOnDestory() {
    this.chattoEvent.unsubscribe();
    //this.events.unsubscribe('chatTo:contact');//更新本地会话存储,判断是否沟通过
  }
  //设置投递简历已读
  setDeliveryRead(){
    console.log('setDeliveryRead',this.resumeDetails.delivery_info)
    if(this.resumeDetails.delivery_info){
      this.dataApi.setDeliveryRead(this.resumeDetails.delivery_info.deliveryid).then((res:any)=>{
      }).catch((error) => {});
    }
  }
}
