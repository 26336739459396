<!-- 遮罩层公共样式 -->
<div class="model-block" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>
    <div class="model-cont">
      <!-- 上层提示部分 -->
      <div class="model-head clear">
        <div class="left">双选会报名</div>
        <button class="right" (click)="dismiss(false)">×</button>
      </div>
      <!-- 下层主体内容部分 -->
      <div class="model-content">
          <div class="model-list clear">
              <div class="left">申请场次</div>
              <div class="left">{{number}}场</div>
          </div>
          <div class="title">填写报名表</div>
           <!-- 公司名称 -->
          <div class="sign-list" *ngIf="department">
              <span>公司名称：</span><input type="text" [(ngModel)]="department.name" disabled="disabled">
          </div>
          <!-- 联系人 -->
          <div class="sign-list">
              <span>联系人：</span><input type="text" [(ngModel)]="model.contacts" maxlength="8" placeholder="请填写联系人">
          </div>
          <!-- 联系电话 -->
          <div class="sign-list">
              <span>联系电话：</span><input type="number" [(ngModel)]="model.phone" placeholder="请填写联系电话">
          </div>
          <!--参会人数 -->
          <div class="sign-list">
              <span>参会人数：</span><input type="number" [(ngModel)]="model.attendance" oninput="if(value.length>2)value=value.slice(0,2)" placeholder="请填写参会人数">
          </div>
      </div>
      <!-- 底部操作部分 -->
      <div class="model-foot">
        <button class="purple" (click)="getSignModel()">提交</button>
        <!-- <button>我知道了</button> -->
      </div>
    </div>
    
  
  </div>
