import { Component, OnInit,Input,Output,ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { DataApi,Config,Local,Constants,Opera} from '../../provider/index';
import { ModelPayWechatComponent } from '../model-pay-wechat/model-pay-wechat.component';
declare let window;
declare let pingpp;
@Component({
  selector: 'app-company-pay',
  templateUrl: './company-pay.component.html',
  styleUrls: ['./company-pay.component.css']
})
export class CompanyPayComponent implements OnInit {
  @ViewChild('payWechat', { static: true }) child: ModelPayWechatComponent;
  public payPath='';//支付渠道
  // public pingpp=require('pingpp-js');
  // public config: Config;
  public model={
    channel:'alipay_pc_direct', //支付渠道alipay_pc_direct:支付宝pc wx_pub_qr:微信扫码
    amount:0 ,//充值金额
    orderid:'' //订单ID
  }
  public pageTitle="充值";
  public payList:any;//支付渠道列表
  public product_name='';//产品名称
  @Output() modelShow = false;//是否打开弹窗
  constructor(
    public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
    
  }

  ngOnInit() {
    this.model.orderid=this.local.get(Constants.orderid);
    this.getPayChannel();//获取支付渠道
    this.getOrderDetails();//获取订单详情
  }
  //接收子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
    if(event){
      this.router.navigateByUrl("home/company-status-one");//审核中
    }else{
      this.toastr.error("支付失败，请重试");
    }
  }

  //支付
  pay(){
    if(!this.model.channel){
      this.toastr.warning("请选择支付渠道");
      return;
    }
    this.opera.loading();
    this.dataApi.postPayCharge(this.model).then((res:any)=>{
      console.log("支付charge");
      console.log(res);
      this.opera.loadiss();
      if(this.model.channel=='wx_pub_qr'){
        this.opera.scrollTop();
        this.modelShow = true;
        this.child.initData(res);
        return;
      }
      let that = this;
      pingpp.createPayment(res, function(result,error){
        console.log('调用支付-----pc');
        console.log(result);
        console.log(error);
        if(result=='success'){
          // that.router.navigateByUrl("home/company-status-one");//审核中
        }else{
          that.toastr.error('支付失败，请重试');
        }
      });
      
    }).catch((err)=>{
      console.log(err);
      this.opera.loadiss();
    })
  }

  //获取支付渠道
  getPayChannel(){
    this.dataApi.getPayChannel().then((res:any)=>{
      if(res){
        console.log('获取到支付渠道');
        console.log(res);
        this.payList=res;
        this.model.channel=res[0].alias;
      }
    }).catch((err)=>{
      console.log('获取支付渠道出错');
      console.log(err);
      this.toastr.error("获取支付渠道出错，请稍后重试");
      // this.navCtrl.popToRoot();
    })
  }

  //获取订单详情
  getOrderDetails(){
    this.opera.loading();
    this.dataApi.getOrderDetails(this.model.orderid).then((res:any)=>{
      this.opera.loadiss();
      console.log('获取到订单详情');
        console.log(res);
      if(res){
        this.model.amount=res.amount_payable;
        this.product_name=res.product_name;
      }
    }).catch((err)=>{
      this.opera.loadiss();
      console.log('获取订单详情出错');
      console.log(err);
      this.toastr.error("获取订单详情出错，请稍后重试");
      // this.navCtrl.popToRoot();
    })
  }

}
