import { Component, OnInit, Input, Output } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
declare let laydate;
@Component({
  selector: 'app-college-milkround',
  templateUrl: './college-milkround.component.html',
  styleUrls: ['./college-milkround.component.css']
})
export class CollegeMilkroundComponent implements OnInit {
  public config: Config;
  public keywordList=[];//搜索关键词匹配列表
  public hotCity=[];//热门城市
  public milkRoundList = [];//双选会列表
  public searchModel = '';//搜索关键词
  public posterUrl = '' // 双选会海报下载链接
   // 筛选条件
  public model={
    region:'',//城市
    start_time:"",
    end_time:"",
    college_name:'',
    offset:0,
    limit:9
  }
  public countList = 0;//列表数量
  @Output() modelShow = false;//是否打开弹窗
  @Output() mridList=[];//招聘会id列表
  @Output() milkNumber = 1;//当前申请的招聘会场次
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  // public isShowFilter = false;
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {
    this.opera.initConfig('college','college-milkround');
    let that = this;
    laydate.render({
      elem:'#startTime',
      // type: 'datetime',
      done: function(value, date){
       that.model.start_time = value;
       if(that.model.end_time){
         that.checkTime();//检测时间
       }
      }
     })
     laydate.render({
      elem:'#endTime',
      // type: 'datetime',
      done: function(value, date){
       that.model.end_time = value;
       that.checkTime();//检测时间
      }
     })
    this.getHotCity();//获取热门城市
    this. getCollegeCode();//获取高校字典
    this.getMilkList();//获取双选会列表
  }

  //去我报名的双选会
  goSignList(){
    this.router.navigateByUrl("/home/position-milk");
  }

  //接收返回参数
  Modeldismiss(event){
    this.modelShow=false;
    this.opera.scrollTrue();
    if(event){
      for(let n of this.milkRoundList){
         for(let m of this.mridList){
           if(n.mrid == m){
             n.status='to_audit';
           }
         }
      }
    }
    this.mridList = [];//清空
  }

  //去报名
  goSign(n){
    if(this.config.positionList.length==0){
      this.opera.remindPublish("报名双选会");
      return;
    }
    //console.log("双选会报名前检查参数-----college-milkround");
    //console.log(this.mridList);
    this.dataApi.checkDepCollegeIdentity(n.collegeid).then((res:any)=>{
      //console.log("获取企业在高校的认证信息-----------------------CampusDetails");
      //console.log(res);
      if(res){
        if(res.is_activity){//活动高校
          if(res.count>0){//通过1家以上高校校招
            // if(!res.can_apply){
            //   this.toastr.warning(res.reason);
            // }else{
            //   this.goMilkSign(n);//报名
            // }
            this.goMilkSign(n);//报名
          }
        }else{//非活动高校
          if(res.status=='2'){//通过校招
            // if(!res.can_apply){
            //   this.toastr.warning(res.reason);
            // }else{
            //   this.goMilkSign(n);//报名
            // }
            this.goMilkSign(n);//报名
          }else if(res.status=='1'){//校招审核中
            this.toastr.warning("校招审核中，审核通过后才可报名双选会");
          }else{//未提交校招申请
            this.opera.remindCollegeIdentity("报名双选会",n.collegeid,res);
          }
        }
        // if(res.gwl_status=='2'){//通过工作啦认证
        //   this.goMilkSign(n);//报名
        // }else{//未通过工作啦审核
        //   if(res.is_activity){//活动高校
        //     if(res.count>0){//通过1家以上高校校招
        //       this.goMilkSign(n);//报名
        //     }else{//提示企业认证
        //       this.opera.remindDepartmentIdentity(n.collegeid,res);
        //     }
        //   }else{//非活动高校
        //     if(res.status=='2'){//通过校招
        //       this.goMilkSign(n);//报名
        //     }else if(res.status=='1'){//校招审核中
        //       this.toastr.warning("校招审核中，审核通过后才可报名双选会");
        //     }else{//未提交校招申请
        //       this.opera.remindCollegeIdentity("报名双选会",n.collegeid,res);
        //     }
        //   }
        // }
      }else{
        this.opera.remindCollegeIdentity("报名双选会",n.collegeid,res);
      }
    }).catch((err)=>{
      this.toastr.error(err);
    })
  }

  //去双选会报名页
  goMilkSign(n){
    if(n.scale <= n.department_participate_count ){
      this.toastr.warning('坐席已满，无法报名！');
      return
    }
    if(n.mrid){
      this.local.set(Constants.collegeid,n.collegeid);
      this.local.set(Constants.mrid,n.mrid);
      this.local.remove(Constants.applyid);
      this.router.navigate(['home/college-milk-sign'], {
        queryParams: {
          milkname:n.name
        }
      });
    }
  }

  //进入会场(不需要通过认证)
  goSpace(id){
    this.router.navigateByUrl('home/college-milk-space');
    this.local.set(Constants.mrid,id);//覆盖本地存储招聘会id
  }


  //去双选会详情
  goDeatils(id){
    this.router.navigate(['home/college-milkdetails'],{
      queryParams:{
        id:id
      }
    })

  }

  goSearch(text){
    this.model.college_name = text;
    this.getMilkList();//获取双选会列表
    this.keywordList=[];
  }

   //获取高校字典
   getCollegeCode(){
    // 获取高校字典数据
    if(this.config.school.length<1){
     this.dataApi.getDictDate('school').then((res:any)=>{
       // //console.log(res);
       this.config.school=res;
     }).catch((err)=>{
       // this.toastr.error("网络错误,请稍后重试");
       //console.log(err);
     })
   }
 }

  //匹配关键字列表
  getItems(){
    this.keywordList=[];
    if(!this.model.college_name){return;}
    for(let s of this.config.school){
      if(s.text.indexOf(this.model.college_name) >= 0){
         this.keywordList.push(s);
      }
    }
  }
  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      that.goSearch(that.model.college_name);
    }else{
      that.getItems();
    }
  }

  //判断时间参数是否完整
  checkTime(){
    if(this.model.start_time&&this.model.end_time){
      this.getMilkList();//获取双选会列表
    }else if(!this.model.start_time){
      this.toastr.warning("请选择开始时间");
      return;
    }
  }

  //获取双选会列表
  getMilkList(){
    this.opera.loading();
    this.dataApi.getDepartmentMilkList(this.model).then((res:any)=>{
      //console.log("获取双选会列表-----college-campustalk");
      //console.log(res);
      this.milkRoundList=res.list;
      this.countList=res.count;
      this.pageCount =Math.ceil(res.count/9);
      for(let val of this.milkRoundList){
        let nowDate = new Date();
        let startDate = new Date(val.start_time);
        let endDate = new Date(val.end_time);
        let applyOpDate = new Date(val.apply_start_time); // 报名开始时间
        let applyEndDate = new Date(val.apply_end_time); // 报名结束时间
        let nowTime = nowDate.getTime();  // 当前时间戳
        let startTime = startDate.getTime();  // 开始时间戳
        let endTime = endDate.getTime();  // 结束时间戳
        let applyOpTime = applyOpDate.getTime();  // 报名开始时间戳
        let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳
        val.signOpTime = false;
        if(applyOpTime == -62135625943000 || applyOpTime == -62135625600000 || val.apply_start_time == '0001-01-01T00:00:00' || val.apply_start_time == '0001-01-01T00:00:00+08:00' || val.apply_start_time == ''){
          val.signOpTime = false; // 未设置报名开始时间
        }else{
          val.signOpTime = true;
        }
        if(applyEndTime == -62135625943000 || applyEndTime == -62135625600000 || val.apply_end_time == '0001-01-01T00:00:00' || val.apply_end_time == '0001-01-01T00:00:00+08:00' || val.apply_end_time == ''){
          val.signEdTime = false; // 未设置报名结束时间
        }else{
          val.signEdTime = true;
        }
        if(nowTime-startTime>0){
          val.isApplySign=false;
          val.isStart=true; // 会议已开始
        }else{
          val.isStart=false;  // 会议未开始
          val.isApplySign=true;
        }
        //增加是否是活动判断
        if(val.is_activity){
          if(endTime-nowTime>0){
            val.isApplySign=true;
          }else{
            val.isApplySign=false;
          }
        }
        if(nowTime-endTime>0){
          val.isEnd=true; // 会议已结束
        }else{
          val.isEnd=false; // 会议未结束
        }
        if(val.signOpTime){ // 有报名时间和报名结束时间
          if(nowTime-applyOpTime>0){
            val.isAppStart=true; // 报名已开始
          }else{
            val.isAppStart=false; // 报名未开始
          }
          if(nowTime-applyEndTime>0){
            val.isAppEnd=true; // 报名已结束
          }else{
            val.isAppEnd=false; // 报名未结束
          }
        }
      }
      this.opera.loadiss();
    }).catch((err)=>{
      //console.log(err);
      this.opera.loadiss();
    })
  }


  //获取热门城市
  getHotCity(){
    if(this.hotCity.length<1){
      this.dataApi.getCollegeHotCity().then((res:any)=>{
         //console.log("获取热门城市----college-campustalk");
         //console.log(res);
         this.hotCity=res;
      }).catch((err)=>{
         //console.log(err);
      })
    }
  }


  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----college-campustalk');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getMilkList();//获取列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getMilkList();//获取列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getMilkList();//获取列表
      }
    }

  }

  // 获取双选会海报下载链接
  downloadPoster(applyid){
    this.opera.loading();
    this.dataApi.getMilkPoster(applyid).then((res:any)=>{
      this.opera.loadiss();
      if(res.value){
        let b = window.open();
        setTimeout(function(){
          b.location.href = res.value;
        }, 500);

      }
    }).catch((err)=>{
      console.log(err);
      this.opera.loadiss();
    })
  }

  downloadIamge(imgsrc, name) {//下载图片地址和图片名
    let image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function() {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      let a = document.createElement("a"); // 生成一个a元素
      let event = new MouseEvent("click"); // 创建一个单击事件
      a.download = name || "photo"; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = imgsrc;
  }




}
