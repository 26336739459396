import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { DataApi, Local, Constants, Opera, Config, FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelStillMilkComponent } from '../model-still-milk/model-still-milk.component';
import { ModelPositionMoreComponent } from '../model-position-more/model-position-more.component';
import * as moment from 'moment';
declare var window;
@Component({
  selector: 'app-college-milk-sign',
  templateUrl: './college-milk-sign.component.html',
  styleUrls: ['./college-milk-sign.component.css']
})
export class CollegeMilkSignComponent implements OnInit {
  public config: Config;
  @ViewChild('child', { static: true }) child: ModelStillMilkComponent;
  @ViewChild('child1', { static: true }) child1: ModelPositionMoreComponent;
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelTwoShow = false;//是否打开弹窗
  public model = {
    is_alumn: false, // 是否为校友企业
    friendList: [{ name: '', major: '', graduation_time: '', position: '', index: 0 }],
    alumn_infos: [],
    contacts: '',//联系人
    attendance: '',//参会人数
    phone: '',
    remark: '',//备注
    facultyid: '',//审核院系id
    attachment: [],//附件
    interview_time: '', // 面试时间
    attendees: [{ name: '', phone: '' }],//参会人数
    positionids: [],//职位id
    major_list: [],//专业
    wechat: '',
    is_invite: false,//是否是邀请的企业（邀请指小秘书邀请）
    authid: null //资质审核申请记录

  }

  public audit_switch = { // 高校开关列表
    department_receipt: '',
    isShowFriend: false,
    department_receipt_url: '',
    air_milkround_alumn_required: false,
    milkround_alumn_required: false
  };
  public dmodel = {
    collegeid: '',
    facultyid: '',//院系id
    proxy_document: '',//招聘委托书
    business_licence: '',//企业营业执照
    id_card_front: '',//身份证正面
    register_number: '',//营业执照注册号
    brief: '',//申请简述
    office_phone: '',//固定电话
    school_permit: "",
  }
  public collegeName = "";
  public secureMaterail: any;//招聘委托书下载链接
  public authcation_office_phone = false;
  public name = "";//双选会名称
  public mrid = '';//招聘会id
  public applyid = '';//招聘会申请id
  public mridList = [];//招聘会id
  public department: any;//公司信息
  public isSend = false;//是否点击发送按钮
  public orgList = [];//院系列表
  public orgList_shenhe = [];//院系列表
  public positionList = [];//选中职位列表
  public collegeMajorList = [];//专业列表（校级）
  public audit_mode = false;//是否是院系初审
  public interview_time_setting = [] // 面试时间申请列表

  public CollegeIdentityModel = {
    status: 10086,
    no_apply_30day: false,
    audit_time: ''
  };
  public MilkDetails = null;


  public showTab1 = false;
  public showTab2 = true;

  //提交资质审核后，不可再次提交
  public IdentityLoading = false;

  // 资质审核记录ID
  public authenticationApplyID = ""

  public milkroundApplyAllowd = true;

  // U端要求上传的文件
  public department_receipt_List = [];

  public CollegeDetail = null;

  public isSameNameApply = true;



  constructor(public toastr: ToastrService,
    public local: Local,
    public opera: Opera,
    private router: Router,
    public formatDate: FormatDate,
    private activatedRoute: ActivatedRoute,
    public dataApi: DataApi) {
    this.config = Config.getInstance();//实例化config(必须)
    activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.milkname) {
        this.name = queryParams.milkname;
      }
    })
    //获取hr信息
    if (this.dataApi.getLocalLoginUser()) {
      //console.log("获取公司HR联系电话");
      if (this.dataApi.getLocalLoginUser().mobile) {
        this.model.phone = this.dataApi.getLocalLoginUser().mobile;//联系电话
      }
    }
    this.mrid = this.local.get(Constants.mrid);

    //获取上次申请提交数据
    if (this.local.get(Constants.applyid)) {
      this.applyid = this.local.get(Constants.applyid);
      this.getPrevMIlkApply();
    } else {
      this.getPositionList();//获取职位数据
    }
  }

  ngOnInit() {
    this.getDepartment();//获取企业信息
    this.getOrgList();//获取院系列表
    this.getCollageDetails();//获取高校信息
    let collegeid = this.local.get(Constants.collegeid);
    this.singleIdentity(collegeid);
    this.dmodel.collegeid = collegeid;
    this.checkMilkroundSameNameApply()
    this.getMilkroundAlumnDepartment(collegeid);
  }

  //添加参会人
  addPosition() {
    for (var i = 0; i < this.model.attendees.length; i++) {
      let item = this.model.attendees[i];
      if (!item.name) {
        this.toastr.warning(`请先完善成员${i + 1}姓名`);
        return;
      }
      if (!item.phone) {
        this.toastr.warning(`请先完善成员${i + 1}联系电话`);
        return;
      }
    }

    if (this.model.attendees.length > 4) {
      this.toastr.warning('参会人最多添加5个');
      return;
    }
    let p = { name: '', phone: '' }
    this.model.attendees.push(p);
  }

  //删除
  delPosition(index) {
    if (this.model.attendees.length == 1) {
      this.toastr.warning(`至少保留一个参会成员`);
      return;
    }
    this.model.attendees.splice(index, 1);
  }


  //获取双选会详情
  getMilkDetails() {
    this.opera.loading();
    if (!this.mrid) {
      return;
    }
    this.getMilkroundApplyAllowd(this.mrid)

    this.dataApi.getMilkDetails(this.mrid).then((res: any) => {
      console.log("获取到双选会详情", res);
      if (res) {
        this.audit_switch.department_receipt = res.department_receipt_switch;
        this.audit_switch.department_receipt_url = res.department_receipt_url;
        //限制行业
        if (res.include_industrys && res.include_industrys.length > 0) {
          let arry = [];
          for (let item of res.include_industrys) {
            arry.push(item.text);
          }
          res.include_industrys_text = arry.join("，");
        }
        this.MilkDetails = res;
        this.checkFriendStaus();
        let scale = res.scale;//规模
        let department_apply_count = res.department_apply_count;//公司报名数
        let department_participate_count = res.department_participate_count;//公司参与数
        if (res.milkround_type != 2) {
          if (department_participate_count >= scale) {
            this.toastr.warning('双选会坐席已满，无法报名');
            this.opera.goChangeRouter('college-milkdetails')
            return;
          }
        }

        if (this.MilkDetails.department_receipt_List == null) {
          this.MilkDetails.department_receipt_List = [];
        }
        this.MilkDetails.department_receipt_List.map((item) => {
          item.links = [];
          this.department_receipt_List.push((item))
        })

        console.log(res);
        this.interview_time_setting = res.interview_time_setting;
        this.name = res.name;
        this.audit_mode = res.audit_mode;
        if (res.audit_mode && res.facultyid != '000000000000000000000000') {
          this.model.facultyid = res.facultyid;
        }


        if (res.status == "to_audit" || res.status == "passed") {
          this.showTab1 = false;
          this.showTab2 = false;
        } else if (res.status == 'not_passed') {
          this.showTab1 = false;
          this.showTab2 = true;
        }
        console.log("报名是否截止", res.isAppEnd)
        //报名结束 +双选会审核中/双选会未通过 isStart 双选会开始时间
        //企业资质审核通过时，双选会/宣讲会报名已截止，企业申请的双选会自动失败，并返回失败的原因（双选会展位分配已满，请报名其他双选会/宣讲会，点击进入双选会/宣讲会列表）
        if (res.isStart && (res.status == "to_audit" || res.status == "not_passed")) {
          if (res.milkround_type == 1) {
            res.status = "not_passed";
            res.reason = "双选会展位分配已满，请报名其他双选会";
            this.showTab2 = false;
          } else if (res.milkround_type == 2) {
          }
        }

        if (this.CollegeIdentityModel.status == 3) {
          if (this.CollegeIdentityModel.no_apply_30day == true) {
            this.showTab1 = false;
            this.showTab2 = false;

            let currentdata = new Date();
            let data = new Date(this.CollegeIdentityModel.audit_time);
            data.setDate(data.getDate() + 30);
            if (currentdata > data) {
              console.log("用户勾选了30天可以申请",);
              this.showTab1 = true;
              this.showTab2 = false;
              this.CollegeIdentityModel.no_apply_30day = false;
            } else {
              console.log("用户勾选了30天不可以申请",);
            }
          } else {
            this.showTab1 = true;
            this.showTab2 = false;
          }

        }
        if (this.CollegeIdentityModel.status == 1 && res.status == 'passed') {
          this.showTab1 = false;
          this.showTab2 = false;
        }

      }

      this.opera.loadiss();
    }).catch((error) => {
      // console.log(error);
      this.opera.goChangeRouter('homepage');
      this.toastr.error(error);
      this.opera.loadiss();
    })
  }

  checkFriendStaus() {
    if (this.MilkDetails) {
      if (this.MilkDetails.milkround_type == 1) {
        this.audit_switch.isShowFriend = this.audit_switch.milkround_alumn_required
      } else {
        this.audit_switch.isShowFriend = this.audit_switch.air_milkround_alumn_required
      }
    }
  }

  // 设置面试时间
  setTime(t) {
    this.model.interview_time = t;
    console.log(t);
    console.log(this.model.interview_time);

  }

  // 获取高校信息
  getCollageDetails() {
    this.opera.loading();
    if (!this.local.get(Constants.collegeid)) {
      return;
    }
    this.dataApi.getCollageDetails(this.local.get(Constants.collegeid)).then((res: any) => {
      console.log("获取到高校信息", res);
      // this.audit_switch = res.audit_switch;
      console.log(this.audit_switch);
      this.opera.loadiss();
      this.audit_switch = res.audit_switch;
      this.audit_switch.milkround_alumn_required = res.audit_switch.milkround_alumn_required;
      this.audit_switch.air_milkround_alumn_required = res.audit_switch.air_milkround_alumn_required;
      if (res) {
        this.CollegeDetail = res;
        this.secureMaterail = res.audit_switch.proxy_document_url;

        if (res.audit_switch.faculty_authcation) {
          this.getOrgList_shenhe();
        } else {
          this.collegeName = res.name;
        }

        if (res.audit_switch.authcation_office_phone) {
          this.authcation_office_phone = true;
          console.log('填写固话');
        } else {
          this.authcation_office_phone = false;
        }
      }


    }).catch((error) => {
      // console.log(error);
      this.opera.goChangeRouter('homepage');
      this.toastr.error(error);
      this.opera.loadiss();
    })
  }

  //附件图片
  previewMilkPic(event, index) {
    if (this.model.attachment.length > 2) {
      this.toastr.warning("附件片最多上传3张");
      return;
    }
    let imgData = event.target.files[0]; //获取file对象
    if (parseInt(imgData.size) / 1024 > 1024 * 5) {
      this.toastr.warning('该附件大小超出了5M，请重新上传');
      return;
    }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e: any) {
        that.uploadDepImg(reader.result, imgData.name, index);
      };

    }
  }

  //上传企业展示图片
  uploadDepImg(data, name, index) {
    let imageData = data;

    imageData = this.opera.formatFileData(imageData)

    console.log('uploadDepImg', name, data)
    let filedata = {
      extension: name,
      filename: name,
      base64: imageData
    }
    this.opera.loading();
    this.dataApi.uploadDepartmentFile(filedata).then((res: any) => {
      // loader.dismiss();
      this.opera.loadiss();
      // this.model.attachment.push(res.link);
      this.department_receipt_List[index].links.push(res.link);

    }).catch((error) => {
      // loader.dismiss();
      this.opera.loadiss();
      this.toastr.error("上传文件错误");
    })
  }

  //删除附件图片
  deleteImg(img) {
    this.model.attachment.forEach((val) => {
      if (val == img) {
        let num = this.model.attachment.indexOf(val);
        this.model.attachment.splice(num, 1);
      }
    })
  }

  //获取列表
  getOrgList() {
    this.opera.loading();
    if (!this.local.get(Constants.collegeid)) {
      return;
    }
    this.dataApi.getCollegePart(this.local.get(Constants.collegeid)).then((res: any) => {
      //console.log("获取院系列表---");
      //console.log(res);
      this.orgList = res;
      this.opera.loadiss();
    }).catch((err) => {
      this.opera.loadiss();
      //console.log(err);
    })
  }
  getOrgList_shenhe() {
    this.opera.loading();
    if (!this.local.get(Constants.collegeid)) {
      return;
    }
    this.dataApi.getCollegePart(this.local.get(Constants.collegeid)).then((res: any) => {
      //console.log("获取院系列表---");
      //console.log(res);
      this.orgList_shenhe = res;
      this.opera.loadiss();
    }).catch((err) => {
      this.opera.loadiss();
      //console.log(err);
    })
  }

  //获取职位列表数据
  getPositionList() {
    //console.log("在期职位本地存储----");
    //console.log(this.dataApi.getLocalpositionList());
    if (this.dataApi.getLocalpositionList()) {
      //console.log(this.config.positionList);
      this.config.positionList = this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
      //console.log(this.config.positionList);
      if (this.model.positionids.length > 0) {
        for (let m of this.config.positionList) {
          for (let n of this.model.positionids) {
            if (m.positionid == n) {
              this.positionList.push(m);
              break;
            }
          }
        }
      } else {
        this.positionIdEval();//赋值全部职位id
      }
    } else {
      this.getNewList();
    }
  }

  //赋值职位默认值
  positionIdEval() {
    this.positionList = [];
    this.model.positionids = [];
    for (let m of this.config.positionList) {
      //大于30天的职位 effect_time
      let tody = moment().format('YYYY-MM-DD');
      let effect_time = moment(m.effect_time).format('YYYY-MM-DD');
      let days = moment(effect_time).diff(moment(tody), 'days');
      if (!m.need_perfect && days > 30) {
        this.positionList.push(m);
        this.model.positionids.push(m.positionid);
      }
    }
  }

  //-------获取线上数据
  getNewList() {
    let model = {
      limit: 100,
      offset: 0,
      status: 'release',
    }
    this.dataApi.getPositionList(model).then((res: any) => {
      //console.log("在期职位获取线上数据-----college-resume");
      //console.log(res);
      if (res) {
        this.config.positionList = res.list;
        if (this.model.positionids.length > 0) {
          for (let m of this.config.positionList) {
            for (let n of this.model.positionids) {
              //大于30天的职位 effect_time
              let tody = moment().format('YYYY-MM-DD');
              let effect_time = moment(n.effect_time).format('YYYY-MM-DD');
              let days = moment(effect_time).diff(moment(tody), 'days');

              if (m.positionid == n && days > 30) {
                this.positionList.push(m);
                break;
              }
            }
          }
          console.log('大于30天的职位', this.positionList);
        } else {
          this.positionIdEval();//赋值全部职位id
        }
        this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
      }

    }).catch((error) => {
      this.toastr.error(error);
    });
  }

  //选择意向专业
  goMajor() {
    this.modelShow = true;
    this.opera.scrollTop();
    //调用子组件函数
    this.child.initData(this.model.major_list, this.orgList, 10,this.model.facultyid);
    // this.choosePosition();
  }

  //接收子组件返回数据
  Modeldismiss(event) {
    this.modelShow = false;
    this.opera.scrollTrue();
    if (event) {
      this.collegeMajorList = [];
      console.log("接收子组件返回数据");
      console.log(event);
      this.model.major_list = event;
      for (let n of event) {
        for (let m of n.majors) {
          this.collegeMajorList.push(m);
        }
      }
    }

  }

  //选择职位
  choosePosition() {
    this.modelTwoShow = true;
    this.opera.scrollTop();
    this.child1.initData(this.model.positionids, 'isdays');
  }

  ModelTwodismiss(event) {
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    if (event) {
      // console.log("接收子组件返回数据");
      // console.log(event);
      this.positionList = [];
      this.model.positionids = event;
      for (let m of this.config.positionList) {
        for (let n of this.model.positionids) {
          if (m.positionid == n) {
            this.positionList.push(m);
            break;
          }
        }
      }

    }
  }


  //获取双选会上次申请数据
  getPrevMIlkApply() {
    this.opera.loading();
    this.dataApi.getPrevMIlkApply(this.applyid).then((res: any) => {
      console.log("获取上次申请双选会信息");
      console.log(res);
      if (res) {
        this.name = res.name;
        this.model.contacts = res.contacts;
        this.model.attendance = res.attendance;
        this.model.phone = res.phone;
        this.model.positionids = res.positionids;
        this.model.remark = res.remark;
        this.model.interview_time = res.interview_time;
        if (res.audit_mode && res.facultyid != '000000000000000000000000') {
          this.audit_mode = true;
          this.model.facultyid = res.facultyid;
        }
        if (res.attachment) {
          this.model.attachment = res.attachment;
        }
        //职位
        if (!res.positionids) {
          this.model.positionids = [];
        } else {
          this.getPositionList();//获取职位数据
        }
        //专业
        if (res.major_list) {
          this.model.major_list = res.major_list;
          for (let n of res.major_list) {
            for (let m of n.majors) {
              this.collegeMajorList.push(m);
            }
          }
        } else {
          this.model.major_list = [];
        }
        //参会人员
        if (res.attendees) {
          this.model.attendees = res.attendees;
        } else {
          this.model.attendees = [];
        }
      }

      this.opera.loadiss();
    }).catch((err) => {
      this.opera.loadiss();
      this.toastr.error(err);
    })
  }
  //验证联系人格式
  checkContact() {
    let filter = /^[a-zA-Z\u4e00-\u9fa5]+$/;
    if (!filter.test(this.model.contacts)) {
      this.toastr.warning("联系人姓名只能输入汉字或字母");
    }
  }

  //验证手机号码格式
  checkPhone(n) {
    let phoneModel = {
      format_type: 1,//0:手机号，1：手机号&固话；2.邮箱
      data: n
    }
    this.dataApi.phoneVerification(phoneModel).then((res: any) => {
      if (!res.value) {
        this.toastr.warning("联系电话格式错误");
        return;
      }
    }).catch((err) => {
      //console.log(err);
      this.toastr.error(err);
    })
  }
  //验证手机号码格式
  checkPhone1(n) {
    let phoneModel = {
      format_type: 3,//0:手机号，1：手机号&固话；2.邮箱
      data: n
    }
    this.dataApi.phoneVerification(phoneModel).then((res: any) => {
      if (!res.value) {
        this.toastr.warning("固定电话格式错误");
        return;
      }
    }).catch((err) => {
      //console.log(err);
      this.toastr.error(err);
    })
  }

  //提交申请
  getSignModel() {
    //限制行业
    if (this.MilkDetails.include_industrys && this.MilkDetails.include_industrys.length > 0) {
      let _bol = true;
      for (let item of this.MilkDetails.include_industrys) {
        if (item.code == this.department.industry) {
          _bol = false;
        }
      }
      if (_bol) {
        this.toastr.warning("您的企业行业不属于该双选会限制行业，再看看其他双选会吧！");
        return
      }
    }

    if (!this.isSameNameApply) {
      this.toastr.warning("高校不允许同名企业多次报名");
      return
    }

    if (!this.milkroundApplyAllowd) {
      this.toastr.warning("30天内无法再次申请");
      return;
    }

    let firiendList = [];
    if (this.model.is_alumn && this.audit_switch.isShowFriend) {
      let isFull = true
      this.model.friendList.map((item) => {
        if (item.name || item.major || item.graduation_time || item.position) {
          if (!item.name || !item.major || !item.graduation_time || !item.position) {
            isFull = false
          }
        }

        if (item.name && item.major && item.graduation_time && item.position) {
          firiendList.push(item)
        }
      })
      if (!isFull) {
        this.toastr.warning("校友企业信息填写完整");
        return;
      }
      if (firiendList.length == 0) {
        this.toastr.warning("请填写校友企业信息");
        return;
      }
      this.model.alumn_infos = firiendList;
    }

    this.isSend = true;
    let filter = /^[a-zA-Z\u4e00-\u9fa5]+$/;
    if (!this.model.contacts) {
      this.toastr.warning("请填写联系人");
      return;
    } else if (!filter.test(this.model.contacts)) {
      this.toastr.warning("联系人姓名只能输入汉字或字母");
      return;
    }

    if (this.audit_mode) {
      if (!this.model.facultyid) {
        this.toastr.warning("审核院系为必选项");
        return;
      }
    }

    if (!this.model.phone) {
      this.toastr.warning("请填写联系电话");
      return;
    } else {
      let phoneModel = {
        format_type: 1,//0:手机号，1：手机号&固话；2.邮箱
        data: this.model.phone
      }
      this.dataApi.phoneVerification(phoneModel).then((res: any) => {
        if (!res.value) {
          this.toastr.warning("联系电话格式错误");
          return;
        } else {

        }
      }).catch((err) => {
        //console.log(err);
        this.toastr.error(err);
      })

      let phone_rex = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      if (!phone_rex.test(this.model.phone)) {
        this.toastr.warning("联系电话格式错误");
        return;
      }
    }
    if (!this.model.wechat) {
      this.toastr.warning("请填写微信号");
      return;
    }
    if (this.model.wechat) {
      let filter3 = /^[^\u4e00-\u9fa5]*$/;
      if (!filter3.test(this.model.wechat)) {
        this.toastr.warning("请输入正确的微信号");
        return;
      }
    }

    if (this.model.positionids.length == 0) {
      this.toastr.warning("请选择招聘职位");
      return;
    }

    // if(!this.model.attendance){
    //   this.toastr.warning("请填写参会人数");
    //   return;
    // }

    //线上双选会不传 参会人数 参会人员
    if (this.MilkDetails.milkround_type == 1) {
      this.model.attendance = this.model.attendees.length.toString();
      //参会人数填写判断
      let aNum = 0;//未填写内容数量
      let eNum = 0;//填写完善内容数量
      for (let val of this.model.attendees) {
        if (!val.name && !val.phone) {
          // let num = this.model.attendees.indexOf(val);
          // attendNum.push(num);
          aNum++;
          break;
        } else if ((!val.name && val.phone) || (val.name && !val.phone)) {
          this.toastr.warning("请完善参会人信息");
          return;
        } else if (val.name && val.phone) {
          if (!this.opera.isPhone(val.phone)) {
            this.toastr.warning("手机号格式错误:" + val.phone);
            return;
          }
          eNum++;
        }
      }
      if (aNum == this.model.attendees.length) {
        this.toastr.warning("请填写参会成员");
        return;
      } else if (eNum != Number(this.model.attendance)) {
        this.toastr.warning("请根据填写的参会人员数量完善参会人员信息");
        return;
      }
    } else {
      this.model.attendees = [];
    }

    // if(this.audit_switch.department_receipt && this.model.attachment.length <= 0){
    //   this.toastr.warning("请至少上传一张附件!");
    //   return;
    // }

    let attachment_list = [];
    if (this.department_receipt_List.length > 0 && this.MilkDetails.department_receipt_switch) {
      let isupload = true;
      this.department_receipt_List.map((item, index) => {
        if (item.links.length == 0) {
          isupload = false
        }
        item.links.map((url) => {
          attachment_list.push(url)
        })
      })
      if (!isupload) {
        this.toastr.warning("请按照高校要求上传附件");
        return
      }
      this.model.attachment = attachment_list;
    }

    this.opera.loading();//加载动画
    if (this.applyid) {
      this.dataApi.updateMilkSign(this.applyid, this.model).then((res: any) => {
        this.opera.loadiss();//停止加载动画
        this.toastr.success("修改成功");
        this.router.navigate(['home/position-milk']);//返回我的双选会列表
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();//停止加载动画
        this.toastr.error(error);

      })
    } else {
      //console.log("双选会报名前检查参数");
      //console.log(this.model);
      if (!this.mrid) {
        return;
      }
      this.dataApi.signMilk(this.mrid, this.model).then((res: any) => {
        //console.log(res);
        this.opera.loadiss();//停止加载动画
        this.toastr.success("报名成功");
        this.router.navigate(['home/position-milk']);//返回我的双选会列表
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();//停止加载动画
        this.toastr.error(error);

      })
    }

  }


  //获取企业信息
  getDepartment() {
    if (this.dataApi.getLocalDepartment()) {
      this.department = this.dataApi.getLocalDepartment();
      console.log("企业信息本地存储", this.department);
      //console.log(this.dataApi.getLocalDepartment());
    } else {
      this.dataApi.getDepartment().then((res: any) => {
        console.log("企业信息本地存储", this.department);
        //console.log(res);
        this.department = res;
        this.dataApi.setLocalDepartment(res);
      }).catch((error) => {
        //console.log(error);
        this.toastr.error(error);
      });
    }
  }
  ///-----------------------------------------
  //上传图片
  previewPic(event, name) {
    console.log(event);
    console.log(name);
    let imgData = event.target.files[0]; //获取file对象
    if (parseInt(imgData.size) / 1024 > 1024 * 5) {
      this.toastr.warning('该图片大小超出了5M，请重新上传');
      return;
    }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e: any) {
        that.uploadPic(reader.result, name);
      };

    }
  }
  //上传到服务器
  uploadPic(data, name) {
    let imageData = data.replace(/^(data:image)\/png;base64,/g, '');
    imageData = imageData.replace(/^(data:image)\/jpeg;base64,/g, '');
    this.opera.loading();//加载动画
    this.dataApi.uploadAuthImg({
      value: imageData
    }).then((res: any) => {
      ////console.log(res);
      if (res) {
        if (res.value) {//------------上传之后赋值
          this.dmodel[name] = res.value;
        }
      }
      this.opera.loadiss();//停止加载动画
    }).catch((error) => {
      ////console.log(error);
      this.opera.loadiss();//停止加载动画
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    });
  }
  //提交认证
  uploadDepartmentIdentity() {
    if (!this.collegeName) {
      if (!this.dmodel.facultyid) {
        this.toastr.info("请选择当前申请学院");
        return;
      }
    }
    let filter1 = /^[0-9a-zA-Z]{9}$/;
    let filter2 = /^[0-9a-zA-Z]{15}$/;
    let filter3 = /^[0-9a-zA-Z]{18}$/;
    if (!this.dmodel.business_licence) {
      this.toastr.warning('请上传营业执照图片');
      return;
    }
    if (!this.dmodel.id_card_front) {
      this.toastr.warning('请上传身份证正面');
      return;
    }
    if (!this.dmodel.register_number) {
      this.toastr.warning('请填写统一社会信用代码');
      return;
    }
    if (!this.opera.isRegister_number(this.dmodel.register_number)) {
      this.toastr.warning('统一社会信用代码格式不正确');
      return;
    }
    // else{
    //   let filterValue=this.model.register_number.trim();//删除左右两端的空格
    //   if(!filter.test(filterValue)){
    //     this.toastr.warning('统一社会信用代码格式错误');
    //     return;
    //   }
    // }

    if (this.authcation_office_phone && !this.dmodel.office_phone) {
      this.toastr.warning('请填写办公电话');
      return;
    }
    if (this.secureMaterail) {
      if (!this.dmodel.proxy_document) {
        this.toastr.info("请上传招聘委托书");
        return;
      }
    }
    this.opera.loading();//加载动画
    if (this.IdentityLoading) {
      this.toastr.success('提交中');
      return;
    }
    if (this.authenticationApplyID) {
      this.model.authid = this.authenticationApplyID
    }
    console.log("申请院系", this.dmodel);
    this.dataApi.uploadDepartmentIdentity(this.dmodel).then((res: any) => {
      this.opera.loadiss();//停止加载动画
      this.IdentityLoading = false;
      if (res.value) {
        this.authenticationApplyID = res.value
      }
      setTimeout(() => {
        this.toastr.success('提交成功');
        // this.lastIdentity();//更新企业认证信息本地存储
        this.singleIdentity(this.dmodel.collegeid)//通过高校id获取高校详情

        this.showTab1 = false;
        this.showTab2 = true;
        //跳转页面
        // this.router.navigateByUrl("/home/company-identity"); //-------返回认证列表
      }, 600);
    }).catch((error) => {
      ////console.log(error);
      this.IdentityLoading = false;
      this.toastr.error(error);
      this.opera.loadiss();//停止加载动画

      //跳转页面
      //this.router.navigateByUrl("/home/college-campustalk"); //-------返回高校列表
      // setTimeout(() => {
      //   this.toastr.warning(error);
      // },600);
    });
  }
  //同步高校认证信息
  lastIdentity() {
    this.opera.loading();
    this.dataApi.lastAuthenticationIdentity().then((res: any) => {
      if (res) {
        this.config.lastCollegeIdentity = res;
        this.dataApi.setLastCollegeIdentity(this.config.lastCollegeIdentity);
      }
      this.opera.loadiss();
    }).catch((error) => {
      ////console.log(error);
      this.opera.loadiss();
    })
  }
  //单个高校认证信息
  singleIdentity(collegeid) {
    if (!collegeid) {
      return;
    }
    this.dataApi.lastCollegeAuthenticationIdentity(collegeid).then((res: any) => {
      console.log("获取上次认证信息", res);

      if (res) {
        if (res.facultyid) {
          this.dmodel.facultyid = res.facultyid;
        }
        this.dmodel.business_licence = res.business_license;
        this.dmodel.id_card_front = res.id_card_front;
        this.dmodel.register_number = res.register_number;
        if (res.proxy_document) {
          this.dmodel.proxy_document = res.proxy_document;
        }
        this.CollegeIdentityModel = res;

        if (this.CollegeIdentityModel.status == 0 || this.CollegeIdentityModel.status == 3) {

          this.router.navigate(['home/collegeidentity'], {
            queryParams: {
              isShow: this.CollegeIdentityModel.status == 0 ? false : true,
              istype: 'milkround',
              name: this.name
            }
          });
          return
        }


        if (this.CollegeIdentityModel.status == 2 || this.CollegeIdentityModel.status == 1) {
          this.showTab1 = false;
          this.showTab2 = true;
        } else if (this.CollegeIdentityModel.status == 3) {

          if (this.CollegeIdentityModel.no_apply_30day == true) {
            this.showTab1 = false;
            this.showTab2 = false;
            //获取到最后一次审核时间+30天，然后和当前时间判断，判断是否可再次提交,并no_apply_30day设置为false，
            let currentdata = new Date();
            let data = new Date(res.audit_time);
            data.setDate(data.getDate() + 30);
            if (currentdata > data) {
              console.log("用户勾选了30天可以申请",);
              this.showTab1 = true;
              this.showTab2 = false;
            }
            else {
              console.log("用户勾选了30天不可以申请",);
              this.showTab1 = false;
              this.showTab2 = false;
            }
          }
          else {
            this.showTab1 = true;
            this.showTab2 = false;
          }
        }
        else {
          this.showTab1 = true;
          this.showTab2 = false;
          //没有进行高校合作//
          // this.getCollegeStatus();
        }
        this.getMilkDetails();//获取双选会详情

      } else {
        this.router.navigate(['home/collegeidentity'], {
          queryParams: {
            isShow: false,
            istype: 'milkround',
            name:this.name
          }
        });
        this.getMilkDetails();//获取双选会详情
      }
    }).catch((error) => {
      ////console.log(error);
      console.log("获取上次认证信息error", error);
      this.getMilkDetails();//获取双选会详情

      this.opera.loadiss();
    })
  }
  formatFileName(url) {
    if (!url) {
      return '附件'
    }
    try {
      let arr = url.split('/');

      let name = arr[arr.length - 1];
      return name
    } catch (e) {
      return '附件'
    }
  }
  deleteFlie(url) {
    let newArr = [];
    this.model.attachment.map((item) => {
      if (item != url) {
        newArr.push(item)
      }
    });
    this.model.attachment = newArr
  }
  // 获取是否允许申请双选会 高校开关
  getMilkroundApplyAllowd(mrid) {
    let data = {
      mrid: mrid
    }
    this.dataApi.getMilkroundApplyAllowd(data).then((res: any) => {
      if (res) {
        console.log('getMilkroundApplyAllowd', res)
        this.milkroundApplyAllowd = res.value;
      }
    }).catch((error) => {
    });
  }
  // 删除自定义上传的附件
  deleteMilkPicClick(index, fileIndex) {
    let newArr = [];
    this.department_receipt_List[index].links.map((item, index) => {
      if (fileIndex != index) {
        newArr.push(item)
      }

    })
    this.department_receipt_List[index].links = newArr;
  }
  checkMilkroundSameNameApply() {
    this.dataApi.checkMilkroundSameNameApply(this.mrid).then((res: any) => {
      console.log('checkMilkroundSameNameApply', res)
      if (res) {
        this.isSameNameApply = res.value
      }
    }).catch((error) => {
    })
  }


  checkFriendClick(isFriend) {
    this.model.is_alumn = isFriend
  }

  addFriend() {
    this.model.friendList.push({ name: '', major: '', graduation_time: '', position: '', index: this.model.friendList.length + 1 })
  }

  deleteFriend(index) {
    console.log('index', index)
    this.model.friendList = this.model.friendList.filter((item, i) => {
      if (index != i) {
        return item
      }
    })
  }

  // 获取校友企业
  getMilkroundAlumnDepartment(collegeid) {
    let data = {
      collegeid: collegeid
    }
    this.dataApi.getMilkroundAlumnDepartment(data).then((res: any) => {
      if (res) {
        console.log('checkDepartmentcooperation', res)
        if (res.alumn_infos) {
          this.model.friendList = res.alumn_infos.filter((item) => {
            item.graduation_time = this.formatDate.formatDate(new Date(item.graduation_time))
            return item
          })
          if (res.alumn_infos.length > 0) {
            this.model.is_alumn = true
          }
        }
      }
    }).catch((error) => {
      // console.log('checkDepartmentcooperation',error);
    });
  }


  facultyidChange(facultyid){
    console.log(facultyid);
  }

}
