<!-- 二级单选 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>
    <div class="model-cont">
        <!-- 上层提示部分 -->
        <div class="model-head clear">
            <div class="left">{{title}}</div>
            <button class="right" (click)="dismiss(false)">×</button>
        </div>
        <div class="select-more-block  clear">
            <div id="first-level" class="first-level" style="overflow-y: scroll;">
                <div class="list-block" *ngFor="let item of firstLevel" (click)="getFirstLevel(item)" [class.activeAddress]="item.code==oneActive.code">
                  {{ item.text }}
                </div>    
              </div>
        
              <div #secend id="secend-level" class="secend-level" style="overflow-y: scroll;">
                <div class="list-block" *ngFor="let item of secendLevel" (click)="getSecendLevel(item)" [class.activeAddress]="item.code==twoActive.code">
                  <div class="list-text">{{ item.text }}</div>
                </div>   
              </div>
        </div>
        <!-- 底部按钮 -->
        <!-- <div class="select-submit"><button class="purple" (click)="dismiss(threeActive)">保存</button></div> -->
    </div>
  </div>