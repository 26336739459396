<br>
<div class="homepage-block">
  <div class="college-block-opera clear">
    <div class="left">
      <button [class.selcted]="model.sender_account_type==''" (click)="chooseStatus('')">全部</button>
      <button [class.selcted]="model.sender_account_type=='college'" (click)="chooseStatus('college')">高校</button>
      <button [class.selcted]="model.sender_account_type=='talent'" (click)="chooseStatus('talent')">学生</button>
      <button [class.selcted]="model.sender_account_type=='system'" (click)="chooseStatus('system')">系统</button>
    </div>
  </div>
  <div class="task-list">
    <div class="li" *ngFor="let n of taskList;let i = index;" [class.yellow]="n.pending">
      <div class="title">
        <div class="title-l">
          <img *ngIf="n.sender_account_type == 'college'" src="../../assets/images/notice/college.png">
          <img *ngIf="n.sender_account_type == 'system'" src="../../assets/images/notice/system.png">
          <img *ngIf="n.sender_account_type == 'talent'" src="../../assets/images/notice/talent.png">
          <span *ngIf="n.content" [ngClass]="{textRed:n.titleColor,textDefault:!n.titleColor}" >{{n.content.first}}</span>
        </div>
        <div class="title-r">{{n.add_time | timetransform}}</div>
      </div>
      <div *ngIf="n.content" class="content">
        <div  *ngFor="let item of n.content | keyvalue"><span *ngIf="item.key != 'first' && item.key != 'remark'">{{item.key+' : '+item.value}}</span><span *ngIf="item.key == 'remark'">{{item.value}}</span></div>
      </div>
      <div style="width: 100%;display: flex;justify-content:flex-end">
        <div *ngIf = "n.alias != 'lacoin_charge_success' && n.alias != 'college_audit_campustalk_passed' && n.alias != 'product_charge_success'" (click)="goHandle(n)"  class="action">{{n.pending ? '待处理': '查看详情'}}</div>
        <div *ngIf = "n.alias == 'lacoin_charge_success' || n.alias == 'product_charge_success'" (click)="goHandle(n)"  class="action">去使用</div>
        <div *ngIf = "n.alias == 'college_audit_campustalk_passed'" (click)="goHandle(n)"  class="action">去处理</div>
      </div>
    </div>
  </div>
  <!-- 分页部分 -->
  <div class='paging-block' [class.isPageHeight]="countList>0">
    <router-outlet></router-outlet>
    <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
  </div>
</div>
