import { Component, OnInit, Input, Output } from '@angular/core';
import { DataApi,Config,Opera,Local,Constants,milkround} from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from  '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-college-details-milk',
  templateUrl: './college-details-milk.component.html',
  styleUrls: ['./college-details-milk.component.css']
})
export class CollegeDetailsMilkComponent implements OnInit {
  public config: Config;
  public isInit = false;//是否初始化成功
  public posterUrl = ''; // 双选会海报下载链接
  @Output() modelShow = false;//是否打开弹窗
  @Output() milkNumber = 1;//当前申请的招聘会场次
  public model = {
    collegeid: '',
    college_name: '',
    offset: 0,
    limit: 9,
    school_type:'',
    milkround_type:"",
    start_time:null,
    end_time:null,
  }
  public dateSattus = 'all';//日期状态
  public keywordList=[];//搜索关键词匹配列表

  public milkRoundList = [];//宣讲会列表
  public historyMilkRoundList = [];
  public countList = 0;//列表数量
  @Output() mridList = [];//招聘会id列表
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public collegeid = "";//高校id
  public college: any;//高校详情
  public collegeIdentity: any;//企业当前高校认证数据

  //确认参会参数
  public QrCodeURL = "";// 通过后可分享的二维码双选会信息
  public showConfirmMilkroundModel = false;
  public currentMilkround = null;
  public showMilkCode = false;
  public revokeModule = false;
  public revokeApplyid = '';

  constructor(public dataApi: DataApi,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public local: Local,
              public dialog: MatDialog,
              private sanitizer: DomSanitizer,
              public opera: Opera,
              public milkroundFormat:milkround,
              public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.opera.initConfig('college', 'college-details-milk');
    this.collegeid = this.local.get(Constants.collegeid);//赋值高校id
    if(this.collegeid) {
      this.checkDepartmentIndentityByCollege(this.collegeid);//检查高校认证信息
      this.model.collegeid = this.collegeid;
      this.getCollegeById();//获取高校详情
      this.getResumeList();//获取列表
      this.getMyMilkroundHistory(this.collegeid);
    }
  }

  //接收返回参数
  Modeldismiss(event) {
    this.modelShow = false;
    if (event) {
      for (let n of this.milkRoundList) {
        for (let m of this.mridList) {
          if (n.mrid == m) {
            n.status = 'to_audit';
          }
        }
      }
    }
    this.mridList = [];//清空
  }

  //去报名
  goSign(n) {
    // if (this.config.positionList.length == 0) {
    //   this.opera.remindPublish("报名双选会");
    //   return;
    // }
    this.goMilkSign(n);
    if (this.collegeIdentity) {//判断check
      if (this.collegeIdentity.is_activity) {//活动高校
        this.goMilkSign(n);//
      } else {//非活动高校
        if (this.collegeIdentity.status == '2') {//通过校招
          this.goMilkSign(n);//报名
        } else if (this.collegeIdentity.status == '1') {//校招审核中
          // this.toastr.warning("校招审核中，审核通过后才可报名双选会");
          this.goMilkSign(n);//报名
        } else {//未提交校招申请
          // this.opera.remindCollegeIdentity("报名双选会",this.college.collegeid,this.collegeIdentity);
          this.goMilkSign(n);//报名
        }
      }
    } else {//未请求到企业认证信息
      this.opera.remindCollegeIdentity("报名双选会", this.college.collegeid, this.collegeIdentity);
    }

  }

  //去双选会报名页
  goMilkSign(n) {
    if(n.milkround_type == 1){
      if (n.scale <= n.department_participate_count) {
        this.toastr.warning('坐席已满，无法报名！');
        return
      }
    }
    this.local.set(Constants.collegeid, n.collegeid);
    this.local.set(Constants.mrid, n.mrid);
    this.local.remove(Constants.applyid);
    this.router.navigate(['home/college-milk-sign'], {
      queryParams: {
        milkname: n.name
      }
    });
  }


  //进入会场
  goSpace(id) {//招聘会会场无需认证
    this.router.navigateByUrl('home/college-milk-space');
    this.local.set(Constants.mrid, id);//覆盖本地存储招聘会id
  }

  //判断企业是否通过高校认证
  checkDepartmentIndentityByCollege(id) {
    this.opera.loading();
    this.dataApi.checkDepCollegeIdentity(id).then((res:any)=> {
      this.collegeIdentity = res;
      this.opera.loadiss();
    }).catch((err) => {
      //console.log(err);
      this.opera.loadiss();
    })
    this.dataApi.lastCollegeAuthenticationIdentity(this.collegeid).then((res:any)=>{
      if(res){
        // this.config.lastCollegeIdentity=res;
        if(res.status==3){
          this.router.navigate(['home/collegeidentity'],{
            queryParams: {
              isShow:true,
              callbackUrl:'Milkround'
            }
          });
        }
      }
      this.opera.loadiss();
    }).catch((error)=>{
      //console.log(error);
      this.opera.loadiss();
    })
  }

  //去双选会详情
  goDeatils(id) {
    this.router.navigate(['home/college-milkdetails'], {
      queryParams: {
        id: id
      }
    })
  };


  //获取列表
  getResumeList() {
    this.opera.loading();
    this.model.collegeid = this.collegeid;
    this.dataApi.getMilkRoundList(this.model,this.collegeid).then((res:any)=> {
      console.log("获取高校双选会列表---", res);
      //console.log(res);
      let newList =  this.milkroundFormat.formatList(res.list);
      this.milkRoundList = newList;

      // for (let val of this.milkRoundList) {
      //   let nowDate = new Date();
      //   let startDate = new Date(val.start_time);
      //   let endDate = new Date(val.end_time);
      //   let applyOpDate = new Date(val.apply_start_time); // 报名开始时间
      //   let applyEndDate = new Date(val.apply_end_time); // 报名结束时间
      //   let nowTime = nowDate.getTime();  // 当前时间戳
      //   let startTime = startDate.getTime();  // 开始时间戳
      //   let endTime = endDate.getTime();  // 结束时间戳
      //   let applyOpTime = applyOpDate.getTime();  // 报名开始时间戳
      //   let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳
      //   val.signOpTime = false;
      //   if (applyOpTime == -62135625943000 || applyOpTime == -62135625600000 || val.apply_start_time == '0001-01-01T00:00:00' || val.apply_start_time == '0001-01-01T00:00:00+08:00' || val.apply_start_time == '') {
      //     val.signOpTime = false; // 未设置报名开始时间
      //   } else {
      //     val.signOpTime = true;
      //   }
      //   if (applyEndTime == -62135625943000 || applyEndTime == -62135625600000 || val.apply_end_time == '0001-01-01T00:00:00' || val.apply_end_time == '0001-01-01T00:00:00+08:00' || val.apply_end_time == '') {
      //     val.signEdTime = false; // 未设置报名结束时间
      //   } else {
      //     val.signEdTime = true;
      //   }
      //   if (nowTime - startTime > 0) {
      //     val.isApplySign = false;
      //     val.isStart = true; // 会议已开始
      //   } else {
      //     val.isStart = false;  // 会议未开始
      //     val.isApplySign = true;
      //   }
      //   //增加是否是活动判断
      //   if (val.is_activity) {
      //     if (endTime - nowTime > 0) {
      //       val.isApplySign = true;
      //     } else {
      //       val.isApplySign = false;
      //     }
      //   }
      //   if (nowTime - endTime > 0) {
      //     val.isEnd = true; // 会议已结束
      //   } else {
      //     val.isEnd = false; // 会议未结束
      //   }
      //   if (val.signOpTime) { // 有报名时间和报名结束时间
      //     if (nowTime - applyOpTime > 0) {
      //       val.isAppStart = true; // 报名已开始
      //     } else {
      //       val.isAppStart = false; // 报名未开始
      //     }
      //     if (nowTime - applyEndTime > 0) {
      //       val.isAppEnd = true; // 报名已结束
      //     } else {
      //       val.isAppEnd = false; // 报名未结束
      //     }
      //   }
      // }


      this.countList = res.count;
      this.pageCount = Math.ceil(res.count / 9);
      this.opera.loadiss();
    }).catch((err) => {
      this.opera.loadiss();
      console.log('getResumeList', err);
    })
  }

  //通过高校id获取高校详情
  getCollegeById() {
    this.opera.loading();
    this.dataApi.getCollageDetails(this.collegeid).then((res:any)=> {
      //console.log("获取到高校信息---");
      //console.log(res);
      this.isInit = true;
      this.college = JSON.parse(JSON.stringify(res));

      this.opera.loadiss();
    }).catch((err) => {
      this.toastr.error(err);
      this.isInit = true;
      this.opera.loadiss();
    });
  }

  //获取分页子组件返回数据
  getChildEvent(index) {
    //console.log('子组件返回分页数-----');
    //console.log(index);
    if (index > 0) {
      this.pageNumber = index;
      this.model.offset = (this.pageNumber - 1) * 9;
      this.getResumeList();//获取简历投递列表
    } else if (index == -1) {//上一页
      if (this.pageNumber == 1) {
        this.toastr.warning("当前为第一页");
      } else {
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber - 1) * 9;
        this.getResumeList();//获取简历投递列表
      }
    } else if (index == -2) {//下一页
      if (this.pageNumber == this.pageCount) {
        this.toastr.warning("当前为最后一页");
      } else {
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber - 1) * 9;
        this.getResumeList();//获取简历投递列表
      }
    }
  }

  // 获取双选会海报下载链接
  downloadPoster(applyid) {
    this.opera.loading();
    this.dataApi.getMilkPoster(applyid).then((res:any)=> {
      // this.posterUrl = res.value;
      // this.downloadIamge(this.posterUrl,'海报')
      // this.opera.loadiss();
      if (res.value) {
        let b = window.open();
        setTimeout(function () {
          b.location.href = res.value;
        }, 500);
        this.opera.loadiss();
      }
    }).catch((err) => {
      console.log(err);
      this.opera.loadiss();
    })
  }

  downloadIamge(imgsrc, name) {//下载图片地址和图片名
    let image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function () {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      let a = document.createElement("a"); // 生成一个a元素
      let event = new MouseEvent("click"); // 创建一个单击事件
      a.download = name || "photo"; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = imgsrc;
  }


  //确认才会逻辑
  /**
   * 确认参会按钮
   * @param n
   */
  okCanhui(n) {
    if(n.milkround_type == 2){
      this.local.set(Constants.mrid, n.mrid);//覆盖本地存储招聘会id
      this.router.navigateByUrl('home/college-milk-space');
    }else{
      this.QrCodeURL = "";
      this.showConfirmMilkroundModel = true;
      this.currentMilkround = n
    }
  }

  //已经参会
  ConfirmMilkroundCommit(type) {
    let data = {
      mrid: this.currentMilkround.mrid,
      type: type,
    };
    this.dataApi.confirmMilkround(data, this.currentMilkround.mrid, type).then((res:any)=> {
      console.log("确认参会", res);
      this.showConfirmMilkroundModel = false;
      if (type == "no_join") {
        this.toastr.error("请合理安排时间，如经常不参加双选会将对您企业的信用造成损失")
      } else {
        this.toastr.success("已确认参加成功")
      }
      for (let index = 0; index < this.milkRoundList.length; index++) {
        let item = this.milkRoundList[index];
        if (item.mrid == this.currentMilkround.mrid) {
          item.feedback_status = type;
        }
      }
      console.log('已确认参会回执',this.milkRoundList)
      if (type == "join") {
        this.currentMilkround.feedback_status = 'join';
        this.QrCodeURL = res.value;
        this.showMilkCode = true;
      }
    }).catch((err) => {
      console.log("确认参会失败", err);
      this.toastr.error(err)
    })
  }
  //关闭确认参会按钮的二维码
  closeModel(){
    this.QrCodeURL = "";
    this.showMilkCode = false;
  }
  ConfirmMilkroundCommit2(item){
    let type= 'join'
    let data = {
      mrid:item.mrid,
      type:type,
    };
    this.dataApi.getMilkroundFeedBackPost(item.mrid).then((res:any)=>{
      this.toastr.success("已确认参加成功")
      if(res.value) {
        this.QrCodeURL = res.value;
        this.showMilkCode = true;
      }
    }).catch((err)=>{
      console.log("确认参会失败",err);
    })
  }
  chooseType(status){
    this.model.offset = 0;
    this.pageNumber = 1;
    this.model.milkround_type = status;
    // this.local.set('milkround_type_search',status)
    this.getResumeList()
  }
  getMyMilkroundHistory(collegeid){
    let data = {
      collegeid:collegeid
    }
    this.dataApi.getMyMilkroundHistory(data).then((res:any)=>{
      if(res){
       let newList =  this.milkroundFormat.formatList(res);
        this.historyMilkRoundList = newList;
        console.log("getMyMilkroundHistory",newList);
      }
    }).catch((err)=>{
      console.log("getMyMilkroundHistory",err);
    })
  }
  chooseDate(status){
    this.dateSattus = status;
    this.local.set('milkround_dateSattus_search',status)
    this.model.offset = 0;
    this.pageNumber = 1;
    if(status == 'now'){
      var date = new Date(), y = date.getFullYear(), m = date.getMonth();
      var firstDay = new Date(y, m, 1);
      var lastDay = new Date(y, m + 1, 0);
      this.model.start_time = firstDay.getFullYear() +'-'+(firstDay.getMonth()+1)+'-'+firstDay.getDate();
      this.model.end_time = lastDay.getFullYear() +'-'+(lastDay.getMonth()+1)+'-'+lastDay.getDate();
      this.local.set('milkround_dateSattus_search_start',this.model.start_time)
      this.local.set('milkround_dateSattus_search_end',this.model.end_time)
      this.getResumeList();
    }else if(status == 'next'){
      var date = new Date(), y = date.getFullYear(), m = date.getMonth();
      var firstDay = new Date(y, m+1, 1);
      var lastDay = new Date(y, m + 2, 0);
      this.model.start_time = firstDay.getFullYear() +'-'+(firstDay.getMonth()+1)+'-'+firstDay.getDate();
      this.model.end_time = lastDay.getFullYear() +'-'+(lastDay.getMonth()+1)+'-'+lastDay.getDate();
      this.local.set('milkround_dateSattus_search_start',this.model.start_time)
      this.local.set('milkround_dateSattus_search_end',this.model.end_time)
      this.getResumeList();
    }else if(status == 'all'){
      this.model.start_time = '';
      this.model.end_time = '';
      this.local.set('milkround_dateSattus_search_start','')
      this.local.set('milkround_dateSattus_search_end','')
      this.getResumeList();
    }
  }
  goSearch(text){
    this.model.offset = 0;
    this.pageNumber = 1;
    this.model.college_name = text;
    this.local.set('milkround_college_name_search',text)
    this.getResumeList();//获取双选会列表
    this.keywordList=[];
  }
  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      that.goSearch(that.model.college_name);
    }else{
      that.getItems();
    }
  }
  //匹配关键字列表
  getItems(){
    this.keywordList=[];
    if(!this.model.college_name){return;}
    for(let s of this.config.school){
      if(s.text.indexOf(this.model.college_name) >= 0){
        this.keywordList.push(s);
      }
    }
  }
  gotoApplyHistory(){
    this.router.navigate(['home/position-milk'])
  }
  gotoPro(){
    if(this.local.get('accesstoken')) {
      let token = this.local.get('accesstoken');
      let accountid = this.local.get('accountid');
      let collegeid = this.local.get('collegeid');
      window.open(this.config.JumpMainWeb+token+'&accountid='+accountid+'&route='+'college-milkround','_blank')
    }
  }

  // 撤回
  openRevoke(applyid){
    this.revokeApplyid = applyid;
    this.revokeModule = true;
  }

  downMilk(){
    this.dataApi.revokeMilk(this.revokeApplyid).then((res:any)=>{
      this.getMyMilkroundHistory(this.collegeid);
      this.revokeModule = false;
    }).catch((err)=>{
      console.log("downMilk",err);
    });
  }
}
