<!-- 页头部分 -->
<div class="contentRight-header">企业中心 - 网络支付</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <router-outlet></router-outlet>
    <app-model-pay-wechat #payWechat [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-pay-wechat>
    <div class="college-block">
        <!-- 页头部分 -->
        <div class="college-block-head">网络支付</div>
        <!-- 商品介绍 -->
        <div class="college-block">
            <!-- 信息展示部分 -->
            <div class="college-list">
              <div class="college-title">确认支付</div>
              <div class="college-message clear">
                <div class="left">商品名称</div>
                <div *ngIf="product_name" class="left">{{product_name}}</div>
                <div *ngIf="!product_name" class="left">暂无</div>
              </div>
              <div class="college-message clear">
                  <div class="left">商品金额</div>
                  <div *ngIf="model.amount" class="left red">{{model.amount}}元</div>
                  <div *ngIf="!model.amount" class="left red">暂无</div>
              </div>
              <div class="college-message">
                  <div class="pay-bg"> 请选择支付方式</div>
              </div>
              <div *ngIf="payList" class="pay-list">
                <div *ngFor="let pay of payList" class="college-message pay-path">
                    <div (click)="model.channel=pay.alias" [class.path-active]="model.channel==pay.alias" class="payCont-path clear">
                        <div class="left"><img [src]="pay.ico">{{pay.name}}</div>
                        <div class="right">支付<span class="red">{{model.amount}}元</span></div>
                    </div>   
                </div>
              </div>
            </div>
            <!-- 确认付款 -->
            <div class="sign-submit">
                <button class="purple" (click)="pay()">确认付款</button>
            </div>
        </div>
    </div>
</div>