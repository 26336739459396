<!-- 页头部分 -->
<div class="contentRight-header">校园招聘 - 校园+ - 学校详情</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-college-identity [name]="collegeName" [orgList]="orgList" [item]="collegeIdentity" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-identity>
  <!-- HTML -->
  <div class="container">
    <div class="resume-info-model" *ngIf="isInit">
       <!-- 展示高校信息 -->
       <div class="home-resume-list">
        <div class="up-block">
          <!--头像-->
          <div class="left-block">
              <img *ngIf="college.logo" class="resumeImg" [src]="college.logo">
              <img *ngIf="!college.logo" class="resumeImg" src='assets/images/none.png'/>
            </div>
            <!--高校信息-->
            <div class="right-block">
                <div class="name-text">{{college.name}}</div>
                <div class="info-text">
                    <span *ngIf="college.region_text">{{college.region_text}}</span>
                    <span *ngIf="college.school_nature_text">/&nbsp;&nbsp;&nbsp;{{college.school_nature_text}}</span>
                </div>
                <ul class="clear">
                  <!-- <li class="left"><a routerLink=""><img src="assets/images/home-calendar.png"><br/>招聘日历</a></li> -->
                  <li class="left" (click)="goOrg()"><img src="assets/images/college-details-org.png"><br/>教学机构</li>
                  <li class="left" (click)="goMilk()"><img src="assets/images/college-details-milk.png"><br/>双选会</li>
                  <li class="left" (click)="goCampus()"><img src="assets/images/college-details-campus.png"><br/>宣讲会</li>
                  <!--<li class="left" (click)="goStudent()"><img src="assets/images/college-details-student.png"><br/>生源速览</li>-->
                  <li class="left" (click)="goNews()"><img src="assets/images/college-details-news.png"><br/>新闻公告</li>
                  <li class="left" (click)="goCollegeIdentity()"><img src="assets/images/college-details-identity.png"><br/>申请合作</li>
                </ul>
            </div>
            <!-- 右上角操作区域 -->
            <div class="top-block">
              <!-- <button><img src="assets/images/resume-share.png">分享</button> -->
              <button *ngIf="!college.is_subscribe" (click)="getCollegeAccount(college)"><img src="assets/images/college-subscribe.png">订阅</button>
              <button *ngIf="college.is_subscribe" (click)="getCollegeAccount(college)"><img src="assets/images/college-subscribed.png">取消订阅</button>
            </div>
        </div>
      </div>
    </div>
    <!-- 高校部分 -->
    <div class="college-block">
      <!-- 信息展示部分 -->
      <div class="college-list">
        <div class="college-title">基本信息</div>
        <div *ngIf='college.common_name' class="college-message clear">
          <div class="left">简称</div>
          <div class="left" *ngIf="college.common_name">{{college.common_name}}</div>
          <div class="left" *ngIf="!college.common_name">暂无</div>
        </div>
        <div *ngIf='college.code' class="college-message clear">
            <div class="left">学校代码</div>
            <div class="left" *ngIf="college.code">{{college.code}}</div>
            <div class="left" *ngIf="!college.code">暂无</div>
        </div>
        <div *ngIf='college.establish_time' class="college-message clear">
            <div class="left">创办时间</div>
            <div class="left" *ngIf="college.establish_time">{{college.establish_time | date:'yyyy-MM-dd'}}</div>
            <div class="left" *ngIf="!college.establish_time">暂无</div>
        </div>
        <div *ngIf='college.email' class="college-message clear">
            <div class="left">联系邮箱</div>
            <div class="left" *ngIf="college.email">{{college.email}}</div>
            <div class="left" *ngIf="!college.email">暂无</div>
        </div>
        <div *ngIf='college.work_info&&college.work_info.office_tel' class="college-message clear">
            <div class="left">就业电话</div>
            <div class="left" *ngIf="college.work_info.office_tel">{{college.work_info.office_tel}}</div>
            <div class="left" *ngIf="!college.work_info.office_tel">暂无</div>
        </div>
      </div>
      <!-- 信息展示部分 -->
      <div class="college-list">
          <div class="college-title">高校简介</div>
          <div class="college-message" [innerHtml]="assembleHTML(college.intro)"></div>
      </div>
      <!-- 操作部分 -->
      <!-- <div class="college-opera">
          <button class=purple *ngIf="college.status==0||college.status==3" (click)="goCollegeIdentity(college.collegeid)">申请校招</button>
          <button *ngIf="college.campustalk_switch" (click)="goSigin()">申请宣讲会</button>
          <button>双选会</button>
      </div> -->
    </div>
  </div>
</div>
