import { Component, OnInit,Input, Output } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute,Router } from  '@angular/router';
// import { ModelAuthExplainComponent } from '../model-auth-explain/model-auth-explain.component';
// import { ModelAuthAgreenmentComponent } from '../model-auth-agreenment/model-auth-agreenment.component';
declare var window;
@Component({
  selector: 'app-company-authentication',
  templateUrl: './company-authentication.component.html',
  styleUrls: ['./company-authentication.component.css']
})
export class CompanyAuthenticationComponent implements OnInit {
  public config: Config;
  public identityValue:any;//保存当前上传照片值
  public model={
    organization_type:'',// 组织机构类型
    department_name:'',//单位名称
    register_number:'',//统一社会信息代码
    business_licence:'',//营业执照
    annex1:'',//附件1
    annex2 :'',// 附件2
    operator_name:'',//姓名
    id_number :'',//身份证号码
    id_card:'',// 身份证正面
    annex3:'',// 附件3
    annex4:'',// 附件4
  }
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelTwoShow = false;//是否打开弹窗
  public isConfirm=false;
  public materail:any;//认证材料
  public secureMaterail:any;//保险行业认证材料
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {
    this.opera.initConfig('company','company-authentication');
    this.getDepartment();//获取企业信息
    this.getUser();//获取hr信息
    this.getOrgType();//获取组织机构类型
    this.getApplyPlatformDetails();//获取平台认证信息并赋值
    this.getIdentityMaterial();//获取认证上传的材料标准
  }
  //查看企业认证协议
  goDepartmentPact(){
    this.opera.scrollTop();//页面滚动函数
    this.modelTwoShow = true;
  }
  //接收子组件返回数据
  ModelTwodismiss(event){
    this.modelTwoShow = false;
    this.opera.scrollTrue();
  }
  //查看企业认证资质说明
  goDepartmentExplain(){
    this.opera.scrollTop();//页面滚动函数
    this.modelShow = true;
  }
  //接收子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
  }
  //提交认证
  uploadIdentity(){
    let filter1 = /^[0-9a-zA-Z]{9}$/;
    let filter2 = /^[0-9a-zA-Z]{15}$/;
    let filter3 = /^[0-9a-zA-Z]{18}$/;
    if(!this.isConfirm){
      this.toastr.warning("请查看并同意企业认证协议");
      return;
    }
    if(this.model.organization_type=='11'){
      this.toastr.warning("个体工商户暂未开放企业认证");
      return;
    }
    if(!this.model.department_name){
      this.toastr.warning("请填写单位名称");
       return;
    }
    if(!this.model.organization_type){
      this.toastr.warning("请选择单位类型");
      return;
    }
    //保险行业
    if(this.config.department.industry=='0202'){
      if(!this.model.register_number){
        this.toastr.warning("请填写统一社会信用代码");
        return;
      }
      if(!this.opera.isRegister_number(this.model.register_number)){
        this.toastr.warning('统一社会信用代码格式不正确');
        return;
      }
      for(let m of this.secureMaterail.material_infos){
        if(m.required){
           if(!this.model[m.material_field]){
            this.toastr.warning("请上传"+m.material_name);
             return;
           }
        }
      }
    }else{//非保险行业
      for(let list of this.materail){
        if(this.model.organization_type==list.item_code){
          //循环判断统一社会信用代码是否必填
          for(let num of list.field_infos){
             if(num.field=='register_number'&&num.required){
                if(!this.model.register_number){
                  this.toastr.warning("请填写统一社会信用代码");
                  return;
                }
               if(!this.opera.isRegister_number(this.model.register_number)){
                 this.toastr.warning('统一社会信用代码格式不正确');
                 return;
               }
             }
          }
          //循环判断图片资料是否必填
           for(let m of list.material_infos){
              if(m.required){
                 if(!this.model[m.material_field]){
                  this.toastr.warning("请上传"+m.material_name);
                   return;
                 }
              }
           }
        }
      }
    }
    if(!this.model.operator_name){
      this.toastr.warning("请填写姓名");
      return;
   }
   if(!this.model.id_number){
     this.toastr.warning("请填写身份证号码");
     return;
   }
   if(!this.model.id_card){
     this.toastr.warning("请上传身份证");
     return;
   }
   if(this.config.lastIdentity&&this.config.lastIdentity.status==3){
    this.dataApi.authenticationUpdatePlatformApply(this.model).then((res:any)=>{
      //console.log("认证提交成功");
      //console.log(res);
      //跳转提交成功页面
      this.router.navigateByUrl("home/company-status-one");//审核中
    }).catch((err)=>{
     //console.log("认证提交失败");
     //console.log(err);
     this.toastr.error(err);
    })
   }else{
    this.dataApi.authenticationPlatformApply(this.model).then((res:any)=>{
      //console.log("认证提交成功");
      //console.log(res);
       //跳转付款页面/提交成功页面
      if(this.model.organization_type=='10'){
        this.router.navigateByUrl("home/company-status-one");//审核中
      }else{
        this.local.set(Constants.orderid,res.value);
        this.router.navigateByUrl("home/company-pay");
      }
    }).catch((err)=>{
     //console.log("认证提交失败");
     //console.log(err);
     this.toastr.error(err);
    })
   }
  }
  //上传图片并赋值
  previewDepartmentPic(event,text){
    let imgData  = event.target.files[0]; //获取file对象
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.uploadDepImg(reader.result,text);
      };

    }
  }
  //上传图片
  uploadDepImg(data,text){
    //console.log("准备上传图片");
    //console.log(text);
    let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
    this.opera.loading();
    this.dataApi.uploadAuthImg({  value: imageData}).then((res:any)=>{
      this.opera.loadiss();
      this.model[text]=res.value;
    }).catch((error)=>{
      this.opera.loadiss();
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    })
  }
  //获取认证上传的材料标准
  getIdentityMaterial(){
    let model={collegeid:''};
    this.dataApi.getMaterial('authentication',model).then((res:any)=>{
      //console.log("获取认证材料成功");
      //console.log(res);
      if(res){
        this.materail=res;
        for(let list of res){
          if(list.item_code=='99'){
            this.secureMaterail=list;
          }
        }
      }
    }).catch((err)=>{
      //console.log("获取认证材料失败");
      //console.log(err);
    })
  }
  //获取平台认证信息并赋值
  getApplyPlatformDetails(){
    if(!this.config.lastIdentity){
      this.dataApi.getPlatformApply().then((res:any)=>{
        //console.log("获取企业平台认证信息");
        //console.log(res);
        if(res){
            if(res.status==3){
              this.model.organization_type=res.organization_type;
              this.model.department_name=res.department_name;
              this.model.register_number=res.register_number;
              this.model.business_licence=res.business_licence;
              this.model.annex1=res.annex1;
              this.model.annex2=res.annex2;
              this.model.operator_name=res.operator_name;
              this.model.id_number=res.id_number;
              this.model.id_card=res.id_card;
              this.model.annex3=res.annex3;
              this.model.annex4=res.annex4;
              // this.isConfirm=true;
            }
            this.config.lastIdentity=res;
            if(this.config.department.industry=='0202'){
              this.config.lastIdentity.organization_type_text="企业";
              this.config.lastIdentity.organization_type="01";
              this.model.organization_type='01';
            }
            this.dataApi.setLastIdentity(this.config.lastIdentity);
        }
      }).catch((error)=>{
        //console.log("获取企业平台认证信息出错");
        console.log(error);
      })
    }else{
      if(this.config.lastIdentity.status==3){
        this.model.organization_type=this.config.lastIdentity.organization_type;
        this.model.department_name=this.config.lastIdentity.department_name;
        this.model.register_number=this.config.lastIdentity.register_number;
        this.model.business_licence=this.config.lastIdentity.business_licence;
        this.model.annex1=this.config.lastIdentity.annex1;
        this.model.annex2=this.config.lastIdentity.annex2;
        this.model.operator_name=this.config.lastIdentity.operator_name;
        this.model.id_number=this.config.lastIdentity.id_number;
        this.model.id_card=this.config.lastIdentity.id_card;
        this.model.annex3=this.config.lastIdentity.annex3;
        this.model.annex4=this.config.lastIdentity.annex4;
        // this.isConfirm=true;
      }
      if(this.config.department.industry=='0202'){
        this.config.lastIdentity.organization_type_text="企业";
        this.config.lastIdentity.organization_type="01";
        this.model.organization_type='01';
      }
    }
  }

  //获取组织机构类型
  getOrgType(){
    if(this.config.organization_type.length==0){
      this.dataApi.getDictDate('organization_type').then((res:any)=>{
        //console.log("获取组织机构字典");
        //console.log(res);
        this.config.organization_type=res;
      }).catch((err)=>{
        //console.log("获取组织机构字典出错");
        //console.log(err);
      })
    }else{
      //console.log("获取组织机构字典本地存储");
      //console.log(this.config.organization_type);
    }
  }

  //获取账户信息
  getUser(){
    if(this.dataApi.getLocalLoginUser()){
      //console.log("企业hr信息本地存储");
      //console.log(this.dataApi.getLocalLoginUser());
      this.config.loginUser=this.dataApi.getLocalLoginUser();
      this.model.operator_name=this.config.loginUser.name;
    }else{
      this.dataApi.getUser().then((res:any)=>{
        //console.log("线上数据hr信息");
        //console.log(res);
        this.model.operator_name=res.name;
        this.dataApi.setLocalLoginUser(res);//将user信息存到本地、全局变量
      }).catch((error)=>{
        this.toastr.error(error);
      });
    }
  }

  //获取企业信息
  getDepartment(){
    if(this.dataApi.getLocalDepartment()){
      //console.log("企业信息本地存储");
      //console.log(this.dataApi.getLocalDepartment());
      this.config.department=this.dataApi.getLocalDepartment();
      this.model.department_name=this.config.department.name;
    }else{
      this.opera.loading();
      this.dataApi.getDepartment().then((res:any)=>{
        this.opera.loadiss();
        //console.log("企业信息获取线上数据");
        //console.log(res);
        this.model.department_name=res.name;
        this.dataApi.setLocalDepartment(res);
      }).catch((error) => {
        this.opera.loadiss();
       console.log(error);
       this.toastr.error(error);
      });
    }
  }

}
