import { Component,EventEmitter, Input, Output } from '@angular/core';
import { DataApi,Constants,Local} from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-model-college-milksign',
  templateUrl: './model-college-milksign.component.html',
  styleUrls: ['./model-college-milksign.component.css']
})
export class ModelCollegeMilksignComponent{
  @Input()  number:number;//招聘会申请场次
  @Input()  isShow: false;//是否显示弹窗页面
  @Input()  mridList=[];//招聘会id
  public department:any;//公司信息
  public model={
    contacts:'',//联系人
    attendance:'',//参会人数
    phone:'',
    is_invite:false//是否是邀请的企业（邀请指小秘书邀请）
  }
  public mrid='';//招聘会id
  public applyid='';//招聘会申请id
  @Output() onVoted = new EventEmitter<boolean>();
  constructor(public toastr: ToastrService,
    public local:Local,
    public dataApi: DataApi) {
      //获取hr信息
      if(this.dataApi.getLocalLoginUser()){
        //console.log("获取公司HR联系电话");
        if(this.dataApi.getLocalLoginUser().mobile){
          this.model.phone = this.dataApi.getLocalLoginUser().mobile;//联系电话
        }
      }
       this.getDepartment();//获取企业信息
     }
    
     //初始化赋值变量值
     initData(data){
       this.applyid=data.applyid;
       this.model.attendance=data.attendance;
       this.model.contacts=data.contacts;
       this.model.phone=data.phone;
     }

   //获取企业信息
   getDepartment(){
      if(this.dataApi.getLocalDepartment()){
        this.department = this.dataApi.getLocalDepartment();
        //console.log("企业信息本地存储");
        //console.log(this.dataApi.getLocalDepartment());
      }else{
        this.dataApi.getDepartment().then((res:any)=>{
          //console.log("企业信息获取线上数据");    
          //console.log(res);
          this.department = res;
          this.dataApi.setLocalDepartment(res);
        }).catch((error) => {
          //console.log(error);
          this.toastr.error(error);
        });
      }     
   } 


   //清空数据
   clearData(){
    this.model.contacts='';
    this.model.attendance='';
    this.model.is_invite=false;
    this.mrid='';//招聘会id
    this.applyid='';//招聘会申请id
   }
   
   //提交申请
    getSignModel(){
      //console.log(this.model);
      let filter=/^[a-zA-Z\u4e00-\u9fa5]+$/;
      if(!this.model.contacts){
        this.toastr.warning("请填写联系人");
        return;
      }else if(!filter.test(this.model.contacts)){
        this.toastr.warning("联系人姓名只能输入汉字或字母");
        return;
      }

      if(!this.model.phone){
        this.toastr.warning("请填写联系电话");
        return;
      }else{
        let phoneModel={
          format_type:1,//0:手机号，1：手机号&固话；2.邮箱
          data:this.model.phone
        }
        this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
          if(!res.value){
            this.toastr.warning("联系电话格式错误");
            return;
          }
        }).catch((err)=>{
          //console.log(err);
          this.toastr.error(err);
        })
      }
      
      if(!this.model.attendance){
        this.toastr.warning("请填写参会人数");
        return;
      }
      if(this.mridList.length>0){
        this.mrid = this.mridList[0];
      }
      if(this.applyid){
        this.dataApi.updateMilkSign(this.applyid,this.model).then((res:any)=>{
          this.toastr.success("修改成功");
          this.clearData();
          this.dismiss(true);
        }).catch((error)=>{
          //console.log(error);
          this.toastr.error(error);
          this.dismiss(false);
        })
      }else{
        //console.log("双选会报名前检查参数");
        //console.log(this.mridList);
        this.dataApi.signMilk(this.mrid,this.model).then((res:any)=>{
          //console.log(res);
          this.clearData();
          this.toastr.success("报名成功");
          this.dismiss(true);
        }).catch((error)=>{
          //console.log(error);
          this.toastr.error(error);
          this.dismiss(false);
        })
      }
      
    }

    dismiss(result: any) {
      //console.log("传值检查");
      //console.log(result);
      this.onVoted.emit(result);
    }
}
