
<div class="content">
  <div class="title">空中宣讲会往期列表</div>
  <div class="airCell flex_l" *ngFor="let n of campList" (click)="gotoDetail(n)">
    <img class="cellImage" src="{{n.video_cover}}" />
    <div class="celltitle">{{n.title}}</div>
    <div class="celltime">{{n.apply_time | date:'yyyy-MM-dd HH:mm'}} </div>
    <div class="cellState">已下线</div>
  </div>
  <div id="resumePage" (show)="campCount>0" style="float: right;margin-right: 0.5%"></div>
  <div class="noneCamp" *ngIf="campCount==0" >
    <div>
      无往期数据
    </div>
  </div>
</div>
