import { Component, OnInit } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { DataApi,Local,Constants,Config } from '../../provider/index';
import { Router } from  '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-identity-status',
  templateUrl: './identity-status.component.html',
  styleUrls: ['./identity-status.component.css']
})
export class IdentityStatusComponent implements OnInit {
  public config: Config;
  constructor( private router: Router,
    private location: PlatformLocation,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
      this.config.isGuide=true;
    }

  ngOnInit() {
  }
  //进入后台
  goHome(){
    // this.router.navigateByUrl('home');
    this.router.navigate(['home/position-publish']);
    this.toastr.warning("当前没有在期职位，请发布职位");
  }

}
