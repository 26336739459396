<!-- 面试邀请 -->
<!-- 遮罩层公共样式 -->
<div class="model-block" [class.ishidden]='!isShow'>
  <div class="black-bg" (click)="dismiss(false)"></div>
  <div class="model-cont">
    <!-- 上层提示部分 -->
    <div class="model-head clear">
      <div class="left">{{feedbackStatus}}</div>
      <button class="right" (click)="dismiss(false)">×</button>
    </div>
    <!-- 编辑面试邀请 -->
    <div class="model-content" [class.block]="isSent && isShowTop">
        <!-- 面试公司 -->
        <div class="sign-list" *ngIf="myDepartment">
          <span>公司全称：</span>
          <div class="selected-text disabled-text">{{myDepartment.name}}</div>
        </div>
        <!-- 面试类型 -->
        <div class="sign-list select-list">
            <span>面试类型：</span>
            <select autocomplete="off" [(ngModel)]="model.stage">
                <option class="place-text" value='' disabled hidden selected="selected">选择面试类型</option>
                <option value="first">初试</option>
                <option value="reexamine">复试</option>
            </select>
        </div>
        <!-- 面试职位 -->
        <div class="sign-list select-list">
            <span>面试职位：</span> 
            <select autocomplete="off" [(ngModel)]="model.positionid">
                <option class="place-text" value='' disabled hidden selected="selected">选择面试职位</option>
                <option *ngFor="let position of config.positionList" [value]="position.positionid">{{position.name}}</option>
            </select>
            <b *ngIf="isSend&&!model.positionid"><img src="assets/images/guide-false.png" alt="必填项提示"></b>
        </div>
        <!-- 面试时间 -->
        <div class="sign-list sign-date">
            <span>面试时间：</span>
            <input type="text" id="noticeDate" [(ngModel)]="completeDate" placeholder="请选择日期"/><img class="calendar-img" src="assets/images/calendar.png" alt="打开日历"/>
            <input type="text" id="noticeHour" [(ngModel)]="hourAndMinute" placeholder="请选择时间"/><img class="calendar-img" src="assets/images/calendar.png" alt="打开日历"/>
            <b *ngIf="isSend&&!model.time"><img src="assets/images/guide-false.png" alt="必填项提示"></b>
        </div>
        <!-- 面试地址 disabled hidden selected="selected"-->
        <div class="sign-list select-list" *ngIf="myDepartment">
            <span>面试地址：</span> 
            <select autocomplete="off" [(ngModel)]="workPlace">
                <option class="place-text" value='' disabled hidden selected="selected">选择面试地址</option>
                <option *ngFor="let address of myDepartment.locations" [value]="address.lid">{{address.region_text}}&nbsp;&nbsp;{{address.address}}</option>
            </select>
            <b *ngIf="isSend&&!workPlace"><img src="assets/images/guide-false.png" alt="必填项提示"></b>
        </div>
        <!-- 联系电话 -->
        <div class="sign-list">
            <span>联系电话：</span><input type="number" [(ngModel)]="model.phone" (blur)="checkPhone()" placeholder="请填写联系电话">
            <b *ngIf="isSend&&!model.phone"><img src="assets/images/guide-false.png" alt="必填项提示"></b>
        </div>
    </div><!-- 编辑面试邀请 -->
    <!-- 查看面试邀请 -->
    <div class="model-content" [class.block]="!isSent">
        <!-- 面试公司 -->
        <div class="sign-list" *ngIf="myDepartment">
            <span>公司全称：</span>
            <div class="selected-text disabled-text">{{myDepartment.name}}</div>
        </div>
        <!-- 面试类型 -->
        <div class="sign-list">
            <span>面试类型：</span>
            <div *ngIf="model.stage=='first'||!model.stage" class="selected-text disabled-text">初试</div>
            <div *ngIf="model.stage=='reexamine'" class="selected-text disabled-text">复试</div>
        </div>
        <!-- 面试职位 -->
        <div class="sign-list select-list" *ngIf="noticeInfo">
            <span>面试职位：</span> 
            <div class="selected-text disabled-text">{{noticeInfo.position_name}}</div>
        </div>
        <!-- 面试时间 -->
        <div class="sign-list sign-date">
            <span>面试时间：</span>
            <input type="text" [(ngModel)]="completeDate" disabled/><img class="calendar-img" src="assets/images/calendar.png" alt="打开日历"/>
            <input type="text" [(ngModel)]="hourAndMinute" disabled/><img class="calendar-img" src="assets/images/calendar.png" alt="打开日历"/>
        </div>
        <!-- 面试地址 -->
        <div class="sign-list select-list">
            <span>面试地址：</span> 
            <div class="selected-text disabled-text">{{model.location.region_text}},{{model.location.address}}</div>
        </div>
        <!-- 联系电话 -->
        <div class="sign-list">
            <span>联系电话：</span>
            <div class="selected-text disabled-text">{{model.phone}}</div>
        </div>
    </div><!-- 查看面试邀请 -->
    <!-- 底部操作部分 -->
    <div class="model-foot">
      
      <!-- 待接受 -->
      <button *ngIf="noticeInfo&&noticeInfo.feedback_status == 'to_be_accepted'" class="purple" (click)="cancelNotice()">取消面试</button><!--未接受-->
      <!-- 等待面试 -->
      <div class="cancel-notice" *ngIf="noticeInfo&&(noticeInfo.feedback_status == 'accept')&&isCancel" (click)="cancelNotice()">
        <span>你可以在 {{validDate | date:"yyyy-MM-dd HH:mm"}}点 前申请</span><b>撤回</b>
        <!-- <button class="purple" >取消面试</button> -->
      </div>
      <!-- 待处理 -->
      <div class="cancel-notice" *ngIf="noticeInfo&&noticeInfo.feedback_status=='handle_interview'">
         <button class="purple" (click)="missNotice()">对方爽约</button>
         <button class="purple" (click)="doNotice()">面试处理</button>
      </div>
      <!-- 面试结束未发送签约通知 -->
      <button *ngIf="noticeInfo&&(noticeInfo.feedback_status == 'hired')&&(!noticeInfo.offer)" (click)="dismiss('hired')">发送签约通知</button>
      <!-- 不合适撤回 -->
      <div class="cancel-notice" *ngIf="noticeInfo&&noticeInfo.feedback_status == 'improper'">
        <span>已设置为不合适，2小时内可申请</span>
        <!-- <b>撤回</b> -->
        <button class="purple" (click)="goInterviewHandle()">撤回</button>
      </div>
      <!-- 对方爽约撤回 -->
      <div class="cancel-notice" *ngIf="noticeInfo&&noticeInfo.feedback_status=='miss'">
        <span>已设置为对方爽约，可申请</span>
        <!-- <b>撤回</b> -->
        <button class="purple" (click)="goInterviewHandle()">撤回</button>
      </div>
      <!-- 发送 -->
      <button *ngIf="!isSent&&isShowTop" class="purple" (click)="refreshSend()">重新发送</button>
      <!-- 重新发送 -->
      <button *ngIf="isSent&&isShowTop" class="purple" (click)="sendNotice()">发送</button>
    </div><!--底部操作部分-->
  </div>
  

</div>
