

<div class="top-header" >
  <div class="left">
    <span class="college-department" *ngIf="config.isByCollege"><img [src]="config.byCollegeLogo">{{config.byCollegeName}}</span>
    <span *ngIf="!config.isByCollege"><img src="assets/images/logo.png"></span>
    管理中心<b></b>企业版
  </div>

</div>
<div class="back" (click)="goBack()">
  << 返回
</div>

<div class="loginOut" (click)="goLogin()">
  退出
</div>

<div class="helpbox">
  <h3>账号找回</h3>
  <div class="title">请联系工作啦客服进行账号找回</div>

  <div class="row flex_l">
    <img src="../../assets/images/kefu_wx.png">
    微信：<span>goworklavip</span>
  </div>
  <div class="row flex_l">
    <img src="../../assets/images/kefu_email.png">
    邮箱：<span>service@gowork.la</span>
  </div>

  <img class="code" src="../../assets/images/find-account.png"/>

</div>
