import { Component,EventEmitter, Input, Output,ViewChild,ElementRef } from '@angular/core';
@Component({
  selector: 'app-model-auth-explain',
  templateUrl: './model-auth-explain.component.html',
  styleUrls: ['./model-auth-explain.component.css']
})
export class ModelAuthExplainComponent{
  @Input() isShow: false;//是否显示弹窗页面
  @Output() onVoted = new EventEmitter();
  dismiss(result: any) {
    //console.log("传值检查");
    //console.log(result);
    this.onVoted.emit(result);
  }

}
