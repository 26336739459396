<!-- 页头部分 -->
<div class="contentRight-header">招聘管理 - 简历管理</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <!-- <div class="notification">
        <img src="assets/images/noticotion.png" />
        <span>进入工作啦官网，看一下有多少毕业生与您职位相匹配 <span  (click)="gotoPro()" class="check">点击查看 <img src="assets/images/notMore.png" /></span></span>
    </div> -->
    <div class="company-block">
        <!-- 头部 -->
        <div class="identity-head">
            <button [class.purple]="type=='delivery'" (click)="goPositionResume()">我收到的</button>
            <button [class.purple]="type=='collection'" (click)="goCollectionResume()">我收藏的</button>
        </div>
        <!-- 组件 -->
        <!-- 认证信息提交部分 -->
        <router-outlet></router-outlet>
        <app-model-delivery-resume [isShow]="modelShow"></app-model-delivery-resume>
        <app-model-collection-resume [isShow]="modelShowStatus"></app-model-collection-resume>
    </div>
</div>