<!-- 裁剪图片 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
  <div class="black-bg" (click)="dismiss(false)"></div>
  <div class="model-cont">
    <!-- 上层提示部分 -->
    <div class="model-head clear">
        <div class="left">裁剪图片</div>
        <button class="right" (click)="dismiss(false)">×</button>
    </div>
    <!-- 中间内容部分 -->
    <div class="model-content">
         <!-- <div class="crop-box">
            <img #imgCrop class="cropper-hidden" src="{{imgSrc}}">
            <div class="left" (click)="dismiss(false)">取消</div>
            <div class="right" (click)="trueCrop()">确定</div>
         </div> -->
         <img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper>
         <div *ngIf="data.image" [class.rounded]="cropperSettings.rounded">
            <img [src]="data.image" [width]="croppedWidth" [height]="croppedHeight">
         </div>
         <!-- 底部按钮 -->
         <div class="model-foot"><button class="purple" (click)="trueCrop()">保存</button></div>
    </div>
  </div>
</div>
