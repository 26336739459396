import { Component, OnInit } from '@angular/core';
import {CalendarModule} from 'primeng/calendar';
declare let laydate;
@Component({
  selector: 'app-position-calendar',
  templateUrl: './position-calendar.component.html',
  styleUrls: ['./position-calendar.component.css']
})
export class PositionCalendarComponent implements OnInit {
  public value: '2018-08-27';
  public ch: any;
  constructor() {
    
   }

  ngOnInit() {
    let that = this;
    laydate.render({
      elem:'#position-calendar',
      mark: {
        '0-10-14': '生日'
        ,'0-12-31': '跨年' //每年的日期
        ,'0-0-10': '工资' //每月某天
        ,'0-0-15': '月中'
        ,'2017-8-15': '' //如果为空字符，则默认显示数字+徽章
        ,'2099-10-14': '呵呵'
        ,'2018-8-29': '测试'
      }
      ,show: true //直接显示
      ,showBottom: false
      ,// type: 'datetime',
      done: function(value, date){
      }
     })
  }

}
