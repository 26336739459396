import { Component, OnInit,Input, Output,ViewChild } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute,Router } from  '@angular/router';
import { ModelSelectTwoComponent } from '../model-select-two/model-select-two.component';
import { ModelCompanyLabelComponent } from '../model-company-label/model-company-label.component';
import { ModelCompanyProductComponent } from '../model-company-product/model-company-product.component';
import { ModelCompanyCoursesComponent } from '../model-company-courses/model-company-courses.component';
import { ModelCompanyStrengthComponent } from '../model-company-strength/model-company-strength.component';
import { ModelCropperComponent } from '../model-cropper/model-cropper.component';
declare let laydate;
declare var window;
@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css']
})
export class CompanyDetailsComponent implements OnInit {
  @ViewChild('industry', { static: true }) child: ModelSelectTwoComponent;
  @ViewChild('label', { static: true }) child1: ModelCompanyLabelComponent;
  @ViewChild('product', { static: true }) child2: ModelCompanyProductComponent;
  @ViewChild('courses', { static: true }) child3: ModelCompanyCoursesComponent;
  @ViewChild('strength', { static: true }) child4: ModelCompanyStrengthComponent;
  @ViewChild('crop', { static: true }) child5: ModelCropperComponent;
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelTwoShow = false;//是否打开弹窗
  @Output() modelThreeShow = false;//是否打开弹窗
  @Output() modelFourShow = false;//是否打开弹窗
  @Output() modelFiveShow = false;//是否打开弹窗
  @Output() modelSixShow = false;//是否打开弹窗
  public config: Config;
  //企业数据(企业信息,修改资料的时候不用这个传递)
  public myDepartment:any;
  //需要修改的企业资料初始化
  public model = {
    industry:'',//所属行业
    employer_type:'',//企业性质
    region:'',//所在地区
    scale:'',//公司规模
    intro:'',//企业简介
    is_quoted_company:false,//是否是上市公司
    stock_code:'',//股票代码
    strengths:[],//公司实力
    official_account:'',//微信公众号
    website:'',//企业网址
    labels:[],//企业标签
    register_capital:null,//注册资金（万元）
    establish_year:null,//成立年份
    products:[],//产品
    courses:[],//发展历程
    image_urls:[],//照片展示
    video_link:'',//宣传视频链接
    register_capital_unit:"",
     currency:'',
      employer_category:''//企业注册地，不可修改
  };
  public register_capital_unit = "";

  //企业当前地址
  public workPlace = '';
  public productid="";//产品id
  public coursesid="";//发展历程id
  public CompanyStatus=2;//公司资质审核状态，默认未2，已通过


  public isMainland = true;//默认显示 营业执照 是否是大陆企业 默认是大陆企业
  public isMainlandCollege = true;//是否是大陆高校，默认是大陆高校
    public global_regionArr = [];

    //货币类型字典
  public currency = [];
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public opera:Opera,
    public formatDate: FormatDate,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
  }
    public companyState = '';//1 已完成；2修改姓名+营业执照

  public ShowUpdateModel2 =false;// 修改企业的弹窗
  //修改企业的模型
  public updateComModel={
    updateType:'',
    department_name:'', //企业名称
    register_number:"",//企业唯一标识
    business_licence_url:'',//营业执照
    change_letter_url:'', //资质变更书
    common_name:"",//企业简称
    business_licence_extension:"",//营业执照扩展名
    business_licence_filename:"",//营业执照文件名称
    change_letter_extension:"",//扩展名,
    change_letter_filename:"",//文件名 ,
    remark:"",//申请原因
      register_capital_unit:'',
      register_capital:'',
  };

  ngOnInit() {
    this.opera.initConfig('company','company-details');
    // this.opera.refreshPage();
    let that = this;
    laydate.render({
      elem: '#establish',
      type: 'year',
      done: function(value, date){
       that.model.establish_year = value;
      }
    })
    // //console.log("日期插件使用");
    // //console.log(laydate.render({elem: '#test',type: 'year',}));
    this.getIndustry();//获取所属行业
    this.getEmployerType();//获取企业类别
    this.getDepartment();//获取企业信息
    this.getEmployerScale();//获取企业规模
    this.getCompanyStatus(); //获取企业认证信息
    this.getCurrency();
    this.getGlobal_region();

      let byCollegeDetails =   this.local.get(Constants.byCollegeDetails);
      try {
          let byCollegeDetailsJson = JSON.parse(byCollegeDetails);
          console.log('byCollegeDetailsJson',byCollegeDetailsJson);
          if (byCollegeDetailsJson.region) {
              var num = byCollegeDetailsJson.region.slice(0, 2)
             this.isMainlandCollege = this.isDaluCollege(num);
              console.log('byCollegeDetailsJson',byCollegeDetailsJson, this.isMainlandCollege);

          }

      }catch(e){

      }

  }

 //删除企业展示图片
 deleteImg(img){
  this.model.image_urls.forEach((val)=>{
    if(val == img){
      let num = this.model.image_urls.indexOf(val);
      this.model.image_urls.splice(num,1);
    }
  })
}

  //去企业发展历程
  goCourses(){
    this.coursesid="";
    this.modelFourShow=true;
    this.child3.initData(false);
    this.opera.scrollTop();//页面滚动函数
  }

  //去编辑企业发展历程
  goEditorCourses(n){
    this.coursesid=n.cid;
    this.modelFourShow=true;
    this.child3.initData(n);
    this.opera.scrollTop();//页面滚动函数
  }

  //接收子组件返回数据
  ModelFourdismiss(event){
    this.modelFourShow = false;
    this.opera.scrollTrue();
    if(event){
     //重新赋值企业产品
     if(!this.coursesid){//添加
        this.model.courses.push(event);
     }else{
       if(event=='delete'){//删除
        for(let n of this.model.courses){
          if(n.cid == this.coursesid){
            this.model.courses.splice(this.model.courses.indexOf(n),1);
          }
        }
       }else{//修改
        for(let n of this.model.courses){
          if(n.cid == this.coursesid){
             n = event;
          }
        }
       }
     }
     this.myDepartment.courses=this.model.courses;
     this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
    }
  }

  //去企业产品
  goProduct(){
    this.productid="";
    this.modelThreeShow=true;
    this.child2.initData(false);
    this.opera.scrollTop();//页面滚动函数
  }

  //编辑企业产品
  goEditorProduct(n){
    this.productid=n.pid;
    this.modelThreeShow=true;
    this.child2.initData(n);
    this.opera.scrollTop();//页面滚动函数
  }

  //接收子组件返回数据
  ModelThreedismiss(event){
    this.modelThreeShow = false;
    this.opera.scrollTrue();
    if(event){
     //重新赋值企业产品
     if(!this.productid){//添加
        this.model.products.push(event);
     }else{
       if(event=='delete'){//删除
        for(let n of this.model.products){
          if(n.pid == this.productid){
            this.model.products.splice(this.model.products.indexOf(n),1);
          }
        }
       }else{//修改
        for(let n of this.model.products){
          if(n.pid == this.productid){
             n = event;
          }
        }
       }
     }
     this.myDepartment.products=this.model.products;
     this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
    }
  }

  //上传logo
previewPic(event) {
  let imgData  = event.target.files[0]; //获取file对象
  if (window.FileReader) {
    var reader = new FileReader();
    reader.readAsDataURL(imgData);
    var that = this;
    reader.onloadend = function (e : any) {
      that.imageCropper(reader.result);
    };

  }
 }
  getPicData(event,name) {

    let imgData  = event.target.files[0]; //获取file对象
    let imageName =  event.target.files[0].name;

    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
      let data = reader.result as string;
      let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
      imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
        //文件扩展名的正则表达式
        var patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
        //获得文件扩展名
        var fileExtension = (imageName).match(patternFileExtension);

        if(name=='yingyezhizhao')
        {
         let Postdata = {
           value:imageData
          }
          that.opera.loading();
          that.dataApi.uploadAuthImg(Postdata).then((res:any)=>{
            console.log("uploadDepartmentImg",res.value);
            that.updateComModel.business_licence_url=res.value;
            that.updateComModel.business_licence_extension = fileExtension[1];
            that.updateComModel.business_licence_filename = imageName.split('.')[0];
            that.opera.loadiss();
            that.toastr.success("上传成功")
          }).catch((err)=>{
            console.log("uploadDepartmentImg",err);
            that.toastr.error("上传图片失败");
            that.opera.loadiss();
          })


        }
        else
        {
          let Postdata = {
            value:imageData
          }
          that.opera.loading();
          that.dataApi.uploadAuthImg(Postdata).then((res:any)=>{
            console.log("uploadDepartmentImg",res.value);

            that.updateComModel.change_letter_url=res.value;
            that.updateComModel.change_letter_extension = fileExtension[1];
            that.updateComModel.change_letter_filename = imageName.split('.')[0];
            that.opera.loadiss();
            that.toastr.success("上传成功")

          }).catch((err)=>{
            console.log("uploadDepartmentImg",err);
            that.toastr.error("上传图片失败");
            that.opera.loadiss();
          })


        }

      };
    }
  }

 //裁剪插件
 imageCropper(imageData){
  this.modelSixShow=true;
  this.child5.initData(imageData);
  this.opera.scrollTop();//页面滚动函数
}

//接收子组件返回数据
ModelSixdismiss(event){
  this.modelSixShow = false;
  this.opera.scrollTrue();
  if(event){
    this.uploadDepLogoImg(event);
  }
}

//上传
uploadDepLogoImg(imageData){
    imageData=imageData.replace(/^(data:image)\/png;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
    this.opera.loading();//加载动画
    this.dataApi.uploadDepartmentLogo({value: imageData}).then((res:any)=>{
      this.myDepartment.logo = res.value;
      this.opera.loadiss();//停止加载动画
      this.dataApi.setLocalDepartment(this.myDepartment);
      setTimeout(() => {
        this.toastr.success('上传成功');
      },600);
    }).catch((error)=>{
     this.opera.loadiss();//停止加载动画
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    })
 }


  //企业展示图片
  previewDepartmentPic(event){
    if(this.model.image_urls.length>9){
      this.toastr.warning("企业展示图片最多上传10张");
      return;
    }
    let imgData  = event.target.files[0]; //获取file对象
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.uploadDepImg(reader.result);
      };

    }
  }

  //上传企业展示图片
  uploadDepImg(data){
      let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
      imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
      // let loader = this.tip.presentLoading('图片保存中，请稍等...');
      this.dataApi.uploadDepartmentImg({
        value: imageData
      }).then((res:any)=>{
        // loader.dismiss();
        this.model.image_urls.push(res.value);
      }).catch((error)=>{
        // loader.dismiss();
        this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
      })
  }

  //去企业标签
  goLabel(){
    this.modelTwoShow = true;
    this.opera.scrollTop();//页面滚动函数
    let newArray=JSON.parse(JSON.stringify(this.model.labels));
    //调用子组件函数
    this.child1.initData(newArray);
  }

  //接收子组件返回数据
  ModelTwodismiss(event){
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    if(event){
      // //console.log("接收子组件返回数据");
      // //console.log(event);
     //重新赋值企业标签
     this.model.labels=event;
     this.myDepartment.labels=this.model.labels;
     this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
    }
  }

  //去公司实力
  goStrengths(){
    this.modelFiveShow = true;
    this.opera.scrollTop();//页面滚动函数
    let newArray=JSON.parse(JSON.stringify(this.model.strengths));
    //调用子组件函数
    this.child4.initData(newArray);
  }

  //接收子组件返回数据
  ModelFivedismiss(event){
    this.modelFiveShow = false;
    this.opera.scrollTrue();
    if(event){
      // //console.log("接收子组件返回数据");
      // //console.log(event);
     //重新赋值企业标签
     this.model.strengths=event;
     this.myDepartment.strengths=this.model.strengths;
     this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
    }
  }

  //去地址列表
  goAddress():void{
    // this.router.navigate(['home/company-details-address']);
    this.router.navigateByUrl("home/company-details-address");
  }
  //选择行业类别
  chooseIndustry(){
      if(this.model.industry){
          return
      }
    this.opera.scrollTop();//页面滚动函数
    this.modelShow = true;
    //调用子组件函数
    this.child.initData(this.model.industry,this.config.industry,'选择行业类别');
  }
  //接收子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
    if(event){
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      this.model.industry = event.code;
    }
  }

  //获取所属行业
  getIndustry(){
    if(this.local.get(Constants.industry)){
      //console.log('获取行业类型本地存储');
      //console.log(this.dataApi.getIndustryCode());
      this.config.industry = this.dataApi.getIndustryCode();
    }else{
      //获取行业类型字典
      this.dataApi.getDictDate('industry').then((res:any)=>{
        //console.log('获取行业类型线上数据');
        //console.log(res);
          if(res)
          {
              this.dataApi.setIndustryCode(res);

          }
      }).catch((error)=>{
        //console.log(error);
      });
    }
  }

  //获取企业类别
  getEmployerType(){
    if(this.local.get(Constants.employer_type)){
      this.config.employer_type = this.dataApi.getEmployerTypeCode();
    }else{
      //获取企业类别字典
      this.dataApi.getDictDate('employer_type').then((res:any)=>{
        //console.log('获取企业类别线上数据');
        //console.log(res);
          if(res)
          {
              this.dataApi.setEmployerTypeCode(res);
              this.config.employer_type=res;
          }

      }).catch((error)=>{
          //console.log(error);
      });
    }
  }

  //获取企业规模字典
  getEmployerScale(){
    if(this.dataApi.getScaleCode()){
      this.config.scale=this.dataApi.getScaleCode();
    }else{
      //获取企业规模字典
      this.dataApi.getDictDate('scale').then((res:any)=>{
          if(res)
          {
              this.config.scale = res;
              this.dataApi.setScaleCode(res);
          }
       }).catch((error)=>{
           //console.log(error);
       });
    }

  }

  //初始化当前页面数据
  initDeparment(_department){
    this.myDepartment = _department;
     //当前页面使用不到的值
    if(this.myDepartment.register_capital){//注册资金
        this.model.register_capital = this.myDepartment.register_capital;

        this.register_capital_unit = this.myDepartment.register_capital_unit;
        if(this.myDepartment.register_capital_unit=='亿元'){
            this.model.register_capital = parseFloat(this.myDepartment.register_capital)/10000;
            console.log('初始化当前页面数据',this.model.register_capital,this.register_capital_unit)
        }
        else {
            this.register_capital_unit = "万元"
        }

    }
      if(this.myDepartment.employer_category) {
          this.model.employer_category = this.myDepartment.employer_category;
      }
      if(this.myDepartment.establish_year){//成立年份
      // this.model.establish_year = String(this.myDepartment.establish_year);
      this.model.establish_year = this.myDepartment.establish_year;
    }
    if(this.myDepartment.products){//产品
      this.model.products = this.myDepartment.products;
    }
    if(this.myDepartment.courses){//发展历程
      this.model.courses = this.myDepartment.courses;
    }
    if(this.myDepartment.image_urls){//展示图片
      this.model.image_urls = this.myDepartment.image_urls;
    }
    if(this.myDepartment.video_link){//视频链接
      this.model.video_link = this.myDepartment.video_link;
    }
    //给当前页面使用到的值赋值,一面对象属性互相影响
    if(this.myDepartment.industry){//所属行业
      this.model.industry = this.myDepartment.industry;
    }
    if(this.myDepartment.employer_type){//企业性质
      this.model.employer_type = this.myDepartment.employer_type;
    }
    // if(this.myDepartment.region){//所在地区
    //   this.model.region = this.myDepartment.region;
    // }

    if(this.myDepartment.scale){//公司规模(员工人数)
      this.model.scale = this.myDepartment.scale;
    }
    if(this.myDepartment.intro){//企业简介
      this.model.intro = this.myDepartment.intro;
    }
    if(this.myDepartment.website){//企业网址
      this.model.website = this.myDepartment.website;
    }
    if(this.myDepartment.labels){//企业标签
      this.model.labels = this.myDepartment.labels;
    }
    if(this.myDepartment.strengths){//公司实力
      this.model.strengths = this.myDepartment.strengths;
    }
    if(this.myDepartment.is_quoted_company){//是否上市
      this.model.is_quoted_company = this.myDepartment.is_quoted_company;
    }
    if(this.myDepartment.stock_code){//股票代码
      this.model.stock_code = this.myDepartment.stock_code;
    }
    if(this.myDepartment.official_account){//微信公众号
      this.model.official_account = this.myDepartment.official_account;
    }
      if(this.myDepartment.currency){//企业性质
          this.model.currency = this.myDepartment.currency;
      }

    //给公司地址默认赋值
    if(this.myDepartment.locations){
      if(this.myDepartment.locations.length>0){
        let is_default = false;
        this.myDepartment.locations.forEach((val)=>{
          if(val.is_default){
            is_default = true;
            this.workPlace = val.lid;
          }
        });
        if(!is_default){
          this.workPlace = this.myDepartment.locations[0].lid;
        }
      }
    }

  }

  //保存、更新企业信息
  updateDepartment(){
    let filter=/^[0-9]+$/;
    //必填项验证
    if(!this.model.employer_type){
      this.toastr.warning("请填写公司性质");
      return;
    }
    if(!this.model.register_capital){
      this.toastr.warning("请填写注册资金");
      return;
    }
    if(!this.workPlace){
      this.toastr.warning("请填写公司地址");
      return;
    }
    if(!this.model.establish_year){
      this.toastr.warning("请填写成立年份");
      return;
    }
    if(this.model.establish_year)
    {
        if(!this.opera.isEstablish_year(this.model.establish_year))
        {
              this.toastr.warning("公司成立年份格式错误");
              this.model.establish_year = "";
              return;
        }
    }
    if(!this.model.scale){
      this.toastr.warning("请选择员工人数");
      return;
    }
    if(!this.model.intro){
      this.toastr.warning("请填写公司简介");
      return;
    }else{
      if (this.model.intro.length<50) {
        this.toastr.warning('公司简介不得少于50字');
        return;
      }
    }
    if(this.model.is_quoted_company&&!this.model.stock_code){
       this.toastr.warning("请填写公司股票代码");
       return;
    }
     //console.log(this.model);
     //-------更新企业展示图片
      if(this.model.image_urls==null){
          this.model.image_urls = [];
      }

      this.dataApi.uploadDepartmentShow(this.model.image_urls).then((res:any)=>{
          this.myDepartment.image_urls = this.model.image_urls;//企业展示
      }).catch((error)=>{
          setTimeout(() => {
              this.toastr.error(error);
          },600);
      });
      this.model.register_capital_unit = this.register_capital_unit;

      if(this.register_capital_unit=='亿元') {
          this.model.register_capital = parseFloat(this.model.register_capital)*10000;
          this.register_capital_unit = "万元";
      }
    //更新、保存企业信息
     this.dataApi.updateDepartment(this.model).then((res:any)=>{
       //-----更新企业信息本地存储
       this.myDepartment.industry = this.model.industry;//所属行业
       this.myDepartment.employer_type = this.model.employer_type;//企业性质
      //  this.myDepartment.region = this.model.region;//所在地区
       this.myDepartment.scale = this.model.scale;//公司规模(员工人数)
       this.myDepartment.website = this.model.website;//企业网址
       this.myDepartment.intro = this.model.intro;//企业简介
       this.myDepartment.is_quoted_company = this.model.is_quoted_company;//是否上市
       this.myDepartment.stock_code = this.model.stock_code;//股票代码
       this.myDepartment.register_capital = this.model.register_capital;//注册资金
       this.myDepartment.establish_year = this.model.establish_year;//成立年份
       this.myDepartment.products = this.model.products;//产品
       this.myDepartment.courses = this.model.courses;//发展历程
         this.myDepartment.video_link = this.model.video_link;//企业视频
         this.myDepartment.currency = this.model.currency;//币种
       this.myDepartment.official_account = this.model.official_account;//微信公众号
         this.myDepartment.register_capital_unit = this.register_capital_unit
         if( this.register_capital_unit=='亿元')
         {
             this.model.register_capital = parseFloat(this.model.register_capital)/10000;
             this.myDepartment.register_capital = parseFloat(this.model.register_capital);//注册资金

         }
         console.log('保存到本地的数据', this.myDepartment)
       //更新企业信息本地存储字段
       //公司规模
       if(this.myDepartment.scale){
         for(let n of this.config.scale){
            if(n.value==this.myDepartment.scale){
              this.myDepartment.scale_text=n.text;
            }
         }
       }
       //公司性质
       if(this.myDepartment.employer_type){
        for(let n of this.config.employer_type){
           if(n.value==this.myDepartment.employer_type){
             this.myDepartment.employer_type_text=n.text;
           }
        }
      }
      //所属行业
      if(this.myDepartment.industry){
        for(let n of this.config.industry){
           if(n.code==this.myDepartment.industry){
             this.myDepartment.industry_text=n.text;
           }
        }
      }
       //判断是否填写了成立年份-----------当前页面展示
       if(this.model.establish_year){
         this.model.establish_year= String(this.model.establish_year);
       }else{
        this.model.establish_year = null;
       }
       this.dataApi.setLocalDepartment(this.myDepartment);//---修改完企业信息之后保存
       setTimeout(() => {
        this.toastr.success("保存成功");
        // this.navCtrl.pop();
       },600);
    }).catch((error) => {
      //console.log(error);
      setTimeout(() => {
        this.toastr.error(error);
      },600);
    });
  }

   //获取企业信息
   getDepartment(){
      this.dataApi.getDepartment().then((res:any)=>{
         console.log("企业信息获取线上数据",res);
         if(res)
         {
            this.dataApi.setLocalDepartment(res);
            this.initDeparment(res);//初始化页面数据
            if(res.employer_category == '0101'){
                this.isMainland = false;
            }else{
                this.isMainland = true;
            }
          }
        }).catch((error) => {
            this.toastr.error(error);
     });
  }

  //选择修改名称的类型
  seleteUpdateNameType(){
    this.ShowUpdateModel2=true;
    this.updateComModel.change_letter_url='',
      this.updateComModel.department_name='',
      this.updateComModel.register_number='',
      this.updateComModel.business_licence_url='',//营业执照
      this.updateComModel.common_name='',//企业简称
      this.updateComModel.business_licence_extension='',//营业执照扩展名
      this.updateComModel.business_licence_filename='',//营业执照文件名称
      this.updateComModel.change_letter_extension='',// 扩展名 ,
      this.updateComModel.change_letter_filename ='',//文件名 ,
      this.updateComModel.remark=''
  }

  /**
   * 关闭更新企业弹窗按钮
   */
  cloceShowUpdate(){
      this.ShowUpdateModel2=false;
      this.updateComModel.change_letter_url='',
      this.updateComModel.department_name='',
      this.updateComModel.register_number='',
      this.updateComModel.business_licence_url='',//营业执照
      this.updateComModel.common_name='',//企业简称
      this.updateComModel.business_licence_extension='',//营业执照扩展名
      this.updateComModel.business_licence_filename='',//营业执照文件名称
      this.updateComModel.change_letter_extension='',// 扩展名 ,
      this.updateComModel.change_letter_filename =''//文件名 ,
      this.updateComModel.remark=''
  }

  /**
   * 更新按钮
   * @param type
   */
  update(type){
    console.log("update",this.updateComModel);
    if(this.updateComModel.department_name=='')
    {
      this.toastr.error('请填写公司名');
      return;
    }
    if(this.updateComModel.register_number=='')
    {
      this.toastr.error("请填写企业有效证照号码");
      return;
    }
    if(!this.opera.isRegister_number(this.updateComModel.register_number))
    {
        this.toastr.warning("企业有效证照号码格式不正确");
        return;
    }
    if(this.updateComModel.business_licence_url=='')
    {
      this.toastr.error("请上传企业有效证照");
      return;
    }
    if(this.updateComModel.change_letter_url=='')
    {
      this.toastr.error('请上传资质变更授权书');
      return;
    }
    if(this.updateComModel.remark=='')
    {
      this.toastr.error('请填写备注');
      return;
    }
    if(this.updateComModel.common_name=='')
    {
      this.toastr.error('请填写企业简称');
      return;
    }

    this.dataApi.updateDepartmentNameAndPic(this.updateComModel).then((res:any)=>{
      console.log("updateDepartmentNameAndPic",res,this.updateComModel);
      this.ShowUpdateModel2=false;
      this.toastr.success("修改成功，请等待审核")
      this.getCompanyStatus();
    }).catch((err)=>{
      console.log("updateDepartmentNameAndPicerr",err);
      this.toastr.error(err);
    })

  }


  getCompanyStatus(){
    let accountid = localStorage.getItem("accountid");
    if(accountid)
    {
      this.dataApi.getCompanyStatus(accountid).then((res:any)=>{
        console.log("getCompanyStatus",res);
        //0', '1', '2', '3', '4']
          if(res)
          {
              this.CompanyStatus = res.status;
          }
      }).catch((err)=>{
        console.log("getCompanyStatus",err);
      })
    }

  }
  //验证企业简称
  checkCommonName(){
    if(this.updateComModel.common_name){
      let filter2=/^[a-zA-Z\uff08\uff09\u4e00-\u9fa5]+$/;
      if(!filter2.test(this.updateComModel.common_name)){
        this.toastr.warning("企业简称只能输入汉字和字母");
        return;
      }
    }
  }
    register_capitalChange(e){
        console.log('e',e,this.model.register_capital)
        if(this.register_capital_unit == "亿元")
        {
            if(parseInt(e)>10000)
            {
                setTimeout(()=>{
                    this.model.register_capital = "10000";
                    this.register_capital_unit = "亿元"
                },200)
            }
            return;
        }
        if(e>=10000)
        {
            console.log('e',this.model.register_capital)
            setTimeout(()=>{
                this.model.register_capital =  (parseFloat(e)/10000)+"";
                this.register_capital_unit = "亿元"
            },200)
        }
        else
        {
            this.model.register_capital =  (parseInt(e)*10000)+"";
            this.register_capital_unit = "万元"
        }
    }
    isDaluCollege(College){
        if(College =="32"||College =="33"||College =="34"||College =="35")
        {
            return false;
        }
        return true;
    }
    getCurrency(){
        //获取货币类型字典
        this.dataApi.getDictDate('currency').then((res:any)=>{
            console.log('获取货币类型线上数据',res);
            if(res)
            {
                this.currency = res;
            }
        }).catch((error)=>{
        });
    }

    getGlobal_region(){
        this.dataApi.getDictDate('global_region').then((res:any)=>{
            this.global_regionArr = res;
        }).catch((error)=>{
            //console.log(error);
        });
    }



}
