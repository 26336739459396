<!-- 企业产品 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>
    <div class="model-cont">
        <!-- 上层提示部分 -->
        <div class="model-head clear">
            <div class="left">{{title}}企业产品</div>
            <button class="right" (click)="dismiss(false)">×</button>
        </div>
        <!-- 中间内容部分 -->
        <div class="model-content">
             <!-- 产品名称 -->
            <div class="sign-list">
                <span>产品名称：</span><input type="text" [(ngModel)]="model.name" maxlength="10" placeholder="请填写产品名称(少于10个字)">
            </div>
            <!-- 产品介绍 -->
            <div class="sign-list sign-intro">
                <span>产品介绍：</span>
                <textarea [(ngModel)]="model.description" minlength="0" maxlength="200" placeholder="请填写产品介绍(小于200字)"></textarea>
            </div>
            <!-- 产品图片 -->
            <div class="sign-list sign-img">
                <span>产品图片：</span>
                <div class="sign-label-block">
                    <label *ngIf="model.image_url" class="uploadImg-label department-logo" for="uploadProductImg">
                        <img [src]="model.image_url+'?imageView2/1/w/240/h/180'">
                    </label>
                    <label *ngIf="!model.image_url" class="uploadImg-label" for="uploadProductImg">
                        <img src="assets/images/add-img.png"><br/>点击上传产品图片
                    </label>
                    <input class="uploadImg" id="uploadProductImg" (change)="previewPic($event)" type="file" accept="image/jpg,image/png">
                    <br/><s>*仅限1张</s>
                </div>   
            </div>
        </div>
        <!-- 底部按钮 -->
        <div class="select-submit"><button *ngIf="model.pid" (click)="deleteProduct()">删除</button><button class="purple" (click)="getProduct()">保存</button></div>
    </div>
</div>