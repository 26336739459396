<!-- 页头部分 -->
<div class="contentRight-header">招聘管理 - 招聘日历</div>
<!-- 主体内容部分 yyyy-MM-dd-->
<div class="homepage-block clear"> 
    <div class="left">
        <span id="position-calendar"></span>
    </div>
    <div class="right"></div>
</div>

