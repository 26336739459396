import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from  '@angular/router';
import {Config, DataApi} from "../../provider";

@Component({
  selector: 'app-find-psw',
  templateUrl: './find-psw.component.html',
  styleUrls: ['./find-psw.component.css']
})
export class FindPswComponent implements OnInit {
  public config: Config;

  public tap = 1 ;   //当前步骤
  public findPswModel={
    email:'',
    emailCode:'',
    oldpsw:'',
    newpsw:''
  };
  public vcodeImg ='';//图片验证码（用户填写）
  public imgCode:any;//图形验证码
  public imgCodeId:any;//图形验证码id
  public isGetCode= true;//是否可以获取验证码
  public sengCodeBtn= '获取邮箱验证码';//是否可以获取验证码

  constructor(
    public toastr: ToastrService,
    private router: Router,
    public dataApi: DataApi,
  ) {
    this.config = Config.getInstance();//实例化config
    window.onpopstate = (event) => {
      console.log('onpopstate',event);
      window.location.reload()
    };
  }
  ngOnInit() {
    this.getCode();
  }

  /**
   * 获取邮箱验证码
   */
  getEmailCode(){
    if(this.findPswModel.email=='')
    {
      this.toastr.error('请填写邮箱');
      return;
    }
    // if(!this.isEmail(this.findPswModel.email))
    // {
    //   this.toastr.error('邮箱格式错误');
    //   return;
    // }
    if(this.vcodeImg=='')
    {
      this.toastr.error('请填写图片验证码');
      return;
    }
    if(this.sengCodeBtn !='获取邮箱验证码')
    {
      this.toastr.error('60秒内不能重复发送');
      return;
    }

    let phoneModel={
      format_type:2,//0:手机号，1：手机号&固话；2.邮箱
      data:this.findPswModel.email
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(!res.value){
        this.toastr.error('邮箱格式错误');
        return;
      }
      let data={
        email:this.findPswModel.email,
        vcode:{
          vcodeid:this.imgCodeId,
          vcode_text:this.vcodeImg,
        },
        account_type:'employer',
      };
      console.log("获取邮箱验证码",data);
      this.dataApi.findPswSendEmail(data).then((res:any)=>{
        console.log("发送邮箱",res,data);
        this.toastr.success('获取成功');

        let count = 60;
        this.sengCodeBtn = count + 's';
        let timer = setInterval(()=>{
          count -= 1;
          this.sengCodeBtn = count + 's';
          if (count === 0) {
            clearInterval(timer);
            this.sengCodeBtn = '获取邮箱验证码';
          }
        }, 1000);

      }).catch((err)=>{
        console.log("发送邮箱失败",err);
        this.toastr.error(err);return;
      })
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error('邮箱格式错误');
      return;
    })
  }


  /**
   * 下一步上一步
   * @param tab
   */
  nextTap(tab){
    if(tab==2) {
      if(this.findPswModel.email==''||this.findPswModel.emailCode==''||this.vcodeImg=='')
      {
        this.toastr.error('信息填写完整');
        return;
      }
      // if(!this.isEmail(this.findPswModel.email))
      // {
      //   this.toastr.error('邮箱格式错误');
      //   return;
      // }

      let data={
        email:this.findPswModel.email,
        vcode_text:this.findPswModel.emailCode,
      };
      console.log("验证邮箱",data);
      this.dataApi.findPswCheckEmailCode(data).then((res:any)=>{
        console.log("验证邮箱",res,data);
        this.toastr.success('验证成功')
        this.tap=tab;
      }).catch((err)=>{
        console.log("验证失败",err);
        this.toastr.error(err);return;
      })

    } else {
      this.tap=tab;
    }
  }

  /**
   * 完成按钮
   */
  finishClick(){
    console.log("this.findPswModel",this.findPswModel);
    if(this.findPswModel.email==''|| this.findPswModel.emailCode=='')
    {
      this.toastr.error('请重新验证邮箱');
      this.tap=1;
      return;
    }
    if(this.findPswModel.newpsw==''||this.findPswModel.oldpsw=='')
    {
      this.toastr.error('信息填写完整');
      return;
    }
    if(this.findPswModel.newpsw!=this.findPswModel.oldpsw)
    {
      this.toastr.error('两次密码不一致');
      return;
    }
    if(this.findPswModel.newpsw.length<8)
    {
      this.toastr.error('密码不小于8位');
      return;
    }
    if(!this.isPsw(this.findPswModel.oldpsw))
    {
      this.toastr.error('密码格式错误，请重新填写');
      return;
    }
    // this.toastr.success("修改密码成功")

    let data = {
      "email": this.findPswModel.email,
      "reset_token":  this.findPswModel.emailCode,
      "newpassword": this.findPswModel.newpsw,
    }
    this.dataApi.findPswResetPsw(data).then((res:any)=>{
      console.log("修改密码",res,data);
      this.toastr.success('修改成功')
    }).catch((err)=>{
      console.log("修改失败",err);
      this.toastr.error("密码格式错误，请重新填写");return;
    })


    setTimeout(() => {
      this.router.navigateByUrl('/login');
    },800);


  }
  isEmail(test){
    return /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/.test(test);
  }

  isPsw(psw){
    var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,25}$/;
    return reg.test(psw)
  }

  cancel(){
    this.router.navigateByUrl('/login');

  }
  // 获取图形验证码
  getCode(){
    this.dataApi.getImgCode().then((res:any)=>{
      //console.log("获取验证码");
      // this.toastr.success('获取成功');
      // this.imgCode=this.sanitizer.bypassSecurityTrustResourceUrl(res.vcode_image);//验证码图片base64
      this.imgCode='data:image/png;base64,'+res.vcode_image;//验证码图片base64
      this.imgCodeId=res.vcodeid;
      //console.log(res);
    }).catch((err)=>{
      //console.log(err);
    })
  }
  gotoFindAccount(){
    this.router.navigateByUrl('/find-account');
  }

}
