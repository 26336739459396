<div class="home-content">
    <!-- 左侧导航部分 -->

    <!-- 遮罩层公共样式 -->

    <app-model-college-identity [orgList]='CollegeList' [name]="collegeName" [faculty_authcation]="faculty_authcation" [item]="collegeIdentity" [audit_time]="audit_time" [collegeid]="collegeid" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-identity>



    <div class="left">
        <ul class="left-fixed">
            <li class="left-head">
                <!-- (click)="getUrl()"导航 -->
            </li>
            <li (click)="goChild('home','')" [class.active-li]="config.isNavActive=='home'" class="nav-home"><a routerLink="./homepage"><span></span>首页</a></li>
            <li *ngIf="config.isByCollege&&config.is_Frozen==false" (click)="config.isGoCollege=!config.isGoCollege" class="nav-college" [class.nav-list]="config.isGoCollege" [class.active-li]="config.isNavActive=='college'"><span></span>校园招聘<b></b></li>
            <li *ngIf="config.isGoCollege&&config.isByCollege" class="li-block">
                <!--<div class="li-list" (click)="goChild('college','college-details-student')" [class.li-active]="config.isNavChildActive=='college-details-student'"><a >生源速览</a></div>-->
                <div class="li-list" (click)="goChild('college','college-details-campus')" [class.li-active]="config.isNavChildActive=='college-details-campus'"><a>宣讲会</a></div>
                <div class="li-list" (click)="goChild('college','airCampusDetail')" [class.li-active]="config.isNavChildActive=='airCampusDetail'"><a>空中宣讲会</a></div>
                <div class="li-list" (click)="goChild('college','college-details-milk')" [class.li-active]="config.isNavChildActive=='college-details-milk'"><a>双选会</a></div>
            </li>
            <li *ngIf="!config.isByCollege&&config.is_Frozen==false" (click)="config.isGoCollege=!config.isGoCollege" class="nav-college" [class.nav-list]="config.isGoCollege" [class.active-li]="config.isNavActive=='college'"><span></span>校园招聘<b></b></li>
            <li *ngIf="config.isGoCollege&&!config.isByCollege" class="li-block">
                <div class="li-list" (click)="goChild('college','college-campustalk')" [class.li-active]="config.isNavChildActive=='college-campustalk'"><a routerLink="./college-campustalk">校园+</a></div>
                <div class="li-list" (click)="goChild('college','college-resume')" [class.li-active]="config.isNavChildActive=='college-resume'"><a routerLink="./college-resume">在线招聘</a></div>
                <div class="li-list" (click)="goChild('college','college-milkround')" [class.li-active]="config.isNavChildActive=='college-milkround'"><a routerLink="./college-milkround">双选会</a></div>
            </li>

            <!--<li *ngIf='config.is_Frozen==false' (click)="goToNewWeb()" class="nav-chat"   [class.active-li]="config.isNavActive=='chat'"><span></span><s *ngIf="config.unReadNum>0"></s>通知中心&lt;!&ndash;<a *ngIf="msgNumber1>0" style="width: 25px;border-radius: 4px;height: 17px;line-height: 20px;text-align: center;padding: -5px -5px 0px 0px ;margin-left: 5px;background-color: red;font-size: 12px;color: white">{{msgNumber1}}</a>&ndash;&gt;&lt;!&ndash;<b></b>&ndash;&gt;</li>-->
            <!--      <li *ngIf="config.isGoChat&&config.is_Frozen==false" class="li-block">

         <div  class="li-list" (click)="goChild('chat','chat-contact')" [class.li-active]="config.isNavChildActive=='chat-contact'">
           <a >在线直聊<s *ngIf="msgNumber2>0"></s>
           </a>
         </div>


        <div class="li-list" (click)="goChild('chat','chat-look')" [class.li-active]="config.isNavChildActive=='chat-look'"><a >谁看过我<a *ngIf="msgNumber3>0"style="width: 25px;border-radius: 4px;height: 17px;line-height: 20px;text-align: center;padding: -5px -5px 0px 0px ;margin-left: 8px;background-color: red;font-size: 12px;color: white">{{msgNumber3}}</a></a></div>
         <div class="li-list" (click)="goChild('chat','chat-collection')" [class.li-active]="config.isNavChildActive=='chat-collection'"><a>对我感兴趣<a *ngIf="msgNumber4>0" style="width: 25px;border-radius: 4px;height: 17px;line-height: 20px;text-align: center;padding: -5px -5px 0px 0px ;margin-left: 5px;background-color: red;font-size: 12px;color: white">{{msgNumber4}}</a></a></div>
        &lt;!&ndash;<div class="li-list" (click)="goChild('chat','chat-contact')" [class.li-active]="config.isNavChildActive=='chat-contact'"><a >在线直聊<s *ngIf="config.unReadNum>0"></s></a></div>&ndash;&gt;
        &lt;!&ndash;<div class="li-list" (click)="goChild('chat','chat-look')" [class.li-active]="config.isNavChildActive=='chat-look'"><a >谁看过我</a></div>&ndash;&gt;
        &lt;!&ndash;<div class="li-list" (click)="goChild('chat','chat-collection')" [class.li-active]="config.isNavChildActive=='chat-collection'"><a>对我感兴趣</a></div>&ndash;&gt;

      </li>-->



            <li (click)="config.isGoPosition=!config.isGoPosition" class="nav-position" [class.nav-list]="config.isGoPosition" [class.active-li]="config.isNavActive=='position'"><span></span>招聘管理<b></b></li>
            <li *ngIf="config.isGoPosition" class="li-block">
                <!-- <div class="li-list" (click)="goChild('position','position-calendar')" [class.li-active]="config.isNavChildActive=='position-calendar'"><a routerLink="./position-calendar">招聘日历</a></div> -->
                <div class="li-list" (click)="goChild('company','position-manage')" [class.li-active]="config.isNavChildActive=='position-manage'"><a routerLink="./position-manage">职位管理</a></div>
                <div class="li-list" *ngIf="ZhaopinSwitch" (click)="goChild('company','position-rules')" [class.li-active]="config.isNavChildActive=='position-rules'"><a routerLink="./position-rules">招聘简章</a></div>
                <div *ngIf='config.is_Frozen==false' class="li-list" (click)="goChild('position','position-resume')" [class.li-active]="config.isNavChildActive=='position-resume'"><a routerLink="./position-resume">简历管理</a></div>
                <div *ngIf='config.is_Frozen==false' class="li-list" (click)="goChild('position','position-notice')" [class.li-active]="config.isNavChildActive=='position-notice'"><a routerLink="./position-notice">面试管理</a></div>
                <!-- <div class="li-list" (click)="goChild('position','position-hired')" [class.li-active]="config.isNavChildActive=='position-hired'"><a routerLink="./position-hired">签约中心</a></div> -->
                <!--<div *ngIf='config.is_Frozen==false' class="li-list" (click)="goChild('position','position-campus')" [class.li-active]="config.isNavChildActive=='position-campus'"><a routerLink="./position-campus">我的宣讲会</a></div>-->
                <div *ngIf='config.is_Frozen==false' class="li-list" (click)="goChild('position','airCampHistory')" [class.li-active]="config.isNavChildActive=='airCampHistory'"><a routerLink="./airCampHistory">我的空中宣讲会</a></div>
                <!--<div *ngIf='config.is_Frozen==false' class="li-list" (click)="goChild('position','position-milk')" [class.li-active]="config.isNavChildActive=='position-milk'"><a routerLink="./position-milk">我的双选会</a></div>-->
                <div *ngIf='config.is_Frozen==false' class="li-list" (click)="goChild('position','collegeidentity')" [class.li-active]="config.isNavChildActive=='collegeidentity'"><a routerLink="./collegeidentity">申请高校合作</a></div>

            </li>
            <li (click)="config.isGoCompany=!config.isGoCompany" class="nav-company" [class.nav-list]="config.isGoCompany" [class.active-li]="config.isNavActive=='company'"><span></span>企业中心<b></b></li>
            <li *ngIf="config.isGoCompany" class="li-block">
                <div class="li-list" (click)="goChild('company','company-details')" [class.li-active]="config.isNavChildActive=='company-details'"><a routerLink="./company-details">公司信息</a></div>
                <!-- <div *ngIf="config.lastIdentity" class="li-list" (click)="goChild('company','company-authentication')" [class.li-active]="config.isNavChildActive=='company-authentication'">
           <a>
             企业认证
             <span class="auth-status" *ngIf="config.lastIdentity.status=='0'">(未认证)</span>
             <span class="auth-status" *ngIf="config.lastIdentity.status=='2'">(已认证)</span>
             <span class="auth-status" *ngIf="config.lastIdentity.status=='1'&&config.lastIdentity.order_stauts=='to_pay'">(未支付)</span>
             <span class="auth-status" *ngIf="config.lastIdentity.status=='1'&&config.lastIdentity.order_stauts!='to_pay'">(审核中)</span>
             <span class="auth-status" *ngIf="config.lastIdentity.status=='3'">(未通过)</span>
            </a>
          </div> -->
                <!-- <div class="li-list" (click)="goChild('company','company-authen')" [class.li-active]="config.isNavChildActive=='company-authen'">
            <a routerLink="./company-authentication">测试页面</a>
          </div> -->
                <div class="li-list" (click)="goChild('company','company-account')" [class.li-active]="config.isNavChildActive=='company-account'"><a routerLink="./company-account">账号管理</a></div>
                <!--          <div *ngIf='config.is_Frozen==false' class="li-list" (click)="goChild('company','company-identity')" [class.li-active]="config.isNavChildActive=='company-identity'"><a routerLink="./company-identity">
           高校合作 <span class="auth-status">(记录)</span></a></div>-->
            </li>
            <li *ngIf="booth" class="li-block booth">
                <img style="height: 110px;width: 210px;margin: 0;padding: 0" (click)="goActivityDetails(booth)" src="{{booth.image_url}}" alt="广告图">
            </li>

            <!--<li class="li-block booth booth-app">-->
            <!--<img src="assets/images/goworklaQRcode.png" alt="广告图"><br/>-->
            <!--扫码关注<br/>-->
            <!--&lt;!&ndash;<b>扫码下载APP</b><br/>&ndash;&gt;-->
            <!--<span style="padding: 0px 15px">及时了解最新双选会、宣讲会、简历投递等校招动态</span>-->
            <!--</li>-->
            <li class="li-block kefuBG" style="height: 380px;width: 100%" *ngIf="config.departmentSelf">
                <img style="width: 23px;
    height: 18px;
    position: absolute;
    margin-left: 28px;
    z-index: 100;
    margin-top: -11px" src="../../assets/images/kefu_hui.png">

                <div class="kefuBox" *ngIf="config.departmentSelf">
                    <div style="width: 100%;height: 50px">
                        <img src="{{config.departmentSelf.avatar}}" style="padding-right: 0px;" class="kefu_headPic">
                        <img src="../../assets/images/guwen_title.png" style="width:107px;height:17px;margin-left: 50px;margin-top: -20px">
                        <p class="kefu_name">{{config.departmentSelf.name}}</p>
                    </div>
                    <div style="width: 100%;height: 25px;margin-top: 10px;display: flex;align-items: center;justify-content: left;">
                        <img class="kefu_Icon" src="../../assets/images/kefu_wx.png">
                        <span class="kefu_infoTitle" style="padding:0">微信：</span>
                        <span class="kefu_info" style="padding:0">{{config.departmentSelf.wx}}</span>
                    </div>
                    <div style="width: 100%;height: 25px;display: flex;align-items: center;justify-content: left;">
                        <img class="kefu_Icon" src="../../assets/images/kefu_email.png">
                        <span class="kefu_infoTitle" style="padding:0">邮箱：</span>
                        <span class="kefu_info" style="padding:0">{{config.departmentSelf.email}}</span>
                    </div>
                    <img class="kefu_Code" src="{{config.departmentSelf.qr_code}}" alt="二维码">
                </div>
            </li>

        </ul>
    </div>


    <!-- 右侧主体部分 -->
    <div class="right">
        <!-- 主体组件部分 -->
        <div class="contentRight-cont">
            <router-outlet></router-outlet>
        </div>
        <!-- 尾部 -->
        <div class="contentRight-foot">{{config.websiteRecord}}<img src="../../assets/images/jishuZhiChiBanner.png" style="width: 200px;height: 50px;margin-top: 8px;float: right" /></div>
    </div>

    <!--<p-dialog  [(visible)]="isShowDownLoadAPP" [width]="300" [height]="340" [minHeight]="340" [modal]=true>-->
    <!--<p-header>下载APP <img (click)="cloceDownLoad()"  style="width: 15px;height: 15px;float: right;margin-top: 5px" src="../../assets/images/closeX.png"/></p-header>-->
    <!--&lt;!&ndash;<p style="text-align: center;line-height: 15px;padding-top: 0px;padding-bottom: 0px;margin: 0px 0px 10px 0px">高校合作通过后才可查看/申请</p>&ndash;&gt;-->
    <!--<img  style="width: 200px;height: 200px;margin-left: 25px" src="../../assets/images/goworklaQRcode.png"/>-->
    <!--<p style="text-align: center;line-height: 15px;margin: 0px">{{ShenHeAPPTest}}</p>-->
    <!--&lt;!&ndash;<p style="text-align: center;line-height: 15px;margin: 0px"></p>&ndash;&gt;-->
    <!--&lt;!&ndash;<p (click)="cloceDownLoad()" style="text-align: center;color: white;font-size: 15px;margin-left: 87px;width: 80px;padding-top: 5px;height: 30px;background-color: #5d479a">确定</p>&ndash;&gt;-->
    <!--</p-dialog>-->

    <p-dialog [(visible)]="isShowWeiShenQing" [style]="{'width':300,'height':180,'minHeight':180}" [modal]=true>
        <p-header>未申请高校合作 <img (click)="cloceDownLoad()" style="width: 15px;height: 15px;float: right;margin-top: 5px" src="../../assets/images/closeX.png" /></p-header>
        <p style="text-align: center;line-height: 15px;padding-top: 0px;padding-bottom: 0px;margin: 0px 0px 10px 0px">申请并通过高校合作后，才可查看/申请！</p>
        <br/>
        <button (click)="gotoShenQing()" style="height: 30px;margin-left: 30px;float: left;padding: 0px 15px;background-color:#5d479a;color: white ">立即申请</button>
        <button (click)="cloceDownLoad()" style="height: 30px;margin-right: 30px;float: right;padding: 0px 15px;background-color:#5d479a;color: white">取消</button>
    </p-dialog>


    <p-dialog [(visible)]="isShowWeiTongGuo" [style]="{'width':300,'height':180,'minHeight':180}" [modal]=true>
        <p-header>审核已拒绝 <img (click)="cloceDownLoad()" style="width: 15px;height: 15px;float: right;margin-top: 5px" src="../../assets/images/closeX.png" /></p-header>
        <p style="text-align: center;line-height: 15px;padding-top: 0px;padding-bottom: 0px;margin: 0px 0px 10px 0px">您申请的高校审核未通过，请点击查看详情</p>
        <br/>

        <button (click)="gotoGaoXiaoHistory()" style="height: 30px;margin-left: 30px;float: left;padding: 0px 15px;background-color:#5d479a;color: white ">立即前往</button>
        <button (click)="cloceDownLoad()" style="height: 30px;margin-right: 30px;float: right;padding: 0px 15px;background-color:#5d479a;color: white">取消</button>
    </p-dialog>

    <!--<p-dialog  [(visible)]="isShow" [width]="300" [height]="330" [minHeight]="340" [modal]=true>-->
    <!--<p-header>下载APP <img (click)="cloceDownLoad()"  style="width: 15px;height: 15px;float: right;margin-top: 5px" src="../../assets/images/closeX.png"/></p-header>-->
    <!--&lt;!&ndash;<p style="text-align: center;line-height: 15px;padding-top: 0px;padding-bottom: 0px;margin: 0px 0px 10px 0px">{{downLoadAPPChatText}}</p>&ndash;&gt;-->
    <!--<img  style="width: 200px;height: 200px;margin-left: 25px" src="../../assets/images/goworklaQRcode.png"/>-->
    <!--<p style="text-align: center;line-height: 15px;margin: 5px">下载/登陆APP</p>-->

    <!--<p style="text-align: center;line-height: 15px;margin: 5px">{{downLoadAPPChatText}}</p>-->
    <!--</p-dialog>-->

    <p-dialog [showHeader]="false" [dismissableMask]="true" [(visible)]="isShow" [style]="{'padding':0,'height':460,'width':402,'minHeight':460}" [modal]=true>
        <img (click)="goToNewWeb()" style="width: 400px;height: 460px;border-radius:3px" src="../../assets/images/goToSchoolAlert.png" />
    </p-dialog>


</div>