<!-- 页头部分 -->
<div class="contentRight-header">校园招聘 - 双选会</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-college-milksign [number]="milkNumber" [mridList]="mridList" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-milksign>
  <div class="college-block">
    <!-- 页头部分 -->
    <div class="college-block-head">
       双选会列表
    </div>
    <!-- 操作部分 <img src="assets/images/select-img.png">-->
    <div class="college-block-opera clear">
      <div class="left">
          <!-- <div class="select-all">全选</div>
          <button>批量申请</button> -->
          <button (click)="goSignList()">我的申请记录</button>
      </div> 
      <div class="right">
        <!-- 选择所在城市[(ngModel)]="" -->
       <span>筛选</span>
       <select name="city-milk" id="city-milk" autocomplete="off" [(ngModel)]="model.region" (ngModelChange)="getMilkList()">
           <option class="place-text" value='' disabled hidden selected="selected">选择所在城市</option>
           <option class="place-text" value='' selected="selected">不限</option>
           <option *ngFor="let city of hotCity" [value]="city.code">{{city.text}}</option>
       </select>
       <div class="date-block">
         <input type="text" id="startTime" [(ngModel)]="model.start_time" placeholder="请选择开始时间"/>
         <input type="text" id="endTime" [(ngModel)]="model.end_time" placeholder="请选择结束时间"/>
         <!-- <input type="date" [(ngModel)]="model.start_time" placeholder="请选择年月日"/>
         <input type="date" [(ngModel)]="model.end_time" placeholder="请选择年月日"/> -->
       </div>
       <!-- 搜索筛选 -->
       <div class="search-input right">
            <input type="text" placeholder="请输入学校名称" [(ngModel)]="model.college_name" (keyup)="inputKeyup($event)"><span (click)="goSearch(model.college_name)"><img src="assets/images/search.png"></span>
            <!-- 搜索关键词匹配列表 -->
            <div class="keyup-block" *ngIf="keywordList.length>0">
                <div class="keyup-list" *ngFor = "let key of keywordList" (click)="goSearch(key.text)">{{key.text}}</div>
            </div>
        </div>
      </div>
    </div>
    <!-- 没有内容存在时 -->
    <div *ngIf="countList==0" class="none-block">
        <img src="assets/images/resume-search-fail.png"><br/>
        暂未找到相关双选会，换个关键词试试吧
    </div>
    <!-- 循环列表 -->
    <div class="college-list-cont" *ngIf="countList>0">
        <ul>
            <li>双选会主题</li>
            <li>学校名称</li>
            <li>报名截止时间</li>
            <li>开始时间</li>
            <li>结束时间</li>
            <li>所在城市</li>
            <li>举办场地</li>
            <li>就业办电话</li>
            <li>当前状态</li>
            <li>审核状态</li>
            <li>操作</li>
        </ul>
        <!-- *ngFor="let n of collegeList;let i = index;" -->
        <div>
            <ul class="college-li" *ngFor="let n of milkRoundList;let i = index;">
                <li><span *ngIf='n.name'>{{n.name}}</span><span *ngIf='!n.name'>暂无</span></li>
                <li><span *ngIf='n.college_name'>{{n.college_name}}</span><span *ngIf='!n.college_name'>暂无</span></li>
                <li><span *ngIf='n.apply_end_time && n.apply_end_time != "" && n.apply_end_time != "0001-01-01T00:00:00+08:00" && n.apply_end_time != "0001-01-01T00:00:00"'>{{n.apply_end_time | date:"yyyy-MM-dd HH:mm" }}</span><span *ngIf='!n.apply_end_time || n.apply_end_time == "" || n.apply_end_time == "0001-01-01T00:00:00" || n.apply_end_time == "0001-01-01T00:00:00+08:00"'>暂无</span></li>
                <li><span *ngIf='n.start_time'>{{n.start_time | date:"yyyy-MM-dd HH:mm" }}</span><span *ngIf='!n.start_time'>暂无</span></li>
                <li><span *ngIf='n.end_time'>{{n.end_time | date:"yyyy-MM-dd HH:mm"}}</span><span *ngIf='!n.end_time'>暂无</span></li>
                <li><span *ngIf='n.region_text'>{{n.region_text}}</span><span *ngIf='!n.region_text'>暂无</span></li>
                <li><span *ngIf='n.place_name'>{{n.place_name}}</span><span *ngIf='!n.place_name'>暂无</span></li>
                <li><span *ngIf='n.phone'>{{n.phone}}</span><span *ngIf='!n.phone'>暂无</span></li>
                <li *ngIf="!n.signOpTime">
                  <span *ngIf="!n.isStart">即将开始</span>
                  <span *ngIf="n.isEnd">已经结束</span>
                  <span *ngIf="n.isStart && !n.isEnd">正在进行</span>
                </li>
                <li *ngIf="n.signOpTime">
                    <span *ngIf="!n.isAppStart">等待报名</span>
                    <span *ngIf="n.isAppStart && !n.isAppEnd">正在报名</span>
                    <span *ngIf="n.isAppEnd && !n.isStart">即将开始</span>
                    <span *ngIf="n.isStart && !n.isEnd">正在进行</span>
                    <span *ngIf="n.isEnd">已经结束</span>
                </li>
                <li>
                  <span *ngIf="!n.status">未报名</span>
                  <span *ngIf="n.status=='to_audit'">审核中</span>
                  <span *ngIf="n.status=='passed'">已通过</span>
                  <span *ngIf="n.status=='not_passed'">未通过</span>
                </li>
                <li *ngIf="!n.signOpTime">
                    <span (click)="goDeatils(n.mrid)">查看</span>
                    <span *ngIf="!n.status && !n.isStart" (click)="goSign(n)">我要报名</span>
                    <span *ngIf="n.status == 'passed' && !n.isEnd && n.poster_type"><a  (click)="downloadPoster(n.applyid)">下载海报</a></span>
                    <!-- <span *ngIf="n.isStart" (click)="goSpace(n.mrid)">进入会场</span> -->
                    <!-- <span>收藏</span>
                    <span>分享</span> -->
                </li> 
                <li *ngIf="n.signOpTime">
                    <span (click)="goDeatils(n.mrid)">查看</span>
                    <span *ngIf="!n.status && n.isAppStart && !n.isAppEnd" (click)="goSign(n)">我要报名</span>
                    <span *ngIf="n.status == 'passed' && !n.isEnd && n.poster_type"><a  (click)="downloadPoster(n.applyid)">下载海报</a></span>
                    <!-- <span *ngIf="n.isStart" (click)="goSpace(n.mrid)">进入会场</span> -->
                    <!-- <span>收藏</span>
                    <span>分享</span> -->
                </li> 
            </ul>
        </div>
    </div>
    <!-- 分页部分 -->
    <div class='paging-block' [class.isPageHeight]="countList>0">
        <router-outlet></router-outlet>
        <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
    </div>
  </div> 
</div>

