import { Component, OnInit, Input, Output,ViewChild } from '@angular/core';
import { DataApi,Local,Opera,Constants,Config } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import { ModelPositionNoticeComponent } from '../model-position-notice/model-position-notice.component';
@Component({
  selector: 'app-model-delivery-resume',
  templateUrl: './model-delivery-resume.component.html',
  styleUrls: ['./model-delivery-resume.component.css']
})
export class ModelDeliveryResumeComponent implements OnInit {
  @ViewChild('noticeDelivery', { static: true }) child: ModelPositionNoticeComponent;
  @Output() modelShow = false;//是否打开弹窗
  public config: Config;
  public positionList=[];//职位列表
  public educationList=[];//学历列表
  public keywordList=[];//搜索关键词匹配列表
  // 筛选条件--搜索
  public model={
    collegeid:'',//高校id
    keywords:'',//关键词
    major:'',//专业
    education:'',//学历
    status:'',//状态
    positionid:'',//职位id
    limit:9,
    offset:0
  }
  public status={
    feedback_status:''//反馈状态 posted：已投递viewed：已被查看interested：合适 improper：不合适
  }
  public searchModel = '';//搜索关键词
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  public majorOne='';//专业类别一级
  public resumeList = [];//简历列表
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  // public isPsitionList=false;
  public isShowFilter = false;
  @Input()  isShow: false;//是否显示弹窗页面
    public unReadCount  = {
        collegeCount:0,
        resumeCount:0,
        isAutu:false,
    };

  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public dialog: MatDialog,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
   }

  ngOnInit() {
    if(this.local.get('collegeid')){
      this.model.collegeid = this.local.get('collegeid');
    }
    this.getPositionList();//获取职位列表数据
    this.getCollegeCode();//获取高校字典
    this.getMajorList();//获取专业列表信息
    this.getEducationList();//获取学历列表信息
    this.getSendResumeList();//获取简历投递列表
      this.getDeliveryStatistics();
  }

  //去面试邀请
  goNotice(id,talentid){
    this.modelShow=true;
    this.child.initData(id,true,talentid);
  }
  //接收子组件返回参数
  Modeldismiss(data){
    this.modelShow = false;
    if(data){
      //console.log("面试邀请返回信息-----model-delivery-resume");
      //console.log(data);
    }
  }

  //反馈状态
  setResumeStatus(id,status){
    this.status.feedback_status = status;
    this.dataApi.setSendResumeStatus(id,status).then((res:any)=>{
      this.toastr.success("状态修改成功");
      for(let n of this.resumeList){
         if(n.deliveryid==id){
            n.feedback_status=status;
         }
      }
    }).catch((err)=>{
       //console.log(err);
       this.toastr.error(err);
    })
  }

  //设置为不合适提醒
  setResumeImproper(id,name){
    this.opera.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '简历处理为不合适',
        message: '简历处理为不合适后，15天内将不再接收 TA 的消息。如需修改，可到简历列表中重新处理。确定将【'+name+'的简历】 处理为不合适吗？',
        trueText:'确定',
        falseText:'再考虑一下'
      }
    }).afterClosed().subscribe((isConfirmed) => {
        //console.log('返回值');
        //console.log(isConfirmed);
        this.opera.scrollTrue();
        if(isConfirmed){
          this.setResumeStatus(id,'improper')
        }
    });
  }



   //获取分页子组件返回数据
   getChildEvent(index){
    //console.log('子组件返回分页数-----college-resume');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getSendResumeList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getSendResumeList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getSendResumeList();//获取简历投递列表
      }
    }

  }

  //获取简历投递列表
  getSendResumeList(){
    this.keywordList=[];
    this.dataApi.getSendResumeList(this.model).then((res:any)=>{
    console.log("获取简历投递列表--------model-delivery-resume",res);
      //console.log(res);
        if(res)
        {
            this.resumeList = res.list;
            this.countList=res.count;
            this.pageCount =Math.ceil(res.count/9);
        }

    }).catch((err)=>{
      //console.log(err);
    })
  }


  //监听majorOne
  majorOneChange(){
    // //console.log("第一级专业值");
    // //console.log(this.majorOne);
    // //console.log("专业列表");
    // //console.log(this.config.major);
    this.majorListTwo=[];
    for(let n of this.config.major){
      if(n.parentcode==this.majorOne){
        this.majorListTwo.push(n);
      }
    }
    // //console.log("第二级专业列表");
    // //console.log(this.majorListTwo);
  }


  //提示先选一级专业
isShowTwo(){
  if(!this.majorOne){
    this.toastr.warning("请先选择一级专业");
    return;
  }
 }


  //获取学历列表信息
  getEducationList(){
    if(this.dataApi.getEducationCode()){
      //console.log("获取学历列表本地存储-----college-resume");
      //console.log(this.dataApi.getEducationCode());
      this.educationList = this.dataApi.getEducationCode();
    }else{
      this.dataApi.getDictDate('education').then((res:any)=>{
        //console.log("获取学历列表-----college-resume");
        //console.log(res);
          if(res)
          {
              this.educationList = res;
              this.dataApi.setEducationCode(res);
          }

      }).catch((error)=>{
        //console.log(error);
      });
    }
  }

  //获取一级专业列表
  getMajorListOne(){
    for(let n of this.config.major){
      if(n.level==2){
        this.majorListOne.push(n);
      }
    }
  }

  //获取专业列表信息
  getMajorList(){
    if(this.dataApi.getMajorCode()){
      this.config.major = this.dataApi.getMajorCode();
      this.getMajorListOne();//获取一级专业列表
    }else{
      this.dataApi.getDictDate('major').then((res:any)=>{
        //console.log("获取专业列表-----college-resume");
        //console.log(res);
          if(res)
          {
              this.config.major = res;
              this.getMajorListOne();//获取一级专业列表
              this.dataApi.setMajorCode(res);
          }

      }).catch((error)=>{
        //console.log(error);
      });
    }
  }

  //获取高校字典
  getCollegeCode(){
    // 获取高校字典数据
    if(this.config.school.length<1){
     this.dataApi.getDictDate('school').then((res:any)=>{
       // //console.log(res);
         if(res)
         {
             this.config.school=res;
         }
     }).catch((err)=>{
       // this.toastr.error("网络错误,请稍后重试");
       //console.log(err);
     })
   }
 }

  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      that.goSearch(that.model.keywords);
    }else{
      that.getItems();
    }
  }

  //匹配高校名称字典
  getItems(){
    this.keywordList=[];
    if(!this.model.keywords){return;}
    for(let s of this.config.school){
      if(s.text.indexOf(this.model.keywords) >= 0){
         this.keywordList.push(s);
      }
    }
  }

  //筛选
  goSearch(text){
    this.model.keywords = text;
    this.getSendResumeList();//获取简历列表
    this.keywordList=[];
  }

   //获取职位列表数据
   getPositionList(){
    //console.log("在期职位本地存储----");
    //console.log(this.dataApi.getLocalpositionList());
    if(this.dataApi.getLocalpositionList()){
      this.config.positionList=this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
      this.positionList =  this.config.positionList;
    }else{
      this.getNewList();
    }
  }

  //-------获取线上数据
  getNewList(){
    let model={
      limit:100,
      offset:0,
      status:'release',
    }
    this.dataApi.getPositionList(model).then((res:any)=>{
       //console.log(res);
        if(res)
        {
            this.positionList =  res.list;
            this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
        }

     }).catch((error) => {
       this.toastr.error(error);
     });
  }

  //-----跳转简历详情
  goDetails(n){
    this.config.isNavActive='college';
    this.config.isNavChildActive='college-resume';
    this.setResumeStatus(n.deliveryid,'viewed');//简历状态修改为已查看
    this.router.navigate(['home/college-resumedetails'], {
      queryParams: {
        resumeid:n.resume_number,
        positionid:this.model.positionid
      }
    });
  }

  //立即沟通
  goChat(n,id){
    this.opera.goChat(n,id);
  }
  //收藏
  goCollect(val){
    val = this.opera.manageResumeArchived(val);
  }
  getDeliveryStatistics(){


      let data = {
          collegeid:this.local.get('collegeid')
      }
      this.dataApi.getDeliveryStatistics(data).then((res:any)=>{
          //console.log(res);
          if(res)
          {
           console.log('getDeliveryStatistics',res);
                //is_cooperation: false
              // college_count: 0
              // delivery_count: 0
              this.unReadCount.isAutu = res.is_cooperation;
              this.unReadCount.collegeCount = res.college_count;
              this.unReadCount.resumeCount = res.delivery_count;
          }

      }).catch((error) => {
          this.toastr.error(error);
      });
  }

    gotoPro(){
        if(this.local.get('accesstoken')) {
            let token = this.local.get('accesstoken');
            let accountid = this.local.get('accountid');
            let collegeid = this.local.get('collegeid');
            window.open(this.config.JumpMainWeb+token+'&accountid='+accountid+'&route='+'position-resume','_blank')
        }
    }

}
