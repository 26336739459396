<!-- 页头部分 -->
<div class="contentRight-header"><img (click)="opera.goNewRouter('college-details-org')" src='assets/images/router-old.png'/>校园招聘<span *ngIf="!config.isByCollege"> - 校园+</span> - 学校详情 - 院系详情</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <!-- 遮罩层部分 -->
  <router-outlet></router-outlet>
  <div class="college-block" style="margin-bottom:20px;">
        <!-- 页头部分 -->
        <div class="college-block-head">
          院系详情
        </div>
        <!-- 详情模块 -->
        <div class="college-head-details clear" *ngIf="college">
            <!-- 左侧文字 -->
            <ul class="left">
              <li><span>学院名称：</span>{{college.name}}<i *ngIf="!college.name">暂无</i></li>
              <li><span>就业负责人：</span>{{college.linkman}}<i *ngIf="!college.linkman">暂无</i></li>
              <li><span>学院地址：</span>{{college.address}}<i *ngIf="!college.address">暂无</i></li>
              <li><span>学院网址：</span>{{college.website}}<i *ngIf="!college.website">暂无</i></li>
              <li><span>联系电话：</span>{{college.phone}}<i *ngIf="!college.phone">暂无</i></li>
            </ul>
            <!-- 右侧logo -->
            <div class="right">
                <img *ngIf="college.logo" [src]="college.logo" alt="学院logo">
                <img *ngIf="!college.logo" src="assets/images/none.png" alt="学院logo">
            </div>
        </div>
  </div>
  <div class="college-block">
    <!-- 中间部分 -->
    <!-- 没有内容存在时 -->
    <div *ngIf="countList==0" class="none-block">
        <img src="assets/images/resume-search-fail.png"><br/>
        暂未找到专业，请稍后重试
    </div>
    <div *ngIf="countList>0" class="college-resume-cont">
        <ul>
            <li>专业名称</li>
            <li>适用学历</li>
            <li>应届生数量</li>
            <li>是否推荐专业</li>
            <li>操作</li>
        </ul>
        <div>
            <ul class="resume-li" *ngFor="let n of resumeList;let i = index;">
                <li><span *ngIf='n.name'>{{n.name}}</span><span *ngIf='!n.name'>暂无</span></li>
                <li>
                  <span *ngIf='n.educations.length>0'>
                    <i *ngFor="let edu of n.educations">{{edu.text}}</i>
                  </span>
                  <span *ngIf='n.educations.length==0'>暂无</span>
                </li>
                <li><span *ngIf='n.student'>{{n.student}}</span><span *ngIf='!n.student'>暂无</span></li>
                <li>否</li>
                <li>
                   <span (click)="goDetails(n)">查看生源</span>
                </li> 
            </ul>
        </div>
    </div>
  </div>
  <!-- 分页部分 -->
  <!-- <div class='paging-block' [class.isPageHeight]="countList>0">
     <router-outlet></router-outlet>
     <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
 </div> -->
</div>