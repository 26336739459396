import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'contimetransform'})
export class ConTimeTransform implements PipeTransform {
  constructor(){}  
  transform(_content) {    
    if(_content){
      let date,yyyy,MM,dd,hh,mm,nowDate,nowyyyy,nowMM,nowdd;      
      date = new Date(_content);
      yyyy = date.getFullYear(); //年份
      MM = date.getMonth() + 1; //月份 
      dd = date.getDate(); //日 
      hh = date.getHours(); //小时 
      mm = date.getMinutes(); //分
      if(hh<10){
        hh = '0'+hh;
      }    
      if(mm<10){
        mm = '0'+mm;
      } 
      //创建今天的日期,做比对
      nowDate = new Date();
      nowyyyy = nowDate.getFullYear(); //年份
      nowMM = nowDate.getMonth() + 1; //月份 
      nowdd = nowDate.getDate(); //日 
      
      if(yyyy==nowyyyy && MM==nowMM && dd==nowdd){
        return hh+':'+mm;        
      }else if(yyyy==nowyyyy && MM==nowMM && dd==(nowdd-1)){
        return "昨天"
      }else{
        return MM+'月'+dd+'日';
      }
      
    }else{
      return _content;
    }
    
  }
}