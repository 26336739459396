<!-- 二级多选 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
  <div class="black-bg" (click)="dismiss(false)"></div>
  <div class="model-cont">
      <!-- 上层提示部分 -->
      <div class="model-head clear">
          <div class="left">意向专业</div>
          <button class="right" (click)="dismiss(false)">×</button>
      </div>
      <div class="select-more-title  clear">
  
        <div class="select-more-absolute">
          <div class="title-block">已选：
            <div class="right-block"><span>{{major.length}}</span> / {{initLength}}</div>
          </div>
  
          <div class="select-choose-block">
            <div class="choose-list">
              <div class="choose-item"  *ngFor="let item of major">
                <div class="choose-text">{{ item.text }}</div>
                <div class="choose-delete" (click)="getSecendLevel($event,item)">x</div>
              </div> 
            </div>         
          </div>

        </div>
  
        <div id="first-level" class="first-level" style="overflow-y: auto;">
          <div class="list-block" *ngFor="let item of dataList" (click)="getFirstLevel(item)" [class.activeAddress]="item.facultyid==facultyid">
            <div *ngIf="faculty.indexOf(item.facultyid)>-1" class="active-pic"><span></span></div>
            {{ item.name }}
          </div>    
        </div>
        
        <div id="secend-level" class="secend-level" style="overflow-y: auto;">
          <div class="list-block" *ngFor="let item of secendLevel" (click)="getSecendLevel($event,item)" [class.active]="major.indexOf(item)>-1">
            <div class="list-text" [class.activeChild]="major.indexOf(item)>-1">
              {{ item.text }}
            </div>
  
          </div>   
        </div>
  </div>
  <!-- 底部操作部分 -->
  <div class="model-foot"><button class="purple" (click)="dismiss(initvalue)">保存</button></div>

</div>
