 <div class="college-block" *ngIf="isShow">
    <router-outlet></router-outlet>
    <app-model-position-notice #noticeDelivery  [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-position-notice>
   <!-- 头部 -->
  <div class="college-resume-head clear">
      <div class="search-position left">
          <!-- 按发布的职位筛选 -->
          <span>在期职位&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <select name="position" id="position" [(ngModel)]="model.positionid" (ngModelChange)="getSendResumeList()" autocomplete="off">
              <option value=''>全部</option>
              <option *ngFor="let a of positionList" [value]="a.positionid">{{a.name}}</option>
          </select>
      </div>
      <!-- 更多筛选 -->
      <div class="search-more right" (click)="isShowFilter=!isShowFilter" [class.show-filter]="isShowFilter">更多条件筛选<img src="assets/images/down-gray.png"></div>
      <!-- 搜索筛选 -->
      <!-- <div class="search-input right">
        <input type="text" placeholder="请输入学校名称" [(ngModel)]="model.keywords" (keyup)="inputKeyup($event)"><span (click)="goSearch(model.keywords)"><img src="assets/images/search.png"></span>

        <div class="keyup-block" *ngIf="keywordList.length>0">
            <div class="keyup-list" *ngFor = "let key of keywordList" (click)="goSearch(key.text)">{{key.text}}</div>
        </div>
      </div> -->
      <!-- <span>&nbsp;&nbsp;&nbsp;&nbsp;其他条件筛选&nbsp;&nbsp;&nbsp;&nbsp;</span> -->
  </div>
  <div *ngIf="isShowFilter" class="college-resume-filter">
      <!-- 选择学校 -->
      <!-- <span>学校</span>
      <select name="college" id="college" [(ngModel)]="model.collegeid" (ngModelChange)="getResumeList()" autocomplete="off">
          <option class="place-text" value='' disabled hidden selected="selected">选择学校</option>
          <option *ngFor="let a of schoolList" [value]="a.collegeid">{{a.name}}</option>
      </select> -->
       <!-- 简历状态 -->
       <span>简历状态</span>
       <select name="major" id="major" [(ngModel)]="model.status" (ngModelChange)="getSendResumeList()" autocomplete="off">
           <option class="place-text" value='' disabled hidden selected="selected">选择简历状态</option>
           <option value="posted">未查看</option>
           <option value="viewed">已查看</option>
           <option value="interested">合适</option>
           <option value="improper">不合适</option>
       </select>
      <!-- 选择专业 -->
      <span>&nbsp;&nbsp;&nbsp;&nbsp;专业</span>
      <select name="major" id="major" [(ngModel)]="majorOne" (ngModelChange)="majorOneChange()" autocomplete="off">
          <option class="place-text" value='' disabled hidden selected="selected">选择一级专业</option>
          <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
      </select>
      <select name="majorTwo" id="majorTwo" [(ngModel)]="model.major" (ngModelChange)="getSendResumeList()" (click)="isShowTwo()" autocomplete="off">
              <option class="place-text" value='' disabled hidden selected="selected">选择二级专业</option>
              <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
      </select>
      <!-- 选择学历 -->
      <span>&nbsp;&nbsp;&nbsp;&nbsp;学历</span>
      <select name="education" id="education" [(ngModel)]="model.education" (ngModelChange)="getSendResumeList()" autocomplete="off">
          <option class="place-text" value='' disabled hidden selected="selected">选择学历</option>
          <option *ngFor="let a of educationList" [value]="a.code">{{a.text}}</option>
      </select>
      <!-- 重置 -->
      <!-- <button (click)="goInit()">重置</button> -->
  </div>
  <!-- 中间部分 -->
     <!--<div (click)="gotoPro()" *ngIf="unReadCount.resumeCount>0" style="text-align: center;width: 100%;height: 50px;margin: 5px 0;color: #5d479a;line-height: 50px;cursor: pointer">{{unReadCount.isAutu?"您还有来自其他":"您有来自其他"}}{{unReadCount.collegeCount}}个学校的{{unReadCount.resumeCount}}份简历未查看，请使用工作啦pro版一站管理所有简历</div>-->

     <!-- 没有内容存在时 -->
  <div *ngIf="countList==0" class="none-block">
      <img src="assets/images/resume-search-fail.png"><br/>
      暂未找到相关简历

  </div>
  <div *ngIf="countList>0" class="college-resume-cont">
      <ul>
          <li>头像</li>
          <li>姓名</li>
          <li>性别</li>
          <li>年龄</li>
          <li>最后毕业学校</li>
          <li>专业</li>
          <li>学历</li>
          <li>应聘职位</li>
          <li>匹配度</li>
          <li>处理状态</li>
          <li>操作</li>
      </ul>
      <div>
          <ul class="resume-li" *ngFor="let n of resumeList;let i = index;">
              <li>
                  <!-- <img *ngIf="n.logo" [src]="n.logo+'&imageView2/1/w/100/h/100'"><img *ngIf="!n.logo" src="assets/images/none.png"> -->
                  <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}">
                  <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png">
                  <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png">
                  <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png">
              </li>
              <li><span *ngIf='n.talent_name'>{{n.talent_name}}</span><span *ngIf='!n.talent_name'>暂无</span></li>
              <li><span *ngIf='n.gender_text'>{{n.gender_text}}</span><span *ngIf='!n.gender_text'>暂无</span></li>
              <li><span *ngIf='n.age'>{{n.age}}</span><span *ngIf='!n.age'>暂无</span></li>
              <li><span *ngIf='n.graduate_university'>{{n.graduate_university}}</span><span *ngIf='!n.graduate_university'>暂无</span></li>
              <li><span *ngIf='n.major_text'>{{n.major_text}}</span><span *ngIf='!n.major_text'>暂无</span></li>
              <li><span *ngIf='n.education_text'>{{n.education_text}}</span><span *ngIf='!n.education_text'>暂无</span></li>
              <li>
                  <span *ngIf='n.position_name'>{{n.position_name}}</span>
                  <span *ngIf='!n.position_name'>暂无</span>
              </li>
              <li><span *ngIf='n.match'>{{n.match}}%</span><span *ngIf='!n.match'>暂无</span></li>
              <li>
                <span *ngIf="n.feedback_status=='posted'" class="red">未查看</span>
                <span *ngIf="n.feedback_status=='viewed'">已查看</span>
                <span *ngIf="n.feedback_status=='interested'">合适</span>
                <span *ngIf="n.feedback_status=='improper'">不合适</span>
                <span *ngIf='!n.feedback_status'>暂无</span>
              </li>
              <li>
                  <span (click)="goDetails(n)">查看简历</span>
                  <!--<span (click)="goChat(n,model.positionid)">{{n.jm_username?'继续沟通':'立即沟通'}}</span>-->
                  <!--<span *ngIf="n.feedback_status!='improper'" (click)="goNotice(n.interviewid,n.talentid)">面试邀请</span>-->
                  <span *ngIf="n.feedback_status!='improper'" (click)="setResumeImproper(n.deliveryid,n.talent_name)">不合适</span>
                  <span *ngIf="n.feedback_status=='improper'" (click)="setResumeStatus(n.deliveryid,'viewed')">重新处理</span>
                  <!-- <span>分享</span> -->
              </li>
          </ul>
      </div>
  </div>
  <!-- 分页部分 -->
  <div class='paging-block' [class.isPageHeight]="countList>0">
     <router-outlet></router-outlet>
     <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
  </div>
</div>
