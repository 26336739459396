import {Injectable} from '@angular/core';

/**
 * 这是一个单例模式的config，用于共享全局变量
 */
@Injectable()
export class Config {

    

    // 配置信息(本地) 杨东波的本地服务器地址
    // public api ='http://192.168.0.120:801';          //http请求前缀
    // public agentApi = 'http://192.168.0.120:802';     //http请求前缀
    // public clientId = '5d9d56d72e8f034ad7d0e5e5';      //clientId  （客户端ID）
    // public GetWXCode = 'http://wx.test.goworkla.cn/mp/makeercode?sceneStr='; //注册第三步和首页获取微信公众

    // 配置信息（test）
    // public api = 'http://apitest.goworkla.cn/';          //http请求前缀
    // public agentApi = 'http://agentest.goworkla.cn/';     //http请求前缀
    // public clientId = '5d9d56d72e8f034ad7d0e5e5';      //clientId  （客户端ID）
    // public GetWXCode = 'http://wx.test.goworkla.cn/mp/makeercode?sceneStr='; //注册第三步和首页获取微信公众

    // 配置信息（test）
    // public api = 'http://webapi.test.goworkla.cn';          //http请求前缀
    // // public agentApi = 'http://agent.test.goworkla.cn';     //http请求前缀
    // public agentApi = 'http://agentnew.test.goworkla.cn/';     //http请求前缀
    // public clientId = '5d9d56d72e8f034ad7d0e5e5';      //clientId  （客户端ID）
    // public GetWXCode = 'http://wx.goworkla.cn/mp/makeercode?sceneStr='; //注册第三步和首页获取微信公众
    // //
    // public api = 'http://webapi2.test.goworkla.cn';          //http请求前缀
    // public agentApi = 'http://agent2.test.goworkla.cn';     //http请求前缀
    // public clientId = '5d9d56d72e8f034ad7d0e5e5';      //clientId  （客户端ID）
    // public GetWXCode = 'http://wx.test.goworkla.cn/mp/makeercode?sceneStr='; //注册第三步和首页获取微信公众

    // public api = 'http://webapi3.test.goworkla.cn';          //http请求前缀
    // public agentApi = 'http://agent3.test.goworkla.cn';       //http请求前缀
    // public clientId = '5d9d56d72e8f034ad7d0e5e5';      // clientId  （客户端ID）
    // public GetWXCode = 'http://wx.test.goworkla.cn/mp/makeercode?sceneStr='; //注册第三步和首页获取微信公众

    //配置信息(正式环境)
    public api = "https://webapinet6.goworkla.cn";          // http请求前缀
    //public api = "https://apinet6.goworkla.cn";          // http请求前缀
    public agentApi = 'https://agentnew.goworkla.cn';     //http请求前缀
    public clientId = '5d9d56d72e8f034ad7d0e5e5';      // clientId  （客户端ID）
    public GetWXCode = 'http://wx.goworkla.cn/mp/makeercode?sceneStr='; //注册第三步和首页获取微信公众



   //首页点击-谁看过我/对我看兴趣跳转到高校企业总后端并自动登录
   //public JumpMainWeb ='http://http://localhost:4203/#/login?token=';
   // public JumpMainWeb ='http://employer.test.goworkla.cn/#/login?token=';
    public JumpMainWeb ='https://employer.goworkla.cn/#/login?token=';
   // public JumpMainWeb ='http://localhost:4201/#/login?token=';


    //public urmApi = "https://urm.test.goworkla.cn/";
    public urmApi = "https://urm.goworkla.cn";

    public PC_Version = "1.3.7.1";

    public isActiveNav ='home';         //判断当前激活的导航栏
    public CustomerServicePhone ='400-661-1396';//客服电话
    public websiteRecord = 'Copyright © ' + this.getYear() + ' GoWork.la. All Rights Reserved.  Ver  '+this.PC_Version;//网站备案号
    public isNavActive = 'home';                //当前激活的导航栏

    public current_system  = "b_lite";
    public current_Collegeid  = "";
    public isDoubleCollegeClearLoginInfo  = false;

    public isNavChildActive = '';               //当前激活的子导航栏
    public isLogin = false;                     //当前是否是登陆页面（用于改变顶部导航栏）
    public isGuide = false;                     //当前是否是引导页面（用于改变顶部导航栏）
    public loading:boolean = false;             //是否显示lodar动画
    public isGoCollege = false;                 //当前是否激活校园招聘导航
    public isGoChat = false;                   //当前是否激活消息中心导航
    public isGoPosition = false;              //当前是否激活招聘管理导航
    public isGoCompany = false;               //当前是否激活企业中心导航

    public isByCollege=true;           //判断是否是从就业信息网跳转
    public byCollegeLogo='';           //从就业信息网跳转高校logo
    public byCollegeName='';           //从就业信息网跳转高校名称
    public token: string = '';         //如果已经登录，存放token，请和localstorage.get('token')同步（令牌）
    public refresh_token : any;         //刷新token
    public accountid: string = '';     //accountid（账号ID）
    public employerid: string = '';    //employerid
    public loginUser: any;             //如果已经登录，存放登录用户信息，请和本地存储保持同步
    public loginName: any;             //账号，方便出错登录
    public loginEmail: any;             //邮箱账号，方便出错登录
    public department: any;            //企业信息 department
    public lastIdentity: any;          //最后一次认证信息
    public lastCollegeIdentity: any;   //最后一次学校认证信息

    public positionList = [];          //在期职位列表(进入首页就需要用到)
    public chatsMsgList = [];          //聊天记录本地存储
    public chatsList :any;             //会话列表本地存储
    public unReadNum = 0;              //tabs页面未读消息数量

    public isAskResume = false;        //是否索要简历

    public industry=[];               //行业字典
    public post_type=[];              //职位类别
    public major=[];                 //专业字典
    public skill=[];                 //技能字典
    public region=[];                //地区字典
    public education=[];             //学历字典
    public common=[];               //常用语字典
    public commonJun=[];            //小秘书常用语字典
    public school_type=[];          //高校分类字典（只做全局变量）
    public school_nature=[];        //高校性质字典（只做全局变量）
    public school_label=[];        //高校特色字典（只做全局变量）
    public school=[];            //高校字典（只做全局变量）
    public label=[];             //企业标签字典
    public strength=[];          //企业实力标签
    public employer_type=[];     //企业性质字典
    public scale=[];             //企业规模字典
    public organization_type=[]; //组织机构类型字典

    static instance: Config;           //初始化config
    static isCreating: Boolean = false;//判断config是否已初始化
    public searchStatus = false;//是否开通高级搜索权益
    public searchendTime:any;//认证结束时间
    public  is_Frozen:any;

    //企业的校招顾问，在home页面获取，赋值
    public departmentSelf:any;
    public departmentSelf_ex = "(添加请备注“用人单位名称+姓名”)";


  public isScreenSmallBox = false;//当前屏幕分辨率小的提示，在登录也检测屏幕分辨率
 //是否是谷歌内核
   public chrome_core = false;

    //是否显示网络请求400，500 错误
    public isShowHTTPErrModel = false;

  constructor() {
        if (!Config.isCreating) {
            throw new Error("You can't call new in Config Singleton instance!");
        }
    }

    static getInstance() {
        if (Config.instance == null) {
            Config.isCreating = true;
            Config.instance = new Config();
            Config.isCreating = false;
        }
        return Config.instance;
    }

    getYear() {
        let newYear = new  Date();
        return newYear.getFullYear();
    }

}


