import {Injectable} from '@angular/core';
// import {Http, Response, RequestOptions, Headers} from '@angular/http';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Events } from '../provider/eventService';
import {Config} from './config';
import {Constants} from './constants';
import {Local} from './local';
import { Router } from  '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, retry ,timeout,map} from 'rxjs/operators';
declare var window;
import {Md5} from "ts-md5";

/**
 * 对http的二次封装，在程序中用来获取远程数据
 */
@Injectable()
export class Api {
  private config:Config;
  constructor(
    private http: HttpClient,
    public local:Local,
    public events: Events,
    private router: Router) {
    this.init();
  }
  private init() {
    //获取config单例一个实例
    this.config = Config.getInstance();
  }
  //私有的错误处理函数
  private handleError(error: Response | any) {
    // console.log("出现错误----------------------------api.ts");
    // console.log(error.error.Errmsg);
    //console.log(this.local.get(Constants.accesstoken));
    let errBody = {
      Errcode:'',
      Errmsg:''
    };
    try {
      errBody = error.error;
    }catch(e) {
    }
    let errMsg;
    if(errBody.Errcode=='1006' || errBody.Errcode=='1018'){
      console.log("发送退出登录广播----------------------------api.ts",errBody);
      //api出现错误，退出登陆
      this.events.publish('user:created'); //发送退出登录选项
      return;
    }
    //以防万一 无效的凭证
    if(errBody.Errmsg) {
      if(errBody.Errmsg.indexOf('无效的凭证')>=0) {
        this.events.publish('user:created'); //发送退出登录选项
        return;
      }
    }

    if(error.status == 409){
      if(errBody.Errcode=='2001'||errBody.Errcode=='2002'||errBody.Errcode=='2005'){
        return Promise.reject(errBody);
      }
    }
    //屏蔽词检测，服务器返回的屏蔽词code未知，只能检测包含检测到屏蔽词 认定为敏感词错误
    if(errBody.Errmsg) {
      if(errBody.Errmsg.indexOf('检测到屏蔽词')>=0) {
        let words = errBody.Errmsg;
        var aPos = words.indexOf('“');
        var bPos = words.indexOf('”');
        var r = words.substr(aPos + 1, bPos - aPos - 1);
        return Promise.reject('系统检测到敏感词：'+r);
      }
    }
    if(errBody.Errmsg) {
      if(errBody.Errmsg.indexOf('当前用户不是账户的拥有者')>=0) {
        this.events.publish('user:created'); //发送退出登录选项
        return;
      }
    }

	// 冻结逻辑中报错,暂注释
    // if(error.status == 403){
    //   this.events.publish('user:created'); //发送退出登录选项
    // }

    errMsg = errBody.Errmsg?errBody.Errmsg:errMsg;
    if(errBody.Errcode=='1021'){//账号已被删除

      if(errBody.Errmsg) {
        if(errBody.Errmsg.indexOf('对方账户不存在')>=0) {
          return Promise.reject(errMsg+"");
        }
      }
      this.accountOut();
      // this.events.publish('user:created'); //发送退出登录选项

    }else if(errBody.Errcode=='1041'){
      this.events.publish('user:pullblack');
      return Promise.reject(errBody);
    }
    return Promise.reject(errMsg);
  }





  //JS加密
  private convertDate = function(date) {
    let res = date.getFullYear() + ',' + (date.getMonth() + 1) + ',' + date.getDate() + ',' + date.getHours() + ',' + date.getMinutes() + ',' + date.getSeconds();
    return res;
  };
  //JS加密
  private encryp (clientId) {
    let CryptoJS = window.CryptoJS;
    let data = clientId + '--' + this.convertDate(new Date());
    let key = CryptoJS.enc.Utf8.parse('607490BE-18CA-43D7-B11A-57E2621B');
    let iv = CryptoJS.enc.Utf8.parse('2D59831C-78AC-4227-B3F3-CE656636');

    let encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  };


  //无参数的get请求
  getNoData(url) {
    return this.http.get(this.config.api + url)
      // .timeout(8000)
      .toPromise()
      .then((res:any)=> {
        try {　　　　
          return res;
        }catch(e){　　　　
          ////console.log(e);
        }
      })
      .catch(err => {
        if(err.name=='TimeoutError'){
          let str1=JSON.stringify({Errcode:"100",Errmsg:"网络太慢了，请更换网络后重试"});
          let str={status:100,_body:str1};
          return this.handleError(str);
        }else{
          return this.handleError(err)
        }
      }).catch();
  }
  //请求版本更新get请求
  versionGet(url){
    return this.http.get(url)
    //       //.timeout(8000)

      .toPromise()
      .then((res:any)=> {
        try {　　　　
          return res;
        }catch(e){　　　　
          ////console.log(e);
        }
      })
      .catch(err => {
        if(err.name=='TimeoutError'){
          let str1=JSON.stringify({Errcode:"100",Errmsg:"网络太慢了，请更换网络后重试"});
          let str={status:100,_body:str1};
          return this.handleError(str);
        }else{
          return this.handleError(err)
        }
      });
  }

  //get请求
  get(url: string, params ? : any) {

    let headers = new HttpHeaders();
    headers = headers.set('client_id', this.config.clientId);
    if(this.local.get(Constants.accesstoken)){
      headers = headers.set('access_token',this.local.get(Constants.accesstoken));
    }
    if(this.local.get(Constants.accountid) && url!="account_user/department"){
      headers = headers.set('accountid',this.local.get(Constants.accountid));
    }
    if(this.config.current_system)
    {
      headers = headers.set('current_system',this.config.current_system);
    }
    // let requestOpts = new RequestOptions({
    //   headers: headers
    // });

     if (params) {
       let paramsArray = [];
       Object.keys(params).forEach(key => paramsArray.push(key + '=' + encodeURIComponent(params[key])))
       if (url.search(/\?/) === -1) {
         url += '?' + paramsArray.join('&')
       } else {
         url += '&' + paramsArray.join('&')
       }
     }


    return this.http.get(this.config.api + url, { 'headers': headers })
      //.timeout(8000)
      .toPromise()
      .then((res:any)=> {

          try {
            return res;
          }catch(e){
            ////console.log(e);
          }

      })
      .catch(err => {
        // //console.log("测试get请求错误---------------------------api.ts");
        // //console.log(err);
        if(err&&err.status==0) {
          console.log('showHTTPErrModelNot',err)
          this.events.publish('showHTTPErrModelNot'); //发送退出登录选项
          return;
        }

        if(err.name=='TimeoutError'){
          // this.tip.presentToast("网络太慢了，请更换网络后重试");
          let str1=JSON.stringify({Errcode:"100",Errmsg:"网络太慢了，请更换网络后重试"});
          let str={status:100,_body:str1};
          return this.handleError(str);
        } else {
          return this.handleError(err)
        }
      });
  }

  //主部门管理员的get请求
  adminGet(url: string,timeout: 5000, params ? : any) {

    let headers = new HttpHeaders();
    headers = headers.set('client_id',this.config.clientId);
    if(this.local.get(Constants.accesstoken)){
      headers = headers.set('access_token',this.local.get(Constants.accesstoken));
    }
    if(this.local.get(Constants.accountid)){
      headers = headers.set('accountid',this.local.get(Constants.accountid));
    }
    if(this.config.current_system) {
      headers = headers.set('current_system',this.config.current_system);
    }

    // let requestOpts = new RequestOptions({
    //   headers: headers
    // });

    if (params) {
      let paramsArray = [];
      Object.keys(params).forEach(key => paramsArray.push(key + '=' + encodeURIComponent(params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }
    // this.events.publish('user:created',{name:null}); //发送退出登录选项
    return this.http.get(this.config.api + url, {'headers':headers})
      //.timeout(8000)
      .toPromise()
      .then((res:any)=> {
        try {　　　　
          return res;
        }catch(e){　　　　
          ////console.log(e);
        }
      })
      .catch(err => {
        if(err&&err.status==0)
        {
          console.log('showHTTPErrModelNot',err)
          this.events.publish('showHTTPErrModelNot'); //发送退出登录选项
          return;
        }
        if(err.name=='TimeoutError'){
          let str1=JSON.stringify({Errcode:"100",Errmsg:"网络太慢了，请更换网络后重试"});
          let str={status:100,_body:str1};
          return this.handleError(str);
        }else{
          return this.handleError(err)
        }
      });
  }

  //post请求
  post(url, params ? : any) {

    let headers = new HttpHeaders();
    headers = headers.set('client_id',this.config.clientId);
    if(this.local.get(Constants.accesstoken)){
      headers = headers.set('access_token',this.local.get(Constants.accesstoken));
    }
    if(this.local.get(Constants.accountid)){
      headers = headers.set('accountid',this.local.get(Constants.accountid));
    }
    if(this.config.current_system) {
      headers = headers.set('current_system',this.config.current_system);
    }
    // let requestOpts = new RequestOptions({
    //   headers: headers
    // });

    // //console.log(this.config.api + url);
    return this.http.post(this.config.api + url, params, {'headers':headers})
      //.timeout(8000)
      .toPromise()
      .then((res:any)=> {
        try {
          return res;
        }catch(e){
          ////console.log(e);
        }
      })
      .catch(err => {
        console.log('post',err);
        if(err&&err.status==0)
        {
          console.log('showHTTPErrModelNot',err)
          this.events.publish('showHTTPErrModelNot'); //发送退出登录选项
          return;
        }
        if(err.name=='TimeoutError'){
          let str1=JSON.stringify({Errcode:"100",Errmsg:"网络太慢了，请更换网络后重试"});
          let str={status:100,_body:str1};
          return this.handleError(str);
        }else{
          return this.handleError(err)
        }
      });
  }

  //post请求
  adminPost(url, params ? : any) {

    let headers = new HttpHeaders();
    // if(params instanceof Array){

    // }else{
    //   if(params instanceof Object){
    //     headers = headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    //     //console.log(params);
    //     let paramsArray = [];
    //     Object.keys(params).forEach((key) => {
    //       ////console.log(params[key] instanceof Array);
    //       if(params[key] instanceof Array){
    //         params[key].forEach((val)=>{
    //           if(val instanceof Array){

    //           }else if(val instanceof Object){

    //             Object.keys(val).forEach((keys) => {
    //               paramsArray.push(key+'['+params[key].indexOf(val)+']'+'['+keys+']'+'=' + encodeURIComponent(val[keys]));
    //             });

    //           }else{
    //             paramsArray.push(key+'['+params[key].indexOf(val)+']'+'=' + encodeURIComponent(val));
    //           }

    //         });
    //       }else if(params[key] instanceof Object){
    //         Object.keys(params[key]).forEach((keys) => {
    //           if(params[key][keys] instanceof Array){//判断对象中又包含子数组的情况
    //             params[key][keys].forEach((keys1) => {
    //               paramsArray.push(key+'['+keys+']'+'['+params[key][keys].indexOf(keys1)+']'+'=' + encodeURIComponent(keys1));
    //             })
    //           }else{
    //             paramsArray.push(key+'['+keys+']'+'=' + encodeURIComponent(params[key][keys]));
    //           }

    //         })
    //       }else{
    //         paramsArray.push(key + '=' + encodeURIComponent(params[key]));
    //       }

    //     })
    //     params = paramsArray.join("&");
    //   }
    // }

    headers = headers.set('client_id', this.config.clientId);
    if(this.local.get(Constants.accesstoken)){
      headers = headers.set('access_token', this.local.get(Constants.accesstoken));
    }
    if(this.local.get(Constants.accountid)){
      headers = headers.set('accountid', this.local.get(Constants.accountid));
    }

    // let requestOpts = new RequestOptions({
    //   headers: headers
    // });
    return this.http.post(this.config.api + url, params, { 'headers': headers})
      //.timeout(8000)
      .toPromise()
      .then((res:any)=> {
        try {　　　　
          return res;
        }catch(e){　　　　
          ////console.log(e);
        }
      })
      .catch(err => {
        if(err.name=='TimeoutError'){
          let str1=JSON.stringify({Errcode:"100",Errmsg:"网络太慢了，请更换网络后重试"});
          let str={status:100,_body:str1};
          return this.handleError(str);
        }else{
          return this.handleError(err)
        }
      });
  }

  //代理请求
  agentPost(url, params ? : any){

    let headers = new HttpHeaders();
    console.log('agentPost',params);
    // headers = headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
   
    // if(params instanceof Array){

    // }else{
    //   if(params instanceof Object){
        
    //     //console.log(params);
    //     let paramsArray = [];
    //     Object.keys(params).forEach((key) => {
    //       //console.log(params[key] instanceof Array);
    //       if(params[key] instanceof Array){
    //         params[key].forEach((val)=>{
    //           paramsArray.push(key+'['+params[key].indexOf(val)+']'+'=' + encodeURIComponent(val));
    //         });
    //       }else if(params[key] instanceof Object){
    //         Object.keys(params[key]).forEach((keys) => {
    //           paramsArray.push(key+'['+keys+']'+'=' + encodeURIComponent(params[key][keys]));
    //         })
    //       }else{
    //         paramsArray.push(key + '=' + encodeURIComponent(params[key]));
    //       }
    //     });
    //     params = paramsArray.join("&");
    //   }
    // }

    headers = headers.set('client_id', this.encryp(this.config.clientId));

    // let requestOpts = new RequestOptions({
    //   headers: headers
    // });
    return this.http.post(this.config.agentApi + url, params, { 'headers': headers})
      //.timeout(8000)
      .toPromise()
      .then((res:any)=> {
        try{
          return res;
        }catch(e){
          //console.log(e);
        }
      })
      .catch(err => {
        if(err.name=='TimeoutError'){
          let str1=JSON.stringify({Errcode:"100",Errmsg:"网络太慢了，请更换网络后重试"});
          let str={status:100,_body:str1};
          return this.handleError(str);
        }else{
          return this.handleError(err)
        }
      });

  }

  // 遇到账号不存在检测是自己的账号不存在还是别人的账号不存在
  accountOut(){
    let headers = new HttpHeaders();
    headers = headers.set('client_id', this.config.clientId);
    if(this.local.get(Constants.accesstoken)){
      headers = headers.set('access_token', this.local.get(Constants.accesstoken));
    } else {
      this.events.publish('user:created'); //发送退出登录选项
      return;
    }
    if(this.local.get(Constants.accountid)){
      headers = headers.set('accountid', this.local.get(Constants.accountid));
    }
    // let requestOpts = new RequestOptions({
    //   headers: headers
    // });
    this.http.get(this.config.api + "account_user/department", { 'headers': headers})
        .toPromise()
        .then((res:any)=> {})
        .catch(err => {
          try {
            let str =  err._body;
            let json = JSON.parse(str);
            if(json.Errcode =='1021' ||json.Errcode =='1006')
            {
              this.events.publish('user:created'); //发送退出登录选项
            }
          }catch (e) {
          }
        });
  }

  urmGetData(url,params ? : any){
    let headers = new HttpHeaders();
    let nowData = new Date();
    let longText = parseInt(nowData.getTime()/1000+'')+'';
    let str =  Md5.hashStr("URM_REQUEST"+longText)+'';

    headers = headers.set('timestamp', longText);
    headers = headers.set('signature', str.toUpperCase());
    if(this.config.current_system) {
      headers = headers.set('current_system', this.config.current_system);
    }
    console.log("MD5",Md5.hashStr('longText'))
    if(this.local.get(Constants.accountid)){
      headers = headers.set('accountid', this.local.get(Constants.accountid));
    }
    headers = headers.set('client_id', this.config.clientId);
    // let request = new RequestOptions({
    //   headers: headers
    // });
    return this.http.get(this.config.urmApi +url, { 'headers': headers })
        .toPromise()
        .then((res:any) => {
          if(res.status==200){
            let data = JSON.parse(res._body)
            console.log('urmPostData',data.data,)
            return data.data;

          }
        })
        .catch(async err => {
          console.log('urmPayStatistic',err)
        });
  }

}
