<!-- 页头部分 -->
<div class="contentRight-header"><img (click)="opera.goChangeRouter('college-milkdetails')" src='assets/images/router-old.png' />校园招聘 - 双选会 - 双选会报名</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <app-model-still-milk #child [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-still-milk>
    <app-model-position-more #child1 [isShow]="modelTwoShow" [initValue]="model.positionids" (onVoted)="ModelTwodismiss($event)"></app-model-position-more>
    <div class="college-block">
        <!-- 限制行业 -->
        <div class="hyTip" *ngIf="MilkDetails && MilkDetails.include_industrys_text">
            温馨提示：本场双选会限制行业（{{MilkDetails.include_industrys_text}}）
        </div>
        <!-- 页头部分 -->
        <div class="college-block-head">双选会报名</div>
        <div style="width: 1000px;margin: 0 auto;height: 120px;background-color: #F0F1FF;overflow: hidden;" *ngIf="config.departmentSelf">
            <div class="guwen_bg_gray">
                <img class="guwen_info_huizhang" src="../../assets/images/kefu_hui.png">

                <img class="guwen_head" src="{{config.departmentSelf.avatar}}">
                <div class="guwen_infoDiv">
                    <ul>
                        <li><img style="width: 120px;height: 20px" src="../../assets/images/guwen_title.png"></li>
                        <li><span class="guwen_info_name">{{config.departmentSelf.name}}</span></li>
                        <li>
                            <img style="width: 17px;height: 17px" src="../../assets/images/kefu_wx.png">
                            <span class="guwen_info_title">微信：</span>
                            <span class="guwen_info_wx">{{config.departmentSelf.wx}}</span>
                        </li>
                        <li style="width: 250px">
                            <img style="width: 17px;height: 17px" src="../../assets/images/kefu_email.png">
                            <span class="guwen_info_title">邮箱：</span>
                            <span class="guwen_info_wx">{{config.departmentSelf.email}}</span>
                        </li>
                    </ul>
                </div>
                <img class="guwen_head_code" style="margin-left: 5px;" src="{{config.departmentSelf.qr_code}}">
                <div class="guwen_info_Line"></div>
                <!--<span class="guwen_info_rightText">双选会/宣讲会申请相关问题， 请扫码咨询！</span>-->

                <div class="guwen_info_rightText">
                    <p>双选会/宣讲会申请相关问题,</p>
                    <p>请扫码咨询！</p>
                </div>
            </div>
        </div>


        <!--//顶部状态-->
        <div style="width: 100%;height: 140px;margin-top: 30px;">
            <div style="width: 33%;height: 120px;text-align: center;float: left">
                <div style="width: 100px;height: 60px;;margin-right: 15px;float: right">
                    <img style="width: 52px;height: 52px;" src="../../assets/images/au_ing.png" *ngIf="CollegeIdentityModel.status==0" />
                    <img style="width: 52px;height: 52px;" src="../../assets/images/au_shenqing.png" *ngIf="CollegeIdentityModel.status==1" />
                    <img style="width: 52px;height: 52px;" src="../../assets/images/au_success.png" *ngIf="CollegeIdentityModel.status==2" />
                    <img style="width: 52px;height: 52px;" src="../../assets/images/au_fail.png" *ngIf="CollegeIdentityModel.status==3" />
                    <img style="width: 52px;height: 52px;" src="../../assets/images/au_fail.png" *ngIf="CollegeIdentityModel.status==4" />
                    <img style="width: 52px;height: 52px;" src="../../assets/images/au_fail.png" *ngIf="CollegeIdentityModel.status==5" />


                    <div style="width: 100px;margin-top: 5px;color: #000000">资质审核</div>
                    <div class="statuBorder_success" style="width: 100px;margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="CollegeIdentityModel.status==2">已通过</div>
                    <div class="statuBorder_ing" style="width: 100px;margin-top: 10px;;font-size: 15px;font-weight: bold;" *ngIf="CollegeIdentityModel.status==1">审核中</div>
                    <div class="statuBorder_auing" style="width: 100px;margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="CollegeIdentityModel.status==0">申请中</div>
                    <div class="statuBorder_fail" style="width: 100px;margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="CollegeIdentityModel.status==3">审核失败</div>
                    <div class="statuBorder_fail" style="width: 100px;margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="CollegeIdentityModel.status==4">未合作</div>
                    <div class="statuBorder_fail" style="width: 100px;margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="CollegeIdentityModel.status==5">已拉黑</div>

                    <div style="width: 100px;margin-top: 5px;color: #fa5d5e" *ngIf="CollegeIdentityModel.status==3 && !CollegeIdentityModel.no_apply_30day">{{CollegeIdentityModel.remark}}</div>
                    <!--<div style="width: 100px;height: 20px;margin-top: 5px;color: #fa5d5e" *ngIf="CollegeIdentityModel.status==3 && CollegeIdentityModel.no_apply_30day" >请申请其他高校</div>-->
                </div>
            </div>

            <div style="height: 3px;width:  34%;background-color: #5e4fff;float: left;margin-top: 17px"></div>

            <div style="width:  33%;height: 120px;text-align: center;float: right">
                <div style="width: 100px;height: 60px;margin-left: 15px;float: left" *ngIf="MilkDetails">
                    <img style="width: 52px;height: 52px;" *ngIf="MilkDetails!=null && MilkDetails.status==null &&(CollegeIdentityModel.status==0||CollegeIdentityModel.status==3)" src="../../assets/images/milk_none.png" />
                    <img style="width: 52px;height: 52px;" *ngIf="MilkDetails!=null && MilkDetails.status==null && (CollegeIdentityModel.status==1||CollegeIdentityModel.status==2)" src="../../assets/images/milk_suc.png" />
                    <img style="width: 52px;height: 52px;" *ngIf="MilkDetails.status=='to_audit'" src="../../assets/images/milk_ing.png" />
                    <img style="width: 52px;height: 52px;" *ngIf="MilkDetails.status=='not_passed'" src="../../assets/images/milk_fail.png" />
                    <img style="width: 52px;height: 52px;" *ngIf="MilkDetails.status=='passed'" src="../../assets/images/milk_success.png" />

                    <div style="width: 100px;height: 20px;margin-top: 5px;color: #000000">报名双选会</div>

                    <div class="statuBorder_ing" style="width: 100px;margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="MilkDetails!=null &&MilkDetails.status=='to_audit'">等待审核</div>
                    <div class="statuBorder_none" style="width: 100px;margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="MilkDetails!=null && MilkDetails.status==null &&(CollegeIdentityModel.status==0||CollegeIdentityModel.status==3)">未申请</div>
                    <div class="statuBorder_auing" style="width: 100px;margin-top: 10px;font-size: 15px;font-weight: bold;" *ngIf="MilkDetails!=null && MilkDetails.status==null && (CollegeIdentityModel.status==1||CollegeIdentityModel.status==2)">申请中</div>
                    <div class="statuBorder_fail" style="width: 100px;margin-top: 10px;padding-top: 5px;padding-bottom: 5px;font-weight: bold;" *ngIf="MilkDetails!=null &&MilkDetails.status=='not_passed'">报名失败</div>
                    <div class="statuBorder_success" style="width: 100px;margin-top: 10px;padding-top: 5px;padding-bottom: 5px;font-weight: bold;" *ngIf="MilkDetails!=null &&MilkDetails.status=='passed'">报名成功</div>

                    <!-- <div (click)="opera.goChangeRouter('college-milkround')" style="width: 400px;margin-top: 5px;color:#fa5d5e;text-align: left" *ngIf="MilkDetails!=null &&MilkDetails.status=='not_passed'">拒绝原因：{{MilkDetails.reason}}</div> -->

                </div>
            </div>
        </div>
        <!--<div (click)="opera.goChangeRouter('college-milkround')" style="width: 100px;height: 20px;margin-top: 5px;color: white;background-color: #5e4fff;text-align: center;padding:8px 15px;margin-left: 46%;margin-bottom: 15px" *ngIf="(CollegeIdentityModel.status==3 && CollegeIdentityModel.no_apply_30day)||reason=='双选会展位分配已满，请报名其他双选会'" >申请其他高校</div>-->
        <div class="noPass" *ngIf="MilkDetails?.status=='not_passed'">拒绝原因：{{MilkDetails.reason}}</div>

        <div class="company-block" *ngIf="showTab1">
            <!-- 中间部分 -->
            <div class="info-block">

                <div *ngIf="collegeName&&orgList_shenhe.length==0" class="identityCollege-name">当前申请高校：{{collegeName}}</div>
                <div *ngIf="orgList_shenhe.length>0" class="identityCollege-name">当前申请院系：
                    <select name="CIOrg" id="CIOrg" [(ngModel)]="dmodel.facultyid" autocomplete="off">
                        <option class="place-text" value='' disabled selected="selected">请选择院系</option>
                        <option *ngFor="let a of orgList_shenhe" [value]="a.facultyid">{{a.name}}</option>
                    </select>
                </div>

                <!-- 营业执照 -->
                <div class="info-list info-logo">
                    <div class="info-head">第一步&nbsp;&nbsp;营业执照/企业资质</div>
                    <label *ngIf="dmodel.business_licence" class="uploadImg-label department-logo" for="uploadLoginAvatar">
                        <img [src]="dmodel.business_licence">
                    </label>
                    <label *ngIf="!dmodel.business_licence" class="uploadImg-label" for="uploadLoginAvatar">
                        <img src="assets/images/add-img.png"><br/>点击上传&nbsp;&nbsp;营业执照/企业资质
                    </label>
                    <input class="uploadImg" id="uploadLoginAvatar" (change)="previewPic($event,'business_licence')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
                    <!--<p style="position: absolute;margin-left: 70px;margin-top: 10px;color: red;height: 50px;padding-bottom: 20px">注：属于培训学校的企业，需要上传办学许可证</p>-->

                    <ul>
                        <li>1. 上传营业执照名称必须与公司全称保持一致</li>
                        <li>2. 请提供有效年检期内的《企业法人营业执照》副本</li>
                        <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                        <li>4. 高校就业老师将会在 1 -3个工作日内审核</li>
                        <li>5. 企业资质是指：有的公司没有营业执照，可以根据<br> &nbsp;&nbsp;&nbsp;&nbsp;不同行业上传不同的证件；如：基金会等公益组织，
                            <br> &nbsp;&nbsp;&nbsp;&nbsp;上传法人登记证书等。
                        </li>
                        <li>6. 属于培训学校的企业，需要上传办学许可证。</li>
                        <!--<li style="margin-top: 10px">-->
                        <!--&lt;!&ndash;<input name="attachment" value='Brows' placeholder="+上传办学许可证" style="width: 100px;height:40px;background-color: lightgray;padding: 5px 15px;margin-top: 10px" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">&ndash;&gt;-->

                        <!--<div style="width: 150px;height: 25px;background-color: lightgray">-->
                        <!--<label style="width: 150px;height: 25px;background-color: lightgray;margin-top: -25px;margin-left: 0px;">+&nbsp;上传办学许可证-->
                        <!--<input id="uploadLoginbanXue" (change)="previewPic($event,'school_permit')" style="width: 150px;height: 25px;background-color: lightgray" type="file" accept="image/jpg,image/png,image/jpeg,image/bmp" style="display:none;">-->
                        <!--</label>-->
                        <!--</div>-->

                        <!--</li>-->
                    </ul>
                </div>

                <div class="info-list info-logo" *ngIf="department?.industry=='0606'||department?.industry=='0604'||department?.industry=='0605'">
                    <label *ngIf="dmodel.school_permit" class="uploadImg-label department-logo" for="uploadLoginbanXue">
                        <img [src]="dmodel.school_permit">
                    </label>
                    <label *ngIf="!dmodel.school_permit" class="uploadImg-label" for="uploadLoginbanXue">
                        <img src="assets/images/add-img.png"><br/>点击上传&nbsp;&nbsp;上传办学许可证
                    </label>
                    <input class="uploadImg" id="uploadLoginbanXue" (change)="previewPic($event,'school_permit')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
                </div>


                <!-- 营业执照 -->
                <div class="info-list info-logo">
                    <div class="info-head">第二步&nbsp;&nbsp;招聘者身份证</div>
                    <label *ngIf="dmodel.id_card_front" class="uploadImg-label department-logo" for="uploadGuideCard">
                        <img [src]="dmodel.id_card_front">
                    </label>
                    <label *ngIf="!dmodel.id_card_front" class="uploadImg-label" for="uploadGuideCard">
                        <img src="assets/images/add-img.png"><br/>点击上传手持身份证
                    </label>
                    <input class="uploadImg" id="uploadGuideCard" (change)="previewPic($event,'id_card_front')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
                    <ul>
                        <li>1. 上传身份证姓名必须与招聘联系人姓名保持一致</li>
                        <li>2. 请提供有效期内的本人手持《身份证》照片</li>
                        <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                        <li>4. 高校就业老师将会在 1 -3个工作日内审核</li>
                    </ul>
                </div>
                <!-- 招聘委托书 -->
                <div *ngIf="secureMaterail" class="info-list info-logo">
                    <div class="info-head">第三步&nbsp;&nbsp;招聘委托书</div>
                    <label *ngIf="dmodel.proxy_document" class="uploadImg-label department-logo" for="uploadProxy">
                        <img [src]="dmodel.proxy_document">
                    </label>
                    <label *ngIf="!dmodel.proxy_document" class="uploadImg-label" for="uploadProxy">
                        <img src="assets/images/add-img.png"><br/>点击上传招聘委托书
                    </label>
                    <input class="uploadImg" id="uploadProxy" (change)="previewPic($event,'proxy_document')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
                    <ul>
                        <li>1. 点击下载：<a [href]="secureMaterail" target="_blank">《招聘委托书示例》</a></li>
                        <li>2. 修改后请加盖公章，截图或拍照上传</li>
                    </ul>
                </div>
                <!-- 统一社会信用代码 -->
                <div class="info-list info-number">
                    <div *ngIf="!secureMaterail" class="info-head">第三步&nbsp;&nbsp;统一社会信用代码</div>
                    <div *ngIf="secureMaterail" class="info-head">第四步&nbsp;&nbsp;统一社会信用代码</div>
                    <input type="text" placeholder="请填写营业执照统一社会信用代码" [(ngModel)]="dmodel.register_number" maxlength="18">
                    <ul>
                        <li>1. 填写的统一社会信用代码必须与营业执照保持一致</li>
                        <li>2. 高校就业老师将会在 1 -3个工作日内审核</li>
                    </ul>
                </div>
                <!-- 办公电话 -->
                <div class="info-lis" *ngIf="authcation_office_phone">
                    <div *ngIf="!secureMaterail" class="info-head">第四步&nbsp;&nbsp;办公电话</div>
                    <div *ngIf="secureMaterail" class="info-head">第五步&nbsp;&nbsp;办公电话</div>
                    <input type="text" [(ngModel)]="dmodel.office_phone" (blur)="checkPhone1(dmodel.office_phone)" placeholder="请填写固定电话，如0371-66668888" class="info-phone">
                    <!-- <b *ngIf="!model.office_phone"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b> -->
                </div>
                <!-- 申请简述 -->
                <div class="info-list">
                    <div *ngIf="!secureMaterail&&!authcation_office_phone" class="info-head">第四步&nbsp;&nbsp;申请简述</div>
                    <div *ngIf="secureMaterail&&!authcation_office_phone" class="info-head">第五步&nbsp;&nbsp;申请简述</div>
                    <div *ngIf="!secureMaterail&&authcation_office_phone" class="info-head">第五步&nbsp;&nbsp;申请简述</div>
                    <div *ngIf="secureMaterail&&authcation_office_phone" class="info-head">第六步&nbsp;&nbsp;申请简述</div>
                    <div class="list-brief">
                        <textarea placeholder="请简述申请校招的目的(最多100字)" scrollHeight [(ngModel)]="dmodel.brief" minlength="0" maxlength="100">{{model.brief}}</textarea>

                    </div>
                </div>
                <!-- 按钮 -->
                <div class="block-button">
                    <button (click)="uploadDepartmentIdentity()">提交</button>
                </div>
            </div>
        </div>



        <!-- 申请双选会部分 -->
        <div class="sign-block" *ngIf="showTab2">
            <!-- 双选会名称 -->
            <div class="sign-list">
                <span>双选会名称：</span><input type="text" [(ngModel)]="name" disabled="disabled">
            </div>
            <!-- 单位名称 -->
            <div class="sign-list" *ngIf="department">
                <span>单位名称：</span><input type="text" [(ngModel)]="department.name" disabled="disabled">
            </div>
            <!-- 审核院系 -->
            <div class="sign-list sign-skill" *ngIf="audit_mode">
                <span><b class="remind-red">*</b>审核院系：</span>
                <select name="milkFacultyid" id="milkFacultyid" [(ngModel)]="model.facultyid" autocomplete="off" (change)="facultyidChange(model.facultyid)">
                    <option class="place-text" value='' disabled selected="selected">请选择院系</option>
                    <option *ngFor="let a of orgList" [value]="a.facultyid">{{a.name}}</option>
                </select>
                <b *ngIf="isSend&&!model.facultyid"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 意向专业 -->
            <div class="sign-list sign-skill">
                <span><b class="remind-red">*</b>意向专业：</span>
                <div class="label-list clear" *ngIf="collegeMajorList.length==0">
                    <div class="label-item purple">
                        <div class="label-text">不限</div>
                    </div>
                    <div class="label-item label-click" (click)="goMajor()">
                        <div class="label-text">添加+</div>
                    </div>
                </div>
                <div class="label-list clear" *ngIf="collegeMajorList.length>0">
                    <div class="label-item purple" *ngFor="let m of collegeMajorList">
                        <div class="label-text">{{m.text}}</div>
                    </div>
                    <div class="label-item label-click" (click)="goMajor()">
                        <div class="label-text">添加+</div>
                    </div>
                </div>
            </div>
            <!-- 招聘职位 -->
            <div class="sign-list sign-skill">
                <span><b class="remind-red">*</b>招聘职位：</span>
                <div class="label-list clear" *ngIf="model.positionids.length==0">
                    <!-- <div class="label-item purple">
                    <div class="label-text">全部职位</div>
                </div> -->
                    <div class="label-item label-click" (click)="choosePosition()">
                        <div class="label-text">添加</div>
                    </div>
                </div>
                <div class="label-list clear" *ngIf="model.positionids.length>0">
                    <div class="label-item purple" *ngFor="let m of positionList">
                        <div class="label-text">{{m.name}}</div>
                    </div>
                    <div class="label-item label-click" (click)="choosePosition()">
                        <div class="label-text">更改</div>
                    </div>
                </div>
            </div>
            <!-- 联系人 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>联系人：</span><input type="text" [(ngModel)]="model.contacts" maxlength="8" placeholder="请填写联系人" (blur)="checkContact()">
                <b *ngIf="isSend&&!model.contacts"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 联系电话 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>联系电话：</span><input type="number" [(ngModel)]="model.phone" (blur)="checkPhone(model.phone)" placeholder="请填写联系电话">
                <b *ngIf="isSend&&!model.phone"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>

            <div class="sign-list">
                <span><b class="remind-red">*</b>微信号：</span><input type="text" [(ngModel)]="model.wechat" placeholder="请填写微信号">
                <b>用于学生投递简历后添加您进行线上面试</b>
                <b *ngIf="isSend&&!model.wechat"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!--参会人数 -->
            <div class="sign-list" style="display: none;">
                <span><b class="remind-red">*</b>参会人数：</span><input type="number" [(ngModel)]="model.attendance" oninput="if(value.length>1)value=value.slice(0,1)" placeholder="请填写参会人数(限5人)">
                <b *ngIf="isSend&&!model.attendance"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!--参会成员 -->
            <div class="sign-list sign-editor" *ngIf="MilkDetails && MilkDetails.milkround_type == 1">
                <span>参会成员：</span>
                <div class="path-block">
                    <div class="path-list" *ngFor="let p of model.attendees;let i =index;">
                        <span>成员{{i+1}}：</span>
                        <input class="double-input" type="text" placeholder="请填写成员姓名(8字以内)" maxlength="8" [(ngModel)]="p.name">
                        <input class="double-input" type="text" placeholder="请填写联系电话" [(ngModel)]="p.phone" (blur)="checkPhone(p.phone)">
                        <!--删除成员-->
                        <img (click)="delPosition(i)" class="close_default" src="assets/images/close_default.png">
                    </div>
                    <div class="path-list textCenter"><img (click)="addPosition()" src="assets/images/add-img.png"></div>
                </div>
            </div>
            <!--校友 -->
            <div class="sign-list" *ngIf="audit_switch.isShowFriend">
                <span style="font-size: 14px;"><b class="remind-red">*</b>来校招聘人员是否为我校校友：</span>
                <div class="path-block" style="padding-top: 8px;">
                    <div class="flex_l">
                        <div class="isFriendCom" [ngClass]="{'isFriendColor':model.is_alumn,'isNoneFriendColor':!model.is_alumn}" (click)="checkFriendClick(true)">是</div>
                        <div class="isFriendCom" [ngClass]="{' isNoneFriendColor':model.is_alumn,'isFriendColor':!model.is_alumn}" (click)="checkFriendClick(false)">否</div>
                    </div>
                </div>
            </div>

            <div style="margin:30px auto;" *ngIf="model.is_alumn && audit_switch.isShowFriend">
                <div style="height: 50px;margin-left: 30px" class="flex_l friendList" *ngFor="let friend of model.friendList;let i =index;">
                    <div class="item">
                        校友名称:
                        <input type="text" placeholder="请填写校友名称" [(ngModel)]="friend.name">
                    </div>
                    <div class="item">
                        校友专业:
                        <input type="text" placeholder="请填写校友专业" [(ngModel)]="friend.major">
                    </div>
                    <div class="item">
                        毕业时间:
                        <input type="date" style="width: 140px !important;" [(ngModel)]="friend.graduation_time">
                    </div>
                    <div class="item">
                        公司职位:
                        <input type="text" style="width: 80px!important;" placeholder="在公司职位" [(ngModel)]="friend.position">
                    </div>
                    <div class="deleteFriend" (click)="deleteFriend(i)">删除</div>
                </div>

                <div class="path-add flex_c" (click)="addFriend()"><img src="../../assets/images/add-img.png">增加</div>
            </div>

            <!-- 面试时间 -->
            <div class="sign-list sign-editor" *ngIf="interview_time_setting?.length > 0">
                <span class="timeSpan">面试时间申请：</span>
                <div class="path-block timeBox">
                    <span *ngFor="let t of interview_time_setting;let i =index;" [class.cur]="model.interview_time == t" (click)="setTime(t)">{{t}}</span>
                </div>
            </div>
            <!--备注 -->
            <div class="sign-list sign-intro">
                <span>备注：</span>
                <textarea [(ngModel)]="model.remark" minlength="0" maxlength="500" placeholder="如需要高校给予现场支持，请在此填写。（如提供面试场地）"></textarea>
            </div>
            <!--附件 -->
            <!--<div class="sign-list sign-skill enclosure" >-->
            <!--<span style="line-height:200px;"><b class="remind-red" *ngIf="audit_switch.department_receipt">*</b>附件：{{audit_switch.department_receipt?"":"(选填)&nbsp;"}}</span>-->
            <!--<div class="company-imglist clear">-->
            <!--&lt;!&ndash;<div class="left img-list" *ngFor="let img of model.attachment;let num=index">&ndash;&gt;-->
            <!--&lt;!&ndash;<img [src]="img+'?imageView2/1/w/240/h/180'">&ndash;&gt;-->
            <!--&lt;!&ndash;<div (click)="deleteImg(img)" class="delete-img"><img src="assets/images/delect.png"></div>&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--<div class="left img-list" *ngIf="model.attachment.length < 3">-->
            <!--<label class="uploadImg-label" for="uploadMilkImg">-->
            <!--<img src="assets/images/add-img.png"><br/>点击添加附件-->
            <!--</label>-->
            <!--<input class="uploadImg" id="uploadMilkImg" (change)="previewMilkPic($event)" type="file" accept="multiple">-->
            <!--</div>-->
            <!--<a *ngIf="audit_switch.department_receipt" class="left downloadBlock" href="{{audit_switch.department_receipt_url}}" download="单位招聘委托书.docx">下载参会回执</a>-->
            <!--</div>-->
            <!--<br/><s>*请遵照高校要求上传参会回执、委托书或招聘公函等附件，最多3个附件，大小：小于5M。</s>-->

            <!--<div class="flex_l fileRow" *ngFor="let img of model?.attachment;let num=index">-->
            <!--<div class="flex_l "> 附件{{num+1}}: &nbsp;  {{formatFileName(img)}} <span class="fileRow-delete" (click)="deleteFlie(img)">删除</span></div>-->
            <!--</div>-->
            <!--</div>-->

            <div class="sign-list sign-skill enclosure" *ngIf="MilkDetails?.department_receipt_switch && department_receipt_List.length>0">
                <span><b class="remind-red" >*</b>附件：</span>
                <div *ngFor="let f of department_receipt_List;let i =index;" class="fileBox">
                    <div class="filetitle">附件{{i+1}}: {{f.name}}</div>
                    <div class="flex_l">
                        <label [for]="'uploadMilkImg'+i" class="uploadLabel">
                          点击上传
                        </label>
                        <a class="download_file" [href]="f.department_receipt_url" *ngIf="f.department_receipt_url">下载模版</a>
                        <input class="uploadImg" [id]="'uploadMilkImg'+i" (change)="previewMilkPic($event,i)" type="file" accept="multiple">
                    </div>
                    <div *ngIf="f.links.length>0">
                        <div class="downloaded" *ngFor="let linkModel of f.links;let a =index;"> 已上传： {{formatFileName(linkModel)}} <img (click)="deleteMilkPicClick(i,a)" src="../../assets/images/close_default.png" class="delete" /></div>
                    </div>
                </div>
                <s style="font-size: 11px;color: #CCCCCC">* 请下载附件模板，按高校要求填写、盖章并上传附件，大小不超过5M</s>
            </div>

            <!-- 提交 -->
            <div class="sign-submit">
                <button class="purple" (click)="getSignModel()">提交申请</button>
            </div>
        </div>
    </div>
</div>