<!-- 引导页面-->
<div class="cancelRegister">
    <span class="cancelRegister_span flex_r" (click)="cancelRegisterChips()"> <img src="../../assets/images/login_close.png">退出</span>

</div>

<div class="guide-page">
    <router-outlet></router-outlet>
    <app-model-select-two #guideIndustry [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-select-two>
    <app-model-cropper #crop [isShow]="modelTwoShow" (onVoted)="ModelTwodismiss($event)"></app-model-cropper>
    <!--<app-model-address-add #address [isShow]="modelThreeShow" (onVoted)="AddressModeldismiss($event)"></app-model-address-add>-->

    <app-model-company-strength #strength [isShow]="modelFiveShow" [isLogin]='false' (onVoted)="ModelFivedismiss($event)"></app-model-company-strength>


    <!-- 头部 -->
    <div class="guide-head" [class.guide-step]="!guideStep">
        <ul>
            <li>
                <span class="isHeadActive">1</span>
                <br/>公司信息
            </li>
            <li>
                <!-- <span><img src="assets/images/guide-ok.png"></span> -->
                <span>2</span>
                <br/>招聘人信息
            </li>
            <li>
                <!-- <span><img src="assets/images/guide-ok.png"></span> -->
                <span>3</span>
                <br/>申请高校合作
            </li>
            <!--<li *ngIf="guideStep">-->
            <!--<span>4</span>-->
            <!--<br/>等待审核-->
            <!--</li>-->
        </ul>
    </div>
    <!--引导完善公司信息-->
    <div class="info-block">
        <div class="info-head">公司信息</div>
        <div class="info-txt">

            <div class="info-list info-logo">
                <span>公司LOGO：</span>
                <label *ngIf="userAvatar" class="uploadImg-label department-logo" for="uploadLoginAvatar">
         <img [src]="userAvatar">
       </label>
                <label *ngIf="!userAvatar" class="uploadImg-label" for="uploadLoginAvatar">
         <img src="assets/images/add-img.png"><br/>点击上传LOGO
       </label>
                <input class="uploadImg" id="uploadLoginAvatar" (change)="previewPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
                <br/><s>像素：200*200px,格式：jpg,png</s>
            </div>
            <nav></nav>

            <!-- 单位全称 -->
            <div class="info-list">
                <span><b class="remind-red">*</b>公司全称：</span><input type="text" [(ngModel)]="model.name" maxlength="40" placeholder="请填写公司全称" (blur)="checkName()">
                <b *ngIf="isSend&&!model.name"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
                <br/><s>*一经填写不可修改，请与营业执照保持一致</s>
            </div>
            <!-- 单位简称 -->
            <div class="info-list">
                <span><b class="remind-red">*</b>公司简称：</span><input type="text" [(ngModel)]="model.common_name" maxlength="10" placeholder="请填写公司对外的简称" (blur)="checkCommonName()">
                <b *ngIf="isSend&&!model.common_name"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
                <br/><s>*一经填写不可修改，请谨慎填写</s>
            </div>

            <!-- 所属行业 -->
            <div class="info-list" (click)="chooseIndustry()">
                <span><b class="remind-red">*</b>所属行业：</span>
                <div *ngIf="!model.industry" class="placholder-text">请选择选择所属行业</div>
                <div *ngIf="model.industry" class="selected-text">{{ model.industry | codetransform:config.industry}}</div>
                <b *ngIf="isSend&&!model.industry"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 单位性质 -->
            <div class="info-list info-type">
                <span><b class="remind-red">*</b>公司性质：</span>
                <select name="employer_type" id="employer_type" [(ngModel)]="model.employer_type" autocomplete="off">
            <option class="place-text" value='' disabled hidden selected="selected">请选择公司性质</option>
            <option *ngFor="let a of config.employer_type" [value]="a.code">{{a.text}}</option>
        </select>
                <b *ngIf="isSend&&!model.employer_type"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>
            <!-- 公司类别 -->
            <div class="info-list info-type" *ngIf="!isMainlandCollege">
                <span><b class="remind-red">*</b>企业注册地：</span>

                <select name="employer_type" style="width: 200px" id="employer_category" [(ngModel)]="global_regionValue1" (change)="employer_categoryChange($event)" autocomplete="off">
             <option class="place-text" value='' disabled hidden selected="selected">请选择注册地</option>
             <option *ngFor="let a of global_regionArr1" [value]="a.code">{{a.text}}</option>
         </select>
                <select name="employer_type" style="width: 200px;margin-left: 20px" [(ngModel)]="model.employer_category" (disabled)="global_regionValue1" autocomplete="off">
             <option class="place-text" value='' disabled hidden selected="selected">请选择详细注册地</option>
             <option *ngFor="let a of global_regionArr2" [value]="a.code">{{a.text}}</option>
         </select>
                <!--<button [ngClass]="{employer_category_current:model.employer_category=='mainland_employer',employer_category:model.employer_category!='mainland_employer'}" (click)="employer_categoryClick('mainland_employer')">大陆</button>-->
                <!--<button [ngClass]="{employer_category_current:model.employer_category=='field_employer',employer_category:model.employer_category!='field_employer'}"  (click)="employer_categoryClick('field_employer')">港澳台</button>-->
                <b *ngIf="isSend&&!model.employer_category"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>


            <!-- 注册资金 -->
            <div class="info-list">
                <span><b class="remind-red">*</b>注册资金：</span><input style="width: 190px;height: 40px; padding-left: 24px;border: 1px solid #e8e8e8;font-size: 16px;" type="number" (ngModelChange)="register_capitalChange($event)" [(ngModel)]="model.register_capital"
                    maxlength="40" autofocus="autofocus" onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')" placeholder="请填写公司注册资金">&nbsp;

                <select style="width: 85px;height: 44px;border-radius: 0;margin-right:5px;" [(ngModel)]="register_capital_type" autocomplete="off" class="zijinType">
             <option value="万元">万元</option>
             <option value="亿元">亿元</option>
         </select>
                <select style="width: 105px;height: 44px;border-radius: 0" [(ngModel)]="model.currency" autocomplete="off" class="zijinType">
          <option *ngFor="let a of currency" [value]="a.code">{{a.text}}</option>
         </select>
                <b *ngIf="isSend&&!model.register_capital"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>

            <!-- 公司地址 -->
            <div class="info-list">
                <span><b class="remind-red">*</b>公司地址：</span>
                <select style="width: 326px;height: 40px" name="employer_type" *ngIf="model.addressArr.length>0" [(ngModel)]="model.linshiDiZhi" autocomplete="off">
         <!--<option class="place-text" value='' disabled hidden selected="selected">请添加公司地址</option>-->
         <option *ngFor="let a of model.addressArr" [value]="a.code">{{a.text}}</option>
       </select>
                <button style="margin-left: 5px" (click)="goAddAddress()">添加地址</button>
            </div>

            <!-- 成立年份 -->
            <div class="info-list">
                <span><b class="remind-red">*</b>成立年份：</span>
                <input type="number" style="border: 1px solid #e8e8e8;font-size: 16px;color: #666;width:240px;height: 40px;padding-left: 24px;padding-right: 60px" [(ngModel)]="model.establish_year" placeholder="请输入成立年份">
                <!--&nbsp;<img class="calendar-img" src="assets/images/calendar.png" alt="打开日历"/>-->
                <span class="calendar-img" style="width: 30px;margin-left: -50px">年</span>

                <b *ngIf="isSend&&!model.establish_year"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>

            <!-- 员工人数 -->
            <div class="info-list">
                <span><b class="remind-red">*</b>员工人数：</span>
                <select style="width: 326px;height: 40px" name="employer_type" [(ngModel)]="model.scale" autocomplete="off">
                    <option class="place-text" value=''>选择规模</option>
                    <option *ngFor="let a of model.ScaleArr" [value]="a.code">{{a.text}}</option>
                </select>
            </div>

            <!-- 单位简称 -->
            <!--<div class="info-list">-->
            <!--<span><b class="remind-red">*</b>公司简介：</span>-->
            <!--<textarea [(ngModel)]="jianJie" minlength="50" maxlength="500" style="width: 320px;height: 120px" placeholder="请填写公司简介（50-500字）"></textarea>-->
            <!--<b *ngIf="isSend&&!model.common_name"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>-->
            <!--</div>-->
            <div class="info-list height140">
                <span><b class="remind-red">*</b>公司简介：</span>
                <textarea [(ngModel)]="model.intro" minlength="50" maxlength="500" style="width: 320px;height: 120px;border: 1px solid #e8e8e8;padding: 5px" placeholder="请填写公司简介（50-500字）"></textarea>
                <span style="width:60px;">{{model.intro.length}}/500</span>
                <b *ngIf="isSend&&!model.intro"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>
            </div>

            <!-- 是否上市 -->
            <div class="info-list">
                <span><b class="remind-red">*</b>是否上市：</span>
                <button *ngIf="model.is_quoted_company" style="width: 100px;height: 40px;margin-left: 15px; color: white;background-color: #5d479a" (click)="model.is_quoted_company=true">上市公司</button>
                <button *ngIf="model.is_quoted_company" style="width: 150px;height: 40px;margin-left: 15px" (click)="model.is_quoted_company=false">非上市公司</button>
                <button *ngIf="!model.is_quoted_company" style="width: 100px;height: 40px;margin-left: 15px;" (click)="model.is_quoted_company=true">上市公司</button>
                <button *ngIf="!model.is_quoted_company" style="width: 150px;height: 40px;margin-left: 15px;color: white;background-color: #5d479a" (click)="model.is_quoted_company=false">非上市公司</button>
            </div>

            <div class="info-list">
                <span><b class="remind-red"></b>公司实力：</span>

                <button style="width: 100px;height: 40px;margin-left: 15px; color: white;background-color: #5d479a" (click)="addShiLi()">+添加</button>
            </div>
            <div class="info-list">
                <div class="label-item purple" *ngFor="let label of model.strengths">
                    <div class="label-text">{{label.text}}</div>
                </div>
            </div>
            <!--<div class="info-list sign-skill">-->
            <!--<span>是否上市：</span>-->
            <!--<div class="label-list clear">-->
            <!--<div class="label-item label-click" [class.purple]="is_quoted_company" (click)="is_quoted_company=true">-->
            <!--<div class="label-text">上市公司</div>-->
            <!--</div>-->
            <!--<div class="label-item label-click" [class.purple]="!is_quoted_company" (click)="is_quoted_company=false">-->
            <!--<div class="label-text">非上市公司</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->


        </div>
    </div>
    <!--<div class="info-block">-->
    <!--<div class="info-head">招聘负责人信息</div>-->
    <!--<div class="info-txt">-->
    <!--&lt;!&ndash; 头像 &ndash;&gt;-->
    <!--<div class="info-list info-logo">-->
    <!--<span>头像：</span>-->
    <!--<label *ngIf="userAvatar" class="uploadImg-label department-logo" for="uploadLoginAvatar">-->
    <!--<img [src]="userAvatar">-->
    <!--</label>-->
    <!--<label *ngIf="!userAvatar" class="uploadImg-label" for="uploadLoginAvatar">-->
    <!--<img src="assets/images/add-img.png"><br/>点击上传头像-->
    <!--</label>-->
    <!--<input class="uploadImg" id="uploadLoginAvatar" (change)="previewPic($event)" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">-->
    <!--<br/><s>像素：200*200px,格式：jpg,png</s>-->
    <!--</div>-->
    <!--&lt;!&ndash; 性别 &ndash;&gt;-->
    <!--<div class="info-list select-list">-->
    <!--<span>性别：</span>-->
    <!--&lt;!&ndash;<select autocomplete="off" [(ngModel)]="model.gender">&ndash;&gt;-->
    <!--&lt;!&ndash;<option class="place-text" value='' disabled hidden selected="selected">选择性别</option>&ndash;&gt;-->
    <!--&lt;!&ndash;<option value="01">男</option>&ndash;&gt;-->
    <!--&lt;!&ndash;<option value="02">女</option>&ndash;&gt;-->
    <!--&lt;!&ndash;</select>&ndash;&gt;-->
    <!--&lt;!&ndash;<input name="男" type="radio" value="01" /><span>男</span>&ndash;&gt;-->

    <!--&lt;!&ndash;<input name="女" type="radio" value="02" /><span>女</span>&ndash;&gt;-->
    <!--<label><input name="Fruit" type="radio" value="01" style="margin-left: 5px" [(ngModel)]="model.gender" />&nbsp;男 </label>-->
    <!--<label style="margin-left: 30px"><input name="Fruit" type="radio" value="02" style="margin-left: 5px"  [(ngModel)]="model.gender"/>&nbsp;女 </label>-->


    <!--</div>-->
    <!--&lt;!&ndash; 姓名 &ndash;&gt;-->
    <!--<div class="info-list">-->
    <!--<span><b class="remind-red">*</b>姓名：</span><input type="text" [(ngModel)]="model.linkman" placeholder="请填写姓名" maxlength="8" (blur)="checkLinkman()">-->
    <!--<b *ngIf="isSend&&!model.linkman"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>-->
    <!--</div>-->
    <!--&lt;!&ndash; 职务 &ndash;&gt;-->
    <!--<div class="info-list">-->
    <!--<span><b class="remind-red">*</b>职务：</span><input type="text" [(ngModel)]="model.post" placeholder="请填写职务" (blur)="checkPost()">-->
    <!--<b *ngIf="isSend&&!model.post"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>-->
    <!--</div>-->
    <!--&lt;!&ndash; 工作邮箱 &ndash;&gt;-->
    <!--<div class="info-list">-->
    <!--<span><b class="remind-red">*</b>工作邮箱：</span><input type="text" [(ngModel)]="model.email" placeholder="请填写工作邮箱" (blur)="checkEmail()">-->
    <!--<b *ngIf="isSend&&!model.email"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--按钮-->
    <div class="block-button">
        <button (click)="setOneStep()">下一步</button>

    </div>
    <!-- 尾部 -->
    <div class="guide-foot">
        <div class="flex_l logoutAccout" (click)="logoutAccountClick()">注销账号</div>
        &nbsp;&nbsp;&nbsp;&nbsp;{{config.websiteRecord}}
    </div>

    <p-dialog [(visible)]="addAddressModel" [style]="{'width':600,'height':300,'minHeight':300}" [modal]=true>
        <p-header>添加公司地址 <img (click)="cloceDownLoad()" style="width: 15px;height: 15px;float: right;margin-top: 5px" src="../../assets/images/closeX.png" /></p-header>
        <select style="width: 150px;margin-left: 15px" autocomplete="off" [(ngModel)]="address1" (ngModelChange)="cityTwoChange()">
      <option class="place-text" value='' >选择省</option>
      <option *ngFor="let address of addressList1" [value]="address.code">{{address.text}}</option>
    </select>
        <select style="width: 150px;margin-left: 15px" autocomplete="off" [(ngModel)]="address2" (ngModelChange)="cityThreeChange()">
      <option class="place-text" value='' >选择市</option>
      <option *ngFor="let address of listTwo" [value]="address.code">{{address.text}}</option>
    </select>
        <select style="width: 150px;margin-left: 15px" autocomplete="off" [(ngModel)]="address3">
      <option class="place-text" value='' >选择区</option>
      <option *ngFor="let address of listThree" [value]="address.code">{{address.text}}</option>
    </select>

        <input style="width: 300px;;margin-left: 15px;margin-top:15px;height: 40px" type="text" [(ngModel)]="detailAddress" maxlength="40" placeholder="请填写公司地址">
        <br/>
        <button (click)="okAddressClick()" style="height: 30px;margin-top: 50px;margin-left: 265px;float: left;padding: 0px 15px;background-color:#5d479a;color: white ">确定</button>
    </p-dialog>
</div>