import { Component, OnInit ,Input, Output,ViewChild,ElementRef } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute,Router } from  '@angular/router';
import { ModelStillMilkComponent } from '../model-still-milk/model-still-milk.component';
import { ModelPositionMoreComponent } from '../model-position-more/model-position-more.component';
import { NgxNeditorComponent } from '@notadd/ngx-neditor';
declare let laydate;
// declare let UE;
declare var window;

@Component({
  selector: 'app-college-campus-sign',
  templateUrl: './college-campus-sign.component.html',
  styleUrls: ['./college-campus-sign.component.css']
})
export class CollegeCampusSignComponent implements OnInit {
  public config: Config;
  @ViewChild('neditor', { static: true }) editorElem: ElementRef;
  @ViewChild('child', { static: true }) child: ModelStillMilkComponent;
  @ViewChild('child1', { static: true }) child1: ModelPositionMoreComponent;
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelTwoShow = false;//是否打开弹窗
  public item:any;
  public isShowText=false;//是否完全展示
  public myDepartment:any;//企业信息
  public model={
    campustalk_type: 'college',//宣讲会类型 college 校级 faculty 院级,
    contacts:'',//联系人
    cover:'',//背景图
    name:'',//宣讲主题
    intro:'',//宣讲说明
    attendance:'',//参会人数
    phone:'',
    start_time:"",
    end_time:"",
    positionids:[],//职位id
    facultyid:'',//院系id
    attachment:[],//附件
    campusid:'',//校区id
    major_list:[],//院系id&专业
    scale:'200',//人数规模
    major:[],
    is_invite:false,
    attachment_list:[], //附件
    authid:null //资质审核申请记录
  }
  public dmodel={
    collegeid:'',
    facultyid:'',//院系id
    proxy_document:'',//招聘委托书
    business_licence:'',//企业营业执照
    id_card_front:'',//身份证正面
    register_number:'',//营业执照注册号
    brief:"",//申请简述
    office_phone:'',//固定电话
    school_permit:"",
  };
  public  CollegeIdentityModel = {
    status:10086,
    no_apply_30day:false,
    remark:''
  };
  public secureMaterail:any;//招聘委托书下载链接
  public collegeName="";
  public orgList_shenhe=[];//院系列表
  public authcation_office_phone=false;

  public completeDate = "";//日期
  public isSend = false;//是否点击了发送按钮
  public college:any;//高校信息
  public applyDetails:any;//申请数据
  public orgList=[];//院系列表
  public positionList=[];//选中职位列表
  public majorList=[];//专业列表（院级）
  public collegeMajorList=[];//专业列表（校级）
  public toolConfig: any;//编辑器配置
  public campusList=[];//校区列表
  public audit_switch = { // 高校开关列表
    department_receipt:''
  };

  public showTab1 = false;
  public showTab2 = false;

  public isShowBackSave= false;//返回的时候是否显示返回保存弹窗
  public isEdit= false;//是否可编辑，用于提交后2小时内可以修改

  //提交资质审核后，不可再次提交
  public IdentityLoading = false;

  // 资质审核记录ID
  public authenticationApplyID = ""

  //是否显示高校的宣讲会提示，10秒后消失
  public isShowCampChipsWithCollege = false;
  public isHaveCampChipsWithCollege = false;
  public isHaveCampChipsWithContent = "";

  public ChipsWithCollegeIndex = 11
  public ChipsWithCollegeTimer;
  public ChipsWithCollegeBtn = ''

  public campustalkApplyAllowd = true; //获取是否允许申请双选会,高校开关


  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public opera:Opera,
    // public ue:NgxNeditorComponent,
    public formatDate: FormatDate,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
      //----通过高校id获取高校详情
      this.getCollegeById(this.local.get(Constants.collegeid));//获取高校详情
      this.completeDate = this.formatDate.formatDate(this.formatDate.addDays(new Date(),1));
      //----获取宣讲会申请数据
      if(this.local.get(Constants.campusApply)){
        this.setApplyDetails();
      }else{
        //初始化时间
        //console.log(this.completeDate);
        this.model.start_time = this.completeDate+" 14:00:00";//默认填充为下午两点
        this.model.end_time = this.completeDate+" 16:00:00";//默认填充为下午四点
      }
      this.toolConfig= {//编辑器配置
        elementPathEnabled: false, //删除元素路径
        initialFrameHeight: 500
      };
    }

  ngOnInit() {
    this.opera.initConfig('college','college-campustalk');

    let collegeid=this.local.get(Constants.collegeid);

    let that = this;
    laydate.render({
      elem:'#start-time',
      type: 'datetime',
      done: function(value, date){
       that.model.start_time = value;
      }
     })
    laydate.render({
      elem:'#end-time',
      type: 'datetime',
      done: function(value, date){
        that.model.end_time = value;
      }
    })
    this.getDepartment();//获取企业信息
    this.getMajorList();//获取专业列表信息
    this.getCollageDetails();//获取高校信息
    setTimeout(()=>{
      that.getCampApplyTips();
    },1000)
    //获取hr信息
    if(this.dataApi.getLocalLoginUser()){
      //console.log("获取公司HR联系电话");
      if(this.dataApi.getLocalLoginUser().mobile){
        this.model.phone = this.dataApi.getLocalLoginUser().mobile;//联系电话
      }
    }
    this.getPositionList();//获取职位数据this.getPositionList();//获取职位数据

    if(collegeid) {
      this.dmodel.collegeid = collegeid;
      this.singleIdentity(collegeid);
      this.getCampustalkApplyAllowd()
    }
  }

  //获取校区列表
  getCollegeCampusList(){
    if(!this.local.get(Constants.collegeid))
    {
      return;
    }
    this.dataApi.getCollegeCampusList(this.local.get(Constants.collegeid)).then((res:any)=>{
      console.log("获取校区列表");
      console.log(res);
      if(res)
      {
        if(res&&res.length>1){
          this.campusList=res;
        }
      }

    }).catch((err)=>{
      this.toastr.error(err);
    })
  }

  // 获取高校信息
  getCollageDetails(){
    this.opera.loading();
    if(!this.local.get(Constants.collegeid))
    {
      return;
    }
    this.dataApi.getCollageDetails(this.local.get(Constants.collegeid)).then((res:any)=>{
      console.log("获取到高校信息",res);
      this.opera.loadiss();
      if(res)
      {
        this.secureMaterail = res.audit_switch.proxy_document_url;

        this.audit_switch = res.audit_switch
        console.log("获取到高校信息2",this.audit_switch);

        if(res.audit_switch.faculty_authcation){
          this.getOrgList_shenhe();
        }else{
          this.collegeName = res.name;
        }
      }

    }).catch((error)=>{
      // console.log(error);
      this.opera.goChangeRouter('homepage');
      this.toastr.error(error);
      this.opera.loadiss();
    })
  }

  //初始化赋值申请数据
  setApplyDetails(){

    this.applyDetails=JSON.parse(this.local.get(Constants.campusApply));


    console.log("修改宣讲会申请----",this.applyDetails);
    // console.log(this.applyDetails);
    this.model.contacts=this.applyDetails.contacts;
    this.model.name=this.applyDetails.name;
    this.model.intro=this.applyDetails.intro;
    this.model.attendance=this.applyDetails.attendance;
    this.model.phone=this.applyDetails.phone;
    this.model.scale=this.applyDetails.scale;
    this.model.cover=this.applyDetails.cover;
    this.model.attachment=this.applyDetails.attachment==null?[]:this.applyDetails.attachment;
    this.model.start_time = this.applyDetails.start_time.replace('T', " ").split("+")[0];
    this.model.end_time = this.applyDetails.end_time.replace('T', " ").split("+")[0];
    this.model.campusid = this.applyDetails.campusid;
    if(this.applyDetails.campustalk_type){
      this.model.campustalk_type=this.applyDetails.campustalk_type;
      this.majorValue();//专业赋值
    }else{
      this.model.campustalk_type='college';
    }
    if(this.applyDetails.facultyid){
      this.model.facultyid=this.applyDetails.facultyid;
    }
    if(this.applyDetails.positionids){
      this.model.positionids=this.applyDetails.positionids;
    }
    if(this.applyDetails.major){
      this.model.major=this.applyDetails.major;
    }
    this.model.is_invite=this.applyDetails.is_invite;
  }


  //赋值专业列表
  majorValue(){
    let ceshiMajor=[];
    if(this.applyDetails.major_list){
      this.model.major_list=this.applyDetails.major_list;
      for(let n of this.model.major_list){
        if(n.majors){
         for(let m of n.majors){
          ceshiMajor.push(m);
         }
        }
      }
    }
    if(this.model.campustalk_type=='college'){
      this.collegeMajorList=ceshiMajor;
    }else{
      this.opera.loading();
      if(!this.local.get(Constants.collegeid))
      {
        return;
      }
      this.dataApi.getCollegePart(this.local.get(Constants.collegeid)).then((res:any)=>{
        this.orgList = res;
        for(let val of this.orgList){//循环遍历出所有专业
          if(val.facultyid==this.model.facultyid){
            this.majorList=val.major_list;
            for(let n of this.majorList){
               n.isActive=false;
            }
          }
        }
        //循环遍历出选中项
        for(let m of this.majorList){
           for(let c of ceshiMajor){
              if(m.majorid==c.majorid){
                m.isActive=true;
              }
           }
        }
        this.opera.loadiss();
      }).catch((err)=>{
        this.opera.loadiss();
        //console.log(err);
      })
    }
  }

  //选择院系后更新专业列表
  newMajor(){
    this.majorList=[];
    for(let val of this.orgList){
      if(val.facultyid==this.model.facultyid){
        this.majorList=val.major_list;
        for(let n of this.majorList){
           n.isActive=false;
        }
      }
    }
  }
  //切换选中状态
  newMajorList(n){
    // console.log("院级宣讲会");
    // console.log(n);
    if(n.isActive){
      n.isActive=!n.isActive;
    }else{
      let m=0;
      for(let a of this.majorList){
        if(a.isActive){
          m++;
        }
      }
      if(m>9){
        this.toastr.warning("意向专业最多添加10个");
        return;
      }
      n.isActive=!n.isActive;
    }

  }
  //获取列表
  getOrgList(){
    this.opera.loading();
    if(!this.local.get(Constants.collegeid))
    {
      return;
    }
    this.dataApi.getCollegePart(this.local.get(Constants.collegeid)).then((res:any)=>{
      // console.log("获取院系列表---");
      // console.log(res);
      this.orgList = res;
      if(this.model.facultyid){
        this.newMajor();
      }
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
    })
  }

  //获取院系列表（根据校区筛选）
  getCampusOrgList(){
    this.opera.loading();
    this.model.major_list=[];
    this.collegeMajorList=[];
    this.model.facultyid='';
    if(!this.local.get(Constants.collegeid))
    {
      return;
    }
    this.dataApi.getCollegeCampusPart(this.local.get(Constants.collegeid),{campusid:this.model.campusid}).then((res:any)=>{
      console.log("获取院系列表---切换校区");
      console.log(res);
      this.orgList = res;
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
    })
  }

   //获取职位列表数据
   getPositionList(){
    //console.log("在期职位本地存储----");
    //console.log(this.dataApi.getLocalpositionList());
    if(this.dataApi.getLocalpositionList()){
      this.config.positionList=this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
      if(this.model.positionids.length>0){
        for(let m of this.config.positionList){
          for(let n of this.model.positionids){
             if(m.positionid==n){
               this.positionList.push(m);
               break;
             }
          }
        }
      }else{
        this.positionIdEval();//赋值全部职位id
      }
    }else{
      this.getNewList();
    }
  }

  //检测是否选择校区
  checkOrgList(){
    if(this.orgList.length==0){
      this.toastr.info("请选择校区");
      return;
    }
  }

  //赋值职位默认值
  positionIdEval(){
    this.positionList=[];
    this.model.positionids=[];
    for(let m of this.config.positionList){
      if(!m.need_perfect){
        this.positionList.push(m);
        this.model.positionids.push(m.positionid);
      }
    }
  }

  //-------获取线上数据
  getNewList(){
    let model={
      limit:100,
      offset:0,
      status:'release',
    }
    this.dataApi.getPositionList(model).then((res:any)=>{
       //console.log("在期职位获取线上数据-----college-resume");
       //console.log(res);
      if(res)
      {
        this.config.positionList =  res.list;
        if(this.model.positionids.length>0){
          for(let m of this.config.positionList){
            for(let n of this.model.positionids){
              if(m.positionid==n){
                this.positionList.push(m);
                break;
              }
            }
          }
        }else{
          this.positionIdEval();//赋值全部职位id
        }
        this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
      }

     }).catch((error) => {
       this.toastr.error(error);
     });
  }

  //验证联系人格式
  checkContact(){
    let filter=/^[a-zA-Z\u4e00-\u9fa5]+$/;
    if(!filter.test(this.model.contacts)){
      this.toastr.warning("联系人姓名只能输入汉字或字母");
    }
  }

  //验证宣讲会主题格式
  checkName(){
    let filter=/^[0-9a-zA-Z\u4e00-\u9fa5]+$/;
    if(!filter.test(this.model.name)){
      this.toastr.warning("宣讲会主题不得输入特殊字符");
    }
  }

  //验证宣讲会期望人数
  checkNum(){
    let filter=/^[0-9]+$/;
    if(!filter.test(this.model.scale)){
      this.toastr.warning("宣讲会期望人数只能为数字");
    }
  }


  //验证手机号码格式
  checkPhone(phone){
    let phoneModel={
      format_type:1,//0:手机号，1：手机号&固话；2.邮箱
      data:phone
    }
    this.dataApi.phoneVerification(phoneModel).then((res:any)=>{
      if(!res.value){
        this.toastr.warning("联系电话格式错误");
        return;
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    })
  }

  //通过高校id获取高校详情
  getCollegeById(id){
    if(!id)
    {
      return;
    }
    this.dataApi.getCollageDetails(id).then((res:any)=>{
      console.log("获取到高校信息");
      console.log(res);
      if(res)
      {
        this.college = JSON.parse(JSON.stringify(res));
        if(!this.college.campustalk_switch){
          this.toastr.warning("当前所选高校暂未开启宣讲会，请重新选择高校申请");
          this.router.navigateByUrl('/home/college-campustalk');
        }
        if(this.college.audit_switch.campus_selection){//是否开启宣讲会校区选择
          this.getCollegeCampusList();//拉取校区列表
        }else{
          // this.getOrgList();//获取院系列表
        }
        this.getOrgList();//获取院系列表

        this.local.set(Constants.collegeid,this.college.collegeid);
      }

    }).catch((err)=>{
      this.toastr.error(err);
    });
  }

  //选择意向专业
  goMajor(){
    if(this.model.campustalk_type=='faculty'&&this.orgList.length==0){
     this.toastr.info("请先选择校区");
     return;
    }
    this.modelShow = true;
    this.opera.scrollTop();
    //调用子组件函数
    //调用子组件函数
    this.child.initData(this.model.major_list,this.orgList,10);
    // this.choosePosition();
  }

  //接收子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
    if(event){
      this.collegeMajorList=[];
      // console.log("接收子组件返回数据");
      // console.log(event);
      this.model.major_list = event;
      for(let n of event){
         for(let m of n.majors){
           this.collegeMajorList.push(m);
         }
      }
    }

  }

  //选择职位
  choosePosition(){
    this.modelTwoShow = true;
    this.opera.scrollTop();
    this.child1.initData(this.model.positionids,'');
  }

  ModelTwodismiss(event){
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    if(event){
      // console.log("接收子组件返回数据");
      // console.log(event);
      this.positionList=[];
      this.model.positionids = event;
      for(let m of this.config.positionList){
        for(let n of this.model.positionids){
           if(m.positionid==n){
             this.positionList.push(m);
             break;
           }
        }
      }

    }
  }

  //上传logo
previewPic(event) {
  let imgData  = event.target.files[0]; //获取file对象
  if (parseInt(imgData.size)/1024 > 1024*5) {
    this.toastr.warning('该图片大小超出了5M，请重新上传');
    return ;
  }
  if (window.FileReader) {
    var reader = new FileReader();
    reader.readAsDataURL(imgData);
    var that = this;
    reader.onloadend = function (e : any) {
      that.imageCropper(reader.result);
    };

  }
 }
 //上传
 imageCropper(imageData){
    imageData=imageData.replace(/^(data:image)\/png;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
    this.opera.loading();//加载动画
    this.dataApi.uploadDepartmentImg({value: imageData}).then((res:any)=>{
      this.model.cover = res.value;
      this.opera.loadiss();//停止加载动画
      this.dataApi.setLocalDepartment(this.myDepartment);
      setTimeout(() => {
        this.toastr.success('上传成功');
      },600);
    }).catch((error)=>{
     this.opera.loadiss();//停止加载动画
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    })
 }
  preview_auc(event,name) {
    let imgData  = event.target.files[0]; //获取file对象
    if (parseInt(imgData.size)/1024 > 1024*5) {
      this.toastr.warning('该图片大小超出了5M，请重新上传');
      return ;
    }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.imageCropper_auc(reader.result,name);
      };
    }
  }
  //上传
  imageCropper_auc(imageData,name){
    imageData=imageData.replace(/^(data:image)\/png;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
    this.opera.loading();//加载动画
    this.dataApi.uploadDepartmentImg({value: imageData}).then((res:any)=>{
      if(res){
        if(res.value){//------------上传之后赋值
          this.dmodel[name] = res.value;
        }
      }
      this.opera.loadiss();//停止加载动画
      setTimeout(() => {
        this.toastr.success('上传成功');
      },600);
    }).catch((error)=>{
      this.opera.loadiss();//停止加载动画
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    })
  }

//提交
goSign(){

  if(!this.campustalkApplyAllowd){
    this.toastr.warning("30天内无法再次申请");
    return
  }

  this.isSend = true;
  if(this.model.campustalk_type=='college'){
    this.model.facultyid='';
  }else{
    if(!this.model.facultyid){
      this.toastr.warning("请完善宣讲会信息");
      return;
    }
    this.model.major_list=[];
    let major=[];
    for(let val of this.majorList){
       if(val.isActive){
         major.push({code:val.code,text:val.name,majorid:val.majorid});
       }
    }
    for(let m of this.orgList){
      if(m.facultyid==this.model.facultyid){
        let model={
         facultyid:m.facultyid,
         faculty_name:m.name,
         majors:major
        }
        this.model.major_list.push(model);
      }
    }

  }
  this.model.major_list=this.model.major_list;
  let d = new Date().getTime();//当前时间
  let d1=new Date(this.model.start_time).getTime();
  let d2 = new Date(this.model.end_time).getTime();
  console.log('检查参数');
  console.log(this.model);
  //console.log(d1);
  //console.log(d2);
  if(this.model.positionids.length==0){
    this.toastr.warning("请选择招聘职位");
    return;
  }

  if(this.campusList.length>0){
    if(!this.model.campusid){
      this.toastr.warning("请选择校区");
      return;
    }
  }

  let filter2=/^[a-zA-Z\u4e00-\u9fa5]+$/;
  if(!this.model.contacts||!this.model.attendance||!this.model.scale||!this.model.phone||!this.model.start_time||!this.model.end_time||!this.model.name||!this.model.intro){
    this.toastr.warning("请先完善宣讲会信息");
    return;
  }else if(d1<d){
    this.toastr.warning("宣讲会开始时间不得早于当前时间");
    return;
  }else if(d2<=d1){
    this.toastr.warning("宣讲会结束时间不得小于开始时间");
     return;
  }
  let filter=/^[0-9]+$/;
  if(!filter.test(this.model.scale)){
    this.toastr.warning("宣讲会期望人数只能为数字");
    return
  }
  if(this.audit_switch.department_receipt && this.model.attachment.length <= 0){
    this.toastr.warning("请至少上传一张附件!");
    return;
  }
  if(this.authenticationApplyID){
    this.model.authid = this.authenticationApplyID
  }

  this.isSend = false;
  this.opera.loading();//加载动画
  if(this.applyDetails){
    this.dataApi.updateCampusSign(this.applyDetails.ctid,this.model).then((res:any)=>{
      this.opera.loadiss();//停止加载动画
      this.local.remove(Constants.campusApply);
      this.toastr.success("修改成功");
      this.router.navigate(['home/position-campus']);//返回我的宣讲会列表
    }).catch((error)=>{
      this.opera.loadiss();//停止加载动画
      this.toastr.error(error);
      //console.log(error);
    })
  }else{
    this.dataApi.getCampustalkSign(this.college.collegeid,this.model).then((res:any)=>{
      this.opera.loadiss();//停止加载动画
      this.toastr.success("报名成功");
      this.router.navigate(['home/position-campus']);//返回我的宣讲会列表
    }).catch((error)=>{
      this.opera.loadiss();//停止加载动画
      this.toastr.error(error);
      //console.log(error);
    })
  }
}

  //获取专业列表信息
  getMajorList(){
    if(this.dataApi.getMajorCode()){
      this.config.major = this.dataApi.getMajorCode();
    }else{
      this.dataApi.getDictDate('major').then((res:any)=>{
        //console.log("获取专业列表-----college-resume");
        //console.log(res);
        if(res)
        {
          this.config.major = res;
          this.dataApi.setMajorCode(res);
        }

      }).catch((error)=>{
        //console.log(error);
      });
    }
  }


  //获取企业信息
  getDepartment(){
    if(this.dataApi.getLocalDepartment()){
          this.myDepartment = this.dataApi.getLocalDepartment();
        }else{
          this.dataApi.getDepartment().then((res:any)=>{
            if(res)
            {
              this.dataApi.setLocalDepartment(res);
            }
        }).catch((error) => {
           this.toastr.error(error);
       });
      }
   }

   //附件图片
   previewMilkPic(event){
    if(this.model.attachment.length>2){
      this.toastr.warning("附件片最多上传3张");
      return;
    }
    let imgData  = event.target.files[0]; //获取file对象
     if (parseInt(imgData.size)/1024 > 1024*5) {
       this.toastr.warning('该附件大小超出了5M，请重新上传');
       return ;
     }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e : any) {
        that.uploadDepFile(reader.result,imgData.name);
      };
    }
  }

  previewMilkWord(event){
    let fileData  = event.target.files[0]; //获取file对象
    console.log("previewMilkWordfileData",fileData);
    let name = fileData.name;
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(fileData);
      var that = this;
      reader.onloadend = function (e : any) {
        let arr = name.split(".")
        let str = reader.result as string;
        let imageData=str.replace(/^(data:image)\/png;base64,/g,'')
        .replace(/^(data:application)\/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,/g,'')
        .replace(/^(data:application)\/wps-writer;base64,/g,'')
        .replace(/^(data:application)\/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,/g,'')
        .replace(/^(data:application)\/vnd.ms-powerpoint;base64,/g,'')
        .replace(/^(data:application)\/pdf;base64,/g,'');
        // imageData=imageData.replace('','');

        let upload = {
          "extension": arr[arr.length-1],
          "filename": name,
          "base64": imageData
        };
        that.uploadFile(upload);
        // console.log("previewMilkWord",reader.result);

      };
    }

  }

  //上传企业展示图片
  uploadDepImg(data){
    let imageData=data.replace(/^(data:image)\/png;base64,/g,'');
    imageData=imageData.replace(/^(data:image)\/jpeg;base64,/g,'');
    // let loader = this.tip.presentLoading('图片保存中，请稍等...');

    this.dataApi.uploadDepartmentImg({
      value: imageData
    }).then((res:any)=>{
      // loader.dismiss();
      this.model.attachment.push(res.value);
    }).catch((error)=>{
      // loader.dismiss();
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    })
  }
  //上传企业展示图片
  uploadDepFile(data,name){
    let imageData=data;
    imageData = this.opera.formatFileData(imageData)
    console.log('uploadDepImg',name,data)
    let filedata = {
      extension: name,
      filename: name,
      base64: imageData
    }
    this.opera.loading();
    this.dataApi.uploadDepartmentFile(filedata).then((res:any)=>{
      // loader.dismiss();
      this.opera.loadiss();
      this.model.attachment.push(res.link);
    }).catch((error)=>{
      // loader.dismiss();
      this.opera.loadiss();
      this.toastr.error("上传文件错误");
    })
  }

  uploadFile(data){
    console.log("uploadFile",data);
    this.opera.loading();//加载动画

    this.dataApi.uploadDepartmentFile(data).then((res:any)=>{
      // loader.dismiss();
      this.toastr.success("上传成功");
      console.log("上传成功",res);
      this.opera.loadiss();//停止加载动画
      this.model.attachment_list = []
      this.model.attachment_list.push(res);

    }).catch((error)=>{
      // loader.dismiss();
      this.opera.loadiss();//停止加载动画

      console.log("uploadFile error",error);
      this.toastr.error("上传失败或文件不支持!");
    })

  }

  //删除附件图片
  deleteImg(img){
    this.model.attachment.forEach((val)=>{
      if(val == img){
        let num = this.model.attachment.indexOf(val);
        this.model.attachment.splice(num,1);
      }
    })
  }
  //单个高校认证信息
  singleIdentity(collegeid){
    if(!collegeid)
    {
      return;
    }
    this.dataApi.lastCollegeAuthenticationIdentity(collegeid).then((res:any)=>{
      console.log("获取上次认证信息",res);

      if(res)
      {
        this.CollegeIdentityModel = res;
        if(res.facultyid){
          this.dmodel.facultyid = res.facultyid;
        }
        this.dmodel.business_licence = res.business_license;
        this.dmodel.id_card_front = res.id_card_front;
        this.dmodel.register_number = res.register_number;
        if(res.proxy_document){
          this.dmodel.proxy_document = res.proxy_document;
        }
        if(res.brief) {
          this.dmodel.brief = res.brief;
        }

        if(this.CollegeIdentityModel.status==0||this.CollegeIdentityModel.status==3) {
          this.router.navigate(['home/collegeidentity'],{
            queryParams: {
              isShow: this.CollegeIdentityModel.status==0?false:true
            }
          });
          return
        }

        if(this.CollegeIdentityModel.status==2||this.CollegeIdentityModel.status==1)
        {
          this.showTab1 = false;
          this.showTab2 = true;
          this.getCampusInfo();
        }
        else if(this.CollegeIdentityModel.status==3)
        {
          if(this.CollegeIdentityModel.no_apply_30day==true)
          {
            this.showTab1 = false;
            this.showTab2 = false;
            //获取到最后一次审核时间+30天，然后和当前时间判断，判断是否可再次提交,并no_apply_30day设置为false，
            let currentdata = new Date();
            let data = new Date(res.audit_time);
            data.setDate(data.getDate() + 30);
            if(currentdata>data)
            {
              console.log("用户勾选了30天可以申请",);
              this.showTab1 = true;
              this.showTab2 = false;
            }
            else
            {
              console.log("用户勾选了30天不可以申请",);
              this.showTab1 = false;
              this.showTab2 = false;
            }

          }
          else
          {
            this.showTab1 = true;
            this.showTab2 = false;
          }

        }
        else
        {
          this.showTab1 = true;
          this.showTab2 = false;
          //没有进行高校合作//

        }


      } else {
        this.router.navigate(['home/collegeidentity'],{
          queryParams: {
            isShow: false
          }
        });
      }
    }).catch((error)=>{
      console.log("获取上次认证信息error",error);
      this.opera.loadiss();
    })

  }
  // 企业获取宣讲会详情
  getCampusInfo(){
    console.log("企业获取宣讲会详情------",this.local.get(Constants.campusApply));

    if(!this.local.get(Constants.campusApply))
    {
      return;
    }
   let applyDetails=JSON.parse(this.local.get(Constants.campusApply));


    this.dataApi.getCampusInfo(applyDetails.ctid).then((res:any)=>{
      console.log("企业获取宣讲会详情",res);

      this.applyDetails = res;
      this.model.facultyid = res.facultyid;

      //audit_status
      //this.CollegeIdentityModel
      if(this.CollegeIdentityModel.status==3)
      {
        this.showTab1 = true;
        this.showTab2 = false;
        if(this.CollegeIdentityModel.status==3&&res.no_apply_30day)
        {
          this.showTab1 = false;
          this.showTab2 = false;
        }
      }
      else if(res.audit_status=="to_audit")
      {
        this.showTab1 = false;
        this.showTab2 = false;
      }
      else if(res.audit_status=="no_pass")
      {
        this.showTab1 = false;
        this.showTab2 = true;
      }
      else if(res.audit_status=="passed")
      {
        this.showTab1 = false;
        this.showTab2 = false;
      }


      //如果
      if(this.CollegeIdentityModel.status==2&&res.audit_status=="to_audit")
      {
        let signDate = new Date(res.department_modify_time)
        let now = new Date();
        let twoHourTime =  1000*60*60*2;
        if((now.getTime()-signDate.getTime())<=twoHourTime)
        {
          console.log('时间判断');
          this.isEdit = true;
          // this.toastr.warning('')
        }
      }


    }).catch((error)=>{
      this.toastr.error(error);
      this.opera.loadiss();
    })
  }
  getOrgList_shenhe(){
    this.opera.loading();
    if(!this.local.get(Constants.collegeid))
    {
      return;
    }
    this.dataApi.getCollegePart(this.local.get(Constants.collegeid)).then((res:any)=>{
      //console.log("获取院系列表---");
      //console.log(res);
      this.orgList_shenhe = res;
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
    })
  }
  //提交认证
  uploadDepartmentIdentity(){
    if(!this.collegeName){
      if(!this.dmodel.facultyid){
        this.toastr.info("请选择当前申请学院");
        return;
      }
    }
    let filter1 = /^[0-9a-zA-Z]{9}$/;
    let filter2 = /^[0-9a-zA-Z]{15}$/;
    let filter3 = /^[0-9a-zA-Z]{18}$/;
    if(!this.dmodel.business_licence){
      this.toastr.warning('请上传营业执照图片');
      return;
    }
    if(!this.dmodel.id_card_front){
      this.toastr.warning('请上传身份证正面');
      return;
    }
    if(!this.dmodel.register_number){
      this.toastr.warning('请填写统一社会信用代码');
      return;
    }
    if(!this.opera.isRegister_number(this.dmodel.register_number)){
      this.toastr.warning('统一社会信用代码格式不正确');
      return;
    }
    // else{
    //   let filterValue=this.model.register_number.trim();//删除左右两端的空格
    //   if(!filter.test(filterValue)){
    //     this.toastr.warning('统一社会信用代码格式错误');
    //     return;
    //   }
    // }

    if(this.authcation_office_phone && !this.dmodel.office_phone){
      this.toastr.warning('请填写办公电话');
      return;
    }
    if(this.secureMaterail){
      if(!this.dmodel.proxy_document){
        this.toastr.info("请上传招聘委托书");
        return;
      }
    }
    if(this.myDepartment.industry=='0604'||this.myDepartment.industry=='0605'||this.myDepartment.industry=='0606')
    {
      if(!this.dmodel.school_permit){
        this.toastr.info("请上传办学许可证");
        return;
      }
    }
    this.opera.loading();//加载动画
    console.log("申请院系",this.dmodel);
    if(this.IdentityLoading)
    {
      this.toastr.success('提交中');
      return;
    }
    this.dataApi.uploadDepartmentIdentity(this.dmodel).then((res:any)=>{
      this.opera.loadiss();//停止加载动画
      this.IdentityLoading = false;
      if(res.value){
        this.authenticationApplyID = res.value
      }
      setTimeout(() => {
        this.toastr.success('提交成功');
        this.singleIdentity(this.dmodel.collegeid)//通过高校id获取高校详情
        this.showTab1 = false;
        this.showTab2 = true;
      },600);
    }).catch((error) => {
      this.IdentityLoading = false;
      this.toastr.error(error);
      this.opera.loadiss();//停止加载动画
      //跳转页面
      // this.router.navigateByUrl("/home/college-campustalk"); //-------返回高校列表
    });
  }
  //获取双选会申请的提示信息
  getCampApplyTips(){
    this.dataApi.getCampApplyTips(this.local.get(Constants.collegeid)).then((res:any)=>{
      console.log('getCampApplyTips',res);
      if(res){
        if(res.tip_switch){
          this.isShowCampChipsWithCollege = res.tip_switch
          this.isHaveCampChipsWithCollege = true
          this.isHaveCampChipsWithContent = res.tip
          this.startTimeChips();

          if(this.CollegeIdentityModel){
            if(this.CollegeIdentityModel.status==1){
              this.stopTimer()
            }
          }
          if(this.applyDetails){
            if(this.applyDetails.audit_status=="to_audit"){
              this.stopTimer()
            }
          }

        }
      }
    }).catch((error)=>{
    });
  }
  stopTimer(){
    if(this.ChipsWithCollegeTimer){
      clearInterval(this.ChipsWithCollegeTimer)
    }
    this.ChipsWithCollegeBtn = "确定"
  }
//开始高校的提示倒计时
  startTimeChips() {
    let that = this;
    this.ChipsWithCollegeTimer =  setInterval(()=>{
      that.ChipsWithCollegeIndex-=1;
      if(that.ChipsWithCollegeIndex==0){
        that.ChipsWithCollegeBtn  = "确定"
        if(that.ChipsWithCollegeTimer){
          clearInterval(that.ChipsWithCollegeTimer)
        }
        return;
      }
      that.ChipsWithCollegeBtn = that.ChipsWithCollegeIndex+ "s"
    },1000)
  }
  //高校的提示 确定
  collegeChipsClick(){
    if(this.ChipsWithCollegeBtn== "确定") {
      this.isShowCampChipsWithCollege = false
    }
  }
  formatFileName(url){
    if(!url){
      return '附件'
    }
    try {
      let arr = url.split('/');
      let name = arr[arr.length-1];
      return name
    }catch (e) {
      return '附件'
    }
  }
  deleteFlie(url){
    let newArr = [];
    this.model.attachment.map((item)=>{
      if(item!=url){
        newArr.push(item)
      }
    });
    this.model.attachment = newArr
  }
  // 获取是否允许申请双选会，高校开关
  getCampustalkApplyAllowd(){
    if(!this.local.get(Constants.collegeid)){
      return
    }
    this.dataApi.getCampustalkApplyAllowd({collegeid:this.local.get(Constants.collegeid)}).then((res:any)=>{
      console.log('getCampustalkApplyAllowd',res);
      if(res){
        this.campustalkApplyAllowd = res.value
      }
    }).catch((error)=>{
    });
  }
}
