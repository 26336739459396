import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
// import { Location } from '@angular/common';
import { DataApi, Local, Constants, Opera, Config, FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelStillMoreComponent } from '../model-still-more/model-still-more.component';
import { ModelSelectMoreComponent } from '../model-select-more/model-select-more.component';
import { ModelSelectTwoComponent } from '../model-select-two/model-select-two.component';
import {ConfirmComponent} from "../confirm/confirm.component";
import { ModelPositionTemplateComponent } from '../model-position-template/model-position-template.component';
import { MatDialog } from "@angular/material/dialog";
import * as moment from 'moment';
declare let laydate;

@Component({
  selector: 'app-position-publish',
  templateUrl: './position-publish.component.html',
  styleUrls: ['./position-publish.component.css']
})
export class PositionPublishComponent implements OnInit {

  public config: Config;
  public isShowDownLoadAPP = false;
  @ViewChild('child1', { static: true }) child: ModelStillMoreComponent;
  @ViewChild('child2', { static: true }) child2: ModelSelectMoreComponent;
  @ViewChild('child3', { static: true }) child3: ModelSelectTwoComponent;
  @ViewChild('child4', { static: true }) child4: ModelPositionTemplateComponent;
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelTwoShow = false;//是否打开弹窗
  @Output() modelThreeShow = false;//是否打开弹窗
  @Output() modelFourShow = false;//是否打开弹窗
  public model = {
    name: '',//职位名称
    post_type: '',//职位类别
    post_nature: '',//工作性质
    position_salary: '',//月薪范围(字典)
    education: '',//学历要求
    location: {//工作地址
      region: ''//,//区域代码
      //coordinates:[],//坐标
      //address:''//详细地址
    },//工作城市
    intent_count: null,
    majors: [],//专业要求
    skills: [],//技能标签
    responsibility: '',//岗位职责(职位描述)
    preferment: [],//晋升路径
    effect_time: ''//截止日期
  };
  //薪资数据
  public salaryData = [
    { "code": "01", "text": "1K以下" },
    { "code": "02", "text": "1K-2k" },
    { "code": "03", "text": "2k-4k" },
    { "code": "04", "text": "4k-6k" },
    { "code": "05", "text": "6k-8k" },
    { "code": "06", "text": "8k-10k" },
    { "code": "07", "text": "10k-15k" },
    { "code": "08", "text": "15k-25k" },
    { "code": "09", "text": "25k以上" }
  ];
  public salaryData02 = [
    { "code": "03", "text": "2k-4k" },
    { "code": "04", "text": "4k-6k" },
    { "code": "05", "text": "6k-8k" },
    { "code": "06", "text": "8k-10k" },
    { "code": "07", "text": "10k-15k" },
    { "code": "08", "text": "15k-25k" },
    { "code": "09", "text": "25k以上" }
  ];
  public publishPosition = "发布职位";
  public isSend = false;//是否点击了发送按钮
  public childTitle = '选择专业';
  public childTwoTitle = '选择职位类别';
  public childThreeTitle = '选择职位类别';
  public path = [{ code: 1, text: '' }, { code: 2, text: '' }, { code: 3, text: '' }];//当前需填写晋升职位数量
  public positionid = '';//职位id
  public isWidthSkill = false;//是否获取推荐技能标签
  public position: any;//职位详情
  public positionEndTime = "";//职位到期时间（提醒）
  public endTime = "";//职位到期时间
  public ruturnPage = false;
  constructor(public dataApi: DataApi,
    public local: Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public opera: Opera,
    public formatDate: FormatDate,
    public toastr: ToastrService,
    public dialog: MatDialog,
    ) {
    this.config = Config.getInstance();//实例化config
    activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.ruturnPage) {
        this.ruturnPage = true;
      }
      if (queryParams.id) {
        this.positionid = queryParams.id;
        this.local.set(Constants.positionid, this.positionid);
        this.publishPosition = "编辑职位";
        this.getPostDetails();//根据职位positionid获取职位详情
      }
    })
    //判断本地存储，当前是否是刷新状态
    if (!this.positionid) {
      if (this.local.get(Constants.positionid)) {
        this.publishPosition = "编辑职位";
        this.positionid = this.local.get(Constants.positionid);
        this.getPostDetails();//根据职位positionid获取职位详情
      } else {
        //创建职位的时候赋值默认职位结束时间
        // this.model.effect_time = this.formatDate.formatDate(this.formatDate.addDays(new Date(),60));
      }
    }
  }

  ngOnInit() {
    this.opera.initConfig('position', 'position-manage');
    this.getSkillList();//获取技能标签字典
    this.getRegionList();//获取地区字典
    this.getPositionType();//获取职位类别字典
    this.getMajorList();//获取专业字典
    this.getEducationList();//获取学历列表信息
    // this.positionEndTime = this.formatDate.formatDate(this.formatDate.addDays(new Date(),60));
    let nowData = new Date();
    let year = nowData.getFullYear();
    let month = nowData.getMonth() + 1;
    let day = nowData.getDate();

    let nowData2 = new Date();
    var resultDate = new Date((nowData2.getTime() / 1000 + (86400 * 180)) * 1000);//增加n天后的日期
    let year1 = resultDate.getFullYear();
    let month1 = resultDate.getMonth() + 1;
    let day1 = resultDate.getDate();

    this.positionEndTime = year1 + "-" + month1 + '-' + day1;

    let that = this;
    laydate.render({
      elem: '#effect-time',
      min: year + "-" + month + '-' + day
      , max: year1 + "-" + month1 + '-' + day1,
      done: function (value, date) {
        // console.log(date);
        that.endTime = value;
      }
    })
    this.config.major.push({
      code: "8080",
      parentcode: "0",
      parenttext: null,
      text: "不限",
      level: 1,
      order: 1234,
      relation_code: "",
      relation_desc: ""
    })
    this.config.major.push({
      code: "808080",
      parentcode: "8080",
      parenttext: null,
      text: "不限专业",
      level: 2,
      order: 1235,
      relation_code: "",
      relation_desc: ""
    })
  }

  //根据职位positionid获取职位详情
  getPostDetails() {
    this.opera.loading();
    this.dataApi.getPostDetails(this.positionid).then((res: any) => {
      //console.log('获取职位详情');
      //console.log(res);
      this.position = res;
      this.initEditorPosition(res);
      this.opera.loadiss();
    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss();
    });
  }

  //编辑职位初始化
  initEditorPosition(_position) {
    this.model.name = _position.name;//职位名称
    this.model.post_type = _position.post_type;//职位类别
    this.model.post_nature = _position.post_nature;//工作性质
    this.model.position_salary = _position.position_salary;//月薪范围(字典)
    this.model.education = _position.education;//学历要求
    this.model.intent_count = _position.intent_count;//学历要求
    this.endTime = _position.effect_time.substr(0, 10);
    //工作地址
    if (_position.location) {
      if (_position.location.region) {
        this.model.location.region = _position.location.region;
      }
    }
    //专业要求
    if (_position.majors) {
      if (_position.majors.length > 0) {
        _position.majors.forEach((val) => {
          this.model.majors.push(val.code);
        });
      }
    }
    //技能标签
    if (_position.skills) {
      if (_position.skills.length > 0) {
        _position.skills.forEach((val) => {
          this.model.skills.push(val.code);
        });
      }
    }
    //晋升路径
    if (_position.preferment) {
      if (_position.preferment.length > 0) {
        let index = 0;
        for (let val of _position.preferment) {
          index = _position.preferment.indexOf(val);
          if (index > 2) {
            let p = { code: index + 1, text: val }
            this.path.push(p);
          } else {
            this.path[index].text = val;
          }
        }
      }
    } else {
      this.model.preferment = [];
    }
    this.model.responsibility = _position.responsibility;//岗位职责(职位描述)
    // this.model.effect_time = this.formatDate.formatDate(new Date(_position.effect_time));//职位到期时间

  }

  //添加晋升职位
  addPosition() {
    if (this.path.length > 4) {
      this.toastr.warning('晋升职位最多添加5个');
      return;
    }
    let number = this.path.length + 1;
    let p = { code: number, text: '' }
    this.path.push(p);
  }

  //验证宣讲会主题文字格式
  checkName(text) {
    if (!text) { return }
    let filter = /^[a-zA-Z\u4e00-\u9fa5]+$/;
    if (!filter.test(text)) {
      this.toastr.warning("职位名称只能输入汉字或字母");
    }
  }

  //获取学历列表信息
  getEducationList() {
    if (this.dataApi.getEducationCode()) {
      //console.log("获取学历列表本地存储-----college-resume");
      //console.log(this.dataApi.getEducationCode());
      this.config.education = this.dataApi.getEducationCode();
    } else {
      //获取学历列表
      this.opera.loading();
      this.dataApi.getDictDate('education').then((res: any) => {
        this.dataApi.setEducationCode(res);
        this.opera.loadiss();
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
      });
    }
  }

  //获取技能标签字典
  getSkillList() {
    if (this.dataApi.getSkillCode()) {
      this.config.skill = this.dataApi.getSkillCode();
    } else {
      //获取技能字典
      this.opera.loading();
      this.dataApi.getDictDate('skill').then((res: any) => {
        // //console.log(res);
        this.dataApi.setSkillCode(res);
        this.opera.loadiss();
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
      });
    }
  }

  //获取地区字典
  getRegionList() {
    if (this.dataApi.getRegionCode()) {
      this.config.region = this.dataApi.getRegionCode();
    } else {
      //获取地区字典
      this.opera.loading();
      this.dataApi.getDictDate('region').then((res: any) => {
        this.dataApi.setRegionCode(res);
        this.opera.loadiss();
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
      });
    }
  }


  //获取职位类别列表
  getPositionType() {
    if (this.dataApi.getPostTypeCode()) {
      this.config.post_type = this.dataApi.getPostTypeCode();
    } else {
      //获取职位类别字典
      this.opera.loading();
      this.dataApi.getDictDate('post_type').then((res: any) => {
        // //console.log(res);
        this.dataApi.setPostTypeCode(res);
        this.opera.loadiss();
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
      });
    }
  }


  //获取专业列表信息
  getMajorList() {
    if (this.dataApi.getMajorCode()) {
      this.config.major = this.dataApi.getMajorCode();
    } else {
      this.opera.loading();
      this.dataApi.getDictDate('major').then((res: any) => {
        //console.log("获取专业列表-----college-resume");
        //console.log(res);
        this.opera.loadiss();
        this.config.major = res;
        this.dataApi.setMajorCode(res);
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
      });
    }
  }

  //去选择职位类别
  goPositionType() {
    this.modelTwoShow = true;
    this.childTwoTitle = "选择职位类别";
    this.opera.scrollTop();//页面滚动
    //调用子组件函数
    this.child2.initData(this.model.post_type, this.config.post_type, this.childTwoTitle);
  }

  //接收子组件返回数据
  ModelTwodismiss(event) {
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    if (event) {
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      this.model.post_type = event.code;
      this.model.name = event.text;
      this.getRecommendList(this.model.post_type);//获得推荐技能标签
      // if(this.childTwoTitle == "选择职位类别"){
      //   this.model.skills = event;
      // }else{
      //   this.model.majors = event;
      // }
    }
  }

  //打开查看模板
  goTemplate() {
    if (!this.model.post_type) {
      this.toastr.warning("请先选择职位类别");
      return;
    } else {
      this.modelFourShow = true;
      this.opera.scrollTop();//页面滚动
      this.child4.getTemplate(this.model.post_type);
    }

  }

  //接收子组件返回数据
  ModelFourdismiss(event) {
    this.modelFourShow = false;
    this.opera.scrollTrue();
    if (event) {
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      this.model.responsibility = event;
    }
  }

  //推荐标签
  getRecommendList(data) {
    let model = {
      source_dict_type: 'post_type',
      source_code: data,
      result_dict_type: 'skill'
    }
    this.model.skills = [];
    this.opera.loading();
    this.dataApi.getRecommendList(model).then((res: any) => {
      this.isWidthSkill = true;
      //console.log("获取推荐技能标签------------------------------------------positionskills");
      //console.log(res);
      let n = 0;
      for (let val of res.contrast_list) {
        if (n > 5) {
          return;
        }
        if (val.selected) {
          n++;
          this.model.skills.push(val.code);
        }
      }
      this.opera.loadiss();
    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss();
    })
  }

  //去选择工作城市
  goCityCode() {
    this.modelThreeShow = true;
    this.childThreeTitle = "选择工作城市";
    this.opera.scrollTop();//页面滚动
    //调用子组件函数
    this.child3.initData(this.model.location.region, this.config.region, this.childThreeTitle);
  }

  //接收子组件返回数据
  ModelThreedismiss(event) {
    this.modelThreeShow = false;
    this.opera.scrollTrue();
    if (event) {
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      this.model.location.region = event.code;
    }
  }



  //选择意向专业
  goMajor() {
    this.modelShow = true;
    this.childTitle = "选择专业";
    this.opera.scrollTop();//页面滚动
    //调用子组件函数
    this.child.initData(this.model.majors, this.config.major, 5, this.childTitle);
  }

  //接收子组件返回数据
  Modeldismiss(event) {
    this.modelShow = false;
    this.opera.scrollTrue();
    if (event) {
      console.log("接收子组件返回数据",this.childTitle);
      console.log(event);
      if (this.childTitle == "选择技能标签") {
        this.model.skills = event;
        this.isWidthSkill = false;
      } else {
        this.model.majors = event;
      }
    }
  }

  //选择技能标签
  goSkill() {
    if (!this.model.post_type) {
      this.toastr.warning("请先选择职位类别");
      return;
    }
    this.modelShow = true;
    this.childTitle = "选择技能标签";
    this.opera.scrollTop();//页面滚动
    //调用子组件函数
    this.child.initData(this.model.skills, this.config.skill, 6, this.childTitle);
  }

  //职位下线
  goExpired() {
    this.opera.loading();
    this.dataApi.managePositionExpired(this.positionid).then((res: any) => {
      //console.log(res);
      this.opera.loadiss();
      setTimeout(() => {
        this.toastr.success('下线成功');
        this.router.navigateByUrl('/home/position-manage');
      }, 600);
      this.opera.setPositionListByExpired(this.positionid);//更新在期职位本地存储
    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss();
      setTimeout(() => {
        this.toastr.error(error);
      }, 600);

    });
  }

  //职位重发检查剩余可发布职位数量
  goResend() {
    let d;
    d = this.dataApi.getLocalDepartment();
    if (d.quota.position == 0) {
      this.opera.loading();
      this.dataApi.getDepartment().then((res: any) => {
        this.opera.loadiss();
        this.dataApi.setLocalDepartment(res);
        if (res.quota.position == 0) {
          this.toastr.warning("发布职位数量已达上限");
          return;
        } else {
          this.goResendPosition();
        }
      }).catch((error) => {
        this.opera.loadiss();
        this.toastr.error(error);
      });
    } else {
      this.goResendPosition();
    }
  }

  //职位重发
  goResendPosition() {
    this.opera.loading();
    this.dataApi.managePositionResend(this.positionid).then((res: any) => {
      this.opera.loadiss();
      this.toastr.success('重发成功');
      this.router.navigateByUrl('/home/position-manage');
      this.opera.setPositionListByRelease();//更新在期职位本地存储
    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss();
      setTimeout(() => {
        if (error.Errcode == '2005') {//配额超限
          this.toastr.warning('发布职位数量已达上限');
        } else {
          this.toastr.error(error);
        }
      }, 600);
    });
  }

  //发布职位
  createPosition() {
    this.isSend = true;
    if (!this.endTime) {
      this.toastr.warning('请选择职位到期时间');
      return;
    }
    this.endTime = moment(this.endTime).format('YYYY-MM-DD');
    this.positionEndTime = moment(this.positionEndTime).format('YYYY-MM-DD')
    let t1 = new Date().getTime();
    let t2 = new Date(this.endTime).getTime();
    let t3 = new Date(this.positionEndTime).getTime();
    if (t1 > t2 || t1 == t2) {
      this.toastr.warning("职位在期时间不得小于1天");
      this.endTime = '';
      return;
    } else if (t2 > t3) {
      this.toastr.warning("职位在期时间不得大于180天");
      this.endTime = '';
      return;
    }
    let hour = new Date().getHours();
    let minute = new Date().getMinutes();
    let second = new Date().getSeconds();
    let hour1, minute1, second1;
    if (hour < 10) {
      hour1 = '0' + hour;
    } else {
      hour1 = String(hour);
    }
    if (minute < 10) {
      minute1 = '0' + minute;
    } else {
      minute1 = String(minute);
    }
    if (second < 10) {
      second1 = '0' + second;
    } else {
      second1 = String(second);
    }
    this.model.effect_time = this.endTime + 'T' + hour1 + ':' + minute1 + ':' + second1 + '+08:00';
    console.log("职位到期时间");
    console.log(this.model.effect_time);
    // return;
    if (!this.model.name || !this.model.post_type || !this.model.position_salary || !this.model.education || !this.model.location.region || !this.model.intent_count || !this.model.responsibility) {
      this.toastr.warning('请完善职位信息');
      return;
    }
    if (this.model.intent_count <= 0) {
      this.toastr.warning('招聘人数填写错误')
      return;
    }
    if (this.model.majors.length > 0) {
      console.log(this.model.majors)
      this.model.majors.map((item) => {
        if (item == '808080') {
          this.model.majors = [];
        }
      })
    }
    this.model.preferment = []
    for (let val of this.path) {
      if (val.text) {
        this.model.preferment.push(val.text);
      }
    }
    if (!this.model.post_nature) {
      this.toastr.warning('请选择工作性质')
      return
    }
    if (this.model.preferment.length > 0) {
      console.log('晋升路径', this.model.preferment);
      let nary = this.model.preferment.slice().sort();
      for (let i = 0; i < this.model.preferment.length; i++) {
        if (nary[i] == nary[i + 1]) {
          this.toastr.warning('重复的晋升路径：' + nary[i])
          return;
        }
      }
    }
    //console.log(this.model);
    //判断否是编辑职位
    this.opera.loading();

    if (this.positionid) {
      this.dataApi.updatePosition(this.positionid, this.model).then((res: any) => {
        console.log('修改职位', res, this.model, this.positionid, this.position);
        if (this.position.position_status == 'stop') {
          this.goResendPosition();
          return;
        }
        this.opera.loadiss();
        //如果使用过APP登陆则不弹窗下载APP
        let isAPPLogin = localStorage.getItem('app_login');
        if (isAPPLogin == 'true') {
          setTimeout(() => {
            this.toastr.success('修改成功');
            this.router.navigateByUrl('/home/position-manage');
          }, 300);
        } else {
          this.toastr.success('修改成功');
          //this.isShowDownLoadAPP= true;
        }
        this.opera.setPositionListByRelease();//更新在期职位本地存储
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
        setTimeout(() => {
          this.toastr.error(error);
        }, 600);
      });
    } else {
      this.dataApi.createPosition(this.model).then((res: any) => {
        if (this.ruturnPage) {
          this.dialog.open(ConfirmComponent, {
            data: {
              title: '提示',
              message: '是否返回双选会继续报名？',
              trueText: '是',
              falseText: '否'
            }
          }).afterClosed().subscribe((isConfirmed) => {
            //console.log(isConfirmed,'点击确定');
            if (isConfirmed) {
              history.go(-1);
              return
            } else {
              this.router.navigate(['home/position-manage'], {
                queryParams: {
                  isShow: true
                }
              });
            }
          });
        } else {
          //如果使用过APP登陆则不弹窗下载APP
          setTimeout(() => {
            this.toastr.success('发布成功');
            this.router.navigate(['/home/position-manage'], {
              queryParams: {
                isShow: true
              }
            });
            this.opera.getDepartment(); //更新企业剩余可发布职位数量
          }, 300);
        }

        this.opera.loadiss();
        this.opera.setPositionListByRelease();//更新在期职位本地存储

      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
        setTimeout(() => {
          if (error.Errcode == '2005') {//配额超限
            this.toastr.warning('发布职位数量已达上限');
          } else {
            this.toastr.error(error);
          }
        }, 600);
      });
    }

  }
  // 监听招聘人数
  recruits() {
    if (this.model.intent_count >= 50) {
      let message = `确定此职位招聘${this.model.intent_count}人`;
      let fn2 = () => this.model.intent_count = 10;
      this.opera.customTips('提示', message, '确定', '取消', '', fn2);
    }
    if (this.model.intent_count <= 0) {
      this.toastr.warning('招聘人数填写错误')
      setTimeout(() => {
        this.model.intent_count = '';
      }, 300)
    }
  }
  cloceDownLoad() {
    this.isShowDownLoadAPP = false;
    this.router.navigateByUrl('/home/position-manage');

  }
  deletePath(item, index) {
    if (this.path.length == 1) {
      return;
    }
    this.path = this.path.filter((item, i) => { // item为数组当前的元素
      return index != i;
    })
    console.log('deletePath', item, index)
  }

  post_natureChange(){
    this.model.position_salary = '';
  }

}
