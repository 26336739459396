import { Component, OnInit, Input, Output } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
@Component({
  selector: 'app-college-campustalk',
  templateUrl: './college-campustalk.component.html',
  styleUrls: ['./college-campustalk.component.css']
})
export class CollegeCampustalkComponent implements OnInit {
  public config: Config;
  public hotCity=[];//热门城市
  public collegeList = [];//高校列表
   // 筛选条件--搜索
  public model={
    city:'',//城市
    school_type:'',//分类
    school_nature:'',//性质
    labels:'',//特色
    status:'',//校招状态
    offset:0,
    limit:20
  }
  public myDepartment:any;//公司信息
  public modelIndentity={
    collegeid:'',
    business_licence:'',//企业营业执照
    id_card_front:'',//身份证正面
    register_number:''//营业执照注册号
  }
  public collegeid="";//存储选中学校id
  public countList = 0;//列表数量
  @Output() modelShow = false;//是否打开弹窗
  @Output() collegeName = '';//高校名称
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public isShowFilter = false;
  @Output() orgList=[];//院系列表
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public dialog: MatDialog,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {
    this.opera.initConfig('college','college-campustalk');
    this.getHotCity();//获取热门城市
    this.getSchoolType();//获取高校性质
    this.getSchoolNature();//获取高校分类
    this.getSchoolLabel();//获取高校特色
    this.getCollegeList();//获取高校列表
    this.getDepartment();//获取企业信息
  }

  //申请宣讲会
  goSigin(n){
    this.router.navigate(['home/college-campus-sign'], {
      queryParams: {
        college:JSON.stringify(n)
      }
    });
  }

  //订阅高校公众号
  getCollegeAccount(college){
    this.opera.CollegeRead(college);
  }

  //打开申请校招页面
  gocollegeIdentity(n){
    this.opera.scrollTop();
    this.collegeid=n.collegeid;
    if(n.audit_switch.faculty_authcation){//是否打开院系认证审核开关
      this.collegeName = '';
      this.getOrgList();
    }else{
      this.collegeName = n.name;
      this.orgList = [];
      this.modelShow = true;
    }
  }

   //获取列表
  getOrgList(){
    this.opera.loading();
    this.dataApi.getCollegePart(this.collegeid).then((res:any)=>{
      // console.log("获取院系列表---");
      // console.log(res);
      this.orgList = res;
      this.opera.loadiss();
      this.modelShow = true;
    }).catch((err)=>{
      this.opera.loadiss();
      //console.log(err);
      this.toastr.error("获取院系列表失败，请稍后重试");
    })
  }

  //获取申请校招返回值
  Modeldismiss(event){
    //console.log('子组件返回结果-----college-details');
    //console.log(event);
    this.modelShow = false;
    this.opera.scrollTrue();
    if(event){
      if(event === true){
        this.local.remove(Constants.facultyid);
      }else{
        this.local.set(Constants.facultyid,event);
      }
      //跳转认证页面
      this.local.set(Constants.collegeid,this.collegeid);
      this.router.navigateByUrl("/home/company-identity-details");
    }
  }

//获取企业信息
getDepartment(){
  if(this.dataApi.getLocalDepartment()){
        this.myDepartment = this.dataApi.getLocalDepartment();
        //console.log("企业信息本地存储---------------------------CollegeIdentityPage");
        //console.log(this.dataApi.getLocalDepartment());
      }else{
        this.dataApi.getDepartment().then((res:any)=>{
          //console.log("企业信息获取线上数据---------------------------CollegeIdentityPage");
         //console.log(res);
         this.dataApi.setLocalDepartment(res);
      }).catch((error) => {
         //console.log(error);
         this.toastr.error(error);
     });
    }

 }



  //跳转高校详情页面
  goDetails(n){
    this.config.isNavActive='college';
    this.config.isNavChildActive='college-campustalk';
    this.local.set(Constants.collegeid,n.collegeid);
    this.router.navigate(['home/college-details'], {
      queryParams: {
        // college:JSON.stringify(n),
        item:n.collegeid
      }
    });
  }

   //获取分页子组件返回数据
   getChildEvent(index){
    //console.log('子组件返回分页数-----college-campustalk');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*20;
      this.getCollegeMoreList();//获取列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*20;
        this.getCollegeMoreList();//获取列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*20;
        this.getCollegeMoreList();//获取列表
      }
    }

  }

  //获取高校列表--offset=0
  getCollegeList(){
    this.model.offset = 0;
    this.opera.loading();
    this.dataApi.getCollegeList(this.model).then((res:any)=>{
      //console.log("获取高校列表-----college-campustalk");
      //console.log(res);
      this.collegeList=res.list;
      this.countList=res.count;
      this.pageCount =Math.ceil(res.count/20);
      this.opera.loadiss();
    }).catch((err)=>{
      //console.log(err);
      this.opera.loadiss();
    })
  }
  //获取高校列表--offset自定义
  getCollegeMoreList(){
    this.opera.loading();
    this.dataApi.getCollegeList(this.model).then((res:any)=>{
      //console.log("获取高校列表-----college-campustalk");
      //console.log(res);
      this.collegeList=res.list;
      this.countList=res.count;
      this.pageCount =Math.ceil(res.count/20);
      this.opera.loadiss();
    }).catch((err)=>{
      //console.log(err);
      this.opera.loadiss();
    })
  }

  //  //获取开启宣讲会的高校列表
  //  getCampustalkList(){
  //   let model={
  //     offset:0,
  //     limit:9
  //   }
  //   this.dataApi.getCampustalkList(model).then((res:any)=>{
  //     //console.log("获取开启宣讲会的高校列表----college-campustalk");
  //     //console.log(res);
  //     this.collegeList = res.list;
  //     this.countList = res.count;
  //     this.pageCount =Math.ceil(res.count/9);
  //   }).catch((err)=>{
  //     this.toastr.error(err);
  //     //console.log(err);
  //   })
  // }


  //获取热门城市
  getHotCity(){
    if(this.hotCity.length<1){
      this.dataApi.getCollegeHotCity().then((res:any)=>{
         //console.log("获取热门城市----college-campustalk");
         //console.log(res);
         this.hotCity=res;
      }).catch((err)=>{
         //console.log(err);
      })
    }
  }
  //获取高校性质school_type
  getSchoolType(){
    this.dataApi.getDictDate('school_type').then((res:any)=>{
      //console.log("获取高校性质----college-campustalk");
      //console.log(res);
      this.config.school_type=res;
    }).catch((err)=>{
      //console.log(err);
    })
  }
  //获取高校分类school_nature
  getSchoolNature(){
    this.dataApi.getDictDate('school_nature').then((res:any)=>{
      //console.log("获取高校分类----college-campustalk");
      //console.log(res);
      this.config.school_nature=res;
    }).catch((err)=>{
      //console.log(err);
    })
  }
  //获取高校特色school_label
  getSchoolLabel(){
    this.dataApi.getDictDate('school_label').then((res:any)=>{
      this.config.school_label=res;
    }).catch((err)=>{
      //console.log(err);
    })
  }


}
