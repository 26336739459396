<!-- 页头部分 -->
<!-- 页头部分 -->
<div class="contentRight-header"><img (click)="opera.goNewRouter('college-details')" src='assets/images/router-old.png'/>校园招聘<span *ngIf="!config.isByCollege"> - 校园+</span> - 学校详情 - 生源速览</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <app-model-college-identity [name]="collegeName" [orgList]="orgList" [item]="collegeIdentity" [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-college-identity>
  <div class="college-block" style="margin-bottom:20px;">
        <!-- 页头部分 -->
        <div class="college-block-head">
            生源速览
        </div>
        <!-- 详情模块 -->
        <div class="college-head-details clear" *ngIf="college">
          <!-- 左侧文字 -->
          <ul class="left">
            <li><span>学校名称：</span>{{college.name}}<i *ngIf="!college.name">暂无</i></li>
            <li><span>创办时间：</span>{{college.establish_time | date:"yyyy-MM-dd"}}<i *ngIf="!college.establish_time">暂无</i></li>
            <li><span>学校性质：</span>{{college.school_nature_text}}<i *ngIf="!college.school_nature_text">暂无</i></li>
            <li><span>联系邮箱：</span>{{college.email}}<i *ngIf="!college.email">暂无</i></li>
            <li><span>学校代码：</span>{{college.code}}<i *ngIf="!college.code">暂无</i></li>
            <li><span>所在城市：</span>{{college.region_text}}<i *ngIf="!college.region_text">暂无</i></li>
            <!-- <li><span>就业电话：</span>{{college.phone}}</li> -->
          </ul>
          <!-- 右侧logo -->
          <div class="right">
            <img *ngIf="college.logo" [src]="college.logo" alt="高校logo">
            <img *ngIf="!college.logo" src="assets/images/none.png" alt="高校logo">
          </div>
      </div>
  </div>
  <div class="college-block">
    <!-- 头部 -->
    <div class="college-resume-filter">
        <!-- 选择专业 -->
        <span>专业</span>
        <select name="major" id="major" [(ngModel)]="majorOne" (ngModelChange)="majorOneChange()" autocomplete="off">
            <option class="place-text" value='' disabled hidden selected="selected">选择一级专业</option>
            <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
        </select>
        <select name="majorTwo" id="majorTwo" [(ngModel)]="model.major" (ngModelChange)="getResumeList()" (click)="isShowTwo()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择二级专业</option>
                <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
        </select>
        <!-- 选择学历 -->
        <span>&nbsp;&nbsp;&nbsp;&nbsp;学历</span>
        <select name="education" id="education" [(ngModel)]="model.education" (ngModelChange)="getResumeList()" autocomplete="off">
            <option class="place-text" value='' disabled hidden selected="selected">选择学历</option>
            <option class="place-text" value=''>不限</option>
            <option *ngFor="let a of educationList" [value]="a.code">{{a.text}}</option>
        </select>
    </div>
    <!-- 中间部分 -->
    <!-- 没有内容存在时 -->
    <!--<div  class="none-block">-->
        <!--<p class="none_tip">申请本校合作通过，才可查看生源信息</p>-->
        <!--<span class="none_btn" (click)="goCollegeIdentity()">立即申请合作</span><br/>-->
        <!--<img src="assets/images/resume-search-fail.png"><br/>-->
        <!--暂未找到生源信息，请稍后重试-->
    <!--</div>-->
    <div  class="college-resume-cont">
        <ul>
            <li>头像</li>
            <li>姓名</li>
            <li>性别</li>
            <li>年龄</li>
            <li>学校</li>
            <li>专业</li>
            <li>学历</li>
            <li>技能标签</li>
            <!--<li>匹配度</li>-->
            <!--<li>在线状态</li>-->
            <li style="width: 160px">操作</li>
        </ul>
        <div>
            <ul class="resume-li" *ngFor="let n of resumeList;let i = index;">
                <li>
                    <!-- <img *ngIf="n.logo" [src]="n.logo+'&imageView2/1/w/100/h/100'"><img *ngIf="!n.logo" src="assets/images/none.png"> -->
                    <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}">
                    <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png">
                    <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png">
                    <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png">
                </li>
                <li><span *ngIf='n.talent_name'>{{n.talent_name}}</span><span *ngIf='!n.talent_name'>暂无</span></li>
                <li><span *ngIf='n.gender_text'>{{n.gender_text}}</span><span *ngIf='!n.gender_text'>暂无</span></li>
                <li><span *ngIf='n.age'>{{n.age}}</span><span *ngIf='!n.age'>暂无</span></li>
                <li><span *ngIf='n.graduate_university'>{{n.graduate_university}}</span><span *ngIf='!n.graduate_university'>暂无</span></li>
                <li><span *ngIf='n.major_text'>{{n.major_text}}</span><span *ngIf='!n.major_text'>暂无</span></li>
                <li><span *ngIf='n.education_text'>{{n.education_text}}</span><span *ngIf='!n.education_text'>暂无</span></li>
                <li>
                    <span *ngIf='n.skills.length>0'>
                        <i *ngFor="let skill of n.skills">{{skill.text}}</i>
                    </span>
                    <span *ngIf='n.skills.length==0'>暂无</span>
                </li>
                <!--<li><span *ngIf='n.matching'>{{n.matching}}%</span><span *ngIf='!n.matching'>暂无</span></li>-->
                <!--<li><span *ngIf='n.last_online_time'>{{n.last_online_time | onlineTime}}</span><span *ngIf='!n.last_online_time'>暂无</span></li>-->
                <li style="width: 160px">
                    <span (click)="goDetails(n.resume_number)">查看简历</span>
                    <!-- <span (click)="goChat(n,modelPosition.positionid)">立即沟通</span> -->
                    <!--<span (click)="goChat(n,'')">{{n.jm_username?'继续沟通':'立即沟通'}}</span>-->
                    <span (click)="goCollect(n)">{{n.isfollow_reusme?'取消收藏':'收藏'}}</span>
                    <!-- <span>分享</span> -->
                </li>
            </ul>
        </div>
    </div>
  <!-- 分页部分 -->
  <div class='paging-block' [class.isPageHeight]="countList>0">
      <router-outlet></router-outlet>
      <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
  </div>
</div>
