<!-- 页头部分 -->
<div class="contentRight-header"><img (click)="opera.goNewRouter('company-details')" src='assets/images/router-old.png'/>企业中心 - 公司信息 - 地址管理</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <router-outlet></router-outlet>
    <app-model-address-add #address [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-address-add>
    <div class="college-block">
        <!-- 页头部分 -->
        <div class="college-block-head">
          公司地址列表
        </div>
        <!-- 操作部分 <img src="assets/images/select-img.png">-->
        <div class="college-block-opera clear">
          <div class="left">
              <button class="purple" (click)="goAddAddress()">新增地址</button>
          </div> 
        </div>
        <!-- 没有内容存在时 -->
        <div *ngIf="locations.length==0" class="none-block">
            <img src="assets/images/resume-search-fail.png"><br/>
            暂未添加企业地址，请及时添加
        </div>
        <!-- 循环列表 -->
        <div class="college-list-cont" *ngIf="locations.length>0">
            <ul>
                <li>省份</li>
                <li>市/地区</li>
                <li>县/区</li>
                <li>详细地址</li>
                <li>操作</li>
            </ul>
            <!-- *ngFor="let n of collegeList;let i = index;" -->
            <div>
                <ul class="college-li" *ngFor="let n of locations;let i = index;">
                    <li><span>{{n.region.slice(0,2) | codetransform:config.region}}</span></li>
                    <li><span>{{n.region.slice(0,4) | codetransform:config.region}}</span></li>
                    <li><span>{{n.region | codetransform:config.region}}</span></li>
                    <li><span *ngIf='n.address'>{{n.address}}</span><span *ngIf='!n.address'>暂无</span></li>
                    <li>
                        <span (click)="goAddress(n.lid)">修改</span>
                        <span (click)="addressDelete(n.lid,i)">删除</span>
                        <span *ngIf="!n.is_default" (click)="myisactive(n)">设为默认</span>
                        <span *ngIf="n.is_default" (click)="myisactive(n)">取消默认</span>
                    </li> 
                </ul>
            </div>
        </div>
    </div> 

</div>
