import { Component, OnInit, Input, Output } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import {  ActivatedRoute,Router } from  '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-position-resume',
  templateUrl: './position-resume.component.html',
  styleUrls: ['./position-resume.component.css']
})
export class PositionResumeComponent implements OnInit {
  public config: Config;
  public isShow =false;

  @Output() modelShow = true;//是否打开弹窗(简历收件箱)
  @Output() modelShowStatus = false;//是否打开弹窗(简历收藏)
  @Output() status = 1;//当前认证状态
  public type="delivery";//当前导航页
  constructor(public dataApi: DataApi,
    public local:Local,

  private sanitizer: DomSanitizer,
    private router: Router,
    public opera:Opera,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
     }

  ngOnInit() {
    this.opera.initConfig('position','position-resume');
  }

  //去简历收件箱
  goPositionResume(){
    this.modelShow = true;
    this.modelShowStatus = false;
    this.type = "delivery";
  }
  //去简历收藏
  goCollectionResume(){
    this.modelShow = false;
    this.modelShowStatus = true;
    this.type = "collection";
  }

  showDoenLoadAPP(){
    this.isShow = true;
  }
  cloceDownLoad(){
    console.log("cloceDownLoad");
    this.isShow= false;
  }
  gotoPro(){
    if(this.local.get('accesstoken')) {
      let token = this.local.get('accesstoken');
      let accountid = this.local.get('accountid');
      let collegeid = this.local.get('collegeid');
      window.open(this.config.JumpMainWeb+token+'&accountid='+accountid+'&route='+'position-resume','_blank')
    }
  }


}
