<!-- 修改手机号 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
  <div class="black-bg" (click)="dismiss(false)"></div>
  <div class="model-cont">
      <!-- 上层提示部分 -->
      <div class="model-head clear">
          <div class="left">修改手机号</div>
          <button class="right" (click)="dismiss(false)">×</button>
      </div>
      <!-- 中间内容部分 -->
      <div class="model-content">
          <!-- 原手机号 -->
          <div class="sign-list">
              <span>原手机号：</span><div class="selected-text disabled-text">{{userMobile}}</div>
          </div>
          <!-- 新手机号 -->
          <div class="sign-list">
              <span>新手机号：</span><input type="text" [(ngModel)]="model.mobile" oninput="if(value.length>11)value=value.slice(0,11)" placeholder="请输入常用的手机号">
          </div>
          <!-- 验证码 -->
          <div class="sign-list sign-code">
              <span> 验证码：</span><input type="text" [(ngModel)]="model.vcode_text" placeholder="请输入短信验证码">
              <button class="purple" (click)="postMobileSmsVcode()" *ngIf="!isSendOver && !countDown">获取验证码</button>
              <button class="purple" (click)="postMobileSmsVcode()" *ngIf="isSendOver && !countDown">重新发送</button>
              <button class="purple" (click)="postMobileSmsVcode()" *ngIf="countDown">{{countDown}}</button>
              <s class="remark-text">* 修改后，请使用新手机号登录工作啦</s>
          </div>
      </div>
      <!-- 底部按钮 -->
      <div class="select-submit"><button class="purple" (click)="getMobileChange()">确定</button></div>
  </div>
</div>