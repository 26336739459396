import { Component, OnInit, Input, Output } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from  '@angular/router';
import {ConfirmComponent} from "../confirm/confirm.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-position-manage',
  templateUrl: './position-manage.component.html',
  styleUrls: ['./position-manage.component.css']
})
export class PositionManageComponent implements OnInit {
  public showFailModel = false;
  public colleges_cooperation:any = {};
  public config: Config;
  public keywordList=[];//搜索关键词匹配列表
  public hotCity=[];//热门城市
  public positionList = [];//职位列表
  public searchModel = '';//搜索关键词
   // 筛选条件
  public model={
    status:'',
    name:'',
    offset:0,
    limit:9,
    collegeid:'',
  }
  public countList = 0;//列表数量
  public isShowPublishVip = false;
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public opera:Opera,
    public dialog: MatDialog,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    activatedRoute.queryParams.subscribe(queryParams => {
      if(queryParams.isShow){
        this.isShowPublishVip = true
      }
    })
    }

  ngOnInit() {
    this.opera.initConfig('position','position-manage');
    this.model.collegeid = this.local.get('collegeid');
    this.getPositionList();//获取职位列表
    this.singleIdentity();
  }
   //搜索
   goSearch(data){
      this.model.name=data;
      this.getPositionList();//获取职位列表
      this.keywordList=[];
   }
  goRouter(){
    if(this.local.get('accesstoken'))
    {
      let token = this.local.get('accesstoken');
      let accountid = this.local.get('accountid');
      let collegeid = this.local.get('collegeid');
      this.isShowPublishVip =false;
      window.open(this.config.JumpMainWeb+token+'&accountid='+accountid+'&route='+'position-manage','_blank')
    }
  }
  closeX(){
    this.isShowPublishVip = false;
  }
  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      that.goSearch(that.model.name);
    }else{
      that.getItems();
    }
  }
  //筛选函数
  getItems() {
    let filter=/^[a-zA-Z\u4e00-\u9fa5]+$/;
    if(this.model.name){
      if(filter.test(this.model.name)){
      // this.isSearch = true;
      this.dataApi.positionNameMatch(this.model.name).then((res:any)=>{
      //  //console.log("匹配职位名称");
      //  //console.log(res);
       this.keywordList =res;
     }).catch((err)=>{
       //console.log(err);
     });
   }else{
     let n=String(this.model.name).length-1;
     this.model.name=this.model.name.slice(0,n);
   }
 }else{
     this.keywordList = [];
    //  this.isSearch = false;
   }
 }

  //发布职位
  goPublish(){
    let d;
    d = this.dataApi.getLocalDepartment();
    if(d.quota.position==0){
      this.opera.loading();
      this.dataApi.getDepartment().then((res:any)=>{
        this.opera.loadiss();
        this.dataApi.setLocalDepartment(res);
        if(res.quota.position==0){
          this.toastr.warning("发布职位数量已达上限");
          return;
        }else{
          this.local.remove(Constants.positionid);
          this.router.navigate(['home/position-publish']);
        }
      }).catch((error) => {
        this.opera.loadiss();
        this.toastr.error(error);
      });
    }else{
      this.local.remove(Constants.positionid);
      this.router.navigate(['home/position-publish']);
    }
  }

  alertFil(){
    this.showFailModel = true;
  }

  //查看详情
  goDeatils(id){
    this.local.remove(Constants.positionid);
    this.router.navigate(['home/position-publish'],{
      queryParams:{
        id:id
      }
    });
  }

  //获取职位列表
  getPositionList(){
    this.opera.loading();
    this.dataApi.getPositionList(this.model).then((res:any)=>{
      // console.log("获取职位列表-----",res.list);
      //console.log(res);
      if(res)
      {
        this.positionList =  res.list;
        this.countList=res.count;
        this.pageCount =Math.ceil(res.count/9);
        //刷新职位列表时更新本地存储
        this.config.positionList=[];
        for(let val of res.list){
          if(val.position_status=="release"){
            this.config.positionList.push(val);
          }
        }
        this.dataApi.setLocalpositionList(this.config.positionList);//在期职位列表本地存储
      }
      this.opera.loadiss();
    }).catch((error) => {
      this.toastr.error(error);
      this.opera.loadiss();
    });
  }

   //职位下线
   goExpired(n){
    this.dataApi.managePositionExpired(n.positionid).then((res:any)=>{
      //console.log(res);
      setTimeout(() => {
        this.toastr.success('下线成功');
      },600);
      n.position_status='stop';
      this.opera.setPositionListByExpired(n.positionid);//更新在期职位本地存储
    }).catch((error) => {
      //console.log(error);
      setTimeout(() => {
        this.toastr.error(error);
      },600);

    });
  }


  //职位重发检查剩余可发布职位数量
 goResend(n){
    let d;
    d = this.dataApi.getLocalDepartment();
    if(d.quota.position==0){
      this.opera.loading();
      this.dataApi.getDepartment().then((res:any)=>{
        this.opera.loadiss();
        this.dataApi.setLocalDepartment(res);
        if(res.quota.position==0){
          this.toastr.warning("发布职位数量已达上限");
          return;
        }else{
          this.goResendPosition(n);
        }
      }).catch((error) => {
        this.opera.loadiss();
        this.toastr.error(error);
      });
    }else{
      this.goResendPosition(n);
    }
  }

  //职位重发
  goResendPosition(n){
    this.dataApi.managePositionResend(n.positionid).then((res:any)=>{
      //console.log(res);
      setTimeout(() => {
        this.toastr.success('重发成功');
      },600);
      n.position_status='release';
      this.opera.setPositionListByRelease();//更新在期职位本地存储
    }).catch((error) => {
      //console.log(error);
      setTimeout(() => {
        if(error.Errcode=='2005'){//配额超限
          this.toastr.warning('发布职位数量已达上限');
        }else{
          this.toastr.error(error);
        }
      },600);
    });
  }

  
  //删除职位
  deletePosition(n){

    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: this.positionList.length == 1 ? '当前只有一个职位，删除后将无法为你匹配学生' : '删除职位后将不再匹配对位学生，确定删除吗？',
        trueText:'确定',
        falseText:'取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      //console.log(isConfirmed,'点击确定');
      if(isConfirmed){
        this.dataApi.deletePosition(n.positionid).then((res:any)=>{
          //console.log(res);
          setTimeout(() => {
            this.toastr.success('删除成功');
          },600);
          this.getPositionList();
        }).catch((error) => {
          //console.log(error);
          setTimeout(() => {
            this.toastr.error(error);
          },600);

        });
      }

    });

  }

  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数----');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getPositionList();//获取列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getPositionList();//获取列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getPositionList();//获取列表
      }
    }

  }
  gotoPro(){
    if(this.local.get('accesstoken')) {
      let token = this.local.get('accesstoken');
      let accountid = this.local.get('accountid');
      let collegeid = this.local.get('collegeid');
      window.open(this.config.JumpMainWeb+token+'&accountid='+accountid+'&route='+'position-manage','_blank')
    }
  }

  //单个高校认证信息
  singleIdentity(){
    let collegeid = this.local.get('collegeid');
    this.dataApi.lastCollegeAuthenticationIdentity(collegeid).then((res:any)=>{
      if(res){
        console.log("获取上次认证信息",res);
        this.colleges_cooperation = res;
      }
    }).catch((error)=>{
      console.log("获取上次认证信息error",error);
    })

  }

  //关闭申请高校合作提示
  closeFailModel(){
    this.showFailModel = false;
  }

  goCooperation(){
   // this.router.navigateByUrl("home/position-manage");//审核中
    this.router.navigate(['home/collegeidentity']);
  }

}
