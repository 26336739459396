import { Component, OnInit,Input, Output,ViewChild } from '@angular/core';
import { DataApi,Local,Opera,Constants,Config } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { ModelPositionNoticeComponent } from '../model-position-notice/model-position-notice.component';
import { ModelPositionHiredComponent } from '../model-position-hired/model-position-hired.component';
@Component({
  selector: 'app-position-notice',
  templateUrl: './position-notice.component.html',
  styleUrls: ['./position-notice.component.css']
})
export class PositionNoticeComponent implements OnInit {
  @ViewChild('noticeList', { static: true }) child: ModelPositionNoticeComponent;
  @ViewChild('hiredList', { static: true }) child1: ModelPositionHiredComponent;
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelTwoShow = false;//是否打开弹窗
  public config: Config;
  public positionList=[];//职位列表
  public educationList=[];//学历列表
  public keywordList=[];//搜索关键词匹配列表
  public isShow=false;
  // 筛选条件--搜索
  public model={
    college_name:'',//关键词
    stage:'',//面试阶段 first:初试 reexamine：复试
    major:'',//专业
    education:'',//学历
    status:'',//状态0：未开始 1：已结束
    feedback_status:'',//反馈结果
    positionid:'',//职位id
    limit:9,
    offset:0
  }
  public status={
    feedback_status:''//反馈状态 posted：已投递viewed：已被查看interested：合适 improper：不合适
  }
  public searchModel = '';//搜索关键词
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  public majorOne='';//专业类别一级
  public resumeList = [];//简历列表
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public isShowFilter = false;
  public interviewid="";//面试邀请id
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
     }

  ngOnInit() {
    this.opera.initConfig('position','position-notice');
    this.getPositionList();//获取职位列表数据
    this.getMajorList();//获取专业列表信息
    this.getEducationList();//获取学历列表信息
    this.getResumeList();//获取列表
    this.getCollegeCode();//获取高校字典
  }

  //筛选
  goSearch(text){
    this.model.college_name = text;
    this.getResumeList();//获取列表
    this.keywordList=[];
  }

   //获取高校字典
   getCollegeCode(){
    // 获取高校字典数据
    if(this.config.school.length<1){
     this.dataApi.getDictDate('school').then((res:any)=>{
       // //console.log(res);
         if(res)
         {
             this.config.school=res;
         }
     }).catch((err)=>{
       // this.toastr.error("网络错误,请稍后重试");
       //console.log(err);
     })
   }
 }

  //匹配关键字列表
  getItems(){
    this.keywordList=[];
    if(!this.model.college_name){return;}
    for(let s of this.config.school){
      if(s.text.indexOf(this.model.college_name) >= 0){
         this.keywordList.push(s);
      }
    }
  }
  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      that.goSearch(that.model.college_name);
    }else{
      that.getItems();
    }
  }

  //去面试邀请
  goNotice(id,talentid){
    this.modelShow=true;
    this.opera.scrollTop();
    this.interviewid=id;
    this.child.initData(id,false,talentid);
  }
  //接收子组件返回参数
  Modeldismiss(data){
    this.modelShow = false;
    this.opera.scrollTrue();
    if(data){
      //console.log("面试邀请返回信息-----position-notice");
      //console.log(data);
      if(data=='hired'){
        this.local.remove(Constants.isByOffer);
        this.goHire();
        return;
      }
      this.model.limit=0;
      this.getResumeList();//获取列表
    }
  }

  //打开签约通知
  goHire(){
    this.modelTwoShow=true;
    this.opera.scrollTop();
    this.child1.initData(false,false,this.interviewid);//测试数据
  }

  //接收子组件返回数据
  ModelTwodismiss(event){
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    if(event){
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      this.model.limit=0;
      this.getResumeList();//获取列表
    }
  }

  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----college-resume');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getResumeList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }

  }

  //获取简历列表
  getResumeList(){
    this.keywordList=[];
    this.opera.loading();
    this.dataApi.getNoticeList(this.model).then((res:any)=>{
      //console.log("获取列表--------position-notice");
      //console.log(res);
        if(res)
        {
            this.resumeList = res.list;
            this.countList=res.count;
            this.pageCount =Math.ceil(res.count/9);
        }

      this.opera.loadiss();
    }).catch((err)=>{
      //console.log(err);
      this.opera.loadiss();
    })
  }


  //监听majorOne
  majorOneChange(){
    // //console.log("第一级专业值");
    // //console.log(this.majorOne);
    // //console.log("专业列表");
    // //console.log(this.config.major);
    this.majorListTwo=[];
    for(let n of this.config.major){
      if(n.parentcode==this.majorOne){
        this.majorListTwo.push(n);
      }
    }
    // //console.log("第二级专业列表");
    // //console.log(this.majorListTwo);
  }


  //提示先选一级专业
isShowTwo(){
  if(!this.majorOne){
    this.toastr.warning("请先选择一级专业");
    return;
  }
 }


  //获取学历列表信息
  getEducationList(){
    if(this.dataApi.getEducationCode()){
      //console.log("获取学历列表本地存储-----college-resume");
      //console.log(this.dataApi.getEducationCode());
      this.educationList = this.dataApi.getEducationCode();
    }else{
      this.dataApi.getDictDate('education').then((res:any)=>{
        //console.log("获取学历列表-----college-resume");
        //console.log(res);
          if(res)
          {
              this.educationList = res;
              this.dataApi.setEducationCode(res);
          }

      }).catch((error)=>{
        //console.log(error);
      });
    }
  }

  //获取一级专业列表
  getMajorListOne(){
    for(let n of this.config.major){
      if(n.level==2){
        this.majorListOne.push(n);
      }
    }
  }

  //获取专业列表信息
  getMajorList(){
    if(this.dataApi.getMajorCode()){
      this.config.major = this.dataApi.getMajorCode();
      this.getMajorListOne();//获取一级专业列表
    }else{
      this.dataApi.getDictDate('major').then((res:any)=>{
        //console.log("获取专业列表-----college-resume");
        //console.log(res);
          if(res){
              this.config.major = res;
              this.getMajorListOne();//获取一级专业列表
              this.dataApi.setMajorCode(res);
          }

      }).catch((error)=>{
        //console.log(error);
      });
    }
  }


   //获取职位列表数据
  getPositionList(){
    //console.log("在期职位本地存储----");
    //console.log(this.dataApi.getLocalpositionList());
    if(this.dataApi.getLocalpositionList()){
      this.config.positionList=this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
      this.positionList =  this.config.positionList;
    }else{
      this.getNewList();
    }
  }
  //-------获取线上数据
  getNewList(){
    let model={
      limit:100,
      offset:0,
      status:'release',
    }
    this.dataApi.getPositionList(model).then((res:any)=>{
       //console.log("在期职位获取线上数据-----college-resume");
       //console.log(res);
        if(res)
        {
            this.positionList =  res.list;
            this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
        }

     }).catch((error) => {
       this.toastr.error(error);
     });
  }

  //-----跳转简历详情
  goDetails(n){
    this.config.isNavActive='college';
    this.config.isNavChildActive='college-resume';
    this.router.navigate(['home/college-resumedetails'], {
      queryParams: {
        resumeid:n,
        positionid:this.model.positionid
      }
    });
  }

  //立即沟通
  goChat(n,id){

    this.isShow=true;
    return;
    // this.opera.goChat(n,id);
  }
  cloceDownLoad(){
    console.log("cloceDownLoad");
    this.isShow= false;

  }

}
