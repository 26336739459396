<!-- 页头部分 -->
<div class="contentRight-header"><img (click)="opera.goChangeRouter('company-identity')" src='assets/images/router-old.png'/>企业中心 - 高校合作申请 - 提交申请资料</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
   <div class="company-block">


       <div style="width: 1000px;margin: 0 auto;height: 120px" *ngIf="config.departmentSelf">
           <div class="guwen_bg_gray">
               <img class="guwen_info_huizhang" src="../../assets/images/kefu_hui.png">

               <img class="guwen_head" src="{{config.departmentSelf.avatar}}">
               <div class="guwen_infoDiv">
                   <ul>
                       <li><img style="width: 120px;height: 20px" src="../../assets/images/guwen_title.png"></li>
                       <li><span class="guwen_info_name">{{config.departmentSelf.name}}</span></li>
                       <li>
                           <img style="width: 17px;height: 17px" src="../../assets/images/kefu_wx.png">
                           <span class="guwen_info_title">微信：</span>
                           <span class="guwen_info_wx">{{config.departmentSelf.wx}}</span>
                       </li>
                       <li style="width: 250px">
                           <img style="width: 17px;height: 17px" src="../../assets/images/kefu_email.png">
                           <span class="guwen_info_title">邮箱：</span>
                           <span class="guwen_info_wx">{{config.departmentSelf.email}}</span>
                       </li>
                   </ul>
               </div>
               <img class="guwen_head_code" style="margin-left: 5px;" src="{{config.departmentSelf.qr_code}}">
               <div class="guwen_info_Line"></div>
               <span class="guwen_info_rightText">双选会/宣讲会申请相关问题， 请扫码咨询！</span>
           </div>
       </div>



     <!-- 中间部分 -->
     <div class="info-block">
      <div *ngIf="collegeName&&orgList.length==0" class="identityCollege-name">当前申请高校：{{collegeName}}</div>
      <div *ngIf="orgList.length>0" class="identityCollege-name">当前申请院系：
        <select name="CIOrg" id="CIOrg" [(ngModel)]="model.facultyid" autocomplete="off">
          <option class="place-text" value='' disabled selected="selected">请选择院系</option>
          <option *ngFor="let a of orgList" [value]="a.facultyid">{{a.name}}</option>
        </select>
      </div>
      <!-- 营业执照 -->
      <div class="info-list info-logo">
        <div class="info-head">第一步&nbsp;&nbsp;营业执照/企业资质</div>
        <label *ngIf="model.business_licence" class="uploadImg-label department-logo" for="uploadLoginAvatar">
          <img [src]="model.business_licence">
        </label>
        <label *ngIf="!model.business_licence" class="uploadImg-label" for="uploadLoginAvatar">
          <img src="assets/images/add-img.png"><br/>点击上传&nbsp;&nbsp;营业执照/企业资质
        </label>
        <input class="uploadImg" id="uploadLoginAvatar" (change)="previewPic($event,'business_licence')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
        <p style="position: absolute;margin-left: 70px;margin-top: 10px;color: red;height: 50px;padding-bottom: 20px">注：属于培训学校的企业，需要上传办学许可证</p>

        <ul>
          <li>1. 上传营业执照名称必须与公司全称保持一致</li>
          <li>2. 请提供有效年检期内的《企业法人营业执照》副本</li>
          <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
          <li>4. 高校就业老师将会在 1 -3个工作日内审核</li>
          <li>5. 企业资质是指：有的公司没有营业执照，可以根据<br>
            &nbsp;&nbsp;&nbsp;&nbsp;不同行业上传不同的证件；如：基金会等公益组织，<br>
            &nbsp;&nbsp;&nbsp;&nbsp;上传法人登记证书等。</li>

          <li style="margin-top: 10px">
            <!--<input name="attachment" value='Brows' placeholder="+上传办学许可证" style="width: 100px;height:40px;background-color: lightgray;padding: 5px 15px;margin-top: 10px" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">-->

            <div style="width: 150px;height: 25px;background-color: lightgray">
              <label style="width: 150px;height: 25px;background-color: lightgray;margin-top: -25px;margin-left: 0px;">+&nbsp;上传办学许可证
                <input id="uploadLoginbanXue" (change)="previewPic($event,'school_permit')" style="width: 150px;height: 25px;background-color: lightgray" type="file" accept="image/jpg,image/png,image/jpeg,image/bmp" style="display:none;">
              </label>
            </div>

          </li>
        </ul>
      </div>
       <label *ngIf="model.school_permit"  for="uploadLoginbanXue">
         <img style="width:245px;height: 260px;margin-left: 71px;margin-top: 35px;" [src]="model.school_permit">
       </label>

      <!-- 营业执照 -->
      <div class="info-list info-logo">
        <div class="info-head">第二步&nbsp;&nbsp;招聘者身份证</div>
        <label *ngIf="model.id_card_front" class="uploadImg-label department-logo" for="uploadGuideCard">
          <img [src]="model.id_card_front">
        </label>
        <label *ngIf="!model.id_card_front" class="uploadImg-label" for="uploadGuideCard">
          <img src="assets/images/add-img.png"><br/>点击上传手持身份证
        </label>
        <input class="uploadImg" id="uploadGuideCard" (change)="previewPic($event,'id_card_front')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
        <ul>
          <li>1. 上传身份证姓名必须与招聘联系人姓名保持一致</li>
          <li>2. 请提供有效期内的本人手持《身份证》照片</li>
          <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
          <li>4. 高校就业老师将会在 1 -3个工作日内审核</li>
        </ul>
      </div>
      <!-- 招聘委托书 -->
      <div *ngIf="secureMaterail" class="info-list info-logo">
        <div class="info-head">第三步&nbsp;&nbsp;招聘委托书</div>
        <label *ngIf="model.proxy_document" class="uploadImg-label department-logo" for="uploadProxy">
          <img [src]="model.proxy_document">
        </label>
        <label *ngIf="!model.proxy_document" class="uploadImg-label" for="uploadProxy">
          <img src="assets/images/add-img.png"><br/>点击上传招聘委托书
        </label>
        <input class="uploadImg" id="uploadProxy" (change)="previewPic($event,'proxy_document')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
        <ul>
          <li>1. 点击下载：<a [href]="secureMaterail">《招聘委托书示例》</a></li>
          <li>2. 修改后请加盖公章，截图或拍照上传</li>
        </ul>
      </div>
      <!-- 统一社会信用代码 -->
      <div class="info-list info-number">
          <div *ngIf="!secureMaterail" class="info-head">第三步&nbsp;&nbsp;统一社会信用代码</div>
          <div *ngIf="secureMaterail" class="info-head">第四步&nbsp;&nbsp;统一社会信用代码</div>
          <input type="text" placeholder="请填写营业执照统一社会信用代码" [(ngModel)]="model.register_number" maxlength="18">
          <ul>
            <li>1. 填写的统一社会信用代码必须与营业执照保持一致</li>
            <li>2. 高校就业老师将会在 1 -3个工作日内审核</li>
          </ul>
      </div>
       <!-- 办公电话 -->
       <div class="info-lis" *ngIf="authcation_office_phone">
          <div *ngIf="!secureMaterail" class="info-head">第四步&nbsp;&nbsp;办公电话</div>
          <div *ngIf="secureMaterail" class="info-head">第五步&nbsp;&nbsp;办公电话</div>
          <input type="text" [(ngModel)]="model.office_phone" (blur)="checkPhone(model.office_phone)" placeholder="请填写固话" class="info-phone">
          <!-- <b *ngIf="!model.office_phone"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b> -->
        </div>
      <!-- 申请简述 -->
      <div class="info-list">
        <div *ngIf="!secureMaterail&&!authcation_office_phone" class="info-head">第四步&nbsp;&nbsp;申请简述</div>
        <div *ngIf="secureMaterail&&!authcation_office_phone" class="info-head">第五步&nbsp;&nbsp;申请简述</div>
        <div *ngIf="!secureMaterail&&authcation_office_phone" class="info-head">第五步&nbsp;&nbsp;申请简述</div>
        <div *ngIf="secureMaterail&&authcation_office_phone" class="info-head">第六步&nbsp;&nbsp;申请简述</div>
      <div class="list-brief">
          <textarea placeholder="请简述申请校招的目的(最多100字)" scrollHeight [(ngModel)]="model.brief" minlength="0" maxlength="100" >{{model.brief}}</textarea>

      </div>
    </div>
      <!-- 按钮 -->
      <div class="block-button">
        <button (click)="uploadDepartmentIdentity()">提交</button>
      </div>
    </div>
  </div>
  <!--<p-dialog [(visible)]="isShowDownLoadAPP" [width]="300" [height]="330" [minHeight]="330" [modal]=true>-->
    <!--<p-header>下载APP <img (click)="cloceDownLoad()"  style="width: 15px;height: 15px;float: right;margin-top: 5px" src="../../assets/images/closeX.png"/></p-header>-->
    <!--<img  style="width: 200px;height: 200px;margin-left: 25px" src="../../assets/images/goworklaQRcode.png"/>-->
    <!--<p style="text-align: center;line-height: 15px;margin: 0px">下载APP进行查看</p>-->
  <!--</p-dialog>-->


</div>
