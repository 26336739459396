<div class="college-block" *ngIf="isShow">
    <!-- 头部 -->
    <div class="college-resume-head clear">
        <div class="right">
           <!-- 选择所在城市[(ngModel)]="" -->
           <span>筛选</span>
           <select name="major" id="major" [(ngModel)]="majorOne" (ngModelChange)="majorOneChange()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择一级专业</option>
                <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
            </select>
            <select name="majorTwo" id="majorTwo" [(ngModel)]="model.major" (ngModelChange)="getSendResumeList()" (click)="isShowTwo()" autocomplete="off">
                    <option class="place-text" value='' disabled hidden selected="selected">选择二级专业</option>
                    <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
            </select>
            <select name="education" id="education" [(ngModel)]="model.education" (ngModelChange)="getSendResumeList()" autocomplete="off">
                    <option class="place-text" value='' disabled hidden selected="selected">选择学历</option>
                    <option *ngFor="let a of educationList" [value]="a.code">{{a.text}}</option>
            </select>
            <!-- 搜索筛选 -->
            <!-- <div class="search-input right">
                <input type="text" placeholder="请输入学校名称" [(ngModel)]="model.keywords" (keyup)="inputKeyup($event)"><span (click)="goSearch(model.keywords)"><img src="assets/images/search.png"></span>

                <div class="keyup-block" *ngIf="keywordList.length>0">
                    <div class="keyup-list" *ngFor = "let key of keywordList" (click)="goSearch(key.text)">{{key.text}}</div>
                </div>
            </div> -->
        </div>
   </div>
   <!-- 中间部分 -->
   <!-- 没有内容存在时 -->
   <div *ngIf="countList==0" class="none-block">
       <img src="assets/images/resume-search-fail.png"><br/>
       暂未找到相关简历

   </div>
   <div *ngIf="countList>0" class="college-resume-cont">
       <ul>
           <li>头像</li>
           <li>姓名</li>
           <li>性别</li>
           <li>年龄</li>
           <li>最后毕业学校</li>
           <li>专业</li>
           <li>学历</li>
           <li>操作</li>
       </ul>
       <div>
           <ul class="resume-li" *ngFor="let n of resumeList;let i = index;">
               <li>
                  <!-- <img *ngIf="n.logo" [src]="n.logo+'&imageView2/1/w/100/h/100'"><img *ngIf="!n.logo" src="assets/images/none.png"> -->
                  <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}">
                  <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png">
                  <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png">
                  <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png">
               </li>
               <li><span *ngIf='n.talent_name'>{{n.talent_name}}</span><span *ngIf='!n.talent_name'>暂无</span></li>
               <li><span *ngIf='n.gender_text'>{{n.gender_text}}</span><span *ngIf='!n.gender_text'>暂无</span></li>
               <li><span *ngIf='n.age'>{{n.age}}</span><span *ngIf='!n.age'>暂无</span></li>
               <li><span *ngIf='n.graduate_university'>{{n.graduate_university}}</span><span *ngIf='!n.graduate_university'>暂无</span></li>
               <li><span *ngIf='n.major_text'>{{n.major_text}}</span><span *ngIf='!n.major_text'>暂无</span></li>
               <li><span *ngIf='n.education_text'>{{n.education_text}}</span><span *ngIf='!n.education_text'>暂无</span></li>
               <li>
                   <span (click)="goDetails(n.resume_number)">查看简历</span>
                   <span (click)="goCollect(n)">{{n.isfollow?'取消收藏':'收藏'}}</span>
                   <!-- <span>分享</span> -->
               </li>
           </ul>
       </div>
   </div>
   <!-- 分页部分 -->
   <div class='paging-block' [class.isPageHeight]="countList>0">
      <router-outlet></router-outlet>
      <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
   </div>
 </div>

