<!-- 引导页面-->
<script type="text/javascript" src="../../assets/js/jquery-3.1.0.min.js"></script>

<app-model-select-more #child2 [isShow]="modelTwoShow" (onVoted)="ModelTwodismiss($event)"></app-model-select-more>
<!--<app-model-select-two #child3 [isShow]="modelThreeShow" (onVoted)="ModelThreedismiss($event)"></app-model-select-two>-->
<app-model-still-more #child1 [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-still-more>
<!--<div class="contentRight-header" (click)="goBackStep()"><img  src='assets/images/router-old.png'/>返回</div>-->
<div class="contentRight-header flex_r">
    <span class="cancelRegister_span flex_r" (click)="cancelRegisterChips()"> <img style="padding: 0" src="../../assets/images/login_close.png">退出</span>
</div>

<div class="guide-page">
    <!-- 头部 -->
    <!--<div class="cancelRegister"><span (click)="cancelRegisterChips()">退出</span></div>-->

    <div class="guide-head">
        <ul>
            <li>
                <span><img src="assets/images/guide-ok.png"></span>
                <br/>公司信息
            </li>
            <li>
                <span><img src="assets/images/guide-ok.png"></span>
                <br/>招聘人信息
            </li>
            <li>
                <!-- <span><img src="assets/images/guide-ok.png"></span> -->
                <span class="isHeadActive">3</span>
                <br/>申请高校合作
            </li>
        </ul>
    </div>


    <!-- 中间部分 -->
    <div class="info-block">
        <!-- 中间部分 -->
        <!-- <div *ngIf="collegeName" class="identityCollege-name">当前认证高校：{{collegeName}}</div>  -->
        <div *ngIf="collegeName&&orgList.length==0" class="identityCollege-name">当前申请高校：{{collegeName}}</div>
        <div *ngIf="orgList.length>0" class="identityCollege-name">当前申请院系：
            <select name="CIOrg" id="CIOrg" [(ngModel)]="model.facultyid" autocomplete="off">
          <option class="place-text" value='' disabled selected="selected">请选择院系</option>
          <option *ngFor="let a of orgList" [value]="a.facultyid">{{a.name}}</option>
        </select>
        </div>
        <p>以下必填项为高校要求，请详细完善信息,否则影响高校校招合作</p>
        <!-- 营业执照 -->
        <div class="info-list info-logo">
            <div class="info-head">第一步&nbsp;&nbsp;企业有效证照(如营业执照、商业登记证等)</div>
            <label *ngIf="model.business_licence" class="uploadImg-label department-logo" for="uploadLoginAvatar">
        <img [src]="model.business_licence">
      </label>
            <label *ngIf="!model.business_licence" class="uploadImg-label" for="uploadLoginAvatar">
        <img src="assets/images/add-img.png"><br/>点击上传&nbsp;&nbsp;<span >企业有效证照</span>
      </label>
            <input class="uploadImg" id="uploadLoginAvatar" (change)="previewPic($event,'business_licence')" type="file" accept="image/jpeg,image/jpg,image/png">
            <!--<p style="position: absolute;margin-left: 70px;margin-top: 10px;color: red;height: 50px;padding-bottom: 20px">注：属于培训学校的企业，需要上传办学许可证</p>-->
            <ul>
                <li>1. 上传<span *ngIf="isMainland">企业有效证照</span><span *ngIf="!isMainland">企业有效证照</span>名称必须与公司全称保持一致</li>
                <li>2. 请提供有效年检期内的《企业有效证照》副本</li>
                <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                <li>4. 高校就业中心将会尽快审核</li>
                <!--<li style="margin-top: 10px">-->
                <!--&lt;!&ndash;<input name="attachment" value='Brows' placeholder="+上传办学许可证" style="width: 100px;height:40px;background-color: lightgray;padding: 5px 15px;margin-top: 10px" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">&ndash;&gt;-->

                <!--<div style="width: 150px;height: 25px;background-color: lightgray">-->
                <!--<label style="width: 150px;height: 25px;background-color: lightgray;margin-top: -25px;margin-left: 0px;">+&nbsp;上传办学许可证-->
                <!--<input id="uploadLoginbanXue" (change)="previewPic($event,'school_permit')" style="width: 150px;height: 25px;background-color: lightgray" type="file" accept="image/jpg,image/png,image/jpeg,image/bmp" style="display:none;">-->
                <!--</label>-->
                <!--</div>-->

                <!--</li>-->
            </ul>
        </div>
        <!--<label *ngIf="model.school_permit"  for="uploadLoginbanXue">-->
        <!--<img style="width:240px;height: 182px;margin-left: 71px;margin-top: 35px;" [src]="model.school_permit">-->
        <!--</label>-->

        <div class="info-list info-logo" style="margin-top: 15px" *ngIf="department?.industry=='0606'||department?.industry=='0604'||department?.industry=='0605'">
            <label *ngIf="model.school_permit" class="uploadImg-label department-logo" for="uploadLoginbanXue">
        <img [src]="model.school_permit">
      </label>
            <label *ngIf="!model.school_permit" class="uploadImg-label" for="uploadLoginbanXue">
        <img src="assets/images/add-img.png"><br/>点击上传&nbsp;&nbsp;上传办学许可证
      </label>
            <input class="uploadImg" id="uploadLoginbanXue" (change)="previewPic($event,'school_permit')" type="file" accept="image/jpeg,image/jpg,image/png">
        </div>



        <!-- 统一社会信用代码 -->
        <div class="info-list info-number">
            <!--<div *ngIf="!secureMaterail" class="info-head">第三步&nbsp;&nbsp;统一社会信用代码</div>-->
            <div class="info-head">第二步&nbsp;&nbsp;<span>企业有效证照号码(如统一社会信用代码、商业登记证号码)</span></div>
            <input type="text" placeholder="请填写企业有效证照号码" [(ngModel)]="model.register_number" maxlength="18">
            <ul>
                <li>1. 填写的企业有效证照号码必须与企业有效证照保持一致</li>
                <li>2. 高校就业中心将会尽快审核</li>
            </ul>
        </div>
        <!-- 招聘委托书 -->
        <div class="info-list info-logo" *ngIf="isHavethree">
            <div class="info-head">第三步&nbsp;&nbsp;招聘委托书</div>
            <label *ngIf="model.proxy_document" class="uploadImg-label department-logo" for="uploadProxy">
        <img [src]="model.proxy_document">
      </label>
            <label *ngIf="!model.proxy_document" class="uploadImg-label" for="uploadProxy">
        <img src="assets/images/add-img.png"><br/>点击上传招聘委托书
      </label>
            <input class="uploadImg" id="uploadProxy" (change)="previewPic($event,'proxy_document')" type="file" accept="image/jpeg,image/jpg,image/png">
            <ul>
                <li>1. 点击下载：<a [href]="secureMaterail">《招聘委托书示例》</a></li>
                <li>2. 修改后请加盖公章，截图或拍照上传</li>
            </ul>
        </div>

        <!-- 营业执照 -->
        <div class="info-list info-logo">
            <div class="info-head">{{isHavethree?'第四步':"第三步"}}&nbsp;&nbsp;招聘者手持个人有效身份证明（如身份证）的照片</div>
            <label *ngIf="model.id_card_front" class="uploadImg-label department-logo" for="uploadGuideCard">
        <img [src]="model.id_card_front">
      </label>
            <label *ngIf="!model.id_card_front" class="uploadImg-label" for="uploadGuideCard">
        <img src="assets/images/add-img.png"><br/>点击上传个人有效身份证明
      </label>
            <input class="uploadImg" id="uploadGuideCard" (change)="previewPic($event,'id_card_front')" type="file" accept="image/jpeg,image/jpg,image/png">
            <ul>
                <li>1. 请提供有效期内的本人手持《个人有效身份证明》照片</li>
                <li>2. 请勿自拍</li>
                <li>3. 上传个人有效身份证明姓名必须与招聘联系人姓名保持一致</li>
                <li>4. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                <li>5. 高校就业中心将会尽快审核</li>
                <li><a href="../../assets/images/addImage_new.png" target="_blank">示例：<img  style="width: 160px;height:  90px" src="../../assets/images/addImage_new.png"/></a></li>
            </ul>
        </div>

        <br/>
        <span style="color: #5d479a;font-size: 17px;margin-top: 25px;display: none;">发布职位</span>


        <!--职位类别-->
        <div class="bottomView" *ngFor="let item of model.list,let i = index" style="display: none;">
            <span style="color:#5d479a ">职位：</span>
            <span style="margin-left: 15px">职位名称:</span>
            <input class="rightInput" readonly (click)="goPositionType(item)" [ngModel]="item.post_typeText" placeholder="选择类别" />
            <span style="margin-left: 5px">招聘人数:</span><input placeholder="小于10000人" [(ngModel)]="item.intent_count" class="rightInput" />
            <span style="margin-left: 5px">专业:</span><input (click)="goMajor(item)" readonly value="选择专业" class="rightInput" />
            <!--<span *ngIf="model.list.length!=i+1" style="margin-left: 5px" (click)="deleteItem(item)" >删除</span>-->
            <!--<span *ngIf="model.list.length==i+1" style="margin-left: 5px" (click)="addItem()" >增加</span>-->
            <img *ngIf="model.list.length==i+1" (click)="addItem()" style="width: 15px;height: 15px;margin-left: 15px" src="../../assets/images/addList.png">
            <img *ngIf="model.list.length!=i+1" (click)="deleteItem(item)" style="width: 15px;height: 15px;margin-left: 15px" src="../../assets/images/deleteList.png">


            <br/>
            <br/>
            <div style="margin-left: 35px;">
                <span *ngFor="let m of item.majors" style="padding: 5px 15px;margin-top: 15px;margin-left: 15px;color:#5d479a; border:1px solid #5d479a">{{m | codetransform:config.major}}</span>
            </div>
        </div>
        <!-- 按钮 -->
        <div class="block-button flex_c">
            <button class="lastStep" (click)="goBackStep()">上一步</button>
            <button (click)="uploadDepartmentIdentity()">提交</button>
        </div>

    </div>
    <!-- 尾部 -->
    <div class="guide-foot">
        <div class="flex_l logoutAccout" (click)="logoutAccountClick()">注销账号</div>

        <span></span> &nbsp;&nbsp;&nbsp;&nbsp;{{config.websiteRecord}}
    </div>
</div>

<p-dialog [closeOnEscape]="false" [(visible)]="shouWeiChatCode" [style]="{'width':300,'height':340,'minHeight':340}" [modal]=true>
    <p-header>关注公众号</p-header>
    <img src={{codeURL}} style="width: 200px;height: 200px;display: block;margin: 0 auto;" />
    <br/>
    <p style="text-align: center;line-height: 15px;margin: 10px 0px">立即扫码关注"校园招聘企业通知中心"， 及时获悉您的高校合作、双选会、宣讲会审核结果通知。</p>
</p-dialog>