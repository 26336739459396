import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { DataApi, Local, Constants, Config, Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ModelSelectMoreComponent } from "../model-select-more/model-select-more.component";
import { ModelSelectTwoComponent } from "../model-select-two/model-select-two.component";
import { ModelPositionTemplateComponent } from "../model-position-template/model-position-template.component";
import { ModelStillMoreComponent } from "../model-still-more/model-still-more.component";

import { Events } from '../../provider/eventService';
import { ConfirmComponent } from "../confirm/confirm.component";
import { MatDialog } from "@angular/material/dialog";
import { LogoutAccountModelComponent } from "../logout-account-model/logout-account-model.component";

declare var window;
declare var layui;

@Component({
  selector: 'app-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.css']
})
export class IdentityComponent implements OnInit {
  public config: Config;
  @ViewChild('child1', { static: true }) child: ModelStillMoreComponent;
  @ViewChild('child2', { static: true }) child2: ModelSelectMoreComponent;
  @ViewChild('child3', { static: true }) child3: ModelSelectTwoComponent;

  @Output() modelTwoShow = false;//是否打开弹窗
  @Output() modelShow = false;//是否打开弹窗
  @Output() modelThreeShow = false;//是否打开弹窗


  public currentIndex = '';
  public codeURL = '';
  public isGuanZhu = false;
  public accountid = '';
  public isHavethree = false;
  public isMainland = true;//默认显示 营业执照

  public shouWeiChatCode = false;
  public model = {
    collegeid: '',
    facultyid: '',//院系id
    proxy_document: '',//招聘委托书
    business_licence: '',//企业营业执照
    id_card_front: '',//身份证正面
    register_number: '',//营业执照注册号

    post_type: "", //职位雷被
    name: '', //职位名称
    post_typeText: "",
    post_nature: '01',//工作性质
    position_salary: '',//月薪范围(字典)
    education: '',//学历要求
    location: {//工作地址
      region: ''//,//区域代码
      //coordinates:[],//坐标
      //address:''//详细地址
    },//工作城市
    locationText: '',//工作地址文字
    intent_count: "",//人数
    responsibility: '',//描述
    majors: [],
    list: [],
    school_permit: "",
    // currentIndex:0
  }


  //薪资数据
  public salaryData = [
    { "code": "01", "text": "1K以下" },
    { "code": "02", "text": "1K-2k" },
    { "code": "03", "text": "2k-4k" },
    { "code": "04", "text": "4k-6k" },
    { "code": "05", "text": "6k-8k" },
    { "code": "06", "text": "8k-10k" },
    { "code": "07", "text": "10k-15k" },
    { "code": "08", "text": "15k-25k" },
    { "code": "09", "text": "25k以上" }
  ];
  public collegeName = "";
  public orgList = [];//高校院系列表
  public secureMaterail: any;//招聘委托书下载链接
  public department: any;

  constructor(public dataApi: DataApi,
    private router: Router,
    public opera: Opera,
    public local: Local,
    private location: PlatformLocation,
    public dialog: MatDialog,
    public events: Events,
    public toastr: ToastrService) {
    this.config = Config.getInstance();//实例化config
    this.config.isGuide = true;
  }


  ngOnInit() {
    if (this.local.get(Constants.collegeid)) {
      this.model.collegeid = this.local.get(Constants.collegeid);
      this.getCollegeById(this.model.collegeid);//获取高校信息
      this.getIdentityMaterial();
    }
    else {
      this.toastr.error(this.dataApi.getNoDepardmentID());
      return;
    }

    for (let a = 0; a < 3; a++) {
      let item = { post_typeText: '', post_type: "", intent_count: '', majors: [], index: a, name: "" };
      this.model.list.push(item)
    }
    this.getPositionTypePost();
    this.getMajorList();//专业类别
    this.getCompanyQRCode();//获取公司的公众号二维码
    // this.getDepartDetail();
    this.getEmployment();//判断企业所属地区

    if (this.local.get('department')) {
      let json = this.local.get('department');
      try {
        this.department = JSON.parse(json);
      } catch (e) {
      }
    }
    this.dataApi.lastAuthenticationIdentity().then((res: any) => {
      //console.log(res);
      if (res) {
        if (res.status >= 1) {
          this.router.navigateByUrl('home/homepage');
          this.local.remove('register_identity')
        }
      }
    }).catch((error) => {
      //console.log(error);
    })
    if (this.local.get('register_identity')) {
      try {
        this.model = JSON.parse(this.local.get('register_identity'))
      } catch (e) {
      }
    }

  }

  // 获取企业详情 判断所属地区
  getEmployment() {
    this.dataApi.getDepartment().then((res: any) => {
      console.log(res.employer_category)
      if (res && res.employer_category) {
        if (res.employer_category == '0101') {
          this.isMainland = false;
        } else {
          this.isMainland = true;
        }
      }
    }).catch((error) => {
      console.log(error);
      // this.tip.presentToast(error);
    });
  }

  //获取公司关注公众号
  getCompanyQRCode() {

    if (this.local.get('accountid')) {
      let url = this.config.GetWXCode + this.local.get('accountid') + "&" + this.local.get('collegeid')
      let data = { departmentid: this.local.get('accountid'), collegeid: this.local.get('collegeid') };
      console.log("WeChatCode", data);
      this.dataApi.getWeiChatCode(data).then((res: any) => {
        console.log("weChatQRCodeSuccess", res.value);
        this.codeURL = res.value;

      }).catch((err) => {
        console.log("weChatQRCodeErr", err);

      })
    }
  }
  getData() {
    console.log("getData")

  }
  //获取认证上传的材料标准
  getIdentityMaterial() {
    let model = { collegeid: this.model.collegeid };
    this.dataApi.getMaterial('authentication', model).then((res: any) => {
      console.log('获取认证材料成功', res);
      if (res[0]) {
        for (let list of res[0].material_infos) {
          if (list.material_field == 'proxy_document') {
            this.secureMaterail = list.material_example;
          }
        }
      }
    }).catch((err) => {
      //console.log("获取认证材料失败");
      //console.log(err);
    })
  }

  //通过高校id获取院系列表
  getOrgList() {
    this.opera.loading();
    this.dataApi.getCollegePart(this.model.collegeid).then((res: any) => {
      // console.log("获取院系列表---");
      // console.log(res);
      this.orgList = res;
      this.opera.loadiss();
    }).catch((err) => {
      this.opera.loadiss();
      //console.log(err);
      this.toastr.error("获取院系列表失败，请稍后重试");
    })
  }
  goBackStep() {
    this.local.set('register_identity', JSON.stringify(this.model))
    this.router.navigateByUrl('/guide-company');
  }
  //通过高校id获取高校详情
  getCollegeById(id) {
    this.opera.loading();
    this.dataApi.getCollageDetails(id).then((res: any) => {
      console.log("获取到高校信息----", res);
      //console.log(res);
      this.isHavethree = res.audit_switch.proxy_document;
      if (res.audit_switch.faculty_authcation) {
        this.getOrgList();
      } else {
        this.collegeName = res.name;
      }
      this.opera.loadiss();
    }).catch((err) => {
      this.toastr.error(err);
      this.opera.loadiss();
    });
  }

  //提交认证
  uploadDepartmentIdentity() {
    let collegeid = this.local.get('collegeid') + ''
    console.log("请重新从高校站内登录", collegeid);
    if (collegeid + '' == 'false') {
      this.toastr.error(this.dataApi.getNoDepardmentID());
      return;
    }

    let filter1 = /^[0-9a-zA-Z]{9}$/;
    let filter2 = /^[0-9a-zA-Z]{15}$/;
    let filter3 = /^[0-9a-zA-Z]{18}$/;
    if (!this.model.business_licence) {
      this.toastr.warning(this.isMainland ? '请上传企业有效证照' : '请上传企业有效证照');
      return;
    }
    if (!this.model.id_card_front) {
      this.toastr.warning('请上传个人有效身份证明');
      return;
    }
    if (!this.model.register_number) {
      this.toastr.warning(this.isMainland ? '请填写企业有效证照号码' : '请填写企业有效证照号码');
      return;
    }
    //只有大陆的校验格式
    if (this.isMainland) {
      if (!this.opera.isRegister_number(this.model.register_number)) {
        this.toastr.warning(this.isMainland ? '统一社会信用代码格式不正确' : '商业登记证号码格式不正确');
        return;
      }
    }

    if (!this.collegeName) {
      if (!this.model.facultyid && this.orgList.length > 0) {
        this.toastr.info("请选择当前申请学院");
        return;
      }
    }
    if (this.isHavethree) {
      if (!this.model.proxy_document) {
        this.toastr.info("请上传招聘委托书");
        return;
      }
    }

    if (this.department) {
      if (this.department.industry == '0604' || this.department.industry == '0605' || this.department.industry == '0606') {
        if (!this.model.school_permit) {
          this.toastr.info("请上传办学许可证");
          return;
        }
      }
    }

    let NewList = [];
    for (let index = 0; index < this.model.list.length; index++) {
      let item = this.model.list[index];
      // let item = {post_typeText:'',post_type:"",intent_count:'',majors:[],index:a};
      if (item.post_typeText != '') {
        item.name = item.post_typeText;
      }
      if (/^[0-9]*$/.test(item.intent_count) == false) {
        this.toastr.info("招聘人数错误");
        return;
      }
      if (item.post_type != '' && item.intent_count != '' && item.majors.length > 0) {
        NewList.push(item);
      } else {
        if (item.post_type != '' || item.intent_count != '' || item.majors.length > 0) {
          this.toastr.info("发布职位信息填写未完整");
          return;
        }
      }
    }
    // if(NewList.length==0) {
    //   this.toastr.info("至少发布一个职位");
    //   return
    // }
    console.log("创建职位this.model", this.model);

    this.opera.loading();//加载动画

    this.dataApi.uploadDepartmentIdentity(this.model).then((res: any) => {
      this.opera.loadiss();//停止加载动画
      //创建职位
      // this.dataApi.fastCreatePosition(NewList).then((res:any)=>{

      // }).catch((error) => {
      // });
      let token = this.local.get(Constants.accesstoken);
      if (!token) {
        return
      }
      // if (this.config.GetWXCode == 'http://wx.goworkla.cn/mp/makeercode?sceneStr=') {
      //   this.shouWeiChatCode = true;
      //   this.startTimeURL();
      // } else {
      //   console.log('注册进入到首页')

      // }
      this.router.navigateByUrl('home/homepage');
      this.local.remove('resetOneStep');
      this.local.remove('register_guide');
      this.local.remove('register_identity');
      this.local.remove('register_guide_company');
      this.lastAuthenticationIdentity(); //更新企业高校认证信息本地存储

    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss();//停止加载动画
      setTimeout(() => {
        this.toastr.warning(error);
      }, 600);
    });
  }

  //获取上次高校认证信息(线上数据)
  lastAuthenticationIdentity() {
    this.dataApi.lastAuthenticationIdentity().then((res: any) => {
      //console.log(res);
      if (res) {
        this.config.lastCollegeIdentity = res;
        this.dataApi.setLastCollegeIdentity(this.config.lastCollegeIdentity);
      }
    }).catch((error) => {
      //console.log(error);
    })
  }

  //上传图片
  previewPic(event, name) {
    console.log(event);
    console.log(name);
    let imgData = event.target.files[0]; //获取file对象
    console.log('imgData', imgData.size)
    if (parseInt(imgData.size) / 1024 > 1024 * 5) {
      this.toastr.warning('该图片大小超出了5M，请重新上传');
      return;
    }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e: any) {
        that.uploadPic(reader.result, name);
      };
    }
  }
  //上传到服务器
  uploadPic(data, name) {
    let imageData = this.dataApi.formatImage(data)

    this.opera.loading();//加载动画
    this.dataApi.uploadAuthImg({
      value: imageData
    }).then((res: any) => {
      ////console.log(res);
      if (res) {
        if (res.value) {//------------上传之后赋值
          this.model[name] = res.value;
        }
      }
      this.opera.loadiss();//停止加载动画
    }).catch((error) => {
      ////console.log(error);
      this.opera.loadiss();//停止加载动画
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    });
  }
  //获取职位类别列表
  //接收子组件返回数据
  ModelTwodismiss(event) {
    this.modelTwoShow = false;
    this.opera.scrollTrue();
    scrollTo(0, 1100);
    if (event) {
      // //console.log("接收子组件返回数据");
      // //console.log(event);
      this.model.post_type = event.code;
      this.model.post_typeText = event.text;
      this.model.name = event.text;
      let arr = [];
      for (let index = 0; index < this.model.list.length; index++) {
        let item = this.model.list[index];
        if (item.index == this.currentIndex) {
          item.post_type = event.code;
          item.post_typeText = event.text;
        }
        arr.push(item);
      }
      this.model.list = arr;

    }
  }
  //去选择职位类别
  goPositionType(item) {
    this.currentIndex = item.index;
    scrollTo(0, 0);
    // console.log(" this.currentIndex",item,this.currentIndex);
    this.modelTwoShow = true;
    this.opera.scrollTop();//页面滚动
    //调用子组件函数
    this.child2.initData(this.model.post_type, this.config.post_type, '选择职位类别');

  }

  //获取职位类别列表
  getPositionTypePost() {
    if (this.dataApi.getPostTypeCode()) {
      this.config.post_type = this.dataApi.getPostTypeCode();
    } else {
      //获取职位类别字典
      this.opera.loading();
      this.dataApi.getDictDate('post_type').then((res: any) => {
        // //console.log(res);
        this.dataApi.setPostTypeCode(res);
        this.config.post_type = res;
        this.opera.loadiss();
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
      });
    }
  }


  //选择意向专业
  goMajor(item) {
    this.currentIndex = item.index;
    let CurrentArr = [];
    for (let index = 0; index < this.model.list.length; index++) {
      let item = this.model.list[index];
      if (item.index == this.currentIndex) {
        CurrentArr = item.majors;
      }
    }
    scrollTo(0, 0);
    this.modelShow = true;
    this.opera.scrollTop();//页面滚动
    //调用子组件函数
    console.log("调用子组件函数", CurrentArr, this.config.major.length);
    this.child.initData(Object.assign([], CurrentArr), Object.assign([], this.config.major), 5, '选择专业');
  }
  //接收子组件返回数据
  Modeldismiss(event) {
    this.modelShow = false;
    this.opera.scrollTrue();
    scrollTo(0, 1100);
    if (event) {
      var arr = [];
      for (let index = 0; index < this.model.list.length; index++) {
        var item = this.model.list[index];
        if (item.index == this.currentIndex) {
          item.majors = Object.assign([], event);
        }
        arr.push(item);
      }
      this.model.list = arr;
      console.log("接收子组件返回数据event", this.model.list, this.currentIndex, event);
    }
  }
  //获取专业列表信息
  getMajorList() {
    if (this.dataApi.getMajorCode()) {
      this.config.major = this.dataApi.getMajorCode();
    } else {
      this.opera.loading();
      this.dataApi.getDictDate('major').then((res: any) => {
        //console.log("获取专业列表-----college-resume");
        //console.log(res);
        this.opera.loadiss();
        this.config.major = res;
        this.dataApi.setMajorCode(res);
      }).catch((error) => {
        //console.log(error);
        this.opera.loadiss();
      });
    }
  }
  deleteItem(deleteitem) {
    if (this.model.list.length == 1) {
      this.toastr.error("至少发布一个职位");
      return;
    }
    var arr = [];
    for (let index = 0; index < this.model.list.length; index++) {
      var item = this.model.list[index];
      if (deleteitem.index != item.index) {
        arr.push(item);
      }
    }
    this.model.list = arr;

  }
  addItem() {
    var arr = [];
    for (let index = 0; index < this.model.list.length; index++) {
      var item = this.model.list[index];
      item.index = index;
      arr.push(item);
    }
    let item1 = { post_typeText: '', name: "", post_type: "", intent_count: '', majors: [], index: arr.length + 1 };
    arr.push(item1);
    this.model.list = arr;
  }

  //开启定时检索
  startTimeURL() {
    if (this.codeURL == '') {
      this.toastr.error("获取微信公众号失败");

      return;
    }
    var timer = null;
    var that = this;
    let accountid = this.local.get('accountid');

    //开启循环：每秒出现一次提示框
    timer = setInterval(function () {
      console.log('检测企业是否绑定微信', accountid)
      //检测企业是否绑定微信
      that.dataApi.getComopanyIsBingWeiChat(accountid).then((res: any) => {
        that.router.navigateByUrl('home/homepage');
        clearInterval(timer);
        that.shouWeiChatCode = false;
        return;
      }).catch((error) => {
        clearInterval(timer);
        that.router.navigateByUrl('home/homepage');
        that.shouWeiChatCode = false;
        console.log('检测企业是否绑定微信erroe', error)
      });

    }.bind(this), 1000);
  }
  getDepartDetail() {
    this.dataApi.getDepartment().then((res: any) => {//通过tooken获取企业信息
      console.log('getDepartment,', res);
      if (res) {

      }
    }).catch((error) => {
    });
  }
  //取消注册询问
  cancelRegisterChips() {
    let that = this;
    this.opera.scrollTop();//页面滚动函数
    this.dialog.open(ConfirmComponent, {
      data: {
        title: '提示',
        message: '您是否要退出注册？',
        trueText: '确定',
        falseText: '取消'
      }
    }).afterClosed().subscribe((isConfirmed) => {
      //console.log('检测是否存在返回值');
      //console.log(isConfirmed);
      this.opera.scrollTrue();
      if (isConfirmed) {
        that.cancelRegisterPost();
      }
    });
  }
  //取消注册询问
  cancelRegisterPost() {
    this.events.publish('user:created'); //发送退出登录选项
    let collegeid = this.local.get('collegeid') + '';
    this.dataApi.logout();
    console.log('logout', collegeid);
    if (collegeid != 'false') {
      this.router.navigateByUrl("login?type=login&id=" + collegeid);
    } else {
      this.router.navigateByUrl("login");
    }
  }
  //注销账号
  logoutAccountClick() {
    this.dialog.open(LogoutAccountModelComponent, {
      data: {}
    }).afterClosed().subscribe((isConfirmed) => {
      console.log('isConfirmed', isConfirmed)
      if (isConfirmed) {
      }
    });
  }
}
