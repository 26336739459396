import {Injectable, EventEmitter, OnInit} from "@angular/core";
import { Observable, throwError, of } from 'rxjs';
@Injectable()
export class Eventemit implements OnInit{
    public event:EventEmitter<any> = new EventEmitter<any>();
    constructor() {
        // 定义发射事件
        this.event = new EventEmitter();
        //emit()：发射,subscribe()：接收;unsubscribe()：取消接收
        // this.event = new Observable();
    }
    ngOnInit() {

    }
}
// import {Injectable} from "@angular/core";
// import {Observable,Subject} from "rxjs";
// @Injectable()
// export class Eventemit {
//   private _selectedPoint: Subject<any> = new Subject<any>();
//   public constructor() {}
//   public setSelectedPoint(selectedPointsIfo: any): void {
//         this._selectedPoint.next(selectedPointsIfo);
//   }
//   public currentSelectedPoint(): Observable<any> {
//        return this._selectedPoint.asObservable();
//   }
// }