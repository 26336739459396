<!-- 提醒弹框 -->
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title"></h4>
      <button type="button" class="close" mat-dialog-close>×</button>
    </div>
    <div class="modal-body">
      <img src="assets/images/search-card.png" alt="">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [mat-dialog-close]="true">立即获取高级搜索权益</button>
    </div>
  </div>
</div>
<div>

</div>
