<!-- 页头部分 -->
<div class="contentRight-header"><img (click)="opera.goChangeRouter('position-rules')" src='assets/images/router-old.png' />招聘管理 - 职位管理 - {{publishTitle}}</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <div class="college-block">
        <!-- 页头部分 -->
        <div class="college-block-head">{{publishTitle}}</div>

        <div style="width: 1000px;margin: 15px auto;height: 120px;background-color: #F0F1FF;overflow: hidden;" *ngIf="config.departmentSelf">
            <div class="guwen_bg_gray">
                <img class="guwen_info_huizhang" src="../../assets/images/kefu_hui.png">
                <img class="guwen_head" src="{{config.departmentSelf.avatar}}">
                <div class="guwen_infoDiv">
                    <ul>
                        <li><img style="width: 120px;height: 20px" src="../../assets/images/guwen_title.png"></li>
                        <li><span class="guwen_info_name">{{config.departmentSelf.name}}</span></li>
                        <li>
                            <img style="width: 17px;height: 17px" src="../../assets/images/kefu_wx.png">
                            <span class="guwen_info_title">微信：</span>
                            <span class="guwen_info_wx">{{config.departmentSelf.wx}}</span>
                        </li>
                        <li style="width: 250px">
                            <img style="width: 17px;height: 17px" src="../../assets/images/kefu_email.png">
                            <span class="guwen_info_title">邮箱：</span>
                            <span class="guwen_info_wx">{{config.departmentSelf.email}}</span>
                        </li>
                    </ul>
                </div>
                <img class="guwen_head_code" style="margin-left: 5px;" src="{{config.departmentSelf.qr_code}}">
                <div class="guwen_info_Line"></div>
                <div class="guwen_info_rightText">
                    <p>双选会/宣讲会申请相关问题,</p>
                    <p>请扫码咨询！<span>{{config.departmentSelf_ex}}</span></p>
                </div>
            </div>
        </div>

        <div style="width: 100%;height: 140px;margin-top: 30px;">
            <div style="width: 33%;height: 120px;text-align: center;float: left">
                <div style="width: 100px;height: 60px;;margin-right: 15px;float: right">
                    <div style="width: 40px;height: 40px;background-color: #5e4fff;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel?.status==0">1</div>
                    <div style="width: 40px;height: 40px;background-color: #30c742;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel?.status==1">1</div>
                    <div style="width: 40px;height: 40px;background-color: #30c742;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel?.status==2">1</div>
                    <div style="width: 40px;height: 40px;background-color: #fa5d5e;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px" *ngIf="CollegeIdentityModel.status==3">1</div>
                    <div style="width: 100px;margin-top: 5px;">资质审核</div>
                    <div style="width: 100px;margin-top: 5px; background-color: #30c742;color: white;padding-top: 5px;padding-bottom: 5px" *ngIf="CollegeIdentityModel.status==2">已通过</div>
                    <div style="width: 100px;margin-top: 5px; background-color: #5e4fff;color: white;padding-top: 5px;padding-bottom: 5px" *ngIf="CollegeIdentityModel.status==1">审核中</div>
                    <div style="width: 100px;margin-top: 5px; background-color: #fa5d5e;color: white;padding-top: 5px;padding-bottom: 5px" *ngIf="CollegeIdentityModel.status==3">审核失败</div>

                    <div style="width: 100px;margin-top: 5px;color: #fa5d5e" *ngIf="CollegeIdentityModel.status==3">{{CollegeIdentityModel.remark}}</div>
                    <!--<div style="width: 100px;height: 20px;margin-top: 5px;color: #fa5d5e" *ngIf="CollegeIdentityModel.status==3 && CollegeIdentityModel.no_apply_30day" >请申请其他高校</div>-->
                </div>
            </div>

            <div style="height: 3px;width:  34%;background-color: #5e4fff;float: left;margin-top: 17px"></div>

            <div style="width:  33%;height: 120px;text-align: center;float: right">
                <div style="width: 100px;height: 60px;margin-left: 15px;float: left">
                    <div style="width: 40px;height: 40px;background-color: gray;color:white;margin-left: 30px;border-radius: 20px;line-height: 40px;font-size: 25px">2</div>
                    <div style="width: 100px;height: 20px;margin-top: 5px;">发布</div>
                    <div style="margin-top: 10px; color: #999999;font-size: 15px;font-weight: bold;" *ngIf="briefDetais&&briefDetais.audit[0].audit_status==1">审核中</div>
                    <div style="margin-top: 10px; color: #999999;font-size: 15px;font-weight: bold;" *ngIf="briefDetais&&briefDetais.audit[0].audit_status==2">已通过</div>
                    <div style="margin-top: 10px; color: #FF5555;font-size: 15px;font-weight: bold;" *ngIf="briefDetais&&briefDetais.audit[0].audit_status==3">已拒绝</div>
                </div>
            </div>
        </div>

        <div style="width: 100px;height: 20px;margin-top: 5px;color: white;background-color: white;text-align: center;padding:8px 15px;margin-left: 46%;margin-bottom: 15px;border-radius: 5px" *ngIf="CollegeIdentityModel.status==3 && CollegeIdentityModel.no_apply_30day"></div>

        <div style="width: 100px;height: 20px;margin-top: 5px;color: #FF5555;background-color: white;text-align: center;padding:8px 15px;margin-left: 46%;margin-bottom: 15px;border-radius: 5px" *ngIf="briefDetais&&briefDetais.audit[0].audit_status==3">{{briefDetais&&briefDetais.audit[0].reason}}</div>

        <div class="company-block" [hidden]="!showTab1">
            <!-- 中间部分 -->
            <div class="info-block">

                <div *ngIf="collegeName&&orgList_shenhe.length==0" class="identityCollege-name">当前申请高校：{{collegeName}}</div>
                <div *ngIf="orgList_shenhe.length>0" class="identityCollege-name">当前申请院系：
                    <select name="CIOrg" id="CIOrg" [(ngModel)]="dmodel.facultyid" autocomplete="off">
            <option class="place-text" value='' disabled selected="selected">请选择院系</option>
            <option *ngFor="let a of orgList_shenhe" [value]="a.facultyid">{{a.name}}</option>
          </select>
                </div>

                <!-- 营业执照 -->
                <div class="info-list info-logo">
                    <div class="info-head">第一步&nbsp;&nbsp;营业执照/企业资质</div>
                    <label *ngIf="dmodel.business_licence" class="uploadImg-label department-logo" for="uploadLoginAvatar">
            <img [src]="dmodel.business_licence">
          </label>
                    <label *ngIf="!dmodel.business_licence" class="uploadImg-label" for="uploadLoginAvatar">
            <img src="assets/images/add-img.png"><br/>点击上传&nbsp;&nbsp;营业执照/企业资质
          </label>
                    <input class="uploadImg" id="uploadLoginAvatar" (change)="preview_auc($event,'business_licence')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
                    <!--<p style="position: absolute;margin-left: 70px;margin-top: 10px;color: red;height: 50px;padding-bottom: 20px">注：属于培训学校的企业，需要上传办学许可证</p>-->

                    <ul>
                        <li>1. 上传营业执照名称必须与公司全称保持一致</li>
                        <li>2. 请提供有效年检期内的《企业法人营业执照》副本</li>
                        <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                        <li>4. 高校就业老师将会在 1 -3个工作日内审核</li>
                        <li>5. 企业资质是指：有的公司没有营业执照，可以根据<br> &nbsp;&nbsp;&nbsp;&nbsp;不同行业上传不同的证件；如：基金会等公益组织，
                            <br> &nbsp;&nbsp;&nbsp;&nbsp;上传法人登记证书等。
                        </li>
                        <li>6. 属于培训学校的企业，需要上传办学许可证</li>
                        <!--<li style="margin-top: 10px">-->
                        <!--&lt;!&ndash;<input name="attachment" value='Brows' placeholder="+上传办学许可证" style="width: 100px;height:40px;background-color: lightgray;padding: 5px 15px;margin-top: 10px" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">&ndash;&gt;-->

                        <!--<div style="width: 150px;height: 25px;background-color: lightgray">-->
                        <!--<label style="width: 150px;height: 25px;background-color: lightgray;margin-top: -25px;margin-left: 0px;">+&nbsp;上传办学许可证-->
                        <!--<input id="uploadLoginbanXue" (change)="previewPic($event,'school_permit')" style="width: 150px;height: 25px;background-color: lightgray" type="file" accept="image/jpg,image/png,image/jpeg,image/bmp" style="display:none;">-->
                        <!--</label>-->
                        <!--</div>-->

                        <!--</li>-->
                    </ul>
                </div>

                <!--<label *ngIf="dmodel.school_permit"  for="uploadLoginbanXue" style="width:240px;height:190px">-->
                <!--<img style="width:240px;height: 190px;margin-left: 71px;margin-top: 35px;border-radius: 5px" [src]="dmodel.school_permit">-->
                <!--</label>-->

                <div class="info-list info-logo" *ngIf="myDepartment?.industry=='0606'||myDepartment?.industry=='0604'||myDepartment?.industry=='0605'">
                    <label *ngIf="dmodel.school_permit" class="uploadImg-label department-logo" for="uploadLoginbanXue">
            <img [src]="dmodel.school_permit">
          </label>
                    <label *ngIf="!dmodel.school_permit" class="uploadImg-label" for="uploadLoginbanXue">
            <img src="assets/images/add-img.png"><br/>点击上传&nbsp;&nbsp;上传办学许可证
          </label>
                    <input class="uploadImg" id="uploadLoginbanXue" (change)="preview_auc($event,'school_permit')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
                </div>

                <!-- 营业执照 -->
                <div class="info-list info-logo">
                    <div class="info-head">第二步&nbsp;&nbsp;招聘者身份证</div>
                    <label *ngIf="dmodel.id_card_front" class="uploadImg-label department-logo" for="uploadGuideCard">
            <img [src]="dmodel.id_card_front">
          </label>
                    <label *ngIf="!dmodel.id_card_front" class="uploadImg-label" for="uploadGuideCard">
            <img src="assets/images/add-img.png"><br/>点击上传手持身份证
          </label>
                    <input class="uploadImg" id="uploadGuideCard" (change)="preview_auc($event,'id_card_front')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
                    <ul>
                        <li>1. 上传身份证姓名必须与招聘联系人姓名保持一致</li>
                        <li>2. 请提供有效期内的本人手持《身份证》照片</li>
                        <li>3. 图片格式：JPG、JPEG、PNG，大小要求：小于5M</li>
                        <li>4. 高校就业老师将会在 1 -3个工作日内审核</li>
                    </ul>
                </div>
                <!-- 招聘委托书 -->
                <div *ngIf="secureMaterail" class="info-list info-logo">
                    <div class="info-head">第三步&nbsp;&nbsp;招聘委托书</div>
                    <label *ngIf="dmodel.proxy_document" class="uploadImg-label department-logo" for="uploadProxy">
            <img [src]="dmodel.proxy_document">
          </label>
                    <label *ngIf="!dmodel.proxy_document" class="uploadImg-label" for="uploadProxy">
            <img src="assets/images/add-img.png"><br/>点击上传招聘委托书
          </label>
                    <input class="uploadImg" id="uploadProxy" (change)="preview_auc($event,'proxy_document')" type="file" accept="image/gif,image/jpeg,image/jpg,image/png">
                    <ul>
                        <li>1. 点击下载：<a [href]="secureMaterail">《招聘委托书示例》</a></li>
                        <li>2. 修改后请加盖公章，截图或拍照上传</li>
                    </ul>
                </div>
                <!-- 统一社会信用代码 -->
                <div class="info-list info-number">
                    <div *ngIf="!secureMaterail" class="info-head">第三步&nbsp;&nbsp;统一社会信用代码</div>
                    <div *ngIf="secureMaterail" class="info-head">第四步&nbsp;&nbsp;统一社会信用代码</div>
                    <input type="text" placeholder="请填写营业执照统一社会信用代码" [(ngModel)]="dmodel.register_number" maxlength="18">
                    <ul>
                        <li>1. 填写的统一社会信用代码必须与营业执照保持一致</li>
                        <li>2. 高校就业老师将会在 1 -3个工作日内审核</li>
                    </ul>
                </div>
                <!-- 办公电话 -->
                <div class="info-lis" *ngIf="authcation_office_phone">
                    <div *ngIf="!secureMaterail" class="info-head">第四步&nbsp;&nbsp;办公电话</div>
                    <div *ngIf="secureMaterail" class="info-head">第五步&nbsp;&nbsp;办公电话</div>
                    <input type="text" [(ngModel)]="dmodel.office_phone" (blur)="checkPhone(dmodel.office_phone)" placeholder="请填写固话" class="info-phone">
                    <!-- <b *ngIf="!model.office_phone"><img src="assets/images/guide-false.png" alt="必填项提示">&nbsp;&nbsp;必填项</b> -->
                </div>
                <!-- 申请简述 -->
                <div class="info-list">
                    <div *ngIf="!secureMaterail&&!authcation_office_phone" class="info-head">第四步&nbsp;&nbsp;申请简述</div>
                    <div *ngIf="secureMaterail&&!authcation_office_phone" class="info-head">第五步&nbsp;&nbsp;申请简述</div>
                    <div *ngIf="!secureMaterail&&authcation_office_phone" class="info-head">第五步&nbsp;&nbsp;申请简述</div>
                    <div *ngIf="secureMaterail&&authcation_office_phone" class="info-head">第六步&nbsp;&nbsp;申请简述</div>
                    <div class="list-brief">
                        <textarea placeholder="请简述申请校招的目的(最多100字)" scrollHeight [(ngModel)]="dmodel.brief" minlength="0" maxlength="100">{{dmodel.brief}}</textarea>

                    </div>
                </div>
                <!-- 按钮 -->
                <div class="block-button">
                    <button (click)="uploadDepartmentIdentity()">提交</button>
                </div>
            </div>
        </div>



        <!-- 填写部分 -->
        <div class="sign-block" [hidden]="!showTab2">
            <!-- 标题 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>标题：</span>
                <input type="text" [(ngModel)]="model.name" maxlength="25" placeholder="请填写标题(25字以内)">
            </div>
            <!-- 招聘单位 -->
            <div class="sign-list" *ngIf="myDepartment">
                <span>招聘单位：</span>
                <div class="selected-text">{{myDepartment.name}}</div>
            </div>
            <!-- 招聘单位 -->
            <div class="sign-list">
                <span><b class="remind-red">*</b>到期时间：</span>
                <input type="text" placeholder="请选择招聘简章到期时间" id="effect-time" [(ngModel)]="model.effect_time" /><img class="calendar-img" src="assets/images/calendar.png" alt="打开日历" />
            </div>
            <!-- 简章内容 -->
            <div class="sign-list sign-editor">
                <span><b class="remind-red">*</b>简章内容：</span>
                <ngx-neditor [(ngModel)]="model.content" #neditor [config]="toolConfig"></ngx-neditor>
            </div>
            <!-- 提交 -->
            <div class="sign-submit">
                <button class="purple" (click)="goSubmit()">发布</button>
            </div>
        </div>
        <!-- 申请部分 -->

    </div>
</div>