import { Component, OnInit, Input, Output } from '@angular/core';
import {Config,Opera } from '../../provider/index';
@Component({
  selector: 'app-position-hired',
  templateUrl: './position-hired.component.html',
  styleUrls: ['./position-hired.component.css']
})
export class PositionHiredComponent implements OnInit {
  public config: Config; 
  @Output() modelShow = true;//是否打开弹窗(我发起的)
  @Output() modelTwoShow = false;//是否打开弹窗(我收到的)
  public type="departrment";//当前导航页
  constructor(public opera:Opera) { this.config = Config.getInstance();}

  ngOnInit() {
    this.opera.initConfig('position','position-hired');
  }
  //我发起的
  goDepartment(){
   this.type="departrment";
   this.modelShow = true;
   this.modelTwoShow = false;
  }
  //我收到的
  goTalent(){
    this.type="talent";
    this.modelShow = false;
   this.modelTwoShow = true;
  }

}
