import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'textformat'})
export class TextFormat implements PipeTransform {//implements--是一个类，实现一个接口用的关键字，它是用来实现接口中定义的抽象方法
  transform(value): string {
    if(value){     
      let one = value.replace(new RegExp(/\n/g), '<br>');//匹配换行符
      let two = one.replace(new RegExp(/\s/g), '&nbsp;');//匹配任何空白字符，包括空格、制表符、换页符等等     
      return two;
    }    
  }
}