import { Component, OnInit, Input, Output ,ViewChild  } from '@angular/core';
import { DataApi,Local,Opera,Constants,Config } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { ModelSelectTwoComponent } from '../model-select-two/model-select-two.component';

@Component({
  selector: 'app-college-resume',
  templateUrl: './college-resume.component.html',
  styleUrls: ['./college-resume.component.css']
})
export class CollegeResumeComponent implements OnInit {
  @ViewChild('industry', { static: true }) child: ModelSelectTwoComponent;
  public config: Config;
  public positionList=[];//职位列表
  public keywordList=[];//搜索关键词匹配列表
  @Output() modelShow = false;//是否打开弹窗
  // 筛选条件--搜索
  public model={
    positionid:'',
    college_name:'',//学校名称
    gender:'',
    city:'',
    limit:9,
    offset:0
  }
  public resumeList = [];//简历列表
  public countList = 0;//列表数量
  public endTime:string;//高级搜索结束时间
  public searchStatus=false;//判断是否开通高级搜索
  public educationList=[];//学历列表
  public val = {
    status: 2
  };
  public mod = {
    city: '',//城市
    school_type: '',//分类
    school_nature: '',//性质
    labels: '',//特色
    status: '',//校招状态
    offset: 0,
    limit: 1000
  };
  public searchModel={
    collegeid:'',//学校id
    positionid:''//职位id
  }
  public advanceModel={
    post_type:'',//职位类别
    salary:'',//月薪范围(字典)
    collegeid:'',
    education:'',//学历要求
    work_city:'',
    major:'',//专业要求
    school_label:'',//高校标签
    gender:'',//性别
    is_online:'',//是否在线
    limit: 9,
    offset: 0
  };
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  public majorOne='';//专业类别一级
  public collegeList = [];//认证的高校列表
  public collegeCount: 0;//列表总数
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  // public isPsitionList=false;
  public isShowFilter = false;
  public hotCity=[];//热门城市
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public dialog: MatDialog,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.opera.initConfig('college','college-resume');
    this.getPositionList();//获取职位列表数据
    this.getCollegeCode();//获取高校字典
    this.getHotCity();//获取热门城市
    this.getEmployment();//判断是否开通高级搜索
    this.getCollegeList();//获取认证学校
    this.getSchoolLabel();//获取高校标签
    this.getIndustry();//获取所属行业
    this.getEducationList();//获取学历列表信息
    this.getMajorList();//获取专业列表信息
  }
  // 获取是否开通高级搜索
  getEmployment(){
    this.dataApi.getEmployment().then((res:any)=> {
      if(res)
      {
        if(res.quota.product_service.length>0){
          this.searchStatus=true;
          this.config.searchStatus=true;
          this.endTime=res.quota.product_service[0].endtime ;
        }else{
          this.config.searchStatus=false;
        }
      }

    }).catch((error) => {
      console.log(error);
      // this.tip.presentToast(error);
    });
}

  //获取高校认证列表
  getCollegeList() {
    if (this.config.lastIdentity.status != '2') {
      this.dataApi.getCollegeList(this.val).then((res:any)=> {
        console.log("获取高校列表--------------------------------chooseCollegePage");
        console.log(res);
        if(res)
        {
          this.collegeList = res.list;
          this.collegeCount = res.count;
        }

      }).catch((err) => {
        console.log(err);
      })
    } else {
      this.dataApi.getCollegeList(this.mod).then((res:any)=> {
        console.log("获取高校列表--------------------------------ChooseCollegePage");
        console.log(res);
        if(res)
        {
          this.collegeList = res.list;

        }
        // this.listCount=res.count;
      }).catch((err) => {
        console.log(err);
      })
    }


  }
  //获取热门城市
  getHotCity(){
    if(this.hotCity.length<1){
      this.dataApi.getCollegeHotCity().then((res:any)=>{
        //console.log("获取热门城市----college-campustalk");
        //console.log(res);
        this.hotCity=res;
      }).catch((err)=>{
        //console.log(err);
      })
    }
  }
  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----college-resume');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
        this.getRecommendMoreList();//获取职位推荐简历
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getRecommendMoreList();//获取职位推荐简历
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getRecommendMoreList();//获取职位推荐简历
      }
    }

  }

  //获取推荐简历列表 offset自定义
  getRecommendMoreList(){
      this.opera.loading();//加载动画
      this.dataApi.getResumeList(this.model).then((res:any)=>{
        //console.log("获取推荐简历列表----");
        //console.log(res);
        if(res)
        {
          this.countList = res.count;
          this.pageCount =Math.ceil(res.count/9);
          this.resumeList = res.list;
        }

        this.opera.loadiss();//停止加载动画
      }).catch((error) => {
        this.opera.loadiss();//停止加载动画
        //console.log(error);
      });
  }

  //获取推荐简历列表 offset=0
  getRecommendList(){
    this.model.offset = 0;
    this.opera.loading();//加载动画
    console.log(this.searchModel.collegeid.length,'学校id')
    if(this.searchModel.collegeid.length!=0 || this.searchModel.positionid.length!=0){
      this.dataApi.getSearch(this.searchModel).then((res:any)=> {
        if(res)
        {
          this.countList = res.count;
          this.pageCount =Math.ceil(res.count/9);
          this.resumeList = res.list;
        }

      this.opera.loadiss();//停止加载动画
      }).catch((err) => {
        this.opera.loadiss();//停止加载动画
        console.log(err);
      })
    }
    if(this.advanceModel.post_type.length!=0 || this.advanceModel.salary.length!=0 || this.advanceModel.collegeid.length!=0 || this.advanceModel.education.length!=0 || this.advanceModel.work_city.length!=0 || this.advanceModel.major.length!=0 || this.advanceModel.school_label.length!=0 || this.advanceModel.gender.length!=0 || this.advanceModel.is_online.length!=0){
      this.dataApi.getAdvancedSearch(this.advanceModel).then((res:any)=> {
        if(res)
        {
          this.countList = res.count;
          this.pageCount =Math.ceil(res.count/9);
          this.resumeList = res.list;
        }

      this.opera.loadiss();//停止加载动画
      }).catch((err) => {
        console.log(err);
        this.opera.loadiss();//停止加载动画
      })
    }
   else{
    this.dataApi.getResumeList(this.model).then((res:any)=>{
      // console.log("获取推荐简历列表----");
      // console.log(res);
      if(res)
      {
        this.countList = res.count;
        this.pageCount =Math.ceil(res.count/9);
        this.resumeList = res.list;
      }

      this.opera.loadiss();//停止加载动画
    }).catch((error) => {
      this.opera.loadiss();//停止加载动画
      //console.log(error);
    });
   }
  }

 //获取高校字典
 getCollegeCode(){
  // 获取高校字典数据
  if(this.config.school.length<1){
   this.dataApi.getDictDate('school').then((res:any)=>{
     // //console.log(res);
     this.config.school=res;
   }).catch((err)=>{
     // this.toastr.error("网络错误,请稍后重试");
     //console.log(err);
   })
 }
}

//筛选
goSearch(text){
  this.model.college_name = text;
  this.getRecommendList();//获取简历列表
  this.keywordList=[];
}

  //匹配关键字列表
  getItems(){
    this.keywordList=[];
    if(!this.model.college_name){return;}
    for(let s of this.config.school){
      if(s.text.indexOf(this.model.college_name) >= 0){
         this.keywordList.push(s);
      }
    }
  }
  //监听键盘事件
  inputKeyup(event){
    let that = this;
    var keycode = window.event?event.keyCode:event.which;
    if(keycode==13){
      that.goSearch(that.model.college_name);
    }else{
      that.getItems();
    }
  }

   //获取职位列表数据
   getPositionList(){
    //console.log("在期职位本地存储-----college-resume");
    //console.log(this.dataApi.getLocalpositionList());
    this.config.positionList=this.dataApi.getLocalpositionList();
    if(this.dataApi.getLocalpositionList()){
      if(this.config.positionList.length==0){
        this.local.remove(Constants.positionid);
        this.local.remove(Constants.positionList);//职位列表
        this.router.navigate(['home/position-publish']);
        setTimeout(() => {
          this.toastr.warning("当前没有在期职位，请发布职位");
        });
        return;
      }
      this.config.positionList=this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
      this.positionList =  this.config.positionList;
      this.model.positionid = this.config.positionList[0].positionid;
      this.getRecommendList();//获取推荐简历
    }else{
      this.getNewList();
    }
  }

  //-------获取线上数据
  getNewList(){
    let model={
      limit:100,
      offset:0,
      status:'release',
    }
    this.opera.loading();
    this.dataApi.getPositionList(model).then((res:any)=>{
       //console.log("在期职位获取线上数据-----college-resume");
       //console.log(res);
      if(res)
      {
        if(res.list.length==0){
          this.opera.loadiss();
          this.local.remove(Constants.positionid);
          this.router.navigate(['home/position-publish']);
          setTimeout(() => {
            this.toastr.warning("当前没有在期职位，请发布职位");
          });
          return;
        }
        this.positionList =  res.list;
        this.model.positionid = res.list[0].positionid;
        this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
        this.getRecommendList();//获取推荐简历
      }

       this.opera.loadiss();
     }).catch((error) => {
       this.toastr.error(error);
       this.opera.loadiss();
     });
  }

  //-----跳转简历详情
  goDetails(n){
    this.router.navigate(['home/college-resumedetails'], {
      queryParams: {
        resumeid:n.resume_number,
        positionid:this.model.positionid,
        match:n.matching
      }
    });
  }

  //立即沟通
  goChat(n,id){
    this.opera.goChat(n,id);
  }
  //收藏
  goCollect(val){
    val = this.opera.manageResumeArchived(val);
  }
 //获取高校标签school_label
 getSchoolLabel(){
  this.dataApi.getDictDate('school_label').then((res:any)=>{
    this.config.school_label=res;
  }).catch((err)=>{
    //console.log(err);
  })
}
 //选择行业类别
 chooseIndustry(){
  this.opera.scrollTop();//页面滚动函数
  this.modelShow = true;
  //调用子组件函数
  this.child.initData(this.advanceModel.post_type,this.config.industry,'选择行业类别');
}
//接收子组件返回数据
Modeldismiss(event){
  this.modelShow = false;
  this.opera.scrollTrue();
  if(event){
    // //console.log("接收子组件返回数据");
    // //console.log(event);
    this.advanceModel.post_type = event.code;
    this.getRecommendList();
  }
}

//获取所属行业
getIndustry(){
  if(this.local.get(Constants.industry)){
    //console.log('获取行业类型本地存储');
    //console.log(this.dataApi.getIndustryCode());
    this.config.industry = this.dataApi.getIndustryCode();
  }else{
    //获取行业类型字典
    this.dataApi.getDictDate('industry').then((res:any)=>{
      //console.log('获取行业类型线上数据');
      //console.log(res);
      this.dataApi.setIndustryCode(res);
    }).catch((error)=>{
      //console.log(error);
    });
  }
}
 //获取学历列表信息
 getEducationList(){
  if(this.dataApi.getEducationCode()){
    //console.log("获取学历列表本地存储-----college-resume");
    //console.log(this.dataApi.getEducationCode());
    this.educationList = this.dataApi.getEducationCode();
  }else{
    this.dataApi.getDictDate('education').then((res:any)=>{
      //console.log("获取学历列表-----college-resume");
      //console.log(res);
      this.educationList = res;
      this.dataApi.setEducationCode(res);
    }).catch((error)=>{
      console.log(error);
    });
  }
}
   //获取专业列表信息
   getMajorList(){
    if(this.dataApi.getMajorCode()){
      this.config.major = this.dataApi.getMajorCode();
      this.getMajorListOne();//获取一级专业列表
    }else{
      this.dataApi.getDictDate('major').then((res:any)=>{
        ////console.log("获取专业列表-----college-resume");
        ////console.log(res);
        this.config.major = res;
        this.getMajorListOne();//获取一级专业列表
        this.dataApi.setMajorCode(res);
      }).catch((error)=>{
        //console.log(error);
      });
    }
  }


  //获取一级专业列表
  getMajorListOne(){
    for(let n of this.config.major){
      if(n.level==2){
        this.majorListOne.push(n);
      }
    }
  }

   //监听majorOne
   majorOneChange(){
    // ////console.log("第一级专业值");
    // ////console.log(this.majorOne);
    // ////console.log("专业列表");
    // ////console.log(this.config.major);
    this.majorListTwo=[];
    for(let n of this.config.major){
      if(n.parentcode==this.majorOne){
        this.majorListTwo.push(n);
      }
    }
    this.advanceModel.major='';
    // ////console.log("第二级专业列表");
    // ////console.log(this.majorListTwo);
  }


  //提示先选一级专业
isShowTwo(){
  if(!this.majorOne){
    this.toastr.warning("请先选择一级专业");
    return;
  }
 }
}
