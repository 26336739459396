import {Component, Inject, OnInit} from '@angular/core';
import {Config} from "../../provider";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Router} from "@angular/router";


export interface ConfirmModel {
  type: "";
  title:string;
  message:any;
  trueText:string;
  falseText:string;
  allData:any;
}


@Component({
  selector: 'app-http-error-model',
  templateUrl: './http-error-model.component.html',
  styleUrls: ['./http-error-model.component.css']
})
export class HttpErrorModelComponent implements OnInit {

  type:any;
  public config: Config;

  constructor(
      private router: Router,
      @Inject(MAT_DIALOG_DATA) public data: ConfirmModel
  ) {
    this.config = Config.getInstance();//实例化config

    console.log('ConfirmModel',data);
  }

  ngOnInit() {
  }
  goRouter(n){
    this.config.isShowHTTPErrModel = false;
  }
  goRouter1(n){
    //this.opera.scrollTop();
    this.router.navigate(['/'+n]);
  }
}
