<!-- 页头部分 -->
<div class="contentRight-header">企业中心 - 高校合作</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <!-- 遮罩层部分 -->
  <router-outlet></router-outlet>
  <app-model-college-identity  [no_apply_30day]="no_apply_30day" [timestr] ="timerStr" [name]="collegeName" [item]="collegeIdentity" [audit_time]="audit_time" [isShow]="modelShow" [collegeid]="collegeid" (onVoted)="Modeldismiss($event)"></app-model-college-identity>
  <div class="company-block">
     <!-- 页头部分 -->
     <div class="college-block-head">高校合作记录</div>
     <!-- 头部 -->
    <!-- <div class="identity-head">
        <button [class.purple]="type=='departrment'" (click)="goDepartmentIdentity()">招聘认证</button>
        <button [class.purple]="type=='college'" (click)="goCollegeIdentity()">高校认证</button>
    </div> -->
    <!-- 组件 -->
    <!-- 认证信息提交部分 -->
    <!-- <router-outlet></router-outlet> -->
    <!-- <app-model-company-identity [isShow]="modelShow" [id]="collegeid" (onVoted)="Modeldismiss($event)"></app-model-company-identity>
    <app-model-identity-status [isShow]="modelShowStatus" [status] ="status" (onVoted)="Modeldismiss($event)"></app-model-identity-status> -->
    <!-- <app-model-identity-college [isShow]="modelShowCollege"></app-model-identity-college> -->
    <div class="college-block">
        <!-- 操作部分 <img src="assets/images/select-img.png">-->
        <div class="college-block-opera clear">
          <div class="right">
           <span>审核状态</span>
           <select name="city-milk" id="city-milk" autocomplete="off" [(ngModel)]="status" (ngModelChange)="getCollegeIdentityList()">
               <option class="place-text" value=0>不限</option>
               <option value=1>审核中</option>
               <option value=2>已通过</option>
               <option value=3>未通过</option>
               <!-- <option *ngFor="let city of hotCity" [value]="city.code">{{city.text}}</option>[(ngModel)]="model.region"  -->
           </select>
           <!-- 搜索筛选 -->
           <div class="search-input right">
                <input type="text" placeholder="请输入学校名称" [(ngModel)]="model.college_name" (keyup)="inputKeyup($event)"><span (click)="goSearch(model.college_name)"><img src="assets/images/search.png"></span>
                <!-- 搜索关键词匹配列表 -->
                <div class="keyup-block" *ngIf="keywordList.length>0">
                    <div class="keyup-list" *ngFor = "let key of keywordList" (click)="goSearch(key.text)">{{key.text}}</div>
                </div>
            </div>
          </div>
        </div>
        <!-- 没有内容存在时 -->
        <div *ngIf="listCount==0" class="none-block">
            <img src="assets/images/resume-search-fail.png"><br/>
            暂未找到校招审核
        </div>
        <!-- 循环列表 -->
        <div class="college-list-cont" *ngIf="listCount>0">
            <ul>
                <li>学校名称</li>
                <li>就业办电话</li>
                <li>申请时间</li>
                <li>审核状态</li>
                <li>操作</li>
            </ul>
            <!-- *ngFor="let n of collegeList;let i = index;" -->
            <div>
                <ul class="college-li" *ngFor="let n of collegeList;let i = index;">
                    <li><span *ngIf='n.college_name'>{{n.college_name}}</span><span *ngIf='!n.college_name'>暂无</span></li>
                    <li><span *ngIf='n.phone'>{{n.phone}}</span><span *ngIf='!n.phone'>暂无</span></li>
                    <li><span *ngIf='n.apply_time'>{{n.apply_time | date:"yyyy-MM-dd HH:mm"}}</span><span *ngIf='!n.apply_time'>暂无</span></li>
                    <li>
                      <span *ngIf='n.status==1'>审核中</span>
                      <span *ngIf='n.status==2'>已通过</span>
                      <span *ngIf='n.status==3' class="red">未通过</span>
                    </li>
                    <!-- <li>
                      <span *ngIf='n.status==3' (click)="goStatus(n)">查看原因</span>
                      <span *ngIf='n.status==3' (click)="goIdentity(n.collegeid)">重新申请</span>
                      <span *ngIf='n.status!=3' class="gray" (click)="goIdentity(n.collegeid,n.audit_time)">重新申请</span>
                    </li>  -->
                    <li>
                        <span *ngIf='n.status==1' (click)="goStatus(n)">查看详情</span>
                        <span *ngIf='n.status==2' (click)="goStatus(n)">查看详情</span>
                        <span *ngIf='n.status==3' (click)="goStatus(n)">查看详情</span>
                      </li>
                </ul>
            </div>
        </div>
        <!-- 分页部分 -->
        <div class='paging-block' [class.isPageHeight]="listCount>0">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>
    </div>    
  </div>
  
    
</div>
