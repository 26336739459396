import { Pipe, PipeTransform } from '@angular/core';
// import { DicData} from '../provider/index';

@Pipe({name: 'codemoretransform'})
export class CodeMoreTransform implements PipeTransform {
  constructor(){

  }  
  transform(_content,_type) {
    let textString=[];
    let text=[];
    if (_content.length>0 && _type) {      
      textString = _content;
      // DicData[_type].forEach((val)=>{
      //   if(textString==val.code){
      //     // console.log(val);
      //     text.push(val.text);//数组形式            
      //   }        
      // }); 
      return text;
    }else{
      return _content;
    }
    
  }
}