<!-- 页头部分 -->
<div class="contentRight-header">招聘管理 - 我的宣讲会</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <div class="college-block">
        <!-- 页头部分 -->
        <div class="college-block-head">宣讲会申请记录列表</div>
        <div class="college-block-opera clear">
            <div class="right">
                <span>筛选</span>
                <!-- 审核状态 -->
                <select name="city" id="city" [(ngModel)]="model.status" (ngModelChange)="getCampusList()" autocomplete="off">
                    <option class="place-text" value='' disabled hidden selected="selected">选择审核状态</option>
                    <option class="place-text" value=''>不限</option>
                    <!-- <option value=0>未申请</option> -->
                    <option value='1'>审核中</option>
                    <option value='2'>已通过</option>
                    <option value='3'>已拒绝</option>
                </select>
                <!-- 搜索筛选 -->
                <div class="search-input right">
                    <input type="text" placeholder="请输入学校名称" [(ngModel)]="model.college_name" (keyup)="inputKeyup($event)"><span (click)="goSearch(model.college_name)"><img src="assets/images/search.png"></span>
                    <!-- 搜索关键词匹配列表 -->
                    <div class="keyup-block" *ngIf="keywordList.length>0">
                        <div class="keyup-list" *ngFor="let key of keywordList" (click)="goSearch(key.text)">{{key.text}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 没有内容存在时 -->
        <!--<div *ngIf="countList==0" class="none-block">-->
        <!--<img src="assets/images/resume-search-fail.png"><br/>-->
        <!--暂未找到宣讲会申请记录-->
        <!--</div>-->
        <div *ngIf="countList==0" class="historyMilkRoundList_noData">
            <img src="assets/images/campnull.png"><br/> 暂无
        </div>

        <!-- 循环列表 -->
        <div class="college-list-cont" *ngIf="countList>0">
            <div class="listCell" *ngFor="let n of campusList;let i = index;">
                <div class="listCellContent" (click)="goPreach(n.ctid)">
                    <div style="width: 90px;height: 100%;float: left">
                        <img src="{{n.logo}}" style="width:60px;height: 60px;margin-left: 15px;margin-top: 15px;border-radius: 30px">
                    </div>
                    <div style="height:60px;width: 25%;margin-top: 20px;float: left;overflow:hidden">
                        <div style="height: 25px;line-height: 25px;font-size: 18px;color: black;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width: 360px;">{{n.name}}</div>
                        <p class="milkRoundAddressAndTime">
                            <img src="../../assets/images/addressIcon.png" style="width: 12px;height: 16px;margin-top: -3px"> {{(!n.place_name?"地点暂无":n.place_name)}}
                        </p>
                    </div>
                    <div style="width: 20%;height: 100%;float: left; display: flex; flex-direction: row;align-items: center;justify-content:center">
                        <span>{{'时间:'+(n.start_time | date:"yyyy-MM-dd HH:mm"+'&nbsp;-&nbsp;'+(n.end_time | date:"yyyy-MM-dd HH:mm"))}}</span>
                    </div>
                    <div style="width: 15%;height: 100%;float: left; display: flex; flex-direction: row;align-items: center;justify-content:center">
                        <span *ngIf='n.college_name'>{{n.college_name==null?"":n.college_name}}</span>
                    </div>
                    <div style="width: 30%;height: 100%;float: left; display: flex; flex-direction: row;align-items: center;justify-content:center">
                        <button class="cellButton" (click)="goUpdata(n.collegeid,n)" *ngIf="(n.audit_status=='to_audit'|| n.cooperation_status=='1')&& !n.fail">审核中</button>
                        <button class="cellButton shibai" (click)="openRevoke($event,n.ctid)" *ngIf="(n.audit_status=='to_audit'|| n.cooperation_status=='1')&& !n.fail">撤回</button>
                        <button class="cellButton" (click)="goUpdata(n.collegeid,n)" *ngIf="n.audit_status == 'revoke'">重新预约</button>
                        <button class="cellButton" (click)="goUpdata(n.collegeid,n)" *ngIf="n.fail">预约失败</button>
                        <button class="cellButton" *ngIf="n.audit_status=='passed'&& n.cooperation_status=='2'">预约成功</button>
                        <button class="cellButton" *ngIf="n.audit_status=='passed'&& n.cooperation_status=='2'" (click)="showJoinCerModel($event,n)">获取参会凭证</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 分页部分 -->
        <div class='paging-block' [class.isPageHeight]="countList>0">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>
    </div>
</div>
<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0}" [(visible)]="isShowJoinCerModel" [style]="{'width':300,'height':532,'minHeight':300,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"
    [modal]=true>
    <div class="JoinCerModel">
        <img class="title" src="../../assets/images/campjointitle.png">
        <div class="line"></div>
        <div class="subtitle">宣·讲·会·参·会·企·业·凭·证</div>
        <div class="info" *ngIf="JoinCerModel">
            <div class="camptitle">{{JoinCerModel.name}}</div>
            <div class="campCell">
                <img class="leftIcon" src="../../assets/images/camp_comname.png" />
                <div class="rightText">
                    企业名称: {{JoinCerModel.department_name}}
                </div>
            </div>
            <div class="campCell">
                <img class="leftIcon" src="../../assets/images/camp_time.png" />
                <div class="rightText">
                    宣讲时间: {{JoinCerModel.start_time | date:"yyyy-MM-dd HH:mm"}}
                </div>
            </div>
            <div class="campCell">
                <img class="leftIcon" src="../../assets/images/camp_address.png" />
                <div class="rightText">
                    宣讲地点: {{JoinCerModel.place_name}}
                </div>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="revokeModule" [modal]=true>
    <div class="showTopingBox">
        <div class="closIcon" (click)="revokeModule = false"><img src="/assets/images/v3/contactMeClos.png"></div>
        <div class="title">
            <div class="tipText">
                <div class="name">撤回报名</div>
            </div>
        </div>
        <div class="p2">确定撤回该宣讲会报名吗？</div>
        <div class="btns">
            <div class="btn active" (click)="revokeModule = false">取消</div>
            <div class="btn blueStreamer" (click)="downMilk()">确定</div>
        </div>
    </div>
</p-dialog>