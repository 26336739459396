<!-- 添加企业地址 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
    <div class="black-bg" (click)="dismiss(false)"></div>
    <div class="model-cont">
        <!-- 上层提示部分 -->
        <div class="model-head clear">
            <div class="left">福利待遇</div>
            <button class="right" (click)="dismiss(false)">×</button>
        </div>
        <!-- 中间内容部分 -->
        <div class="label-block">
          <!-- 添加标签 -->
          <div class="mj-div clear">
              <div class="mj-left"><input type="text" [(ngModel)]="userLabel" maxlength="6" minlength="1" name="label" placeholder="填写标签内容，最多6个字"></div>
              <div class="mj-right" (click)="addLabel(userLabel)">添加</div>          
          </div>
          <!--可选标签  -->
          <div class="label-title-block">可选标签（已选 {{index}}/9）：</div>
          <!-- 选择标签 -->
          <div class="select-choose-block clear" *ngIf="labelAll.length>0">
              <div class="choose-list">
                <div class="choose-item"  *ngFor="let label of labelAll">
                  <div class="choose-text">{{label.text}}</div>
                  <div class="choose-delete" (click)="delectSkill(label)">x</div>
                </div> 
              </div>         
          </div>
          <!--推荐标签  -->
          <div class="label-title-block">推荐标签</div>
          <!-- 选择标签 -->
          <div class="label-list clear">
              <div class="label-item" [class.isActive]="labels.isActive" *ngFor="let labels of config.label" (click)="chooseConfigLabel(labels)">
                 <div>{{labels.text}}</div>
              </div>
          </div> 
        </div>
        <!-- 底部按钮 -->
        <div class="model-foot"><button class="purple" (click)="getLabel()">保存</button></div>
    </div>
</div>