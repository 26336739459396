import { Component, OnInit, Input,ViewChild, Output } from '@angular/core';
import { DataApi,Local,Opera,Constants,Config } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
import { ModelPositionHiredComponent } from '../model-position-hired/model-position-hired.component';
@Component({
  selector: 'app-model-hired-department',
  templateUrl: './model-hired-department.component.html',
  styleUrls: ['./model-hired-department.component.css']
})
export class ModelHiredDepartmentComponent implements OnInit {
  public config: Config;
  @ViewChild('hired', { static: true }) child: ModelPositionHiredComponent;
  @Input()  isShow: false;//是否显示弹窗页面
  @Output() modelShow = false;//是否打开弹窗
  public positionList=[];//职位列表
  public schoolList=[];//高校列表
  public educationList=[];//学历列表
  public keywordList=[];//搜索关键词匹配列表
  // 筛选条件--搜索
  public model={
    college_name:'',//学校名称
    positionid:'',//职位id
    major:'',//专业
    education:'',//学历
    limit:9,
    offset:0
  }
  public searchModel = '';//搜索关键词
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  public majorOne='';//专业类别一级
  public resumeList = [];//简历列表
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public isShowFilter = false;
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
  }

  ngOnInit() {
    this.getCollegeCode();//获取高校字典
    this.getPositionList();//获取职位列表数据
    this.getMajorList();//获取专业列表信息
    this.getEducationList();//获取学历列表信息
    this.getResumeList();//获取简历列表
  }

  //打开签约通知
  goHire(n){
    this.opera.scrollTop();
    this.modelShow=true;
    this.child.initData(false,false,'5b6c13cf09b8df5660ebff64');//测试数据
  }
  //接收子组件返回数据
  Modeldismiss(event){
    this.modelShow = false;
    this.opera.scrollTrue();
    if(event){
      // //console.log("接收子组件返回数据");
      // //console.log(event);
    }
  }

  //获取简历列表
  getResumeList(){
    this.dataApi.getHiredDepartmentList(this.model).then((res:any)=>{
      //console.log("获取我发起的列表");
      //console.log(res);
      this.resumeList = res.count;
      this.countList=res.count;
      this.pageCount =Math.ceil(res.count/9);
    }).catch((err)=>{
      //console.log(err);
    })
  }

  //获取职位列表数据
  getPositionList(){
    //console.log("在期职位本地存储----");
    //console.log(this.dataApi.getLocalpositionList());
    if(this.dataApi.getLocalpositionList()){
      this.config.positionList=this.dataApi.getLocalpositionList();//赋值在期职位列表本地存储
      this.positionList =  this.config.positionList;
    }else{
      this.getNewList();
    }
  }
  //-------获取线上数据
  getNewList(){
    let model={
      limit:100,
      offset:0,
      status:'release',
    }
    this.dataApi.getPositionList(model).then((res:any)=>{
       //console.log("在期职位获取线上数据-----college-resume");
       //console.log(res);
       this.positionList =  res.list;
       this.dataApi.setLocalpositionList(res.list);//在期职位列表本地存储
     }).catch((error) => {
       this.toastr.error(error);
     });
  }

  goSearch(text){
    this.model.college_name = text;
    this.getResumeList();//获取列表
    this.keywordList=[];
  }

   //获取高校字典
   getCollegeCode(){
    // 获取高校字典数据
    if(this.config.school.length<1){
     this.dataApi.getDictDate('school').then((res:any)=>{
       // //console.log(res);
       this.config.school=res;
     }).catch((err)=>{
       // this.toastr.error("网络错误,请稍后重试");
       //console.log(err);
     })
   }
 }

  //匹配关键字列表
  getItems(data){
    this.keywordList=[];
    if(!data){return;}
    for(let s of this.config.school){
      if(s.text.indexOf(data) >= 0){
         this.keywordList.push(s);
      }
    }
  }

   //获取学历列表信息
   getEducationList(){
    if(this.dataApi.getEducationCode()){
      //console.log("获取学历列表本地存储-----college-resume");
      //console.log(this.dataApi.getEducationCode());
      this.educationList = this.dataApi.getEducationCode();
    }else{
      this.dataApi.getDictDate('education').then((res:any)=>{
        //console.log("获取学历列表-----college-resume");
        //console.log(res);
        this.educationList = res;
        this.dataApi.setEducationCode(res);
      }).catch((error)=>{
        //console.log(error);
      });
    }
  }

  //监听majorOne
  majorOneChange(){
    this.majorListTwo=[];
    for(let n of this.config.major){
      if(n.parentcode==this.majorOne){
        this.majorListTwo.push(n);
      }
    }
  }


  //提示先选一级专业
isShowTwo(){
  if(!this.majorOne){
    this.toastr.warning("请先选择一级专业");
    return;
  }
 }

  //获取一级专业列表
  getMajorListOne(){
    for(let n of this.config.major){
      if(n.level==1){
        this.majorListOne.push(n);
      }
    }
  }

  //获取专业列表信息
  getMajorList(){
    if(this.dataApi.getMajorCode()){
      this.config.major = this.dataApi.getMajorCode();
      this.getMajorListOne();//获取一级专业列表
    }else{
      this.dataApi.getDictDate('major').then((res:any)=>{
        //console.log("获取专业列表-----college-resume");
        //console.log(res);
        this.config.major = res;
        this.getMajorListOne();//获取一级专业列表
        this.dataApi.setMajorCode(res);
      }).catch((error)=>{
        //console.log(error);
      });
    }
  }



}
