<!-- 页头部分 -->
<div class="contentRight-header">招聘管理 - 招聘简章</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <div class="college-block">
        <!-- 页头部分 -->
        <div class="college-block-head">
            招聘简章列表
        </div>
        <!-- 操作部分 <img src="assets/images/select-img.png">-->
        <div class="college-block-opera clear">
            <div class="left">
                <button class="purple" (click)="goPublishRules()">发布招聘简章</button>
            </div>
            <div class="right">
                <!-- 选择所在城市[(ngModel)]="" -->
                <!-- <span>筛选</span>
           <select name="status" id="status" [(ngModel)]="model.status" (ngModelChange)="getPositionList()" autocomplete="off">
                  <option class="place-text" value='' disabled hidden selected="selected">选择状态</option>
                  <option class="place-text" value=''>全部</option>
                  <option value="release">在招</option>
                  <option value="expired">下线</option>
           </select> -->
                <!-- 搜索筛选 -->
                <!-- <div class="search-input right">
                <input type="text" placeholder="请输入招聘简章标题" [(ngModel)]="model.name" (keyup)="inputKeyup($event)"><span (click)="goSearch(model.name)"><img src="assets/images/search.png"></span> -->
                <!-- 搜索关键词匹配列表 -->
                <!-- <div class="keyup-block" *ngIf="keywordList.length>0">
                    <div class="keyup-list" *ngFor = "let key of keywordList" (click)="goSearch(key.name)">{{key.name}}</div>
                </div> -->
                <!-- </div> -->
            </div>
        </div>
        <!-- 没有内容存在时 -->
        <div *ngIf="countList==0" class="none-block">
            <img src="assets/images/resume-search-fail.png"><br/> 暂未找到招聘简章
        </div>
        <!-- 循环列表 -->
        <div class="college-list-cont" *ngIf="countList>0">
            <ul>
                <li>标题</li>
                <li>招聘单位</li>
                <li>发布时间</li>
                <li>到期时间</li>
                <li>操作</li>
            </ul>
            <!-- *ngFor="let n of collegeList;let i = index;" -->
            <div>
                <ul class="college-li" *ngFor="let n of rulesList;let i = index;">
                    <li><span *ngIf='n.name'>{{n.name}}</span><span *ngIf='!n.name'>暂无</span></li>
                    <li><span *ngIf='n.department_name'>{{n.department_name}}</span><span *ngIf='!n.department_name'>暂无</span></li>
                    <li><span *ngIf='n.add_time'>{{n.add_time | date:"yyyy-MM-dd HH:mm:ss"}}</span><span *ngIf='!n.add_time'>暂无</span></li>
                    <li><span *ngIf='n.effect_time'>{{n.effect_time | date:"yyyy-MM-dd HH:mm:ss"}}</span><span *ngIf='!n.effect_time'>暂无</span></li>
                    <li>
                        <span (click)="goRulesDetails(n.briefid)">查看</span>
                        <span (click)="goRulesEditor(n)">修改</span>
                        <span (click)="deleteRules(n.briefid)">删除</span>
                        <!-- <span>收藏</span>
                        <span>分享</span> -->
                    </li>
                </ul>
            </div>
        </div>
        <!-- 分页部分 -->
        <div class='paging-block' [class.isPageHeight]="countList>0">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>
    </div>
</div>