<!-- 企业认证协议 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
  <div class="black-bg" (click)="dismiss(false)"></div>
  <div class="model-cont">
    <!-- 上层提示部分 -->
    <div class="model-head clear">
        <div class="left">企业认证协议</div>
        <button class="right" (click)="dismiss(false)">×</button>
    </div>
    <div class="agreen">
      「工作啦」企业认证协议<br/>
      欢迎您使用「工作啦」认证服务！<br/>
      为使用「工作啦」认证服务（简称为：本服务），您应当阅读并遵守《认证协议》（简称为：本协议）。
      请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。<br/>
      除非您已阅读并接受本协议所有条款，否则您无权使用本服务。您购买或使用「工作啦」软
      件内的任何收费服务，即视为您已阅读并同意本协议的约束。<br/>
      一.协议条款的确认和接受<br/>
      1.1在用户决定使用相关认证服务之前，请仔细阅读本收费协议条款。用户必须在完全同意
      以下条款的前提下，才能进行购买程序。「工作啦」将根据以下收费协议条款为用户提供认证服务。用户在享受「工作啦」认证服务时必须完全、严格遵守本协议条款。<br/>
      1.2协议适用主体范围<br/>
      「工作啦」提供的网络服务采用收费与免费结合的方式，用户须先成为「工作啦」普通注册
      用户之后，才能继续进行购买服务。通过购买程序支付费用后，用户就可以轻松享受「工作
      啦」认证服务。在开始购买程序之前，请用户首先同意并接受以下服务条款。<br/>
      1.3本服务内容<br/>
      本协议为您与「工作啦」《用户协议及隐私说明》的补充协议，是其不可分割的组成部分，
      与其构成统一整体。本收费协议中未约定事项以《用户协议及隐私说明》中的约定为准。本
      协议与前述协议存在冲突的，以本协议为准。<br/>
      本协议内容同时包括「工作啦」可能不断发布的关于本服务的相关协议、业务规则等内容。
      上述内容一经正式发布，即为本协议不可分割的组成部分，用户同样应当遵守。<br/>
      1.4「工作啦」根据互联网的发展和中华人民共和国有关法律、法规的变化，不断地完善服
      务质量并依此修改服务条款。「工作啦」有权就本协议随时更新，并在软件内以公告的形式或通过您留下的联系方式通知您。用户的权利以及义务的表述、购买程序及收费标准，均以最新的服务条款为准。
      用户如果不同意该条款的修改并不影响已发生责任及义务的承担；如果用户在条款变更后继续访问或使用「工作啦」，则视为用户已经接受对本协议的修改。<br/>
      二、服务说明<br/>
      认证服务，指按照「工作啦」的指定方式支付一定服务费用之后，用户可以享有「工作啦」
      平台提供的企业认证标识、职位权限、沟通权限、查看权限等特权服务，简称为：本服务。「工作啦」可能会根据不同的企业或组织类型，要求用人单位提供不同的对应的认证资质，目前，「工作啦」提供企业认证的增值服务。同时，「工作啦」也可能会根据用户的需求、产品及服务类型的变化等，对现有认证服务种类进行调整以及不断推出新的增值服务种类。具体增值服务种类及服务内容及费用标准以相关服务页面公布、实际提供的内容为准。用户可以自行根据需要选择相应服务。
      用户可通过登录「工作啦」，免费查询其消费服务的详情（包括其购买的认证服务名称、服务权益、购买时间、标示价格、即时服务状态等）。<br/>
      三、术语定义<br/>
      3.1 企业认证服务：是指符合一定条件的用户可以对企业帐号申请企业认证。「工作啦」根据用户的申请及其提交的资料和信息，自行或者委托第三方审核机构进行审核，并根据审核情况确定认证结果和认证信息的服务。<br/>
      3.2 认证审核：指用户向「工作啦」提出申请，「工作啦」根据法律规定及双方的约定和保证，基于用户提交的资料和信息，对用户的企业帐号进行帐号资质审核。<br/>
      3.2.1 单位审核：指用户向「工作啦」提出申请，「工作啦」根据法律规定及双方的约定和保证，对用户提交的企业主体资质证明或其所拥有的权利证明资料和信息进行甄别及核实的过程；<br/>
      3.2.2 身份审核：指企业账号向「工作啦」提出申请，「工作啦」根据法律规定及双方的约定和保证，对企业账号使用人进行身份审查核定的过程。<br/>
      3.3 第三方审核机构：指「工作啦」委托对用户进行认证审核（单位审核、身份审核）的第三方机构。<br/>
      3.4 单次认证：指用户向「工作啦」发起的本服务申请，每发起一次申请为一个认证。<br/>
      3.5 补充认证：指用户以获得认证成功或年审认证成功的审核结果为目的发起的本服务申请，在新订单的审核过程中，
      用户自发补充或者根据「工作啦」及/或「工作啦」委托第三方审核机构的要求所补充的资料作为同一次认证的审核范围。<br/>
      3.6 变更认证：指用户认证成功后，申请变更、修改用户信息所产生的认证,在变更审核过程中，用户自发补充或者根据「工作啦」及/或「工作啦」委托第三方审核机构的要求所补充的资料同样作为同一认证的审核范围。<br/>
      3.7 认证结果：是指「工作啦」及其委托的第三方审核机构按照用户的申请进行认证审核后，「工作啦」向用户输出的认证结果，认证结果只包括成功和失败两种情形。<br/>
      3.8 认证成功：指「工作啦」自行或委托第三方审核机构对用户提交的资料和信息进行甄别及核实，在完成所有审核流程后，由「工作啦」作出认证成功的判断。
      针对认证成功的用户，「工作啦」将根据本协议确定用户的认证帐号名称，生成认证标识及认证权益，开通相应的高级功能及高级权限。未按期完成年审认证或者年审认证失败的用户不属于认证成功用户。<br/>
      3.9 系统通知用户调整申请内容：指用户在申请认证过程中，因不满足认证成功条件，而被「工作啦」以系统通知的方式要求作调整或补充的情形。<br/>
      3.10 认证失败：指「工作啦」直接或委托第三方审核机构对用户的资料进行甄别及核实，在完成所有审核流程后，由「工作啦」作出认证失败判断，包括但不限于以下任一情形：<br/>
      3.10.1 由于用户原因，经「工作啦」三次系统通知用户调整申请内容，用户仍未能满足帐号单位资质审核及身份审核要求的；<br/>
      3.10.2 由于用户原因，自用户付费之日起三十日，用户仍未能满足单位审核及身份审核要求之一的；<br/>
      3.10.3 因其他原因而不能认证成功的情形。<br/>
      3.11 审核服务费用：指「工作啦」根据用户的申请对用户所提交的资料和信息进行认证审核而产生的费用。<br/>
      四、收费标准及服务开通<br/>
      4.1收费标准<br/>
      4.1.1－本服务是「工作啦」提供的认证服务，用户须在按照本服务的收费标准支付相
      应费用后，方可使用本服务。<br/>
      4.1.2－收费费用标准：服务费用的具体资费政策以「工作啦」软件内届时公布的内容
      为准，「工作啦」有权随时修改本服务的资费政策（包括但不限于服务价格）、订购方式，并
      在「工作啦」软件内予以公布。如用户不同意按照届时「工作啦」制定（修改）并公布的资
      费政策（包括但不限于服务价格）、订购方式订购本服务，用户将不能获得本服务任何部分或全部，
      如已获得本服务用户应自行主动取消本服务，但「工作啦」有权不予退还用户已支付的任何服务费用。
      但用户已经付费取得的服务内容、服务时长等，可以在原有范围内使用，如因服务内容变更导致无法继续使用的，用户可以申请退还未使用部分的费用。<br/>
      4.2服务开通<br/>
      4.2.1重要约定：用户通过ApplePay、支付宝、微信等方式购买「工作啦」认证审核服务，支付成功应当以款项到达「工作啦」指定账户为准。
      用户支付一旦成功，支付即确定完成。认证无论成功或失败，用户都需要支付成本费用（审核服务费）。
      若认证审核失败，审核费用不予退还（除非符合免费认证资格且已支付认证费用），通知中心会提示您补填或修改资料，请您及时补填资料，详情请留意企业认证通知。若发生异议用户可通过客服专线查询处理。<br/>
      4.2.2－非经「工作啦」许可，用户不得以以下方式开通服务。<br/>
      （1）以营利、经营等非个人使用的目的为自己或他人开通本服务；<br/>
      （2）通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式为自己或他人开通本服务；<br/>
      （3）通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本服务；<br/>
      （4）通过非「工作啦」指定的方式为自己或他人开通本服务；<br/>
      （5）通过侵犯「工作啦」或他人合法权益的方式为自己或他人开通本服务，如通过第三方平台由他人代充代付；<br/>
      （6）通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本服务。<br/>
      3.3服务期限<br/>
      本服务中认证可能存在相关期限，如有期限限制以用户自行选择并支付相应服务费用的期限为准，用户也可以登陆「工作啦」“我的”-“企业认证”界面显示有效期限。<br/>
      4.4风险提示<br/>
      4.4.1－在使用「工作啦」支付系统时，用户必须仔细确认自己的帐号及支付金额，若因为用户自身输入账号错误、
      操作不当或不了解支付计费方式等因素造成充错账号、错选支付种类等情形而损害自身权益，不得因此要求「工作啦」作任何补偿或赔偿。<br/>
      4.4.2－若用户以非法的方式，或使用非「工作啦」所指定的支付方式进行支付，「工作啦」不保证该支付顺利或者正确完成。
      若因此造成用户权益受损时，「工作啦」不会作任何补偿或赔偿，「工作啦」同时保留随时冻结或终止该用户账号资格及使用各项支付服务的权利。<br/>
      五、用户权利及义务<br/>
      5.1 用户应保证其提供给「工作啦」或者第三方审核机构的所有资料和信息的真实性、合法性、准确性和有效性。
      如用户提供服务或内容需要取得相关法律法规规定的许可或进行备案的，用户应当主动进行明确说明并提交相应的许可或备案证明。
      否则，「工作啦」有权拒绝或终止提供本服务，并依照本协议对违规帐号进行处罚。因此给「工作啦」或第三方造成损害的，你应当依法予以赔偿。<br/>
      5.2 用户同意授权「工作啦」将其提交的资料和信息委托第三方审核机构进行甄别核实，第三方审核机构有权出于认证审核之必要查阅、使用及保存上述资料及信息，
      并有权受「工作啦」委托以自身名义与用户就认证审核事宜进行联系与沟通，用户应当配合第三方审核机构在认证审核过程中所提出的相关需求。<br/>
      5.3 用户不得通过非「工作啦」授权的其他任何渠道使用本服务，如非因「工作啦」或者第三方审核机构原因导致的认证失败、认证流程过长等后果，「工作啦」及第三方审核机构不承担责任。<br/>
      5.4 用户理解并且同意，用户提交的资料和信息是作出认证结果的重要依据，任何资料和信息的变更将可能影响认证结果，用户应当在资料和信息变更时及时提出补充认证申请，
      否则「工作啦」有权依照本协议约定对用户补充认证进行驳回，并不退还认证费用。「工作啦」因此遭受损失的，用户也应当一并赔偿。<br/>
      5.5 帐号资质审核成功后，用户可以拥有对应高级功能，用户使用高级功能时应遵守法律、行政法规等法律规范和本协议或相关服务条款的规定，
      否则，「工作啦」有权不经通知随时视行为情节对违规用户的帐号采取包括但不限于限制、停止使用高级功能等措施。<br/>
      5.6 认证成功后，用户有权以认证信息所公示的身份对外运营，并通过认证标识区别于其他非认证企业用户。<br/>
      5.7重要约定：注册、运营账号用户主体不得泄露通过使用本服务获取的任何用户信息。
      因违反前述约定导致用户隐私泄露的，用户应当自行承担相应后果；给「工作啦」造成损失的，「工作啦」可以要求用户承担违约责任并赔偿损失。<br/>
      5.8用户同意不会采用收费或免费的方式，全部或部分地展示用户购买的增值服务内容，用户购买的「工作啦」服务内容属于「工作啦」的产品秘密，用户应当予以保密。<br/>
      5.9用户在使用本服务时不得利用本服务从事以下行为，包括但不限于：<br/>
      （1）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、
      社会公德以及侮辱、诽谤、淫秽、暴力的内容；<br/>
      （2）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利
      的内容；<br/>
      （3）虚构事实、隐瞒真相以误导、欺骗他人；<br/>
      （4）发表、传送、传播广告信息及垃圾信息；<br/>
      （5）从事其他违反法律法规、政策及公序良俗、社会公德等的行为。<br/>
      （6）通过任何方式搜集本服务中其他用户的用户名、电子邮件等相关信息，并以发送垃圾
      邮件、连锁邮件、垃圾短信、即时消息等方式干扰、骚扰其他用户；<br/>
      （7）通过本服务发布包含广告、宣传、促销等内容的信息。<br/>
      
      5.10对自己行为负责<br/>
      用户充分了解并同意，用户必须为自己注册帐号下的一切行为负责，包括但不限于用户所发
      表的任何内容以及由此产生的任何后果。用户应对本服务中的内容自行加以判断，并承担因
      使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。
      若因用户违反「工作啦」《用户协议及隐私说明》或本协议内容，「工作啦」因此冻结账号及
      终止账号使用者资格，不得因此要求「工作啦」作任何补偿或赔偿。<br/>
      5.11用户应自行负责妥善且正确地保管、使用、维护其在「工作啦」申请取得的账户及账户密码。用户应对其账户密码采取必要和有效的保密措施。
      非「工作啦」原因致使用户账户密码泄漏以及因用户保管、使用、维护不当造成损失的，「工作啦」无须承担与此有关的任何责任。<br/>
      5.12用户明确了解并同意，本协议第3.2条款约定的付费方式为代收费运营商托收的付款方式，
      用户通过此种付费方式付费可能存在一定的商业风险（包括但不限于不法分子利用用户账户或银行卡等有价卡等进行违法活动），
      该等风险均会给用户造成相应的经济损失。为此，用户同意「工作啦」不对用户的前述风险和损失承担任何责任；
      并且，「工作啦」不承担向不法分子追究侵权责任或者代其向用户承担损失的任何责任和义务。<br/>
      六、「工作啦」的权利义务<br/>
      6.1 保护用户信息是「工作啦」的一项基本原则，
      「工作啦」将会采取合理的措施保护用户所提交的一切资料及信息。
      除法律法规规定及及用户与「工作啦」约定的情形外，
      「工作啦」不会向其他第三方公开、透露上述资料及信息。
      用户基于申请本服务的需要，授权「工作啦」将其提交的资料和信息委托第三方审核机构进行甄别核实，
      在本协议许可的范围内，「工作啦」及第三方审核机构对相关资料及信息采用专业加密存储与传输方式进行传输及使用，保障用户的信息安全。<br/>
      6.2 为依法保障企业认证服务的规范有序，
      「工作啦」有权根据相关法律法规的规定及行业标准，
      针对用户的不同认证需求制定不同的认证审核标准及要求，并有权在必要时对相关标准及要求进行修改。<br/>
      6.3 「工作啦」有权根据用户提交的资料及信息，确定帐号资质审核结果，生成认证标识及认证信息，开通相应的高级功能及高级权限。<br/>
      七、【认证服务规范】<br/>
      7.1 【认证流程】<br/>
      7.1.1 用户申请本服务需要登录申请页面按照「工作啦」提示提交有关资料及信息。
      你在申请本服务时应仔细阅读并同意本协议，你对本服务的接受、购买、提交资料和信息、付款等行为即视为你已阅读并同意受本协议的约束。<br/>
      7.1.2 用户在完成在线申请及资料提交流程，并完成审核费用支付后，「工作啦」会在3个工作日内展开认证审核工作，用户应积极配合「工作啦」或第三方审核公司的审核需求，并有权随时了解、查询审核进度。<br/>
      7.1.3 用户同意「工作啦」可自行委托第三方审核机构对其提交的资料和信息进行甄别核实，并在完成所有审核流程后，
      由「工作啦」独立作出帐号资质审核成功或者失败、帐号名称审核成功或者失败、认证成功或者认证失败的判断，
      并以前述判断为依据确定用户的认证帐号，生成认证标识及认证信息，开通相应的高级功能及高级权限等，用户应当予以配合。<br/>
      7.1.4 审核完成后，「工作啦」将反馈以下审核结果：<br/>
      7.1.4.1 认证审核成功，「工作啦」将作出认证成功的判断，确定用户的认证帐号名称，生成认证标识及认证信息，开通相应的高级功能及高级权限；<br/>
      7.1.4.2 认证失败，「工作啦」将告知用户认证失败的原因。<br/>
      7.1.5 审核成功用户应根据以下条款申请年审认证：<br/>
      7.1.5.1 用户帐号资质审核成功后帐号审核成功状态将会被保留一年（起算日为帐号资质审核成功日）。
      用户如需持续保留帐号审核成功状态，保持高级功能的申请、使用权利，则应自帐号资质审核成功之日起一年内发起并完成年审认证，
      年审认证流程及认证审核标准与原认证审核一致。如用户未能及时完成年审认证或者帐号资质审核失败，其帐号审核成功状态终止，高级功能、高级权限的使用权将被取消。<br/>
      7.1.6 用户向「工作啦」或者第三方审核机构提供的资料和信息如有变更的，应当及时采取以下措施：<br/>
      7.1.6.1 如处于认证审核过程中的资料和信息发生变更，用户应立即通知「工作啦」或负责审核订单的第三方审核机构更新有关资料及信息；<br/>
      7.1.6.2 如认证成功后资料和信息发生变更，用户应及时申请补充订单变更有关资料及信息；<br/>
      7.1.6.3 如认证成功后「工作啦」发现资料和信息存在错误，用户应及时申请补充订单更正有关资料及信息。<br/>
      7.2 【认证规则】<br/>
      7.2.1 用户理解并同意：微信公众平台认证服务内容仅限于对用户提交的资料及信息进行甄别与核实，
      「工作啦」将对前述资料及信息进行合理、谨慎的形式审查，但在「工作啦」的合法权限和合理能力范围内，
      「工作啦」无法实质审查用户的实际经营、运营以及推广等行为，并不为此提供任何担保。<br/>
      7.2.2 「工作啦」有权自行或委托第三方审核机构甄别核实包括但不限于以下内容：<br/>
      7.2.2.1 用户是否拥有合法主体资质，以及是否取得提供服务或内容相应的权利或授权；<br/>
      7.2.2.2 帐号运营者主体身份，以及是否经过用户明确授权；<br/>
      7.2.2.3 其他「工作啦」或第三方审核机构认为需要审核的内容。用户应就上述内容提供相应的证明文件予以证明并承担真实性、合法性、准确性的瑕疵担保责任。<br/>
      7.3 认证信息内容由「工作啦」根据审核情况进行确定，并在认证帐号审核成功后生成，主要包括用户主体资质和权利等可有效证明的信息。「工作啦」有权根据平台规范运营的需要调整认证信息的内容及格式。<br/>
      7.4 为保障「工作啦」安全运营及其他用户的合法权益，若「工作啦」发现或经他人举报用户的帐号存在任何违反法律法规、
      《用户协议与隐私说明》及本协议等各项规范规则的情况，或「工作啦」认为或经第三方通知用户的帐号存在或可能存在上述情况，
      不管该帐号是否已经认证成功，「工作啦」均有权立即冻结该账号并进一步采取拒绝提供年审认证服务等方式停止为该用户提供本服务。<br/>
      八、变更及免责条款<br/>
      8.1「工作啦」有权随时修改本协议的任何条款，一旦本协议的内容发生变动，
      「工作啦」将会在网站上公布修改之后的协议内容，若用户不同意上述修改，
      则可以选择停止使用「工作啦」服务。「工作啦」也可选择通过其他适当方式（比如系统通知）向用户通知修改内容。
      如果不同意「工作啦」对本协议相关条款所做的修改，用户有权停止使用「工作啦」服务。如果用户继续使用「工作啦」服务，则视为用户接受「工作啦」对本协议相关条款所做的修改。<br/>
      8.2用户充分了解并同意，由于互联网服务的特殊性，「工作啦」可能会按照相关法规、双
      方约定或在其他必要时，中止或终止向用户提供本服务，届时，「工作啦」会依法保护用户
      的合法权益。用户在此同意并确认：「工作啦」提供服务时可能会涉及到虚拟物品（包括但
      不限于道具、材料等），该虚拟物品仅在服务运营期内有效，服务停止时，虚拟物品将随服
      务停止运营而消失。但对于用户已经购买但尚未使用的虚拟货币，「工作啦」将以法定货币方式或用户接受的其他方式退还用户。「工作啦」将在终止服务公告中明确虚拟货币申请退还的期限和具体的退还政策。<br/>
      九、服务的中断和终止<br/>
      9.1因发生「工作啦」不可抗拒的事由，如政府行为、不可抗力，导致服务无法继续，「工
      作啦」将尽快通知用户，但不承担由此对用户造成的任何损失并不退还任何费用。
      9.2如用户严重违反本服务条款中的内容，「工作啦」有权在不通知用户的情况下立即终止
      用户已购买的部分或所有服务，以及暂时或永久取消用户的「工作啦」账户和使用权限，但
      不退还任何已缴纳的服务费用。<br/>
      9.3「工作啦」未行使或延迟行使其在本协议项下的权利并不构成对这些权利的放弃，而单
      一或部分行使其在本协议项下的任何权利并不排斥其任何其它权利的行使。「工作啦」随时
      有权要求用户继续履行义务并承担相应的法律责任。<br/>
      十、违约责任<br/>
      10.1如因「工作啦」违反有关法律、法规或本协议项下的任何条款而给用户造成损失，「工
      作啦」同意承担由此造成的损害赔偿责任。但「工作啦」的赔偿责任亦以用户就使用服务所
      支付「工作啦」的价款为限。<br/>
      10.2用户若违反协议或相关法令，导致「工作啦」、或其关系企业、受雇人、受托人、代理
      人及其它相关履行辅助人因此而受到损害或支出费用（包括但不限于由法律诉讼、行政程序
      等所支出的诉讼费用、律师费用、实际遭受损失的费用等），用户应负担损害赔偿责任。
      10.3对第三方损害的处理<br/>
      用户有违反本协议任何行为，导致任何第三方损害的，用户应当独立承担责任；「工作啦」
      因此遭受损失的，用户也应当一并赔偿。<br/>
      十一、其他条款<br/>
      11.1本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。如本服务条款与中华人民共和国法律相抵触时，则该等条款将按法律规定重新修订，而其它条款则依旧有效并具有法律效力。<br/>
      11.2如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，
      任何一方均可向「工作啦」所在地的人民法院提起诉讼。<br/>
      11.3本协议项下所有的通知均可通过软件内通知、电子邮件、短信或常规的信件传送等方式进行；上述任何一种方式通知于发送之日视为已送达收件人。 <br/>     
    </div>
  </div>
</div>