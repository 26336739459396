import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'numtransform'})
export class NumTransform implements PipeTransform {
  constructor(public _sanitizer: DomSanitizer){

  }  
  transform(min,max) {   
    if(min && max){
      if(Number(min)>0 && Number(max)>0){
        
        //最小值大于最大值的情况(特殊情况)      
        if(Number(min)>Number(max)){
          let minCopy = min;
          let maxCopy = max;
          min = maxCopy;
          max = minCopy;
        }

        if(Number(min)==1 && Number(max)==90000){
          return '不限';
        }else if(Number(min)!=1 && Number(min)<10000 && Number(max)==90000){
          return Number(min)/1000+'千以上';
        }else if(Number(min)!=1 && Number(min)>=10000 && Number(max)==90000){
          return Number(min)/10000+'万以上';
        }else if(Number(min)==1 && Number(max)<10000 && Number(max)!=90000){
          return Number(max)/1000+'千以下';
        }else if(Number(min)==1 && Number(max)>=10000 && Number(max)!=90000){
          return Number(max)/10000+'万以下';
        }else if(Number(min)!=1 && Number(min)>=10000 && Number(max)>=10000 && Number(max)!=90000){
          return Number(min)/10000+'万-'+Number(max)/10000+'万';
        }else if(Number(min)!=1 && Number(min)<10000 && Number(max)<10000 && Number(max)!=90000){
          return Number(min)/1000+'千-'+Number(max)/1000+'千';
        }else if(Number(min)!=1 && Number(min)<10000 && Number(max)>=10000 && Number(max)!=90000){
          return Number(min)/1000+'千-'+Number(max)/10000+'万';
        }else{
          return '不限';
        }
      }else{
        return '不限';
      }

    }else{
      return '不限';
    }
    
    
  }
}