import { Component, OnInit, Input, Output } from '@angular/core';
import { DataApi,Local,Constants,Config,Opera } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';
@Component({
  selector: 'app-college-details-student',
  templateUrl: './college-details-student.component.html',
  styleUrls: ['./college-details-student.component.css']
})
export class CollegeDetailsStudentComponent implements OnInit {
  public config: Config;
  // 筛选条件
  public model={
      collegeid:'',
      education:'',
      major:'',
      offset:0,
      limit:9
  }
  public resumeList=[];//文章列表
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  @Output() modelShow = false;//是否打开弹窗
  @Output() collegeName = '';//高校名称
  @Output() collegeIdentity:any;//高校认证信息
  @Output() orgList=[];//院系列表
  public college:any;//高校详情
  public isInit=false;
  public majorListOne=[];//一级专业列表
  public majorListTwo=[];//二级专业列表
  public majorOne='';//专业类别一级
  public educationList=[];//学历列表
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {


    this.opera.initConfig('college','college-details-student');

    // setTimeout(()=>{
    //   this.opera.initConfig('college','college-campustalk');
    // },200)
    this.model.collegeid=this.local.get(Constants.collegeid);//赋值高校id
    if(this.model.collegeid)
    {
      this.getCollegeById();//获取高校详情
      this.getResumeList();//获取生源速览
      this.getMajorList();//获取专业列表信息
      this.getEducationList();//获取学历列表信息
    }

  }
  //-----跳转简历详情
  goDetails(n){
    this.router.navigate(['home/college-resumedetails'], {
      queryParams: {
        resumeid:n,
        positionid:''
      }
    });
  }

 //立即沟通
 goChat(n,id){
   this.opera.goChat(n,id);
 }
 //收藏
 goCollect(val){
   val = this.opera.manageResumeArchived(val);
 }

  //生源速览
  getResumeList(){
    this.opera.loading();
    this.dataApi.getSearchResumeList(this.model).then((res:any)=>{
     console.log("生源速览",res,this.model);
      this.opera.loadiss();
      if(res)
      {
        this.resumeList=res.list;
        this.countList=res.count;
        this.pageCount =Math.ceil(res.count/9);
      }


    }).catch((err)=>{
      //console.log(err);
      this.opera.loadiss();
    })
  }

  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数-----');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getResumeList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }
  }

  //监听majorOne
  majorOneChange(){
    // //console.log("第一级专业值");
    // //console.log(this.majorOne);
    // //console.log("专业列表");
    // //console.log(this.config.major);
    this.majorListTwo=[];
    this.model.major='';
    for(let n of this.config.major){
      if(n.parentcode==this.majorOne){
        this.majorListTwo.push(n);
      }
    }
    // //console.log("第二级专业列表");
    // //console.log(this.majorListTwo);
  }


  //提示先选一级专业
isShowTwo(){
  if(!this.majorOne){
    this.toastr.warning("请先选择一级专业");
    return;
  }
 }

 //获取学历列表信息
 getEducationList(){
  if(this.dataApi.getEducationCode()){
    //console.log("获取学历列表本地存储-----college-resume");
    //console.log(this.dataApi.getEducationCode());
    this.educationList = this.dataApi.getEducationCode();
  }else{
    this.dataApi.getDictDate('education').then((res:any)=>{
      //console.log("获取学历列表-----college-resume");
      //console.log(res);
      if(res)
      {
        this.educationList = res;
        this.dataApi.setEducationCode(res);
      }
    }).catch((error)=>{
      //console.log(error);
    });
  }
}

//获取一级专业列表
getMajorListOne(){
  for(let n of this.config.major){
    if(n.level==1){
      this.majorListOne.push(n);
    }
  }
}

//获取专业列表信息
getMajorList(){
  if(this.dataApi.getMajorCode()){
    this.config.major = this.dataApi.getMajorCode();
    this.getMajorListOne();//获取一级专业列表
  }else{
    this.dataApi.getDictDate('major').then((res:any)=>{
      //console.log("获取专业列表-----college-resume");
      //console.log(res);
      if(res)
      {
        this.config.major = res;
        this.getMajorListOne();//获取一级专业列表
        this.dataApi.setMajorCode(res);
      }

    }).catch((error)=>{
      //console.log(error);
    });
  }
}

  //通过高校id获取高校详情
  getCollegeById(){
    this.dataApi.getCollageDetails(this.model.collegeid).then((res:any)=>{
      if(res)
      {
        this.isInit = true;
        this.college = res;
        this.collegeName = this.college.name;
        if(res['audit_switch']['faculty_authcation']){
          this.getOrgList(res.collegeid);
        }
      }

    }).catch((err)=>{
      this.toastr.error(err);
      this.isInit = true;
    });
  }

  //获取列表
  getOrgList(id){
    this.opera.loading();
    this.dataApi.getCollegePart(id).then((res:any)=>{
      this.orgList = res;
      this.opera.loadiss();
    }).catch((err)=>{
      this.opera.loadiss();
      this.toastr.error("获取院系列表失败，请稍后重试");
    })
  }

  //立即申请合作
  goCollegeIdentity(){
    if(this.college.audit_switch.faculty_authcation){//是否打开院系认证审核开关
      this.collegeName = '';
    }
    this.modelShow = true;//打开校招申请确认弹窗
    this.opera.scrollTop();//页面滚动函数
  }

    //获取申请校招返回值
    Modeldismiss(event){
      ////console.log('子组件返回结果-----college-details');
      ////console.log(event);
      this.opera.scrollTrue();
      if(event){
        if(event === true){
          this.local.remove(Constants.facultyid);
        }else{
          this.local.set(Constants.facultyid,event);
        }
        //跳转认证页面
        this.local.set(Constants.collegeid,this.college.collegeid);
        this.router.navigateByUrl("/home/company-identity-details");
        this.modelShow = false;
      }else{
        this.modelShow = false;
      }

    }
}
