import { Component, OnInit } from '@angular/core';
import { DataApi,Config,Opera,Local,Constants } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from  '@angular/router';

@Component({
  selector: 'app-college-details-newstxt',
  templateUrl: './college-details-newstxt.component.html',
  styleUrls: ['./college-details-newstxt.component.css']
})
export class CollegeDetailsNewstxtComponent implements OnInit {
  public config: Config;
  public item:any;//文章详情
  public articleid='';//文章id
  constructor(public dataApi: DataApi,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public local:Local,
    public opera:Opera,
    public toastr: ToastrService) { 
      this.config = Config.getInstance();//实例化config
    }

  ngOnInit() {
    this.opera.initConfig('college','college-campustalk');
    this.articleid=this.local.get(Constants.articleid);
    this.getArticleDetails();
  }

  getArticleDetails(){
    this.opera.loading();
    this.dataApi.getArticleDetails(this.articleid).then((res:any)=>{
      this.item = res;
      this.opera.loadiss();  
    }).catch((err)=>{
      this.opera.loadiss(); 
      this.toastr.error("出现错误，请稍后重试");
      // this.navCtrl.pop();
    })
  }

}
