import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'salaryFilter'})
export class SalaryFilter implements PipeTransform {
  transform(value): string {
    
    if(value){
      value = value+'';        
      if (value.indexOf('.') > -1) {                      
        return value.substr(0, value.indexOf('.') + 2);          
      }else{
        return value;
      }
      
    }else{
      return value;
    }

  }
}