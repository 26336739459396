<!-- 企业认证资质说明 -->
<!-- 遮罩层公共样式 -->
<div class="model-block select-block" [class.ishidden]='!isShow'>
  <div class="black-bg" (click)="dismiss(false)"></div>
  <div class="model-cont">
    <!-- 上层提示部分 -->
    <div class="model-head clear">
        <div class="left">企业认证协议</div>
        <button class="right" (click)="dismiss(false)">×</button>
    </div>
    <div class="explain">
        <img src="assets/images/department-identity-explain.png" alt="企业认证资质说明">
    </div>
  </div>
</div>
