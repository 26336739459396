import { Component, OnInit ,Input, Output,ViewChild } from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate } from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute,Router } from  '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import { ModelAddressAddComponent } from '../model-address-add/model-address-add.component';

@Component({
  selector: 'app-company-details-address',
  templateUrl: './company-details-address.component.html',
  styleUrls: ['./company-details-address.component.css']
})
export class CompanyDetailsAddressComponent implements OnInit {
  public config: Config;
  @ViewChild('address', { static: true }) child: ModelAddressAddComponent;
  @Output() modelShow = false;//是否打开弹窗
  public locations=[];
  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public opera:Opera,
    public dialog: MatDialog,
    public formatDate: FormatDate,
    public toastr: ToastrService) { this.config = Config.getInstance();}

  ngOnInit() {
    this.opera.initConfig('company','company-details'); 
    this.getDepartment();//获取企业信息
    this.getRegionList();//获取地区字典
  }

  //设为默认
  myisactive(item){
    //let loader = this.tip.presentLoading(''); 
    //console.log(item.is_default);   
    this.dataApi.employerDefaultPositionInfo(item.lid).then((res:any)=>{
      //loader.dismiss();
      if(item.is_default){
        this.locations.forEach((val)=>{
          val.is_default = false;         
        }); 
        this.config.department.locations=this.locations;
        this.dataApi.setLocalDepartment(this.config.department);
      }else{
        this.locations.forEach((val)=>{
          if(item.lid == val.lid){
            val.is_default = true;
          }else{
            val.is_default = false;
          }        
        }); 
        this.config.department.locations=this.locations;
        this.dataApi.setLocalDepartment(this.config.department);     
      } 
     
      this.toastr.success('设置成功');      
    }).catch((error) => {
      ////console.log(error);    
      this.toastr.error(error);
      //loader.dismiss();  
    });

    
  }

   //删除地址
   addressDelete(lid,n){
    if(this.locations.length<=1){
      this.toastr.warning('请至少保留1个企业地址');
      return
    }
    
    ////console.log('准备删除');
    //let loader = this.tip.presentLoading('');    
    this.dataApi.employerDeletePositionInfo(lid).then((res:any)=>{    
      ////console.log(res);     
      //loader.dismiss();
      this.toastr.success('删除成功');
      this.config.department.locations.splice(n,1);
      this.locations=this.config.department.locations;
      this.dataApi.setLocalDepartment(this.config.department);
      //loader.dismiss();
    }).catch((error) => {
      ////console.log(error);    
      this.toastr.error(error);
      //loader.dismiss();  
    });
  }
 
  //添加地址
  goAddAddress(){
    if(this.locations.length==5){
       this.toastr.warning("企业地址总数不得超过5个");
       return;
    }
    this.modelShow=true;
    this.opera.scrollTop();
    this.child.initData("","添加地址");
  }

  //编辑地址
  goAddress(lid){
    this.modelShow=true;
    this.opera.scrollTop();
    this.child.initData(lid,"编辑地址");
  }

  //子组件返回数据
  Modeldismiss(event){
    this.modelShow=false;
    this.opera.scrollTrue();
    if(event){
      //重新赋值地址
      this.dataApi.getDepartment().then((res:any)=>{
        //console.log("企业信息获取线上数据");    
        //console.log(res);
        this.locations=res.locations;
        this.dataApi.setLocalDepartment(res);
      }).catch((error) => {
       //console.log(error);
       this.toastr.error(error);
      });
    }
  }

   //获取地区字典
   getRegionList(){
    if(this.dataApi.getRegionCode()){
      this.config.region = this.dataApi.getRegionCode();
    }else{
      //获取地区字典
      this.dataApi.getDictDate('region').then((res:any)=>{
        this.config.region = res;     
        this.dataApi.setRegionCode(res);
      }).catch((error)=>{       
        //console.log(error);      
      });
    }
  }

  //获取企业信息
  getDepartment(){
    if(this.dataApi.getLocalDepartment()){
      this.config.department = this.dataApi.getLocalDepartment();
      if(this.config.department.locations){
        this.locations=this.config.department.locations;
      }
      //console.log("企业信息本地存储");
      //console.log(this.dataApi.getLocalDepartment());
    }else{
      this.dataApi.getDepartment().then((res:any)=>{
        //console.log("企业信息获取线上数据");    
        //console.log(res);
        if(this.config.department.locations){
          this.locations=res.locations;
        }
        this.dataApi.setLocalDepartment(res);
      }).catch((error) => {
       //console.log(error);
       this.toastr.error(error);
      });
  }
    
} 

}
