import { Component, OnInit } from '@angular/core';
import {Config, DataApi, Local, Opera} from "../../provider";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";

declare let Txp;
declare let tvp;
declare let layui;
declare let  ActiveXObject;
declare let  VSwf;
declare let  QRCode;
@Component({
  selector: 'app-air-camp-history-detail',
  templateUrl: './air-camp-history-detail.component.html',
  styleUrls: ['./air-camp-history-detail.component.css']
})
export class AirCampHistoryDetailComponent implements OnInit {


  public config: Config;

  // @Output() isShowVoiceCallModel = false;//是否打开弹窗(简历收件箱)
  // @Output() isShowPositionPushModel = false;//是否打开弹窗(简历收件箱)
  // @ViewChild('voiceCallModel') voiceCallModel: VoiceCallModelComponent;
  // @ViewChild('sengMsgModel') sengMsgModel: ModelSendMsgComponent;
  // @Output() SengMsgModel ={
  //   userName:'',
  //   userPic:'',
  //   talentid:'',
  //   graduation_school:'',//学校
  //   major_text:'',//专业
  //   gender:'',
  //   address:'',
  //   positionid:''
  // };//打开单品发送极速短信用户对象

  public TJTalentModel = false;
  public TJSchoolModel = false;
  public addNewquestionModel = false;
  public downLineModel = false;
  public schoolList = [1,2,3,5,6,77,8,9,1,4343,332,2];
  public yuanxi1 = [];
  public yuanxi2 = [];
  public yuanxi3 = [];
  public  area1 = [];
  public  area2 = [];
  public  area3 = [];
  public airCampustalk:any;
  public currentTab = "1";
  public talentTab = "1";//学生简历内部tab
  public questionTab = "1";//学生简历内部tab
  public isAllSeleteCollege=false; //是否全选高校推荐
  public ApplyCollegeData={
    all:[],
    passed: [],
    to_audit: [],
    not_passed: [],
  };

  public TJTalentModelData = {
    collegeid:'',
    collegeids:[],
    majorOne:'',
    majorTwo:'',
    majorList:[],
    education:'',
    educationList:[],
    sex:'',
    areaOne:'',
    areaTwo:'',
    areaList:[],
    resuleCount:0,//用户选择的学生数量
    filtCount:0,//少选出来的数量
    SmsContent:'',
  }
  //推广到学生
  public seleteCollegeids = [];
  public allCollege = [];
  public MyActivityCount = 0;//单品 活动邀请的数量

  //问答
  public questionList = [];
  public AllquestionCount = 0;
  public addNewquestionData={
    question:'',
    answer:'',
    acid:''
  }
  public seleteQuestionList= [];
  public isALlSeletequestion = false;//是否全选问题
  public currentAnswerTalent:any;//当前回复的问题模型

  //学生简历列表
  public resumeList = [];
  public AllresumeCount = 0;

  //未投递的学生数
  public AirStudentCount = 0;
  public shareImageUrl = "";

  public reasons = [];
  constructor(
      public dataApi: DataApi,
      public local:Local,
      private router: Router,
      public opera:Opera,
      public toastr: ToastrService,

  ) {
    this.config = Config.getInstance();//实例化config

  }

  ngOnInit() {

    let that = this;
    this.opera.initConfig('position','airCampHistory');

    let hash = location.hash;

    let arr = hash.split('?')
    let acid = "";
    if(arr.length>1) {
      let urlStr = arr[1];
      let itemAr = urlStr.split('&');

      let openid = '';
      let unionid = '';
      for (let item of itemAr) {
        let dataArr = item.split('=');
        if (dataArr.length >= 2) {
          if (dataArr[0] == 'acid') {
            acid = dataArr[1]
          }
        }
      }
    }
    console.log('airCampHistoryacid',acid);

    let data = {
      acid:acid
    }

    // this.router.navigateByUrl("home/creatAirCampus");
    let collegeid = localStorage.getItem('collegeid')
    this.dataApi.getAirCampustalkWithid(acid,collegeid).then((res:any) => {
      if(res)
      {
        console.log('getAirCampustalk',res)
        this.airCampustalk = res;

        //a30662p0khi
        //e0354z3cqjp
        //video_url
        if(this.airCampustalk.majors==null)
        {
          this.airCampustalk.majors = [];
        }
        if(this.airCampustalk.video_url)
        {
          let video_url = this.airCampustalk.video_url
          let video_cover = this.airCampustalk.video_cover
          let video_urlArr =  video_url.split("/");
          let lastStr  = video_urlArr[video_urlArr.length-1];
          let newArr =   lastStr.split(".");
          //var video = new tvp.VideoInfo();
          // video.setVid(newArr[0]);
          // var player =new tvp.Player();
          // player.create({
          //   width:500,
          //   height:300,
          //   video:video,
          //   autoplay:false,
          //   modId:"mod_player", onwrite:function(){
          //     // alert("播放器显示完毕");
          //     console.log('播放器显示完毕',video,lastStr,video_urlArr)
          //   },
          //   pic: that.airCampustalk.video_cover
          // });


          // //events
          // player.onplaying = function(vid) {
          //   console.log('onplaying',vid);
          //   that.dataApi.setVideoPlayCount(that.airCampustalk.acid).then((res:any)=> {
          //   }).catch(() => {})
          // }
          // player.onended = function(vid) {
          //   console.log('onended',vid);
          // }
          var player =  new Txp.Player({
            containerId: 'mod_player',
            width:500,
            height:300,
            vid: newArr[0]
          });
          player.on('playStateChange', function(callback){
            console.log('playStateChange',callback);
            if(callback.state == 1){
              that.dataApi.setVideoPlayCount(that.airCampustalk.acid).then((res:any)=> {}).catch(() => {})
            }
          });
        }
        //获取学生简历列表
        if(res.college_audit_status==2)
        {
          this.getApplyCollegeList()

          // this.getSmsContent();
          //获取单品的余额
          this.getDepartment();

          // this.getAllCollege();
          // this.getMajorList();
          // this.getEducationList();

          this.getAirQuestion(1);

          //获取学生简历列表
          this.getAirCampResumeList(true,0);

          this.getAirStudentCount();

          this.getShareImage();

          this.getApplyCollegeList();


        }
        if(res.college_audit_status==3)
        {
          //limit_apply_time
          //limit_day
          let nowDate = new Date();
          let nowTime = nowDate.getTime();  // 当前时间戳

          let endDate = new Date(res.limit_apply_time); // 报名开始时间
          let endDateTime = endDate.getTime();  // 报名结束时间戳
          if(nowTime-endDateTime>=0){
            console.log('可以申请')
            res.isApply = true;
            res.ApplyStr = "";
          }
          else
          {
            res.isApply = false;
            res.ApplyStr = "如需申请，请在"+res.limit_day+"个工作日后提交";
          }


        }
      }
    }).catch(() => {
    })
  }
  creatAirCampus(){
    this.router.navigateByUrl("home/creatAirCampus");
  }
  tjSchoolMode(){

    this.TJSchoolModel = true;
  }
  tjTalentMode(){
    this.smsDepartment();
    this.TJTalentModel= true;
  }
  closeModel(){
    this.TJSchoolModel = false;
    this.TJTalentModel = false;
  }

  //获取全部高校
  getAllCollege() {
    this.dataApi.getCollegeList({limit: 999}).then((res:any) => {
      if(res)
      {
        let arr = [];
        for (let n of res.list)
        {
          if(n.total_student>=1000)
          {
            arr.push(n);
          }
        }
        this.allCollege = arr;
      }
    }).catch(() => {
    })
  }
  //获取专业列表信息
  getMajorList(){
  }

  seletecollegeChange(){
    if(this.seleteCollegeids.length>=5)
    {
      this.toastr.warning("最多选择5所高校")
      return;
    }
    for(let n of this.seleteCollegeids)
    {
      if(n.collegeid == this.TJTalentModelData.collegeid)
      {
        this.toastr.warning("该高校已选择")
        return;
      }
    }
    console.log('seletecollegeChange',this.TJTalentModelData.collegeid)
    for (let n of this.allCollege)
    {
      if(n.collegeid == this.TJTalentModelData.collegeid)
      {
        console.log("seletecollegeChange----n",n)

        this.seleteCollegeids.push(n);
      }
    }
    // console.log("this.seleteCollegeids",this.seleteCollegeids)
    this.smsDepartment();

  }
  deleteCollegeChange(id){
    this.TJTalentModelData.collegeid = "";
    let arr = [];
    for(let n of this.seleteCollegeids)
    {
      if(n.collegeid != id)
      {
        arr.push(n);
      }
    }
    this.seleteCollegeids = arr;
    this.smsDepartment();

  }
  //选择院系
  seleteyuanxiChange(n){
    this.TJTalentModelData.majorTwo = '';
    let yuanxi3 = [];
    for (let yuanxi of this.yuanxi2)
    {
      if(yuanxi.parentcode == this.TJTalentModelData.majorOne)
      {
        yuanxi3.push(yuanxi)
      }
    }
    this.yuanxi3 = yuanxi3;
  }
  seleteyuanxiChange2(){
    if(this.TJTalentModelData.majorList.length>=5)
    {
      this.toastr.error('最多选择5个专业')
      return;
    }
    for (let n of this.TJTalentModelData.majorList)
    {
      if(n.code == this.TJTalentModelData.majorTwo)
      {
        return;
      }
    }
    for (let n of this.yuanxi3)
    {
      if(n.code == this.TJTalentModelData.majorTwo)
      {
        this.TJTalentModelData.majorList.push(n);
        break;
      }
    }
    // console.log('majorList',this.TJTalentModelData.majorList)
    this.smsDepartment();
  }
  deletemajorChange(code){
    // this.TJTalentModelData.majorOne = "";
    if(this.TJTalentModelData.majorTwo ==code)
    {
      this.TJTalentModelData.majorTwo = "";

    }
    // this.yuanxi3 = [];
    let arr = [];
    for(let n of this.TJTalentModelData.majorList)
    {
      if(n.code != code){
        arr.push(n)
      }
    }
    this.TJTalentModelData.majorList = arr;
    this.smsDepartment();
  }
  //获取学历列表信息
  getEducationList(){
    if(this.dataApi.getEducationCode()){
      this.config.education = this.dataApi.getEducationCode();
    }else{
      //获取学历列表
      // this.opera.loading();
      this.dataApi.getDictDate('education').then((res:any)=>{
        this.dataApi.setEducationCode(res);
        this.opera.loadiss();
      }).catch((error)=>{
        //console.log(error);
        this.opera.loadiss();
      });
    }
  }
  //区域字典
  getAreaList(){
    this.dataApi.getDictDate('region').then((res:any)=>{
      if(res){
        for(let i of res){
          if(i.level===1){
            this.area1.push(i)
          }else if(i.level===2){
            this.area2.push(i)
          }
        }
        let arr = [];
        for (let n of this.area1)
        {
          let item = {
            code: n.code,
            level: 2,
            order: 0,
            parentcode: n.code,
            parenttext: null,
            relation_code: null,
            relation_desc: "",
            text: n.text+'-全省',
          };
          arr.push(item);
        }
        // console.log('全省',this.area1,this.area2,arr);
        this.area2 = [...arr,...this.area2]
      }
    })
  }
  seleteSexChange(){
    this.smsDepartment()
  }
  seleteEducationChange(){
    if(this.TJTalentModelData.educationList.length>=5)
    {
      this.toastr.error('最多选择5个学历')

      return;
    }

    for (let n of this.TJTalentModelData.educationList)
    {
      if(n.code == this.TJTalentModelData.education)
      {
        return;
      }
    }
    for (let n of this.config.education)
    {
      if(n.code == this.TJTalentModelData.education)
      {
        this.TJTalentModelData.educationList.push(n);
        break;
      }
    }
    this.smsDepartment();
  }
  deleteEducationChange(code){
    let newArr =[];
    if(code == this.TJTalentModelData.education)
    {
      this.TJTalentModelData.education = "";
    }
    for (let n of this.TJTalentModelData.educationList)
    {
      if(n.code != code)
      {
        newArr.push(n);
      }
    }
    this.TJTalentModelData.educationList = newArr;
    this.smsDepartment();

  }

  seleteareaChange(n){

    this.TJTalentModelData.areaTwo = '';

    let area3 = [];
    for (let area of this.area2)
    {
      if(area.parentcode == this.TJTalentModelData.areaOne)
      {
        area3.push(area)
      }
    }
    this.area3 = area3;
  }
  seleteareaChange2(){
    if(this.TJTalentModelData.areaList.length>=5)
    {
      this.toastr.error('最多选择5个地区')
      return;
    }
    for (let n of this.TJTalentModelData.areaList)
    {
      if(n.code == this.TJTalentModelData.areaTwo)
      {
        return;
      }
    }
    for (let n of this.area3)
    {
      if(n.code == this.TJTalentModelData.areaTwo)
      {
        this.TJTalentModelData.areaList.push(n);
        break;
      }
    }
    this.smsDepartment()
  }
  deleteAareaChange(code){
    let newArr =[];
    if(code == this.TJTalentModelData.areaTwo)
    {
      this.TJTalentModelData.areaTwo = "";
    }
    for (let n of this.TJTalentModelData.areaList)
    {
      if(n.code != code)
      {
        newArr.push(n);
      }
    }
    this.TJTalentModelData.areaList = newArr;
    this.smsDepartment()
  }
  resuleCountClick(type){

    if(type=="+")
    {
      this.TJTalentModelData.resuleCount+=1;
    }
    else
    {
      if(this.TJTalentModelData.resuleCount==0)
      {
        return;
      }
      this.TJTalentModelData.resuleCount-=1;
    }
  }
  downLineClick(){
    this.downLineModel = true;
  }
  editAirCampClick(){
    this.router.navigateByUrl("home/creatAirCampus");

  }
  shareAirCampClick(){

    if(!this.shareImageUrl)
    {
      this.toastr.warning('网络繁忙，请稍后再试')
      return;
    }
    let that  = this;
    let text = "<p style='text-align: center;margin-top: 20px;margin-bottom: 15px' class='qrcode_t'>手机扫码分享</p> <div style='margin-left: 20px;margin-top: 20px' class='qrcode' id='qrcode'></div>";
    layui.use(['layer'], function() {
      var layer = layui.layer;
      layer.open({
        type: 1,
        title: false, //不显示标题
        area: ['240px', '280px'], //宽高
        content: text
      });
      setTimeout(()=>{
        let qrcode1 = document.getElementById("qrcode");
        console.log("qrcode",qrcode1)
        var qrcode = new QRCode('qrcode', {
          text:that.shareImageUrl,
          width: 200,
          height: 200,
          typeNumber:-1,
          colorDark : '#000000',
          colorLight : '#ffffff',
        });
      },300)
    })
  }
  tabClick(tab){
    this.currentTab = tab;
  }
  talentTabClick(tab){
    this.talentTab = tab;
    this.getAirCampResumeList(true,0)
  }
  questionTabTabClick(tab){
    this.questionTab = tab;

    this.getAirQuestion(1);
  }

  getAirQuestion(page){
    let that = this;
    let data = {
      acid:this.airCampustalk.acid,
      type:'0',
      isAnswer:this.questionTab=="1"?false:true,
      page:page,
      pageSize:'10',
    }
    this.dataApi.getAirQuestion(data).then((res:any) => {
      if(res)
      {
        console.log('getAirQuestion',res,data)

        for(let n of res.list)
        {
          n.selete = false;
        }
        this.questionList = res.list;
        this.AllquestionCount = res.count;
        this.seleteQuestionList=[];
        this.isALlSeletequestion = false;

        layui.use('laypage', function(){
          var laypage = layui.laypage;
          //执行一个laypage实例
          laypage.render({
            elem: 'test1' //注意，这里的 test1 是 ID，不用加 # 号
            ,count: res.count, //数据总数，从服务端得到
            limit:10,
            theme: '#5e4fff',
            layout:['prev', 'page', 'next','skip','count']
            ,jump: function(obj, first){
              //obj包含了当前分页的所有参数，比如：
              console.log("obj.curr",obj.curr); //得到当前页，以便向服务端请求对应页的数据。
              console.log(obj.limit); //得到每页显示的条数
              that.getAirQuestionNonePage(obj.curr)
              //首次不执行
              if(!first){
              }
            }
          });
        });


      }
    }).catch(() => {
    })
  }
  getAirQuestionNonePage(page){
    let data = {
      acid:this.airCampustalk.acid,
      type:'0',
      isAnswer:this.questionTab=="1"?false:true,
      page:page,
      pageSize:'10',
    }
    this.dataApi.getAirQuestion(data).then((res:any) => {
      if(res)
      {
        console.log('getAirQuestion',res,data)

        for(let n of res.list)
        {
          n.selete = false;
        }
        this.questionList = res.list;
        this.AllquestionCount = res.count;
        this.seleteQuestionList=[];
        this.isALlSeletequestion = false;

      }
    }).catch(() => {
    })
  }
  getApplyCollegeList(){
  }
  //单选所有高校推荐
  AllApplyCollegeClick(college){
  }
  //全选高校推荐
  AllQuanClick(){

  }
  //获取符合推荐到学生的人数
  smsDepartment(){

  }
  //推荐的学生参数
  goTJTalent(){


  }
  //获取企业信息
  getDepartment(){
    this.opera.loading();
    this.dataApi.getDepartment().then((res:any)=> {
      this.opera.loadiss();
      if(res)
      {
        let arr = res.quota.product_component_info;
        let listArr = [];
        let activity_invitation = null;
        let activity_invitation_left = 0;
        let activity_invitation_total = 0;
        for (let item of arr) {
          if (item.alias == "activity_invitation") {
            //校招活动申请
            activity_invitation = item;
            activity_invitation_left += item.left;
            activity_invitation_total += item.total;
          }
        }
        if(activity_invitation)
        {
          this.MyActivityCount = activity_invitation_left;
        }
      }
    }).catch((error) => {
      this.opera.loadiss();
      console.log(error);
      this.toastr.error(error);
    });
  }
  //立即推荐到高校就业信息网
  tiCollegeClick(){

    console.log(this.ApplyCollegeData.all)
    let collegeids = [];
    for(let n of this.ApplyCollegeData.all)
    {
      if(n.selete)
      {
        collegeids.push(n.collegeid)
      }
    }
    if(collegeids.length==0)
    {
      this.toastr.warning('未选择高校')
      return;
    }

    let data = {
      acid: this.airCampustalk.acid,
      collegeids:collegeids
    }
    console.log('推广到高校',data)
    this.dataApi.air_campustalk_applyCollege(data).then((res:any)=>{//通过tooken获取企业信息
      console.log('creatAirQuestion',res);
      this.getApplyCollegeList();
    }).catch((error)=>{
      this.toastr.error(error);
    });

  }
  //重新高校到就业信息网
  resetCommitCollege(n){

    let data = {
      acid: this.airCampustalk.acid,
      collegeids:[n.collegeid]
    }
    console.log('推广到高校',data)
    this.dataApi.air_campustalk_applyCollege(data).then((res:any)=>{//通过tooken获取企业信息
      console.log('creatAirQuestion',res);
      this.toastr.success('已重新提交')
      this.getApplyCollegeList();
    }).catch((error)=>{
      this.toastr.error(error);
    });

  }
  //添加新的问题弹窗
  addNewquestionModelClick(type,question){
    this.addNewquestionModel = true;
    this.addNewquestionData.question="";
    this.addNewquestionData.answer="";
    this.addNewquestionData.acid = this.airCampustalk.acid;
    if(type=='answer')
    {
      this.currentAnswerTalent = question;
      this.addNewquestionData.question = question.question;
    }
    console.log(this.currentAnswerTalent)
  }
  addNewquestionBoxHiden(){
    this.addNewquestionModel = false;
    this.currentAnswerTalent =""
    this.addNewquestionData.question = "";
    console.log('addNewquestionBoxHiden')
  }
  //确定添加新的问题
  addNewquestionClick(){
    console.log('this.addNewquestionData',this.addNewquestionData)

    //如果有回复数据 走 回复逻辑
    if(this.currentAnswerTalent)
    {
      if(!this.addNewquestionData.answer)
      {
        return;
      }
      let data = {
        airquestionid:this.currentAnswerTalent.id,
        answer:this.addNewquestionData.answer,
        is_public:true,
      }

      //
      this.dataApi.AnswerTalent(data).then((res:any)=>{//通过tooken获取企业信息
        this.addNewquestionModel = false;
        this.toastr.success('回复成功')
        this.currentAnswerTalent = "";
        this.getAirQuestion(1);
      }).catch((error)=>{
        this.toastr.error(error);
        this.addNewquestionModel = false;
      });

      return;
    }
    if(!this.addNewquestionData.question||!this.addNewquestionData.answer||!this.addNewquestionData.acid)
    {
      return;
    }
    this.dataApi.creatAirQuestion([this.addNewquestionData]).then((res:any)=>{//通过tooken获取企业信息
      console.log('creatAirQuestion',res);
      this.addNewquestionModel = false;
      this.toastr.success('添加成功')
      this.getAirQuestion(1);
    }).catch((error)=>{
      this.toastr.error(error);
      this.addNewquestionModel = false;
    });
  }
  questionCellClick(q)
  {
    //未回答，不走编辑逻辑
    if(this.questionTab=='1')
    {
      return;
    }
    let newArr = [];
    let seleteArr = [];
    for(let n of this.questionList )
    {
      if(n.id==q.id)
      {
        n.selete =!n.selete;
      }
      if(n.selete)
      {
        seleteArr.push(n.id);
      }
      newArr.push(n);
    }
    this.seleteQuestionList = seleteArr;
    this.questionList = newArr;
  }
  allSeletequestionClick(){
    let newArr= [];
    let seleteArr = [];

    this.isALlSeletequestion = !this.isALlSeletequestion;
    for(let n of this.questionList )
    {
      n.selete = this.isALlSeletequestion
      newArr.push(n);
      if(n.selete)
      {
        seleteArr.push(n.id)
      }
    }
    this.seleteQuestionList = seleteArr;
    this.questionList = newArr;
  }
  //设置问题是否公开
  questionMenuClick(type){
    // type ：open hidden
    //setQuestionIsPublic
    let data = {
      questionids:this.seleteQuestionList,
      is_public:type=="open"?true:false
    }
    console.log("设置问题是否公开",data)
    this.dataApi.setQuestionIsPublic(data).then((res:any)=>{
      console.log("设置问题是否公开su",data)
      this.getAirQuestion(1);
      this.toastr.success('设置成功')
    }).catch((err)=>{
      console.log(err);
    })
  }
  getSmsContent(){

  }
  getAirCampResumeList(isFirst,page){

    // talentTab  1全部， 2 投递  3查看
    let offset = page*10;
    let data = {};
    data = {
      offset:offset,
      limit:'12',
      is_delivery:true
    }

    console.log('开始请求空中宣讲会简历列表',this.airCampustalk.acid,data)
    this.dataApi.getAirCampResumeList(this.airCampustalk.acid,data).then((res:any)=>{
      console.log("getAirCampResumeList",res,data)
      if(res)
      {
        this.AllresumeCount = res.count;
        this.resumeList = res.list;

        for (let val of  this.resumeList )
        {
          let arr = [];
          val.yixiang = "";
          val.youshiArr = [];
          for (let item1 of val.pro_skills)
          {
            val.yixiang += item1.text+"    "
            if(item1.contrast_list){
              for (let youshi of item1.contrast_list)
              {
                val.youshiArr.push(youshi.text);
              }
            }
          }
        }

        let that = this;
        layui.use('laypage', function(){
          var laypage = layui.laypage;
          //执行一个laypage实例
          laypage.render({
            elem: 'resumePage' //注意，这里的 test1 是 ID，不用加 # 号
            ,count: res.count, //数据总数，从服务端得到
            limit:12,
            theme: '#5e4fff',
            layout:['prev', 'page', 'next','skip','count']
            ,jump: function(obj, first){
              //obj包含了当前分页的所有参数，比如：
              console.log("obj.curr",obj.curr); //得到当前页，以便向服务端请求对应页的数据。
              console.log(obj.limit); //得到每页显示的条数
              if(isFirst)
              {
                return;
              }
              that.getAirCampResumeList(false,obj.curr)
              //首次不执行
              if(!first){
              }
            }
          });
        });




      }

    }).catch((err)=>{
      console.log(err);
    })

  }
  //下线宣讲会
  downLineModekClick(isdown){
    if(!isdown)
    {
      this.downLineModel = false;
      return;
    }
    this.downLineModel = false;


    let data = {
      is_online:false
    }
    this.dataApi.downLine(this.airCampustalk.acid,data).then((res:any)=>{
      console.log("下线",)
      this.toastr.success('下线成功')
      this.router.navigateByUrl("home/airCampHistory");

    }).catch((err)=>{
      console.log(err);
    })

  }
  checkFlash(){
    var hasFlash = 0;　　　　 //是否安装了flash
    var flashVersion = 0;　　 //flash版本
    if(document.all) {
      var swf = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
      if(swf) {
        hasFlash = 1;
        VSwf = swf.GetVariable("$version");
        flashVersion = parseInt(VSwf.split(" ")[1].split(",")[0]);
      }
    } else {
      if(navigator.plugins && navigator.plugins.length > 0) {
        var swf = navigator.plugins["Shockwave Flash"];
        if(swf) {
          hasFlash = 1;
          var words = swf.description.split(" ");
          for(var i = 0; i < words.length; ++i) {
            if(isNaN(parseInt(words[i]))){
              continue;
            }
            flashVersion = parseInt(words[i]);
          }
        }
      }
    }

    if(hasFlash){
      console.log("您安装了flash,当前flash版本为: " + flashVersion + ".x");
    }else {
      console.log("您没有安装flash");
    }
  }
  goDetails(n){
    this.router.navigate(['home/college-resumedetails'], {
      queryParams: {
        resumeid:n,
        positionid:''
      }
    });
  }
  //去pro主会场
  gotogwlAirCamp(){

    if(this.local.get('accesstoken'))
    {
      let token = this.local.get('accesstoken');
      let accountid = this.local.get('accountid');
      let collegeid = this.local.get('collegeid');
      window.open(this.config.JumpMainWeb+token+'&accountid='+accountid+'&route='+'airCampusDetail','_blank')
    }
  }
  getAirStudentCount(){
    let collegeid = this.local.get('collegeid');
    let data = {
      acid:this.airCampustalk.acid,
      collegeid:collegeid
    }

    this.dataApi.getAirStudentCount(data).then((res:any)=>{
      console.log("getAirStudentCount",res)

      if(res)
      {
        this.AllresumeCount = res
      }

    }).catch((err)=>{
      console.log(err);
    })

  }
  getShareImage(){
    let data =  {
      acid:this.airCampustalk.acid
    }
    this.dataApi.getAircampustalkImage(this.airCampustalk.acid,data).then((res:any)=>{
      if(res)
      {
        this.shareImageUrl = res
      }
    }).catch((error)=>{
      this.opera.loadiss();
    });
  }

}
