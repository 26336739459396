<!-- 页头部分 -->
<div class="contentRight-header">企业中心 - 企业认证</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
    <router-outlet></router-outlet>
    <app-model-auth-explain [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-auth-explain>
    <app-model-auth-agreenment [isShow]="modelTwoShow" (onVoted)="ModelTwodismiss($event)"></app-model-auth-agreenment>
    <div class="college-block">
        <!-- 页头部分 -->
        <div class="college-block-head">企业认证</div>
        <!--认证说明-->
        <div *ngIf="config.lastIdentity.status=='3'&&config.lastIdentity.remark" class="title-text identity-text">
            <span>审核未通过原因：</span>{{config.lastIdentity.remark}}
        </div>
        <!-- 单位认证 -->
        <div class="title-img"><span>01</span><b>单位认证</b></div>
        <!-- 基本资料 -->
        <div class="title-text">基本资料</div>
        <div class="sign-block">
            <!-- 公司名称 -->
            <div class="sign-list">
                <span>公司名称：</span>
                <input type="text" [(ngModel)]="model.department_name" placeholder="请填写公司名称">
            </div>
            <!-- 单位类型 -->
            <div class="sign-list select-list">
              <span>单位类型：</span> 
              <select *ngIf="config.department.industry!='0202'" autocomplete="off" [(ngModel)]="model.organization_type">
                  <option class="place-text" value='' disabled hidden selected="selected">选择单位类型</option>
                  <option *ngFor="let type of config.organization_type" [value]="type.code">{{type.text}}</option>
              </select>
              <div *ngIf="config.department.industry=='0202'" class="selected-text disabled-text">{{config.lastIdentity.organization_type_text}}</div>
            </div>
            <!-- 统一社会信用代码 -->
            <div *ngIf="materail">
              <div *ngFor="let list of materail">
                <div *ngIf="list.item_code==model.organization_type">
                  <div *ngFor="let num of list.field_infos">
                    <div *ngIf="num.field=='register_number'&&num.required" class="sign-list">
                      <span>统一社会信用代码：</span>
                      <input type="text" [(ngModel)]="model.register_number" placeholder="请填写统一社会信用代码" maxlength="18">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 图片上传 -->
            <div *ngIf="model.organization_type&&model.organization_type!='11'" class="title clear">
                <div class="left">单位资质上传</div>
                <div class="right" (click)="goDepartmentExplain()"><img src="assets/images/department-identity-explainImg.png">资质说明</div>
            </div>
            <!-- 上传图片 -->
            <div class="identity-imgBlock" *ngIf="materail">
                <div class="identity-imgList" *ngFor="let list of materail">
                    <!-- 非保险行业 -->
                    <div *ngIf="list.item_code==model.organization_type&&config.department.industry!='0202'" class="identity-imgIf">
                      <div class="text" *ngIf="list.remark">{{list.remark}}</div>
                      <div class="publish-position-list" *ngFor="let m of list.material_infos">
                        <ul class="list-content identity-block">
                          <li class="di-img" *ngIf="!model[m.material_field]">
                            <label class="uploadImg-label" [for]="m.material_field">
                              <img *ngIf="m.material_example" [src]="m.material_example"/>
                              <img *ngIf="!m.material_example" src="assets/images/auth-bgcompany.png"/>
                              <span>上传{{m.material_name}}</span>
                            </label>
                            <input class="uploadImg" [id]="m.material_field" (change)="previewDepartmentPic($event,m.material_field)" type="file" accept="image/jpeg,image/jpg,image/png">
                          </li>
                          <li *ngIf="model[m.material_field]" class="di-img" (click)="openMenu(m.material_field)">
                            <label class="uploadImg-label" [for]="m.material_field">
                              <img [src]="model[m.material_field]"/>
                            </label>
                            <input class="uploadImg" [id]="m.material_field" (change)="previewDepartmentPic($event,m.material_field)" type="file" accept="image/jpeg,image/jpg,image/png">
                          </li>
                        </ul>
                      </div>
                    </div>
                </div>
                <!-- 保险行业 -->
                <div *ngIf="config.department.industry=='0202'&&secureMaterail" class="identity-imgIf">
                  <div class="text" *ngIf="secureMaterail.remark">{{secureMaterail.remark}}</div>
                  <div class="publish-position-list" *ngFor="let m of secureMaterail.material_infos">
                    <ul class="list-content identity-block">
                      <li class="di-img" *ngIf="!model[m.material_field]" (click)="openMenu(m.material_field)">
                        <label class="uploadImg-label" [for]="m.material_field">
                          <img *ngIf="m.material_example" [src]="m.material_example"/>
                          <img *ngIf="!m.material_example" src="assets/images/auth-bgcompany.png"/>
                          <span>上传{{m.material_name}}</span>
                        </label>
                        <input class="uploadImg" [id]="m.material_field" (change)="previewDepartmentPic($event,m.material_field)" type="file" accept="image/jpeg,image/jpg,image/png">
                      </li>
                      <li *ngIf="model[m.material_field]" class="di-img" (click)="openMenu(m.material_field)">
                        <label class="uploadImg-label" [for]="m.material_field">
                          <img [src]="model[m.material_field]"/>
                        </label>
                        <input class="uploadImg" [id]="m.material_field" (change)="previewDepartmentPic($event,m.material_field)" type="file" accept="image/jpeg,image/jpg,image/png">
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- 备注 -->
                <div *ngIf="model.organization_type&&model.organization_type!='11'" class="auth-remark">
                  <ul class="identity-text clear">
                    <li>*注：</li>
                    <li>1.上传营业执照照片，信息需清晰完整，请确保单位名称与提交审核公司一致；</li>
                    <li>2.仅限PNG或JPG格式，大小限制在3M以内；</li>
                    <li>3.如为复印件、黑白扫描件，需加盖单位公章；</li>
                    <li>4.不可使用翻拍照片提交审核；</li>
                    <li>5.上传图片不可有与工作啦无关的标识或水印；</li>
                    <li>6.证照仅用于审核，不会向第三方透露，请放心上传！</li>
                  </ul>
                </div>
            </div>
        </div><!--sign-block-->
        <!-- 身份认证 -->
        <div class="title-img"><span>02</span><b>身份认证</b></div>
        <!-- 基本资料 -->
        <div class="title-text">基本资料</div>
        <div class="sign-block">
            <!-- 姓名 -->
            <div class="sign-list">
                <span>姓名：</span>
                <input type="text" [(ngModel)]="model.operator_name" placeholder="请填写姓名">
            </div>
            <!-- 身份证号码 -->
            <div class="sign-list select-list">
              <span>身份证号码：</span> 
              <input type="text" [(ngModel)]="model.id_number" placeholder="请填写身份证号码">
            </div>
            <!-- 图片上传 -->
            <div class="title clear">
                <div class="left">身份证上传</div>
            </div>
            <!-- 上传图片 -->
            <div class="identity-imgBlock">
                <div class="identity-imgList">
                    <div class="identity-imgIf">
                      <div class="publish-position-list">
                        <ul class="list-content identity-block">
                          <li *ngIf="!model.id_card" class="di-img">
                            <label class="uploadImg-label" for="id_card">
                              <img src="assets/images/auth-bgpersonal.png"/>
                              <span>上传手持身份证</span>
                            </label>
                            <input class="uploadImg" id="id_card" (change)="previewDepartmentPic($event,'id_card')" type="file" accept="image/jpeg,image/jpg,image/png">
                          </li>
                          <li *ngIf="model.id_card" class="di-img">
                            <label class="uploadImg-label" for="id_card">
                              <img [src]="model.id_card"/>
                            </label>
                            <input class="uploadImg" id="id_card" (change)="previewDepartmentPic($event,'id_card')" type="file" accept="image/jpeg,image/jpg,image/png">
                          </li>
                        </ul>
                      </div>
                    </div>
                </div>
                <!-- 备注 -->
                <div class="auth-remark">
                  <ul class="identity-text clear">
                    <li>*注：</li>
                    <li>1.请上传清晰、真实的手持身份证照片，并且用手指遮挡身份证后五位；</li>
                    <li>2.仅限PNG或JPG格式，大小限制在3M以内；</li>
                    <li>3.您的身份信息仅用于审核，不会向第三方透露，请放心上传！</li>
                  </ul>
                </div>
            </div>
        </div><!--sign-block-->
        <div class="di-pact">
            <img *ngIf="isConfirm" tappable (click)="isConfirm=!isConfirm" src="assets/images/department-identity-confirm.png">
            <img *ngIf="!isConfirm" tappable (click)="isConfirm=!isConfirm" src="assets/images/department-identity-unconfirm.png">
            我已看过并同意<span (click)="goDepartmentPact()">《企业认证协议》</span>
        </div>
        <!-- 保存 -->
        <div class="sign-submit">
            <button class="purple" (click)="uploadIdentity()">提交认证</button>
        </div>
    </div><!--college-block-->
</div><!--homepage-block-->