<!-- 遮罩层公共样式 -->
<div class="model-block" [class.ishidden]='!isShow'>
  <div class="black-bg" (click)="dismiss(false)"></div>
  <!-- 申请高校合作 -->
  <div class="model-cont0" *ngIf="!item">
    <!-- 上层提示部分 -->
    <div class="model-head clear">
      <div class="left">申请高校合作</div>
      <button class="right" (click)="dismiss(false)">×</button>
    </div>
    <!-- 下层主体内容部分 -->
    <div class="model-content">
      <div *ngIf="!faculty_authcation" class="model-list clear">
        <div class="left">申请院校</div>
        <div class="left">{{name}}</div>
      </div>
      <!--<div *ngIf="faculty_authcation" class="model-list clear">-->
        <!--<div class="left">申请学院</div>-->
        <!--<div class="left">-->
          <!--<select name="COrg" id="COrg" [(ngModel)]="facultyid" autocomplete="off">-->
            <!--<option class="place-text" value='' disabled selected="selected">请选择院系</option>-->
            <!--<option *ngFor="let a of orgList" [value]="a.facultyid">{{a.name}}</option>-->
          <!--</select>-->
        <!--</div>-->
      <!--</div>-->

      <div class="model-list clear">
          <div class="left">合作要求</div>
          <div class="left">
            1、完善公司基本信息，包括：公司名称、公司简介、发布职位等；<br/>
            2、资质审核：提交公司营业执照等相关证件；
          </div>
        </div>
        <div class="model-list clear">
            <div class="left">校招内容</div>
            <div class="left">
               1、职位招聘：正在招聘的职位将自动进入高校就业中心后台，审核通过后，可在高校就业信息网展示；<br/>
               2、品牌展示：用人单位资质经审核后，有机会在高校就业信息网单位品牌栏目展示；<br/>
               3、优先资格：经过高校审核的企业，将会有机会优先参加招聘会、宣讲会；
            </div>
        </div>
    </div>
    <!-- 底部操作部分 -->
    <div class="model-foot">
      <button class="purple" (click)="dismiss(true)">知道了,我要提交申请</button>
      <!-- <button  class="purple" (click)="goWork()">工作啦认证</button> -->
    </div>
  </div>
  <!-- 查看申请校招状态 -->
  <div class="model-cont" *ngIf="item">
    <!-- 上层提示部分 -->
    <!--<div class="model-head clear">-->
        <!--<div class="left">高校合作审核状态</div>-->
        <!--<button class="right" (click)="dismiss(false)">×</button>-->
      <!--</div>-->
      <!-- 下层主体内容部分 -->
      <div class="model-content">
        <!-- 审核中 -->
        <!--<div class="info-list" *ngIf="item.status==1">-->
            <!--<div class="info-head"><img src="assets/images/indentity-one.png"></div>-->
            <!--<div class="info-title">正在审核中，请耐心等待...</div>-->
            <!--<div class="info-text">预计1-2个工作日。</div>-->
        <!--</div>-->
        <!-- 审核成功 -->
        <div >
            <!--<div class="info-head"><img src="assets/images/indentity-three.png"></div>-->
            <!--&lt;!&ndash; <div class="info-title">审核已通过</div> &ndash;&gt;-->
            <!--<div class="info-text">恭喜您，申请高校合作已通过。</div>-->
            <div style="width: 100%;height: 300px" class="kefuBg">

                <div style="width: 396px;height: 44px;margin: 30px auto 0 auto;overflow: hidden" *ngIf="item.status==1">
                    <img style=" width: 44px;height: 44px;float: left;margin-right: 5px" src="../../assets/images/alert_loading.png">
                    <p style="height: 20px; line-height: 20px;text-align: left; margin-left: 5px;color: #666666;font-size: 16px;margin-top: 5px">审核中</p>
                    <p style="height: 18px; line-height: 18px;text-align: left; margin-left: 5px;color: #666666;font-size: 14px;">正在审核中,预计1-2个工作日，请耐心等待...</p>
                </div>

                <div style="width: 396px;height: 44px;margin: 30px auto 0 auto;overflow: hidden" *ngIf="item.status==2">
                    <img style=" width: 44px;height: 44px;float: left;margin-right: 5px" src="../../assets/images/alert_success.png">
                    <p style="height: 20px; line-height: 20px;text-align: left; margin-left: 5px;color: #666666;font-size: 16px;margin-top: 5px">审核已通过</p>
                    <p style="height: 18px; line-height: 18px;text-align: left; margin-left: 5px;color: #666666;font-size: 14px;">恭喜您，申请高校合作已通过。</p>
                </div>

                <div style="width: 396px;height: 44px;margin: 30px auto 0 auto;overflow: hidden" *ngIf="item.status==3" (click)="dismiss(true)">
                    <img style=" width: 44px;height: 44px;float: left;margin-right: 5px" src="../../assets/images/alert_error.png">
                    <p style="height: 20px; line-height: 20px;text-align: left; margin-left: 5px;color: #666666;font-size: 16px;margin-top: 5px">审核未通过</p>
                    <p style="height: 18px; line-height: 18px;text-align: left; margin-left: 5px;color: #666666;font-size: 14px;">原因：{{item.remark}}</p>
                </div>

                <div class="guwen_cardbg" *ngIf="config.departmentSelf">
                    <!--396-->
                    <div class="guweb_l">
                        <div style="width: 100%;height: 50px">
                            <img style="width: 50px;height: 50px;border-radius: 5px;float: left;margin-right: 5px;" src="{{config.departmentSelf.avatar}}">
                            <p style="color: #666666;font-size: 16px;margin-left: 55px;text-align: left;height: 25px;line-height: 25px;margin: 0">工作啦校招顾问</p>
                            <p style="color: #333333;font-size: 16px;margin-left: 55px;text-align: left;height: 25px;line-height: 25px;margin: 0">{{config.departmentSelf.name}}</p>
                        </div>
                        <ul style="margin-top: 15px;float: left;width: 250px;">
                            <li style="float: left;height: 20px;">
                                <img style="width:20px;height: 20px " src="../../assets/images/kefu_wx.png">
                                <span style="color: #5E4FFF;font-size: 14px;margin-left: 2px;">微信：</span>
                                <span style="color: #666666;font-size: 14px;margin-left: 5px;">{{config.departmentSelf.wx}}</span>
                            </li>
                            <li  style="float: left;height: 20px">
                                <img style="width:20px;height: 20px " src="../../assets/images/kefu_email.png">
                                <span style="color: #5E4FFF;font-size: 14px;margin-left: 2px;">邮箱：</span>
                                <span style="color: #666666;font-size: 14px;margin-left: 5px;">{{config.departmentSelf.email}}</span>
                            </li>
                        </ul>
                    </div>
                    <img class="guweb_r" src="{{config.departmentSelf.qr_code}}}}"/>
                </div>

                <button  *ngIf="item.status==3" class="purple" style="width: 100px;height: 30px;padding: 0; margin-top: 15px" (click)="dismiss(true)">重新申请</button>


            </div>
        </div>
        <!-- 审核失败 -->
        <!--<div class="info-list" *ngIf="item.status==3">-->
          <!--<div style="width: 580px;height:200px;text-align: center;">-->
            <!--<div class="info-head"><img src="assets/images/indentity-two.png"></div>-->
            <!--<div class="info-title red">审核未通过</div>-->
            <!--<div class="info-text info-reason">原因：{{item.remark}}</div>-->

          <!--</div>-->
          <!--&lt;!&ndash; <div style="width: 290px;height:200px;float: right">-->
            <!--<div class="info-head"><img style="width: 100px;height: 100px" src="../../assets/images/goworklaQRcode.png"></div>-->
            <!--<div style="margin-top: 5px">立即下载/登陆App</div>-->
            <!--<div style="margin-top: 5px">连接更多高校，想招的学生直接聊</div>-->

          <!--</div> &ndash;&gt;-->
          <!--<div>-->

            <!--<p style="color: red">{{no_apply_30day?timestr:""}}</p>-->
            <!--<button class="purple" (click)="dismiss(true)">重新申请</button>-->

          <!--</div>-->

        <!--</div>-->
      </div>
  </div>


</div>
