import { Component,EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.css']
})
export class PagingComponent {
  @Input()  count: number;//分页总数
  @Input()  activeCount: number;//当前激活分页
  public pageList = [1,2,3,4,5];
  public pageMoreList = [1,2,3];
  @Output() onVoted = new EventEmitter<number>();
  
  paging(page: number) {
    this.onVoted.emit(page);
  }

}
