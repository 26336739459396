import { Component, OnInit } from '@angular/core';
import { Constants, DataApi, Local, Opera } from "../../provider";
import { ActivatedRoute, Router } from "@angular/router";
import { PlatformLocation } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { Events } from '../../provider/eventService';
import { ToastrService } from "ngx-toastr";

declare var window;
declare var layui;


@Component({
  selector: 'app-collegeidentity',
  templateUrl: './collegeidentity.component.html',
  styleUrls: ['./collegeidentity.component.css']
})
export class CollegeidentityComponent implements OnInit {
  public isGuanZhu = false;
  public accountid = '';
  public isHavethree = false;
  public isMainland = true;//默认显示 营业执照
  public collegeName = "";
  public orgList = [];//高校院系列表
  public secureMaterail: any;//招聘委托书下载链接
  public department: any;
  public isCommitErr = true;
  public currentCollege: any;
  public authcation_office_phone = false;
  public isType: string = '';
  public callbackUrl: string = '';
  public isName: string = '';
  public model = {
    collegeid: '',
    facultyid: '',//院系id
    proxy_document: '',//招聘委托书
    business_licence: '',//企业营业执照
    id_card_front: '',//身份证正面
    register_number: '',//营业执照注册号
    post_type: "", //职位雷被
    name: '', //职位名称
    post_typeText: "",
    post_nature: '01',//工作性质
    position_salary: '',//月薪范围(字典)
    education: '',//学历要求
    location: {//工作地址
      region: ''//,//区域代码
      //coordinates:[],//坐标
      //address:''//详细地址
    },//工作城市
    locationText: '',//工作地址文字
    intent_count: "",//人数
    responsibility: '',//描述
    majors: [],
    list: [],
    school_permit: "",
    office_phone: '',
    brief: ''
    // currentIndex:0
  }
  public CollegeIdentityModel = {
    status: 0,
    no_apply_30day: false,
    remark: '',
    canApply: false

  };

  public isShowChips = false
  public revokeModule = false;

  constructor(
    public dataApi: DataApi,
    private router: Router,
    public opera: Opera,
    public local: Local,
    private location: PlatformLocation,
    public dialog: MatDialog,
    public events: Events,
    public toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
  ) {
    activatedRoute.queryParams.subscribe(queryParams => {
      console.log('queryParams', queryParams);
      if (queryParams.isShow) {
        this.isShowChips = true
      }
      if (queryParams.istype) {
        this.isType = queryParams.istype;
      }
      if (queryParams.callbackUrl) {
        this.callbackUrl = queryParams.callbackUrl;
      }
      if (queryParams.name) {
        this.isName = queryParams.name;
      }

    })
  }

  ngOnInit() {
    this.opera.initConfig('position', 'collegeidentity');

    if (this.local.get(Constants.collegeid)) {
      this.model.collegeid = this.local.get(Constants.collegeid);
      this.getCollegeById(this.model.collegeid);//获取高校信息
      this.getIdentityMaterial();
      this.singleIdentity(this.model.collegeid);
    }
    else {
      this.toastr.error(this.dataApi.getNoDepardmentID());
      return;
    }


  }
  //通过高校id获取高校详情
  getCollegeById(id) {
    this.opera.loading();
    this.dataApi.getCollageDetails(id).then((res: any) => {
      console.log("获取到高校信息----", res);
      //console.log(res);
      this.currentCollege = res;
      this.isHavethree = res.audit_switch.proxy_document;
      if (res.audit_switch.faculty_authcation) {
        this.getOrgList();
      } else {
        this.collegeName = res.name;
      }

      if (res.audit_switch.authcation_office_phone) {
        this.authcation_office_phone = true;
        console.log('填写固话');
      } else {
        this.authcation_office_phone = false;
      }

      this.opera.loadiss();
    }).catch((err) => {
      this.toastr.error(err);
      this.opera.loadiss();
    });
  }
  //通过高校id获取院系列表
  getOrgList() {
    this.opera.loading();
    this.dataApi.getCollegePart(this.model.collegeid).then((res: any) => {
      // console.log("获取院系列表---");
      // console.log(res);
      this.orgList = res;
      this.opera.loadiss();
    }).catch((err) => {
      this.opera.loadiss();
      //console.log(err);
      this.toastr.error("获取院系列表失败，请稍后重试");
    })
  }
  //获取认证上传的材料标准
  getIdentityMaterial() {
    let model = { collegeid: this.model.collegeid };
    this.dataApi.getMaterial('authentication', model).then((res: any) => {
      console.log('获取认证材料成功', res);
      if (res[0]) {
        for (let list of res[0].material_infos) {
          if (list.material_field == 'proxy_document') {
            this.secureMaterail = list.material_example;
          }
        }
      }
    }).catch((err) => {
      //console.log("获取认证材料失败");
      //console.log(err);
    })
  }
  //上传到服务器
  uploadPic(data, name) {
    let imageData = this.dataApi.formatImage(data)

    this.opera.loading();//加载动画
    this.dataApi.uploadAuthImg({
      value: imageData
    }).then((res: any) => {
      ////console.log(res);
      if (res) {
        if (res.value) {//------------上传之后赋值
          this.model[name] = res.value;
        }
      }
      this.opera.loadiss();//停止加载动画
    }).catch((error) => {
      ////console.log(error);
      this.opera.loadiss();//停止加载动画
      this.toastr.error("图片格式不正确，请上传png/jpg格式的图片");
    });
  }
  //上传图片
  previewPic(event, name) {
    console.log(event);
    console.log(name);
    let imgData = event.target.files[0]; //获取file对象
    console.log('imgData', imgData.size)
    if (parseInt(imgData.size) / 1024 > 1024 * 5) {
      this.toastr.warning('该图片大小超出了5M，请重新上传');
      return;
    }
    if (window.FileReader) {
      var reader = new FileReader();
      reader.readAsDataURL(imgData);
      var that = this;
      reader.onloadend = function (e: any) {
        that.uploadPic(reader.result, name);
      };

    }
  }
  //提交认证
  uploadDepartmentIdentity() {

    let collegeid = this.local.get('collegeid') + ''
    console.log("请重新从高校站内登录", collegeid);
    if (collegeid + '' == 'false') {
      this.toastr.error(this.dataApi.getNoDepardmentID());
      return;
    }
    let filter1 = /^[0-9a-zA-Z]{9}$/;
    let filter2 = /^[0-9a-zA-Z]{15}$/;
    let filter3 = /^[0-9a-zA-Z]{18}$/;
    if (!this.model.business_licence) {
      this.toastr.warning(this.isMainland ? '请上传企业有效证照' : '请上传企业有效证照');
      return;
    }
    if (!this.model.id_card_front) {
      this.toastr.warning('请上传个人有效身份证明');
      return;
    }
    if (!this.model.register_number) {
      this.toastr.warning(this.isMainland ? '请填写企业有效证照号码' : '请填写企业有效证照号码');
      return;
    }
    //只有大陆的校验格式
    if (this.isMainland) {
      if (!this.opera.isRegister_number(this.model.register_number)) {
        this.toastr.warning(this.isMainland ? '统一社会信用代码格式不正确' : '商业登记证号码格式不正确');
        return;
      }
    }

    if (!this.collegeName) {
      if (!this.model.facultyid && this.orgList.length > 0) {
        this.toastr.info("请选择当前申请学院");
        return;
      }
    }
    if (this.isHavethree) {
      if (!this.model.proxy_document) {
        this.toastr.info("请上传招聘委托书");
        return;
      }
    }
    if (this.department) {
      if (this.department.industry == '0604' || this.department.industry == '0605' || this.department.industry == '0606') {
        if (!this.model.school_permit) {
          this.toastr.info("请上传办学许可证");
          return;
        }
      }
    }
    if (this.authcation_office_phone && !this.model.office_phone) {
      this.toastr.warning('请填写办公电话');
      return;
    }

    this.dataApi.uploadDepartmentIdentity(this.model).then((res: any) => {
      this.opera.loadiss();//停止加载动画
      this.toastr.success('申请成功，请等待高校审核');
      if(this.isType == 'milkround'){
        this.router.navigate(['home/college-milk-sign'], {
          queryParams: {
            milkname: this.isName
          }
        });
      } else if(this.callbackUrl == 'Milkround'){
        this.router.navigate(['home/college-details-milk']);
      } else if(this.callbackUrl == 'Campustalk'){
        this.router.navigate(['home/college-details-campus']);
      } else if(this.callbackUrl == 'AirCampustalk'){
        this.router.navigate(['home/airCampusDetail']);
      }else{
        this.CollegeIdentityModel.status = 1;
      }
    }).catch((error) => {
      //console.log(error);
      this.opera.loadiss();//停止加载动画
      setTimeout(() => {
        this.toastr.warning(error);
      }, 600);
    });
  }
  //单个高校认证信息
  singleIdentity(collegeid) {
    if (!collegeid) {
      return;
    }
    this.dataApi.lastCollegeAuthenticationIdentity(collegeid).then((res: any) => {
      if (res) {
        console.log("获取上次认证信息", res);
        this.CollegeIdentityModel = res;
        if (this.CollegeIdentityModel.status == 3) {
          this.isCommitErr = false;
        }
        if (this.CollegeIdentityModel.no_apply_30day) {
          let currentdata = new Date();

          let data = new Date(res.audit_time);
          data.setDate(data.getDate() + 30);
          if (currentdata > data) {
            console.log("用户勾选了30天可以申请",);
            this.CollegeIdentityModel.canApply = true;

          }
          else {
            console.log("用户勾选了30天不可以申请",);
            this.CollegeIdentityModel.canApply = false;

          }
        }
        else {
          this.CollegeIdentityModel.canApply = true;
        }
        if (res.business_license) {
          this.model.business_licence = res.business_license;
        }
        if (res.facultyid) {
          this.model.facultyid = res.facultyid;
        }
        if (res.register_number) {
          this.model.register_number = res.register_number;
        }
        if (res.school_permit) {
          this.model.school_permit = res.school_permit;
        }
        if (res.proxy_document) {
          this.model.proxy_document = res.proxy_document;
        }
        if (res.id_card_front) {
          this.model.id_card_front = res.id_card_front;
        }
        if (res.brief) {
          this.model.brief = res.brief;
        }
        if (res.office_phone) {
          this.model.office_phone = res.office_phone;
        }


        console.log("获取上次认证信息", this.model, res.business_license);

      }
    }).catch((error) => {
      console.log("获取上次认证信息error", error);
      this.opera.loadiss();
    })

  }
  //重新申请
  isCommitErrClick() {
    this.isCommitErr = true;
  }
  //验证手机号码格式
  checkPhone1(n) {
    let phoneModel = {
      format_type: 3,//0:手机号，1：手机号&固话；2.邮箱
      data: n
    }
    this.dataApi.phoneVerification(phoneModel).then((res: any) => {
      if (!res.value) {
        this.toastr.warning("固定电话格式错误");
        return;
      }
    }).catch((err) => {
      //console.log(err);
      this.toastr.error(err);
    })
  }

  // 撤回申请
  openRevoke(){
    this.revokeModule = true;
  }
  downCooperate(){
    this.dataApi.revokeCooperate(this.model.collegeid).then((res:any)=>{
      this.toastr.success("申请已撤回");
      this.CollegeIdentityModel.status = 7;
      this.revokeModule = false;
    }).catch((err)=>{
      console.log("downMilk",err);
    });
  }
}
