<!-- 引导页面-->
<div class="contentRight-header flex_r" >
    <span class="cancelRegister_span flex_r" (click)="cancelRegisterChips()"> <img style="padding: 0" src="../../assets/images/login_close.png">退出</span>
</div>
<div class="guide-page">
  <router-outlet></router-outlet>
    <app-model-address-add #address [isShow]="modelShow" (onVoted)="Modeldismiss($event)"></app-model-address-add>
    <app-model-company-label #label [isShow]="modelTwoShow" (onVoted)="ModelTwodismiss($event)"></app-model-company-label>
    <app-model-company-product #product [isShow]="modelThreeShow" (onVoted)="ModelThreedismiss($event)"></app-model-company-product>
    <app-model-company-courses #courses [isShow]="modelFourShow" (onVoted)="ModelFourdismiss($event)"></app-model-company-courses>
    <app-model-company-strength #strength [isShow]="modelFiveShow" (onVoted)="ModelFivedismiss($event)"></app-model-company-strength>
    <app-model-cropper #crop [isShow]="modelSixShow" (onVoted)="ModelSixdismiss($event)"></app-model-cropper>
 <!-- 头部 -->

    <div class="guide-head" [class.guide-step]="!guideStep">
   <ul>
     <li>
        <span><img src="assets/images/guide-ok.png"></span>
       <br/>公司信息
     </li>
     <li>
      <!-- <span><img src="assets/images/guide-ok.png"></span> -->
      <span class="isHeadActive">2</span>
      <br/>招聘人信息
    </li>
     <li >
       <!-- <span><img src="assets/images/guide-ok.png"></span> -->
       <span>3</span>
       <br/>申请高校合作
     </li>
     <!--<li *ngIf="guideStep">-->
       <!--<span>4</span>-->
       <!--<br/>等待审核-->
     <!--</li>-->
   </ul>
 </div>
<!--引导完善公司信息-->
<div class="info-block">
 <div class="info-head">招聘人信息</div>
 <div class="info-txt">
   <!-- 公司LOGO -->
   <div class="sign-list sign-img" *ngIf="myDepartment">
    <span>头像：</span>
    <label *ngIf="model.userPic" class="uploadImg-label department-logo" for="uploadDepatmentLogoImg">
        <img [src]="model.userPic">
    </label>
    <label *ngIf="!model.userPic" class="uploadImg-label" for="uploadDepatmentLogoImg">
        <img src="assets/images/add-img.png"><br/>点击上传头像
    </label>
    <input class="uploadImg" id="uploadDepatmentLogoImg" (change)="previewPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">
    <br/><s>像素：200*200px,格式：jpg,png</s>
  </div>

   <!-- 姓名 -->
   <div class="sign-list" >
     <span><b class="remind-red">*</b>姓名：</span><input type="text" [(ngModel)]="model.name" placeholder="请填写姓名">
   </div>

   <div class="sign-list select-list sign-address"  *ngIf="myDepartment">
     <span><b class="remind-red">*</b>性别：</span>
     <select autocomplete="off"  [(ngModel)]="model.gender">
       <option class="place-text" value=''  selected="selected">选择性别</option>
       <option  value = "01">男</option>
       <option  value = "02">女</option>
     </select>
   </div>

   <!-- 姓名 -->
   <div class="sign-list">
     <span><b class="remind-red">*</b>职务：</span><input type="text" [(ngModel)]="model.post" placeholder="请填写职务">
   </div>
   <!-- 姓名 -->
   <div class="sign-list">
     <span><b class="remind-red">*</b>工作邮箱：</span><input type="text" [(ngModel)]="model.email" placeholder="请填写工作邮箱">
       <span  [ngClass]="{'openInfo':contact_info_switch.is_public_email,'closeInfo':!contact_info_switch.is_public_email}" (click)="contact_info_switch.is_public_email = true"><b></b>公开</span>
       <span  [ngClass]="{'openInfo':!contact_info_switch.is_public_email,'closeInfo':contact_info_switch.is_public_email}" (click)="contact_info_switch.is_public_email = false"><b></b>不公开</span>
   </div>

     <div class="sign-list">
         <span><b class="remind-red">*</b>微信号：</span><input type="text" [(ngModel)]="model.weixin" placeholder="请填写微信号">
         <span [ngClass]="{'openInfo':contact_info_switch.is_public_wx,'closeInfo':!contact_info_switch.is_public_wx}" (click)="contact_info_switch.is_public_wx = true"><b></b>公开</span>
         <span  [ngClass]="{'openInfo':!contact_info_switch.is_public_wx,'closeInfo':contact_info_switch.is_public_wx}" (click)="contact_info_switch.is_public_wx = false"><b></b>不公开</span>
     </div>
     <div style="color: red;font-size: 12px;margin-left: 140px">用于学生投递简历后添加您进行线上面试</div>

     <div class="sign-list">
         <span><b class="remind-red">*</b>绑定手机：</span> <input type="text" [(ngModel)]="loginUserPhone" disabled>
         <span [ngClass]="{'openInfo':contact_info_switch.is_public_mobile,'closeInfo':!contact_info_switch.is_public_mobile}" (click)="contact_info_switch.is_public_mobile = true"><b></b>公开</span>
         <span [ngClass]="{'openInfo':!contact_info_switch.is_public_mobile,'closeInfo':contact_info_switch.is_public_mobile}" (click)="contact_info_switch.is_public_mobile = false"><b></b>不公开</span>
     </div>



  <!--&lt;!&ndash; 注册资金 &ndash;&gt;-->
  <!--<div class="sign-list"  *ngIf="myDepartment">-->
      <!--<span><b class="remind-red">*</b>注册资金：</span><input type="text" [(ngModel)]="model.register_capital" placeholder="请填写注册资金"><span class="purple">万元</span>-->
  <!--</div>-->
  <!--&lt;!&ndash; 公司地址 &ndash;&gt;-->
  <!--<div class="sign-list select-list sign-address"  *ngIf="myDepartment">-->
      <!--<span><b class="remind-red">*</b>公司地址：</span> -->
      <!--<select autocomplete="off" *ngIf="workPlace" [(ngModel)]="workPlace">-->
          <!--<option class="place-text" value='' disabled hidden selected="selected">选择公司地址</option>-->
          <!--<option *ngFor="let address of myDepartment.locations" [value]="address.lid">{{address.region_text}}&nbsp;&nbsp;{{address.address}}</option>-->
      <!--</select>-->
      <!--<button (click)="goAddAddress()">添加地址</button>-->
  <!--</div>-->
  <!--&lt;!&ndash; 成立年份 [(ngModel)]="model.establish_year" &ndash;&gt;-->
  <!--<div class="sign-list">-->
      <!--<span><b class="remind-red">*</b>成立年份：</span>-->
      <!--<input type="text" id="establish1" [(ngModel)]="model.establish_year" placeholder="请选择成立年份"><img class="calendar-img" src="assets/images/calendar.png" alt="打开日历"/>-->
  <!--</div>-->
  <!--&lt;!&ndash; 员工人数 &ndash;&gt;-->
  <!--<div class="sign-list select-list"  *ngIf="myDepartment">-->
      <!--<span><b class="remind-red">*</b>员工人数：</span> -->
      <!--<select autocomplete="off" [(ngModel)]="model.scale">-->
          <!--<option class="place-text" value='' disabled hidden selected="selected">选择员工人数</option>-->
          <!--<option *ngFor="let scale of config.scale" [value]="scale.code">{{scale.text}}</option>-->
      <!--</select>-->
  <!--</div>-->
  <!--&lt;!&ndash; 公司简介 &ndash;&gt;-->
  <!--<div class="sign-list sign-intro"  *ngIf="myDepartment">-->
      <!--<span><b class="remind-red">*</b>公司简介：</span>-->
      <!--<textarea [(ngModel)]="model.intro" minlength="50" maxlength="500" placeholder="请填写公司简介（50-500字）"></textarea>-->
  <!--</div>-->
   <!--&lt;!&ndash; 是否上市 &ndash;&gt;-->
   <!--<div class="sign-list sign-skill">-->
    <!--<span>是否上市：</span>-->
    <!--<div class="label-list clear">-->
        <!--<div class="label-item label-click" [class.purple]="model.is_quoted_company" (click)="model.is_quoted_company=true">-->
            <!--<div class="label-text">上市公司</div>-->
        <!--</div>-->
        <!--<div class="label-item label-click" [class.purple]="!model.is_quoted_company" (click)="model.is_quoted_company=false">-->
            <!--<div class="label-text">非上市公司</div>-->
        <!--</div> -->
    <!--</div>-->
    <!--</div>-->
    <!--&lt;!&ndash; 股票代码 &ndash;&gt;-->
    <!--<div class="sign-list" *ngIf="model.is_quoted_company">-->
        <!--<span><b class="remind-red">*</b>股票代码：</span><input type="text" [(ngModel)]="model.stock_code" placeholder="请填写公司股票代码">-->
    <!--</div>-->
    <!--&lt;!&ndash; 公司实力 &ndash;&gt;-->
    <!--<div class="sign-list sign-skill">-->
        <!--<span>公司实力：</span>-->
        <!--<div class="label-list clear">-->
            <!--<div class="label-item purple" *ngFor="let label of model.strengths">-->
            <!--<div class="label-text">{{label.text}}</div>-->
            <!--</div>-->
            <!--<div class="label-item label-click" (click)="goStrengths()">-->
                <!--<div class="label-text">+添加</div>-->
            <!--</div> -->
        <!--</div>   -->
    <!--</div>-->
    <!--&lt;!&ndash; 福利待遇 &ndash;&gt;-->
    <!--<div class="sign-list sign-skill">-->
        <!--<span>福利待遇：</span>-->
        <!--<div class="label-list clear">-->
            <!--<div class="label-item purple" *ngFor="let label of model.labels">-->
            <!--<div class="label-text">{{label.text}}</div>-->
            <!--</div>-->
            <!--<div class="label-item label-click" (click)="goLabel()">-->
                <!--<div class="label-text">+添加</div>-->
            <!--</div> -->
        <!--</div>   -->
    <!--</div>-->
    <!--&lt;!&ndash; 微信公众号 &ndash;&gt;-->
    <!--<div class="sign-list"  *ngIf="myDepartment">-->
        <!--<span>微信公众号：</span><input type="text" [(ngModel)]="model.official_account" placeholder="请填写公司微信公众号">-->
    <!--</div>-->
    <!--&lt;!&ndash; 公司网址 &ndash;&gt;-->
    <!--<div class="sign-list"  *ngIf="myDepartment">-->
        <!--<span>公司网址：</span><input type="text" [(ngModel)]="model.website" placeholder="请填写公司网址">-->
    <!--</div>-->
    <!--&lt;!&ndash; 公司产品 &ndash;&gt;-->
    <!--<div class="sign-list sign-skill"  *ngIf="myDepartment">-->
        <!--<span>公司产品：</span>-->
        <!--<div class="product-block" *ngIf="model.products.length>0">-->
            <!--<div class="product-list time-line" *ngFor="let n of model.products" >-->
                <!--<b><img src="assets/images/line-time.png"></b>-->
                <!--<div (click)="goEditorProduct(n)" title="编辑" class="product-top">{{n.name}}</div>-->
                <!--<div class="product-txt clear">-->
                    <!--<div class="left">-->
                        <!--<img *ngIf="n.image_url" [src]="n.image_url+'?imageView2/1/w/180/h/180'">-->
                        <!--<img *ngIf="!n.image_url" src="assets/images/resume-search-fail.png">-->
                    <!--</div>-->
                    <!--<div class="right">{{n.description}}</div>-->
                    <!--&lt;!&ndash; <textarea class="right" [(ngModel)]="n.description" disabled></textarea> &ndash;&gt;-->
                <!--</div>-->
            <!--</div> -->
            <!--<div class="product-list"><button (click)="goProduct()">+添加</button></div>-->
        <!--</div>   -->
        <!--<div class="label-list clear" *ngIf="model.products.length==0">-->
            <!--<div class="label-item label-click" (click)="goProduct()">-->
                <!--<div class="label-text">+添加</div>-->
            <!--</div> -->
        <!--</div>  -->
        <!---->
    <!--</div>-->
    <!--&lt;!&ndash; 发展历程 &ndash;&gt;-->
    <!--<div class="sign-list sign-skill"  *ngIf="myDepartment">-->
        <!--<span>发展历程：</span>-->
        <!--<div class="product-block" *ngIf="model.courses.length>0">-->
            <!--<div class="product-list time-line" *ngFor="let n of model.courses">-->
                <!--<b><img src="assets/images/line-time.png"></b>-->
                <!--<div class="product-top" title="编辑" (click)="goEditorCourses(n)">{{n.occur_time | date:"yyyy-MM-dd"}}</div>-->
                <!--<div class="product-title">{{n.name}}</div>-->
                <!--<div class="product-link"><a href="{{n.link}}">{{n.link}}</a></div>-->
                <!--<div class="product-details" [innerHtml]="n.details | textformat"></div>-->
                <!--&lt;!&ndash; <textarea class="product-details" [(ngModel)]="n.details" disabled></textarea> &ndash;&gt;-->
            <!--</div> -->
            <!--<div class="product-list"><button (click)="goCourses()">+添加</button></div>-->
        <!--</div> -->
        <!--<div class="label-list clear" *ngIf="model.courses.length==0">-->
            <!--<div class="label-item label-click" (click)="goCourses()">-->
                <!--<div class="label-text">+添加</div>-->
            <!--</div> -->
        <!--</div>   -->
    <!--</div>-->
    <!--&lt;!&ndash; 公司展示 &ndash;&gt;-->
    <!--<div class="sign-list sign-skill"  *ngIf="myDepartment">-->
        <!--<span style="line-height:200px;">公司展示：<br/><i>(限10张)</i></span>-->
        <!--<div class="company-imglist clear">-->
            <!--<div class="left img-list" *ngFor="let img of model.image_urls;let num=index">-->
                <!--<img [src]="img+'?imageView2/1/w/240/h/180'">-->
                <!--<div (click)="deleteImg(img)" class="delete-img"><img src="assets/images/delect.png"></div>-->
            <!--</div>-->
            <!--<div class="left img-list">-->
                <!--<label class="uploadImg-label" for="uploadDepartmentImg">-->
                    <!--<img src="assets/images/add-img.png"><br/>点击添加图片-->
                <!--</label>-->
                <!--<input class="uploadImg" id="uploadDepartmentImg" (change)="previewDepartmentPic($event)" type="file" accept="image/jpeg,image/jpg,image/png">-->
            <!--</div>-->
        <!--</div>-->
        <!---->
    <!--</div>-->
  <!--</div>-->
<!--</div>-->
<!--按钮-->
    <div class="block-button flec_c">
        <button  class="lastStep" (click)="goBackStep()">上一步</button>

        <button (click)="updateDepartment()">下一步</button>
    </div>
 </div>
</div>
    <!-- 尾部 -->
    <div class="guide-foot">
        <div class="flex_l logoutAccout" (click)="logoutAccountClick()">注销账号</div>

        &nbsp;&nbsp;&nbsp;&nbsp;{{config.websiteRecord}}
    </div>
