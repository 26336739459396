<!-- 页头部分 -->
<!--<div class="contentRight-header">招聘管理 - 我的双选会</div>-->
<!-- 主体内容部分 -->
<div class="homepage-block">
    <!-- 遮罩层部分 -->
    <router-outlet></router-outlet>
    <div class="college-block">
        <!-- 页头部分 -->
        <div class="college-block-head">双选会报名记录列表</div>
        <div class="college-block-opera clear">
            <div class="right">

                <span>筛选</span>
                <!-- 审核状态 -->
                <select name="city" id="city" [(ngModel)]="model.status" (ngModelChange)="getMilkList()" autocomplete="off">
                      <option class="place-text" value='' disabled hidden selected="selected">选择审核状态</option>
                      <option class="place-text" value=''>不限</option>
                      <!-- <option value=0>未申请</option> -->
                      <option value='1'>审核中</option>
                      <option value='2'>已通过</option>
                      <option value='3'>已拒绝</option>
                   </select>


                <!-- 搜索筛选 -->
                <div class="search-input right">
                    <input type="text" placeholder="请输入学校名称" [(ngModel)]="model.college_name" (keyup)="inputKeyup($event)"><span (click)="goSearch(model.college_name)"><img src="assets/images/search.png"></span>
                    <!-- 搜索关键词匹配列表 -->
                    <div class="keyup-block" *ngIf="keywordList.length>0">
                        <div class="keyup-list" *ngFor="let key of keywordList" (click)="goSearch(key.text)">{{key.text}}</div>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="milkRoundList.length==0" class="historyMilkRoundList_noData">
            <img src="assets/images/campnull.png"><br/> 暂无
        </div>
        <div class="college-list-cont" *ngIf="milkRoundList.length>0">
            <!--//新版列表-->
            <div class="listCell" *ngFor="let n of milkRoundList;let i = index;">
                <div style="width: 45%;cursor: pointer;display: flex;align-items: center;overflow: hidden" (click)="goDeatils(n.mrid)">
                    <img style="width: 50px;height: 50px;border-radius: 50%;margin-left: 30px" src="{{n.logo}}" />
                    <div>
                        <div style="display: flex;align-items: center">
                            <div style="margin-left: 15px;font-size: 17px;color:#333333;  overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width: 220px;font-weight: bold">{{n.name}}</div>
                            <span class="gongyi" *ngIf="n.is_welfare">专场</span>
                            <img class="sign-img uglyImg" *ngIf="n.loadState==1" src="../../assets/images/sign1.png" />
                            <img class="sign-img" *ngIf="n.loadState==2" src="../../assets/images/sign2.png" />
                            <img class="sign-img" *ngIf="n.loadState==4" src="../../assets/images/sign4.png" />
                            <img class="sign-img" *ngIf="n.loadState==5" src="../../assets/images/sign5.png" />
                        </div>
                        <p class="milkRoundAddressAndTime">
                            <img src="../../assets/images/addressIcon.png" style="width: 12px;height: 16px;margin-top: -3px"> {{(n.milkround_type == 2?"线上":n.place_name?n.place_name:'暂无')+" 举办时间："}}{{n.start_time| date:"yyyy-MM-dd HH:mm"}}
                        </p>
                    </div>
                </div>
                <!-- <div style="width: 17%;min-width: 175px" (click)="goDeatils(n.mrid)">
                    <div style="margin-top: 4px;color: #999999;font-size: 13px;text-overflow: ellipsis;white-space: nowrap;">{{"举办学校:"}} {{n.college_name}}</div>
                </div> -->
                <div style="width: 17%;min-width: 175px">
                    审核状态：
                    <span style="cursor: pointer;" *ngIf="n.audit_status=='to_audit'" (click)="goDeatils(n.mrid)">审核中</span>
                    <span style="cursor: pointer; color: #FF5555;" *ngIf="n.audit_status=='not_passed'" (click)="goDeatils(n.mrid)">审核失败</span>
                    <span style="color: #30c742;" *ngIf="n.audit_status=='passed'">审核通过</span>
                    <span style="cursor: pointer;color: #FF5555;" *ngIf="n.audit_status=='revoke'">已撤回申请</span>
                </div>
                <div style="width: 38%;">
                    <div class="cellButton shibai bordeRadius" *ngIf="n.audit_status=='to_audit'" (click)="openRevoke(n.applyid)">撤回</div>
                    <div class="cellButton baoming bordeRadius" *ngIf="n.showBaoMingBtn &&(n.loadState != 1 && n.loadState != 5)" (click)="goSign(n)">我要报名</div>
                    <div class="cellButton bordeRadius" (click)="okCanhui(n)" *ngIf="n.showSuccessBtn &&n.feedback_status=='no_feedback'">{{n.milkround_type == 2?'进入会场':'确认参会'}}</div>
                    <div class="cellButton bordeRadius" (click)="ConfirmMilkroundCommit2(n)" *ngIf="n.showSuccessBtn&&n.feedback_status=='join'&&n.milkround_type != 2">已确认</div>
                    <div class="cellButton shibai bordeRadius" *ngIf="n.showSuccessBtn&&n.feedback_status=='no_join'">已拒绝</div>
                    <div class="cellButtonYellow zhanwei bordeRadius" *ngIf="n.showSuccessBtn&&n.feedback_status=='join'&&n.milkround_type != 2">{{n.seat_number==0?"展位号暂未分配":'展位号：'+n.zone_name_string+n.seat_number}}</div>
                    <div class="cellButton shenhe bordeRadius" *ngIf="n.showShenHeBtn" (click)="goDeatils(n.mrid)">查看详情</div>
                    <div class="cellButton baoming bordeRadius" *ngIf="n.showFaileBtn" (click)="goSign(n)">重新申请</div>
                    <button class="cellButton bordeRadius" (click)="downloadPoster(n.applyid)" *ngIf="n.status == 'passed' && !n.isEnd && n.poster_type">下载海报</button>
                    <img class="air-icon" src="../../assets/images/air_icon.png" *ngIf="n.milkround_type == 2">
                </div>
            </div>
        </div>

        <!-- 没有内容存在时 -->
        <!--<div *ngIf="countList==0" class="none-block">-->
        <!--<img src="assets/images/resume-search-fail.png"><br/>-->
        <!--暂未找到双选会申请记录-->
        <!--</div>-->
        <!--&lt;!&ndash; 循环列表 &ndash;&gt;-->
        <!--<div class="college-list-cont" *ngIf="countList>0">-->
        <!--<ul>-->
        <!--<li>双选会主题</li>-->
        <!--<li>学校名称</li>-->
        <!--<li>就业办电话</li>-->
        <!--<li>报名截止时间</li>-->
        <!--<li>开始时间</li>-->
        <!--<li>结束时间</li>-->
        <!--<li>审核状态</li>-->
        <!--<li>座位号</li>-->
        <!--<li>操作</li>-->
        <!--</ul>-->
        <!--<div>-->
        <!--<ul class="college-li" *ngFor="let n of milkRoundList;let i = index;">-->
        <!--<li><img class="air-icon" src="../../assets/images/air_icon.png"  *ngIf="n.milkround_type == 2"> <span *ngIf='n.name'>{{n.name}}</span><span *ngIf='!n.name'>暂无</span></li>-->
        <!--<li><span *ngIf='n.college_name'>{{n.college_name}}</span><span *ngIf='!n.college_name'>暂无</span></li>-->
        <!--<li><span *ngIf='n.phone'>{{n.college_work_phone}}</span><span *ngIf='!n.college_work_phone'>暂无</span></li>-->
        <!--<li><span *ngIf='n.apply_end_time && n.apply_end_time != "" && n.apply_end_time != "0001-01-01T00:00:00+08:00" && n.apply_end_time != "0001-01-01T00:00:00"'>{{n.apply_end_time | date:"yyyy-MM-dd HH:mm" }}</span><span *ngIf='!n.apply_end_time || n.apply_end_time == "" || n.apply_end_time == "0001-01-01T00:00:00" || n.apply_end_time == "0001-01-01T00:00:00+08:00"'>暂无</span></li>-->
        <!--<li><span *ngIf='n.start_time'>{{n.start_time | date:"yyyy-MM-dd HH:mm" }}</span><span *ngIf='!n.start_time'>暂无</span></li>-->
        <!--<li><span *ngIf='n.end_time'>{{n.end_time | date:"yyyy-MM-dd HH:mm"}}</span><span *ngIf='!n.end_time'>暂无</span></li>-->
        <!--<li>-->
        <!--<span *ngIf="!n.audit_status">未报名</span>-->
        <!--<span *ngIf="n.audit_status=='to_audit'">审核中</span>-->
        <!--<span *ngIf="n.audit_status=='passed'">已通过</span>-->
        <!--<span *ngIf="n.audit_status=='not_passed'">未通过</span>-->
        <!--</li>-->
        <!--<li><span>{{n.seat_number==0?"未分配":n.zoom+n.seat_number}}</span></li>-->
        <!--<li>-->
        <!--<span (click)="goDeatils(n.mrid)">查看</span>-->
        <!--<span *ngIf="n.audit_status=='to_audit'&&n.isApplySign" (click)="goUpdate(n)">修改</span>-->
        <!--<span *ngIf="n.audit_status=='not_passed'&&n.isApplySign" (click)="goSign(n)">再次申请</span>-->
        <!--<span *ngIf="n.audit_status == 'passed' && !n.isEnd && n.poster_type"><a  (click)="downloadPoster(n.applyid)">下载海报</a></span>-->
        <!--<span (click)="okCanhui(n)" class="queRen" style="color: white" *ngIf="n.audit_status == 'passed' &&n.feedback_status=='no_feedback'">{{n.milkround_type == 2 ? '进入会场' : '确认参加'}}</span>-->
        <!--<span (click)="ConfirmMilkroundCommit2(n)"   class="queRen" style="color: white" *ngIf="n.audit_status == 'passed' &&n.feedback_status=='join'">已确认</span>-->
        <!--<span  class="queRen" style="color: white" *ngIf="n.audit_status == 'passed' &&n.feedback_status=='no_join'">已拒绝</span>-->
        <!--</li>-->
        <!--</ul>-->
        <!--</div>-->
        <!--</div>-->
        <!-- 分页部分 -->
        <div class='paging-block' [class.isPageHeight]="countList>0">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>
    </div>
</div>
<p-dialog [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'width':600,'height':300,'minHeight':300,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="showConfirmMilkroundModel">
    <div style="width: 600px;height: 300px;background-color: white;overflow: hidden;" *ngIf="currentMilkround">
        <p style="font-size: 23px;text-align: center;margin-top: 40px;">双选会确认函</p>
        <p style="font-size: 20px;width: 300px;margin-left: 150px;text-align: center;margin-top: 30px;">恭喜您，您申请的双选会"{{currentMilkround.name}}"已通过高校审核，请确认是否参加</p>
        <button (click)="ConfirmMilkroundCommit('no_join')" style="float: left;margin-left: 120px;width: 100px;background-color: gray;color: white;margin-top: 40px">不参加</button>
        <button (click)="ConfirmMilkroundCommit('join')" style="float: right;margin-right: 120px;width: 100px;background-color: #5e4fff;color: white;margin-top: 40px">参加</button>
    </div>
</p-dialog>
<p-dialog [contentStyle]="{'padding':'0',border:0}" [dismissableMask]="true" [style]="{'width':300,'height':532,'minHeight':532,'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}" [modal]="true" [showHeader]="false" [(visible)]="showMilkCode">
    <div style="width: 300px;height: 532px;background-color: white;overflow: hidden;">
        <img (click)="closeModel()" src="{{QrCodeURL}}" style="width: 300px;height: 532px;float: right;margin-top: 0px;margin-right: 0px;background-color: #ffffff">
    </div>
</p-dialog>

<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0','border-radius':'10px'}" [(visible)]="revokeModule" [modal]=true>
    <div class="showTopingBox">
        <div class="closIcon" (click)="revokeModule = false"><img src="/assets/images/v3/contactMeClos.png"></div>
        <div class="title">
            <div class="tipText">
                <div class="name">撤回报名</div>
            </div>
        </div>
        <div class="p2">确定撤回该双选会报名吗？</div>
        <div class="btns">
            <div class="btn active" (click)="revokeModule = false">取消</div>
            <div class="btn blueStreamer" (click)="downMilk()">确定</div>
        </div>
    </div>
</p-dialog>