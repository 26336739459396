import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'noticetransform'})
export class NoticeTransform implements PipeTransform {
  constructor(public _sanitizer: DomSanitizer){

  }  
  transform(value) {   

    if(value){
      if(value == 'to_be_accepted'){
        return '待接受';
      }
      if(value == 'rejected'){
        return '已拒绝';
      }
      if(value == 'accept'){
        return '等待面试';
      }
      if(value == 'talent_canceled'){
        return '已取消';
      }
      if(value == 'department_canceled'){
        return '已取消';
      }
      if(value == 'expired'){
        return '已过期';
      }
      if(value == 'handle_interview'){
        return '待处理';
      }
      if(value == 'hired'){
        return '已录用';
      }
      if(value == 'improper'){
        return '不合适';
      }
      if(value == 'miss'){
        return '对方爽约';
      }
    }else{
      return '待接受';
    }

  }

}