import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'onlineTime'})
export class OnlineTime implements PipeTransform {
  constructor(){

  }  
  transform(_content,_isNoDetail) {  
    // console.log(_content);  
    if(_content){
      let date,nowDate,MIN;
     //创建今天的日期,做比对
      nowDate = new Date();
      date = new Date(_content);
      MIN = (nowDate.getTime()-date.getTime())/(1000*3600);//----获取小时数
      // console.log(MIN);
         if(MIN>2){
          return "不在线";
        }else{
          return "在线";
       }     
    }else{
      return _content;
    }
  }

}
