<p-dialog [showHeader]="false" [dismissableMask]="true" [contentStyle]="{'padding':'0',border:0,'border-radius':'6px','width':'500px'}" [(visible)]="isShow" (onHide)="dismiss(false)" [modal]=true>
    <div class="model-cont">
        <!-- 上层提示部分 -->
        <div class="model-head">
            <div class="left">请选择职位</div>
            <div class="clos" (click)="dismiss(false)"><img src="/assets/images/v3/contactMeClos.png"></div>
        </div>
        <div class="position-list-cont">
            <div class="none-block" *ngIf="dataList.length==0">
                <img src="assets/images/resume-search-fail.png"><br/> 暂无在期的招聘职位
                <br/>
                <button class="purple" (click)="goPublish()">去发布职位</button>
            </div>
            <div class="item" *ngFor="let item of dataList" [class.active]="item.isActive" (click)="confirm(item)">
                <div class="position-list-block">
                    <div class="up-block">
                        <div class="name">
                            <div class="left-block">{{item.name}}</div>
                            <div class="right-block"><span class="urgent-color" *ngIf="item.is_urgent">急聘</span></div>
                        </div>
                        <div class="time">
                            到期时间&nbsp;&nbsp;{{item.effect_time | date:"yyyy.MM.dd"}}
                        </div>
                    </div>
                    <div class="down-block">
                        <div class="left-block">
                            {{item.position_salary_text}}&nbsp;&nbsp;{{item.work_place_text}}&nbsp;&nbsp;{{item.work_experience_text}}&nbsp;&nbsp;{{item.education_text}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btns">
            <div class="btn blueStreamer" *ngIf="dataList.length>0" (click)="keepValue()">保存</div>
            <div class="btn blueStreamer" *ngIf="dataList.length>0" (click)="goPublish()">去发布职位</div>
        </div>
    </div>
</p-dialog>