
<!-- 页头部分 -->
<ngx-loading [show]="config.loading"></ngx-loading>
<div class="top-header" [class.isLogin]="config.isLogin">
  <div class="left">
    <span class="college-department" *ngIf="config.isByCollege"><img [src]="config.byCollegeLogo">{{config.byCollegeName}}</span>
    <span *ngIf="!config.isByCollege"><img src="assets/images/logo.png"></span>
    管理中心<b></b>企业版
  </div>
  <!-- 登陆前 -->
  <div *ngIf="config.isLogin||config.isGuide" class="right login-right"><img style="width: 220px;height: 50px;padding-left: 0px" src="./../assets/images/bannerRight.png" /></div>
  <!-- 登陆后 -->
  <ul  *ngIf="!config.isLogin&&!config.isGuide" class="right header-right">
     <li class="left">
       <!-- <img title="帮助中心" src="assets/images/head-help.png" /> -->
     </li>

    <li class="left user-left">
      <img src="assets/images/head-user.png"/><span *ngIf="config.loginUser">{{config.loginUser.name}}</span><img class="header-down" src="assets/images/down-white.png"/>
      <!-- hover展示选项卡 -->
      <div class="user-block">
        <div class="user-list" (click)="goAccount()">账号管理</div>
        <div class="user-list" (click)="goLogin()">退出</div>
      </div>
    </li>
  </ul>
</div>
<div class="app-content">
    <router-outlet></router-outlet>
</div>


