<!-- 页头部分 -->
<div class="contentRight-header">招聘管理 - 职位管理</div>



<!-- 主体内容部分 -->
<div class="homepage-block">

    <!-- <div class="notification">
        <img src="assets/images/noticotion.png" />
        <span>进入工作啦官网，可让您的招聘信息覆盖更多毕业生 <span  (click)="gotoPro()" class="check">点击查看 <img src="assets/images/notMore.png" /></span></span>
    </div> -->

    <div class="college-block">
        <!-- 页头部分 -->
        <div class="college-block-head">
            职位列表<span class="notCooperation" *ngIf="colleges_cooperation.status!=2">(您当前未跟院校建立合作，发布职位仅供高校审核查看，请认真填写。在建立合作之后发布的职位显示。)</span>
        </div>
        <!-- 操作部分 <img src="assets/images/select-img.png">-->
        <div class="college-block-opera clear">
            <div class="left">
                <button class="purple" (click)="goPublish()">发布职位</button>
                <!-- <button class="purple active" (click)="alertFil()" *ngIf="colleges_cooperation.status!=2">发布职位</button> -->
            </div>
            <div class="right">
                <!-- 选择所在城市[(ngModel)]=""   -->
                <span>筛选</span>
                <select name="status" id="status" [(ngModel)]="model.status" (ngModelChange)="getPositionList()" autocomplete="off">
                  <option class="place-text" value='' disabled hidden selected="selected">选择状态</option>
                  <option class="place-text" value=''>全部</option>
                  <option value="release">在招</option>
                  <option value="expired">下线</option>
           </select>
                <!-- 搜索筛选 -->
                <div class="search-input right">
                    <input type="text" placeholder="请输入职位名称" [(ngModel)]="model.name" (keyup)="inputKeyup($event)"><span (click)="goSearch(model.name)"><img src="assets/images/search.png"></span>
                    <!-- 搜索关键词匹配列表 -->
                    <div class="keyup-block" *ngIf="keywordList.length>0">
                        <div class="keyup-list" *ngFor="let key of keywordList" (click)="goSearch(key.name)">{{key.name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 没有内容存在时 -->
        <div *ngIf="countList==0" class="none-block">
            <img src="assets/images/resume-search-fail.png"><br/> 暂未找到职位
        </div>
        <!-- 循环列表  -->
        <div class="college-list-cont" *ngIf="countList>0">
            <ul>
                <li style="width: 120px;">本校审核状态</li>
                <li>职位状态</li>
                <li>操作</li>
                <li>职位名称</li>
                <li>职位类别</li>
                <li>工作性质</li>
                <li>月薪范围</li>
                <li>学历要求</li>
                <li>工作城市</li>
                <li>招聘人数</li>
                <li>专业要求</li>
                <li>技能标签</li>
            </ul>
            <!-- *ngFor="let n of collegeList;let i = index;" -->
            <div>
                <ul class="college-li" *ngFor="let n of positionList;let i = index;">
                    <li style="width: 120px;">
                        <span *ngIf="n.college_audit_status==1" style="color:#6977eb">待审核</span>
                        <span *ngIf="n.college_audit_status==2" style="color:#6977eb">已通过</span>
                        <span *ngIf="n.college_audit_status==3" style="color:red ">已拒绝</span>
                    </li>
                    <li>
                        <span *ngIf="n.need_perfect==true&&n.position_status!='delete'" style="color:red ">未完善</span>
                        <span *ngIf="n.need_perfect==true&&n.position_status=='delete' " style="color:red ">已删除</span>
                        <span *ngIf="n.need_perfect==false &&n.position_status=='release'" style="color:#6977eb">已发布</span>
                        <span *ngIf="n.need_perfect==false &&n.position_status=='expired'" style="color:#6977eb">已过期</span>
                        <span *ngIf="n.need_perfect==false &&n.position_status=='stop'" style="color:#6977eb">已下线</span>
                        <span *ngIf="n.need_perfect==false &&n.position_status=='delete'" style="color:#6977eb">已删除</span>
                    </li>
                    <li>
                        <a (click)="goDeatils(n.positionid)" style="color: #6977eb" *ngIf="n.position_status!='delete'">{{n.need_perfect==true?"完善":"编辑"}} &nbsp;</a>
                        <a *ngIf="n.need_perfect==false && n.position_status=='release'" style="color: #6977eb" (click)="goExpired(n)">下线&nbsp;</a>
                        <span *ngIf="n.position_status=='expired'|| n.position_status=='stop'" style="color: #6977eb" (click)="goResend(n)">重发&nbsp;</span>
                        <span *ngIf="n.position_status=='stop'" style="color: #6977eb" (click)="deletePosition(n)">删除&nbsp;</span>
                    </li>

                    <li><span *ngIf='n.name'>{{n.name}}</span><span *ngIf='!n.name'>暂无</span></li>
                    <li><span *ngIf='n.post_type_text'>{{n.post_type_text}}</span><span *ngIf='!n.post_type_text'>暂无</span></li>
                    <li><span *ngIf='n.post_nature_text'>{{n.post_nature_text}}</span><span *ngIf='!n.post_nature_text'>暂无</span></li>
                    <li><span *ngIf='n.position_salary_text'>{{n.position_salary_text}}</span><span *ngIf='!n.position_salary_text'>暂无</span></li>
                    <li><span *ngIf='n.education_text'>{{n.education_text}}</span><span *ngIf='!n.education_text'>暂无</span></li>
                    <li><span *ngIf='n.work_place_text'>{{n.work_place_text}}</span><span *ngIf='!n.work_place_text'>暂无</span></li>
                    <li><span *ngIf='n.intent_count'>{{n.intent_count}}</span><span *ngIf='!n.intent_count'>暂无</span></li>
                    <li>
                        <span class="skill-block" *ngIf='n.majors && n.majors.length>0'>
                            <i *ngFor="let m of n.majors">{{m.text}}</i>
                        </span>
                        <span *ngIf='n.majors.length==0'>不限</span>
                    </li>
                    <li>
                        <span class="skill-block" *ngIf='n.skills && n.skills.length>0'>
                            <i *ngFor="let skill of n.skills">{{skill.text}}</i>
                        </span>
                        <span *ngIf='n.skills.length==0'>不限</span>
                    </li>


                </ul>
            </div>
        </div>
        <!-- 分页部分 -->
        <div class='paging-block' [class.isPageHeight]="countList>0" *ngIf="countList>0">
            <router-outlet></router-outlet>
            <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
        </div>
    </div>
</div>
<!--<p-dialog-->
<!--[showHeader]="false"-->
<!--[dismissableMask]="true"-->
<!--[contentStyle]="{'padding':'0',border:0}"-->
<!--[(visible)]="isShowPublishVip" [width]="390"-->
<!--[style]="{'padding':0,'border':0,'background':'transparent','box-shadow':'unset'}"-->
<!--[height]="390" [minHeight]="191"-->
<!--[modal]=true>-->
<!--<div class="modal-content" style="margin: 0 auto ;  display: flex;-->
<!--position: relative;-->
<!--flex-direction: column;-->
<!--align-items: center;-->
<!--width: 390px;-->
<!--height:191px;-->
<!--background: url(../../assets/images/vip/vip_bg2.png);-->
<!--background-size: 390px 191px" >-->
<!--<button (click) = "closeX()" style="border: none">-->
<!--<img  style="position: absolute;top: 10px;right: 10px;width: 16px;height: 16px" src="../../assets/images/vip/vip_x.png"  />-->
<!--</button>-->
<!--<div style="display: flex;align-items: center;margin: 10px 0">-->
<!--<img src="../../assets/images/vip/pul_suc.png" style="width: 48px;height: 48px;margin-right: 4px">-->
<!--<span style="font-size: 19px;color: #333333;font-weight: bold">职位发布成功</span>-->
<!--</div>-->
<!--<div style="color: #666666;font-size: 15px">体验发布职位更多高校毕业生可见</div>-->
<!--<button [mat-dialog-close] style="border: none">-->
<!--<div  (click) = "goRouter()" style="display: flex;margin-top: 10px;align-items: center">-->
<!--<div style="font-size: 15px;color: #ffffff;background: #5E4FFF;text-align: center;line-height: 36px;width: 120px;height: 36px">立即体验</div>-->
<!--</div>-->
<!--</button>-->
<!--</div>-->
<!--</p-dialog>-->


<!--修改密码弹窗-->
<p-dialog [closeOnEscape]="false" [(visible)]="showFailModel" [style]="{'width':350,'height':200}" [modal]=true>
    <p-header>温馨提示
        <img (click)="closeFailModel()" style="width: 15px;height: 15px;float: right;margin-top: 5px" src="assets/images/closeX.png" />
    </p-header>
    <div class="failText">未跟当前院校建立合作，暂不支持发布职位，请点击申请高校合作</div>
    <div class="flex_c">
        <button class="updateEmailBtnClick" (click)="goCooperation()">申请高校合作</button>
    </div>
</p-dialog>