<!-- 页头部分 -->
<div class="contentRight-header"><img (click)="opera.goChangeRouter('college-milkround')" src='assets/images/router-old.png'/>校园招聘 - 双选会 - 双选会会场</div>
<!-- 主体内容部分 -->
<div class="homepage-block">
  <!-- 遮罩层部分 -->
  <router-outlet></router-outlet>
  <div class="college-block" style="margin-bottom:20px;">
        <!-- 页头部分 -->
        <div class="college-block-head">
            双选会会场
        </div>
        <!-- 详情模块 -->
        <div class="college-head-details clear" *ngIf="milk">
            <!-- 左侧文字 -->
            <ul class="left">
              <li><span>双选会名称：</span>{{milk.name}}<i *ngIf="!milk.name">暂无</i></li>
              <li><span>举办场地：</span>{{milk.place_name}}<i *ngIf="!milk.place_name">暂无</i></li>
              <li><span>联系人：</span>{{milk.contacts}}<i *ngIf="!milk.contacts">暂无</i></li>
              <li><span>联系邮箱：</span>{{milk.email}}<i *ngIf="!milk.email">暂无</i></li>
              <li><span>结束时间：</span>{{milk.end_time | date:"yyyy-MM-dd HH:mm"}}<i *ngIf="!milk.end_time">暂无</i></li>
              <li *ngIf="!isMilkStart"><span>当前状态：</span>即将开始</li>
              <li *ngIf="isMilkStart"><span>当前状态：</span>{{isMilkEnd?'已经结束':'正在进行'}}</li>
            </ul>
            <!-- 右侧logo -->
            <div class="right">
                <img [src]="milk.logo" alt="高校logo">
            </div>
        </div>
  </div>
  <div class="college-block">
    <!-- 筛选项 -->
    <div class="college-resume-head clear">
        <div class="left" style="color: #504c9d">参会学生</div>
        <div class="right">
           <!-- 选择所在城市[(ngModel)]="" -->
           <span>筛选</span>
           <select name="major" id="major" [(ngModel)]="majorOne" (ngModelChange)="majorOneChange()" autocomplete="off">
                <option class="place-text" value='' disabled hidden selected="selected">选择一级专业</option>
                <option *ngFor="let a of majorListOne" [value]="a.code">{{a.text}}</option>
            </select>
            <select name="majorTwo" id="majorTwo" [(ngModel)]="model.major" (ngModelChange)="getResumeList()" (click)="isShowTwo()" autocomplete="off">
                    <option class="place-text" value='' disabled hidden selected="selected">选择二级专业</option>
                    <option *ngFor="let a of majorListTwo" [value]="a.code">{{a.text}}</option>
            </select>
            <select name="education" id="education" [(ngModel)]="model.education" (ngModelChange)="getResumeList()" autocomplete="off">
                    <option class="place-text" value=''>不限</option>
                    <option *ngFor="let a of educationList" [value]="a.code">{{a.text}}</option>
            </select>
        </div>
    </div>
    <!-- 中间部分 -->
    <!-- 没有内容存在时 -->
    <div *ngIf="countList==0" class="none-block">
        <img src="assets/images/resume-search-fail.png"><br/>
        暂未找到相关简历，换个关键词试试吧
    </div>
    <div *ngIf="countList>0" class="college-resume-cont">
        <ul>
            <li>头像</li>
            <li>姓名</li>
            <li>性别</li>
            <li>年龄</li>
            <li>学校</li>
            <li>专业</li>
            <li>学历</li>
            <li>技能标签</li>
            <li>在线状态</li>
            <li>操作</li>
        </ul>
        <div>
            <ul class="resume-li" *ngFor="let n of resumeList;let i = index;">
                <li>
                    <!-- <img *ngIf="n.logo" [src]="n.logo"><img *ngIf="!n.logo" src="assets/images/none.png"> -->
                    <img *ngIf="n.logo" src="{{n.logo+'&imageView2/1/w/100/h/100'}}">
                    <img *ngIf="!n.logo&&(n.gender=='01')" src="assets/images/male.png">
                    <img *ngIf="!n.logo&&(n.gender=='02')" src="assets/images/female.png">
                    <img *ngIf="!n.logo&&!n.gender" src="assets/images/male.png">
                </li>
                <li><span *ngIf='n.talent_name'>{{n.talent_name}}</span><span *ngIf='!n.talent_name'>暂无</span></li>
                <li><span *ngIf='n.gender_text'>{{n.gender_text}}</span><span *ngIf='!n.gender_text'>暂无</span></li>
                <li><span *ngIf='n.age'>{{n.age}}</span><span *ngIf='!n.age'>暂无</span></li>
                <li><span *ngIf='n.graduate_university'>{{n.graduate_university}}</span><span *ngIf='!n.graduate_university'>暂无</span></li>
                <li><span *ngIf='n.major_text'>{{n.major_text}}</span><span *ngIf='!n.major_text'>暂无</span></li>
                <li><span *ngIf='n.education_text'>{{n.education_text}}</span><span *ngIf='!n.education_text'>暂无</span></li>
                <li>
                    <span *ngIf='n.skills.length>0'>
                        <i *ngFor="let skill of n.skills">{{skill.text}}</i>
                    </span>
                    <span *ngIf='n.skills.length==0'>暂无</span>
                </li>
                <li><span *ngIf='n.last_online_time'>{{n.last_online_time | onlineTime}}</span><span *ngIf='!n.last_online_time'>暂无</span></li>
                <li>
                    <span (click)="goDetails(n)">查看简历</span>
                    <span (click)="goCollect(n)">{{n.isfollow_reusme?'取消收藏':'收藏'}}</span>
                </li>
            </ul>
        </div>
    </div>
  </div>
  <!-- 分页部分 -->
  <div class='paging-block' [class.isPageHeight]="countList>0">
     <router-outlet></router-outlet>
     <app-paging [count]="pageCount" [activeCount]="pageNumber" (onVoted)="getChildEvent($event)"></app-paging>
 </div>
</div>
