// 数据管理器
// 在这里数据都将采用异步方式返回
// 参数传true调用接口返回最新数据
// 无参数或其他参数将返回上次接口访问的数据缓存，若没有值将调用接口访问
import { Injectable } from "@angular/core";
import { Config } from "./config";
import { Local } from "./local";
import { Constants } from "./constants";
import { DataApi } from "./dataApi";
import { ToastrService } from "ngx-toastr";
import { ConfirmComponent } from "../app/confirm/confirm.component";
import { ConfirmsComponent } from "../app/confirms/confirms.component";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
@Injectable()
export class DataManager {
  private dataCache: { [x: string]: any } = {};
  constructor(private dataApi: DataApi) {}
  async getPositionList(newest = false) {
    if (newest !== true && this.dataCache.positionList)
      return this.dataCache.positionList;
    let res = await this.dataApi.getPositionList({});
    if (res) {
      this.dataCache.positionList = res.list;
      return res.list;
    }
  }
}