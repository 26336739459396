import { Injectable } from '@angular/core';
import { Events } from '../provider/eventService';

import { Api } from "./api";
import { Config } from "./config";
import { Local } from "./local";
import { Constants } from "./constants";
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/toPromise';

/**
 * 这里用来封装一些与数据请求相关的业务逻辑
 * 当程序规模增大时，需要分离此文件
 * 1.业务上的对外http数据请求接口，统一在此处
 * 2.某些从localstorage获取数据的接口
 */
@Injectable()
export class DataApi {
  private config: Config;

  constructor(private api: Api, private local: Local, public events: Events) {
    //获取单例config
    this.config = Config.getInstance();
  }

  ///////////////////////////////////////////////////////////////////////登录相关接口
  //获取验证码(手机pc登陆)
  postMobileSmsVcode(data) {
    return this.api.agentPost('/topsystem/smsvcode', data);
  }

  //获取验证码（修改手机号）
  postMobileSmsVcodeApp(data) {
    return this.api.agentPost('/topsystem/smsvcode/app', data);
  }

  //获取图片验证码
  getImgCode() {
    return this.api.get('/topsystem/vcode')
  }

  //通过手机验证码获取code
  postMobileSmsCode(data) {
    return this.api.agentPost('/user/mobile/sms', data);
  }
  loginByPhone(data) {
    return this.api.agentPost('/user/login', data);
  }

  //通过code换tooken
  postCodeTradeToken(data) {
    return this.api.agentPost('/api/oauth2/access_token', data);
  }


  ///验证手机号码、固话、邮箱
  phoneVerification(data) {
    return this.api.post('/topsystem/formatvalidation', data);
  }

  ////////////////////////////////////////////////////////////////测试接口
  //登陆
  TestLogin(data) {
    return this.api.agentPost('/user/login/test/' + data.loginname + '/' + data.password);
  }
  //邮箱登录
  accountLogin(data) {
    return this.api.agentPost('/user/login', data);
  }

  ///////////////////////////////////////////////////////////////////招聘简章相关接口
  //创建招聘简章
  creatRules(data) {
    return this.api.post('/position_brief/create', data)
  }

  //招聘简章列表
  getRulesList(data) {
    return this.api.get('/position_brief', data)
  }
  getRulesListWithCollage(data, pic) {
    return this.api.get('/position_brief/college/' + data + "/list" + pic)
  }

  //修改招聘简章
  editorRules(id, data) {
    return this.api.post('/position_brief/' + id + '/update', data)
  }

  //重新向高校提交
  editorCollegeRules(data) {
    return this.api.post('/position_brief/' + data.briefid + '/college/' + data.collegeid + '/update')
  }

  //删除招聘简章
  deleteRules(id) {
    return this.api.post('/position_brief/' + id + '/delete')
  }

  //获取招聘简章详情
  getRulesDetails(data) {
    return this.api.get('/position_brief/' + data)
  }

  //获取招聘简章审核高校列表
  getRulesCollegeList(id, data) {
    return this.api.get('/position_brief/' + id + '/college/list', data)
  }

  ////////////////////////////////////////////////////////////////首页相关接口
  //招聘进度
  jobProgress(data) {
    return this.api.get('/statistics/department/progress', data);
  }

  //合作院校
  collegePart() {
    return this.api.get('/statistics/department/cooperation');
  }

  ///////////////////////////////////////////////////////////////////////当前登录的用户(user信息)
  //获取登录用户信息
  getUser() {
    return this.api.get('/user');
  }

  //修改登录用户信息
  updateUser(data) {
    return this.api.post('/user/update', data);
  }

  //添加用户反馈
  creatFeedback(data) {
    return this.api.post('/feedback/create', data);
  }

  //添加用户反馈--未登录
  newFeedback(data) {
    return this.api.post('/feedback', data);
  }

  //用户修改手机号
  userChangeMobile(data) {
    return this.api.post('/user/mobile/change', data);
  }

  ///////////////////////////////////////////////////////////////////////七牛图片base64转value
  //七牛图片base64转value
  uploadImageTransform(data) {
    return this.api.post('/department/uploadimage/app', data);
  }

  //七牛视频上传请求token api
  uploadQIniuVideo(data) {
    return this.api.post('/api/qiniu/video/access_token', data);
  }

  ///////////////////////////////////////////////////////////////////////企业信息接口
  //新企业完善信息(引导)
  setOneStep(data) {
    return this.api.post('/department/onestep', data);
  }
  //重新企业完善信息(引导)
  resetOneStep(data) {
    return this.api.post('/department/perfect', data);
  }
  //--------引导页检测企业全称是否重复
  testDepartmentName(data) {
    return this.api.get('/department/name/count', data)
  }

  //上传头像(登录用户的头像,不是logo)更新管理员头像(base64)
  updateUserAvatar(data) {
    return this.api.post('/user/update/avatar/app', data);
  }

  //获取企业信息
  getDepartment() {
    return this.api.get('/account_user/department');
  }

  //保存/更新 企业基本信息
  updateDepartment(data) {
    return this.api.post('/department/update', data)
  }

  //上传企业logo
  uploadDepartmentLogo(data) {
    return this.api.post('/department/logo/app', data)
  }

  //保存企业标签
  uploadDepartmentLable(data) {
    return this.api.post('/department/lable', data)
  }

  //保存公司实力标签
  uploadDepartmentStrength(data) {
    return this.api.post('/department/strength', data)
  }

  //添加企业产品
  createDepartmentProduct(data) {
    return this.api.post('/department/product/create', data)
  }

  //修改企业产品
  uploadDepartmentProduct(_pid, data) {
    return this.api.post('/department/product/' + _pid + '/update', data)
  }

  //删除企业产品
  deleteDepartmentProduct(_pid) {
    return this.api.post('/department/product/' + _pid + '/delete')
  }

  //上传企业相关图片
  uploadDepartmentImg(data) {
    return this.api.post('/department/uploadimage/app', data)
  }
  //上传附件
  uploadDepartmentFile(data) {
    return this.api.post('/department/uploadfile', data)
  }

  //设置企业展示
  uploadDepartmentShow(data) {
    return this.api.post('/department/show', data)
  }

  //设置企业视频
  uploadDepartmentVideo(data) {
    return this.api.post('/department/video', data)
  }

  //添加企业发展历程
  createDepartmentProgress(data) {
    return this.api.post('/department/course/create', data)
  }

  //修改企业发展历程
  uploadDepartmentProgress(_pid, data) {
    return this.api.post('/department/course/' + _pid + '/update', data)
  }

  //删除企业发展历程
  deleteDepartmentProgress(_pid) {
    return this.api.post('/department/course/' + _pid + '/delete')
  }

  //企业申请平台认证
  authenticationPlatformApply(data) {
    return this.api.post('/authentication/platform/apply', data)
  }

  //企业修改平台认证
  authenticationUpdatePlatformApply(data) {
    return this.api.post('/authentication/platform/apply/update', data)
  }

  //获取企业平台认证信息
  getPlatformApply() {
    return this.api.get('/authentication/platform/apply')
  }

  //企业申请校招
  uploadDepartmentIdentity(data) {
    return this.api.post('/authentication/apply', data)
  }

  //获取企业上次认证信息
  lastAuthenticationIdentity() {
    return this.api.get('/authentication/lastapply')
  }

  //获取企业上次认证信息(高校)
  lastCollegeAuthenticationIdentity(data) {
    return this.api.get('/authentication/' + data + '/lastapply');
  }

  //刷新企业在线时间
  RefreshOnlinetime() {
    return this.api.get('/account/refresh/onlinetime')
  }

  //上传企业认证相关图片
  uploadAuthImg(data) {
    return this.api.post('/department/uploadauthimage/app', data)
  }

  //企业获取认证的高校列表
  getCollegeIdentityList(data) {
    return this.api.get('/authentication/department/college/list', data);
  }

  // 获取企业信息
  getEmployment() {
    return this.api.get('/department')
  }

  /////////////////////////////////////////////////////////////////////支付相关接口
  getPayCharge(data) {//认证充值（废弃）
    return this.api.post('/payment/charge', data);
  }

  //充值
  postPayCharge(data) {
    return this.api.post('/payment/recharge', data);
  }

  // 高级搜索支付
  postPaySearch(data) {
    return this.api.post('/payment/charge', data);
  }

  //检查微信扫码付款状态
  getPayStatus(chargeid) {
    return this.api.get('/payment/' + chargeid + '/chackcharge');
  }
  //获取代办事项列表
  getTodoList(data) {
    return this.api.get('/tasktodo/department/list', data)
  }
  viewTodo(id) {
    return this.api.post('/tasktodo/' + id + '/view')
  }
  doneTodo(id) {
    return this.api.post('/tasktodo/' + id + '/done')
  }
  //根据订单编号获取需支付金额
  getPayAmount(orderid) {
    return this.api.get('/consume/calculate/' + orderid + '/pay');
  }

  //订单付款
  payOrderById(orderid) {
    return this.api.post('/order/' + orderid + '/paid');
  }

  //获取材料标准（认证:authentication 双选会申请:milkround 宣讲会申请:campustalk）
  getMaterial(material, data) {
    return this.api.get('/material/standard/' + material + '/public', data);
  }

  //获取材料标准（认证:authentication 双选会申请:milkround 宣讲会申请:campustalk）
  getWeiChatCode(data) {
    return this.api.post('/wechat/department/makeercode', data);
  }

  ///////////////////////////////////////////////////////////////////////职位相关接口
  //发布职位
  createPosition(data) {
    return this.api.post('/position/create', data);
  }

  //注册快速发布职位
  fastCreatePosition(data) {
    return this.api.post('/position/create/fast', data);
  }

  //修改职位
  updatePosition(_positionid, data) {
    return this.api.post('/position/' + _positionid + '/update', data);
  }

  //获取该部门下在招职位的职位类别
  getPostTypeList() {
    return this.api.get('/position/post_type')
  }

  // //获取职位类别的推荐技能标签
  // getPostTypeSkillList(data) {
  //     return this.api.get('position/post_type/skill?post_type='+data)
  // }
  //获取职位推荐薪资（根据职位类别）
  // getPostTypeSalary(data){
  //   return this.api.get('position/post_type/salary?post_type='+data)
  // }
  //根据所属行业获得推荐职位类别
  getPositionPostType() {
    return this.api.post('/position/industry/post_type')
  }

  //获取某字典下code对应其他字典的结果
  getRecommendList(data) {
    return this.api.get('/dict/contrast/' + data.source_dict_type + '/' + data.source_code + '/' + data.result_dict_type)
  }

  //获取职位列表
  getPositionList(data) {
    return this.api.get('/position', data)
  }

  //职位下线
  managePositionExpired(_positionid) {
    return this.api.post('/position/' + _positionid + '/expired')
  }

  //职位重发
  managePositionResend(_positionid) {
    return this.api.post('/position/' + _positionid + '/online')
  }
  //删除职位
  deletePosition(_positionid) {
    return this.api.post('/position/' + _positionid + '/delete')
  }
  //根据职位positionid获取职位详情
  getPostDetails(_positionid) {
    return this.api.get('/position/' + _positionid)
  }

  //根据职位类别获取推荐职位描述GET /position/{post_type}/post_desc
  getPositionDescription(post_type) {
    return this.api.get('/position/' + post_type + '/post_desc')
  }

  //匹配企业所有职位
  getPositionMatchName(data) {
    return this.api.post('/position/name/matching', data);
  }

  ///////////////////////////////////////////////////////////////////////职位实时状态相关接口
  //职位浏览记录(谁看过我)
  getPositionViewHistory(data) {
    return this.api.get('/position/view/history', data)
  }

  //职位收藏记录(谁收藏了我)
  getPositionCollectionHistory(data) {
    return this.api.get('/position/collection/history', data)
  }

  //匹配职位名称
  positionNameMatch(data) {
    return this.api.post('/position/name/matching?name=' + data);
  }

  ///////////////////////////////////////////////////////////////////////人才简历相关接口
  //获取简历列表(推荐的简历)
  getResumeList(data) {
    return this.api.get('/resume/recommendation', data);
  }

  //根据条件搜索简历
  getSearchResumeList(data) {
    return this.api.get('/resume/searcher', data);
  }

  //普通搜索
  getSearch(data) {
    return this.api.get('/resume/search', data);
  }

  //高级搜索
  getAdvancedSearch(data) {
    return this.api.get('/resume/search/advanced', data);
  }

  //获取简历详情(通过简历编号)
  getResumeNumView(_resumeNumber, data = {}) {//给data赋初始值为一个空对象
    return this.api.get('/resume/' + _resumeNumber.resume_number + '/view?is_search=' + _resumeNumber.is_search, data);
  }

  //获取简历详情（通过简历ID）/resume/senior/{resumeid}
  getResumeIdView(_resumeid) {
    return this.api.get('/resume/' + _resumeid);
  }

  //收藏简历
  manageResumeArchived(data) {
    return this.api.post('/resume/archived/create', data);
  }

  //取消简历收藏
  manageResumeArchivedDelete(resumeid) {
    return this.api.post('/resume/archived/' + resumeid + '/delete');
  }

  //收藏简历列表
  getResumeArchivedList(data) {
    return this.api.get('/resume/archived/searcher', data);
  }

  //获取分享简历key
  getResumeShareKey(resumeid, channels) {
    return this.api.get('/resume/' + resumeid + '/department/share/' + channels + '/key');
  }

  //投递简历列表
  getSendResumeList(data) {
    return this.api.get('/delivery/searcher', data);
  }

  ///投递反馈delivery/{deliveryid}/feedback
  setSendResumeStatus(id, data) {
    return this.api.post('/delivery/' + id + '/feedback?feedback_status=' + data);
  }

  ///////////////////////////////////////////////////////////////////////面试通知相关接口
  //面试日程列表
  getNoticeList(data) {
    return this.api.get('/interview/department/list', data);
  }

  //发送面试通知
  sendNotice(data) {
    return this.api.post('/interview/send', data);
  }

  //企业取消面试通知
  cancelNotice(_interviewid) {
    return this.api.post('/interview/' + _interviewid + '/cancel');
  }

  //企业修改面试邀请状态/interview/{interviewid}/deprtment/feedback
  UpdateInterview(_interviewid, data) {
    return this.api.post('/interview/' + _interviewid + '/deprtment/feedback?feedback_status=' + data);
  }

  ///////////////////////////////////////////////////////////////////////IM相关接口
  //获取对方的极光ID(扣除资源)
  getImInit() {
    return this.api.get('/im/auth_payload');
  }

  //获取对方的极光ID(扣除资源)
  getChatIm(data) {
    return this.api.post('/im/chat/check', data);
  }

  //获取会话列表(分页)
  getImDialogueList(data) {
    return this.api.get('/im/dialogue/list', data);
  }

  //获取会话列表(不分页)
  getImDialogueAllList(data) {
    return this.api.get('/im/dialogue/alllist', data);
  }

  //获取聊天记录(根绝当前会话的ID)
  getChatList(_dlid, data) {
    return this.api.get('/im/chat/' + _dlid + '/list', data);
  }

  //根据对方极光ID获取对话记录信息
  getByJmChatList(data) {
    return this.api.get('/im/dialogue/' + data);
  }

  //获取聊天记录(根据极光用户名)()
  getJmChatList(username, data) {
    return this.api.get('/im/chat/jm/' + username + '/list', data);
  }

  //发送消息
  sendChat(data) {
    return this.api.post('/im/chat/create', data);
  }

  //聊天图片上传
  uploadChatImg(data) {
    return this.api.post('/im/image/upload', data);
  }

  //设置当前聊天记录为已读
  dlidRead(_dlid) {
    return this.api.post('/im/chat/' + _dlid + '/read', { dlid: _dlid });
  }

  //获取招聘会详情（单个）
  getMilkDetails(mrid) {
    return this.api.get('/milkround/' + mrid + '/public');
  }

  //申请参加招聘会
  signMilk(mrid, data) {
    return this.api.post('/milkround/' + mrid + '/apply/department', data);
  }

  //进入招聘会-----获取简历列表
  getMilkResumeList(mrid, data) {
    return this.api.get('/milkround/' + mrid + '/live/department', data);
  }

  //获取招聘会弹幕信息
  getMilkBarrage(mrid) {
    return this.api.get('/milkround/' + mrid + '/live/barrage');
  }

  // 企业获取双选会海报
  getMilkPoster(applyid) {
    return this.api.get('/milkround/apply/department/' + applyid + '/poster');
  }

  //企业获取开放宣讲会申请高校列表
  getCampustalkList(data) {
    return this.api.get('/campustalk/open/list', data);
  }

  //企业申请宣讲会
  getCampustalkSign(collegeId, data) {
    return this.api.post('/campustalk/' + collegeId + '/apply', data);
  }

  //获取宣讲会详情（单个）
  getCampustalk(ctid) {
    return this.api.get('/campustalk/' + ctid + '/public');
  }

  //企业获取企业申请过的宣讲会和招聘会列表
  getMilkTalkList(data) {
    return this.api.get('/mc/apply/list/department', data);
  }

  //获取当前高校的宣讲会申请记录/campustalk/{collegeid}/apply/list
  getCampustalkByCollegeid(collegeid, data) {
    return this.api.get('/campustalk/' + collegeid + '/apply/list', data);
  }

  ////////////////////////////////////////////////////////////////////////高校相关
  // 获取高校列表
  getCollegeList(data) {
    return this.api.get('/college/department/list', data)
  }

  //获取高校信息
  getCollageDetails(collegeid) {
    return this.api.get('/college/' + collegeid);
  }

  //订阅高校公众号（根据高校ID）
  getCollegeAccount(collegeid) {
    return this.api.post('/im/official_account/' + collegeid + '/csubscribe');
  }

  //取消订阅高校公众号（根据高校ID）
  unCollegeAccount(collegeid) {
    return this.api.post('/im/official_account/' + collegeid + '/uncsubscribe');
  }

  //获取高校招聘会列表
  getMilkRoundList(data, collegeid) {
    ///milkround/department/list
    //milkround/college/{collegeid}/department/list
    return this.api.get('/milkround/college/' + collegeid + '/department/list', data);
  }

  //获取高校公众号菜单
  getCollegeMenu(accountid) {
    return this.api.get('/im/official_account/' + accountid + '/menu');
  }

  //获取高校就业信息文章列表
  getCollegeJobList(data) {
    return this.api.get('/article/list/public', data);
  }

  //获取高校就业信息文章详情
  getCollegeJobDetails(articleid) {
    return this.api.get('/article/n' + articleid + '/public');
  }

  //获取高校院系列表
  getCollegePart(collegeid) {
    return this.api.get('/college/' + collegeid + '/faculty/list/public');
  }

  //获取高校院系列表(校区联动筛选)
  getCollegeCampusPart(collegeid, data) {
    return this.api.get('/college/' + collegeid + '/faculty/list/public', data);
  }

  //获取院系的专业列表
  getCollegeMajor(facultyid) {
    return this.api.get('/major/' + facultyid + '/list/public');
  }

  //获取院系详情
  getCollegeFaculty(facultyid) {
    return this.api.get('/college/faculty/' + facultyid + '/public')
  }

  //获取校区列表
  getCollegeCampusList(collegeid) {
    return this.api.get('/campus/' + collegeid + '/list/public')
  }

  //根据编码获取高校信息
  getCollegeByCode(code) {
    return this.api.get('/college/code/' + code);
  }

  //获取文章详情
  getArticleDetails(id) {
    return this.api.get('/article/' + id + '/public');
  }

  //获取双选会列表（所有双选会）
  getDepartmentMilkList(data) {
    return this.api.get('/milkround/department/list', data)
  }

  //获取企业申请的双选会列表
  getDepartmentSignMilkList(data) {
    return this.api.get('/milkround/apply/list/department', data)
  }

  //获取企业申请的宣讲会列表
  getDepartmentSignCampusList(data) {
    return this.api.get('/campustalk/apply/list', data);
  }

  //修改双选会报名申请
  updateMilkSign(id, data) {
    return this.api.post('/milkround/apply/department/' + id + '/update', data)
  }

  //修改宣讲会报名申请
  updateCampusSign(id, data) {
    return this.api.post('/campustalk/apply/' + id + '/update', data)
  }

  //企业获取宣讲会详情
  getCampusInfo(id) {
    return this.api.get('/campustalk/apply/' + id)
  }

  //获取上次申请双选会填写数据
  getPrevMIlkApply(applyid) {
    return this.api.get('/milkround/apply/department/' + applyid)
  }

  //检测高校认证信息
  checkDepCollegeIdentity(collegeid) {
    return this.api.get('/authentication/' + collegeid + '/check')
  }

  ///////////////////////////////////////////////////////////////////////IM会话操作接口
  //置顶当前会话
  setIMtop(_dlid) {
    return this.api.post('/im/dialogue/' + _dlid + '/top', { dlid: _dlid });
  }

  //聊天会话设置为不合适
  setIMinappropriate(_dlid) {
    return this.api.post('/im/dialogue/' + _dlid + '/inappropriate', { dlid: _dlid });
  }

  //屏蔽聊天会话
  setIMshield(_dlid) {
    return this.api.post('/im/dialogue/' + _dlid + '/shield', { dlid: _dlid });
  }

  //举报聊天会话
  setIMtipoff(_dlid, data) {
    return this.api.post('/im/dialogue/' + _dlid + '/tip_off?reason=' + data.reason, data);
  }

  //获取面试信息
  getInterviewById(interviewid) {
    return this.api.get('/interview/department/' + interviewid);
  }

  //发送面试offer
  sendInterviewOffer(interviewid, data) {
    return this.api.post('/interview/send/offer?interviewid=' + interviewid, data);
  }

  ///////////////////////////////////////////////////////////////////////字典接口
  //获取字典数据
  getDictDate(_dicttype) {
    return this.api.get('/dict/' + _dicttype);
  }

  //获取父字典的子字典列表
  getChildDictDate(_dicttype, data) {
    return this.api.get('/dict/' + _dicttype + '/' + data);
  }

  //根据名称模糊匹配返回字典
  getSearchListDic(_dicttype, data) {
    return this.api.get('/dict/' + _dicttype + '/matching?name=' + data.name + '&level=' + data.level);
  }
  //根据国标专业3级匹配 返回工作啦专业二级
  getmajor_lvel2(data) {
    return this.api.get('/dict/contrast/major_gb_level3/major_lvel2/match', data);
  }

  ///////////////////////////////////////////////////////////////////////企业位置相关接口
  //获取公司位置信息
  employerPositionInfo() {
    return this.api.get('/department/location/position')
  }

  //修改公司位置信息
  employerUpdatePositionInfo(_lid, data) {
    return this.api.post('/department/location/' + _lid + '/update', data)
  }

  //新增公司位置信息（企业信息）
  employerCreatePositionInfo(data) {
    return this.api.post('/department/location/create', data)
  }

  //删除公司位置信息
  employerDeletePositionInfo(_lid) {
    return this.api.post('/department/location/' + _lid + '/delete')
  }

  //将所选公司地址地址设为默认
  employerDefaultPositionInfo(_lid) {
    return this.api.post('/department/location/' + _lid + '/default')
  }

  //高校热门城市
  getCollegeHotCity() {
    return this.api.get('/college/hot/city')
  }

  ///////////////////////////////////////////////////////////////////////签约中心
  //企业发起的签约信息列表
  getHiredDepartmentList(data) {
    return this.api.get('/signup/department/initiate/list', data)
  }

  //企业收到的签约信息列表
  getHiredTalentList(data) {
    return this.api.get('/signup/department/receipt/list', data)
  }

  //检测是否有已完成的面试
  checkInterviewEnd(talentid) {
    return this.api.post('/interview/send/' + talentid + '/check');
  }

  //未完成面试邀请时发送录用通知
  sendOffter(talentid, data) {
    return this.api.post('/offer/send/' + talentid + '/offer', data);
  }

  ///////////////////////////////////////////////////////////////////////广告
  getBoothList() {
    return this.api.get('/booth/client/pcemployer/list/public')
  }

  getBoothWithKey(key) {
    return this.api.get('/booth/manage/list/public?alias=' + key)
  }
  //////////////////////////////////////////////////////////////////////////发票
  //获取订单详情
  getOrderDetails(orderid) {
    return this.api.get('/order/' + orderid);
  }

  //获取支付渠道 android ios pc wap wx
  getPayChannel() {
    return this.api.get('/payment/pc/channel');
  }

  //获取当前用户是否第一次申请高校合作
  getIsFirstIdentity() {
    return this.api.get('/department');
  }

  //检查企业是否绑定企业
  getComopanyIsBingWeiChat(data) {
    return this.api.get('/wechat/department/' + data + '/bind/check');
  }


  //更新企业的名称和营业执照
  updateDepartmentNameAndPic(data) {
    return this.api.post('/authentication/platform/apply/businesslicence/update', data);
  }

  //获取企业申请认证信息
  getCompanyStatus(data) {
    return this.api.get('/department/' + data + '/apply');
  }

  //上传文件 word excel
  uploadFile(data) {
    return this.api.post('/college/uploadimage', data);
  }
  //发送邮箱验证码
  sendEmail(data) {
    return this.api.post('/user/active/active_email', data);
  }
  findPswSendEmail(data) {
    return this.api.agentPost('/user/password/reset_email', data);
  }

  //验证邮箱
  virtyEmail(data) {
    return this.api.post('/user/active/email', data);
  }
  changePsw(data) {
    return this.api.post('/user/password/change', data);

  }
  findPswCheckEmailCode(data) {
    return this.api.agentPost('/user/emailvcode/reset/check', data);
  }
  findPswResetPsw(data) {
    return this.api.agentPost('/user/password/resetbyemail', data);
  }
  //邮箱登录验证图片验证码
  emailLoginCheckPicCode(data) {

    return this.api.post('/topsystem/vcode/verify', data);

  }

  //招聘会反馈确认
  confirmMilkround(data, mrid, type) {
    return this.api.post('/milkround/' + mrid + '/' + type + '/feedback/confirm', data);
  }
  //验证招聘会是否二次确认
  virtyMilkroundFeedBack(mrid) {
    return this.api.get('/milkround/' + mrid + '/feedback/confirm_status');
  }
  //获取双选会返回结果
  getMilkroundFeedBackPost(mrid) {
    return this.api.get('/milkround/' + mrid + '/joinposter');
  }
  //获取高校招聘简章的开关
  getCollegeZhaopinSwitch(collegeid) {
    return this.api.get('/position_brief/college/switch/' + collegeid);
  }
  //获取校招顾问二维码联系方式
  getRecruitmentSelf() {
    // return this.api.get("recruitment_consultant/department/self");
    let _departmentStr = this.local.get('department')
    if (_departmentStr) {
      let depart = JSON.parse(_departmentStr);
      return this.api.urmGetData('/recruitmentconsultant/bydepartmentid?departmentid=' + depart.accountid, {})

    }
  }
  //用户设置是否被推荐
  setDepartmentTuiJian(data) {
    return this.api.post("/department/switch/display_mobile", data);
  }


  //空中宣讲会相关 开始
  //获取企业的最新有效宣讲会
  getAirCampustalk(collegeid) {
    return this.api.get("/air_campustalk/get/" + collegeid);
  }
  //获取空中宣讲会详情
  getAirCampustalkWithid(acid, collegeid) {
    return this.api.get("/air_campustalk/" + acid + "/college/" + collegeid);
  }

  //提交空中宣讲会
  commitCampustalk(id, data) {
    return this.api.post("/air_campustalk/submit/college/" + id, data);
  }
  //更新空中宣讲会
  updateCampustalk(data) {
    return this.api.post("/air_campustalk/update", data);
  }
  //创建问题
  creatAirQuestion(data) {
    return this.api.post("/api/AirQuestion/CompanySelfQuestion", data);
  }
  //更新问题
  updateAirQuestion(data) {
    return this.api.post("/api/AirQuestion/CompanyEditSelfQuestion", data);
  }
  //删除问题
  deleteAirQuestion(airquestionid) {
    return this.api.post("/api/AirQuestion/CompanyDeleteTalentQuestion/" + airquestionid + "/Delete");
  }
  //回复学生问题
  AnswerTalent(data) {
    return this.api.post("/api/AirQuestion/CompanyAnswerTalentQuestion", data);
  }
  //获取问题
  getAirQuestion(data) {
    return this.api.get("/api/AirQuestion/GetALLQuestionList", data);
  }
  //获取企业申请的高校信息
  getApplyCollegeList(data) {
    return this.api.get("/air_campustalk_apply/department/list/" + data);
  }

  //推广到高校
  air_campustalk_applyCollege(data) {
    return this.api.post("/air_campustalk_apply/department/apply_bulk", data);
  }
  //推广到学生
  air_campustalk_applyTalent(data) {
    return this.api.post("/air_campustalk/activity/invite/talent", data);
  }
  //设置问题是否公开
  setQuestionIsPublic(data) {
    return this.api.post("/api/AirQuestion/CompanySetTalentQuestionIsPublic", data);
  }
  //获取空中宣讲会学生列表
  getAirCampResumeList(id, data) {
    return this.api.post("/air_campustalk/" + id + "/resume/list", data);
  }
  //增加视频的播放量
  setVideoPlayCount(id) {
    return this.api.post("/air_campustalk/increase/video_play_count/" + id);
  }
  //获取宣讲会列表，往期列表使用
  getAircampustalkList(id, data) {
    return this.api.get("/air_campustalk/list/college/" + id, data);
  }
  //获取宣讲会分享图片
  getAircampustalkImage(id, data) {
    return this.api.get("/air_campustalk/public/mp_image/" + id, data);
  }
  //空中宣讲会下线
  downLine(id, data) {
    return this.api.post("/air_campustalk/online/switch/" + id, data);
  }
  //获取空中宣讲会未投递的学生数
  getAirStudentCount(data) {
    return this.api.get("/air_campustalk/not_delivery_student", data);
  }

  getDeliveryStatistics(data) {
    return this.api.get("/delivery/college/statistics", data);
  }

  //取消注册
  cancelRegister(data) {
    return this.api.post("/account/register/cancel", data);
  }

  //设置投递简历已读
  setDeliveryRead(deliveryid) {
    return this.api.post("/delivery/" + deliveryid + "/read");
  }
  setDepartmentSwitchContactinfo(data) {
    return this.api.post("/department/contactinfo", data);
  }
  //企业工作台去设置企业公开信息标记为true
  departmentSwitchContactinfo(data) {
    return this.api.post("/department/switch/contactinfo", data);
  }

  //获取申请宣讲会的提示
  getCampApplyTips(collegeid) {
    return this.api.get('/campustalk/apply/' + collegeid + '/tips')
  }
  //空中宣讲会相关 结束

  //用户绑定微信
  userBindWeChat(data) {
    return this.api.agentPost('/user/bind/wechat', data);
  }

  //验证密码格式是否正确
  checkPassword(Password) {
    return this.api.get("/user/password/check?password=" + Password);
  }
  //用户绑定邮箱并设置密码
  userBindEmailSetPsw(data) {
    return this.api.post("/user/active/email/setpwd", data);
  }

  //解绑微信登录
  unBindWeChat(data) {
    return this.api.post('/wechat/department/unbind', data);
  }

  //获取宣讲会参会凭证
  getCampustalkJoginCerInfo(data) {
    return this.api.get('/campustalk/join_certificate\n', data)
  }

  // 获取是否允许申请宣讲会
  getCampustalkApplyAllowd(data) {
    return this.api.get('/campustalk/apply/allowd', data)
  }
  // 获取是否允许申请双选会
  getMilkroundApplyAllowd(data) {
    return this.api.get('/milkround/apply/allowd', data)
  }

  //检查是否有相同企业申请同一场双选会
  checkMilkroundSameNameApply(mrid) {
    return this.api.get('/milkround/' + mrid + '/same/check')
  }

  getMyMilkroundHistory(data) {
    return this.api.get("/milkround/apply/list/underway/department", data);
  }
  getMyCampustalk1History() {
    return this.api.get("/campustalk/apply/list/underway",);
  }

  // 获取高校的合作企业列表
  getMilkroundAlumnDepartment(data) {
    return this.api.get('/milkround_alumn/department/info', data)
  }

  // 撤销双选会报名
  revokeMilk(applyid){
    return this.api.post(`/milkround/apply/department/${applyid}/revoke`)
  }

  // 撤销宣讲会报名
  revokeCampus(applyid){
    return this.api.post(`/campustalk/apply/${applyid}/revoke`)
  }

  // 撤销高校合作申请
  revokeCooperate(collegeid){
    return this.api.post(`/authentication/${collegeid}/apply/revoke`)
  }

  // 修改账号名字
  uplinkman(data){
    return this.api.post(`/department/linkman/update`,data)
  }


  ///////////////////////////////////////////////////////////////////////操作接口


  ///////////////////////////////////////////////////////////////////////产品订单接口


  ///////////////////////////////////////////////////////////////////////意见反馈


  ///////////////////////////////////////////////////////////////////////上传接口


  ///////////////////////////////////////////////////////////////////////账户管理


  ///////////////////////////////////////////////////////////////////////测试接口



  //local(本地存储接口)————————————————————————————————————————————————————————
  //设置tooken
  setLocalToken(token: string) {
    this.local.set(Constants.accesstoken, token);
    this.config.token = token;
  }
  getLocalToken() {
    return this.local.get(Constants.accesstoken);
  }
  //设置accountid
  setLocalAccountId(accountid: string) {
    this.local.set(Constants.accountid, accountid);
    this.config.accountid = accountid;
  }
  getLocalAccountId() {
    return this.local.get(Constants.accountid);
  }
  //设置登录的手机号
  setLocalLoginName(_data) {
    this.local.set(Constants.loginName, _data);
    this.config.loginName = _data;
  }
  //设置登录的手机号
  setLocalLoginEmail(_data) {
    this.local.set(Constants.loginEmail, _data);
    this.config.loginEmail = _data;
  }
  getLocalLoginName(): any {
    return this.local.get(Constants.loginName);
  }
  //刷新token数据本地存储
  setRefreshToken(_data) {
    this.local.set(Constants.refreshToken, JSON.stringify(_data));
    this.config.refresh_token = _data;
  }
  getRefreshToken() {
    return JSON.parse(this.local.get(Constants.refreshToken));
  }
  //设置本地当前登录用户的信息(user信息)
  setLocalLoginUser(_data) {
    this.local.set(Constants.loginUser, JSON.stringify(_data));
    this.config.loginUser = _data;
  }
  getLocalLoginUser() {
    return JSON.parse(this.local.get(Constants.loginUser));
  }
  //设置企业信息
  setLocalDepartment(_data) {
    this.local.set(Constants.department, JSON.stringify(_data));
    this.config.department = _data;
  }
  getLocalDepartment() {
    return JSON.parse(this.local.get(Constants.department));
  }

  //聊天记录本地存储
  setLocalChatsMsgList(_data) {
    this.local.set(Constants.chatsMsgList, JSON.stringify(_data));
    this.config.chatsMsgList = _data;
  }
  //聊天记录本地存储
  getLocalChatsMsgList() {
    return JSON.parse(this.local.get(Constants.chatsMsgList));
  }
  //会话列表本地存储
  setLocalChatsList(_data) {
    this.local.set(Constants.chatsList, JSON.stringify(_data));
    this.config.chatsList = _data;
  }
  //会话列表本地存储
  getLocalChatsList() {
    return JSON.parse(this.local.get(Constants.chatsList));
  }
  //职位列表本地存储
  setLocalpositionList(_data) {
    this.local.set(Constants.positionList, JSON.stringify(_data));
    this.config.positionList = _data;
  }
  //职位列表本地存储
  getLocalpositionList() {
    return JSON.parse(this.local.get(Constants.positionList));
  }
  //---------企业最后一次认证信息本地存储
  setLastIdentity(_data) {
    this.local.set(Constants.lastIdentity, JSON.stringify(_data));
    this.config.lastIdentity = _data;
  }
  getLastIdentity() {
    return JSON.parse(this.local.get(Constants.lastIdentity));
  }
  //---------企业最后一次高校认证信息本地存储
  setLastCollegeIdentity(_data) {
    this.local.set(Constants.lastCollegeIdentity, JSON.stringify(_data));
    this.config.lastCollegeIdentity = _data;
  }
  getLastCollegeIdentity() {
    return JSON.parse(this.local.get(Constants.lastCollegeIdentity));
  }
  //--------行业字典本地存储
  setIndustryCode(_data) {
    this.local.set(Constants.industry, JSON.stringify(_data));
    this.config.industry = _data;
  }
  getIndustryCode() {
    return JSON.parse(this.local.get(Constants.industry));
  }
  //--------行业字典本地存储
  setEmployerTypeCode(_data) {
    this.local.set(Constants.employer_type, JSON.stringify(_data));
    this.config.employer_type = _data;
  }
  getEmployerTypeCode() {
    return JSON.parse(this.local.get(Constants.employer_type));
  }
  //--------职位类别字典本地存储
  setPostTypeCode(_data) {
    this.local.set(Constants.post_type, JSON.stringify(_data));
    this.config.post_type = _data;
  }
  getPostTypeCode() {
    return JSON.parse(this.local.get(Constants.post_type));
  }
  //--------专业字典本地存储
  setMajorCode(_data) {
    this.local.set(Constants.major, JSON.stringify(_data));
    this.config.major = _data;
  }
  getMajorCode() {
    return JSON.parse(this.local.get(Constants.major));
  }
  //--------技能字典本地存储
  setSkillCode(_data) {
    this.local.set(Constants.skill, JSON.stringify(_data));
    this.config.skill = _data;
  }
  getSkillCode() {
    return JSON.parse(this.local.get(Constants.skill));
  }
  //--------地区字典本地存储
  setRegionCode(_data) {
    this.local.set(Constants.region, JSON.stringify(_data));
    this.config.region = _data;
  }
  getRegionCode() {
    return JSON.parse(this.local.get(Constants.region));
  }
  //--------企业规模字典本地存储
  setScaleCode(_data) {
    this.local.set(Constants.scale, JSON.stringify(_data));
    this.config.scale = _data;
  }
  getScaleCode() {
    return JSON.parse(this.local.get(Constants.scale));
  }
  //--------学历字典本地存储
  setEducationCode(_data) {
    this.local.set(Constants.education, JSON.stringify(_data));
    this.config.education = _data;
  }
  getEducationCode() {
    return JSON.parse(this.local.get(Constants.education));
  }
  //--------常用语字典本地存储
  setCommonCode(_data) {
    this.local.set(Constants.common, JSON.stringify(_data));
    this.config.common = _data;
  }
  getCommonCode() {
    return JSON.parse(this.local.get(Constants.common));
  }
  //--------小秘书常用语字典本地存储
  setCommonJunCode(_data) {
    this.local.set(Constants.commonJun, JSON.stringify(_data));
    this.config.commonJun = _data;
  }
  getCommonJunCode() {
    return JSON.parse(this.local.get(Constants.commonJun));
  }

  //退出登录(清空缓存)
  logout() {
    //清除本地存储缓存
    this.local.remove(Constants.accesstoken);//tooken
    this.local.remove(Constants.accountid);//accountid
    this.local.remove(Constants.loginUser);//用户信息
    // this.local.remove(Constants.loginName);//用户登录账号
    this.local.remove(Constants.department);//企业信息
    this.local.remove(Constants.lastIdentity);//企业最后一次认证信息
    this.local.remove(Constants.chatsMsgList);//聊天记录
    this.local.remove(Constants.chatsList);//会话列表
    this.local.remove(Constants.positionList);//职位列表
    this.local.remove(Constants.chatPosition);//首次沟通需要的职位参数
    this.local.remove(Constants.chatsInfo);//沟通页面会话信息参数
    this.local.remove(Constants.userInfo);//沟通页面会话信息参数(新会话)
    this.local.remove(Constants.seat_number);//沟通页面展位号参数
    this.local.remove(Constants.refreshToken);//刷新token数据
    this.local.remove(Constants.industry);//行业字典
    this.local.remove(Constants.employer_type);//企业类别
    this.local.remove(Constants.post_type);//职位类别
    this.local.remove(Constants.major);//专业字典
    this.local.remove(Constants.skill);//技能字典
    this.local.remove(Constants.region);//地区字典
    this.local.remove(Constants.scale);//企业规模
    this.local.remove(Constants.common);//常用语字典
    this.local.remove(Constants.commonJun);//小秘书常用语字典
    this.local.remove(Constants.education);//学历字典
    // this.local.remove(Constants.resumeid);//简历id(防止刷新时报错)
    // this.local.remove(Constants.collegeid);//高校id(防止刷新时报错)
    // this.local.remove(Constants.mrid);//双选会id(防止刷新时报错)
    this.local.remove(Constants.positionid);//职位id(防止刷新时报错)
    this.local.remove(Constants.facultyid);//学院id(防止刷新时报错)
    this.local.remove(Constants.articleid);//文章id(防止刷新时报错)
    this.local.remove(Constants.campusApply);//宣讲会申请数据
    this.local.remove(Constants.oldUrl);//旧路由
    this.local.remove(Constants.newUrl);//新路由
    this.local.remove(Constants.briefDetais);//招聘简章详情
    this.local.remove(Constants.briefid);//招聘简章id
    this.local.remove(Constants.loginRs);//登陆来源参数
    // this.local.remove('collegeid')
    this.local.remove('resetOneStep'); //注册返回上/下一步缓存数据
    this.local.remove('register_guide');//注册返回上/下一步缓存数据
    this.local.remove('register_identity');//注册返回上/下一步缓存数据
    this.local.remove('register_guide_company');//注册返回上/下一步缓存数据
    this.local.remove('register_userAvatar');//注册返回上/下一步缓存数据

    // this.local.remove(Constants.byCollege);//就业信息网id
    // this.local.remove(Constants.byCollegeDetails);//就业信息网高校信息

    //清除全局变量
    this.config.token = '';
    this.config.accountid = '';
    this.config.loginUser = null;
    this.config.department = null;
    this.config.chatsMsgList = [];
    this.config.refresh_token = null;
    this.config.lastIdentity = null;
    this.config.industry = [];
    this.config.post_type = [];
    this.config.major = [];
    this.config.skill = [];
    this.config.region = [];
    this.config.common = [];
    this.config.commonJun = [];
    //下面只有全局变量
    this.config.positionList = [];
    this.config.chatsList = [];
    this.config.school_type = [];
    this.config.school_nature = [];
    this.config.school_label = [];
    this.config.school = [];
    this.config.label = []; //企业标签字典
    //console.log('token、loginuser已经清除，退出成功');
    //取消events事件监听
    // this.events.unsubscribe('user:notification');//取消订阅账号被踢下线事件
    // // this.events.unsubscribe('user:created');//取消订阅api.ts通知退出登陆事件
    // this.events.unsubscribe('newMessage:tabs');//取消订阅tabs页面接受到了新消息广播事件
    // this.events.unsubscribe('tabsTo:chat');//取消订阅会话页面接受到了新消息广播事件
    // this.events.unsubscribe('chatTo:contact');//取消订阅更新简历详情页面状态事件
    // this.events.unsubscribe('noticeTo:contact');//取消订阅发送面试邀请后更新会话页面的广播
  }


  dowmLoadImage(uri,) {

  }

  getNoDepardmentID() {
    return '请从高校就业信息网登录';
  }
  formatImage(imageData) {
    imageData = imageData.replace(/^(data:image)\/png;base64,/g, '');
    imageData = imageData.replace(/^(data:image)\/jpeg;base64,/g, '');
    imageData = imageData.replace(/^(data:image)\/JPEG;base64,/g, '');
    imageData = imageData.replace(/^(data:image)\/jpg;base64,/g, '');
    imageData = imageData.replace(/^(data:image)\/JPG;base64,/g, '');
    imageData = imageData.replace(/^(data:image)\/PNG;base64,/g, '');

    return imageData
  }

}
