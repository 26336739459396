import { Component, OnInit,Output} from '@angular/core';
import { DataApi,Local,Constants,Opera,Config,FormatDate} from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Events } from '../../provider/eventService';
import {Router} from "@angular/router";


@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.css']
})
export class NotificationCenterComponent implements OnInit {
  // 筛选条件
  public config: Config;
  public model={
    sender_account_type:'',
    offset:0,
    limit:10,
    sender:''
  };
  public taskList=[];
  public chatsList:any;//会话列表
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  constructor(
    public dataApi: DataApi,
    public opera:Opera,
    public local:Local,
    public toastr: ToastrService,
    private router: Router,
    public events : Events,
  ) {
    //接收到更新会话列表的广播
    this.events.subscribe('chatTo:contact', (data) => {
      this.config.chatsList=data;
      // //console.log('会话列表更新');
      this.chatsList=data.list;
    });
  }

  ngOnInit() {
    this.opera.initConfig('chat','');
    this.model.sender = this.local.get('collegeid');
    this.getTaskTodoList();
    this.getNewChatList();

  }
  chooseStatus(n){
    this.model.sender_account_type = n;
    this.getTaskTodoList()
  }

  getNewChatList(){
    this.dataApi.getImDialogueAllList({}).then((res:any)=>{
      this.dataApi.setLocalChatsList(res);//会话列表本地存储
      this.chatsList = res.list;
    }).catch((error)=>{
      this.toastr.error(error);
    });
  }
  //去沟通页面
  goContactChat(n){
    //console.log("判断当前有无会话列表------college-resumedetails");
    //console.log(this.chatsList);
    if(this.chatsList){//判断当前有无会话列表
      if(this.chatsList.length>0){
        let isInDialog = false;//当前人才是否已在会话列表中
        this.chatsList.forEach((val)=>{
          if(val.dlid == n.businessid){
            isInDialog = true;
            // this.config.isNavActive='college';
            // this.config.isNavChildActive='college-resume';
            //展位号
            this.local.remove(Constants.chatPosition);//职位参数
            this.local.set(Constants.chatsInfo,JSON.stringify(val));//聊天记录本地存储参数
            this.router.navigate(['home/chat-contact']);
          }
        });
      }else{
        this.toastr.warning('暂未找到聊天记录');
      }
    }
  }

  getTaskTodoList(){
    this.opera.loading();
    this.dataApi.getTodoList(this.model).then((res:any)=>{
      this.countList=res.count;
      this.pageCount =Math.ceil(res.count/10);
      this.taskList =  res.list;
      this.taskList.map((item,index)=>{
        if(item.content){
          if(NotificationCenterComponent.isJsonString(item.content)){
            item.content = JSON.parse(item.content)
            // console.log(index)
          }
        }


        item.titleColor = item.alias == 'milkround_apply_not_passed'
          || item.alias == 'college_audit_not_passed'
          || item.alias == 'college_audit_campustalk_not_passed'
          || item.alias == 'system_notice_department_campustalk_not_passed'
          || item.alias == 'college_audit_position_not_passed'
          || item.alias == 'system_notice_milkround_apply_not_passed'
          || item.alias == 'authentication_not_passed';
      })
      console.log(this.taskList)
      this.opera.loadiss();
    }).catch((error) => {
      this.toastr.error(error);
      this.opera.loadiss();
    });
  }
  static isJsonString(str) {
    try {
      if (typeof JSON.parse(str) == "object") {
        return true;
      }
    } catch(e) {
    }
    return false;
  }
  //处理操作
  goHandle(n){
    if(!n.view){
      this.dataApi.viewTodo(n.taskid).then((res:any)=>{
        //this.toastr.success('已查看');
        this.events.publish('isReadNotice',false);
        this.opera.loadiss();
      }).catch((error) => {
        this.toastr.error(error);
        this.opera.loadiss();
      });
    }
    if(n.pending){
      this.dataApi.doneTodo(n.taskid).then((res:any)=>{
        this.toastr.warning('消息已处理！');
      }).catch((error) => {
        this.toastr.error(error);
      });
    }
    if(n.alias == 'lacoin_charge_success'){
      this.router.navigate(['home/payHistory']);
    }else if(n.alias == 'talent_delivery'){

      this.router.navigate(['home/position-resume']);

    }else if(n.alias == 'college_audit_not_passed' || n.alias == 'college_audit_passed'){

      this.router.navigate(['home/college-campustalk1']);

    }else if( n.alias == 'college_audit_position_passed' || n.alias == 'college_audit_position_not_passed'){

      this.router.navigate(['home/position-manage']);

    }else if(n.alias == 'department_campustalk_apply_wait_to_audit' ||n.alias == 'college_audit_campustalk_passed' || n.alias == 'college_audit_campustalk_not_passed' || n.alias =='system_notice_department_campustalk_not_passed'){
      this.local.set("ctid", n.businessid);
      this.router.navigate(['home/college-details-preach']);

    }else if(n.alias == 'lacoin_charge_failed'){
      this.router.navigate(['home/buyLaCoin']);
    }else if(n.alias == 'talent_leave_message_to_department'){
      this.goContactChat(n);
      //this.router.navigate(['home/chat-contact']);

    }else if(n.alias == 'authentication_not_passed' || n.alias == 'authentication_passed'){

      this.dataApi.getPlatformApply().then((res:any)=>{
        this.config.lastIdentity=res;
        if(res.status == 2){
          this.router.navigateByUrl('/home/company-authentication-suc');
        }else if(res.status == 1){
          this.router.navigateByUrl('/home/company-authentication-wait');
        }else if(res.status == 3){
          this.router.navigateByUrl('/home/company-authentication');
        }else{
          this.router.navigateByUrl('/home/company-authentication');
        }
      }).catch((err)=>{
        this.router.navigateByUrl('/home/company-authentication');
      })

    } else if(n.alias == 'product_charge_failed'){

      this.local.set("recharge_category_code", JSON.stringify(n.businessid));
      this.router.navigate(['home/buyProduct']);

    }else if(n.alias == 'milkround_apply_wait_to_audit' || n.alias == 'department_apply_milkround_confirm' ||n.alias == 'milkround_apply_not_passed' || n.alias == 'milkround_notice_department_by_depttype' || n.alias == 'milkround_notice_department_by_feedback' || n.alias == 'milkround_invite_department' || n.alias == 'system_notice_milkround_apply_not_passed'){

      this.router.navigate(['home/college-milkdetails'],{
        queryParams: {
          id:n.businessid,
        }
      });
    }else{
      this.getTaskTodoList();
    }
  }
  //获取分页子组件返回数据
  getChildEvent(index){
    //console.log('子组件返回分页数----');
    console.log(1);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*10;
      this.getTaskTodoList();//获取列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*10;
        console.log(2);
        this.getTaskTodoList();//获取列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*10;
        console.log(3);
        this.getTaskTodoList();//获取列表
      }
    }

  }

}
