import {Injectable,Provider} from '@angular/core';
/*
 * 对Storage进行二次封装，增加可维护性, 或方便添加我们自己的钩子代码
 */
@Injectable()
export class milkround {



    formatList(arr) {
        // for(let item of arr){
        //     let nowDate = new Date();
        //     let startDate = new Date(item.start_time);
        //     let endDate = new Date(item.end_time);
        //     let applyOpDate = new Date(item.apply_start_time); // 报名开始时间
        //     let applyEndDate = new Date(item.apply_end_time); // 报名结束时间
        //     let nowTime = nowDate.getTime();  // 当前时间戳
        //     let startTime = startDate.getTime();  // 开始时间戳
        //     let endTime = endDate.getTime();  // 结束时间戳
        //     let applyOpTime = applyOpDate.getTime();  // 报名开始时间戳
        //     let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳
        //     if(nowTime-endTime>0){
        //         item.isEnd=true; // 会议已结束
        //     }else{
        //         item.isEnd=false; // 会议未结束
        //     }
        // }
        for(let val of arr){
            if(val.audit_status){
                val.status = val.audit_status
            }
            let nowDate = new Date();
            let startDate = new Date(val.start_time);
            let endDate = new Date(val.end_time);
            let applyOpDate = new Date(val.apply_start_time); // 报名开始时间
            let applyEndDate = new Date(val.apply_end_time); // 报名结束时间
            let nowTime = nowDate.getTime();  // 当前时间戳
            let startTime = startDate.getTime();  // 开始时间戳
            let endTime = endDate.getTime();  // 结束时间戳
            let applyOpTime = applyOpDate.getTime();  // 报名开始时间戳
            let applyEndTime = applyEndDate.getTime();  // 报名结束时间戳
            val.signOpTime = false;
            if(applyOpTime == -62135625943000 || applyOpTime == -62135625600000 || val.apply_start_time == '0001-01-01T00:00:00' || val.apply_start_time == '0001-01-01T00:00:00+08:00' || val.apply_start_time == ''){
                val.signOpTime = false; // 未设置报名开始时间
            }else{
                val.signOpTime = true;
            }
            if(applyEndTime == -62135625943000 || applyEndTime == -62135625600000 || val.apply_end_time == '0001-01-01T00:00:00' || val.apply_end_time == '0001-01-01T00:00:00+08:00' || val.apply_end_time == ''){
                val.signEdTime = false; // 未设置报名结束时间
            }else{
                val.signEdTime = true;
            }
            if(nowTime-startTime>0){
                val.isApplySign=false;
                val.isStart=true; // 会议已开始
            }else{
                val.isStart=false;  // 会议未开始
                val.isApplySign=true;
            }
            //增加是否是活动判断
            if(val.is_activity){
                if(endTime-nowTime>0){
                    val.isApplySign=true;
                }else{
                    val.isApplySign=false;
                }
            }
            if(nowTime-endTime>0){
                val.isEnd=true; // 会议已结束
            }else{
                val.isEnd=false; // 会议未结束
            }
            if(val.signOpTime){ // 有报名时间和报名结束时间
                if(nowTime-applyOpTime>0){
                    val.isAppStart=true; // 报名已开始
                }else{
                    val.isAppStart=false; // 报名未开始
                }
                if(nowTime-applyEndTime>0){
                    val.isAppEnd=true; // 报名已结束
                }else{
                    val.isAppEnd=false; // 报名未结束
                }
            }
            // loadState
            //现在 时间 小于开始时间  = 报名未开始
            if(nowTime < applyOpTime) {
                val.loadState = 1;
            } else  if(applyOpTime < nowTime && nowTime < applyEndTime) {
                //现在时间大于 报名开始时间 && 现在时间小于报名结束时间
                //报名中
                val.loadState = 2;
            } else if(nowTime>applyEndTime) {
                //报名已经截止
                val.loadState = 3;
                if(startTime<nowTime && nowTime<endTime) {
                    //现在时间 大于开始时间 && 开始时间小于结束时间 == 进行中
                    val.loadState = 4;
                } else if(nowTime>endTime) {
                    //已经结束
                    val.loadState = 5;
                }
            }
            // console.log("name",val.name);
            // console.log("报名是否结束",val.isAppEnd);
            // console.log("会议是否结束",val.isEnd);
            // console.log("会议是否开始",val.isStart);
            // console.log("报名是否开始",val.isAppStart);

            //前面的状态
            // 1 可申请 state =null
            // 2 已申请state != null

            // 1报名已结束 val.isAppEnd=true
            // 正在进行 val.isStart && !val.isEnd
            // 已经结束 val.isEnd=true

            //操作按钮
            // 报名失败  只有认证和报名失败才显示  n.status=='not_passed'|| n.cooperation_status==3

            //报名失败按钮
            if(val.status=='not_passed' || val.cooperation_status==3 || val.status=='revoke') {
                val.showFaileBtn = true;
                val.FaileBtnText = "报名失败";
            } else {
                val.showFaileBtn = false;
                val.FaileBtnText = "";
            }

            //审核中 双选会审核中+合作审核中/合作通过 +报名未截止
            if(val.status=='to_audit'&&!val.isStart && (val.cooperation_status==1||val.cooperation_status==2)) {
                val.showShenHeBtn = true;
                val.showShenHeBtnText = "审核中";
            } else if (val.status=="passed" &&!val.isStart && val.cooperation_status==1) {
                val.showShenHeBtn = true;
                val.showShenHeBtnText = "审核中";
            } else {
                val.showShenHeBtn = false;
                val.showShenHeBtnText = "";
            }

            //展位号+通知学生   合作通过 + 双选会通过 + 会议未结束 + 会议已开始
            if(val.status == 'passed' &&val.cooperation_status==2) {
                val.showSuccessBtn = true;
            }

            //我要报名  未申请双选会
            if(val.status==null) {
                val.showBaoMingBtn = true;
            }

            //如果已经显示了报名失败的按钮，则强制不显示《我要报名》的按钮
            if(val.showFaileBtn) {
                val.showBaoMingBtn = false;
            }

            //如果已经显示了《审核中》的按钮，则强制不显示《我要报名》的按钮
            if(val.showShenHeBtn ) {
                val.showBaoMingBtn = false;
            }

            //合作通过+ 未报名双选会 +报名未结束
            if(val.status==null && val.cooperation_status==2 && !val.isAppEnd) {
                val.showBaoMingBtn = true;
            } else if(val.status==null && val.cooperation_status==0 && !val.isAppEnd) {
                val.showBaoMingBtn = true;
            } else if(val.status=="to_audit" && val.cooperation_status==0 && !val.isAppEnd) {
                val.showBaoMingBtn = true;
            } else if(val.status==null && val.cooperation_status==1 && !val.isAppEnd) {
                val.showBaoMingBtn = true;
            } else {
                val.showBaoMingBtn = false;
            }
            //报名开始 +双选会审核中/双选会未通过
            if(val.milkround_type == 1){
                if(val.isStart &&(val.status=="to_audit"||val.status=="not_passed")) {
                    val.showBaoMingBtn = false;
                    val.showShenHeBtn = false;
                    val.showFaileBtn = true;
                }
            }
            if(val.milkround_type == 2){
                if(val.isStart &&(val.status=="to_audit"||val.status=="not_passed")) {
                    if(val.status=="to_audit"){
                        val.showBaoMingBtn = false;
                        val.showShenHeBtn = true;
                        val.showFaileBtn = false;
                    }else if(val.status=="not_passed"){
                        val.showBaoMingBtn = false;
                        val.showShenHeBtn = false;
                        val.showFaileBtn = true;
                    }
                    if( val.cooperation_status==3){
                        val.showBaoMingBtn = false;
                        val.showShenHeBtn = false;
                        val.showFaileBtn = true;
                    }
                }
            }

            //如果合作失败/并且没有申请过双选会+ 报名未结束 =我要报名
            if(!val.status) {
                if( val.cooperation_status==3&&!val.isAppEnd && val.milkround_type == 1) {
                    val.showShenHeBtn = false;
                    val.showFaileBtn = false;
                    val.showBaoMingBtn = true;
                    // console.log('报名是否结束',val.name,val.isAppEnd)
                }
            }
            val.zone_name_string = '';
            if(val.zone_name){
                val.zone_name_string = val.zone_name;
                if(val.seat_number){
                    val.zone_name_string = val.zone_name +'-';
                }
            }

            //feedback_status
            //no_feedback 未反馈
            //no_join  不参会
            //join 已参会

            if(val.isAppEnd){
                val.showFaileBtn = false;
            }
        }
        return arr
    }

    formatCampus(arr){
        for(let val of arr){
            //宣讲会预约审核中 + 合作审核中/合作成功 = 审核中
            if(val.audit_status=='to_audit') {
                val.btnState = 1;
            }
            //显示预约成功
            if(val.audit_status=='pass') {
                val.btnState = 2;
            }
            //预约失败  预约失败/合作失败
            if(val.audit_status=='not_passed') {
                val.btnState = 3;
            }
            val.fail = false;
            if(val.audit_status=='not_passed'|| val.cooperation_status=='3') {
                val.fail = true;
            }
            val.isEnd = false
            let nowDate = new Date()
            let endDate = new Date(val.end_time)
            if(nowDate>endDate){
                val.isEnd = true
            }
        }
        return arr
    }


}
