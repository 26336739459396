import { Component, OnInit, Input, Output } from '@angular/core';
import {DataApi, Local, Constants, Config, Opera, milkround} from '../../provider/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from  '@angular/router';

@Component({
  selector: 'app-college-details-campus',
  templateUrl: './college-details-campus.component.html',
  styleUrls: ['./college-details-campus.component.css']
})
export class CollegeDetailsCampusComponent implements OnInit {
  public config: Config;
  public keywordList=[];//搜索关键词匹配列表
  public campusList = [];//宣讲会列表
   // 筛选条件
  public model={
    offset:0,
    limit:9
  }
  public countList = 0;//列表数量
  @Output() pageNumber = 1;//当前分页数
  @Output() pageCount = 0;//分页数量Math.ceil(this.countList/this.model.limit)
  public myDepartment:any;//公司信息
  public collegeid="";//高校id
  public college:any;//高校详情
  public isInit=false;
  public is_apply = true;
  public collegeIdentity:any;//企业当前高校认证数据

  public isShowJoinCerModel = false; // 是否展示参会凭证弹窗
  public JoinCerModel = null; //展示参会凭证弹窗
  public revokeModule = false;
  public revokeApplyid = '';

  constructor(public dataApi: DataApi,
    public local:Local,
    private router: Router,
    public opera:Opera,
    public milkroundFormat:milkround,
    public toastr: ToastrService) {
      this.config = Config.getInstance();//实例化config
  }


  ngOnInit() {
    this.opera.initConfig('college','college-details-campus');
    this.collegeid=this.local.get(Constants.collegeid);//赋值高校id
    if( this.collegeid) {
      this.checkDepartmentIndentityByCollege();//检测企业认证信息
      this.getDepartment();//获取企业信息
      this.getResumeList();//获取宣讲会列表
      this.getCollegeById();//获取高校详情
    }
  }

  //通过高校id获取高校详情
  getCollegeById(){
    this.opera.loading();
    this.dataApi.getCollageDetails(this.collegeid).then((res:any)=>{
      this.opera.loadiss();
      if(res) {
        this.isInit = true;
        this.college = JSON.parse(JSON.stringify(res));
      }
    }).catch((err)=>{
      this.toastr.error(err);
      this.isInit = true;
      this.opera.loadiss();
    });
  }

  //获取企业信息
  getDepartment(){
    if(this.dataApi.getLocalDepartment()){
          this.myDepartment = this.dataApi.getLocalDepartment();
        }else{
          this.dataApi.getDepartment().then((res:any)=>{
            if(res) {
              this.dataApi.setLocalDepartment(res);
            }
        }).catch((error) => {
          //console.log(error);
          this.toastr.error(error);
      });
    }
  }

  //修改宣讲会申请
  goUpdata(n,item){
    //console.log("修改宣讲会申请-------");
    //console.log(n);

    let collegeid = item.collegeid;
    this.dataApi.checkDepCollegeIdentity(collegeid).then((res:any)=>{
      if(res){
        if(res.gwl_status=='2'){//通过工作啦认证
          // this.local.set(Constants.collegeid,collegeid);

          this.local.set(Constants.campusApply,JSON.stringify(item));
          this.router.navigate(['home/college-campus-sign']);

        }else{//未通过工作啦审核
          if(res.status=='2'){//通过校招
            // this.local.set(Constants.collegeid,collegeid);
            this.local.remove(Constants.campusApply);
            this.local.set(Constants.campusApply,JSON.stringify(item));
            this.router.navigate(['home/college-campus-sign']);
          }else if(res.status=='1'){//校招审核中
            this.toastr.warning("高校合作审核中，审核通过后才可预约宣讲会");
          }else{//未提交校招申请
            this.opera.remindCollegeIdentity("预约宣讲会",collegeid,res);
          }
        }
      }else{
        this.opera.remindCollegeIdentity("预约宣讲会",collegeid,res);
      }
    }).catch((err)=>{
      //console.log(err);
      this.toastr.error(err);
    })
  }


  // 查看宣讲会详情
  goPreach(n){
    this.local.set(Constants.ctid,n.ctid);
    this.router.navigate(['home/college-details-preach']);
  }

  //申请宣讲会(再次申请)
  goSign(n){
    if(this.config.positionList.length==0){
      this.opera.remindPublish("申请宣讲会");
      return;
    }
    if(n) {
      this.local.set(Constants.campusApply,JSON.stringify(n));
    } else {
      this.local.remove(Constants.campusApply);
    }
    this.router.navigate(['home/college-campus-sign']);
    console.log("获取企业在高校的认证信息-----------------------CampusDetails");
  }

  //判断状态
  statusSign(){
    if(!this.college.campustalk_switch){
       this.toastr.warning("当前高校未开启宣讲会");
       return;
    }else if(!this.is_apply){
      this.toastr.warning("当前有正在审核的宣讲会");
       return;
    }else{
      this.goSign('');//报名
    }
  }

  //判断企业是否通过高校认证
  checkDepartmentIndentityByCollege(){
    this.dataApi.checkDepCollegeIdentity(this.collegeid).then((res:any)=>{
      console.log(res);
      if(res) {
        this.collegeIdentity=res;
      }
    }).catch((err)=>{
      //console.log(err);
    })
    this.dataApi.lastCollegeAuthenticationIdentity(this.collegeid).then((res:any)=>{
      console.log('lastCollegeAuthenticationIdentity',res);
      if(res){
        // this.config.lastCollegeIdentity=res;
        if(res.status==3){
          this.router.navigate(['home/collegeidentity'],{
            queryParams: {
              isShow:true,
              callbackUrl:'Campustalk'
            }
          });
        }
      }
      this.opera.loadiss();
    }).catch((error)=>{
      //console.log(error);
      this.opera.loadiss();
    })

  }

  //获取宣讲会申请记录
  getResumeList(){
    this.opera.loading();
    this.dataApi.getCampustalkByCollegeid(this.collegeid,this.model).then((res:any)=>{
      console.log("获取宣讲会申请记录-----",res,this.model);
      this.opera.loadiss();
      if(res) {
        this.campusList=this.milkroundFormat.formatCampus(res.list) ;
        this.countList=res.count;
        this.pageCount =Math.ceil(res.count/9);
      }
    }).catch((err)=>{
      //console.log(err);
      this.opera.loadiss();
    })
  }

  //获取分页子组件返回数据
  getChildEvent(index){
    console.log('子组件返回分页数-----');
    //console.log(index);
    if(index > 0){
      this.pageNumber = index;
      this.model.offset = (this.pageNumber-1)*9;
      this.getResumeList();//获取简历投递列表
    }else if(index == -1){//上一页
      if(this.pageNumber == 1){
        this.toastr.warning("当前为第一页");
      }else{
        this.pageNumber = this.pageNumber - 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }else if(index == -2){//下一页
      if(this.pageNumber == this.pageCount){
        this.toastr.warning("当前为最后一页");
      }else{
        this.pageNumber = this.pageNumber + 1;
        this.model.offset = (this.pageNumber-1)*9;
        this.getResumeList();//获取简历投递列表
      }
    }
  }

  /**
   * 下载附件
   */
  downLoadField(){
    console.log("downLoadField",this.college)
    if(this.college.audit_switch.department_receipt_url!=null&&this.college.audit_switch.department_receipt_url!='') {
      // window.location.href =this.college.audit_switch.department_receipt_url;
      window.open(this.college.audit_switch.department_receipt_url,'__blank')
    } else {
      this.toastr.error('附件不存在');
    }
  }
  showJoinCerModel(e,camp){
    e.stopPropagation();
    let data = {
      mcid:camp.ctid
    }
    this.dataApi.getCampustalkJoginCerInfo(data).then((res:any)=> {
      if(res){
        console.log('getCampustalkJoginCerInfo',res)
        this.JoinCerModel = res;
        console.log('showJoinCerModel',camp)
        this.isShowJoinCerModel = true
      }else {
        this.toastr.error('获取凭证失败')
      }
    }).catch(()=>{
      this.toastr.error('获取凭证失败')
    })
  }
  //去其他的双选会，工作啦企业后端的页面
  gotoOtherCampus(){
    if(this.local.get('accesstoken')) {
      let token = this.local.get('accesstoken');
      let accountid = this.local.get('accountid');
      let collegeid = this.local.get('collegeid');
      window.open(this.config.JumpMainWeb+token+'&accountid='+accountid+'&route='+'college-campustalk1','_blank')
    }
  }
  gotoAllApplyHistory(){
    this.router.navigate(['home/position-campus']);
  }

   // 撤回
   openRevoke(e,applyid){
    e.stopPropagation();
    this.revokeApplyid = applyid;
    this.revokeModule = true;
  }

  downMilk(){
    this.dataApi.revokeCampus(this.revokeApplyid).then((res:any)=>{
      this.getResumeList();
      this.revokeModule = false;
    }).catch((err)=>{
      console.log("downMilk",err);
    });
  }
}
