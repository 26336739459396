import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'heighttransform'})
export class HeightTransform implements PipeTransform {
  constructor(public _sanitizer: DomSanitizer){

  }  
  transform(min,max) {
    if(min && max){

      if(Number(min)>0 && Number(max)>0){

        //最小值大于最大值的情况(特殊情况)      
        if(Number(min)>Number(max)){
          let minCopy = min;
          let maxCopy = max;
          min = maxCopy;
          max = minCopy;
        }

        if(Number(min)==1 && Number(max)==201){
          return '身高：不限';
        }else if(Number(min)!=1 && Number(max)==201){
          return Number(min)+'cm以上';
        }else if(Number(min)==1 && Number(max)!=201){
          return Number(max)+'cm以下';
        }else{
          return Number(min)+'cm-'+Number(max)+'cm';
        }
      }else{
        return '不限';
      }

    }else{
      return '不限';
    }
    
    
  }
}